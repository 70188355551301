import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { EventButtonSection } from '../../styles'

const BotButtonCointaner = () => {
	const { t } = useTranslation()
	return (
		<EventButtonSection>
			<CButton
				title={t('learnMore')}
				onClick={() => {
					console.log('Login')
				}}
				border={{ width: '1px', color: COLORS.white }}
				style={{
					paddingBlock: 10,
					paddingInline: 25,
					marginRight: 10,
					color: '#fff',
				}}
				textHover={COLORS.white}
				backgroundHover={COLORS.primary}
			/>
		</EventButtonSection>
	)
}

export default BotButtonCointaner
