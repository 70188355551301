import React, { useContext, useState } from 'react'
import { CButton } from 'app/components/Common'
import { IconMoon, IconSunHigh } from '@tabler/icons'
import { ThemeContext } from 'theme/ThemeProvider'
import styled from 'styled-components'
import { Text } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { useThemeStore } from 'app/hooks'

const DarkModeToggle = () => {
	const { t } = useTranslation()
	const { isDarkMode, toggleTheme } = useContext(ThemeContext)
	const [rotated, setRotated] = useState<boolean>(false)
	const { data: themeStore, mutate: mutateThemeStore } = useThemeStore()
	if (themeStore?.darkMode === undefined) {
		mutateThemeStore({
			darkMode: false,
		})
	}
	const themeText = isDarkMode ? 'darkMode' : 'lightMode'

	const icon = isDarkMode ? (
		<IconSunHigh color={'#000'} size={16} />
	) : (
		<IconMoon color={'#fff'} size={16} />
	)
	return (
		<Wrapper
			rotated={rotated}
			onClick={() => {
				toggleTheme()
				setRotated(true)
			}}>
			<CButton icon={icon} />
		</Wrapper>
	)
}

const Wrapper = styled.div<{ rotated?: boolean }>`
	transition: transform 0.2s ease-in-out;
	display: flex;
	cursor: pointer;
	align-items: center;
	&:active {
		transform: scale(0.95);
	}
	&:focus {
		outline: none;
		transform: scale(1.05);
	}
`
export default DarkModeToggle
