import { COLORS } from 'app/constants'
import styled from 'styled-components'
export const OrderMenuSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	padding-inline: 10px;
`
export const MenuContainer = styled.div`
	width: 100px;
	background-color: ${p => p.theme.COLORS.bgColor};
	position: absolute;
	margin-top: 150px;
	box-shadow: 5px 10px 10px ${p => p.theme.COLORS.grey};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-inline: 5px;
`
export const MenuItems = styled.div`
	font-size: 14px;
	padding-block: 7px;
	cursor: pointer;
	&:hover {
		background-color: ${p => p.theme.COLORS.borderColor};
		color: ${COLORS.white} !important;
	}
	width: 100%;
`
