import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'

export const ContentContainer = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	flex-grow: 1;
	background-color: ${props => props.theme.COLORS.background};
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`
export const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	/* flex: 1; */
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	margin: 0;
	font-size: 1.5rem;
`

export const ContentSplit = styled.div`
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
	margin-top: 4px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	position: relative;
`
export const ContentHalf = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const TrendWrapper = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

// export const ContentWrapper = styled.div`
//     display: flex;
//     flex: 1;
//     height: auto;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     padding: 0;
//     margin-bottom: 180px;

//     @media only screen and (min-width: 1600px) {
//         width: 50%;
//     }
//     @media only screen and (max-width: 1400px) {
//         width: 70%;
//     }
//     @media only screen and (max-width: 968px) {
//         width: 70%;
//     }
//     @media only screen and (max-width: 600px) {
//         width: 100%;
//     }
// `;

// export const ContentSplit = styled.div`
//     height: 610px;
//     width: 100%;
//     display: flex;
//     flex: 1;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-around;
//     position: relative;
//     margin-top: 20px;

//     @media only screen and (min-width: 1200px) {
//         display: flex;
//     }
//     @media only screen and (max-width: 968px) {
//         display: block;
//         height: auto;
//     }
// `;
