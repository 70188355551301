import React, { useState } from 'react'
import Select from 'react-select'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { useFetcherPublic } from 'app/hooks/fetcher'
import { useChangeLanguage } from 'app/organisms/Header/BtnLanguageChange/hooks'

const LanguageSetting = () => {
	const { i18n, t } = useTranslation()

	const [lang, setLang] = useState(i18n.language)
	useChangeLanguage(lang)

	const { data: supportLanguage } = useFetcherPublic(
		'/system/language',
		false
	)

	const languageList = supportLanguage?.result?.result?.map((item: any) => {
		return {
			value: item?.code,
			label: item?.name,
		}
	})

	const customStyles = {
		container: (provided: any) => ({
			...provided,
			width: '100%',
		}),
	}

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
				padding: 10,
			}}>
			<Text size={18} style={{ fontWeight: '500' }}>
				{t('language')}
			</Text>
			<View
				style={{ marginTop: 20, width: 300, alignItems: 'flex-start' }}>
				<Text size={16} style={{ fontWeight: '400' }}>
					{t('chooseYourLanguage')}
				</Text>
				<View style={{ width: '100%', marginTop: 10 }}>
					<Select
						defaultValue={languageList?.find(
							(item: any) =>
								item?.value?.toUpperCase() ===
								i18n?.language?.toUpperCase()
						)}
						options={languageList}
						styles={customStyles}
						onChange={({ value }: any) => {
							setLang(value?.toLowerCase())
						}}
					/>
				</View>
			</View>
		</View>
	)
}

export default React.memo(LanguageSetting)
