import React, { useContext } from 'react'
import { IconChevronRight } from '@tabler/icons'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { WindowSize } from 'utils/ExpensiveFunction'

const ProfitDetailHeader = () => {
	const { textw, borderColor } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				flexDirection: 'row',
				borderBottom: `1px solid ${borderColor}`,
				width: '100%',
				padding: '16px 24px',
				justifyContent: 'space-between',
			}}>
			<Text size={16} style={{ fontWeight: '600' }}>
				Profit (Follower)
			</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text size={16}>View All</Text>
				<IconChevronRight size={WindowSize(16, 14, 12)} color={textw} />
			</View>
		</View>
	)
}

export default React.memo(ProfitDetailHeader)
