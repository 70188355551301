import React, { useContext } from 'react'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AboutContext = ({ context, style }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<Text
			size={20}
			style={{
				fontWeight: '300',
				marginTop: 34,
				lineHeight: 1.8,
				color: textw,
				...style,
			}}>
			{context}
		</Text>
	)
}

export default AboutContext
