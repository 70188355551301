import React, { useContext } from 'react'

import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { CBottomSheet } from '../mobile/components'
import CrossModal from '../mobile/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesHeader/CrossModal'
import { ThemeContext } from '../../theme/ThemeProvider'
import { MobileTradeModalView } from './TradePage/styles'
import DepositModal from '../mobile/components/DepositModal'
import WithdrawModal from '../mobile/components/WithdrawModal'
import ConvertModal from '../mobile/components/ConvertModal'
import PositionCloseContainer from '../mobile/organisms/Trade/TradeDetail/TradePositionsTable/PositionCloseContainer'
import DWTModal from '../mobile/components/DWTModal'
import { useWindowDimensions } from 'app/hooks'
import OrderPreferenceModal from 'app/mobile/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesBody/OrderValueInput/OrderPreferenceModal'
import { TransferModal } from '../components'

const MTradeModal = () => {
	const { height } = useWindowDimensions()
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	const COLORS = useContext(ThemeContext).theme.COLORS

	//bottomsheet color
	document.documentElement.style.setProperty(
		'--rsbs-bg',
		COLORS.mdrawerBgColor
	)

	return (
		<MobileTradeModalView
			style={{
				color: COLORS.textGrey,
			}}>
			<CBottomSheet
				open={modalStore?.depositModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						depositModal: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={DepositModal}
				maxHeight={height - 40}
			/>
			<CBottomSheet
				open={modalStore?.dwtModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						dwtModal: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={DWTModal}
				maxHeight={220}
			/>
			<CBottomSheet
				open={modalStore?.transferModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						transferModal: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={TransferModal}
				maxHeight={500}
			/>
			<CBottomSheet
				open={modalStore?.withdrawModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						withdrawModal: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={WithdrawModal}
				maxHeight={height - 40}
			/>
			<CBottomSheet
				open={modalStore?.accountConvert}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						accountConvert: value,
					})
				}}
				maxHeight={500}
				expandOnContentDrag={false}
				BottomContainer={ConvertModal}
			/>

			<CBottomSheet
				open={modalStore?.crossModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						crossModal: value,
					})
				}}
				maxHeight={500}
				expandOnContentDrag={false}
				BottomContainer={CrossModal}
			/>

			<CBottomSheet
				open={modalStore?.orderPlace}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						orderPlace: value,
					})
				}}
				maxHeight={500}
				expandOnContentDrag={false}
				BottomContainer={OrderPreferenceModal}
			/>
			<CBottomSheet
				open={modalStore?.positionClose?.close}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						positionClose: { close: false },
					})
				}}
				maxHeight={500}
				expandOnContentDrag={false}
				BottomContainer={PositionCloseContainer}
			/>
		</MobileTradeModalView>
	)
}

export default React.memo(MTradeModal)
