import React, { useState } from 'react'
import { CModal, CSwiper } from 'app/components/Common'
import TraderSliderItem from './TraderSliderItem'
import { SliderSize } from 'utils/ExpensiveFunction'
import { SectionHeader } from 'app/components'
import {
	SectionContainer,
	SectionWrap,
} from 'app/organisms/globalOrganismStyles'
import CopyTradeModal from '../../CopyTradeModal'

const TraderSlider = () => {
	const [selectedData, setSelectedData] = useState<any>(null)
	const [showModal, setShowModal] = useState(false)
	const closeModal = () => setShowModal(false)
	const openModal = (item: any) => {
		setShowModal(true)
		setSelectedData(item)
	}

	const data = ['0', '1', '2', '3', '4', '5', '6']
	const renderHeader = () => (
		<SectionHeader
			title={'topTradersByROI'}
			content={'traderslastdays'}
			goTo={'No Where'}
		/>
	)
	const renderItem = (item: any, index: any) => (
		<TraderSliderItem item={item} index={index} openModal={openModal} />
	)
	return (
		<SectionContainer>
			<CModal visible={showModal} onClose={closeModal}>
				<CopyTradeModal
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</CModal>
			<SectionWrap>
				<CSwiper
					data={data}
					renderItem={renderItem}
					slidesPerView={SliderSize()}
					slidesPerGroup={SliderSize()}
					SwiperHeader={renderHeader}
				/>
			</SectionWrap>
		</SectionContainer>
	)
}

export default TraderSlider
