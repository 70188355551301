import React from 'react'
import { CFloatBtnsSection, FloatBtnSection2 } from './styles'
import { CImage } from '../Common'
import { IMAGES } from 'app/constants'
import { FloatBtnSection } from './styles'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../styles/reactnative'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { IconHandMove } from '@tabler/icons-react'
import { IconArrowsMove, IconCross } from '@tabler/icons'
import { useHistory } from 'react-router-dom'
import { goTo } from '../../hooks/navigation'
import TicketModal from '../../organisms/TicketModal'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
const CFloatBtns = () => {
	const { t } = useTranslation()
	const nav = useHistory()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	return (
		<Draggable handle=".handmove">
			<CFloatBtnsSection>
				<TicketModal />
				<FloatBtnSection2
					className="handmove"
					style={{ marginRight: 15 }}>
					<IconArrowsMove size={15} />
				</FloatBtnSection2>

				<FloatBtnSection
					style={{ marginRight: 10, width: 40, height: 40 }}
					onClick={() => {
						mutateModalStore({
							...modalStore,
							qna: true,
						})
					}}>
					<CImage
						src={IMAGES.callCenter}
						alt="logo"
						width={50}
						height={50}
						resizeMode={'cover'}
						style={{}}
					/>
				</FloatBtnSection>
				<FloatBtnSection
					style={{ width: 40, height: 40 }}
					onClick={() => {
						goTo(nav, '/supports?tab=3')
					}}>
					<CImage
						src={IMAGES.helpCenter}
						alt="logo"
						width={'auto'}
						height={'auto'}
						resizeMode={'cover'}
						style={{}}
					/>
				</FloatBtnSection>
			</CFloatBtnsSection>
		</Draggable>
	)
}

export default CFloatBtns
