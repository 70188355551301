import React, { useContext } from 'react'
import { CButton, CImage } from 'app/components/Common'
import { useFavourite } from 'app/hooks'
import { Text, View } from 'styles/reactnative'
import { IconStarFilled, IconStar } from '@tabler/icons-react'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'

const FavBtn = ({ item }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { data: favourites, mutate: mutateFavourite } = useFavourite()
	const favourite = favourites?.find?.((f: any) => f?.id === item?.id)
	const removeFavourite = favourites?.filter?.((f: any) => f?.id !== item?.id)
	const icon = favourite ? (
		<IconStarFilled size={20} style={{ color: COLORS.primary }} />
	) : (
		<IconStar color={textw} size={20} />
	)
	return (
		<CButton
			icon={icon}
			style={{ marginRight: 5 }}
			onClick={() => {
				if (!favourite) {
					mutateFavourite([...(favourites ? favourites : []), item])
				} else {
					mutateFavourite(removeFavourite)
				}
			}}
		/>
	)
}

export default React.memo(FavBtn)
