import React from 'react'
import { MView } from '../../../../../styles/mobilePageStyles'
import { SpacebetweenRowView, Text } from '../../../../../styles/reactnative'
import { useTimezone } from '../../../../hooks/timezone'
import BigNumber from 'bignumber.js'

const MReferralHistoryItem = ({ item }) => {
	return (
		<SpacebetweenRowView
			style={{
				width: '100%',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				minHeight: 40,
			}}>
			<Text style={{ width: '30%', justifyContent: 'flex-start' }}>
				{item?.nickname}
			</Text>
			<Text style={{ width: '30%' }}>
				{useTimezone(item?.created_at)}
			</Text>
			<Text style={{ width: '30%', justifyContent: 'flex-end' }}>
				{BigNumber(item?.balance || 0).toFormat(2)}
			</Text>
		</SpacebetweenRowView>
	)
}

export default React.memo(MReferralHistoryItem)
