import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'

const AlertInputLists = ({ item, index }) => {
	const { t } = useTranslation()
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginBottom: 20,
			}}>
			<Text size={14} style={{ fontWeight: '400' }}>
				{item.title}
			</Text>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					padding: 15,
					background: borderColor,
					borderRadius: 5,
					marginTop: 10,
				}}>
				<Text size={14} style={{ fontWeight: '400' }}>
					{item.link}
				</Text>
				<Text
					size={14}
					style={{
						color: COLORS.secondary,
						fontWeight: '500',
						marginLeft: 50,
					}}>
					{t('copyUrl')}
				</Text>
			</View>
		</View>
	)
}

export default AlertInputLists
