import { COLORS, SIZES } from 'app/constants'
import styled from 'styled-components'

export const HighestProfitSection = styled.div`
	font-size: ${SIZES.h4};
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
	margin-block: 30px;
	width: 100%;
`
export const HighestProfitListSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	row-gap: 10px;
	column-gap: 10px;
	@media (max-width: 600px) {
		justify-content: center;
	}
	&:after {
		content: '';
		flex: 0 1 30%;
	}
`
export const ProfitItemSection = styled.div`
	width: 260px;
	height: 320px;
	margin-top: 24px;
	border-radius: 8px;
	border: 0.5px solid ${p => p.theme.COLORS.borderColor};
	position: relative;
	font-size: 14px;
	align-items: flex-start;
	width: calc(95% / 4);
	display: flex;
	cursor: pointer;
	transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	background-color: ${p => p.theme.COLORS.bgColor};
	box-shadow: 0 4px 24px rgb(0 0 0 / 5%);
	transition: 0.3s;
	&:hover {
		box-shadow: 2px 3px 20px ${COLORS.primaryDark};
	}
	@media (max-width: 1400px) {
		width: calc(95% / 3);
	}
	@media (max-width: 900px) {
		width: calc(95% / 2);
	}
	@media (max-width: 700px) {
		width: calc(95% / 1);
	}
`
