import BigNumber from 'bignumber.js'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useIndexPrice } from '../../../../../../hooks/trade/indexprice'
import { useInfo24H } from '../../../../../../hooks/trade/info24H'
import { useLastPrice } from '../../../../../../hooks/trade/lastprice'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import ContaractDetailsList from './ContaractDetailsList'
import throttle from 'lodash/throttle' // Lodash 추가

const ContractDetails = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { data: tradeStore } = useTradeStore()
	let { getPrice } = useIndexPrice(tradeStore?.ep_id)
	const { data: info24H } = useInfo24H(tradeStore?.ep_id)

	const lastPrice = getPrice(tradeStore?.ep_id)

	const [throttledPrice, setThrottledPrice] = useState(lastPrice)
	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		throttle(newPrice => {
			// 여기서 가격 업데이트 로직을 실행합니다.
			setThrottledPrice(newPrice) // 쓰로틀된 가격을 상태로 업데이트
		}, 300), // 2초마다 최대 1번 실행
		[] // 의존성 배열 비움
	)

	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(lastPrice)
	}, [lastPrice, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함

	/*
				<ContaractDetailsList
					title={t('openInterest')}
					amount={`${'51,108,290'} BTC`}
				/>
				<ContaractDetailsList
					title={t('24HTurnover')}
					amount={`${'3,075,955,134.00'} USDT`}
				/>
*/
	return (
		<View style={{ alignItems: 'flex-start', marginTop: 20 }}>
			<Text style={{ fontSize: 14, color: textw }}>
				{t('contractDetails')}
			</Text>
			<View style={{ marginTop: 10, width: '100%' }}>
				<ContaractDetailsList
					title={t('expirationDate')}
					amount={t('perpetual')}
				/>
				<ContaractDetailsList
					title={t('indexPrice')}
					amount={BigNumber(throttledPrice).toFormat(2)}
				/>
				<ContaractDetailsList
					title={t('24HVolume')}
					amount={BigNumber(info24H?.volume).toFormat(2)}
				/>
				<ContaractDetailsList
					title={t('riskLimit')}
					amount={`${'2,000,000'} USDT`}
				/>
			</View>
		</View>
	)
}

export default React.memo(ContractDetails)
