import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingTitleSection } from './styles'

const SettingTitle = ({ title }) => {
	const { t } = useTranslation()
	return <SettingTitleSection>{t(title)}</SettingTitleSection>
}

export default SettingTitle
