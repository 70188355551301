import React from 'react'
import { useTranslation } from 'react-i18next'
import { CImage } from 'app/components/Common'
import { MText, MView } from 'styles/mobilePageStyles'
import { IMAGES } from 'app/constants'
import { toast } from 'react-toastify'

const CryptoContainer = ({ setOpen }) => {
	const { t } = useTranslation()
	const data = [
		{
			image: IMAGES.crypto,
			title: 'oneClickBuy',
			content: 'buyCryptoWithSeconds',
		},
		{
			image: IMAGES.derivatives,
			title: 'p2pTrading',
			content: 'p2pTradingFeesContent',
		},
		{
			image: IMAGES.eventImage,
			title: 'fiatDeposit',
			content: 'fiatDepositContent',
		},
	]
	return (
		<MView style={{ marginTop: 20 }}>
			<MView>
				{data?.map((item, index) => (
					<MView
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							padding: 10,
						}}
						onClick={() => {
							toast(t('notWorkingNow') + '')
						}}
						key={index.toString()}>
						<MView
							style={{
								width: 25,
								height: 25,
								borderRadius: 50,
								marginRight: 10,
								alignSelf: 'flex-start',
							}}>
							<CImage
								src={item?.image}
								resizeMode="cover"
								style={{
									width: '25px',
									height: '25px',
									borderRadius: 50,
								}}
							/>
						</MView>
						<MView>
							<MText style={{ fontSize: 14, fontWeight: 300 }}>
								{t(item?.title)}
							</MText>
							<MText
								style={{
									fontSize: 12,
									fontWeight: 300,
									paddingTop: 5,
								}}>
								{t(item?.content)}
							</MText>
						</MView>
					</MView>
				))}
			</MView>
		</MView>
	)
}

export default CryptoContainer
