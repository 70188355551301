import React from 'react'
import { View, Text } from 'styles/reactnative'

const TradersFollowersItems = ({ data }: any) => {
	return (
		<View
			style={{
				alignItems: 'center',
				justifyContent: 'flex-start',
				flexDirection: 'row',
			}}>
			<Text size={12}>+{data * 22315}%</Text>
		</View>
	)
}

export default React.memo(TradersFollowersItems)
