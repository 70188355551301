import useSWR from 'swr'
import { useToken } from '../auth/token'
import API from '../../../utils/api'

export function useTradeOrder(ep_id) {
	const { data: token } = useToken()
	let KEYURL = '/trade/orderlist?status=1&ep_id=' + ep_id

	const { data, mutate } = useSWR(
		!ep_id || !token ? null : KEYURL,
		async () => {
			const response = await API.get(KEYURL, token + '', {}, false)
			console.log('useTradeOrder', response)
			return response?.result || []
		},
		{ revalidateOnFocus: true, refreshInterval: 1500 }
	)

	return {
		data,
		mutate,
	}
}
