import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'

import TrustedDeviceList from './TrustedDeviceList'

const TrustedDevices = () => {
	const { t } = useTranslation()
	return (
		<MView
			style={{
				width: '100%',

				paddingInline: 5,
			}}>
			<MView style={{ paddingTop: 10 }}>
				<MText style={{ fontSize: 10, fontWeight: '300' }}>
					{t('theseDevicesHaveBeenAuthorized')}
				</MText>
			</MView>
			<TrustedDeviceList />
			<TrustedDeviceList />
			<TrustedDeviceList />
		</MView>
	)
}

export default TrustedDevices
