export const API_URL = process.env.REACT_APP_API_URL // API URL
export const CHART_HISTORY = process.env.REACT_APP_CHART_URL + '/history'
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const GLOBAL_URL = process.env.REACT_APP_GLOBAL_URL

export const USERINFO = '/user/my/info' // 사용자 정보
export const MYSETTINGS = '/user/my/settings' // 내 설정
export const MYBALANCE = '/user/my/balance' // 내 자산
export const POPUP = '/popup/active' // popup
export const MYTRADESETTINGS = '/user/my/tradesetting' // 내 트레이딩설정

// Created By 태신
export const TRANSFER = '/user/my/transfer'

export const EXCHANGESETTINGS = '/exchanges' // 거래쌍 설정
export const ORDERBOOK = '/trade/orderbooks' // 오더북
export const CANCELORDER = '/trade/cancel' // 주문취소

export const TRADELIST = '/trade/tradelist' // 주문취소
export const GOOGLE_CLIEND_ID =
	'61575463377-p61va5v6gri0iprnfsj74uau6q3qa88m.apps.googleusercontent.com'
