import styled from 'styled-components'

export const TpslPercentageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: ${props => props.theme.COLORS.gray};
	justify-content: center;
	align-items: center;
	border-radius: 5px;
`
export const TpslInputSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
`
