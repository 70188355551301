import React from 'react'
import MarketTableList from './MarketTableList'
import {
	MarketTableSection,
	SectionContainer,
	SectionWrap,
} from '../../globalOrganismStyles'
import { SectionHeader } from 'app/components'

const MarketTable = () => {
	return (
		<SectionContainer>
			<SectionWrap>
				<MarketTableSection>
					<SectionHeader title={'btnMarkets'} marginLeft={true} />
					<MarketTableList />
				</MarketTableSection>
			</SectionWrap>
		</SectionContainer>
	)
}

export default React.memo(MarketTable)
