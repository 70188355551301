import React from 'react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'

interface IProps {
	title: string
	amount: string
	icon: () => JSX.Element
}
const CopyTraderDetailAssets = ({ title, amount, icon }: IProps) => {
	const { width } = useWindowDimensions()
	return (
		<View
			style={{
				backgroundColor: ' #213047',
				padding: width < 600 ? 7 : 10,
				borderRadius: 5,
			}}>
			<View style={{ flexDirection: 'row', marginBottom: 5 }}>
				{icon()}
				<Text
					size={12}
					style={{ color: COLORS.lightGray, marginLeft: 5 }}>
					{title}
				</Text>
			</View>
			<Text
				size={width < 600 ? 12 : 14}
				style={{ color: COLORS.greyWhite }}>
				{amount}
			</Text>
		</View>
	)
}

export default CopyTraderDetailAssets
