import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const CheckboxWrapper = styled.div<{ borderColor?: string }>`
	input[type='checkbox'] {
		-webkit-appearance: none;
		appearance: none;
		width: 1.3em;
		height: 1.3em;
		border-radius: 0.15em;
		margin-right: 0.5em;
		border: ${props =>
			`0.15em solid ${
				props.borderColor ? props?.borderColor : COLORS.primary
			}`};
		outline: none;
		cursor: pointer;
	}

	input:checked {
		background-color: ${COLORS.primary};
		position: relative;
	}

	input:checked::before {
		content: '\\2714';
		font-size: 1em;
		color: #fff;
		position: absolute;
		right: 0px;
		top: -4px;
	}
`
