import React, { useContext } from 'react'
import CText from 'app/components/Common/CText'
import { useTranslation } from 'react-i18next'
import { FONTS } from 'app/constants/'
import { ChoiceBtns, ChoiceTab } from './styles'
import { ThemeContext } from '../../../../theme/ThemeProvider'

interface IProps {
	fontSize?: number
	color?: string
	active: string
	setActive: (active: string) => void
	types: string[]
	onPressOrClick: (type: string) => void
	style?: any
	wrapstyle?: any
}

const CTab = ({
	fontSize,
	color,
	active,
	setActive,
	types,
	onPressOrClick,
	style,
	wrapstyle,
}: IProps) => {
	const { t } = useTranslation()

	const theme = useContext(ThemeContext)

	return (
		<ChoiceBtns style={wrapstyle}>
			{types.map(type => {
				return (
					<ChoiceTab
						color={color}
						active={active === type}
						activeColor={theme.theme.COLORS.textw}
						key={type}
						onClick={() => {
							setActive(type)
							onPressOrClick(type)
						}}>
						<CText
							text={t(type)}
							style={{
								...FONTS.h5,
								fontSize: fontSize,
								fontWeight: '500',
								...style,
							}}
						/>
					</ChoiceTab>
				)
			})}
		</ChoiceBtns>
	)
}

export default React.memo(CTab)
