import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'

export const HomeSection = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${props => props.theme.COLORS.background};
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 70px;
`
