import React from 'react'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import { COLORS } from 'app/constants/STYLES'
import {
	CustomDot,
	TransactionRangeSlider,
	TransactionSliderPercent,
	TransactionSliderSection,
} from './styles'
import { Text } from 'styles/reactnative'
const { Handle } = Slider

const handle = (props: any) => {
	const { value, dragging, index, ...restProps } = props
	return (
		<SliderTooltip
			prefixCls="rc-slider-tooltip"
			overlay={`${value}%`}
			visible={dragging}
			placement="top"
			key={index}>
			<Handle value={value} {...restProps} />
		</SliderTooltip>
	)
}

const TransactionSlider = () => {
	return (
		<TransactionSliderSection>
			<TransactionRangeSlider>
				<Slider
					min={0}
					max={100}
					defaultValue={0}
					handle={handle}
					railStyle={{
						backgroundColor: COLORS.inputBackground,
						height: 4,
					}}
					trackStyle={{
						backgroundColor: COLORS.lightGray5,
						height: 4,
					}}
					handleStyle={{
						borderWidth: 3,
						borderColor: COLORS.lightGray5,
						height: 15,
						width: 15,
						backgroundColor: COLORS.HeaderBarBackground,
						zIndex: 1,
					}}
				/>

				{[0, 4, 8, 12, 16].map((item, index) => (
					<CustomDot key={index.toString()} item={item} />
				))}
			</TransactionRangeSlider>
			<TransactionSliderPercent>
				<Text style={{ color: COLORS.lightGray5 }}>0%</Text>
				<Text style={{ color: COLORS.lightGray5 }}>100%</Text>
			</TransactionSliderPercent>
		</TransactionSliderSection>
	)
}

export default React.memo(TransactionSlider)
