import { CButton, CImage } from 'app/components/Common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import totalReserve from 'assets/images/total-reserve.png'
import { COLORS } from 'app/constants'
const TotalReserveCard = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				height: 330,
				background: 'rgba(255,255,255,0.1)',
				marginTop: 20,
				borderRadius: 10,
				justifyContent: 'flex-start',
				padding: 30,
			}}>
			<View>
				<Text size={24}>{t('latestReserveCardTitle2')}</Text>
			</View>
			<View style={{ marginBlock: 20 }}>
				<CImage src={totalReserve} style={{ width: 80, height: 80 }} />
			</View>
			<View style={{ width: '60%', marginBlock: 20 }}>
				<Text size={18} style={{ fontWeight: '300', lineHeight: 1.5 }}>
					{t('latestReserveCardContent2')}
				</Text>
			</View>
			<CButton
				title={'245%'}
				style={{
					width: '100%',
					height: 35,
					background: COLORS.primary,
					borderRadius: 5,
					color: COLORS.white,
				}}
			/>
		</View>
	)
}

export default TotalReserveCard
