import React from 'react'

import HelpCenterContainer from './HelpCenterContainer'
import { MView } from 'styles/mobilePageStyles'

const dummyData: any = []

const MHelpCenter = () => {
	if (dummyData.length === 0) {
		for (let i = 1; i < 4; i++) {
			dummyData.push({
				id: 1,
				date: new Date(),
				title: 'FAQTITLE' + i,
				content: 'FAQCONTENT' + i,
			})
		}
	}

	return (
		<MView style={{ width: '100%' }}>
			<HelpCenterContainer dummyData={dummyData} />
		</MView>
	)
}

export default MHelpCenter
