import { message } from 'antd'
import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { MButton } from 'app/mobile/components'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import API from '../../../../../../../utils/api'
import { useToken } from '../../../../../../hooks'

const PasswordContainer = () => {
	const { textw, gray, textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { data: token } = useToken()
	const [password, setPassword] = useState('')
	const [password_repeat, setPassword_repeat] = useState('')
	const modifyPassword = () => {
		if (password.length < 8) {
			message.error(t('passwordLengthError'))
			return
		} else if (password !== password_repeat) {
			message.error(t('passwordNotMatch'))
			return
		}

		API.put('/user/my/password', token + '', { password: password }).then(
			res => {
				message.success(t('success'))
			}
		)
	}
	return (
		<MView
			style={{
				width: '100%',
				paddingInline: 16,
				paddingTop: 30,
			}}>
			<MView>
				<MText
					style={{
						fontSize: 14,
						fontWeight: '300',
					}}>
					{t('password')}
				</MText>
			</MView>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<CInput
					placeholder={t('setNewPasssword')}
					value={password}
					type={'password'}
					onChange={(e: any) => setPassword(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 7,
					}}
					style={{
						background: gray,
						border: 0,
						color: textw,
						padding: 8,
						paddingLeft: 15,
						width: '100%',
					}}
				/>
			</MView>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<CInput
					placeholder={t('repeatPassword')}
					value={password_repeat}
					type={'password'}
					onChange={(e: any) => setPassword_repeat(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 7,
					}}
					style={{
						background: gray,
						border: 0,
						color: textw,
						padding: 8,
						paddingLeft: 15,
						width: '100%',
					}}
				/>
			</MView>
			<MButton
				title={'confirm'}
				onClick={modifyPassword}
				style={{
					paddingBlock: 8,
					background: COLORS.primary,
					marginTop: -3,
					borderRadius: 5,
					width: '100%',
				}}
				textStyle={{ color: COLORS.white }}
			/>
		</MView>
	)
}

export default PasswordContainer
