import React from 'react'

import { TradeChartContainer } from './styles'
// import TradeChartHeader from './TradeChartHeader'
import TradeChartSection from './TradeChartSection'

const TradeChart = ({ isDragging }) => {
	return (
		<TradeChartContainer>
			<TradeChartSection isDragging={isDragging} />
		</TradeChartContainer>
	)
}

export default React.memo(TradeChart)
