export const data = [
	{
		title: 'about',
		list: [
			{
				text: 'aboutTheBit',
				goTo: '/abouts?tab=1',
			},
			{
				text: 'announcements',
				goTo: '/abouts?tab=2',
			},
			{
				text: 'proofOfReserves',
				goTo: '/abouts?tab=3',
			},
			{
				text: 'riskWarning',
				goTo: '/abouts?tab=4',
			},
			{
				text: 'privacyPolicy',
				goTo: '/terms-service?tab=1',
			},
			{
				text: 'TermsOfService',
				goTo: '/terms-service?tab=2',
			},
		],
	},
	{
		title: 'btnMarkets',
		list: [
			{
				text: 'marketOverview',
				goTo: '/markets',
			},
			// {
			// 	text: 'marketData',
			// 	goTo: '/markets/trends',
			// },
		],
	},
	{
		title: 'trade',
		list: [
			{
				text: 'usdtPerpetual',
				goTo: '/trade/2',
			},
		],
	},
	{
		title: 'tools',
		list: [
			{
				text: 'copyTrading',
				goTo: '/copytrade',
			},
			{
				text: 'leaderBoard',
				goTo: '/leaderboard',
			},
		],
	},
	{
		title: 'support',
		list: [
			{
				text: 'tradingFee',
				goTo: '/supports?tab=1',
			},
			{
				text: 'theBitLearn',
				goTo: '/supports?tab=2',
			},
			{
				text: 'helpCenter',
				goTo: '/supports?tab=3',
			},
		],
	},
]
