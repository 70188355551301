import React, { useContext } from 'react'
import {
	TraderSliderBody,
	TraderSliderHeader,
	TraderSliderHeaderText,
	TraderSliderItemSection,
} from './styles'
import TraderDetails from '../TraderDetails'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const TraderSliderItem = ({ item, index, openModal }: any) => {
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<TraderSliderItemSection>
			<TraderSliderHeader
				style={{ background: isDarkMode ? '#fdebc4' : '#F5D580' }}>
				<TraderSliderHeaderText>No.{index + 1}</TraderSliderHeaderText>
			</TraderSliderHeader>
			<TraderSliderBody>
				<View style={{ marginTop: -50 }}>
					<TraderDetails
						openModal={openModal}
						item={item}
						index={index}
					/>
				</View>
			</TraderSliderBody>
		</TraderSliderItemSection>
	)
}

export default TraderSliderItem
