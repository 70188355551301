import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconBell, IconUserCircle } from '@tabler/icons'
import { View } from 'styles/reactnative'
import AuthHeaderButton from './AuthHeaderButton'
import { AuthHeaderMobile } from './styles'
import { useHistory } from 'react-router-dom'
import { goTo } from '../../../hooks/navigation'

const AuthHeaderMenu = () => {
	const { t } = useTranslation()

	const nav = useHistory()

	return (
		<View
			style={{
				flexDirection: 'row',
				paddingInline: '1rem',
				marginRight: 0,
			}}>
			<AuthHeaderMobile>
				<AuthHeaderButton
					type={'asset'}
					label={t('assets')}
					width={50}
				/>
				<AuthHeaderButton
					type={'deposit'}
					label={t('DepositWithdraw')}
					width={80}
				/>
				<AuthHeaderButton
					type={'orders'}
					label={t('orders')}
					width={70}
					onPress={() => {
						goTo(nav, '/orders')
					}}
				/>
			</AuthHeaderMobile>
			<View
				style={{
					marginLeft: 5,
					marginRight: 5,
					width: 1,
					height: 15,
					background: '#f1f1f1',
				}}
			/>
			<AuthHeaderButton
				type={'user'}
				Icon={() => <IconUserCircle size={20} />}
			/>
			<AuthHeaderButton
				type={'noti'}
				Icon={() => <IconBell size={20} />}
			/>
		</View>
	)
}

export default AuthHeaderMenu
