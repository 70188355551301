import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { ProfileTradingAlertHeaderSection } from './styles'

const ProfileTradingAlertHeader = () => {
	const { t } = useTranslation()
	return (
		<ProfileTradingAlertHeaderSection>
			<Text size={20} style={{ fontWeight: '500' }}>
				{t('tradingViewAlerts')}
			</Text>
		</ProfileTradingAlertHeaderSection>
	)
}

export default ProfileTradingAlertHeader
