import React, { useEffect, useState } from 'react'
import { CFlatList } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import { View } from 'styles/reactnative'
import { loadData } from 'utils/api'
import LeaderBoardRenderItem from './LeaderBoardRenderItem'
import LeaderBoardTableHeader from './LeaderBoardTableHeader'
import LeaderBoardTableTabs from './LeaderBoardTableTabs'
import { LeaderBoardTableSection } from './styles'
import { useFetcher, useFetcherPublic } from '../../../hooks/fetcher'
import { DatePicker, Space } from 'antd'
import dayjs from 'dayjs'

const LeaderBoardTable = () => {
	const { height } = useWindowDimensions()

	const [startDate, setStartDate] = useState<any>(
		dayjs().format('YYYY-MM-01')
	)
	const [endDate, setEndDate] = useState<any>(dayjs().format('YYYY-MM-DD'))
	const [orderBy, setOrderBy] = useState('pnl_price desc')
	const [maxData, setMaxdata] = useState(50)
	const [page, setPage] = useState(1)

	const url = `/user/rank?page=${page}${
		(startDate && `&startDate=${startDate}`) || ''
	}${
		(endDate && `&endDate=${endDate}`) || ''
	}&maxData=${maxData}&orderBy=${orderBy}`

	const { data, refresh } = useFetcherPublic(url, false)

	const list = data?.result?.[0]

	useEffect(() => {
		console.log('rank refresh', url, data)
		refresh()
	}, [startDate, endDate, page])

	console.log('rank', url, list)

	const renderItem = (item: any, index: any) => {
		return <LeaderBoardRenderItem item={item} index={index} />
	}
	return (
		<LeaderBoardTableSection>
			<Space size="small" style={{width:'100%', justifyContent:'flex-end'}}>
				<Space.Compact style={{}}>
					<DatePicker
						onChange={e =>
							setStartDate(e ? e.format('YYYY-MM-DD') : null)
						}
						defaultValue={
							startDate
								? dayjs(startDate, 'YYYY-MM-DD')
								: undefined
						}
					/>
					<DatePicker
						onChange={e =>
							setEndDate(e ? e.format('YYYY-MM-DD') : null)
						}
						defaultValue={
							endDate ? dayjs(endDate, 'YYYY-MM-DD') : undefined
						}
					/>
				</Space.Compact>
			</Space>
			<LeaderBoardTableHeader />

			<View style={{ height: height, width: '100%' }}>
				<CFlatList data={list} renderItem={renderItem} />
			</View>
		</LeaderBoardTableSection>
	)
}

export default LeaderBoardTable
