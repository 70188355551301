import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { AssetsHeaderSection } from './styles'

const AssetsHeader = () => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	return (
		<AssetsHeaderSection>
			<View style={{ flexDirection: 'row' }}>
				<Text size={16} style={{ color: textsilver, marginRight: 10 }}>
					{t('DepositWithdraw')}
				</Text>
			</View>
		</AssetsHeaderSection>
	)
}

export default React.memo(AssetsHeader)
