import styled from 'styled-components'

export const TheBitCardListSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding-inline: 5px;
`

export const TheBitCardItemSection = styled.div`
	width: 100%;
	height: 300px;
	margin: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 10px;
	@media only screen and (min-width: 600px) {
		width: 45%;
	}
`
