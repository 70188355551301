import React from 'react'
import { RecentDepositSection } from '../styles'

import RecentDepositHeader from './RecenDepositHeader'
import RecentDepositTable from './RecentDepositTable'

const RecentDeposit = () => {
	return (
		<RecentDepositSection>
			<RecentDepositHeader />
			<RecentDepositTable />
		</RecentDepositSection>
	)
}

export default RecentDeposit
