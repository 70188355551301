const LOADING = 1
const LOADED = 2

let itemStatusMap = {}

export const loadMoreItems = (startIndex: any, stopIndex: number) => {
	for (let index = startIndex; index <= stopIndex; index++) {
		itemStatusMap[index] = LOADING
	}
	return new Promise<void>(resolve =>
		setTimeout(() => {
			for (let index = startIndex; index <= stopIndex; index++) {
				itemStatusMap[index] = LOADED
			}
			resolve()
		}, 0)
	)
}
export const isItemLoaded = (index: string | number) => !itemStatusMap[index]

// true later need to learn about it !!itemStatusMap[index];
// export const isItemLoaded = (index: string | number) => !!itemStatusMap[index];
