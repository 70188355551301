import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const ChoiceBtns = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	padding: 10px;
	padding-top: 10px;
	padding-bottom: 0px;
	width: 100%;
`
// eslint-disable-next-line prettier/prettier
export const ChoiceTab = styled.button < { active: boolean; activeColor: string,color?: string } >`
	padding: 0px;
	margin-right: 10px;
	cursor: pointer;
	opacity: 0.6;
	background: transparent;
	border: 0;
	outline: 0;
	color: ${props => props.color};
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	transition: ease border-bottom 250ms;
	padding-bottom: 5px;
	&:hover {
		color: ${COLORS.BTN_HOVER};
	}
	${({ active, activeColor }) =>
		active &&
		`
        color:${activeColor};
        border-bottom-width: 2px;
        border-bottom-color: ${activeColor};
        opacity: 1;
    `}
`
