import React from 'react'
import { COLORS } from 'app/constants/STYLES'
import { Divider } from './styles'
interface IProps {
	style?: React.CSSProperties
	dStyle?: React.CSSProperties
	color?: string
}
const CDivider = ({ style, color, dStyle }: IProps) => {
	return (
		<div style={style}>
			<Divider color={color ? color : COLORS.grayWhite} style={dStyle} />
		</div>
	)
}

export default React.memo(CDivider)
