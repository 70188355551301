import React from 'react'
import BigNumber from 'bignumber.js'
import { Text, View } from 'styles/reactnative'
import { COLORS } from '../../../../../../constants'

const PriceHigh = ({ indexPrice, info24H }) => {
	return (
		<View
			style={{
				width: '45%',
				flexDirection: 'row',
			}}>
			<Text
				style={{
					width: '40%',
					fontSize: 12,
					color:
						info24H?.rate > 0
							? COLORS.lightGreen
							: info24H?.rate < 0
							? COLORS.lightRed
							: 'white',
				}}>
				{BigNumber(indexPrice).toFormat(2)}
			</Text>
			<Text
				style={{
					width: '60%',
					fontSize: 12,
					color:
						info24H?.rate > 0
							? COLORS.lightGreen
							: info24H?.rate < 0
							? COLORS.lightRed
							: 'white',
				}}>
				{BigNumber(info24H?.rate).toFormat(2) + '%'}
			</Text>
		</View>
	)
}

export default React.memo(PriceHigh)
