import React, { useEffect, useState } from 'react'
import { View, Text } from 'styles/reactnative'
import { loadData } from 'utils/api'
import HistoryChartTableList from './HistoryChartTableList'

const HistoryChartTable = () => {
	const [data, setData] = useState([])

	useEffect(() => {
		loadData().then(res => setData(res.slice(0, 5)))
	}, [])
	return (
		<View
			style={{
				width: '100%',
				height: '40%',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
			}}>
			<View style={{ flexDirection: 'row', width: '100%' }}>
				<Text size={14} style={{ width: '33.33%' }}>
					Pair / % of the Total Trades
				</Text>
				<Text size={14} style={{ width: '33.33%' }}>
					총 입출금 거래
				</Text>
				<Text size={14} style={{ width: '33.33%' }}>
					P&L
				</Text>
			</View>
			<View style={{ width: '100%' }}>
				{data?.map((item: any, index) => (
					<HistoryChartTableList
						key={index.toString()}
						item={item}
						index={index}
					/>
				))}
			</View>
		</View>
	)
}

export default HistoryChartTable
