import React, { useContext } from 'react'
import { CButton, CImage } from 'app/components/Common'
import { useFavourite } from 'app/hooks'
import { Text, View } from 'styles/reactnative'
import { IconStarFilled, IconStar } from '@tabler/icons-react'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import FavBtn from './FavBtn'

const ListCoin = ({ item, setDisplayDrawer }) => {
	const navigation = useHistory()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '35%',
				alignItems: 'center',
				justifyContent: 'flex-start',
				flexDirection: 'row',
			}}>
			<FavBtn item={item} />
			<View
				style={{ flexDirection: 'row' }}
				onClick={() => {
					goTo(navigation, `/trade/${item?.id}`)
					setDisplayDrawer(false)
				}}>
				<CImage
					src={item?.image}
					alt="logo"
					width={18}
					height={18}
					resizeMode={'cover'}
					style={{ borderRadius: 50 }}
				/>
				<Text
					style={{
						marginLeft: 5,
						fontWeight: 'bold',
						fontSize: 12,
					}}>
					{item?.pairs}
				</Text>
			</View>
		</View>
	)
}

export default React.memo(ListCoin)
