import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconChevronDown } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { HeaderDetailNextList } from '../styles'

const HelpListDetailFooter = ({
	item,
	data,
	setReadDetail,
	setCurrentItem,
}) => {
	const { t } = useTranslation()
	const { textw, borderColor } = useContext(ThemeContext).theme.COLORS
	const [nextPage, setNextPage] = useState(+item.id)
	const [currentData, setCurrentData] = useState(data[nextPage])

	useEffect(() => {
		setCurrentData(data[nextPage])
	}, [nextPage, data])

	return (
		<View style={{ width: '100%', height: '18%' }}>
			{data.length === nextPage ? (
				<CButton
					title={t('previous')}
					style={{
						alignSelf: 'flex-start',
						border: `1px solid ${borderColor}`,
						paddingBlock: 3,
						paddingInline: 10,
						marginLeft: 20,
						color: textw,
					}}
					onClick={() => {
						setNextPage(nextPage - 1)
						setCurrentItem(data[nextPage - 2])
					}}
				/>
			) : (
				<HeaderDetailNextList
					onClick={() => {
						setCurrentItem(data[nextPage])
						setNextPage(
							data.length === nextPage
								? data.length
								: nextPage + 1
						)
					}}
					style={{
						borderBlock: `1px solid ${borderColor}`,
					}}>
					<View style={{ flexDirection: 'row', width: '80%' }}>
						<View style={{ width: '7%', alignItems: 'flex-start' }}>
							<IconChevronDown size={18} color={textw} />
						</View>
						<View
							style={{ width: '13%', alignItems: 'flex-start' }}>
							<Text style={{ fontSize: 14 }}>{t('next')}</Text>
						</View>
						<View
							style={{ width: '80%', alignItems: 'flex-start' }}>
							<Text style={{ fontSize: 14 }}>
								{currentData?.title}
							</Text>
						</View>
					</View>
					<View style={{ width: '20%' }}>
						<Text style={{ fontSize: 12 }}>
							{moment(currentData?.date).format('YYYY-MM-DD')}
						</Text>
					</View>
				</HeaderDetailNextList>
			)}

			<View style={{ width: '100%', height: '40%' }}>
				<CButton
					title={t('list')}
					onClick={() => {
						setReadDetail(false)
					}}
					style={{
						border: `1px solid ${borderColor}`,
						color: textw,
						fontSize: 16,
						paddingInline: 10,
						paddingBlock: 3,
						borderRadius: 5,
						marginTop: 10,
					}}
				/>
			</View>
		</View>
	)
}

export default HelpListDetailFooter
