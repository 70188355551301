import React, { useContext } from 'react'
import { COLORS } from 'app/constants/STYLES'
import { ListItemContent, ListItemSection } from './styles'
import { CoinLogo } from 'app/components'
import { useHistory } from 'react-router-dom'
import { goTo } from 'app/hooks/navigation'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useInfo24H } from '../../../../hooks/trade/info24H'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import BigNumber from 'bignumber.js'
import { CButton } from 'app/components/Common'
import { useTranslation } from 'react-i18next'

const ListItems = ({ item, index }: any) => {
	const { t } = useTranslation()
	const { isDarkMode, theme } = useContext(ThemeContext)
	const navigation = useHistory()

	const { data: info24H } = useInfo24H(item?.id)
	let { getPrice } = useIndexPrice(item?.id)

	return (
		<ListItemSection
			onClick={() => {
				goTo(navigation, `/trade/${item?.id}`)
			}}>
			<CoinLogo
				src={item?.image}
				size={30}
				style={{
					alignSelf: 'center',
					display: 'flex',
					marginRight: 15,
				}}
			/>
			<ListItemContent>
				<View style={{ alignItems: 'flex-start' }}>
					<Text
						size={14}
						hoverColor={COLORS.primary}
						style={{
							color: isDarkMode ? COLORS.black : COLORS.white,
							marginBottom: 5,
							fontWeight: '500',
							letterSpacing: 0.5,
							textTransform: 'uppercase',
						}}>
						{item?.main_symbol}
					</Text>
					<Text
						size={14}
						style={{
							color: COLORS.lightGray,
						}}>
						{item?.sub_symbol}
					</Text>
				</View>
				<View style={{ alignItems: 'flex-end', flexDirection: 'row' }}>
					<View style={{ alignItems: 'flex-end' }}>
						<View style={{ flexDirection: 'row' }}>
							<Text
								size={12}
								style={{
									color: theme.COLORS.textw,
									fontWeight: '300',
								}}>
								$ {BigNumber(getPrice(item?.id)).toFormat(4)}
							</Text>
						</View>
						<Text
							size={16}
							style={{
								color:
									info24H?.rate >= 0
										? COLORS.lightGreen
										: COLORS.lightRed,
								marginTop: 5,
							}}>
							{BigNumber(info24H?.rate || 0).toFormat(2)}%
						</Text>
					</View>

					<CButton
						title={t('trade')}
						style={{
							marginLeft: 10,
							paddingInline: 12,
							height: 30,
							color: COLORS.white,
							background:
								'linear-gradient(92.49deg, #7438FF 7.72%, #3860FF 100%)',
						}}
					/>
				</View>
			</ListItemContent>
		</ListItemSection>
	)
}

export default ListItems
