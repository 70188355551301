/* eslint-disable eqeqeq */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { useToken } from 'app/hooks'
import { COLORS } from 'app/constants'
import { TRANSFER } from 'app/constants/APIKEYS'
import API from 'utils/api'
import { toast } from 'react-toastify'
import { useBalance } from '../../../hooks/user/mybalance'

const TransferFooter = ({
	transferFrom,
	transferTo,
	amount,
	coinType,
	balance,
}) => {
	const { t } = useTranslation()
	const { data: token } = useToken()
	const { mutate: mutateFrom } = useBalance(coinType, transferFrom)
	const { mutate: mutateTo } = useBalance(coinType, transferTo)

	const type = 'USDT'

	const onTransfer = () => {
		if (amount > 0) {
			API.post(TRANSFER, token + '', {
				transfer_from: transferFrom,
				transfer_to: transferTo,
				coin_type: type,
				amount: amount,
			}).then(res => {
				if (res.result.result == true) {
					toast(t('transferSuccess') + '')
					mutateFrom()
					mutateTo()
				} else {
					toast(t(res?.result?.message) + '')
				}
				console.log(res)
			})
		}
	}
	return (
		<View
			style={{
				width: '100%',
				justifyContent: 'flex-start',
				marginTop: 30,
			}}>
			<CButton
				title={t('confirm')}
				onClick={onTransfer}
				style={{
					width: '100%',
					padding: 12,
					background: COLORS.primary,
					color: COLORS.white,
				}}
			/>
			{transferFrom === 1 ? (
				<Text
					size={10}
					style={{ color: COLORS.lightRed, marginTop: 15 }}>
					{t('transferRedAlert')}
				</Text>
			) : null}

			{/* <CButton
				title={t('toLoan')}
				style={{ color: COLORS.primary, marginTop: 15 }}
				icon={<IconChevronRight size={14} />}
			/> */}
		</View>
	)
}

export default TransferFooter
