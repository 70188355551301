import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ReferralRegisterSection, RefferalSection } from '../styles'

const ReferralRegister = () => {
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	return (
		<RefferalSection>
			<ReferralRegisterSection>
				<View style={{ alignItems: 'flex-start' }}>
					<Text
						size={20}
						style={{ color: COLORS.white, marginBottom: 10 }}>
						{t('referralRoyaleReloaded')}
					</Text>
					<Text size={18} style={{ color: COLORS.lightGray }}>
						{t('referralRoyalReloadedContext')}
					</Text>
				</View>
				<CButton
					title={t('registerNow')}
					style={{
						background: COLORS.secondary,
						color: COLORS.white,
						width: 100,
						height: width < 600 ? 30 : 35,
						alignSelf: width < 600 ? 'flex-start' : 'center',
					}}
				/>
			</ReferralRegisterSection>
		</RefferalSection>
	)
}

export default ReferralRegister
