import React from 'react'
import { CFlatList } from 'app/components/Common'
import SubDropdownListItem from './SubDropdownListItem'
import { SubDropdownBodySection } from './styles'
import { useFetcherPublic } from '../../../../../hooks/fetcher'

const SubDropdownBody = ({ headerTab, types, pairs }) => {
	// header tab for all trending new
	// headerTabs= 'all', 'trending', 'new'
	const { data } = useFetcherPublic('/exchanges/?id=' + (pairs || 1), false)

	const renderItem = (item: any, index: any) => {
		return <SubDropdownListItem item={item} index={index} />
	}

	return (
		<SubDropdownBodySection>
			<CFlatList
				data={data?.result}
				renderItem={renderItem}
				itemSize={50}
			/>
		</SubDropdownBodySection>
	)
}

export default React.memo(SubDropdownBody)
