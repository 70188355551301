import React, { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Text, View } from 'styles/reactnative'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import throttle from 'lodash/throttle' // Lodash 추가
import { COLORS } from '../../../../constants'

const PriceHigh = ({ indexPrice, info24H, setDisplayDrawer, item }) => {
	const navigation = useHistory()
	const { EPs } = useExchangeStore()
	const EP = EPs?.find?.(e => e?.id === item?.id)

	const [throttledPrice, setThrottledPrice] = useState(indexPrice)

	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		throttle(newPrice => {
			// 여기서 가격 업데이트 로직을 실행합니다.
			setThrottledPrice(newPrice) // 쓰로틀된 가격을 상태로 업데이트
		}, 300), // 2초마다 최대 1번 실행
		[] // 의존성 배열 비움
	)
	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(indexPrice)
	}, [indexPrice, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함

	return (
		<View
			onClick={() => {
				goTo(navigation, `/trade/${item?.id}`)
				setDisplayDrawer(false)
			}}
			style={{
				width: '45%',
				flexDirection: 'row',
			}}>
			<Text
				style={{
					width: '40%',
					fontSize: 12,
				}}>
				{BigNumber(throttledPrice).toFormat(EP?.dp || 2)}
			</Text>
			<Text
				style={{
					width: '60%',
					fontSize: 12,
					color:
						info24H?.rate > 0 ? COLORS.lightGreen : COLORS.lightRed,
				}}>
				{BigNumber(info24H?.rate).toFormat(2) + '%'}
			</Text>
		</View>
	)
}

export default React.memo(PriceHigh)
