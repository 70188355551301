import React from 'react'
import { useTranslation } from 'react-i18next'
import { MView, MText } from 'styles/mobilePageStyles'

const MAboutService = () => {
	const { t } = useTranslation()
	return (
		<MView
			style={{
				width: '100%',
				alignItems: 'center',
				marginBlock: 50,
			}}>
			<MText style={{ fontSize: 24 }}>{t('ourServices')}</MText>
			<MView style={{ marginTop: 20, paddingInline: 20 }}>
				<MText
					style={{
						fontSize: 14,
						textAlign: 'center',
						lineHeight: 1.2,
					}}>
					{t('ourServiceContent')}
				</MText>
			</MView>
		</MView>
	)
}

export default MAboutService
