import React, { useContext } from 'react'
import { IconArrowNarrowRight } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { NotificationHeaderSection } from './styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
import { CButton } from 'app/components/Common'

const NotificationHeader = ({ count }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const navigation = useHistory()
	return (
		<NotificationHeaderSection
			onClick={() => {
				navigation.push('/notifications')
			}}>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={16}
					style={{ color: COLORS.primary, marginRight: 10 }}>
					{count}
				</Text>
				<Text size={14}>{t('newNotifications')}</Text>
			</View>
			<CButton
				onClick={() => {
					navigation.push('/notifications')
				}}
				title={t('viewMore')}
				icon={<IconArrowNarrowRight size={14} color={textw} />}
				style={{
					fontSize: 12,
					color: textw,
				}}
			/>
		</NotificationHeaderSection>
	)
}

export default React.memo(NotificationHeader)
