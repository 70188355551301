import { COLORS } from 'app/constants'
import React from 'react'
import { View } from 'styles/reactnative'

import { PercentageFormWrapper } from './styles'

const ConvertPercentage = ({ percentage, setPercentage }) => {
	const data = ['25', '50', '75', '100']
	return (
		<PercentageFormWrapper>
			{data.map((item, index) => (
				<View
					onClick={() => {
						setPercentage(item)
					}}
					key={index.toString()}
					style={{
						background:
							item === percentage
								? `${COLORS.lightGray}55`
								: 'transparent',
						color:
							item === percentage
								? COLORS.secondary
								: COLORS.lightGray,
						cursor: 'pointer',
						padding: 5,
						fontSize: 12,
						width: `${100 / data?.length}%`,
						borderRadius: 5,
					}}>
					{item}%
				</View>
			))}
		</PercentageFormWrapper>
	)
}

export default ConvertPercentage
