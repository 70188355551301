import styled from 'styled-components'

export const AuthButtonSection = styled.div`
	width: 80%;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	padding-left: 40px;
	padding-right: 40px;
	@media (max-width: 900px) {
		width: 100%;
	}
`
