import { IconArrowLeft, IconShare } from '@tabler/icons'
import { CButton, CImage } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MAnnouncementDetailSection } from './styles'
import moment from 'moment'
import { MarkdownComponent } from 'app/components'

const MAnnouncementDetail = ({ show, setShow }) => {
	const { t } = useTranslation()
	const { height } = useWindowDimensions()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MAnnouncementDetailSection>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<MView style={{ width: '10%' }}>
					<CButton
						icon={<IconArrowLeft size={16} color={textw} />}
						onClick={() => {
							setShow({
								...show,
								show: false,
							})
						}}
					/>
				</MView>
				<MView style={{ width: '70%' }}>
					<MText style={{ paddingBottom: 5 }}>{show?.title}</MText>
					<MText style={{ fontWeight: '300', fontSize: 10 }}>
						{moment(show.rdate).format('YYYY-MM-DD')}
					</MText>
				</MView>
				<MView style={{ width: '15%' }}>
					<CButton
						icon={
							<IconShare
								size={16}
								color={textw}
								onClick={() => {
									toast(t('notWorkingNow') + '')
								}}
							/>
						}
					/>
				</MView>
			</MView>
			<MView style={{ marginTop: 10 }}>
				{JSON.parse(show?.images)?.map((item, index) => (
					<CImage
						key={index.toString()}
						src={item}
						style={{ width: '100%', height: height / 3 }}
					/>
				))}
			</MView>
			<MView style={{ marginTop: 10 }}>
				{/* <MText style={{ lineHeight: 2, fontWeight: '300' }}>
					{show?.content}
				</MText> */}
				<MarkdownComponent markdown={show?.content} />
			</MView>
		</MAnnouncementDetailSection>
	)
}

export default MAnnouncementDetail
