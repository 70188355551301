import React, { useRef, useState, useEffect } from 'react'
import SwiperCore, { Navigation, Scrollbar, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
	// ShadowSwiper,
	StyledButton,
	StyledRoot,
	SwiperContainer,
	SwiperHeaderSection,
} from './styles'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons'

import 'swiper/swiper.min.css'
import 'swiper/css'
import 'swiper/css/pagination'

const CSwiper = ({
	data,
	renderItem,
	slidesPerView,
	slidesPerGroup,
	SwiperHeader,
}: any) => {
	const prevRef = useRef(null)
	const nextRef = useRef(null)

	const [swiperSetting, setSwiperSetting] = useState<object | null>(null)
	SwiperCore.use([Navigation, Scrollbar, Pagination])

	const settings = {
		spaceBetween: 20,
		navigation: {
			prevEl: prevRef.current,
			nextEl: nextRef.current,
		},
		scrollbar: { draggable: true, el: null },
		slidesPerView,
		slidesPerGroup,
		allowTouchMove: false,
		slideToClickedSlide: false,
		// pagination: {
		//     // clickable: true,
		//     // renderBullet: function (index: any, className: any) {
		//     //     return (
		//     //         '<span class="' +
		//     //         className +
		//     //         '" style={{padding:10px}}>' +
		//     //         (index + 1) +
		//     //         '</span>'
		//     //     );
		//     // },
		// },
		// loop: true,
		onBeforeInit: (swiper: any) => {
			swiper.params.navigation.prevEl = prevRef.current
			swiper.params.navigation.nextEl = nextRef.current
			swiper.navigation.update()
		},
	}
	useEffect(() => {
		if (!swiperSetting) {
			setSwiperSetting(settings)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swiperSetting])

	return (
		<SwiperContainer>
			<SwiperHeaderSection>{SwiperHeader()}</SwiperHeaderSection>
			<StyledRoot>
				<StyledButton ref={prevRef}>
					<IconChevronLeft size={18} color={'#fff'} />
				</StyledButton>
				{swiperSetting && (
					<Swiper {...settings}>
						{data?.map((item: any, index: any) => (
							<SwiperSlide key={index.toString()}>
								{renderItem(item, index)}
							</SwiperSlide>
						))}
					</Swiper>
				)}

				<StyledButton ref={nextRef}>
					<IconChevronRight size={18} color={'#fff'} />
				</StyledButton>
			</StyledRoot>
		</SwiperContainer>
	)
}

export default React.memo(CSwiper)
