import { IconUserCircle } from '@tabler/icons'
import { CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React from 'react'
import { CSSProperties } from 'styled-components'
import { MView } from 'styles/mobilePageStyles'
interface IProps {
	src?: any
	size?: number
	style?: CSSProperties
	vStyle?: CSSProperties
}
const CProfileImage = ({ src, size, style = {}, vStyle = {} }: IProps) => {
	return (
		<MView style={vStyle}>
			{src ? (
				<CImage
					src={src}
					alt="logo"
					width={size ? size : 24}
					height={size ? size : 24}
					resizeMode={'cover'}
					style={style}
				/>
			) : (
				<MView
					style={{
						width: size ? size : 24,
						height: size ? size : 24,
						background: COLORS.secondary,
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 50,
					}}>
					<IconUserCircle size={18} color={COLORS.white} />
				</MView>
			)}
		</MView>
	)
}

export default CProfileImage
