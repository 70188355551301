import { IconChevronDown, IconCircleCheck, IconId } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { MButton } from 'app/mobile/components'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const VerificationList = ({ level, show, setShow }: any) => {
	const { t } = useTranslation()
	const { cardOpacity, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView
			style={{
				width: '100%',
				background: cardOpacity,
				paddingBlock: 10,
				paddingInline: 10,
				borderRadius: 5,
				marginBottom: 10,
			}}>
			<MView
				onClick={() => {
					setShow && setShow(!show)
				}}
				style={{
					width: '100%',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					cursor: 'pointer',
				}}>
				<MText>Lv.{level}</MText>
				{level === 1 ? (
					<MView
						style={{ flexDirection: 'row', alignItems: 'center' }}>
						<IconCircleCheck size={14} color={COLORS.lightGreen} />
						<MText style={{ marginLeft: 5 }}>{t('verified')}</MText>
					</MView>
				) : (
					<IconChevronDown size={16} color={textw} />
				)}
			</MView>
			{show || show === undefined ? (
				<MView style={{ width: '100%' }}>
					<MText
						style={{
							fontSize: 14,
							fontWeight: '300',
							marginTop: 10,
							marginBottom: 15,
						}}>
						{t('benefits')}
					</MText>
					<MView
						style={{
							width: '100%',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingBottom: 15,
						}}>
						<MText>{t('p2pTrading')}</MText>
						<MText style={{ fontWeight: '300' }}>
							{t('noLimit')}
						</MText>
					</MView>
					<MView
						style={{
							width: '100%',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingBottom: 15,
						}}>
						<MText>{t('fiatDeposit')}</MText>
						<MText style={{ fontWeight: '300' }}>$20k Daily</MText>
					</MView>
					<MView
						style={{
							width: '100%',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingBottom: 15,
						}}>
						<MText>{t('dailyWithdrawal')}</MText>
						<MText style={{ fontWeight: '300' }}>
							Daily 1,000,000 USDT
						</MText>
					</MView>
					<MView
						style={{
							width: '100%',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingBottom: 15,
						}}>
						<MText>{t('p2pTrading')}</MText>
						<MText style={{ fontWeight: '300' }}>
							{t('moreEventBonuses')}
						</MText>
					</MView>
					<MView>
						<MText>{t('requirements')}</MText>
						<MView
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: 5,
							}}>
							<IconId size={14} color={COLORS.secondary} />
							<MText style={{ marginLeft: 5 }}>ID</MText>
						</MView>
					</MView>
					{show !== undefined ? (
						<MView style={{ width: '100%' }}>
							<MButton
								title={'verifyNow'}
								onClick={() => {
									toast(t('notWorkingNow') + '')
								}}
								style={{
									width: '100%',
									padding: 7,
									background: COLORS.primary,
									marginTop: 5,
									borderRadius: 5,
								}}
								textStyle={{ color: COLORS.white }}
							/>
						</MView>
					) : null}
				</MView>
			) : null}
		</MView>
	)
}

export default VerificationList
