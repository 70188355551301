import styled from 'styled-components'

export const FeeTableItems = styled.div`
	display: flex;
	width: 300px;
	align-items: center;
	font-size: 16px;
	color: ${props => props.theme.COLORS.textsilver};
`
export const FeeTableSectionContainer = styled.div`
	background-color: rgba(255, 255, 255, 0.1);
	width: 100%;
	overflow-x: auto !important;
	padding: 15px;
`
