import { useEffect, useState } from 'react'
import { STORE_SEARCH_HISTORY } from 'app/constants/LOCALKEYS'

const KEY = STORE_SEARCH_HISTORY

export function useSearchHistory(searchComponent?: boolean) {
	const [data, setData] = useState<any>([])

	useEffect(() => {
		function fetchData() {
			try {
				const cachedData = localStorage.getItem(KEY)
				if (cachedData) {
					setData(JSON.parse(cachedData))
				}
			} catch (error) {
				console.log('Error fetching data from storage:', error)
			}
		}
		fetchData()
	}, [])

	const addToHistory = (item: any) => {
		try {
			const newData = [item, ...data.filter(({ id }) => id !== item.id)]
			localStorage.setItem(KEY, JSON.stringify(newData))
			setData(newData)
		} catch (error) {
			console.log('Error saving data to storage:', error)
		}
	}

	const clearHistory = () => {
		try {
			localStorage.removeItem(KEY)
			setData([])
			console.log('Cleared search history')
		} catch (error) {
			console.log('Error clearing data from storage:', error)
		}
	}
	if (searchComponent) {
		return { data: data, addToHistory, clearHistory }
	}

	return { data: data }
}
