import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import { OrderBookHeaderSection, Price, Quantity, Total } from './styles'

const OrderBookHeader = () => {
	const { t } = useTranslation()

	const { data: store } = useTradeStore()

	const current = store?.pairs?.split('/')

	const color = COLORS.lightGray
	return (
		<OrderBookHeaderSection>
			<Price style={{ color, fontSize: '1.1rem' }}>
				{t('price')} ({current?.[1]})
			</Price>
			<Price style={{ color, marginLeft: 10, fontSize: '1.1rem' }}>
				{t('amount')} ({current?.[0]})
			</Price>
			<Total style={{ color, fontSize: '1.1rem' }}>
				{t('total')} ({current?.[0]})
			</Total>
		</OrderBookHeaderSection>
	)
}

export default OrderBookHeader
