import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const TradingFeeTableHeader = ({ title, level }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				flexDirection: 'row',
				paddingInline: 16,
			}}>
			<Text style={{ color: textw, fontSize: 18 }}>{t(title)}</Text>
			<CButton
				title={`VIP ${level}`}
				style={{
					color: textw,
					paddingInline: 7,
					paddingBlock: 3,
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: COLORS.primary,
					borderRadius: 5,
					marginLeft: 20,
				}}
			/>
		</View>
	)
}

export default TradingFeeTableHeader
