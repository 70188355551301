import { CImage } from 'app/components/Common'
import React, { useContext } from 'react'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import announcement from 'assets/images/announcement.png'
import { useTranslation } from 'react-i18next'
const SpecialDerivativeItem = ({ item, index }) => {
	const { t } = useTranslation()
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<MView
			style={{
				background: 'rgba(255, 255, 255, 0.1)',
				width: '100%',
				height: 250,
				marginBottom: 10,
				borderRadius: 10,
				justifyContent: 'center',
				alignItems: 'center',
				padding: 20,
			}}>
			<MView
				style={{
					width: '100%',
					height: '40%',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<CImage
					src={announcement}
					style={{ width: 100, height: 100 }}
					resizeMode="cover"
				/>
			</MView>
			<MView style={{ flexDirection: 'row', alignItems: 'center' }}>
				<MText
					style={{
						color: textsilver,
						fontSize: 16,
						marginRight: 10,
						lineHeight: 2,
					}}>
					0{index + 1}
				</MText>
				<MText style={{ color: textw, fontSize: 16, lineHeight: 2 }}>
					{t(item.title)}
				</MText>
			</MView>
			<MView style={{ marginTop: 10, paddingInline: 35 }}>
				<MText style={{ color: textw, fontSize: 14, lineHeight: 1.5 }}>
					{t(item.content)}
				</MText>
			</MView>
		</MView>
	)
}

export default SpecialDerivativeItem
