import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import HistoryChartTable from './StatisticHistoryChart/HistoryChartTable'
import HistoryPieChart from './StatisticHistoryChart/HistoryPieChart'
import StatisticHistoryHeader from './StatisticHistoryHeader'
const StatisticHistory = () => {
	const [selectByDay, setSelectByDay] = useState('7 Days')

	const dayLists = ['7 Days', '30 Days', '90 Days', '180 Days']
	return (
		<View
			style={{
				width: '100%',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				height: 550,
			}}>
			<StatisticHistoryHeader
				dayLists={dayLists}
				selectByDay={selectByDay}
				setSelectByDay={setSelectByDay}
			/>
			<HistoryPieChart />
			<HistoryChartTable />
		</View>
	)
}

export default React.memo(StatisticHistory)
