import React from 'react'
import { Text, View } from 'styles/reactnative'
import { CImage } from 'app/components/Common'
import { TransferCoinListItems, TransferCoinListsWrapper } from '../styles'
import { useFetcher } from 'app/hooks/fetcher'

const TransferCoinList = ({ coinDetail, setCoinDetail, setDisplay }) => {
	const { data } = useFetcher('/exchanges/?id=1')

	return (
		<TransferCoinListsWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					width: '100%',
					height: '100%',
				}}>
				{data?.result?.map((item: any, index) => (
					<TransferCoinListItems
						onClick={() => {
							setCoinDetail(item)
							setDisplay(false)
						}}
						key={index.toString()}>
						<View style={{ flexDirection: 'row' }}>
							<CImage
								src={item?.image}
								alt="logo"
								width={20}
								height={20}
								resizeMode={'cover'}
								style={{}}
							/>
							<Text
								size={14}
								style={{
									marginLeft: 5,
								}}>
								{item?.name}
							</Text>
						</View>
						<Text size={14}>{item?.symbol}</Text>
					</TransferCoinListItems>
				))}
			</View>
		</TransferCoinListsWrapper>
	)
}

export default TransferCoinList
