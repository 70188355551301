import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'

const ReservesDetailItems = ({ item, data }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ width: '100%', flexDirection: 'row', marginBottom: 30 }}>
			<Text size={14} style={{ color: textw, width: `${100 / 4}%` }}>
				{t('level')}
				{'  '}
				{item.level}
			</Text>
			<Text
				size={14}
				style={{
					color: textw,
					width: `${100 / 4}%`,
				}}>
				{item.tradeok ? t('available') : t('X')}
			</Text>
			<Text
				size={14}
				style={{
					color: textw,
					width: `${100 / 4}%`,
				}}>
				{item.maker_fee}%
			</Text>
			<Text
				size={14}
				style={{
					color: textw,
					width: `${100 / 4}%`,
				}}>
				{item.taker_fee}%
			</Text>
		</View>
	)
}

export default ReservesDetailItems
