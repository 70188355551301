import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { ReferralTableSection } from '../styles'
import ReferralTableHeader from './ReferralTableHeader'
import ReferralTableList from './ReferralTableList'

const ReferralTableContainer = ({ title, data, menuList, tab, setTab }) => {
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<ReferralTableSection
			style={{ background: isDarkMode ? COLORS.white : COLORS.black }}>
			<ReferralTableHeader
				title={title}
				menuList={menuList}
				tab={tab}
				setTab={setTab}
			/>
			<ReferralTableList data={data} />
		</ReferralTableSection>
	)
}

export default ReferralTableContainer
