import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../../../../../../theme/ThemeProvider'
import { CInput } from '../../../../../../components/Common'
import {
	SliderBar,
	SliderButton,
	SliderButtonContainer,
	CrossTradeAmount,
	CrossModalLeverage,
} from './styles'

const CrossSliderBar = ({ leverage, setLeverage, maxLeverage }) => {
	const { t } = useTranslation()
	const sliderBarRef = useRef<any>(null)
	const sliderButtonRef = useRef<any>(null)
	const STEP = 1

	const { theme } = useContext(ThemeContext)

	const handleClick = newValue => {
		setLeverage(newValue)
	}

	const handleSliderChange = event => {
		const newValue = parseFloat(event.target.value)
		if (newValue > 0) {
			setLeverage(newValue)
		}
	}

	const handleSliderMouseDown = event => {
		const newValue = parseFloat(event.target.value)
		if (newValue > 0) {
			setLeverage(newValue)
		}
	}

	const buttonIncrement = 25
	const numButtons = Math.floor(maxLeverage / buttonIncrement)

	const buttons = Array.from({ length: numButtons }, (_, i) => {
		const leverage = (i + 1) * buttonIncrement
		return (
			<SliderButton
				key={i.toString()}
				ref={sliderButtonRef}
				onClick={() => handleClick(leverage)}>
				{leverage}x
			</SliderButton>
		)
	})
	return (
		<View
			style={{
				alignItems: 'flex-start',
				width: '100%',
			}}>
			<CrossModalLeverage>
				<Text size={14}>{t('leverage')}</Text>
				<CrossTradeAmount>
					<CInput
						value={leverage + 'x'}
						onChange={handleSliderChange}
						onInputClick={event => {
							event.target.focus({ cursor: 0 })
						}}
						onFocus={event => {
							event.target.focus({ cursor: 0 })
						}}
						type={''}
						placeholder={''}
						style={{
							color: theme.COLORS.textw,
							background: 'transparent',
							fontWeight: 600,
							fontSize: 22,
							border: 0,
							textAlign: 'center',
						}}
					/>
				</CrossTradeAmount>
			</CrossModalLeverage>

			<SliderBar
				max={maxLeverage}
				ref={sliderBarRef}
				value={leverage}
				maxLeverage={maxLeverage}
				onChange={handleSliderChange}
				onMouseUp={() => handleClick(leverage)}
				onMouseDown={handleSliderMouseDown}
				step={STEP}
			/>

			<SliderButtonContainer>
				<SliderButton
					ref={sliderButtonRef}
					onClick={() => handleClick(1)}>
					1x
				</SliderButton>
				{buttons}
			</SliderButtonContainer>
		</View>
	)
}

export default CrossSliderBar
