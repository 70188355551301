import React, { useEffect, useRef, useState } from 'react'
import { View } from 'styles/reactnative'
import { TradeRealTimeContainer } from './styles'
import TradeRealTimeTabs from './TradeRealTimeTopTabs'

import OrderBook from './OrderBook'
import TradeRealTimeFilter from './TradeRealTimeFilter'

import RecentTrade from './RecentTrade'
import RecentTradeNavBar from './RecentTradeNavBar'
import { FloatBtnSection3 } from '../../../components/CFloatBtns/styles'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useWindowDimensions } from '../../../hooks'
import { IconArrowsMove } from '@tabler/icons'
import useTradeStore from '../../../hooks/trade/tradeStore'

const TradeRealTime = ({ drag }) => {
	const [topTab, setTopTab] = useState('orderBook')
	const [filterTab, setFilterTab] = useState('one')
	const { width: w, height: h } = useWindowDimensions()
	const { data: tradeStore } = useTradeStore()

	const orderBook = topTab === 'orderBook'

	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()

	const [height, setHeight] = useState(500)

	const parentRef = useRef<any>(null)

	useEffect(() => {
		if (parentRef.current) {
			if (parentRef.current?.clientHeight != height) {
				setTimeout(() => {
					setHeight(parentRef.current?.clientHeight - 100)
				}, 100)
				setTimeout(() => {
					setHeight(parentRef.current?.clientHeight - 100)
				}, 500)
			}
		}
	}, [w, h, parentRef.current?.clientHeight, drag])


	
	const grouplist = tradeStore?.dp == 1 ? [100, 10, 1] : tradeStore?.dp == 2 ? [10, 1] : [1]

	for (let i = 1; i <= tradeStore?.dp; i++) {
		const value = parseFloat((0.1 ** i).toFixed(i))
		grouplist.push(value === 0 ? 1 : value)
	}

	const [selected, setSelected] = useState(grouplist[grouplist?.length - 1])

	useEffect(() => {
		setSelected(grouplist[grouplist?.length - 1])
	}, [tradeStore?.dp2, tradeStore?.ep_id])

	return (
		<TradeRealTimeContainer ref={parentRef}>
			<FloatBtnSection3
				style={{ marginRight: 20, marginTop: 10 }}
				className={'drag-handle'}>
				<IconArrowsMove />
			</FloatBtnSection3>
			<FloatBtnSection3
				className="widgetClose"
				style={{ marginTop: 10 }}
				onClick={() => {
					muateTradeModalStore({
						...tradeModalStore,
						orderbookshow: tradeModalStore?.orderbookshow
							? false
							: true,
					})
				}}>
				X
			</FloatBtnSection3>
			<View style={{ width: '100%', height: 65 }}>
				<TradeRealTimeFilter
					filterTab={filterTab}
					setFilterTab={setFilterTab}
					grouplist={grouplist}
					selected={selected}
					setSelected={setSelected}
				/>
			</View>
			<View
				style={{
					width: '100%',
					flex: 1,
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<OrderBook
					orderBook={orderBook}
					filterTab={filterTab}
					height={height}
					selected={selected}
				/>
			</View>
		</TradeRealTimeContainer>
	)
}

export default React.memo(TradeRealTime)
