import React, { useState } from 'react'

import OrderContainer from './OrderContainer'
import OrderSubTabs from '../OrderSubTabs'
import { DerivativeSection } from './styles'

const Derivatives = () => {
	// Container Header Tabs
	const orderHistory = 'orderhistory'
	const closedpl = 'closedpnl'
	const tradeHistory = 'HASPOSITIONS'
	const [tab, setTab] = useState(orderHistory)
	const data = [orderHistory, closedpl, tradeHistory]

	// Container Tabs
	const usdtPerpetual = 'usdtPerpetual'
	const [cTab, setCTab] = useState(usdtPerpetual)
	const cTabs = [usdtPerpetual]

	return (
		<DerivativeSection>
			<OrderSubTabs data={data} tab={tab} setTab={setTab} />
			<OrderContainer cTab={tab} setCTab={setCTab} cTabs={cTabs} />
		</DerivativeSection>
	)
}

export default Derivatives
