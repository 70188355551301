import React from 'react'

import { COLORS } from 'app/constants'
import CoinRate from 'app/components/CoinRate'
import { View, Text } from 'styles/reactnative'

const CurrentTradesTableBody = ({ data }: any) => {
	return (
		<View style={{ width: '100%' }}>
			{data.map((item: any, index: any) => (
				<View
					style={{
						flexDirection: 'row',
						width: '100%',
						justifyContent: 'space-between',

						padding: 5,
					}}
					key={index.toString()}>
					<View style={{ width: '20%', alignItems: 'flex-start' }}>
						<Text size={12}>{item?.positions}</Text>
						<CoinRate type={'Long'} times={'10'} />
					</View>
					<View style={{ width: '15%', alignItems: 'flex-start' }}>
						<Text size={12}>{item?.entryPrice}</Text>
					</View>
					<View style={{ width: '15%', alignItems: 'flex-start' }}>
						<Text size={12}>{item?.currentPositions}</Text>
					</View>
					<View style={{ width: '10%', alignItems: 'flex-start' }}>
						<Text size={12}>{item?.orderQty}</Text>
					</View>
					<View style={{ width: '15%', alignItems: 'flex-start' }}>
						<Text size={12} style={{ color: COLORS.darkRed }}>
							{item?.unrealizedp} USDT ({item?.unrealizedl})
						</Text>
					</View>
					<View style={{ width: '15%', alignItems: 'flex-start' }}>
						<Text size={12}>{item?.openedTime}</Text>
					</View>
					<View style={{ width: '10%', alignItems: 'flex-end' }}>
						<Text size={12}>{item?.tpsl}</Text>
					</View>
				</View>
			))}
		</View>
	)
}

export default React.memo(CurrentTradesTableBody)
