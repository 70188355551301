import React, { useState } from 'react'
import { CFlatList } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import { MView } from 'styles/mobilePageStyles'
import MAnnouncementItems from '../AnnouncementItems'
import { useFetcherPublic } from 'app/hooks/fetcher'

const MProductAnnouncements = ({ show, setShow }) => {
	const { height } = useWindowDimensions()
	const [page, setPage] = useState(1)
	const { data } = useFetcherPublic('/notice/list?page=' + page)

	const renderItem = (item: any, index: any) => (
		<MAnnouncementItems item={item} show={show} setShow={setShow} />
	)
	return (
		<MView
			style={{
				width: '100%',
				height: height - 85,
				paddingTop: 10,
			}}>
			<CFlatList
				data={data?.result?.list?.length ? data?.result?.list : []}
				renderItem={renderItem}
				noDataTitle={'announcementNoData'}
				itemSize={50}
			/>
		</MView>
	)
}

export default MProductAnnouncements
