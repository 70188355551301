import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'
export const MobileSearchSection = styled.div<{ isDarkMode?: any }>`
	flex: 1;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	background-color: ${props => props.theme.COLORS.background};
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`
export const MobileHeaderSearch = styled.div`
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
`
export const SearchInput = styled.div`
	flex: 1;
	width: 100%;
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	&.inputWithIcon {
		position: relative;
	}

	.left-icon {
		position: absolute;
		left: 10px;
		top: 37%;
		transform: translateY(-37%);
	}
	.right-icon {
		width: 15px;
		height: 15px;
		position: absolute;
		right: 10px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 37%;
		padding-bottom: 1px;
		padding-right: 0px;
		transform: translateY(-37%);
		background-color: ${p => p.theme.COLORS.borderColor};
		border-radius: 50px;
	}
`
