import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	title?: string
	content?: string
}
const SupportTitle = ({ title, content }: IProps) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
			<Text size={30} style={{ color: textw }}>
				{t(title + '')}
			</Text>
			{content ? (
				<View style={{ width: '100%', alignItems: 'flex-start' }}>
					<Text
						size={16}
						style={{
							color: textw,
							fontWeight: '300',
							marginTop: 20,
							lineHeight: 1.5,
						}}>
						{t(content + '')}
					</Text>
				</View>
			) : null}
		</View>
	)
}

export default SupportTitle
