import { getSetting, useSetting } from './user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../constants/SETTING'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

export function useTimezone(
	date: Date,
	format: string = 'YYYY-MM-DD HH:mm:ss',
	offset: number = 0
) {
	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const { i18n } = useTranslation()
	const lang = i18n?.language

	const timezone = getSetting(setting, 'TIMEZONE', 'Asia/Seoul')

	const result = moment(date).utc().tz(timezone).add(offset, 'hours')
	return result.format(format)
}
