import React from 'react'
import SubFooterList from 'app/components/SubFooter'
import { SubFooterContainer, SubFooterWrapper } from './styles'

const SubFooter = () => {
	return (
		<SubFooterContainer>
			<SubFooterWrapper>
				<SubFooterList />
			</SubFooterWrapper>
		</SubFooterContainer>
	)
}

export default React.memo(SubFooter)
