import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'theme/ThemeProvider'
import { HeaderAssetSection } from './styles'

const HeaderOrders = () => {
	const navigation = useHistory()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<HeaderAssetSection>
			<CButton
				title={t('usdtPerp')}
				onClick={() => {
					navigation.push('orders?tab=1')
				}}
				textHover={COLORS.secondary}
				// backgroundHover={'blue'}
				style={{
					color: textw,
					marginBottom: 10,
					width: '100%',
					paddingBlock: 10,
					alignItems: 'flex-start',
				}}
			/>
		</HeaderAssetSection>
	)
}

export default HeaderOrders
