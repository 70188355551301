import React, { useContext } from 'react'
import { HelpCenterHeaderSection } from './styles'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from 'app/constants'
import { Input } from 'antd'
import { IconSearch } from '@tabler/icons'

const HelpCenterHeader = ({ search, setSearch }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<HelpCenterHeaderSection>
			<View style={{}}>
				<Text size={18} style={{ color: COLORS.secondary }}>
					{t('total')} 7
				</Text>
			</View>
			<View>
				<Input
					size="middle"
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={t('searchFaq') + ''}
					style={{
						backgroundColor: 'transparent',
						paddingLeft: 30,
						color: textw,
					}}
				/>
				<View
					style={{
						background: 'transparent',
						position: 'absolute',
						marginLeft: -160,
					}}>
					<IconSearch size={14} color={textw} />
				</View>
			</View>
		</HelpCenterHeaderSection>
	)
}

export default HelpCenterHeader
