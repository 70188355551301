import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconTrash } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import SearchHistoryList from './SearchHistoryList'
import { MSearchHistorySection } from './styles'
import { useSearchHistory } from 'app/hooks/stateStore'

const MSearchHistory = ({ setOpenSearch }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { clearHistory } = useSearchHistory(true)

	return (
		<MSearchHistorySection>
			<MView
				style={{
					height: 30,
					justifyContent: 'space-between',
					marginBottom: 5,
					flexDirection: 'row',
					alignItems: 'center',
					paddingInline: 16,
				}}>
				<MText style={{ color: textw, letterSpacing: 1 }}>
					{t('searchHistory')}
				</MText>
				<CButton
					icon={<IconTrash size={16} color={textw} />}
					style={{ paddingBlock: 5 }}
					onClick={() => {
						clearHistory && clearHistory()
						setOpenSearch && setOpenSearch(false)
						setTimeout(() => {
							setOpenSearch(true)
						}, 0)
					}}
				/>
			</MView>
			<SearchHistoryList setOpenSearch={setOpenSearch} />
		</MSearchHistorySection>
	)
}

export default MSearchHistory
