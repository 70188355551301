import { IconHourglassLow } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const IdentifyBody = ({ level, last }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				height: '90%',
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
			}}>
			<View style={{ alignItems: 'flex-start', padding: 15 }}>
				<View
					style={{
						borderLeft: `2px solid ${COLORS.secondary}`,
						paddingLeft: 5,
					}}>
					<Text>{t('requirements')}</Text>
				</View>
				<View style={{ flexDirection: 'row', marginTop: 10 }}>
					{level?.map((item: any, index: any) => (
						<View
							style={{
								flexDirection: 'row',
								marginRight: 20,
								borderRight:
									level?.length - 1 > index
										? `2px solid ${textw}`
										: 0,
								paddingRight: 10,
							}}
							key={index.toString()}>
							{item?.icon}
							<Text style={{ marginLeft: 5 }}>
								{t(item?.title)}
							</Text>
						</View>
					))}
				</View>
				<View
					style={{
						alignItems: 'center',
						flexDirection: 'row',
						marginTop: 30,
					}}>
					<IconHourglassLow size={18} color={textw} />
					<Text size={14} style={{ marginLeft: 10 }}>
						{t('reviewDuration')} (Est. 1H)
					</Text>
				</View>
			</View>
			{last ? (
				<CButton
					title={t('verifyNow')}
					onClick={() => {
						toast(t('notWorkingNow') + '')
					}}
					style={{
						width: '100%',
						height: 35,
						background: COLORS.primary,
						color: COLORS.white,
						borderRadius: 10,
					}}
				/>
			) : null}
		</View>
	)
}

export default IdentifyBody
