import React from 'react'
import { useTranslation } from 'react-i18next'
import { AssetsTitleSection } from './styles'

const AssetsTitle = ({ title }) => {
	const { t } = useTranslation()
	return <AssetsTitleSection>{t(title)}</AssetsTitleSection>
}

export default AssetsTitle
