import styled from 'styled-components'

export const MSearchHistorySection = styled.div`
	height: 160px;
	width: 100%;
`

export const SearchHistoryListSection = styled.div`
	height: 125px;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 4px;
	::-webkit-scrollbar {
		display: none;
	}
`

export const SearchHistoryItems = styled.div`
	flex: 0 0 134px;
	margin-right: 10px;
	padding: 10px;
	height: 100%;
	background: ${p => p.theme.COLORS.cardOpacity};
	box-shadow: 2px 2px 5px ${p => p.theme.COLORS.cardOpacity};
	border-radius: 5px;
`
