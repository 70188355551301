import React from 'react'
import TheBitCardItems from './TheBitCardItems'
import { TheBitCardListSection } from './styles'

const TheBitCardList = ({ data, openModal, images=[] }) => {
	return (
		<TheBitCardListSection>
			{data?.map((item, index) => (
				<TheBitCardItems
					key={index.toString()}
					item={item}
					image={images[index]}
					index={index}
					openModal={openModal}
				/>
			))}
		</TheBitCardListSection>
	)
}

export default TheBitCardList
