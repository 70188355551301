import styled from 'styled-components'

export const CryptoCurrencyTrendSection = styled.div`
	width: 100%;
	height: 412px;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	border-radius: 20px;
	box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	padding-bottom: 10px;
	@media (max-width: 600px) {
		height: 512px;
	}
	@media (max-width: 900px) {
		height: 462px;
	}
`
export const CryptoCurrencyTrendHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-inline: 30px;
	padding-block: 20px;
`
export const CryptoCurrencyTrendFooterSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	@media (max-width: 900px) {
		justify-content: flex-start;
	}
`
