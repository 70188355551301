import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { NormalDropdown } from 'app/components/Common/'
import { ThemeContext } from 'theme/ThemeProvider'

const TraderDetailDropdowns = ({ selected, setSelected, data }: any) => {
	const { textsilver, bgColor, borderColor, textw } =
		useContext(ThemeContext).theme.COLORS
	return (
		<NormalDropdown
			setSelected={setSelected}
			selected={selected}
			title={selected}
			data={data}
			style={{
				background: bgColor,
				color: COLORS.lightGray1,
				marginLeft: -10,
			}}
			btnStyle={{
				borderColor: borderColor,
				borderWidth: 1,
				color: textw,
				marginLeft: 10,
			}}
			borderColor={borderColor}
			bgHover={COLORS.borderColor}
			boxShadow={`1px 2px 5px ${textsilver}`}
		/>
	)
}

export default React.memo(TraderDetailDropdowns)
