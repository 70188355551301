import React, { useState } from 'react'
import SubDropdownLists from '../SubDropdownLists'

import { UsdtContent } from './styles'

const UsdtPerpetualTrading = () => {
	const [headerTab, setHeaderTab] = useState('all')
	const types = ['all', 'trending', 'new']

	return (
		<UsdtContent>
			<SubDropdownLists
				headerTab={headerTab}
				setHeaderTab={setHeaderTab}
				types={types}
			/>
		</UsdtContent>
	)
}

export default UsdtPerpetualTrading
