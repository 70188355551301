import { CImage } from 'app/components/Common'
import { IMAGES } from 'app/constants'
import React from 'react'
import { View } from 'styles/reactnative'

const TraderProfileImage = () => {
	return (
		<View>
			<CImage
				src={IMAGES.eventImage}
				alt="logo"
				width={128}
				height={128}
				resizeMode={'cover'}
				style={{
					background: 'red',
					borderRadius: 150,
				}}
			/>
		</View>
	)
}

export default TraderProfileImage
