import React from 'react'
import {
	TraderDetailSection,
	SectionWrap,
	TraderDetailBackground,
} from './styles'
import TraderDetailRightSide from './TraderDetailRightSide'
import TraderDetailLeftSide from './TraderDetailLeftSide'

const TraderDetailEvent = () => {
	return (
		<TraderDetailSection>
			<SectionWrap>
				<TraderDetailBackground />
				<TraderDetailLeftSide />
				<TraderDetailRightSide />
			</SectionWrap>
		</TraderDetailSection>
	)
}

export default React.memo(TraderDetailEvent)
