import React, { useContext } from 'react'
import { IconChevronDown } from '@tabler/icons'
import { useTranslation } from 'react-i18next'

import { OrderValueInputSection } from '../styles'
import { CButton, CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ThemeContext } from 'theme/ThemeProvider'
import BigNumber from 'bignumber.js'
import { useTradeSetting } from '../../../../../../hooks/trade/tradeSetting'

const OrderValueInput = () => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const { data: settings } = useTradeSetting(tradeStore?.ep_id)

	if (tradeStore?.order_amount === undefined) {
		mutateTradeStore({ ...tradeStore, order_amount: 0 })
	}
	const setOrderAmount = data => {
		if (isNaN(data)) return
		mutateTradeStore({ ...tradeStore, order_amount: data })
	}

	return (
		<OrderValueInputSection>
			<CButton
				title={t('orderValue')}
				onClick={() => {
					mutateModalStore({
						...modalStore,
						orderPlace: true,
					})
				}}
				style={{
					alignSelf: 'flex-start',
					marginLeft: -5,
					fontSize: 13,
					color: textw,
				}}
				icon={
					<IconChevronDown
						size={14}
						color={COLORS.lightGray5}
						style={{ marginLeft: 5 }}
					/>
				}
			/>
			<CInput
				placeholder={''}
				value={tradeStore?.order_amount}
				type={'text'}
				onChange={(e: any) => setOrderAmount(e.target.value)}
				vStyle={{ padding: 0, flexDirection: 'row', width: '100%' }}
				style={{
					background: gray,
					border: 'none',
					marginTop: 5,
					color: textw,
					padding: 10,
					width: '100%',
					paddingRight: 60,
				}}
			/>
			<View style={{ position: 'absolute', right: 30, marginTop: 35 }}>
				<Text style={{ color: textw }}>{tradeStore?.coin_type}</Text>
			</View>
		</OrderValueInputSection>
	)
}

export default OrderValueInput
