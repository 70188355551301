import React, { useContext } from 'react'
import { COLORS } from 'app/constants/STYLES'
import { TradingListHeaderSection } from './styles'
import { Text } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
import { CImage } from 'app/components/Common'
import fire from 'assets/icons/fire.png'
import listingStar from 'assets/icons/listingStar.png'
import thumpup from 'assets/icons/thumpup.png'
import { useWindowDimensions } from 'app/hooks'

const TradingListHeader = ({ item, index }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	const wSize = width > 1000 ? (index === 1 ? 45 : 50) : 50
	return (
		<TradingListHeaderSection
			style={{
				background: isDarkMode ? COLORS.white : '#343140',
			}}>
			<CImage
				src={index === 0 ? fire : index === 1 ? thumpup : listingStar}
				style={{ width: wSize, height: wSize }}
			/>
			<Text
				size={25}
				style={{
					color: textw,
					justifyContent: 'flex-start',
					marginLeft: 10,
				}}>
				{t(item?.title)}
			</Text>
		</TradingListHeaderSection>
	)
}

export default TradingListHeader
