import React, { useContext, useState } from 'react'
import { CButton, CImage, NormalDropdown } from 'app/components/Common/'
import { TradeRealTimeFilterSection } from './OrderBook/styles'
import { View } from 'styles/reactnative'
import { COLORS, IMAGES } from 'app/constants/'
import { ThemeContext } from 'theme/ThemeProvider'
// import { NormalDropdown } from 'app/components/Common/'

const TradeRealTimeFilter = ({ filterTab, setFilterTab, grouplist, selected, setSelected }: any) => {
	const { isDarkMode } = useContext(ThemeContext)
	const lightDefault = IMAGES.lightDefault
	const lightAsk = IMAGES.lightAsk
	const lightBid = IMAGES.lightBid
	const darkDefault = IMAGES.darkDefault
	const darkAsk = IMAGES.darkAsk
	const darkBid = IMAGES.darkBid
	const data = [
		{ name: 'one', image: isDarkMode ? lightDefault : darkDefault },
		{ name: 'two', image: isDarkMode ? lightBid : darkBid },
		{ name: 'three', image: isDarkMode ? lightAsk : darkAsk },
	]

	return (
		<TradeRealTimeFilterSection>
			<View style={{ flexDirection: 'row' }}>
				{data.map((item, index) => (
					<CButton
						key={index.toString()}
						onClick={() => {
							setFilterTab(item.name)
						}}
						backgroundHover={COLORS.gray}
						style={{
							backgroundColor:
								item.name === filterTab
									? COLORS.secondary
									: 'transparent',
							padding: 1,
							marginRight: 10,
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
						}}
						icon={
							<CImage
								src={item.image}
								alt="logo"
								width={24}
								height={20}
								resizeMode={'cover'}
								style={{ borderRadius: 3 }}
							/>
						}
					/>
				))}
			</View>
{/* 			
			<NormalDropdown
				title={selected}
				setSelected={setSelected}
				selected={selected}
				data={grouplist}
				style={{
					marginLeft: -50,
				}}
				btnStyle={{
					backgroundColor: COLORS.gray,
					paddingBlock: 2,
					marginTop: 5,
				}}
			/> */}
		</TradeRealTimeFilterSection>
	)
}

export default React.memo(TradeRealTimeFilter)
