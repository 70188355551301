import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../../../styles/reactnative'
import { COLORS } from '../../../../constants'
import { useTimezone } from '../../../../hooks/timezone'
import { TradeDetailListItemSection, TradeText } from './styles'
import KrwPrice from '../../../../components/KrwPrice'

const TradeDetailListItem = ({ item, index, dataType }: any) => {
	//const coin = item?.pairs?.split('/')[0]
	const currency = item?.pairs?.split('/')[1]
	const { t } = useTranslation()
	return (
		<TradeDetailListItemSection>
			<TradeText style={{ flexDirection: 'column' }}>
				<Text>{item?.pairs}</Text>
				<Text style={{ color: 'orange' }}>
					{item?.cross_iso === 'C' ? t('cross') : t('iso')}{' '}
					{item?.leverage}X
				</Text>
			</TradeText>
			<TradeText style={{ width: 200 }}>
				{BigNumber(item?.amount)
					.multipliedBy(item?.leverage)
					.toFormat(3)}
			</TradeText>
			<TradeText style={{ width: 200 }}>
				{item?.trade_price || item?.order_price} {currency}
			</TradeText>
			<TradeText>
				{item?.limit_market === 'L' ? t('limit') : t('market')}
			</TradeText>
			<TradeText
				style={{
					color:
						item?.long_short === 'L'
							? COLORS.lightGreen
							: COLORS.lightRed,
				}}>
				{item?.long_short === 'L' ? t('long') : t('short')}
			</TradeText>
			<TradeText style={{ width: 200 }}>
				{BigNumber(item?.margin).toFormat(3)} {currency}
			</TradeText>
			<TradeText style={{ width: 90 }}>
				<KrwPrice
					dp={3}
					price={-BigNumber(item?.fee_price).toNumber()}
					krw={true}
				/>
			</TradeText>
			<TradeText style={{ width: 130 }}>
				{useTimezone(item?.created_at)}
			</TradeText>
		</TradeDetailListItemSection>
	)
}

export default React.memo(TradeDetailListItem)
