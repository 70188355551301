import React from 'react'
import MyAssetsRightSectionList from './MyAssetsRightSectionList'
import { MyAssetRightSectionContainer } from './styles'

const MyAssetRightSection = () => {
	return (
		<MyAssetRightSectionContainer>
			<MyAssetsRightSectionList title={'spot'} balanceType={0} />
			<MyAssetsRightSectionList title={'futures'} balanceType={1} />
		</MyAssetRightSectionContainer>
	)
}

export default MyAssetRightSection
