import { CPagination } from 'app/components/Common'
import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import { OrderContainerFooterSection } from './styles'

const OrderContainerFooter = ({ data }) => {
	const [page, setPage] = useState(1)
	return (
		<OrderContainerFooterSection>
			<View style={{ width: 50 }}>
				{/* <CPagination
					dataLength={data.length}
					page={page}
					setPage={setPage}
				/> */}
			</View>
		</OrderContainerFooterSection>
	)
}

export default OrderContainerFooter
