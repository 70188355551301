import React from 'react'
import { CButton } from 'app/components/Common/'
import { COLORS, FONTS } from 'app/constants/STYLES'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'

const Trade = ({ data }) => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: 'transparent',
				justifyContent: 'space-around',
				alignItems: 'center',
				alignSelf: 'center',
			}}>
			<CButton
				title={t('trade')}
				onClick={() => {}}
				style={{
					...FONTS.h6,
					backgroundColor: COLORS.secondary,
					color: COLORS.white,
					padding: 5,
				}}
			/>
		</View>
	)
}

export default Trade
