import React, { useEffect } from 'react'
import { CButton, CInput } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { OrderValueInputSection } from '../styles'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import { useLastPrice } from '../../../../../../hooks/trade/lastprice'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from '../../../../../../constants'
import BigNumber from 'bignumber.js'
import { Button, Input, Popover, Select, Slider, Space, message } from 'antd'
import { IconAlarm } from '@tabler/icons'
import { getSetting, useSetting } from '../../../../../../hooks/user/mysetting'
import { SETTING_TYPE_TRADE } from '../../../../../../constants/SETTING'
import { useIndexPrice } from '../../../../../../hooks/trade/indexprice'

const OrderPriceInput = ({ limit_market }) => {
	const { textw, gray } = React.useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()
	const { getPrice } = useIndexPrice(tradeStore?.ep_id)

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_TRADE)
	const alarm = getSetting(setting, 'ALM' + tradeStore?.ep_id, 0)

	const { isDarkMode } = React.useContext(ThemeContext)

	const [trigger, setTrigger] = React.useState('BIG')

	useEffect(() => {
		if (
			tradeStore?.order_price === undefined ||
			tradeStore?.order_price === '' ||
			isNaN(tradeStore?.order_price) ||
			tradeStore?.order_price == 0
		) {
			mutateTradeStore({
				...tradeStore,
				order_price: BigNumber(
					getPrice(tradeStore?.ep_id) || 0
				).toFixed(tradeStore?.dp || 3),
			})
		}
	}, [])

	const setOrderPrice = data => {
		if (isNaN(data)) return
		mutateTradeStore({ ...tradeStore, order_price: data })
	}
	const setTriggerPrice = data => {
		if (isNaN(data)) return
		mutateTradeStore({
			...tradeStore,
			trigger_price: data,
			trigger_type: trigger,
		})
	}

	const [alm, setAlm] = React.useState(alarm)
	const [almp, setAlmp] = React.useState(alarm)

	return (
		<OrderValueInputSection>
			{limit_market != 'CM' && (
				<>
					<View style={{ flexDirection: 'row' }}>
						<Text
							style={{
								color: textw,
								fontSize: 13,
							}}>
							{t('orderPrice')}
						</Text>
						<Popover
							trigger={'click'}
							content={
								<View>
									<Space size={'small'}>
										{t('alarmsetting')}
										<Input
											value={alm}
											type={'number'}
											onChange={(e: any) => {
												setAlm(+e.target.value)
											}}
										/>
										<Button
											onClick={() => {
												updateSetting(
													'ALM' + tradeStore?.ep_id,
													alm
												)
												message.success(t('savedalarm'))
											}}>
											{t('save')}
										</Button>
										<Button
											onClick={() => {
												updateSetting(
													'ALM' + tradeStore?.ep_id,
													0
												)
												message.success(t('savedalarm'))
											}}>
											{t('delete')}
										</Button>
									</Space>
									<Space.Compact size="small">
										<Slider
											style={{ width: 350 }}
											min={-10}
											max={10}
											onChange={(e: any) => {
												setAlmp(e)
												const price =
													getPrice(
														tradeStore?.ep_id
													) *
													(1 + e / 100)
												setAlm(
													price.toFixed(
														tradeStore?.dp || 3
													)
												)
											}}
											value={almp}
											marks={{
												'-10': '-10%',
												'-5': '-5%',
												'-3': '-3%',
												'-1': '-1%',
												0: '0',
												1: '1%',
												3: '3%',
												5: '5%',
												10: '10%',
											}}
										/>
									</Space.Compact>
								</View>
							}>
							<Button
								size="small"
								type="ghost"
								style={{ color: textw }}>
								<IconAlarm
									size={20}
									style={{ marginTop: -4 }}
									color={textw}
								/>
								{alarm}
							</Button>
						</Popover>
					</View>
					<CInput
						placeholder={''}
						value={tradeStore?.order_price}
						type={'text'}
						onChange={(e: any) => setOrderPrice(e.target.value)}
						vStyle={{
							padding: 0,
							flexDirection: 'row',
							width: '100%',
						}}
						style={{
							background: gray,
							color: textw,
							border: 'none',
							marginTop: -5,
							padding: 10,
							width: '100%',
							paddingRight: 40,
						}}
					/>
					<CButton
						title={t('lastprice')}
						onClick={() => {
							setOrderPrice(
								BigNumber(getPrice(tradeStore?.ep_id)).toFixed(
									tradeStore?.dp || 3
								)
							)
						}}
						style={{
							position: 'absolute',
							right: '1rem',
							fontSize: '0.99rem',
							marginTop: '2.9rem',
							color: COLORS.primary,
						}}
					/>
				</>
			)}

			{(limit_market == 'CM' || limit_market == 'CL') && (
				<>
					<Text
						style={{
							color: textw,
							fontSize: 13,
						}}>
						{t('triggerPrice')}
					</Text>
					<View style={{ flexDirection: 'row', color: textw }}>
						<Select
							value={trigger}
							className={
								'trigger' + (!isDarkMode ? 'dark' : 'light')
							}
							style={{
								background: gray,
								color: textw,
								borderRadius: 5,
								marginRight: 5,
								marginTop: -10,
							}}
							bordered={false}
							allowClear={false}
							onChange={e => {
								setTrigger(e)
								mutateTradeStore({
									...tradeStore,
									trigger_type: e,
								})
							}}>
							<Select.Option value="BIG">{'>='}</Select.Option>
							<Select.Option value="SML">{'<='}</Select.Option>
						</Select>
						<CInput
							placeholder={''}
							value={tradeStore?.trigger_price}
							type={'text'}
							onChange={(e: any) =>
								setTriggerPrice(e.target.value)
							}
							vStyle={{
								padding: 0,
								flexDirection: 'row',
								width: '100%',
							}}
							style={{
								background: gray,
								color: textw,
								border: 'none',
								padding: 10,
								width: '100%',
								paddingRight: 40,
							}}
						/>
					</View>
				</>
			)}
		</OrderValueInputSection>
	)
}

export default React.memo(OrderPriceInput)
