import React from 'react'
import { CButton, CImage } from 'app/components/Common'
import { COLORS, FONTS, IMAGES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { CreateBotItems } from './styles'
import { useTranslation } from 'react-i18next'

const CreateBotItem = ({ item, index }) => {
	const { t } = useTranslation()
	return (
		<CreateBotItems>
			<View style={{ width: '20%' }}>
				<CImage
					src={IMAGES.createBot}
					alt="logo"
					width={124}
					height={124}
					style={{ borderRadius: 50, marginRight: 10 }}
					resizeMode={'cover'}
				/>
			</View>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'center',
					width: '60%',
				}}>
				<Text style={{ ...FONTS.h2 }}>{item.title}</Text>
				<Text
					style={{
						...FONTS.h5,
						color: COLORS.lightGray5,
						paddingBlock: 15,
					}}>
					{item.content}
				</Text>
				<View style={{ flexDirection: 'row' }}>
					<Text
						style={{
							...FONTS.h5,
							color: COLORS.lightGreen,
							marginRight: 5,
						}}>
						{item.active}
					</Text>
					<Text
						style={{
							...FONTS.h6,
							color: COLORS.lightGray5,
							marginRight: 5,
						}}>
						active bots & up to
					</Text>
					<Text
						style={{
							...FONTS.h5,
							color: COLORS.lightGreen,
							marginRight: 5,
						}}>
						{item.apr}
					</Text>
					<Text style={{ ...FONTS.h6, color: COLORS.lightGray5 }}>
						APR
					</Text>
				</View>
			</View>
			<View>
				<CButton
					title={t('createNow')}
					onClick={() => {
						console.log('buy now')
					}}
					style={{
						paddingBlock: 15,
						paddingInline: 40,
						fontWeight: 'bold',
						color: COLORS.gray,
						backgroundColor: COLORS.primary,
					}}
				/>
			</View>
		</CreateBotItems>
	)
}

export default CreateBotItem
