import styled from 'styled-components'

export const AboutTheBitSection = styled.div`
	margin: auto;
	margin-top: 100px;
	/* height: 650px; */
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 68%;
	}
	@media only screen and (max-width: 968px) {
		width: 80%;
	}
	@media screen and (max-width: 1200px) {
		/* height: 950px; */
	}
`

export const AboutTheBitItems = styled.div`
	width: 250px;
	height: 300px;
	margin: 10px;
	padding-block: 10px;
	padding-inline: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	@media screen and (max-width: 1200px) {
		width: 45%;
	}
	@media screen and (max-width: 900px) {
		width: 40%;
	}
	@media screen and (min-width: 1200px) {
		width: 30%;
	}
`
export const AboutTheBitList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 1200px) {
		flex-wrap: wrap;
		justify-content: flex-start;
		& > ${AboutTheBitItems} {
			width: calc(32% - 20px);
			margin: 10px;
		}
	}

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
		& > ${AboutTheBitItems} {
			width: calc(50% - 20px);
			margin: 10px;
		}
	}
`
