import React, { useContext } from 'react'
import { MContentContainer, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MNotiSetting } from 'app/mobile/organisms'
import { OtherHeader } from 'app/mobile/components/'
import { useNotiSetting } from 'app/hooks/stateStore'

const NotiSettingMobile = () => {
	const { isDarkMode } = useContext(ThemeContext)
	const { data: notiSetting, mutate: mutateNotiSetting } = useNotiSetting()

	const defaultNotiSetting = {
		ALL: notiSetting?.ALL !== undefined ? notiSetting.ALL : true,
		NORMAL: notiSetting?.NORMAL !== undefined ? notiSetting.NORMAL : true,
		NIGHT: notiSetting?.NIGHT !== undefined ? notiSetting.NIGHT : false,
		FORGROUND:
			notiSetting?.FORGROUND !== undefined ? notiSetting.FORGROUND : true,
	}
	mutateNotiSetting(defaultNotiSetting)

	return (
		<MContentContainer isDarkMode={isDarkMode}>
			<OtherHeader title={'notiSetting'} />
			<MView style={{ marginTop: 50, width: '100%' }}>
				<MNotiSetting />
			</MView>
		</MContentContainer>
	)
}

export default NotiSettingMobile
