import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MView } from 'styles/mobilePageStyles'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import {
	AboutExperienceContainer,
	AboutExperienceSection,
	ExperienceListItem,
	ExperienceLists,
} from './styles'

const AbroutExperience = () => {
	const { t } = useTranslation()

	const { isDarkMode } = useContext(ThemeContext)
	const { textw } = useContext(ThemeContext).theme.COLORS
	const data = [
		{ title: 170, content: 'yearExperience' },
		{ title: 35, content: 'awardWin' },
		{ title: 1750, content: 'happyCustomers' },
		{ title: 120, content: 'ourExpertStaffs' },
	]
	return (
		<AboutExperienceSection isDarkMode={isDarkMode}>
			<AboutExperienceContainer>
				<ExperienceLists>
					{data?.map((item, index) => (
						<ExperienceListItem
							key={index.toString()}
							style={{
								border: `1px solid ${COLORS.white}`,
							}}>
							<Text size={40}>{item?.title}+</Text>
							<MView
								style={{
									width: '50%',
									height: 2,
									background: textw,
								}}
							/>
							<Text size={24}>{t(item?.content)}</Text>
						</ExperienceListItem>
					))}
				</ExperienceLists>
			</AboutExperienceContainer>
		</AboutExperienceSection>
	)
}

export default AbroutExperience
