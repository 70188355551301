import { useWindowDimensions } from 'app/hooks'
import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	children?: any
	style?: any
}
const ProfileSettingBox = ({ children, style }: IProps) => {
	const { width } = useWindowDimensions()
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS
	// const wSize = width < 1200 ? '48%' : width < 900 ? '90%' : '32%'
	const wSize =
		width < 600
			? '100%'
			: width < 1200
			? '100%'
			: width < 1400
			? '48%'
			: '32%'
	return (
		<View
			style={{
				width: wSize,
				height: 400,
				background: drawerBgColor,
				marginRight: 5,
				marginBottom: 5,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				...style,
			}}>
			{children}
		</View>
	)
}

export default ProfileSettingBox
