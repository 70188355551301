import { COLORS } from 'app/constants/STYLES'
import styled from 'styled-components'

export const TradeDetailListSection = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
	padding-inline: 3px;

	& .ant-card {
		background: ${props => props.theme.COLORS.drawerBgColor} !important;
		border: 0;
		color: ${props => props.theme.COLORS.textw};
		border-radius: 0;
	}

	& .ant-table {
		border-radius: 0;
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
	}

	& .ant-table-container {
		border-start-start-radius: 0 !important;
		border-start-end-radius: 0 !important;
		background: transparent !important;
	}

	& .ant-table-header {
		border-radius: 0 !important;
		padding: 0;
		background: transparent !important;
	}
	& .ant-table-header::before {
		width: 0 !important;
	}

	& .ant-table-thead .ant-table-cell {
		border-radius: 0 !important;
		padding: 0;
		background: transparent !important;
		border-bottom: 1px solid ${props => props.theme.COLORS.gray} !important;
		color: ${props => props.theme.COLORS.textw};
		border: 0;
		box-shadow: none !important;
	}

	& .ant-table-header .ant-table-cell {
		border-radius: 0 !important;
		padding: 0;
		background: transparent !important;
		color: ${props => props.theme.COLORS.textw};
		border: 0;
		box-shadow: none !important;
	}
	& .ant-table-thead .ant-table-cell::before {
		width: 0 !important;
	}

	& .ant-table-header .ant-table-cell::before {
		width: 0 !important;
	}

	& .ant-table-body .ant-table-cell {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
		border-bottom: 1px solid ${props => props.theme.COLORS.gray} !important;
	}

	& .ant-table-row:hover .ant-table-cell {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
	}

	& .ant-table-footer {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
		border-radius: 0;
	}
	& .ant-pagination .ant-pagination-item a {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
	}
	& .ant-pagination li button {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw} !important;
	}
`
export const TradeDetailListHeaderSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: center;
	color: ${COLORS.lightGray5};
`

export const TradeDetailListItemSection = styled.div`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	align-self: center;
	display: flex;
	flex-direction: row;

	margin-top: 10px;
`

export const TradeText = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: flex-start;
	min-width: 50px;
	width: 100%;
	font-size: 13px;
	color: ${props => props.theme.COLORS.textw};
	@media (max-width: 900px) {
		font-size: 12px;
	}
	@media (max-width: 600px) {
		font-size: 10px;
	}
`
