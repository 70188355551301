import React, { useContext } from 'react'
import { CButton } from 'app/components/Common'

import mobilelogo from 'assets/icons/logo.png'
import { ThemeContext } from 'theme/ThemeProvider'
import { IconChevronLeft } from '@tabler/icons'
import { OtherHeaderSection } from './styles'
import { MText, MView } from 'styles/mobilePageStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import DarkModeToggle from '../../../organisms/Header/DarkModeToggle/mobile'
import { CoinLogo } from '../../../components'
import { message } from 'antd'

const OtherHeader = ({ title, RightComp, setShowSelect = e => {} }: any) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const history = useHistory()
	return (
		<OtherHeaderSection isDarkMode={isDarkMode}>
			<CButton
				onClick={() => {
					// useHistory이전페이지가 /logout 이면 홈으로 아니면 뒤로 goBack
					//location.pathname 아님, 이건 현재 페이지/
					history.goBack()
				}}
				icon={<IconChevronLeft color={textw} />}
				style={{ height: '100%', width: 50 }}
			/>
			<MView
				style={{
					minWidth: 50,
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					marginLeft: RightComp ? 20 : -20,
				}}>
				<MText style={{ color: textw, fontSize: 16 }}>{t(title)}</MText>
			</MView>

			<MView style={{ flexDirection: 'row' }}>
				{/* <CoinLogo
					src={mobilelogo}
					style={{ marginRight: 20 , marginTop: 2, }}
					onClick={() => {
						setShowSelect(true)
						//navigation.push('/?skip=true')
					}}
				/> */}
				{RightComp ? <RightComp /> : <DarkModeToggle size={18} />}
			</MView>
		</OtherHeaderSection>
	)
}

export default OtherHeader
