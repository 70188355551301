import React, { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { CInput } from 'app/components/Common'
import DepositCoinLists from './DepositCoinLists'
import CustomCoinComp from './CustomCoinComp'
import { useDepositStore } from '../../../../hooks/deposit/store'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { mutate } from 'swr'
import { useFetcherSetting } from '../../../../hooks/fetcher'

const DepositCoins = () => {
	const ref = useRef<HTMLInputElement>(null)
	const { t } = useTranslation()
	const [modal, setModal] = useState(true)
	const { data: depositStore, mutate } = useDepositStore({
		network: 'TRC20',
		coin: 'KRW',
	})
	const { textw } = useContext(ThemeContext).theme.COLORS

	const search = depositStore?.coin

	const handleChange = (event: any) => {
		mutate({ ...depositStore, coin: event.target.value })
		setModal(false)
	}
	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<Text size={14}>{t('coin')}</Text>
			<CInput
				ref={ref}
				placeholder={t('searchCoin')}
				value={search}
				type={'text'}
				onClick={() => {
					console.log('click')
					setModal(!modal)
				}}
				onChange={handleChange}
				vStyle={{ width: '100%' }}
				CustomComp={false ? <CustomCoinComp /> : null}
				style={{
					flexDirection: 'row',
					border: `1px solid ${textw}`,
					marginTop: 10,
					width: '100%',
					justifyContent: 'space-between',
					padding: 8,
					borderRadius: 5,
					cursor: 'pointer',
					background: 'transparent',
					color: textw,
				}}
			/>
			{search?.length && !modal ? (
				<DepositCoinLists setModal={setModal} />
			) : null}
		</View>
	)
}

export default DepositCoins
