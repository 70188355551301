import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconX } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
interface IProps {
	closeModal?: () => void
	title?: any
}
const CModalHeader = ({ closeModal, title }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				padding: 15,
			}}>
			<Text size={18}>{t(title)}</Text>
			<CButton
				icon={<IconX size={20} color={textw} />}
				style={{
					padding: 3,
					borderRadius: 50,
				}}
				onClick={() => {
					closeModal && closeModal()
				}}
			/>
		</View>
	)
}

export default CModalHeader
