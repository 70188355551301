export const COLORS = {
	textLightGrey: '##888B90',
	textLightGray: '#888B90',
	textDarkGray: '#888B90',
	textDarkGrey: '##888B90',
	darkBg: '#09021d',
	drawerBg: '#2B293B',
	darkBox: '#343140',
	darkBoxOpacity: '#34314022',
	lightBg: '#D9DFFF',
	white: '#ffffff',
	whiteOpacity: 'rgba(255, 255, 255, 0.8)',
	blackOpacity: 'rgba(0, 0, 0, 0.8)',
	blackCardOpacity: 'rgba(0,0,0,0.4)',
	whiteCardOpacity: 'rgba(255,255,255,0.4)',
	black: '#000',
	darkGray: '#1C1C1C',
	darkGrey: '#333333',
	lightGrey: '#e9e9e9',
	lightGray: '#eee',
	lightSilver: '#888b90',
	darkBorder: '#ccc',
	lightBorder: '#555',
	subHeaderDark: '#242424',
	subHeaderLight: '#FBFBFB',
	shadowDark: '#333',
	shadowLight: '#eee',
	loginbgb: '#1d1003',
	loginbgw: '#ffd7cb',
	grad1: '#7e39ff',
	grad2: '#3172fd',
}
export const theme: {
	light: { COLORS: any }
	dark: { COLORS: any }
} = {
	light: {
		COLORS: {
			background: COLORS.darkBg,
			bgColor: COLORS.black,
			drawerBgColor: COLORS.drawerBg,
			mdrawerBgColor: '#343140',
			tradeBG: '#171B26',
			opacityBgColor: COLORS.blackOpacity,
			descBox: COLORS.darkBox,
			textsilver: COLORS.lightSilver,
			gray: COLORS.darkGray,
			grey: COLORS.darkGrey,
			textw: COLORS.white,
			textGrey: COLORS.textLightGrey,
			textGray: COLORS.textLightGrey,
			borderColor: COLORS.lightBorder,
			header: COLORS.darkGray,
			subHeader: COLORS.subHeaderDark,
			shadow: COLORS.shadowDark,
			cardOpacity: COLORS.blackCardOpacity,
			content: '#ddd',

			stroke: '#2F2F2F',
			Secondary: '#EFEFEF',
			Tertiary: '#DFDFDF',
			onSecondary: '#CBD1D7',
			onTertiary: '#000000',
			fail_sub: '#333E45',
			Prinary: '#ffffff',
			raise_sub: '#603736',
			point: '#00499F',
			complementary: '#1D1D1D',
			fail: '#007EDA',
			raise: '#F05551',
			descBorder: COLORS.darkBorder,
		},
	},
	dark: {
		COLORS: {
			background: COLORS.lightBg,
			drawerBgColor: COLORS.white,
			mdrawerBgColor: COLORS.white,
			tradeBG: COLORS.white,
			opacityBgColor: COLORS.whiteOpacity,
			descBox: COLORS.darkBoxOpacity,
			bgColor: COLORS.white,
			textsilver: COLORS.lightSilver,
			gray: COLORS.lightGray,
			grey: COLORS.lightGrey,
			textw: COLORS.black,
			textGrey: COLORS.textLightGrey,
			textGray: COLORS.textLightGrey,
			borderColor: COLORS.darkBorder,
			header: COLORS.white,
			subHeader: COLORS.subHeaderLight,
			shadow: COLORS.shadowLight,
			cardOpacity: COLORS.whiteCardOpacity,
			content: '#999',

			onTertiary: '#F2F4F6',
			fail_sub: '#E0F2FF',
			fail: '#007EDA',
			raise: '#F05551',
			raise_sub: '#FFEDED',
			stroke: '#EAEAEA',
			onSecondary: '#EDEFF1',
			Prinary: '#303030',
			point: '#00499F',
			Tertiary: '#7D7D7D',
			Secondary: '#3A3A3A',
			descBorder: COLORS.lightBorder,
		},
	},
}
