import { Button, Input, Popover, Select, Space, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from '../../../../styles/mobilePageStyles'
import API from '../../../../utils/api'
import { useToken } from '../../../hooks'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { IconEdit } from '@tabler/icons'
import { COLORS } from '../../../constants'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import useTradeStore from '../../../hooks/trade/tradeStore'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import BigNumber from 'bignumber.js'
import { Text, View } from '../../../../styles/reactnative'

const TPSLEDIT = ({
	posid,
	price,
	leverage,
	amount,
	long_short,
	margin,
	tpsl,
	isMobile = false,
}) => {
	const { t } = useTranslation()
	const { data: token } = useToken()
	const [tp, setTp] = useState(tpsl?.tp)
	const [sl, setSl] = useState(tpsl?.sl)
	const { mutate: mutatePosition } = useTradePosition()

	const { textw, gray, bgColor } = React.useContext(ThemeContext).theme.COLORS

	let v = new BigNumber(price)
		.multipliedBy(amount)
		.multipliedBy(leverage)
		.toNumber()

	let calcpricelong = new BigNumber(long_short == 'L' ? tp : price)
		.minus(long_short == 'L' ? price : tp)
		.multipliedBy(amount)
		.multipliedBy(leverage)

	let calcpriceshort = new BigNumber(long_short == 'L' ? sl : price)
		.minus(long_short == 'L' ? price : sl)
		.multipliedBy(amount)
		.multipliedBy(leverage)

	return (
		<Popover
			trigger={'click'}
			title={t('TP/SL')}
			content={
				<MView style={{ width: isMobile ? 220 : '200px' }}>
					<Space.Compact size={isMobile ? 'middle' : 'small'}>
						<MText style={{ color: 'black' }}>{t('TP')}</MText>
						<Input
							defaultValue={tpsl?.tp}
							type="number"
							value={tp}
							slot="end"
							onChange={e => setTp(+e.target.value)}
							size={isMobile ? 'middle' : 'small'}
							style={{
								width: 140,
								fontSize: '1rem',
							}}
						/>
						<Button
							size={isMobile ? 'middle' : 'small'}
							onClick={() => {
								setTp(0)
							}}>
							{t('delete')}
						</Button>
					</Space.Compact>

					<Space.Compact size={isMobile ? 'middle' : 'small'}>
						<MText style={{ color: 'black' }}>{t('SL')}</MText>
						<Input
							defaultValue={tpsl?.sl}
							type="number"
							value={sl}
							slot="end"
							onChange={e => setSl(+e.target.value)}
							size={isMobile ? 'middle' : 'small'}
							style={{
								width: 140,
								fontSize: '1rem',
							}}
						/>
						<Button
							size={isMobile ? 'middle' : 'small'}
							onClick={() => {
								setSl(0)
							}}>
							{t('delete')}
						</Button>
					</Space.Compact>

					<View style={{ marginTop: 10, marginBottom: 10 }}>
						<Space
							style={{
								color: COLORS.black,
								marginTop: 5,
								width: '100%',
								whiteSpace: 'nowrap',
							}}>
							{t('pnlprice')}
							<Input
								readOnly
								value={
									!tp
										? '-'
										: calcpricelong?.toFormat(2) +
										  ' (' +
										  (
												(+calcpricelong.toNumber() /
													v) *
												leverage *
												100
										  ).toFixed(2) +
										  '%)'
								}
								style={{
									background: bgColor,
									minWidth: '50px',
									width: '100%',
									color:
										+calcpricelong.toNumber() > 0
											? COLORS.lightGreen
											: +calcpricelong.toNumber() < 0
											? COLORS.lightRed
											: textw,
									fontSize: 11,
								}}
							/>
						</Space>
						<Space
							style={{
								color: COLORS.black,
								marginTop: 5,
								whiteSpace: 'nowrap',
							}}>
							{t('pnlloss')}
							<Input
								readOnly
								value={
									!sl
										? '-'
										: calcpriceshort?.toFormat(2) +
										  ' (' +
										  (
												(+calcpriceshort.toNumber() /
													v) *
												leverage *
												100
										  ).toFixed(2) +
										  '%)'
								}
								style={{
									background: bgColor,
									minWidth: '50px',
									width: '100%',
									color:
										+calcpriceshort.toNumber() > 0
											? COLORS.lightGreen
											: +calcpriceshort.toNumber() < 0
											? COLORS.lightRed
											: textw,
									fontSize: 11,
								}}
							/>
						</Space>
					</View>

					<Space.Compact size={isMobile ? 'middle' : 'small'}>
						<Button
							size={isMobile ? 'middle' : 'small'}
							type={'primary'}
							onClick={() => {
								if (tp || sl) {
									API.post('/trade/tpsl', '' + token, {
										posId: posid,
										tp: tp,
										tptype: '',
										sl: sl,
										sltype: '',
									}).then(res => {
										message.success(`${t('success')}`)
										mutatePosition()
									})
								}
							}}>
							{t('save')}
						</Button>
						<Button
							size={isMobile ? 'middle' : 'small'}
							type={'primary'}
							onClick={() => {
								API.post('/trade/tpsl', '' + token, {
									posId: posid,
									tp: 0,
									tptype: '',
									sl: 0,
									sltype: '',
								}).then(res => {
									message.success(`${t('success')}`)
									mutatePosition()
								})
							}}>
							{t('entire') + t('delete')}
						</Button>
					</Space.Compact>
				</MView>
			}>
			<Button
				icon={<IconEdit size={13} color={textw} />}
				size={isMobile ? 'middle' : 'small'}
				style={
					isMobile
						? {
								marginLeft: 10,
								background: gray,
								color: textw,
								minHeight: 30,
						  }
						: { background: gray, color: textw }
				}>
				{isMobile ? 'TPSL' : ''}
			</Button>
		</Popover>
	)
}
export default React.memo(TPSLEDIT)
