/**
 * Asynchronously loads the component for ReferralPage
 */

import { lazyLoad } from 'utils/loadable'

export const ReferralPage = lazyLoad(
	() => import('./index'),
	module => module.ReferralPage
)
