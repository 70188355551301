import React, { useState } from 'react'
import ChartContainer from './ChartContainer'
import { TradeChartSectionWrapper } from './styles'
import TradeChartNavBar from './TradeChartNavBar'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { mutate } from 'swr'
import { Modal } from 'antd'

const TradeChartSection = ({ isDragging }) => {
	const [tab, setTab] = useState('1m')

	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const tabs = modalStore?.tabs
		? modalStore?.tabs
		: ['1m', '5m', '15m', '30m', '1h', '1d', '1w', '1M']

	if (!modalStore?.tabs) mutateModalStore({ ...modalStore, tabs: tabs })

	return (
		<TradeChartSectionWrapper>
			<TradeChartNavBar tab={tab} setTab={setTab} tabs={tabs} />
			<ChartContainer tab={tab} isDragging={isDragging} />
		</TradeChartSectionWrapper>
	)
}

export default TradeChartSection
