import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import SpecialMobileImages from './SpecialMobileImages'

const SpecialMobileDisplay = () => {
	const { t } = useTranslation()
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<MView style={{ padding: 16, width: '100%' }}>
			<MView style={{ width: '50%' }}>
				<MText style={{ color: textw, fontSize: 25, lineHeight: 1.4, whiteSpace:'nowrap' }}>
					{t('specialSectionContent')}
				</MText>
			</MView>
			<MView style={{ marginTop: 30 }}>
				<MText
					style={{
						color: textsilver,
						fontSize: 16,
						lineHeight: 1.2,
					}}>
					{t('avoidTrading')}
				</MText>
			</MView>
			<SpecialMobileImages />
		</MView>
	)
}

export default SpecialMobileDisplay
