import React, { useContext, useState } from 'react'
import { CButton } from 'app/components/Common'
import { MenuContainer, MenuItems, OrderMenuSection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { IconChevronDown } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { Text } from 'styles/reactnative'

const OrderMenus = ({ title, menus, tab, setTab }) => {
	const [show, setShow] = useState(false)
	const { borderColor, textsilver, textw } =
		useContext(ThemeContext).theme.COLORS

	return (
		<OrderMenuSection>
			<Text style={{ marginBottom: 5 }}>{title}</Text>
			<CButton
				title={tab}
				style={{
					border: `1px solid ${borderColor}`,
					width: 100,
					height: 30,
					justifyContent: 'space-between',
					color: textw,
				}}
				icon={<IconChevronDown size={16} color={textsilver} />}
				onClick={() => {
					setShow(!show)
				}}
			/>
			{show ? (
				<MenuContainer>
					{menus?.map((item: any, index: number) => (
						<MenuItems
							style={{
								color: item === tab ? COLORS.secondary : textw,
							}}
							key={index.toString()}
							onClick={() => {
								setTab(item)
								setShow(!show)
							}}>
							{item}
						</MenuItems>
					))}
				</MenuContainer>
			) : null}
		</OrderMenuSection>
	)
}

export default OrderMenus
