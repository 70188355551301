import styled from 'styled-components'

export const AccountInfoNavBarRightSideSection = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	background: #f0fff6;
	width: 430px;
	padding-inline: 15px;
	padding-block: 20px;
	border-radius: 16px;
	@media only screen and (max-width: 1100px) {
		width: 380px;
	}
	@media only screen and (max-width: 900px) {
		margin-top: 30px;
		width: 350px;
	}
`
export const AccountInfoNavBarSection = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	padding: 32px;
`

export const AccountInfoNavBarSideContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	@media only screen and (max-width: 900px) {
		flex-direction: column;
	}
`
