import React, { useContext } from 'react'
import { IconArrowNarrowRight } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS, SIZES } from 'app/constants'
import { View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const ViewAllMasterFooter = ({ setTabs }: any) => {
	const { bgColor, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				height: 50,
				background: bgColor,
				marginBottom: 100,
				borderRadius: 5,
			}}>
			<CButton
				icon={
					<IconArrowNarrowRight
						color={COLORS.primary}
						style={{ marginLeft: 10 }}
					/>
				}
				title={t('viewAllMasterTraders')}
				onClick={() => {
					setTabs('All Master Traders')
				}}
				style={{
					color: textw,
					fontWeight: '600',
					fontSize: SIZES.h5,
				}}
			/>
		</View>
	)
}

export default React.memo(ViewAllMasterFooter)
