import useSWR from 'swr'
import API from '../../../utils/api'
import { ORDERBOOK } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'

let swrData: any = { data: { long: [], short: [], ep_id: 0 } }

export function useOrderBook(ep_id: string | number) {
	const KEY = `${ORDERBOOK}/${ep_id}`
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData.data !== null) return swrData?.data

			/*
			const response = await API.get(KEY, token + '', {})
			console.log('API', response.result)
			swrData.data = response.result
			*/
			return swrData.data
		},
		{
			revalidateIfStale: false,
			revalidateOnReconnect: false,
			revalidateOnFocus: false,
		}
	)

	return {
		data,
		mutate: async (value?: any) => {
			if (value !== undefined) {
				swrData.data = value
			}
			if (value === null) {
				swrData.data = null
			}
			return mutate()
		},
	}
}
