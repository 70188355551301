import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { AnnouncementTitleSection } from './styles'

const AnnouncementTitle = ({ title }) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const content = 'announcementContent'

	//거래소 이름 치환
	const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

	const currentname = t('title')

	title = title?.replace(new RegExp(list.join('|'), 'gi'), currentname)

	return (
		<AnnouncementTitleSection>
			<Text
				style={{
					alignItems: 'flex-start',
					fontSize: 30,
					marginBottom: 10,
				}}>
				{title && t(title)}
			</Text>
			<Text
				style={{
					alignItems: 'flex-start',
					fontSize: 18,
					color: textsilver,
				}}>
				{content && t(content)}
			</Text>
		</AnnouncementTitleSection>
	)
}

export default AnnouncementTitle
