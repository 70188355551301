import styled from 'styled-components'

export const AccountSecuritySettingSection = styled.div``
export const ProfileSecurities = styled.div`
	background-color: ${p => p.theme.COLORS.drawerBgColor};
	border-radius: 5px;
	width: 100%;
	margin-bottom: 25px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 30px;
`
