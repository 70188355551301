import React, { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { useTradeSetting } from '../../../../../../hooks/trade/tradeSetting'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import QuantityCostFormList from './QuantityCostFormList'
import { useIndexPrice } from '../../../../../../hooks/trade/indexprice'

const QuantityCostForm = () => {
	const { data: store } = useTradeStore()
	const { data: setting } = useTradeSetting(store?.ep_id)
	const [MS, setMS] = React.useState(store?.main_sub || 'M')

	const { getPrice } = useIndexPrice(store?.ep_id)

	const leverage = setting?.leverage || 20
	let price = store?.order_price || 0
	const amount = store?.order_amount || 0

	if (store?.limit_market != 'L') {
		price = getPrice(store?.ep_id)
	}

	let value = new BigNumber(price)
		.multipliedBy(amount)
		.dividedBy(leverage)
		.toNumber()
	let bidsvalue = Number(
		(price * amount).toFixed(store?.dp2 || 2)
	).toLocaleString('ko-kr')
	let asksvalue = Number(
		(price * amount).toFixed(store?.dp2 || 2)
	).toLocaleString('ko-kr')

	if (MS === 'S') {
		value = new BigNumber(amount).dividedBy(leverage).toNumber()

		bidsvalue = new BigNumber(amount).toFormat(2)
		asksvalue = new BigNumber(amount).toFormat(2)
	}

	useEffect(() => {
		setMS(store?.main_sub)
	}, [store?.main_sub])

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginBlock: 20,
				border: `1px solid ${COLORS.lightGray}`,
				borderRadius: 3,
				paddingInline: 5,
				paddingBlock: 7,
			}}>
			<QuantityCostFormList
				title={'value'}
				bids={bidsvalue}
				asks={asksvalue}
				type={store?.pairs?.split('/')[1]}
			/>
			<QuantityCostFormList
				title={'cost'}
				bids={value.toFixed(3)}
				asks={value.toFixed(3)}
				type={store?.pairs?.split('/')[1]}
			/>
		</View>
	)
}

export default QuantityCostForm
