import styled from 'styled-components'

export const TraderDetailLeftSection = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${p => p.theme.COLORS.bgColor};
	width: 100%;
	border-radius: 12px;
	margin-bottom: 10px;
	@media only screen and (max-width: 1199px) {
		margin-right: 10px;
	}
`
export const TraderDetailInfoLeftSideSection = styled.div`
	margin-bottom: 30px;
	width: 35%;
	display: flex;
	@media only screen and (max-width: 1199px) {
		flex-direction: row;
		width: 100%;
	}
	@media only screen and (min-width: 1200px) {
		flex-direction: column;
	}
`
