import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { MView } from '../../../../../styles/mobilePageStyles'
import { Text } from '../../../../../styles/reactnative'
import { FONTS } from '../../../../constants'
import CoinRate from '../../../../components/CoinRate/mobile'
import { IconShare } from '@tabler/icons'

interface IProps {
	pairs: any
	longShort: any
	lsColor?: any
}

const OrderItemHeader = ({ pairs, longShort, lsColor }: IProps) => {
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<MView
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}>
			<MView style={{ flexDirection: 'row' }}>
				<Text
					style={{
						...FONTS.h5,
						fontWeight: '500',
						color: THEMECOLORS.textw,
						marginRight: 5,
					}}>
					{pairs ? pairs : '-'}
				</Text>
				<CoinRate color={lsColor} content={t(longShort)} />
			</MView>
			<MView>
				<IconShare />
			</MView>
		</MView>
	)
}
export default React.memo(OrderItemHeader)
