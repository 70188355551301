import React from 'react'
import {
	IconBrandBinance,
	IconBrandCoinbase,
	IconChartBar,
	IconGasStation,
} from '@tabler/icons'
import { GlobalTrendSection } from './styles'
import GlobalTrendItem from './GlobalTrendItem'

const GlobalTrend = () => {
	const data = [
		{
			title: 'marketSentiment',
			content: '51',
			subContent: 'Neutral',
			icon: () => <IconChartBar />,
			type: '0',
		},
		{
			title: 'globalCryptocurrencyMarket',
			content: '1,033.43B',
			subContent: '10.61 %',
			icon: () => <IconBrandCoinbase color={'#5E9AEE'} />,
			type: '1',
		},
		{
			title: 'globalCryptocurrencyTradingVolume',
			content: '114.58 B',
			subContent: '10.61 %',
			icon: () => <IconBrandBinance color={'#FEAD71'} />,
			type: '1',
		},
		{
			title: 'ethGas',
			content: '12Gwei',
			subContent: '10.61 %',
			icon: () => <IconGasStation color={'#C77EFB'} />,
			type: '1',
		},
	]
	return (
		<GlobalTrendSection>
			{data.map((item, index) => (
				<GlobalTrendItem
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</GlobalTrendSection>
	)
}

export default React.memo(GlobalTrend)
