import { CButton, CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useToken } from 'app/hooks'
import { useMyProfile } from 'app/hooks/user/myprofile'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import API from 'utils/api'

const ProfileEditBody = ({ closeModal }: any) => {
	const { data: token } = useToken()
	const { mutate } = useMyProfile()
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	const [nickname, setNickName] = useState('')
	const { t } = useTranslation()

	const changeUsername = async () => {
		if (nickname?.length) {
			try {
				const res = await API.put('/user/my/nickname', `${token}`, {
					nickname: nickname,
				})
				if (res?.result?.success) {
					toast(`${t('successfullyChangedNickname')}`)
					mutate(null)
					closeModal()
				} else {
				}
			} catch (e) {
				console.log(e, 'ERROR LOGIN')
			}
		} else {
			toast(`${t('pleaseFillAllFields')}`)
		}
	}
	return (
		<View
			style={{
				justifyContent: 'space-between',
				height: '80%',
			}}>
			<View
				style={{
					width: '100%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					marginTop: 20,
				}}>
				<Text size={14}>{t('changeUsername')}</Text>
				<CInput
					placeholder={t('edityourusername')}
					value={nickname}
					type={'text'}
					onClick={() => {}}
					onChange={(e: any) => {
						setNickName(e.target.value)
					}}
					vStyle={{ width: '100%' }}
					style={{
						flexDirection: 'row',
						marginTop: 10,
						width: '100%',
						justifyContent: 'space-between',
						padding: 8,
						borderRadius: 5,
						cursor: 'pointer',
						background: gray,
						border: 'none',
						color: textw,
					}}
				/>
			</View>
			<CButton
				title={t('confirm')}
				onClick={changeUsername}
				style={{
					background: COLORS.primary,
					color: COLORS.white,
					width: '50%',
					height: 35,
				}}
			/>
		</View>
	)
}

export default ProfileEditBody
