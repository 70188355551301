import React from 'react'
import { SupportSection } from '../styles'
import TradingFeeRate from './TradingFeeRate'
import SupportTitle from '../SupportTitle'
import { useFetcher } from '../../../hooks/fetcher'

const TradingFees = () => {
	const feeHeader = ['level', 'trade', 'Maker', 'Taker']

	const { data } = useFetcher('/system/level', false)
	let feeTableList = data?.result?.result || []

	//레벨 0이상만
	feeTableList = feeTableList?.filter(item => item?.level >= 0)

	return (
		<SupportSection>
			<SupportTitle title={'tradingFees'} />
			<TradingFeeRate
				title={'yourFeeLevel'}
				tableHeader={feeHeader}
				tableList={feeTableList}
			/>
		</SupportSection>
	)
}

export default TradingFees
