import React, { useEffect, useRef, useState, RefObject } from 'react'
import { IconChevronDown } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import AccordionItemList from './AccordionItemList'
import {
	AccordionBodyContainer,
	AccordionContent,
	AccordionHeader,
	AccordionHeaderBtn,
	AccordionHeaderIcon,
	AccordionHeaderText,
	AccordionList,
} from './styles'

export function getRefValue<C>(ref: RefObject<C>) {
	// eslint-disable-next-line prettier/prettier
  return ref.current as C;
}
type AccordionData = {
	title: string
	content?: any
}

interface Iprops {
	data: AccordionData
	isOpen: boolean
	btnOnClick: () => void
}
function AccordionItem({ data, isOpen, btnOnClick }: Iprops) {
	const { t } = useTranslation()
	const contentRef = useRef<HTMLDivElement>(null)
	const [height, setHeight] = useState(0)

	useEffect(() => {
		if (isOpen) {
			const contentEl = getRefValue(contentRef)

			setHeight(contentEl.scrollHeight)
		} else {
			setHeight(0)
		}
	}, [isOpen])

	return (
		<AccordionList>
			<AccordionHeader>
				<AccordionHeaderBtn onClick={btnOnClick}>
					<AccordionHeaderText>{t(data?.title)}</AccordionHeaderText>
					<AccordionHeaderIcon isOpen={isOpen}>
						<IconChevronDown size={18} />
					</AccordionHeaderIcon>
				</AccordionHeaderBtn>
			</AccordionHeader>
			<AccordionBodyContainer style={{ height }}>
				<AccordionContent ref={contentRef}>
					<AccordionItemList list={data?.content} />
				</AccordionContent>
			</AccordionBodyContainer>
		</AccordionList>
	)
}

export default React.memo(AccordionItem)
