import React, { useState } from 'react'
import { AccordionSectionList } from './styles'
import AccordionItem from './AccordionItem'
// import BtnLanguageChange from '../../BtnLanguageChange'

type AccordionData = {
	title: string
	content?: any
}
interface Iprops {
	items: Array<AccordionData>
}

function Accordion({ items }: Iprops) {
	const [currentIdx, setCurrentIdx] = useState(-1)
	const btnOnClick = (idx: number) => {
		setCurrentIdx(currentValue => (currentValue !== idx ? idx : -1))
	}

	return (
		<AccordionSectionList>
			<div>
				{items.map((item, idx) => (
					<AccordionItem
						key={idx.toString()}
						data={item}
						isOpen={idx === currentIdx}
						btnOnClick={() => btnOnClick(idx)}
					/>
				))}
			</div>
			{/* <LanguageBtn>
				<BtnLanguageChange
					label={'Language'}
					iconSize={20}
					fontSize={20}
				/>
			</LanguageBtn> */}
		</AccordionSectionList>
	)
}

export default React.memo(Accordion)
