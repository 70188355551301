import styled from 'styled-components'

export const MMarketSection = styled.div`
	width: 100%;
`
export const MarketListHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 10px;
`

export const MMarketListSection = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
`
