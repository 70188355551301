import { CFlatList } from 'app/components/Common'
import React from 'react'
import MCoinItems from './MCoinItems'
import { MCoinListSection } from './styles'

const MCoinLists = ({ data, noData, search }: any) => {
	const renderItem = (item: any, index: any) => (
		<MCoinItems item={item} index={index} search={search} />
	)

	return (
		<MCoinListSection>
			<CFlatList
				data={data?.length ? data : []}
				renderItem={renderItem}
				noDataTitle={noData ? noData : null}
				itemSize={43}
			/>
		</MCoinListSection>
	)
}

export default MCoinLists
