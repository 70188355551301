import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MView } from 'styles/mobilePageStyles'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import MPrivacyPolicyList from './PrivacyPolicyList'

import { PrivacyPolicySection } from './styles'

const MPrivacyPolicy = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<PrivacyPolicySection>
			<MView
				style={{
					width: '100%',
					height: 50,
					justifyContent: 'center',
				}}>
				<Text
					size={24}
					style={{
						alignItems: 'flex-start',
						fontWeight: '400',
						color: textw,
					}}>
					{t('privacyPolicy')}
				</Text>
			</MView>
			<MPrivacyPolicyList />
		</PrivacyPolicySection>
	)
}

export default MPrivacyPolicy
