import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const AllTraderMasterSection = styled.div`
	width: 100%;
	height: 900px;
	padding-bottom: 30px;
	background-color: ${p => p.theme.COLORS.bgColor};
	overflow-x: scroll;
	margin-bottom: 30px;
`
export const TraderMasterHeaderSection = styled.div`
	padding: 10px;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`
export const TraderMasterItemsSection = styled.div`
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
	width: 100%;
	height: 100%;
	border-top: 1px solid ${COLORS.lightGray2};
	&:hover {
		background-color: ${COLORS.lightGray};
	}
`
export const TraderMasterItems = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	width: 12.5%;
	margin-right: 1px;
`
export const TableHeaderText = styled.div`
	font-size: 12px;
	color: ${COLORS.lightGray5};
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
`
export const TableItemText = styled.div`
	font-size: 14px;
	color: ${COLORS.lightGray5};
	font-weight: 400;
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
`

export const TraderTableContainer = styled.div`
	.ag-cell-focus,
	.ag-cell-no-focus {
		border: none !important;
	}
	/* This CSS is to not apply the border for the column having 'no-border' class */
	.no-border.ag-cell:focus {
		border: none !important;
		outline: none;
	}
	.ag-root-wrapper {
		border: none !important;
		padding: 10px;
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
	.ag-header-cell {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
	.ag-header-cell-text {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-row-position-absolute {
		cursor: pointer;
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-row-summary-panel {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-page-summary-panel {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-button {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-row-even {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
	.ag-row-odd {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
`
