import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { useInfo24H } from '../../../../hooks/trade/info24H'
import ListItems from './ListItems'
import { TradingListsItemsSection } from './styles'

const TradingListsItems = ({ item }: any) => {
	const { isDarkMode } = useContext(ThemeContext)


	return (
		<TradingListsItemsSection
			style={{ background: isDarkMode ? COLORS.white : '#343140' }}>
			{item?.list?.map((item: any, index: any) => {
				return (
					<ListItems
						item={item}
						index={index}
						key={index.toString()}
					/>
				)
			})}
		</TradingListsItemsSection>
	)
}

export default React.memo(TradingListsItems)
