import React, { useState } from 'react'
import { ScrollableTabs } from 'app/mobile/components/'

import { MView } from 'styles/mobilePageStyles'
import MOrderPositions from './OrderPositions'
import MOrderCurrent from './CurrentOrders'
import MOrderHistory from './OrderHistory'
import MOrderRealized from './OrderRealized'
import MTradeHistory from './TradeHistory'
import MTradeModal from '../../../pages/MTradeModal'

const MAnnouncements = () => {
	const [tab, setTab] = useState(0)

	const tabs = [
		{
			title: 'positions',
			tab: 0,
		},
		{
			title: 'currentOrders',
			tab: 1,
		},
		{
			title: 'orderHistory',
			tab: 2,
		},
		{
			title: 'realizedpl',
			tab: 3,
		},
	]
	return (
		<MView style={{ width: '100%', height: '100%' }}>
			<ScrollableTabs tab={tab} setTab={setTab} tabs={tabs} />
			{tab === 0 ? <MOrderPositions /> : null}
			{tab === 1 ? <MOrderCurrent /> : null}
			{tab === 2 ? <MOrderHistory /> : null}
			{tab === 3 ? <MOrderRealized /> : null}

			<MTradeModal />
		</MView>
	)
}

export default MAnnouncements
