import React, { useContext } from 'react'
import { CDrawerHeader } from 'app/components/Common'
import DrawerAccordion from './DrawerAccordion'
import DrawerAuth from './DrawerAuth'
import { DrawerSection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { useToken } from 'app/hooks'

const HeaderDrawer = ({ displayNav, setDisplayNav }) => {
	const { isDarkMode } = useContext(ThemeContext)
	const { data: token } = useToken()
	return (
		<DrawerSection display={displayNav} isDarkMode={isDarkMode}>
			<CDrawerHeader onClick={setDisplayNav} />
			{!token ? <DrawerAuth /> : null}
			<DrawerAccordion />
		</DrawerSection>
	)
}

export default React.memo(HeaderDrawer)
