import React, { useState } from 'react'
import { CDrawerHeader } from 'app/components/Common/'
import { TradeDrawerSection } from './styles'
import TradeDrawerSearch from './TradeDrawerSearch'
import TradeDrawerTabBar from './TradeDrawerTabBar'
import TradeDataList from './TradeDataList'
import TradeFavouriteCoin from './TradeFavouriteCoin'
import { useFavourite } from '../../../hooks'
import CRenderEmpty from '../../CRenderEmpty'

const TradeDrawer = ({ setDisplayDrawer }) => {
	const FAVOURITE = 'favourites'
	const [search, setSearch] = useState('')
	const { data } = useFavourite()

	const [tab, setTab] = useState(data?.length ? FAVOURITE : 'USDT')
	return (
		<TradeDrawerSection>
			<CDrawerHeader onClick={setDisplayDrawer} />
			<TradeDrawerSearch search={search} setSearch={setSearch} />
			<TradeDrawerTabBar
				tab={tab}
				setTab={setTab}
				favourite={FAVOURITE}
			/>
			{tab === FAVOURITE ? (
				<TradeFavouriteCoin
					search={search}
					setDisplayDrawer={setDisplayDrawer}
				/>
			) : (
				<TradeDataList
					search={search}
					tab={tab}
					setDisplayDrawer={setDisplayDrawer}
				/>
			)}
		</TradeDrawerSection>
	)
}

export default React.memo(TradeDrawer)
