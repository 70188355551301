import React, { useContext } from 'react'
import CImage from 'app/components/Common/CImage'
import { CSSProperties } from 'styled-components'
import { ThemeContext } from 'theme/ThemeProvider'
import { AppLogoWrap } from './styles'

interface IProps {
	width?: string | number
	height?: number
	onPress?: () => void
	style?: CSSProperties
}

const AppLogo = ({ width, height, onPress, style }: IProps) => {
	const { isDarkMode } = useContext(ThemeContext)
	const lightLogo = '/images/lightLogo.png'
	const darkLogo = '/images/darkLogo.png'
	return (
		<AppLogoWrap onClick={onPress}>
			<CImage
				src={isDarkMode ? lightLogo : darkLogo}
				alt="logo"
				width={width ? width : 'auto'}
				height={height ? height : 'auto'}
				resizeMode={'cover'}
				style={style}
			/>
		</AppLogoWrap>
	)
}

export default React.memo(AppLogo)
