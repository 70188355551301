import React from 'react'
import { IconCircleCheck, IconCircleX } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { useMyProfile } from '../../../../hooks/user/myprofile'

const IdentifyHeader = ({ level, title }) => {
	const { t } = useTranslation()
	const { data: myinfo } = useMyProfile()
	let verified = false
	const mylevel = parseInt(myinfo?.level)
	const currentlevel = parseInt(level?.[0]?.level)

	if (currentlevel == 1) {
		if (mylevel >= 1 || mylevel == -1) {
			verified = true
		}
	} else if (currentlevel == 2) {
		if (mylevel >= 2) {
			verified = true
		}
	} else if (currentlevel == 3) {
		if (mylevel >= 3) {
			verified = true
		}
	}

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				background: '#F0FFF6',
				width: '100%',
				height: '10%',
				paddingInline: 10,
				borderTopRightRadius: 10,
				borderTopLeftRadius: 10,
			}}>
			<Text size={20} style={{ color: COLORS.black }}>
				{t(title)}
			</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={14}
					style={{
						marginRight: 10,
						color: COLORS.black,
					}}>
					{t(verified ? 'verified' : 'notverified')}
				</Text>
				{verified ? (
					<IconCircleCheck
						color={verified ? COLORS.lightGreen : COLORS.lightRed}
					/>
				) : (
					<IconCircleX
						color={verified ? COLORS.lightGreen : COLORS.lightRed}
					/>
				)}
			</View>
		</View>
	)
}

export default IdentifyHeader
