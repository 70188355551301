import useSWR from 'swr'
import { STORE_SYSTEM, THEME_STORE } from 'app/constants/LOCALKEYS'
import { getPersistData } from '../persist'

const KEY = STORE_SYSTEM

let swrData: string | null = getPersistData(KEY)
export function useSystemStore(initData?: any) {
	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (initData !== undefined) {
			swrData = initData
		}
		return swrData
	})

	return {
		data,
		mutate: (value: any) => {
			swrData = value
			return mutate()
		},
	}
}
