import React, { useState } from 'react'
import ReferralTableContainer from '../ReferralTableContainer'
import { RefferalSection } from '../styles'

const ReferralCommission = () => {
	const [tab, setTab] = useState('all')
	const data = []
	const menuList = [
		'all',
		'registered',
		'deposited',
		'traded',
		'qualifiedReferess',
		'notQualified',
		'pastReferee',
	]
	return (
		<RefferalSection>
			<ReferralTableContainer
				title={'referralCommission'}
				data={data}
				menuList={menuList}
				tab={tab}
				setTab={setTab}
			/>
		</RefferalSection>
	)
}

export default ReferralCommission
