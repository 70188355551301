import { ACCESS_TOKEN } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../persist'
import jwtDecode from 'jwt-decode'
import { useEffect, useMemo } from 'react'
import { message } from 'antd'

const KEY = ACCESS_TOKEN

let swrData: string | null = getPersistData(KEY)

export function useToken(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<string | null>(
		KEY,
		async () => {
			if (reset === true) {
				swrData = null
				return null
			}
			if (initData !== undefined) {
				swrData = initData
			}

			return swrData
		},
		{ refreshInterval: 60 * 1000 * 10, dedupingInterval: 60 * 1000 * 10 }
	)

	const userid = useMemo(() => {
		try {
			const decodedToken: any = jwtDecode(data + '')
			return decodedToken?.id || null
		} catch (e) {
			return null
		}
	}, [data])

	const expire = useMemo(() => {
		try {
			const decodedToken: any = jwtDecode(data + '')
			return decodedToken?.exp || null
		} catch (e) {
			return null
		}
	}, [data])

	
	const level = useMemo(() => {
		try {
			const decodedToken: any = jwtDecode(data + '')
			return decodedToken?.level || null
		} catch (e) {
			return null
		}
	}, [data])

	useEffect(() => {
		if (level == -9) {
			message.error('블랙')
			swrData = null
			mutate(null)
		} else if (level == -2) {
			message.error('거절')
			swrData = null
			mutate(null)
		}else if (level == -10) {
			message.error('모의 블랙')
			swrData = null
			mutate(null)
		}else if (level == -8) {
			message.error('승인 대기중입니다.')
			swrData = null
			mutate(null)
		}else if (level == -1) {
			message.error('승인 대기중입니다.')
			swrData = null
			mutate(null)
		}
	}, [level])

	return {
		data,
		level,
		userid,
		expire,
		refreshToken: async () => {
			const refresh = await API.refreshToken(swrData + '')
			if (refresh?.result) {
				message.success('세션이 갱신되었습니다.')
				swrData = refresh?.result
				mutate()
			} else {
				message.error('세션 갱신에 실패했습니다.')
				swrData = null
				mutate()
			}
		},
		mutate: (value: string | null) => {
			swrData = value
			return mutate()
		},
	}
}
