import useSWR from 'swr'
import API from '../../../utils/api'
import { TRADELIST } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'

let swrData: any = []
let swrData2: any = []
let mutateList: any = []

export function useTradeList(ep_id: number) {
	let KEY: any = TRADELIST + '?ep_id=' + ep_id

	if (!ep_id) KEY = null

	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (swrData[ep_id] != undefined) return swrData[ep_id] || []

		const result = await API.get(KEY, '', {}, false)
		swrData[ep_id] = result.result || []

		return swrData[ep_id]
	})

	swrData2[ep_id] = data
	if (mutateList[ep_id] == null) mutateList[ep_id] = mutate

	return {
		data,
		getData: (epid?: any) => {
			if (swrData2[epid ? epid : ep_id])
				return swrData2[epid ? epid : ep_id]
			else return null
		},
		mutate: (value?: any, epid?: any) => {
			if (value !== undefined) swrData[epid ? epid : ep_id] = value
			if (mutateList[epid ? epid : ep_id] == null) {
				return mutate()
			} else {
				return mutateList[epid ? epid : ep_id]()
			}
		},
		pushmutate: (value?: any, epid?: any) => {
			try {
				const temp = [
					...value,
					...(swrData[epid ? epid : ep_id] || []),
				]?.slice(0, 50)

				if (value !== undefined) swrData[epid ? epid : ep_id] = temp
				if (mutateList[epid ? epid : ep_id] == null) {
					return mutate()
				} else {
					return mutateList[epid ? epid : ep_id]()
				}
			} catch (e) {
				console.log('pushmutate', e)
			}
		},
	}
}
