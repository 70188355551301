import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { TradeDetailListHeaderSection } from './styles'

const TradeDetailListHeader = ({ dataType }) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const widthlist = [180,250,260,140,200,280,200]
	return (
		<TradeDetailListHeaderSection>
			{dataType?.map((item: any, index: any) => (
				<View
					style={{ width: widthlist[index], alignItems: 'flex-start' }}
					key={index.toString()}>
					<Text
						key={index.toString()}
						style={{ padding: 5, color: textsilver }}>
						{item && t(item)}
					</Text>
				</View>
			))}
		</TradeDetailListHeaderSection>
	)
}

export default React.memo(TradeDetailListHeader)
