import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import { useWindowDimensions } from 'app/hooks'

const TpslCheckBox = ({ title, checked, setChecked, color }) => {
	const { width } = useWindowDimensions()
	const { textGray } = React.useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const handleChange = () => {
		setChecked(!checked)
	}
	return (
		<CheckBox
			labelStyle={{ marginTop: 10, color: COLORS.primary }}
			inputStyle={{
				width: '1em',
				height: '1em',
				border: `1px solid ${checked ? color : COLORS.secondary}`,
				background: checked ? color : 'transparent',
			}}
			textStyle={{
				color: checked ? color : textGray,
				fontSize: width > 1400 ? 12 : 10,
			}}
			label={t(title)}
			value={checked}
			onChange={handleChange}
		/>
	)
}

export default TpslCheckBox
