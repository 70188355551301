import styled from 'styled-components'
import { IMAGES } from 'app/constants'
export const LeaderBoardSection = styled.div`
	width: 100%;
	height: 100%;
	padding-bottom: 30px;
	@media (max-width: 900px) {
		width: 100%;
	}
`
export const LeaderBoardMainViewSection = styled.div`
	width: 100%;
	height: 409px;
	margin: auto;
	display: flex;
	align-items: flex-end;
	flex-direction: row;
	background-image: url(${IMAGES.mainView});
	background-size: cover;
	background-position: center;
	@media (max-width: 600px) {
		flex-direction: column;
		margin-bottom: 60px;
		padding-top: 30px;
	}
`
