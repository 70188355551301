import React from 'react'
import { CheckBox } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import useTradeStore from 'app/hooks/trade/tradeStore'

const DerivativesCheckBox = ({ title, checked, action }: any) => {
	const { t } = useTranslation()
	const { data: store } = useTradeStore()
	const checkType = store?.checkType

	return (
		<CheckBox
			labelStyle={{ marginTop: 10 }}
			inputStyle={{
				width: '1em',
				height: '1em',
				border: `1px solid ${
					checkType
						? checkType === 'goodTillCanceled' ||
						  title === 'reduceOnly'
							? COLORS.lightGray
							: COLORS.lightGray5
						: COLORS.lightGray
				}`,
			}}
			textStyle={{
				whiteSpace: 'nowrap',
				fontSize: 12,
				color: checkType
					? checkType === 'goodTillCanceled' || title === 'reduceOnly'
						? COLORS.lightGray
						: COLORS.lightGray5
					: COLORS.lightGray,
			}}
			label={title && t(title)}
			value={checked}
			onChange={e => action(e.target.checked)}
		/>
	)
}

export default DerivativesCheckBox
