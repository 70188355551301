import React from 'react'
// import InverseContractsTrading from './InverseContractsTrading'
// import UsdcOptionsTrading from './UsdcOptionsTrading'
import UsdcPerpetualTrading from './UsdcPerpetualTrading'
import UsdtPerpetualTrading from './UsdtPerpetualTrading'
import { SubDropdownSection } from '../styles'
import { Text } from '../../../../../styles/reactnative'
interface IProps {
	subDropdown?: string
}

const SubDropdown = ({ subDropdown }: IProps) => {
	return (
		<SubDropdownSection>
			{subDropdown === 'usdtPerpetual' ? <UsdtPerpetualTrading /> : null}

			{/*subDropdown === 'usdcoptions' ? <UsdcOptionsTrading /> : null}
			{subDropdown === 'inverseContracts' ? (
				<InverseContractsTrading />
			) : null*/}
		</SubDropdownSection>
	)
}

export default SubDropdown
