import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const OrderHistoryList = ({ title, content, contentColor }: any) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingBlock: 5,
				width: '100%',
			}}>
			<MView style={{ flexDirection: 'row' }}>
				<MText style={{ fontWeight: '300' }}>{t(title)}</MText>
			</MView>
			<MView>
				<MText
					style={{
						color: contentColor ? contentColor : textw,
					}}>
					{content}
				</MText>
			</MView>
		</MView>
	)
}

export default React.memo(OrderHistoryList)
