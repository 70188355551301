import { useFetcher } from 'app/hooks/fetcher'
import React, { useDeferredValue } from 'react'
import { HotCoinSection } from './styles'
import HotCoinItemsNoData from './HotCoinItemsNoData'
import { useExchangeSetting } from '../../../../../hooks/trade/exchangeSetting'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'

const HotCoins = () => {
	let { EPs } = useExchangeStore()

	let HotCoinItems: any = React.lazy(() => import('./HotCoinItems'))

	return (
		<HotCoinSection>
			<React.Suspense
				fallback={
					<>
						{EPs?.map((item: any, index: any) => (
							<HotCoinItemsNoData key={index.toString()} item={item} />
						))}
					</>
				}>
				{EPs?.map((item: any, index: any) => (
					<HotCoinItems key={index.toString()} item={item} />
				))}
			</React.Suspense>
		</HotCoinSection>
	)
}

export default HotCoins
