export const market = [
	{ title: 'marketOverView', goTo: '/markets' },
	{ title: 'leaderboard', goTo: '/leaderboard' },
	// { title: 'marketData', goTo: '/markets/trends' },
	// {
	// 	title: 'launch',
	// 	message: 'notWorkingNow',
	// },
]

export const crypto = [
	{
		title: 'oneClickBuy',
		content: 'buyCryptoWithSeconds',
		message: 'notWorkingNow',
	},
	{
		title: 'p2pTradingFees',
		content: 'p2pTradingFeesContent',
		message: 'notWorkingNow',
	},
	{
		title: 'fiatDeposit',
		content: 'fiatDepositContent',
		message: 'notWorkingNow',
	},
]
export const trade = [
	{
		title: 'usdtPerpetual',
		content: 'perpetualContract',
		subdropdown: 'usdtPerpetual',
	},
	// {
	// 	title: 'usdcPerpetual',
	// 	content: 'perpetualContractUsingUSDC',
	// 	subdropdown: 'usdcPerpetual',
	// },
]

export const tools = [
	{
		title: 'copyTrading',
		content: 'letTopTradersWorkForYou',
		goTo: '/copytrade',
	},
	{
		title: 'tradingBot',
		content: 'smartTradesMadeEasy',
		message: 'notWorkingNow',
	},
	{
		title: 'leaderBoard',
		content: 'AccessAVarietyOfTradingTools',
		goTo: '/leaderboard',
	},
]

// export const assets = [
// 	{ title: 'Funding', goTo: 'copytrade' },
// 	{ title: 'Spot', goTo: 'copytrade' },
// 	{ title: 'Derivatives', goTo: 'copytrade' },
// 	{ title: 'USDC Derivatives', goTo: 'copytrade' },
// 	{ title: 'Earn', goTo: 'copytrade' },
// 	{ title: 'Trading Bot', goTo: 'copytrade' },
// ]

// export const orders = [
// 	{ title: 'Spot Order', goTo: 'copytrade' },
// 	{ title: 'Derivative Order', goTo: 'copytrade' },
// 	{ title: 'Earn Order', goTo: 'copytrade' },
// 	{ title: 'Loan Order', goTo: 'copytrade' },
// ]
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	market,
	trade,
	tools,
	crypto,
	// assets,
	// orders,
}
