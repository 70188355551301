import React from 'react'
import { Select } from 'antd'
import { View } from 'styles/reactnative'
import { FeedbackCategorySection } from '../../styles'
import FeedbackContainerLeft from './FeedbackContainerLeft'
import { useTranslation } from 'react-i18next'

const FeedbackCategory = ({ category, setCategory }) => {
	const { t } = useTranslation()
	const handleChange = (value: string) => {
		console.log(`aaaaa ${value}`)
		setCategory(value)
	}
	return (
		<FeedbackCategorySection>
			<FeedbackContainerLeft text={'category'} />
			<View
				style={{
					width: '80%',
					height: '100%',
					alignItems: 'flex-start',
				}}>
				<View style={{ alignItems: 'flex-end' }}>
					<Select
						defaultValue={t(category)}
						style={{ width: 120, background: 'transparent' }}
						onChange={handleChange}
						options={[
							{
								value: 'safety',
								label: 'safety',
							},
							{
								value: 'advanced',
								label: 'advanced',
							},
						]}
					/>
				</View>
			</View>
		</FeedbackCategorySection>
	)
}

export default FeedbackCategory
