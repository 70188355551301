import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import ConvertForm from './ConvertForm'
import ConvertPercentage from './ConvertPercentage'
import {
	ConvertAmountBody,
	ConvertAmountTitle,
	ConvertAmountWrapper,
} from './styles'

const ConvertAmount = () => {
	const [percentage, setPercentage] = useState('')
	const [amount, setAmount] = useState('')
	const { t } = useTranslation()
	return (
		<ConvertAmountWrapper>
			<ConvertAmountTitle>
				<Text size={14}>{t('amount')}</Text>
			</ConvertAmountTitle>
			<ConvertAmountBody>
				<ConvertForm amount={amount} setAmount={setAmount} />
				<ConvertPercentage
					percentage={percentage}
					setPercentage={setPercentage}
				/>
			</ConvertAmountBody>
		</ConvertAmountWrapper>
	)
}

export default ConvertAmount
