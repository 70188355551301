import React, { useContext } from 'react'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useInfo24H } from '../../../../hooks/trade/info24H'

const MarketTableListItems = ({ ep_id, type, item, color }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { data: price} = useInfo24H(ep_id);

	if(type == "volume") {
		item = price?.volume
	}else if(type == "rate") {
		item = price?.rate + "%"
	}else if(type == "price") {
		item = price?.price
	}else if(type == "high"){
		item = price?.high
	}else if(type == "low"){
		item = price?.low
	}


	return (
		<Text
			size={14}
			style={{ fontWeight: '600', color: color ? color : textw }}>
			{item}
		</Text>
	)
}

export default React.memo(MarketTableListItems)
