import styled from 'styled-components'

export const MQuickWatchSection = styled.div`
	padding-left: 16px;
	margin-top: 10px;
`
export const QuickWatchTabSection = styled.div`
	display: flex;
	flex-direction: row;
	height: 40px;
`
