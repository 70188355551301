import React from 'react'
import { PageMenuTabs } from 'app/components'
import { OrderSubTabSection } from './styles'

const OrderSubTabs = ({ data, tab, setTab }) => {
	return (
		<OrderSubTabSection>
			<PageMenuTabs
				tab={tab}
				setTab={setTab}
				data={data}
				bgColor={'transparent'}
				style={{
					alignItems: 'flex-start',
				}}
				activeColor={true}
			/>
		</OrderSubTabSection>
	)
}

export default OrderSubTabs
