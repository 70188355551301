import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderContainerHeaderSection, OrderHeaderItems } from './styles'

const OrderContainerHeader = ({ headers }) => {
	const { t } = useTranslation()
	return (
		<OrderContainerHeaderSection>
			{headers?.map((item: any, index: number) => (
				<OrderHeaderItems
					key={index.toString()}
					style={{ width: `${100 / headers.length}%` }}>
					{item && t(item)}
				</OrderHeaderItems>
			))}
		</OrderContainerHeaderSection>
	)
}

export default OrderContainerHeader
