import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from 'styles/reactnative'
import { CImage } from 'app/components/Common'
import { TheBitCardItemSection } from './styles'

const TheBitCardItems = ({ item, index, openModal, image = null }) => {
	const { drawerBgColor, content } = useContext(ThemeContext).theme.COLORS

	return (
		<TheBitCardItemSection
			onClick={() => openModal(item)}
			style={{
				background: drawerBgColor,
			}}>
			<View
				style={{
					width: '100%',
					height: '85%',
					borderRadius: 50,
				}}>
				<CImage
					src={image ? image : `https://picsum.photos/id/${index + 1}/200`}
					style={{ height: '100%', width: '100%' }}
				/>
			</View>
			<View
				style={{
					width: '100%',
					height: '15%',
				}}>
				<Text
					size={12}
					style={{
						fontWeight: '300',
						color: content,
						textAlign: 'center',
						lineHeight: 1.3,
					}}>
					{item?.content}
				</Text>
			</View>
		</TheBitCardItemSection>
	)
}

export default TheBitCardItems
