import React, { useState } from 'react'
import { COLORS, IMAGES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import AuthButtonLists from '../AuthButtonLists'
import { useToken } from 'app/hooks'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import CIUtils from '../../../../../utils/ciutils'
import { HeaderProfieSection, HeaderProfieUid } from '../styles'
import { CImage } from 'app/components/Common'
import { Button, Input, Popconfirm, message } from 'antd'
import API from '../../../../../utils/api'
import { ThemeContext } from '../../../../../theme/ThemeProvider'

const HeaderProfie = () => {
	const { data: token, mutate } = useToken()
	const { data: myinfo, mutate: mutateProfile } = useMyProfile()
	const [id, setId] = useState('')

	const { textw } = React.useContext(ThemeContext).theme.COLORS

	return (
		<HeaderProfieSection>
			<View
				style={{
					width: '100%',
					height: 50,
					marginTop: 20,
				}}>
				<CImage
					src={
						myinfo?.profileimage
							? myinfo?.profileimage
							: IMAGES.avatar
					}
					alt="logo"
					width={40}
					height={40}
					resizeMode={'cover'}
					style={{ borderRadius: 50 }}
				/>
			</View>
			<HeaderProfieUid>
				<Text size={16}>{myinfo?.nickname}</Text>

				{myinfo?.loginid ? (
					<Text
						size={14}
						style={{ color: COLORS.lightGray, marginTop: 5 }}>
						ID : {myinfo?.loginid}
					</Text>
				) : (
					<Popconfirm
						title={
							<>
								<Text
									style={{
										color: 'black',
									}}>
									로그인 아이디를 설정하시겠습니까?
								</Text>
								<Input
									placeholder="아이디를 입력해주세요"
									value={id}
									onChange={e => setId(e.target.value)}
								/>
							</>
						}
						okText="설정"
						cancelText="취소"
						onConfirm={() => {
							if (!id)
								return message.error('아이디를 입력해주세요')
							if (id.includes('@') || id.includes(' '))
								return message.error(
									'아이디는 이메일 형식이 아닌 문자로 입력해주세요'
								)
							API.post('/auth/setid', token + '', {
								loginid: id,
							}).then(res => {
								if (res?.result?.success){
									mutateProfile()
									message.success('아이디가 설정되었습니다')
								} 
								if (res?.result?.msgcode)
									message.error(res?.result?.msgcode)
							})
						}}>
						<Button
							size="small"
							type="primary"
							style={{
								margin: '5px 0',
							}}>
							로그인 아이디설정
						</Button>
					</Popconfirm>
				)}

				<Text
					size={14}
					style={{ color: COLORS.lightGray, marginTop: 5 }}>
					UID : {CIUtils.padId(myinfo?.id)}
				</Text>
			</HeaderProfieUid>
			<AuthButtonLists title={'accountSecurity'} go={'/settings?tab=1'} />
			<AuthButtonLists title={'referralProgram'} go={'/referral'} />
			<AuthButtonLists title={'audit'} go={'/settings?tab=2'} />
			{/* <AuthButtonLists
				title={'tradingViewAlerts'}
				go={'/settings?tab=4'}
			/> */}
			<AuthButtonLists title={'settings'} go={'/settings?tab=3'} />
			{/* <AuthButtonLists title={'myFeeRates'} go={'settings'} />
			<AuthButtonLists title={'myRewards'} go={'settings'} /> */}
			{/* <AuthButtonLists title={'subaccount'} go={'settings'} /> */}
			{/* <AuthButtonLists title={'api'} go={'/settings'} /> */}

			<AuthButtonLists
				title={'logout'}
				action={() => {
					mutate(null)
					// setTimeout(() => window.location.reload(), 100)
				}}
				style={{
					width: '50%',
					marginTop: 15,
					marginLeft: 130,
					alignItems: 'flex-end',
				}}
			/>
		</HeaderProfieSection>
	)
}

export default React.memo(HeaderProfie)
