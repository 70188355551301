import styled from 'styled-components'

export const PrivacyPolicySection = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const PrivacyPolicyListSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 20px;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
	}
`
export const PrivacyPolicyListTitle = styled.div`
	width: 35%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
`
export const PrivacyPolicyListContent = styled.div`
	width: 65%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		margin-top: 20px;
	}
`
