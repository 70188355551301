import { COLORS } from 'app/constants'
import BigNumber from 'bignumber.js'
import React from 'react'
import { Text, View } from 'styles/reactnative'

const BankInfo = ({ text, theme = true }: any) => {
	return (
		<View style={{ lineHeight: 1, alignItems: 'flex-end' }}>
			<Text
				style={
					theme
						? {
								justifyContent: 'flex-end',
						  }
						: { justifyContent: 'flex-end', color: 'black' }
				}>
				{text}
			</Text>
		</View>
	)
}

export default React.memo(BankInfo)
