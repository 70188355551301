import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { LeaderBoardHeaderText } from './styles'

const LeaderBoardTableHeader = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				padding: 15,
				paddingBottom: 5,
			}}>
			<LeaderBoardHeaderText>
				<Text size={18}>{t('rank')}</Text>
			</LeaderBoardHeaderText>
			<LeaderBoardHeaderText>
				<Text size={18}>{t('user')}</Text>
			</LeaderBoardHeaderText>

			<LeaderBoardHeaderText>
				<Text size={16}>{t('PNL')}</Text>
			</LeaderBoardHeaderText>
			<LeaderBoardHeaderText>
				<Text size={16}>{t('dailyInterestRate')}</Text>
			</LeaderBoardHeaderText>
		</View>
	)
}

export default LeaderBoardTableHeader
