import React from 'react'
import { IconCaretUp, IconCaretDown } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'

const DownUpBtns = ({ top, setTop }) => {
	return (
		<View
			style={{ marginLeft: 3 }}
			onClick={() => {
				if (top === 0) {
					setTop(1)
				} else if (top === 1) {
					setTop(2)
				} else {
					setTop(0)
				}
			}}>
			<CButton
				icon={
					<IconCaretUp
						size={12}
						color={top === 1 ? COLORS.primary : COLORS.lightGray}
					/>
				}
				style={{ padding: 0, marginBottom: -2.3 }}
			/>
			<CButton
				icon={
					<IconCaretDown
						size={12}
						color={top === 2 ? COLORS.primary : COLORS.lightGray}
					/>
				}
				style={{ padding: 0, marginTop: -2.3 }}
			/>
		</View>
	)
}

export default DownUpBtns
