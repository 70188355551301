import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { CImage } from 'app/components/Common'
import { AboutTheBitItems } from './styles'
import { MView } from 'styles/mobilePageStyles'

const AboutValueItems = ({ item, index }) => {
	const { t } = useTranslation()
	const { content, textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<AboutTheBitItems>
			<MView
				style={{
					flexDirection: 'row',
					width: '100%',
					alignItems: 'center',
				}}>
				<View
					style={{
						width: 40,
						height: 40,
						borderRadius: 50,
						background: isDarkMode ? '#f1f1f1' : '#ddd',
						marginRight: 15,
					}}>
					<CImage
						src={item?.image}
						style={{ height: 25, width: 25 }}
					/>
				</View>
				<Text size={20} style={{ color: textw }}>
					{t(item?.title)}
				</Text>
			</MView>
			<MView style={{ marginTop: 20 }}>
				<Text
					size={13}
					style={{
						fontWeight: '300',
						color: content,
						textAlign: 'left',
						lineHeight: 1.5,
					}}>
					{t(item?.content)}
				</Text>
			</MView>
		</AboutTheBitItems>
	)
}

export default AboutValueItems
