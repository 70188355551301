import React from 'react'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
const TraderInfoBody = () => {
	return (
		<View style={{ flexDirection: 'row' }}>
			<View style={{ alignSelf: 'flex-start', alignItems: 'flex-start' }}>
				<Text size={14}>7-Day ROI Rank</Text>
				<Text
					size={20}
					style={{
						fontWeight: 'bold',
						lineHeight: 1.5,
					}}>
					No.53
				</Text>
			</View>
			<View
				style={{
					width: 1,
					height: 30,
					backgroundColor: COLORS.borderColor,
					marginInline: 20,
				}}
			/>
			<View
				style={{
					alignSelf: 'flex-start',
					alignItems: 'flex-start',
				}}>
				<Text size={14}>Follower(s)</Text>
				<View style={{ flexDirection: 'row' }}>
					<Text
						size={20}
						style={{
							fontWeight: 'bold',
							lineHeight: 1.5,
						}}>
						18
					</Text>
					<Text
						size={14}
						style={{
							marginBottom: -5,
							marginLeft: 3,
						}}>
						/250
					</Text>
				</View>
			</View>
			<View
				style={{
					width: 1,
					height: 30,
					backgroundColor: COLORS.borderColor,
					marginInline: 20,
				}}
			/>
			<View style={{ alignSelf: 'flex-start', alignItems: 'flex-start' }}>
				<Text size={14}>7-Day ROI Rank</Text>
				<Text
					size={20}
					style={{
						fontWeight: 'bold',
						lineHeight: 1.5,
					}}>
					No.53
				</Text>
			</View>
		</View>
	)
}

export default TraderInfoBody
