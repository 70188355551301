import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useTranslation } from 'react-i18next'
import { CButton } from '../../../components/Common'
import { ThemeContext } from '../../../../theme/ThemeProvider'

const DwtModal = ({ setShowModal }) => {
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const btnData = [
		{ title: 'transfer', goTo: '' },
		{ title: 'deposit', goTo: '' },
		{ title: 'withdraw', goTo: '' },
	]

	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={t('action')}
				closeModal={() =>
					mutateModalStore({ ...modalStore, dwtModal: false })
				}
			/>
			{btnData?.map((item: any, index: any) => (
				<CButton
					key={index.toString()}
					title={t(item.title)}
					onClick={() => {
						if (item?.title === 'transfer') {
							mutateModalStore({
								...modalStore,
								dwtModal: false,
								transferModal: true,
							})
						} else if (item?.title === 'deposit') {
							mutateModalStore({
								...modalStore,
								dwtModal: false,
								depositModal: true,
							})
						} else if (item?.title === 'withdraw') {
							mutateModalStore({
								...modalStore,
								dwtModal: false,
								withdrawModal: true,
							})
						}
					}}
					style={{
						justifyContent: 'center',
						padding: 12,
						marginTop: 1,
						width: '100%',
						flex: 1,
						fontSize: 12,
						color: THEMECOLORS.textsilver,
						background: THEMECOLORS.drawerBgColor,
						fontWeight: '500',
					}}
				/>
			))}
		</View>
	)
}

export default React.memo(DwtModal)
