import React, { useEffect, useState } from 'react'
import { IconCategory } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { CButton, CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import kakaotalk from 'assets/icons/kakao-talk.png'
import telegram from 'assets/icons/telegram.png'
import { InviteFriendSection } from './styles'
import { toast } from 'react-toastify'
import { copyTextToClipboard, isIos } from '../../../../../utils/ciutils'

const InviteFriend = ({ referralLink }) => {
	const { t } = useTranslation()
	const [shareButton, setShareButton] = useState(false)
	useEffect(() => {
		const script = document.createElement('script')
		script.src = 'https://developers.kakao.com/sdk/js/kakao.js'
		script.async = true
		document.body.appendChild(script)

		// 스크립트가 로드 된 후 쉐어버튼 렌더링
		script.onload = () => {
			setShareButton(true)
		}

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	const createKakaoButton = () => {
		const w: any = window
		if (w.Kakao) {
			// 카카오 스크립트가 로드된 경우 init
			const kakao = w.Kakao
			if (!kakao.isInitialized()) {
				kakao.init('3b78973e09862a0109e4760fefb8338c')
			}
			kakao.Link.createDefaultButton({
				container: '#kakao-link-btn',
				objectType: 'feed',
				content: {
					title: '초대합니다',
					description: referralLink,
					// imageUrl 이 없으면 동작 안하기 때문에 default 이미지를 준비해 두기
					imageUrl:
						'http://bitkorea.net/images/darkLogo.png',
					link: {
						mobileWebUrl: referralLink,
						webUrl: referralLink,
					},
				},
				buttons: [
					{
						title: '웹으로 보기',
						link: {
							mobileWebUrl: referralLink,
							webUrl: referralLink,
						},
					},
				],
			})
		}
	}
	createKakaoButton()

	return (
		<InviteFriendSection>
			<View
				style={{
					width: '100%',
					alignSelf: 'center',
					marginTop: 17,
					flexDirection: 'row',
				}}>
				<CButton
					onClick={() => {
						toast(`${t('copied')}`)
						copyTextToClipboard(referralLink)
					}}
					title={t('inviteFriends')}
					style={{
						background: `linear-gradient(to right, #7438FF, #3860FF)`,
						color: COLORS.white,
						width: '40%',
						height: 35,
						alignSelf: 'flex-start',
					}}
				/>
				<View
					style={{
						width: '60%',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
					}}>
					<CButton
						id={'kakao-link-btn'}
						icon={
							<CImage
								src={kakaotalk}
								alt="logo"
								width={24}
								height={24}
								resizeMode={'cover'}
							/>
						}
						style={{
							background: COLORS.lightGray2,
							width: '25%',
							height: 35,
						}}
					/>
					<CButton
						onClick={() => {
							window.open(
								'https://telegram.me/share/url?url=' +
									referralLink +
									'&text=초대합니다.'
							)
						}}
						icon={
							<CImage
								src={telegram}
								alt="logo"
								width={24}
								height={24}
								resizeMode={'cover'}
							/>
						}
						style={{
							background: COLORS.lightGray2,
							width: '25%',
							height: 35,
						}}
					/>
				</View>
			</View>
		</InviteFriendSection>
	)
}

export default InviteFriend
