import React from 'react'
import ProofEvent from './ProofEvent'
import { ProofOfReserveSection } from './styles'
import LastestReserve from './LatestReserves'
import LatestReservesDetail from './LatestReservesDetail'
import AboutProofReserves from './AboutProofReserves'

const ProofOfReserves = () => {
	return (
		<ProofOfReserveSection style={{ width: '100%' }}>
			<ProofEvent />
			<LastestReserve />
			<LatestReservesDetail />
			<AboutProofReserves />
		</ProofOfReserveSection>
	)
}

export default ProofOfReserves
