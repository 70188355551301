import BigNumber from 'bignumber.js'
import React, { useContext, useState } from 'react'
import { PieChart, Pie, ResponsiveContainer, Sector } from 'recharts'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from '../../../../constants'
import { useBalance } from '../../../../hooks/user/mybalance'
import { MyAssetLeftSectionContainer } from './styles'
const renderActiveShape = (props, textw) => {
	const RADIAN = Math.PI / 180
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		// percent,
		value,
	} = props
	const sin = Math.sin(-RADIAN * midAngle)
	const cos = Math.cos(-RADIAN * midAngle)
	const sx = cx + (outerRadius + 5) * cos
	const sy = cy + (outerRadius + 5) * sin
	const mx = cx + (outerRadius + 5) * cos
	const my = cy + (outerRadius + 5) * sin
	const ex = mx + (cos >= 0 ? 1 : -1) * 22
	const ey = my
	const textAnchor = cos >= 0 ? 'start' : 'end'

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				fontSize={10}
				textAnchor={textAnchor}
				fill={textw}>{`${value ? value : ''}`}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				fontSize={12}
				dy={18}
				textAnchor={textAnchor}
				fill={textw}>
				{`(Rate ${isNaN(payload?.rate) ? '0' : payload?.rate}%)`}
			</text>
		</g>
	)
}

const MyAssetLeftSection = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const [activeIndex, setActiveIndex] = useState(0)
	const onPieEnter = (_: any, index: any) => {
		setActiveIndex(index)
	}

	const { data: spot } = useBalance('USDT', 0)
	const { data: futures } = useBalance('USDT', 1)

	const rate1 = BigNumber(spot?.balance)
		.dividedBy(BigNumber(spot?.balance).plus(futures?.balance))
		.multipliedBy(100)
		.toFormat(2)
	const rate2 = BigNumber(futures?.balance)
		.dividedBy(BigNumber(spot?.balance).plus(futures?.balance))
		.multipliedBy(100)
		.toFormat(2)

	const data = [
		{
			name: 'Spot',
			value: spot?.balance || 0,
			rate: rate1,
			fill: COLORS.secondary,
		},
		{
			name: 'Futures',
			rate: rate2,
			value: futures?.balance || 0,
			fill: COLORS.primary,
		},
	]
	return (
		<MyAssetLeftSectionContainer>
			<ResponsiveContainer width={'100%'} height="100%">
				<PieChart width={400} height={400}>
					<Pie
						activeIndex={activeIndex}
						activeShape={props => renderActiveShape(props, textw)}
						data={data}
						cx="50%"
						cy="50%"
						innerRadius={40}
						outerRadius={58}
						dataKey="value"
						onMouseEnter={onPieEnter}
					/>
				</PieChart>
			</ResponsiveContainer>
		</MyAssetLeftSectionContainer>
	)
}

export default MyAssetLeftSection
