import styled from 'styled-components'

export const MNotificationSection = styled.div`
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
`

export const MNotificationListSection = styled.div`
	width: 100%;
	height: 100%;
	padding-inline: 16px;
`
