import { IconCheck } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'

const CurrencyBtn = ({ title, item, setItem, setOpen }) => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const checkType = item?.value === title
	return (
		<CButton
			title={item?.label}
			icon={
				checkType ? (
					<IconCheck size={16} color={COLORS.primary} />
				) : null
			}
			onClick={() => {
				setItem(item?.value)
				setOpen(false)
			}}
			style={{
				color: checkType ? COLORS.primary : textw,
				width: '100%',
				background: gray,
				marginTop: 10,
				padding: 10,
			}}
		/>
	)
}

export default React.memo(CurrencyBtn)
