import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconSearch, IconX } from '@tabler/icons'
import { CButton, CInput } from 'app/components/Common/'
import { COLORS } from 'app/constants/STYLES'
import HeaderSearchList from './HeaderSearchList'
import { ThemeContext } from 'theme/ThemeProvider'
import { StyledInput, HeaderSearchSection } from './styles'
import { useWindowDimensions } from 'app/hooks'

const HeaderSearch = () => {
	const { gray, textw, drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const [hidden, setHidden] = useState(false)

	const inputRef = useRef<HTMLInputElement>(null)
	const containerRef = useRef<HTMLInputElement>(null)
	const [text, setText] = useState('')
	const [focused, setFocused] = useState(false)
	const { t } = useTranslation()
	const handleChange = (event: any) => {
		setText(event.target.value)
	}
	const clearInput = () => {
		setText('')
		if (inputRef.current) {
			inputRef.current.value = ''
		}
	}
	const handleOutsideClick = event => {
		if (
			containerRef.current &&
			!containerRef.current.contains(event.target)
		) {
			setFocused(false)
		}
	}
	useEffect(() => {
		if (width < 900) {
			setHidden(false)
		} else if (width < 1200) {
			setHidden(true)
		} else {
			setHidden(false)
		}
	}, [width])
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick)
		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [])

	return (
		<HeaderSearchSection ref={containerRef}>
			<form>
				{hidden ? (
					<CButton
						icon={<IconSearch size={14} color={textw} />}
						onClick={() => {
							setHidden(false)
						}}
						style={{
							background: drawerBgColor,
							width: 25,
							height: 25,
							borderRadius: 50,
						}}
					/>
				) : (
					<StyledInput className={'inputWithIcon'}>
						<CInput
							ref={inputRef}
							placeholder={t('searchCoin')}
							value={text}
							type={'text'}
							onChange={handleChange}
							style={{
								backgroundColor: gray,
								height: 30,
								marginTop: 10,
								paddingLeft: 35,
								width: 150,
								color: textw,
								borderRadius: 15,
								borderColor: focused
									? COLORS.BTN_HOVER
									: 'transparent',
								borderWidth: 1,
								transition: 'all 0.5s ease-out',
								overflow: 'hidden',
							}}
							onFocus={() => setFocused(true)}
						/>
						<div className="left-icon">
							<IconSearch size={14} color={textw} />
						</div>
						{text?.length ? (
							<div className="right-icon" onClick={clearInput}>
								<IconX size={12} />
							</div>
						) : null}
					</StyledInput>
				)}
			</form>
			{focused ? <HeaderSearchList text={text} /> : null}
		</HeaderSearchSection>
	)
}

export default React.memo(HeaderSearch)
