import React from 'react'
import {
	AccountInfoNavBarSection,
	AccountInfoNavBarSideContainer,
} from './styles'
import AccountInfoNavBarLeftSide from './AccountInfoNavBarLeftSide'
import AccountInfoNavBarRightSide from './AccountInfoNavBarRightSide'

const AccountInfoNavBar = () => {
	return (
		<AccountInfoNavBarSection>
			<AccountInfoNavBarSideContainer>
				<AccountInfoNavBarLeftSide />
				<AccountInfoNavBarRightSide />
			</AccountInfoNavBarSideContainer>
		</AccountInfoNavBarSection>
	)
}

export default AccountInfoNavBar
