import React from 'react'
import { Text, View } from 'styles/reactnative'
import { LeaderBoardHeaderText } from './styles'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'

const LeaderBoardRenderItem = ({ item, index }) => {
	const { t } = useTranslation()

	const rate =
		(item?.endBalance - item?.startBalance) / item?.startBalance > 0
			? (
					((item?.endBalance - item?.startBalance) /
						item?.startBalance) *
					100
			  ).toFixed(2)
			: 0
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				padding: 15,
			}}>
			<LeaderBoardHeaderText>
				<Text size={16}> {index + 1}</Text>
			</LeaderBoardHeaderText>
			<LeaderBoardHeaderText>
				<Text size={16}> {item.nickname}</Text>
			</LeaderBoardHeaderText>
			<LeaderBoardHeaderText style={{ paddingLeft: 5 }}>
				<Text size={16}>
					{' '}
					{new BigNumber(item?.pnl_price).toFormat(2)}
				</Text>
			</LeaderBoardHeaderText>
			<LeaderBoardHeaderText style={{ paddingLeft: 10 }}>
				<Text size={16}> {rate}%</Text>
			</LeaderBoardHeaderText>
		</View>
	)
}

export default React.memo(LeaderBoardRenderItem)
