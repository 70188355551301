import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const TradingBotSection = styled.div`
	position: static;
	top: 0;
	width: 100%;
	height: 472px;
	background-color: ${COLORS.darkBlue};
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const SectionWrap = styled.div`
	width: 100%;
	align-items: center;
	max-width: 1280px;
	display: flex;
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: space-between;
`
