import React, { useContext } from 'react'
import { CButton, CInput } from 'app/components/Common'
import { useTranslation } from 'react-i18next'

import { ClosingPriceQtySection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

const ClosingPriceInput = ({ closePrice, setClosePrice }) => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	return (
		<ClosingPriceQtySection>
			<CButton
				title={t('closingPriceUSDT')}
				onClick={() => {}}
				style={{
					alignSelf: 'flex-start',
					marginLeft: -5,
					color: textw,
					fontSize: 13,
				}}
			/>
			<CInput
				placeholder={closePrice ? closePrice : ''}
				value={closePrice}
				type={'text'}
				onChange={(e: any) => {
					setClosePrice(e.target.value)
				}}
				vStyle={{ padding: 0, flexDirection: 'row', width: '100%' }}
				style={{
					background: gray,
					color: textw,
					border: 'none',
					marginTop: 5,

					padding: 10,
					width: '100%',
					paddingRight: 60,
				}}
			/>
		</ClosingPriceQtySection>
	)
}

export default ClosingPriceInput
