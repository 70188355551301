import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
interface IProps {
	title: string
	amount: string
	follow?: boolean
}
const CopyTraderDetailList = ({ title, amount, follow }: IProps) => {
	const { t } = useTranslation()
	return (
		<View>
			<Text
				size={12}
				style={{ color: COLORS.lightGray4, marginBottom: 10 }}>
				{t(title)}
			</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={16}
					style={{ color: COLORS.greyWhite, fontWeight: 'bold' }}>
					{amount}
				</Text>
				{follow ? (
					<Text size={16} style={{ color: COLORS.lightGray4 }}>
						/1000
					</Text>
				) : null}
			</View>
		</View>
	)
}

export default CopyTraderDetailList
