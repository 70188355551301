import React, { useState, useContext } from 'react'
import { useWindowDimensions } from 'app/hooks'
import { CrossModalDescriptionBox, CrossModalWrapper } from './styles'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import CrossSliderBar from './CrossSliderBar'
import CrossModalHeader from './CrossModalHeader'
import CrossModalFooter from './CrossModalFooter'
import { CButton } from '../../../../../../components/Common'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import { useTradeSetting } from '../../../../../../hooks/trade/tradeSetting'
import { ThemeContext } from 'theme/ThemeProvider'

const CrossModal = ({ setShowModal }) => {
	const { borderColor, textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { t } = useTranslation()
	const { width, height } = useWindowDimensions()
	const wSize =
		width < 772
			? width / 1.5
			: width < 1400
			? width < 900
				? width / 2
				: width / 3
			: width / 4

	const CROSS = 'cross'
	const ISO = 'iso'
	const tabs = [CROSS, ISO]

	const { data: tradeStore } = useTradeStore()
	const { data: tradeSetting } = useTradeSetting(tradeStore.ep_id)

	const [tab, setTab] = useState(
		tradeSetting?.cross_iso === 'C' ? CROSS : ISO
	)

	const [leverage, setLeverage] = useState(tradeSetting?.leverage)

	return (
		<CrossModalWrapper
			style={{ width: wSize, height: height > 600 ? 550 : 450 }}>
			<CrossModalHeader closeModal={() => setShowModal(false)} />
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				{tabs?.map((item, index) => (
					<CButton
						key={index.toString()}
						title={item && t(item)}
						onClick={() => setTab(item)}
						style={{
							background:
								item === tab ? COLORS.primary : 'transparent',
							border:
								item !== tab
									? `1px solid ${borderColor}`
									: 'none',
							height: 35,
							width: '48%',
							justifyContent: 'center',
							alignItems: 'center',
							color: item === tab ? COLORS.white : textw,
						}}
					/>
				))}
			</View>

			<View style={{ width: '100%' }}>
				<CrossSliderBar
					leverage={leverage}
					setLeverage={setLeverage}
					maxLeverage={tradeStore.max_leverage}
				/>
				{leverage > 21 ? (
					<Text
						style={{
							marginTop: 25,
							color: COLORS.lightRed,
							fontSize: 11,
						}}>
						{t('crossLeverageDangerText')}
					</Text>
				) : null}
				<CrossModalDescriptionBox
					style={{
						background: isDarkMode
							? 'rgba(52, 49, 64, 0.1)'
							: 'rgba(52, 49, 64, 0.8)',
					}}>
					<Text size={12}>
						{tab === CROSS
							? t('crossModalDescription')
							: t('isoModalDescription')}
					</Text>
				</CrossModalDescriptionBox>

				<CrossModalFooter
					ep_id={tradeStore.ep_id}
					cross_iso={tab === CROSS ? 'C' : 'I'}
					leverage={leverage}
					closeModal={() => setShowModal(false)}
				/>
			</View>
		</CrossModalWrapper>
	)
}

export default React.memo(CrossModal)
