import { useFetcher } from 'app/hooks/fetcher'
import React, { useDeferredValue } from 'react'
import HotDerivativesItems from './HotDerivativesItems'

import { HotDerivativeSection } from './styles'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'

const HotDerivatives = () => {
	let { EPs } =useExchangeStore()


	return (
		<HotDerivativeSection>
			{EPs?.map((item: any, index: any) => (
				<HotDerivativesItems key={index.toString()} item={item} />
			))}
		</HotDerivativeSection>
	)
}

export default HotDerivatives
