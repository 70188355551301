import styled from 'styled-components'

export const NotificationNavBarSection = styled.div`
	width: 100%;
	height: 7%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 20px;
`
