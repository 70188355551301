import React from 'react'
import LeaderBoardMainView from './LeaderBoardMainView'
import LeaderBoardTable from './LeaderBoardTable'
import { LeaderBoardSection } from './styles'

const LeaderBoard = () => {
	return (
		<LeaderBoardSection>
			<LeaderBoardMainView />
			<LeaderBoardTable />
		</LeaderBoardSection>
	)
}

export default LeaderBoard
