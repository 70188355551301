import React from 'react'
import { CFlatList } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import { MView } from 'styles/mobilePageStyles'

import MOrderHistoryItems from './MOrderHistoryItems'
import { useFetcher } from 'app/hooks/fetcher'

const MOrderHistory = () => {
	const { height } = useWindowDimensions()
	let KEYURL = '/trade/orderlist?status=*&ep_id=*'

	const { data } = useFetcher(KEYURL, false)
	const renderItem = (item: any, index: any) => (
		<MOrderHistoryItems item={item} />
	)
	return (
		<MView
			style={{
				width: '100%',
				height: height - 85,
				paddingTop: 10,
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<CFlatList
				data={data?.result?.length ? data?.result : []}
				renderItem={renderItem}
				noDataTitle={'noOrderHistory'}
				itemSize={190}
			/>
		</MView>
	)
}

export default MOrderHistory
