import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CButton } from 'app/components/Common/'
import { COLORS, FONTS } from 'app/constants/STYLES'
import { DrawerAuthSection } from './styles'

const DrawerAuth = () => {
	const { t } = useTranslation()
	const navigate = useHistory()
	const goTo = (url: any) => {
		navigate.push(url)
	}
	return (
		<DrawerAuthSection>
			<CButton
				title={t('login')}
				onClick={() => {
					goTo('login')
				}}
				style={{
					width: '100%',
					...FONTS.h6,
					color: COLORS.white,
					fontWeight: '500',
					padding: 8,
				}}
				backgroundHover={COLORS.primary}
			/>

			<CButton
				title={t('signUp')}
				onClick={() => {
					goTo('signup')
				}}
				style={{
					width: '100%',
					...FONTS.h6,
					color: COLORS.white,
					fontWeight: '500',
					backgroundColor: COLORS.primary,
					padding: 8,
					marginTop: 10,
				}}
			/>
		</DrawerAuthSection>
	)
}

export default React.memo(DrawerAuth)
