import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { CImage } from 'app/components/Common'
import { AboutTheBitItems } from './styles'

const AboutTheBitValueItems = ({ item, index }) => {
	const { t } = useTranslation()
	const { drawerBgColor, content } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<AboutTheBitItems
			style={{
				background: drawerBgColor,
			}}>
			<Text size={20}>{t(item?.title)}</Text>
			<View
				style={{
					width: 72,
					height: 72,
					borderRadius: 50,
					background: isDarkMode ? '#f1f1f1' : '#ddd',
				}}>
				<CImage src={item?.image} style={{ height: 30, width: 30 }} />
			</View>
			<Text
				size={12}
				style={{
					fontWeight: '300',
					color: content,
					textAlign: 'center',
					lineHeight: 1.3,
				}}>
				{t(item?.content)}
			</Text>
		</AboutTheBitItems>
	)
}

export default AboutTheBitValueItems
