import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import { ProofHalf } from '../styles'
import { ThemeContext } from 'theme/ThemeProvider'
import avatar from 'assets/images/avatar.png'
import ReservesDetailHeader from './ReservesDetailHeader'
import ReservesDetailItems from './ReservesDetailItems'
const LatestReservesDetail = () => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const header = [
		{
			title: 'coin',
			width: '16',
		},
		{
			title: 'platformAssets',
			width: '28',
		},
		{
			title: 'userAssets',
			width: '28',
		},
		{
			title: 'reserveRatio',
			width: '28',
		},
	]
	const data = [
		{
			main_symbol: 'USDT',
			symbol_name: 'Theather USD',
			image: avatar,
			platform_assets: 100000000,
			user_assets: 1572.61,
			reserve_ratio: 100,
		},
	]

	return (
		<ProofHalf>
			<View
				style={{
					marginTop: 50,
					width: '100%',
					height: 300,
					background: drawerBgColor,
					borderRadius: 10,
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<ReservesDetailHeader header={header} />
				<View style={{ width: '100%' }}>
					{data?.map((item, index) => (
						<ReservesDetailItems
							key={index.toString()}
							item={item}
						/>
					))}
				</View>
			</View>
		</ProofHalf>
	)
}

export default LatestReservesDetail
