import { useWindowDimensions } from 'app/hooks'
import React, { useContext, useState } from 'react'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import MHeaderSearch from './MHeaderSearch'
import MSearchHistory from './MSearchHistory'
import MSearchHot from './MSearchHot'
import MSearchLists from './MSearchLists'
import { MobileSearchSection } from './styles'

const MobileSearch = ({ setOpenSearch }) => {
	const { width, height } = useWindowDimensions()
	const { isDarkMode } = useContext(ThemeContext)
	const [text, setText] = useState('')

	return (
		<MobileSearchSection isDarkMode={isDarkMode} style={{ width, height }}>
			<MHeaderSearch
				setOpenSearch={setOpenSearch}
				text={text}
				setText={setText}
			/>
			<MView style={{ height: height }}>
				{text.length ? (
					<MSearchLists search={text} />
				) : (
					<>
						<MSearchHistory setOpenSearch={setOpenSearch} />
						<MSearchHot setOpenSearch={setOpenSearch} />
					</>
				)}
			</MView>
		</MobileSearchSection>
	)
}

export default React.memo(MobileSearch)
