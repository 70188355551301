import React from 'react'
import { CButton } from 'app/components/Common'
import { View } from 'styles/reactnative'
import { COLORS } from 'app/constants'

const RankingTopTabs = ({ tab, setTab, tabs }: any) => {
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'flex-start',
				background: 'transparent',
			}}>
			{tabs.map((item: any, index: any) => (
				<CButton
					key={index.toString()}
					title={item}
					onClick={() => {
						setTab(item)
					}}
					style={{
						color:
							tab === item ? COLORS.primary : COLORS.lightGray5,
						fontWeight: '500',
						paddingBlock: 15,
						paddingInline: 20,
						backgroundColor: COLORS.white,
						boxShadow:
							tab === item
								? index === 1
									? '-3px 0px 15px 0px #ddd'
									: '5px 0px 3px  #eee'
								: '3px 3px 3px #eee',
						borderRadius: 0,
						borderTopLeftRadius: 10,
						borderTopRightRadius: tab === item ? 35 : 30,
						zIndex: tab === item ? 1 : 0,
						marginLeft: index !== 0 && -2,
					}}
					backgroundHover={COLORS.lightGray3}
				/>
			))}
		</View>
	)
}

export default RankingTopTabs
