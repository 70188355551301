import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'

const DerivativesAccountList = ({ title, amount }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { data: tradeStore } = useTradeStore()
	const currency = tradeStore?.pairs?.split('/')[1]

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				marginTop: 5,
			}}>
			<Text style={{ fontSize: 12, color: COLORS.lightGray }}>
				{title && t(title)}
			</Text>
			<Text style={{ fontSize: 12, color: textw }}>
				{BigNumber(amount).toFormat(3)} {currency}
			</Text>
		</View>
	)
}

export default DerivativesAccountList
