import React from 'react'
import { SectionContainer, SectionWrap } from '../../globalOrganismStyles'
import { CSwiper } from 'app/components/Common/'
import { SectionHeader } from 'app/components'
import MarketListItem from './MarketListItem'

import { SliderSize } from 'utils/ExpensiveFunction'

import { useFetcherPublic } from '../../../hooks/fetcher'

const MarketListing = () => {

	const renderHeader = () => <SectionHeader title={'newListing'} />
	const renderItem = (item: any, index: any) => (
		<MarketListItem item={item} index={index} />
	)
	return (
		<SectionContainer>
			<SectionWrap>
				<CSwiper
					data={[]}
					renderItem={renderItem}
					slidesPerView={SliderSize()}
					slidesPerGroup={SliderSize()}
					SwiperHeader={renderHeader}
				/>
			</SectionWrap>
		</SectionContainer>
	)
}

export default React.memo(MarketListing)
