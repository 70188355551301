import styled from 'styled-components'

export const AssetDerivativeSection = styled.div`
	display: flex;
	flex-direction: column;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
export const AssetSpotContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	margin-top: 30px;
`
export const AssetDerivativesHeaderSection = styled.div`
	width: 100%;
	height: 30%;
	padding-block: 20px;
	padding-inline: 30px;
	display: flex;
	flex-direction: column;
	background: ${p => p.theme.COLORS.drawerBgColor};
`
export const AssetDerivativesTableSection = styled.div`
	width: 100%;
	height: 69%;
	overflow-x: auto;
	background: ${p => p.theme.COLORS.drawerBgColor};
`
