import React, { useContext } from 'react'
import { AboutAppView } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MView } from 'styles/mobilePageStyles'

import AbroutExperience from './AboutExperience'
import AboutService from './AboutService'
import AboutServiceList from './AboutServiceList'
import AboutMission from './AboutMission'
import AboutJoin from './AboutJoin'
import AboutWelcome from './AboutWelcome'
import AboutFounder from './AboutFounder'
import AboutTheBitValues from './AboutTheBitValues'

const AboutUs = () => {
	const { isDarkMode } = useContext(ThemeContext)

	return (
		<MView style={{ width: '100%' }}>
			<AboutWelcome />
			<AboutAppView isDarkMode={isDarkMode}></AboutAppView>
			<AboutMission />
			<AbroutExperience />
			<AboutService />
			<AboutServiceList />
			{/* <AboutFounder /> */}
			<AboutTheBitValues />
			<AboutJoin />
		</MView>
	)
}

export default AboutUs
