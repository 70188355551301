import { IconCheck } from '@tabler/icons'
import { CoinLogo } from 'app/components'
import { COLORS } from 'app/constants'
import { useFetcher } from 'app/hooks/fetcher'
import { useChangeLanguage } from 'app/organisms/Header/BtnLanguageChange/hooks'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const ChangeLanguageContainer = ({ setOpen, item, setItem }) => {
	const { gray } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { i18n } = useTranslation()
	const [lang, setLang] = useState(i18n.language)

	useChangeLanguage(lang)
	const changeLanguage = lang => {
		setLang(lang.toLowerCase())
		toast(t('languageChanged') + '')
	}
	const { data } = useFetcher('/system/language')

	return (
		<MView style={{ width: '100%', padding: 15 }}>
			<MView
				style={{
					width: '100%',
					justifyContent: 'space-between',
					flexDirection: 'row',
				}}>
				<MText style={{ fontSize: 14 }}>{t('language')}</MText>
			</MView>
			<MView style={{ width: '100%' }}>
				{data?.result?.result?.map((listItem, index) => {
					return (
						<MView
							key={index.toString()}
							style={{
								paddingBlock: 10,
								flexDirection: 'row',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'space-between',
								background:
									listItem?.name === item
										? gray
										: 'transparent',
								paddingInline: 3,
							}}
							onClick={() => {
								setItem(listItem.name)
								changeLanguage(listItem.code)
								setOpen(false)
							}}>
							<MView
								style={{
									flex: 1,
									flexDirection: 'row',
									alignItems: 'center',
								}}>
								<CoinLogo
									src={listItem?.image}
									style={{ width: 20, height: 20 }}
								/>
								<MText style={{ marginLeft: 5 }}>
									{listItem.name}
								</MText>
							</MView>
							{item === listItem.name ? (
								<IconCheck size={16} color={COLORS.primary} />
							) : null}
						</MView>
					)
				})}
			</MView>
		</MView>
	)
}

export default ChangeLanguageContainer
