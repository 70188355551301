import React, { useEffect, useState } from 'react'
import { Button, Card, DatePicker, Empty, Space, Table } from 'antd'
import useSWR from 'swr'
import { useToken } from '../../hooks'
import API from '../../../utils/api'
import {
	InfoCircleOutlined,
	OrderedListOutlined,
	ReloadOutlined,
} from '@ant-design/icons'
import { goTo } from '../../hooks/navigation'
import { useHistory } from 'react-router'
import BigNumber from 'bignumber.js'

import dayjs from 'dayjs'
import moment from 'moment'
import NoData from '../Common/CFlatList/NoData'

interface TableAPIProps {
	title: any
	apikey: string
	columns: any
	width?: string | number | undefined
	stylewidth?: string | number | undefined
	height?: number | string | undefined
	pagination?: boolean
	usermodal?: boolean
	detail?: string
	datesearch?: boolean
	action?: any
	actionmodal?: any
	defaultMaxData?: number
	footer?: any
	fold?: boolean
	sharemutate?: string
}

const TableAPI = ({
	title,
	apikey,
	columns,
	width = '100%',
	stylewidth = '100%',
	height = 470,
	pagination = true,
	usermodal = false,
	detail = '',
	datesearch = false,
	action = () => {},
	actionmodal = () => {},
	defaultMaxData = 20,
	footer = 0,
	fold = false,
	sharemutate = '',
}: any) => {
	const { data: token } = useToken()
	const nav = useHistory()

	const [page, setPage] = useState(1)
	const [maxData, setMaxData] = useState(defaultMaxData)
	const [showModal, setShowModal] = useState(false)
	const [selectedData, setSelectedData] = useState<any>(null)
	const [startDate, setStartDate] = useState<any>(null)
	const [endDate, setEndDate] = useState<any>(null)

	const [edit, setEdit] = useState<any>(false)

	let footerTable: any = () => {
		return null
	}

	const dateurl = `${(startDate && `&startdate=${startDate}`) || ''}
	${(endDate && `&enddate=${endDate}`) || ''}`

	let KEY = apikey
		? apikey + dateurl + '&maxData=' + maxData + '&page=' + page
		: null

	let SHAREKEY = sharemutate
		? sharemutate + dateurl + '&maxData=' + maxData + '&page=' + page
		: null

	const { data, mutate } = useSWR<any>(
		token ? KEY : null,
		async () => {
			const response = await API.get(`${KEY}`, token + '', {})
			console.log('Table API', response)
			return response?.result?.result
		},
		{ revalidateOnFocus: false, dedupingInterval: 0 }
	)
	const { mutate: shareMutate } = useSWR<any>(
		token ? SHAREKEY : null,
		async () => {
			const response = await API.get(`${SHAREKEY}`, token + '', {})
			console.log('Table API', response)
			return response?.result?.result
		},
		{ revalidateOnFocus: false, dedupingInterval: 0 }
	)

	useEffect(() => {
		KEY = apikey
			? apikey + dateurl + '&maxData=' + maxData + '&page=' + page
			: null

		SHAREKEY = sharemutate
			? sharemutate + dateurl + '&maxData=' + maxData + '&page=' + page
			: null
	}, [page])

	const dataSource = data?.list || []

	return (
		<Card
			key={'card' + KEY}
			title={title}
			extra={
				<Space size="small">
					{datesearch && (
						<Space.Compact>
							<DatePicker
								onChange={e => setStartDate(e ? e : null)}
								defaultValue={
									startDate
										? dayjs(startDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
							<DatePicker
								onChange={e => setEndDate(e ? e : null)}
								defaultValue={
									endDate
										? dayjs(endDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
						</Space.Compact>
					)}

					{detail && (
						<Button
							onClick={() => {
								goTo(nav, detail)
							}}
							type="ghost"
							size="small">
							<OrderedListOutlined />
						</Button>
					)}

					<Button
						onClick={() => {
							mutate(1)
						}}
						type="ghost"
						size="small">
						<ReloadOutlined />
					</Button>

					{action && action(mutate)}
					{actionmodal && actionmodal(mutate)}
				</Space>
			}
			size="small"
			style={{
				width: '100%',
				height: '100%',
				margin: 0,
				padding: 0,
			}}>
			{dataSource?.length ? (
				<Table
					key={'table' + KEY}
					pagination={
						pagination
							? {
									current: page,
									total: data?.totalCount || 1,
									pageSize: maxData,
									onShowSizeChange: (current, size) => {
										setMaxData(size)
										setPage(1)
									},
									onChange: page => setPage(page),
							  }
							: false
					}
					footer={footerTable}
					dataSource={dataSource}
					columns={columns(
						mutate,
						data => {
							setSelectedData(data)
							setShowModal(true)
						},
						token,
						fold,
						edit,
						setEdit,
						shareMutate
					)}
					scroll={
						!height || height == 'default'
							? { x: width }
							: { x: width, y: height }
					}
					size="small"
					style={{
						width: stylewidth ? stylewidth : '100%',
						padding: 0,
						margin: 0,
					}}
				/>
			) : (
				<NoData
					mt={'10%'}
					title={
						apikey.includes('mypositions') ? 'NOPOSITONS' : 'NODATA'
					}
				/>
			)}
		</Card>
	)
}

export default React.memo(TableAPI)
