import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../../../../../../theme/ThemeProvider'
import { useTradeModalStore } from '../../../../../../hooks/trade/modalStore'
import { View } from '../../../../../../../styles/reactnative'
import CModalHeader from '../../../../../components/Modal/CModalHeader'
import PositionCloseTabs from './PositionCloseTabs'
import PositionCloseLimit from './PositionCloseLimit'
import PositionCloseMarket from './PositionCloseMarket'
const PositionCloseContainer = ({}) => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const [tab, setTab] = useState('L')
	const { data: modalStore, mutate } = useTradeModalStore()
	const item = modalStore?.positionClose?.item
	const setShowModal = value => {
		mutate({
			...modalStore,
			positionClose: {
				close: value,
			},
		})
	}

	if (!item) {
		return null
	}

	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={'positionClose'}
				closeModal={() =>
					mutate({ ...modalStore, positionClose: { close: false } })
				}
			/>
			<PositionCloseTabs tab={tab} setTab={setTab} />
			{tab === 'L' ? (
				<PositionCloseLimit item={item} setShowModal={setShowModal} />
			) : (
				<PositionCloseMarket item={item} setShowModal={setShowModal} />
			)}
		</View>
	)
}

export default React.memo(PositionCloseContainer)
