import React from 'react'
import { View } from 'styles/reactnative'
import { TitleText, TradeListTitleSection } from './styles'
import DownUpBtns from './DownUpBtns'
import { useTranslation } from 'react-i18next'

const TradeListTitle = ({ top, setTop }) => {
	const { t } = useTranslation()
	return (
		<TradeListTitleSection>
			<View
				style={{
					width: '35%',
					alignItems: 'center',
					justifyContent: 'flex-start',
					flexDirection: 'row',
					paddingLeft: 10,
				}}>
				<TitleText>{t('pairs')}</TitleText>
					<DownUpBtns top={top} setTop={setTop} />
			</View>
			<View
				style={{
					width: '45%',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'row',
				}}>
				<View style={{ flexDirection: 'row', width: '40%' }}>
					<TitleText>{t('price')}</TitleText>
				</View>
				<View style={{ flexDirection: 'row', width: '60%' }}>
					<TitleText style={{ fontSize: 12 }}>24H %</TitleText>
				</View>
			</View>
			<View
				style={{
					width: '20%',
					alignItems: 'center',
					justifyContent: 'flex-end',
					flexDirection: 'row',
				}}>
				<TitleText style={{ fontSize: 12 }}>{t('volume')}</TitleText>
			</View>
		</TradeListTitleSection>
	)
}

export default TradeListTitle
