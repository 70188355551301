import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { FeeTableItems } from './styles'

const FeeTableHeader = () => {
	const { t } = useTranslation()

	return (
		<View
			style={{
				flexDirection: 'row',
				width: 1500,
				height: 60,
			}}>
			<FeeTableItems style={{ width: 150 }}>{t('level')}</FeeTableItems>
			<FeeTableItems style={{ width: 400 }}>
				{t('monthTradeVolume')}
			</FeeTableItems>
			<FeeTableItems style={{ width: 150 }}>{t('andOr')}</FeeTableItems>
			<FeeTableItems style={{ width: 200 }}>
				{t('bnbBalance')}
			</FeeTableItems>
			<FeeTableItems style={{ width: 300 }}>
				{t('makerTakerUsdt')}
			</FeeTableItems>

			<FeeTableItems style={{ width: 400 }}>
				{t('makerTakerUsdt10')}
			</FeeTableItems>

			<FeeTableItems style={{ width: 300 }}>
				{t('makerTakerBusd')}
			</FeeTableItems>

			<FeeTableItems style={{ width: 400 }}>
				{t('makerTakerBusd10')}
			</FeeTableItems>
		</View>
	)
}

export default FeeTableHeader
