import { goTo } from 'app/hooks/navigation'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ListItems } from './styles'

const AccordionListItems = ({ item, index }: any) => {
	const navigation = useHistory()
	const { t } = useTranslation()
	return (
		<ListItems
			onClick={() => {
				goTo(navigation, item?.goTo)
			}}>
			{t(item?.title)}
		</ListItems>
	)
}

export default AccordionListItems
