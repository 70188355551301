import React from 'react'
import { CPagination } from 'app/components/Common'
import NotificationListItems from './NotificationListItems'
import { NotificationListSection } from './styles'

const NotificationList = ({ data, page, setPage, count }) => {
	const itemsPerPage = 20
	const totalPages = Math.ceil(data?.length / itemsPerPage)
	const isLastPage = page !== totalPages && data?.length % itemsPerPage !== 0

	return (
		<NotificationListSection>
			{data?.map((item, index) => (
				<NotificationListItems key={index.toString()} item={item} />
			))}
			{count > 20 ? (
				<CPagination
					count={count}
					dataLength={data?.length}
					lastData={isLastPage}
					page={page}
					setPage={setPage}
					style={{ marginTop: 30 }}
				/>
			) : null}
		</NotificationListSection>
	)
}

export default NotificationList
