import { CButton, CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import google from 'assets/icons/googleLogo.png'
import apple from 'assets/images/apple.png'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { AuthContainer } from './styles'
const SpecialAuth = () => {
	const { t } = useTranslation()
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const navigate = useHistory()
	return (
		<View style={{ width: '60%', height: '50%' }}>
			<CButton
				onClick={() => {
					navigate.push('/login')
				}}
				title={t('signInwithEmailOrPhone')}
				style={{
					background: COLORS.primary,
					color: COLORS.white,
					width: '100%',
					height: 40,
					borderRadius: 3,
				}}
			/>
			<AuthContainer>
				<CButton
					onClick={() => {
						message.open({
							key: 'updatable',
							content: 'The feature will be available soon.',
						})
					}}
					title={'Google'}
					icon={
						<CImage
							src={google}
							style={{ width: 14, height: 14, marginRight: 10 }}
						/>
					}
					style={{
						background: '#15131F',
						color: COLORS.white,
						flex: 1,
						height: 40,
						borderRadius: 3,
						marginRight: 30,
						flexDirection: 'row-reverse',
					}}
				/>
				<CButton
					onClick={() => {
						// navigate.push('/login')
						message.open({
							key: 'updatable',
							content: 'The feature will be available soon.',
						})
					}}
					title={'Apple'}
					icon={
						<CImage
							src={apple}
							style={{ width: 14, height: 14, marginRight: 10 }}
						/>
					}
					style={{
						background: '#15131F',
						color: COLORS.white,
						flex: 1,
						height: 40,
						borderRadius: 3,
						flexDirection: 'row-reverse',
					}}
				/>
			</AuthContainer>
		</View>
	)
}

export default SpecialAuth
