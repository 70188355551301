import styled from 'styled-components'

export const ScrollableTabSection = styled.div`
	display: flex;
	flex-direction: row;
	height: 40px;
	overflow-x: auto;
	width: 100%;
	white-space: nowrap;
	::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}
`
