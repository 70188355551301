import styled from 'styled-components'

export const MSearchHotSection = styled.div`
	margin-top: 20px;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	padding-inline: 16px;
`
