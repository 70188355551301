import React, { useContext } from 'react'
import { CheckBox } from 'app/components/Common'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	title?: string
	checked?: boolean
	setChecked?: any
}
const TradeSettingList = ({ title, checked, setChecked }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ marginBottom: 20 }}>
			<CheckBox
				labelStyle={{ marginLeft: 10, color: textw }}
				label={title}
				value={checked}
				onChange={() => {
					setChecked(!checked)
				}}
			/>
		</View>
	)
}

export default TradeSettingList
