import styled from 'styled-components'

export const OrderContainerNavBarSection = styled.div`
	height: 80px;
	width: 100%;
	border-block-end: 1px solid ${p => p.theme.COLORS.borderColor};
	flex-direction: row;
	display: flex;
	align-items: flex-start;
	@media only screen and (max-width: 900px) {
		flex-direction: column-reverse;
		height: 150px;
	}
`
