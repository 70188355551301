import React, { useContext } from 'react'
import { AboutUsSection, AboutUsSectionContent } from './styles'
import { Text } from 'styles/reactnative'
import AboutContext from './AboutContext'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'

const AboutWelcome = () => {
	const { isDarkMode } = useContext(ThemeContext)
	const { t } = useTranslation()

	return (
		<AboutUsSection isDarkMode={isDarkMode}>
			<Text size={44} style={{ color: COLORS.white }}>
				{t('aboutWelcome')}
			</Text>
			<AboutUsSectionContent>
				<AboutContext
					context={t('aboutWelcomeEventContent')}
					style={{ color: COLORS.lightGray3 }}
				/>
			</AboutUsSectionContent>
		</AboutUsSection>
	)
}

export default AboutWelcome
