import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const PositionCloseTabs = ({ tab, setTab }) => {
	const { borderColor, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const tabs = ['L', 'M']
	return (
		<View
			style={{
				width: '94%',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignSelf: 'center',
			}}>
			{tabs?.map((item, index) => (
				<CButton
					key={index.toString()}
					title={t(item== 'L' ? 'limit' : 'market')}
					onClick={() => setTab(item)}
					style={{
						background:
							item === tab ? COLORS.primary : 'transparent',
						border:
							item !== tab ? `1px solid ${borderColor}` : 'none',
						height: 35,
						width: '48%',
						justifyContent: 'center',
						alignItems: 'center',
						color: item === tab ? COLORS.white : textw,
					}}
				/>
			))}
		</View>
	)
}

export default PositionCloseTabs
