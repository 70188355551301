import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'

const MReferralHistoryHeader = () => {
	const { t } = useTranslation()
	return (
		<MView
			style={{
				width: '100%',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingBlock: 5,
			}}>
			<MView style={{ width: '33.333%', height: '100%' }}>
				<MText>{t('friendAccount')}</MText>
			</MView>
			<MView
				style={{
					width: '33.333%',
					height: '100%',
					alignItems: 'center',
				}}>
				<MText>{t('registeredDate')}</MText>
			</MView>
			<MView
				style={{
					width: '33.333%',
					height: '100%',
					alignItems: 'flex-end',
				}}>
				<MText>{t('assets')}</MText>
			</MView>
		</MView>
	)
}

export default MReferralHistoryHeader
