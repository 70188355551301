import React, {
	useState,
	useMemo,
	useRef,
	useCallback,
	useContext,
} from 'react'
import { AgGridReact } from 'ag-grid-react'
import { AllTraderMasterSection, TraderTableContainer } from './styles'
import { useWindowDimensions } from 'app/hooks'
import { loadData } from 'utils/api'
import { AllTraderMasterTableData } from './AllTraderMasterTableData'
import { CModal } from 'app/components/Common'
import CopyTradeModal from '../CopyTradeModal'
import { ThemeContext } from 'theme/ThemeProvider'

const AllTraderMaster = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const [showModal, setShowModal] = useState(false)
	const [selectedData, setSelectedData] = useState<any>(null)
	const closeModal = () => setShowModal(false)
	const openModal = (rowData: any, colDef: any) => {
		setSelectedData({
			field: colDef.field,
			data: rowData,
		})
		setShowModal(true)
	}

	const { width } = useWindowDimensions()
	const gridRef = useRef<any>()
	const [rowData, setRowData] = useState([])
	const [columnDefs] = useState(AllTraderMasterTableData())

	const onGridReady = useCallback(params => {
		loadData().then(res => {
			setRowData(res)
		})
	}, [])

	const defaultColDef = useMemo(
		() => ({
			flex: width > 1400 ? 1 : 0,
			minWidth: 200,
			sortable: true,
			resizable: true,
		}),
		[width]
	)

	return (
		<AllTraderMasterSection>
			<CModal visible={showModal} onClose={closeModal}>
				<CopyTradeModal
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</CModal>
			<TraderTableContainer
				className="ag-theme-alpine"
				style={{ width: '100%', height: 750 }}>
				<AgGridReact
					rowStyle={{
						justifyContent: 'center',
						alignItems: 'center',
						alignSelf: 'center',
						paddingTop: 10,
						color: textw,
					}}
					animateRows={true}
					ref={gridRef}
					rowData={rowData}
					columnDefs={columnDefs}
					onCellClicked={event => {
						openModal(event.data, event.colDef)
					}}
					// onRowClicked={({ data }: any) => {
					//     const coinName = data?.market?.slice(4);
					//     goTo(navigation, `/trade/${coinName}`);
					// }}
					defaultColDef={defaultColDef}
					onGridReady={onGridReady}
					rowHeight={60}
					paginationAutoPageSize={true}
					pagination={true}
				/>
			</TraderTableContainer>
		</AllTraderMasterSection>
	)
}

export default AllTraderMaster
