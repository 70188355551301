import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { CheckBox } from 'app/components/Common'
import { View } from 'styles/reactnative'
import CloseContext from './CloseContext'
import ClosingPercentage from './ClosingPercentage'
import ClosingPriceInput from './ClosingPriceInput'
import ClosingQtyInput from './ClosingQtyInput'
import { PositionCloseSection } from './styles'
import PositionCloseConfirm from './PositionCloseConfirm'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { ThemeContext } from 'theme/ThemeProvider'

const PositionCloseLimit = ({ item, setShowModal }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	let { getPrice } = useIndexPrice(item?.exchange_pairs_id)
	const indexPrice = getPrice(item?.exchange_pairs_id)
	const [percentage, setPercentage] = useState('100')
	const [closePrice, setClosePrice] = useState(
		BigNumber(indexPrice || 0).toFormat(3)
	)
	const [amount, setAmount] = useState(item?.amount)
	const [postOnly, setPostOnly] = useState(false)
	const handleChange = () => {
		setPostOnly(!postOnly)
	}

	return (
		<PositionCloseSection>
			<ClosingPriceInput
				closePrice={closePrice}
				setClosePrice={setClosePrice}
			/>
			<ClosingQtyInput amount={amount} setAmount={setAmount} />
			<ClosingPercentage
				percentage={percentage}
				setPercentage={setPercentage}
				amount={amount}
				setAmount={setAmount}
			/>
			<CloseContext />
			<View style={{ marginTop: 20, alignItems: 'flex-start' }}>
				<CheckBox
					labelStyle={{ marginLeft: 10, color: textw }}
					textStyle={{ color: textw, whiteSpace: 'nowrap' }}
					label={t('postOnly')}
					value={postOnly}
					onChange={handleChange}
				/>
			</View>
			<PositionCloseConfirm
				setShowModal={setShowModal}
				item={item}
				limitmarket={'L'}
				closePrice={closePrice}
				postOnly={postOnly}
				amount={amount}
			/>
		</PositionCloseSection>
	)
}

export default PositionCloseLimit
