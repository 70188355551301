import { useWindowDimensions } from 'app/hooks'
import { ScrollableTabs } from 'app/mobile/components'
import React, { useState } from 'react'
import MarketFavouriteDerivatives from './MarketFavouriteDerivatives'

import MarketFavouriteSpot from './MarketFavouriteSpot'

import { MMarketFavouriteSection } from './styles'

const MMarketFavourite = () => {
	const { height } = useWindowDimensions()
	const [tab, setTab] = useState(0)
	const tabs = [
		{
			title: 'derivatives',
			tab: 0,
		},
		{
			title: 'spot',
			tab: 1,
		},
	]
	return (
		<MMarketFavouriteSection style={{ height: height - 100 }}>
			<ScrollableTabs
				tab={tab}
				setTab={setTab}
				tabs={tabs}
				style={{ fontSize: 11 }}
			/>
			{tab === 0 ? <MarketFavouriteDerivatives /> : null}
			{tab === 1 ? <MarketFavouriteSpot /> : null}
		</MMarketFavouriteSection>
	)
}

export default MMarketFavourite
