import React, { useContext } from 'react'
import CButton from 'app/components/Common/CButton'
import { COLORS } from 'app/constants/STYLES'

import { TradeDetailHeaderSection } from './styles'
import useWindowDimensions from 'app/hooks/useWindowDimensions'
import { useTranslation } from 'react-i18next'
import { useFetcher, useFetcherPublic } from '../../../hooks/fetcher'
import { FloatBtnSection3 } from '../../../components/CFloatBtns/styles'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { IconArrowsMove } from '@tabler/icons'
import useTradeStore from '../../../hooks/trade/tradeStore'
import { Space, Switch, Tooltip } from 'antd'
import { View } from '../../../../styles/reactnative'
import { ThemeContext } from '../../../../theme/ThemeProvider'

const TradeDetailHeader = ({ tabs, setTabs }: any) => {
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { data: pos_list, orderlist: activeOrder } = useTradePosition()
	let openpositioncount = ' (' + (pos_list?.length || 0) + ')'
	let activecount = ' (' + (activeOrder?.length || 0) + ')'

	const data = [
		'positions',
		'closedpnl',
		'active',
		'filled',
		'cancel',
		'orderhistory',
	]
	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()
	return (
		<TradeDetailHeaderSection>
			<FloatBtnSection3
				style={{ marginRight: 20 }}
				className={'drag-handle'}>
				<IconArrowsMove />
			</FloatBtnSection3>
			<FloatBtnSection3
				className="widgetClose"
				onClick={() => {
					muateTradeModalStore({
						...tradeModalStore,
						detailshow: tradeModalStore?.detailshow ? false : true,
					})
				}}>
				X
			</FloatBtnSection3>
			<View style={{ flexDirection: 'row' }}>
				{data.map((item, index) => (
					<CButton
						key={index.toString()}
						title={
							t(item) +
							(item === 'active'
								? activecount
								: item === 'positions'
								? openpositioncount
								: '')
						}
						onClick={() => {
							setTabs(item)
						}}
						textHover={COLORS.primary}
						style={{
							padding: 7,
							paddingBottom: 10,
							marginRight: 10,
							color:
								tabs === item
									? COLORS.primary
									: COLORS.lightGray,
							borderBottomWidth: 1,
							borderBottomColor:
								tabs === item ? COLORS.primary : 'transparent',
							borderRadius: 0,
							fontSize: width > 900 ? 14 : width > 600 ? 10 : 8,
						}}
					/>
				))}
			</View>

			<Space style={{ marginRight: 40, color: textw }}>
				{t('current')}
				<Tooltip title={t('currentTooltip')}>
					<Switch
						checked={tradeModalStore?.iscurrent ? true : false}
						onChange={value => {
							muateTradeModalStore({
								...tradeModalStore,
								iscurrent: value,
							})
						}}
					/>
				</Tooltip>
			</Space>
		</TradeDetailHeaderSection>
	)
}

export default React.memo(TradeDetailHeader)
