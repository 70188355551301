import { useWindowDimensions } from 'app/hooks'
import { ScrollableTabs } from 'app/mobile/components'
import React, { useState } from 'react'
import MarketSpotDai from './MarketSpotDai'
import MarketSpotEur from './MarketSpotEur'
import MarketSpotUsdc from './MarketSpotUsdc'
import MarketSpotUsdt from './MarketSpotUsdt'

import { MMarketFavouriteSection } from './styles'

const MMarketSpot = () => {
	const { height } = useWindowDimensions()
	const [tab, setTab] = useState(0)
	const tabs = [
		{
			title: 'usdt',
			tab: 0,
		},
		{
			title: 'btc',
			tab: 1,
		},
		{
			title: 'usdc',
			tab: 2,
		},
		{
			title: 'dai',
			tab: 3,
		},
	]
	return (
		<MMarketFavouriteSection
			style={{ height: height - 100, paddingLeft: 10 }}>
			<ScrollableTabs
				tab={tab}
				setTab={setTab}
				tabs={tabs}
				style={{ fontSize: 11 }}
			/>
			{tab === 0 ? <MarketSpotUsdt /> : null}
			{tab === 1 ? <MarketSpotUsdc /> : null}
			{tab === 2 ? <MarketSpotDai /> : null}
			{tab === 3 ? <MarketSpotEur /> : null}
		</MMarketFavouriteSection>
	)
}

export default MMarketSpot
