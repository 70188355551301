import { IMAGES } from 'app/constants'
import styled from 'styled-components'

export const TraderDetailSection = styled.div`
	position: static;
	top: 0;
	width: 100%;
	height: 278px;
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
`
export const TraderDetailBackground = styled.div`
	background-image: url(${IMAGES.traderBg});
	background-size: 50% 248px;
	width: 100%;
	height: 248px;
	position: absolute;
	top: 0;
	left: 0;
`

export const SectionWrap = styled.div`
	width: 100%;
	align-items: center;
	max-width: 1280px;
	display: flex;
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: space-between;
	@media only screen and (max-width: 900px) {
		flex-direction: column;
	}
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const TraderDetailRightSideSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: flex-start;
	z-index: 1;
	height: 100%;
	width: 200px;
	@media only screen and (max-width: 900px) {
		align-self: center;
		margin-top: 20px;
		margin-left: 0;
	}
	@media only screen and (max-width: 600px) {
		margin-left: 40px;
	}
`
