import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useToken } from 'app/hooks'
import { toast } from 'react-toastify'
import useTradeStore from 'app/hooks/trade/tradeStore'
import { useOrderBook } from 'app/hooks/trade/orderBook'
import { useFetcher } from 'app/hooks/fetcher'
import { useBalance } from 'app/hooks/user/mybalance'
import API from 'utils/api'
import { View } from 'styles/reactnative'
import { useTradePosition } from '../../../../../../hooks/trade/tradePosition'
import { message } from 'antd'

const PositionCloseConfirm = ({
	setShowModal,
	item,
	limitmarket,
	closePrice,
	amount,
	postOnly,
}) => {
	const { t } = useTranslation()
	const { data: token } = useToken()
	const { data: tradeStore } = useTradeStore()
	const { mutate: mutateOrderBook } = useOrderBook(item?.exchange_pairs_id)
	const { mutate: mutatePosition } = useTradePosition()


	const sendOrder = async () => {
		if ( !tradeStore?.order_amount)
			return message.error(t('pleaseFillAllFields') + '')

		if (tradeStore?.tpsl) {
			if (isNaN(tradeStore?.tp) || isNaN(tradeStore?.sl)) {
				return message.error('CHECK TP/SL')
			}
		}
		const orderParams = {
			ep_id: item?.exchange_pairs_id,
			main_sub: 'M',
			long_short: item?.long_short === 'L' ? 'S' : 'L',
			cross_iso: item?.cross_iso,
			leverage: item?.leverage,
			reduce_position: 1,
			post_only: postOnly ? 1 : 0,
			limit_market: limitmarket,
			price: closePrice,
			amount: amount,
		}

		const result = await API.post('/trade/order', '' + token, orderParams)

		if (result?.result?.success) {
			setTimeout(() => {
				mutateOrderBook()
				mutatePosition()
			}, 500)
			toast(`${t('positionClosedSuccessfully')}`, {
				type: 'success',
			})
		} else {
			result?.result?.message &&
				toast(`${t(result?.result?.message)}`, {
					type: 'error',
					autoClose: 700,
				})
		}
		setShowModal(false)
	}
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '92%',
				marginTop: 20,
				position: 'absolute',
				bottom: 30,
			}}>
			<CButton
				title={t('confirm')}
				style={{
					flex: 1,
					padding: 10,
					background: COLORS.primary,
					marginRight: 10,
					color: COLORS.white,
				}}
				onClick={() => {
					sendOrder()
					setShowModal(false)
				}}
			/>
			<CButton
				title={t('cancel')}
				style={{
					flex: 1,
					padding: 10,
					background: COLORS.lightGray5,
					color: COLORS.greyWhite,
				}}
				onClick={() => {
					setShowModal(false)
				}}
			/>
		</View>
	)
}
export default PositionCloseConfirm
