import React from 'react'
import SettingTitle from '../SettingTitle'
import ProfileSettingContainer from './ProfileSettingContainer'
import { ProfileSettingSection } from './styles'

const ProfileSetting = () => {
	return (
		<ProfileSettingSection>
			<SettingTitle title={'settings'} />
			<ProfileSettingContainer />
		</ProfileSettingSection>
	)
}

export default ProfileSetting
