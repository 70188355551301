import styled from 'styled-components'

export const AnnouncementTitleSection = styled.div`
	margin-top: 40px;
	font-weight: 400;
	letter-spacing: 1px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: ${p => p.theme.COLORS.textw};
	@media only screen and (max-width: 600px) {
		padding-inline: 15px;
	}
`
