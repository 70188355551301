/**
 * Asynchronously loads the component for ZMobileTradingView
 */

import { lazyLoad } from 'utils/loadable'

export const ZMobileTradingView = lazyLoad(
	() => import('./index'),
	module => module.ZMobileTradingView
)
