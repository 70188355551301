import React from 'react'
import { LastestReserveSection } from './styles'
import { ProofHalf } from '../styles'
import LatestReservesHeader from './LatestReservesHeader'
import LatestReserveCards from './LatestReserveCards'

const LastestReserve = () => {
	return (
		<ProofHalf>
			<LastestReserveSection>
				<LatestReservesHeader />
				<LatestReserveCards />
			</LastestReserveSection>
		</ProofHalf>
	)
}

export default LastestReserve
