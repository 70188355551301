import React, { useState } from 'react'
import { useWindowDimensions } from 'app/hooks'
import { ScrollableTabs } from 'app/mobile/components'
import { MMarketFavouriteSection } from './styles'
import MarketDerivativesAll from './MarketDerivativesAll'
import MarketDerivativesUsdc from './MarketDerivativesUsdc'
import MarketDerivativesUsdt from './MarketDerivativesUsdt'
import MarketDerivativesInverse from './MarketDerivativesInverse'

const MMarketDerivatives = () => {
	const { height } = useWindowDimensions()
	const [tab, setTab] = useState(0)

	const tabs = [
		{
			title: 'all',
			tab: 0,
		},
		{
			title: 'usdtPerp',
			tab: 1,
		},
		{
			title: 'usdcPerp',
			tab: 2,
		},
		{
			title: 'inverse',
			tab: 3,
		},
	]
	//  { name: t('all'), Component: DerivativesMarketUsdt },
	// { name: 'USDT-Perp', Component: DerivativesMarketUsdt },
	// { name: 'USDC-Perp', Component: DerivativesMarketUsdc },
	// { name: t('inverse'), Component: DerivativesMarketInverse },
	return (
		<MMarketFavouriteSection
			style={{ height: height - 100, paddingLeft: 10 }}>
			<ScrollableTabs
				tab={tab}
				setTab={setTab}
				tabs={tabs}
				style={{ fontSize: 11 }}
			/>
			{tab === 0 ? <MarketDerivativesAll /> : null}
			{tab === 1 ? <MarketDerivativesUsdt /> : null}
			{tab === 2 ? <MarketDerivativesUsdc /> : null}
			{tab === 3 ? <MarketDerivativesInverse /> : null}
		</MMarketFavouriteSection>
	)
}

export default MMarketDerivatives
