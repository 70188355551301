import React, { useState } from 'react'
import { MView } from 'styles/mobilePageStyles'
import UserFeedbackTitle from './UserFeedbackTitle'
import UserFeedbackSatisfaction from './UserFeedbackSatisfaction'
import UserFeedbackCategory from './UserFeedbackCategory'
import UserFeedbackDetail from './UserFeedbackDetail'
import { UploadFile } from 'antd'
import UserFeedbackImage from './FeedbackImage'
import { CButton } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { createFeedback } from 'app/ActionApi/feedback'

const MUserFeedback = () => {
	const { t } = useTranslation()
	const [tab, setTab] = useState(1)
	const [content, setContent] = useState('')
	const [category, setCategory] = useState('')
	const [satisfaction, setSatisfaction] = useState(1)
	const [images, setImages] = useState<UploadFile[]>([])

	const addFeedBack = async () => {
		const res = createFeedback(images, content, satisfaction, category)
		setSatisfaction(1)
		setCategory('safety')
		setContent('')
		setImages([])
	}
	return (
		<MView style={{ paddingInline: 26, width: '100%' }}>
			<UserFeedbackTitle />
			<UserFeedbackSatisfaction tab={tab} setTab={setTab} />
			<UserFeedbackCategory
				category={category}
				setCategory={setCategory}
			/>
			<UserFeedbackDetail />
			<UserFeedbackImage images={images} setImages={setImages} />
			<CButton
				onClick={addFeedBack}
				title={t('summit')}
				style={{
					background: COLORS.secondary,
					color: COLORS.white,
					width: 100,
					height: 35,
					borderRadius: 5,
					marginTop: 20,
				}}
			/>
		</MView>
	)
}

export default MUserFeedback
