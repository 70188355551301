/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const FooterListSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const FooterListTitle = styled.div`
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
export const FooterItemsList = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -5px;
    margin-bottom: 10px;
`;
export const FooterListItemsContainer =
    styled.div <
    { show: boolean } >
    `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   ${({ show }) => !show && `display:none`}
`;
