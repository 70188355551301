/**
 * Asynchronously loads the component for FollowerTraderPage
 */

import { lazyLoad } from 'utils/loadable'

export const FollowerTraderPage = lazyLoad(
	() => import('./index'),
	module => module.FollowerTraderPage
)
