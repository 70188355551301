import styled from 'styled-components'

export const EventSliderSection = styled.div`
	width: 100%;
	height: 160px;
	padding-left: 16px;
	margin-block: 5px;
`
export const EventTabSection = styled.div`
	display: flex;
	flex-direction: row;
	height: 30px;
`
