import PageBorder from 'app/mobile/components/PageBorder'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
// import MAnnouncement from './Announcement'

import MCatergory from './Catergory'
import MEventSlider from './EventSlider'
import MNotice from './NoticeSlider'
import MQuickWatch from './QuickWatch'
import { HomeSection } from './styles'
import CRenderEmpty from '../../../components/CRenderEmpty'
import MSpecialDerivative from './SpecialDerivative'
import SpecialMobileDisplay from './SpecialMobileDisplay'
import { useTranslation } from 'react-i18next'
import { View } from '../../../../styles/reactnative'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import { goTo } from '../../../hooks/navigation'
import { useHistory } from 'react-router-dom'
const MHome = () => {
	const { isDarkMode, theme } = useContext(ThemeContext)
	const { t } = useTranslation()
	const { EPs } = useExchangeStore()
	const nav = useHistory()
	return (
		<HomeSection isDarkMode={isDarkMode}>
			<MCatergory />
			<PageBorder />
			<MEventSlider />
			<View
				onClick={() => {
					goTo(nav, '/trade/' + EPs[0].id)
				}}
				style={{
					backgroundColor: theme.COLORS.tradeBG,
					width: '100%',
					height: 60,
					justifyContent: 'center',
					fontSize: 16,
					cursor: 'pointer',
					alignItems: 'center',
					color: theme.COLORS.textw,
				}}>
				{t('tradeNow')}
			</View>
			<MNotice />
			<PageBorder />
			<MQuickWatch />
			<PageBorder />
			<MSpecialDerivative />
			<CRenderEmpty id="2" />
			<PageBorder />
			<SpecialMobileDisplay />
		</HomeSection>
	)
}

export default MHome
