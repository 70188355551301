import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { USERINFO } from 'app/constants/APIKEYS'
import { useToken } from 'app/hooks'
import { useMyProfile } from 'app/hooks/user/myprofile'
import { MButton } from 'app/mobile/components'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import API from 'utils/api'
import { apiError } from 'utils/ExpensiveFunction'

const ChangeUsername = () => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const [username, setUsername] = useState('')
	const { t } = useTranslation()
	const { mutate } = useMyProfile()
	const { data: token } = useToken()
	const modifyUsername = async () => {
		if (username?.length >= 5) {
			const res = await API.put(USERINFO, token + '', {
				nickname: username,
			})

			if (res?.result?.success) {
				toast(t('usernameChangeSuccess') + '')
				mutate()
			} else {
				const errorResult = apiError(t, res?.result?.msgcode)
				toast(t('invalidError') + ' ' + errorResult)
			}
		} else {
			toast(t('usernameAtLeastFive') + '')
		}
	}
	return (
		<MView
			style={{
				width: '100%',
			}}>
			<MView>
				<MText
					style={{
						fontSize: 14,
						fontWeight: '300',
					}}>
					{t('username')}
				</MText>
			</MView>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<CInput
					placeholder={t('enterUsername')}
					value={username}
					type={'text'}
					onChange={(e: any) => setUsername(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 7,
					}}
					style={{
						background: gray,
						border: 0,
						color: textw,
						padding: 8,
						paddingLeft: 15,
						width: '100%',
					}}
				/>
				<MButton
					title={'save'}
					onClick={modifyUsername}
					style={{
						paddingBlock: 8,
						background: COLORS.primary,
						marginTop: -3,
						marginLeft: 15,
						borderRadius: 5,
					}}
					textStyle={{ color: COLORS.white }}
				/>
			</MView>
			<MText style={{ fontSize: 10, color: COLORS.lightGray3 }}>
				{t('changeUsernameGuide')}
			</MText>
		</MView>
	)
}

export default ChangeUsername
