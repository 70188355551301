import { IconCopy } from '@tabler/icons'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { copyText } from 'utils/ExpensiveFunction'

const MOrderHistoryHeader = ({ item }) => {
	const { t, i18n } = useTranslation()
	const coinCode = `${item?.id}`
	const { borderColor, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView
			style={{
				borderBottom: `1px solid ${borderColor}`,
				paddingInline: 10,
				paddingBlock: 5,
				justifyContent: 'space-between',
				flexDirection: 'row',
				width: '100%',
			}}>
			<MView style={{}}>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						paddingBottom: 3,
					}}>
					<MText style={{ marginRight: 5 }}>{item?.pairs}</MText>
					<MView
						style={{
							background:
								item?.long_short === 'L'
									? COLORS.lightGreen
									: COLORS.lightRed,
							padding: 3,
							borderRadius: 3,
							alignItems: 'center',
							marginTop: -3,
						}}>
						<MText style={{ color: COLORS.white, fontSize: 8 }}>
							{item?.long_short === 'L' ? t('long') : t('short')}
						</MText>
					</MView>
				</MView>
				<MText style={{ fontWeight: '300' }}>
					{new Date(item?.created_at).toLocaleString(i18n.language)}
				</MText>
			</MView>
			<MView
				onClick={async () => {
					const res = await copyText(coinCode)
					if (res) {
						toast(t('copied') + '')
					}
				}}
				style={{ flexDirection: 'row', alignItems: 'center' }}>
				<MText>{coinCode}</MText>
				<IconCopy size={14} color={textw} />
			</MView>
		</MView>
	)
}

export default MOrderHistoryHeader
