import React from 'react'
import AccordionListItems from './AccordionListItems'
import { AccordionItemListSection } from './styles'

interface Iprops {
	list?: any
}
const AccordionItemList = ({ list }: Iprops) => {
	return (
		<AccordionItemListSection>
			{list?.map((item: any, index: any) => (
				<AccordionListItems
					item={item}
					index={index}
					key={index.toString()}
				/>
			))}
		</AccordionItemListSection>
	)
}

export default AccordionItemList
