import { TRADE_SOCKET } from '../../constants/LOCALKEYS'
import useSWR from 'swr'
import { DEFAULT_EP_ID } from '../../constants/DEFAULT'
import { useOrderBook } from './orderBook'
import { useTradeList } from './tradeList'
import { useInfo24H } from './info24H'
import useTradeStore from './tradeStore'
import BigNumber from 'bignumber.js'
import { useIndexPrice } from './indexprice'
import { useTradePosition } from './tradePosition'
import { useCallback, useEffect, useState } from 'react'
import { useChartDataFeed } from './chartDataFeed'
import { useToken } from '../auth/token'
import { message } from 'antd'
import { useExchangeStore } from './exchangeStore'
import { useMitOrder } from './mitorder'
import API from '../../../utils/api'
import { useTranslation } from 'react-i18next'
import { useFps } from 'react-fps'
import throttle from 'lodash/throttle'
import { useLogQueue } from './logQueue'

// WebSocket 인스턴스 생성
let tradeWS: WebSocket | null = null
let tradeMessage: any = null
let lastBar: any = null

let throttleOrderBook: any = []
const throttleOrderBookInterval = 50

let lastprice = new Map()
let amtbuffer = 0
let lastOrderBook = new Map()
let changeload = false

let bufferTradeList: any = []
let throttleTradeList: any = []
const throttleTradeListInterval = 50
let current = null
let mitdata: any = {}
let orderworker2: any = []

let grouplist: any = []

const joinGroup = (group: string, ep_id?: string) => {
	if (group == 'user' && !ep_id) return

	if (!grouplist.includes(ep_id) && ep_id) {
		grouplist.push(ep_id)
	}

	if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
		tradeWS?.send(
			`{"type":"join","group": "${group}", "ep_id": "${ep_id}"}`
		)
	} else {
		setTimeout(() => {
			joinGroup(group, ep_id)
		}, 1000)
	}
}

const leaveGroup = (group: string, ep_id?: string) => {
	if (grouplist.includes(ep_id) && ep_id) {
		grouplist = grouplist.filter(item => item != ep_id)
	}

	if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
		tradeWS?.send(
			`{"type":"leave","group": "${group}", "ep_id": "${ep_id}"}`
		)
	} else {
		setTimeout(() => {
			leaveGroup(group, ep_id)
		}, 1000)
	}
}

export function useTradeSocket(url) {
	const { currentFps } = useFps(15)
	const [fps, setFps] = useState(0)

	const { data: tradeStore, chart_id } = useTradeStore()
	const { data: token, userid } = useToken()
	const { t } = useTranslation()
	const { data: updatechart } = useChartDataFeed()
	const { data: mit, mutate: mutateMit } = useMitOrder()
	const { myip, data: logqueue, popLog } = useLogQueue()
	mitdata = mit

	if (logqueue?.length > 0 && token) {
		if (tradeWS != null) {
			if (tradeWS?.readyState === WebSocket.OPEN) {
				const execLog = popLog()
				if (execLog) {
					tradeWS.send(
						`{"type":"LOG", "token":"${token}", "data": ${JSON.stringify(
							execLog
						)}, "ip": "${myip}" }`
					)
				}
			}
		}
	}

	useEffect(() => {
		if (currentFps >= 50) {
			setFps(120)
		} else if (currentFps > 40) {
			setFps(60)
		} else if (currentFps > 20) {
			setFps(20)
		} else {
			setFps(0)
		}
	}, [currentFps])

	const sendOrder = useCallback(
		async (
			long_short: string,
			price: number,
			amount: number,
			ep_id: number,
			main_sub: string,
			leverage: number,
			limit_market: string,
			post_only: number,
			reduce_position: number,
			cross_iso: string,
			tpsl: any,
			tp: any,
			tptype: any,
			sl: any,
			sltype: any,
			after: any
		) => {
			const orderParams = {
				ep_id: ep_id,
				main_sub: main_sub || 'M',
				long_short: long_short,
				cross_iso: cross_iso,
				leverage: leverage,
				reduce_position: reduce_position,
				post_only: post_only,
				limit_market: limit_market,
				price: price,
				amount: amount,
				tpsl: tpsl,
				tp: tp,
				sl: sl,
				tptype: tptype,
				sltype: sltype,
			}

			const result = await API.post(
				'/trade/order',
				'' + token,
				orderParams
			)

			console.log('result', result)

			if (result.result.success) {
				mutateOrderBook()
				mutatePosition()
				after()
				message.success(t('ordersuccess') + '')
			} else {
				message.error(t(result?.result?.message + '') + '')
			}
		},
		[token, tradeStore?.ep_id]
	)

	const { EPs } = useExchangeStore()
	const {
		data: indexPrice,
		getPrice,
		mutate: mutateIndexPrice,
	} = useIndexPrice(tradeStore?.ep_id || DEFAULT_EP_ID)

	const { data: orderbook, mutate: mutateOrderBook } = useOrderBook(
		tradeStore?.ep_id || DEFAULT_EP_ID
	)
	const { pushmutate: mutateTradeList } = useTradeList(
		tradeStore?.ep_id || DEFAULT_EP_ID
	)
	const { mutate: mutatePosition } = useTradePosition()

	const { mutate: muateInfo24 } = useInfo24H(tradeStore?.ep_id)

	current = tradeStore?.ep_id

	useEffect(() => {
		lastBar = null
		//console.log('tradeSocket', tradeStore?.ep_id)
		tradeWS &&
			tradeWS?.readyState === WebSocket.OPEN &&
			tradeWS?.send(
				`{"type":"join","group": "user", "ep_id": "${current}"}`
			)
	}, [current])

	useEffect(() => {
		//console.log('useEffect ep_change', current, chart_id)
		lastBar = null
		changeload = true

		console.log('useEffect ep_change', current, chart_id, changeload)
		setTimeout(() => {
			if (chart_id == current) changeload = false
			//console.log('useEffect ep_changed', current, chart_id)
		}, 3000)
	}, [current, chart_id])

	const handleOrderBook = useCallback(
		throttle(async (trade, lastPrice) => {
			//Tep_id|숏|롱
			if (!throttleOrderBook[trade?.ep_id]) {
				throttleOrderBook[trade?.ep_id] = true
				setTimeout(() => {
					throttleOrderBook[trade?.ep_id] = false
				}, throttleOrderBookInterval)

				const orderbook = lastOrderBook.get(current)

				if (trade?.from == 'binance') {
					mutateOrderBook({
						long: orderbook?.long,
						short: orderbook?.short,
					})
				} else {
					if (lastPrice != 0) {
						trade.long =
							trade?.long?.filter(
								longitem =>
									+longitem?.split('|')[0] <= +lastPrice
							) || []
						trade.short =
							trade?.short?.filter(
								shortitem =>
									+shortitem?.split('|')[0] >= +lastPrice
							) || []
					}
				}
			}
		}, 220 - fps),
		[mutateOrderBook, current, fps]
	)

	const handleTradeOrder = useCallback(
		throttle(async trade => {
			if (!bufferTradeList[trade?.ep_id])
				bufferTradeList[trade?.ep_id] = []

			setImmediate(() => {
				const mits = mitdata?.['MIT' + trade?.ep_id]

				if (!mits?.length) {
					return
				}
				for (const mit of mits) {
					console.log(
						'MIT',
						mit?.longshort,
						+trade?.price >= +mit?.price,
						+trade?.price,
						+mit?.price
					)
					if (mit?.trigger_type == 'BIG') {
						if (+trade?.price >= +mit?.trigger_price) {
							console.log(
								'LONG',
								trade?.price,
								mit?.price,
								+trade?.price >= +mit?.price
							)
							if (!orderworker2.includes(mit)) {
								orderworker2.push(mit)
								sendOrder(
									mit?.longshort,
									mit?.price,
									mit?.amount,
									trade?.ep_id,
									mit?.main_sub,
									mit?.leverage,
									mit?.limit_market == 'CL' ? 'L' : 'M',
									mit?.post_only,
									mit?.reduce_position,
									mit?.cross_iso,
									mit?.tpsl,
									mit?.tp,
									mit?.tptype,
									mit?.sl,
									mit?.sltype,
									() => {
										orderworker2 = orderworker2.filter(
											(i: any) => i != mit
										)
									}
								)

								mutateMit(
									mitdata?.['MIT' + trade?.ep_id]?.filter(
										(i: any) => i != mit
									) || [],
									trade?.ep_id
								)
							}
						}
					} else {
						if (+trade?.price <= +mit?.trigger_price) {
							if (!orderworker2.includes(mit)) {
								orderworker2.push(mit)
								sendOrder(
									mit?.longshort,
									mit?.price,
									mit?.amount,
									trade?.ep_id,
									mit?.main_sub,
									mit?.leverage,
									mit?.limit_market == 'CL' ? 'L' : 'M',
									mit?.post_only,
									mit?.reduce_position,
									mit?.cross_iso,
									mit?.tpsl,
									mit?.tp,
									mit?.tptype,
									mit?.sl,
									mit?.sltype,
									() => {
										orderworker2 = orderworker2.filter(
											(i: any) => i != mit
										)
									}
								)
								mutateMit(
									mitdata?.['MIT' + trade?.ep_id]?.filter(
										(i: any) => i != mit
									) || [],
									trade?.ep_id
								)
							}
						}
					}
				}
			})
		}, 300 - fps),
		[mitdata, EPs, fps]
	)

	const handleTradeMessage = useCallback(
		async trade => {
			if (changeload) {
				lastBar = null

				if ((chart_id || current) == trade.ep_id) {
					setTimeout(() => {
						changeload = false
					}, 3000)
				}
				return
			}

			if (
				(chart_id || current) == trade.ep_id &&
				updatechart?.updatebars !== null &&
				typeof updatechart?.updatebars === 'function'
			) {
				const time = +Math.floor(trade.timestamp / 60000) * 60000

				let bar = {
					ep_id: trade.ep_id,
					time: +time,
					close: +lastprice.get(+trade.ep_id) || trade.price,
					high: +lastprice.get(+trade.ep_id) || trade.price,
					low: +lastprice.get(+trade.ep_id) || trade.price,
					open: +lastprice.get(+trade.ep_id) || trade.price,
					volume: +amtbuffer,
				}
				amtbuffer = 0
				if (lastBar === null || bar.time > lastBar?.time) {
					updatechart?.updatebars(bar)
					lastBar = bar
				} else if (bar.time == lastBar?.time) {
					bar = {
						ep_id: +trade.ep_id,
						time: bar.time,
						close: +trade.price,
						high: Math.max(+lastBar.high, +trade.price),
						low: Math.min(+lastBar.low, +trade.price),
						open: lastBar.open,
						volume: lastBar.volume + bar.volume,
					}
					updatechart?.updatebars(bar)
					lastBar = bar
				} else {
					console.log('??? BAR')
					updatechart?.updatebars(bar)
					lastBar = bar
				}
			}
		},
		[updatechart?.updatebars, chart_id, current, fps, changeload]
	)

	const handleTradePrice = useCallback(
		throttle(async (trade, indexPrice) => {
			setImmediate(() => {
				mutateIndexPrice(
					[
						{ price: lastprice.get(+trade.ep_id) || 0 },
						{ price: indexPrice },
					],
					trade.ep_id
				)
			})
		}, 120 - fps),
		[fps, lastprice]
	)

	const onmessage = async (data: any) => {
		const getDATA = await data.data.text()
		const trade = JSON.parse(getDATA)
		const lp = getPrice(trade?.ep_id)
		try {
			if (trade.type == 'REFRESH') {
				if (trade?.userid == userid) {
					mutatePosition()
					console.log('REFRESH', trade)
				}
			} else if (trade.type == 'ALARM') {
				if (trade?.user_id == userid) {
					const EP = EPs?.find(
						(item: any) => item?.id == trade?.ep_id
					)
					message.warning(
						EP.pairs + ' 지정가 도달 알람 : 가격 : ' + trade?.price
					)
				}
			} else if (trade.type === 'ORDERBOOK') {
				//console.log('ORDERBOOK', trade, current)
				lastOrderBook.set(trade?.ep_id, trade)
				handleOrderBook(trade, lp)
			} else if (trade.type === 'ORDER') {
				// console.log('ORDER', trade)
				let updateorder = true
				if (updateorder) {
					let templong = orderbook?.long || []
					let tempshort = orderbook?.short || []

					if (trade.long_short == 'L') {
						if (
							orderbook?.long?.find(item => {
								let temp = item?.split('|')
								item = {
									price: new BigNumber(temp[0]).toNumber(),
									amount: new BigNumber(temp[1]).toNumber(),
								}
								return item.price == trade.price
							})
						) {
							templong = orderbook?.long.map(item => {
								let temp = item?.split('|')
								item = {
									price: new BigNumber(temp[0]).toNumber(),
									amount: new BigNumber(temp[1]).toNumber(),
								}
								if (+item.price == +trade.price) {
									return `${trade.price}|${BigNumber(
										item.amount
									)
										.plus(trade.amount)
										.toNumber()}`
								} else {
									return `${item.price}|${item.amount}`
								}
							})
						} else {
							templong = [
								...orderbook?.long,
								`${trade.price}|${trade.amount}`,
							].sort((a, b) => {
								a = BigNumber(a.split('|')[0]).toNumber()
								b = BigNumber(b.split('|')[0]).toNumber()
								return b - a
							})
						}
					} else {
						if (
							orderbook?.short?.find(item => {
								let temp = item.split('|')
								item = {
									price: new BigNumber(temp[0]).toNumber(),
									amount: new BigNumber(temp[1]).toNumber(),
								}
								return item.price == trade.price
							})
						) {
							tempshort = orderbook?.short?.map(item => {
								let temp = item.split('|')
								item = {
									price: new BigNumber(temp[0]).toNumber(),
									amount: new BigNumber(temp[1]).toNumber(),
								}
								if (+item.price == +trade.price) {
									return `${trade.price}|${BigNumber(
										item.amount
									)
										.plus(trade.amount)
										.toNumber()}`
								} else {
									return `${item.price}|${item.amount}`
								}
							})
						} else {
							tempshort = [
								...(orderbook?.short || []),
								`${trade.price}|${trade.amount}`,
							]?.sort((a, b) => {
								a = BigNumber(a.split('|')[0]).toNumber()
								b = BigNumber(b.split('|')[0]).toNumber()

								return b - a
							})
						}
					}

					mutateOrderBook({
						long: templong || [],
						short: tempshort || [],
					})
				}
			} else if (trade.type === 'TRADE') {
				//amtbuffer += trade.amount\
				if (trade?.ep_id == current) amtbuffer += trade.amount
				lastprice.set(+trade.ep_id, +trade.price)

				handleTradeMessage(trade)
				handleTradeOrder(trade)
				handleTradePrice(trade, indexPrice?.[0]?.price)

				setImmediate(() => {
					if (throttleTradeList[trade?.ep_id] === undefined) {
						throttleTradeList[trade?.ep_id] = false
					}

					if (!throttleTradeList[trade?.ep_id]) {
						throttleTradeList[trade?.ep_id] = true
						setTimeout(() => {
							throttleTradeList[trade?.ep_id] = false

							const tempbuffer =
								bufferTradeList[trade?.ep_id] || []
							bufferTradeList[trade?.ep_id] = []
							mutateTradeList(
								tempbuffer?.reverse() || [],
								trade?.ep_id
							)
						}, throttleTradeListInterval)

						if (bufferTradeList[trade?.ep_id]?.length > 0) {
							const tempbuffer =
								bufferTradeList[trade?.ep_id] || []
							bufferTradeList[trade?.ep_id] = []
							try {
								if (current == trade?.ep_id) {
									mutateTradeList(
										tempbuffer?.reverse() || [],
										trade?.ep_id
									)
								}
								//mutateOrderBook(tradeStore?.ep_id)
							} catch (e) {
								console.error(e)
							}
						}
					} else {
						if (!bufferTradeList[trade?.ep_id]) {
							bufferTradeList[trade?.ep_id] = []
						}

						bufferTradeList[trade?.ep_id].push(trade)
					}
				})
			} else if (trade.type === 'INFO24H') {
				console.log('INFO24H', trade)
				muateInfo24(trade, trade.ep_id)
			}
		} catch (e) {
			console.error('ERROR', e)
			console.log(e)
			console.log('-------------------')
		}
	}

	const { data, mutate } = useSWR<any>(
		TRADE_SOCKET,
		async () => {
			if (tradeWS == null && url) {
				try {
					console.log('START')
					tradeWS = new WebSocket(url)
					tradeWS.binaryType = 'blob'

					tradeWS.onclose = () => {
						console.log('tradeWS close')
						tradeWS = null
						mutate()
					}

					tradeWS.onerror = () => {
						console.log('tradeWS error')
					}
					tradeWS.onopen = () => {
						console.log('tradeWS open')
						tradeWS &&
							tradeWS?.readyState === WebSocket.OPEN &&
							tradeWS?.send(
								`{"type":"join","group": "user", "ep_id": "${current}"}`
							)

						grouplist.map(item => {
							if (
								tradeWS &&
								tradeWS?.readyState === WebSocket.OPEN
							) {
								tradeWS?.send(
									`{"type":"join","group": "user", "ep_id": "${item}"}`
								)
							}
						})
					}
					console.log('tradeWS', tradeWS)
				} catch (e) {
					console.log('tradeWS error', e)
				}
			}

			tradeMessage = onmessage

			if (tradeWS != null) {
				tradeWS.onmessage = tradeMessage
			}

			return tradeWS
		},
		{
			revalidateIfStale: true,
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
		}
	)

	return {
		data,
		mutate: (onmessage?: any) => {
			return mutate()
		},
		joinGroup: joinGroup,
		leaveGroup: leaveGroup,
	}
}
