import React, { useContext } from 'react'
import { MButton } from 'app/mobile/components/'
import { QuickWatchTabSection } from './styles'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'

const QuickWatchTabs = ({ tab, setTab }) => {
	const { textsilver, grey } = useContext(ThemeContext).theme.COLORS
	const tabs = [
		{
			title: 'new',
			tab: 0,
		},
		{
			title: 'usdtPrep',
			tab: 2,
		},
		{
			title: 'spot',
			tab: 1,
		},
	]
	return (
		<QuickWatchTabSection>
			{tabs?.map((item, index) => (
				<MButton
					key={index.toString()}
					title={item?.title}
					onClick={() => {
						setTab(item.tab)
					}}
					style={{
						background: item?.tab === tab ? COLORS.secondary : grey,
						marginRight: 10,
						paddingBlock: 5,
						paddingInline: 10,
						borderRadius: 10,
					}}
					textStyle={{
						color: item?.tab === tab ? COLORS.white : textsilver,
						fontSize: 12,
					}}
				/>
			))}
		</QuickWatchTabSection>
	)
}

export default QuickWatchTabs
