import React from 'react'
import { View } from 'styles/reactnative'
import { AuthCheckBtns } from 'app/components'
import { useToken } from 'app/hooks'
import useTradeStore from 'app/hooks/trade/tradeStore'
import ContractDetails from './ContractDetails'
import DerivativesAccount from './DerivativesAccount'
import DerivativesCheckBoxs from './DerivativesCheckBoxs'
import DerivativesFormBtns from './DerivativesFormBtns'
import DerivativesTpSl from './DerivativesTpsl'
import OrderPriceInput from './OrderPriceInput'
import OrderValueInput from './OrderValueInput'
import PercentageForm from './PercentageForm'
import QuantityCostForm from './QuantityCostForm'

import { DerivativesBodyWrapper } from './styles'

const DerivativesBody = ({ isMobile = false, percentage, setPercentage }) => {
	const { data: token } = useToken()
	const { data: tradeStore } = useTradeStore()
	return (
		<DerivativesBodyWrapper style={isMobile?{width:'100%'}:{}}>
			{tradeStore?.limit_market === 'L' ||
			tradeStore?.limit_market == 'CM' ||
			tradeStore?.limit_market == 'CL' ? (
				<OrderPriceInput limit_market={tradeStore?.limit_market} />
			) : null}
			<OrderValueInput />
			<PercentageForm
				percentage={percentage}
				setPercentage={setPercentage}
			/>
			<QuantityCostForm />
			{token ? (
				<View style={{ alignItems: 'flex-start' }}>
					<DerivativesTpSl />
					<DerivativesFormBtns />
					<DerivativesCheckBoxs />
					{!isMobile && <DerivativesAccount />}
				</View>
			) : (
				<AuthCheckBtns />
			)}

			{!isMobile && <ContractDetails />}
		</DerivativesBodyWrapper>
	)
}

export default DerivativesBody
