import styled from 'styled-components'

export const PercentageFormWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: ${props => props.theme.COLORS.gray};
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
`
export const ConvertAmountWrapper = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
`
export const ConvertAmountTitle = styled.div`
	width: 20%;
	align-self: flex-start;
	justify-content: center;
	padding-top: 20px;
	display: flex;
`
export const ConvertAmountBody = styled.div`
	width: 80%;
	padding-inline: 20px;
`
export const ConvertFormWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`
