import React from 'react'
import { CopyTradeSection, SectionWrap } from './styles'
import CopyTradeRightSide from './CopyTradeRightSide'
import CopyTradeLeftSide from './CopyTradeLeftSide'

const CopyTradeEvent = () => {
	return (
		<CopyTradeSection>
			<SectionWrap>
				<CopyTradeLeftSide />
				<CopyTradeRightSide />
			</SectionWrap>
		</CopyTradeSection>
	)
}

export default CopyTradeEvent
