import React from 'react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { RankTableText, RankItemSection } from './styles'
import { Text, View } from 'styles/reactnative'

const RankItem = ({ item, index }: any) => {
	return (
		<RankItemSection>
			<RankTableText style={{ width: '40%', flexDirection: 'row' }}>
				<View
					style={{
						width: 22,
						height: 22,
						background: COLORS.lightGray,
						borderRadius: 50,
						color: COLORS.gray,
						marginRight: 10,
						fontSize: 10,
					}}>
					{index + 1}
				</View>
				<Text>{item?.english_name}@gmail.com</Text>
			</RankTableText>
			<RankTableText>FTT/USDT</RankTableText>
			<RankTableText>{item?.market}</RankTableText>
			<RankTableText style={{ color: COLORS.lightGreen }}>
				3,467.25%
			</RankTableText>
			<RankTableText>
				<CButton
					title="Action"
					style={{
						border: `1px solid ${COLORS.lightGray}`,
						paddingBlock: 5,
						paddingInline: 20,
					}}
					backgroundHover={COLORS.primary}
					onClick={() => {}}
				/>
			</RankTableText>
		</RankItemSection>
	)
}

export default React.memo(RankItem)
