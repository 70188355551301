import React from 'react'
import { SupportSection } from '../styles'
import SupportTitle from '../SupportTitle'
import HelpCenterContainer from './HelpCenterContainer'

const HelpCenter = () => {
	return (
		<SupportSection>
			<SupportTitle title={'faq'} content={'faqSupportContent'} />
			<HelpCenterContainer />
		</SupportSection>
	)
}

export default HelpCenter
