import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const RiskWarningTitle = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ width: '100%', alignItems: 'flex-start', height: 150 }}>
			<Text
				size={35}
				style={{ color: textw, fontWeight: '500', lineHeight: 2 }}>
				{t('riskWarning')}
			</Text>
		</View>
	)
}

export default RiskWarningTitle
