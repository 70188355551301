import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'

const SecurityListTitle = ({ title }) => {
	const { t } = useTranslation()
	return (
		<MView>
			<MText>{t(title)}</MText>
		</MView>
	)
}

export default SecurityListTitle
