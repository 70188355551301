import React from 'react'
import { MView } from 'styles/mobilePageStyles'
import MProofOfReservesTitle from './MProofOfReservesTitle'
import MProofOfSelect from './MProofOfSelect'
import TotalReserveCard from './TotalReserveCard'
import ProofReserveCoin from './ProofReserveCoin'
import AboutProoftReserve from './AboutProoftReserve'

const MProofOfReserves = () => {
	return (
		<MView style={{ width: '100%', paddingInline: 26, marginBottom: 100 }}>
			<MProofOfReservesTitle />
			<MProofOfSelect />
			<TotalReserveCard />
			<ProofReserveCoin />
			<AboutProoftReserve />
		</MView>
	)
}

export default MProofOfReserves
