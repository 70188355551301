import React from 'react'
import { TraderFollowerSection, SectionWrap } from './styles'
import TraderFollowerBackBtn from './TraderFollowerBackBtn'
import TraderFollowerInfo from './TraderFollowerInfo'
import TraderFollowerTitle from './TraderFollowerTitle'

const TraderFollowerEvent = () => {
	return (
		<TraderFollowerSection>
			<SectionWrap>
				<TraderFollowerBackBtn />
				<TraderFollowerTitle />
				<TraderFollowerInfo />
			</SectionWrap>
		</TraderFollowerSection>
	)
}

export default TraderFollowerEvent
