import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { useTimezone } from '../../../../../hooks/timezone'

const OverviewRightTableList = ({ data }) => {
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',
				paddingBlock: 8,
				paddingInline: 5,
			}}>
			<View style={{ alignItems: 'flex-start' }}>
				<Text size={16} style={{ marginBottom: 3, fontWeight: '400' }}>
					{t(data?.detail)}
				</Text>
				<Text
					size={14}
					style={{ color: COLORS.lightGray, fontWeight: '300' }}>
					{useTimezone(
						data?.confirm_at || data?.created_at,
						'YYYY-MM-DD HH:mm:ss',
						9
					)}
				</Text>
			</View>
			<View style={{ alignItems: 'flex-end' }}>
				<Text
					size={width < 1200 ? 14 : 11}
					style={{
						marginBottom: 3,

						fontWeight: '500',
					}}>
					{data?.type == 'D' ? '+' : '-'}
					{BigNumber(data?.amount || 0)?.toFormat(3)} USDT
				</Text>
				<Text
					size={12}
					style={{ fontWeight: '400', color: COLORS.lightGray }}>
					{data?.confirm_amount
						? `${data?.type == 'W' ? '출금' : '입금'}`
						: `${data?.type == 'W' ? '출금' : '입금'}`}{' '}
					{data?.process == 1
						? '신청'
						: data?.process == 2
						? '완료'
						: '거절'}
				</Text>
			</View>
		</View>
	)
}

export default React.memo(OverviewRightTableList)
