import React, { useContext } from 'react'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ReferralTableListItems = ({ item, width }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				width: width ? width : '10%',
			}}>
			<Text size={14} style={{ color: textw }}>
				{item ? item : ' - '}
			</Text>
		</View>
	)
}

export default React.memo(ReferralTableListItems)
