import React from 'react'
import { MView } from 'styles/mobilePageStyles'
import FeeTable from './FeeTable'
import ReferTable from './ReferTable'

const MTradingFees = () => {
	return (
		<MView style={{ width: '100%' }}>
			<FeeTable />
			<ReferTable />
		</MView>
	)
}

export default MTradingFees
