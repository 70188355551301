import React, { useContext } from 'react'
import { LatestReserveCard } from './styles'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { CButton, CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
const LatestReserveItem = ({ title, contentData, image, id }) => {
	const { textw, content } = useContext(ThemeContext).theme.COLORS
	return (
		<LatestReserveCard>
			<Text size={25} style={{ color: textw, lineHeight: 2 }}>
				{title}
			</Text>
			<Text
				size={16}
				style={{
					color: content,
					lineHeight: 1.4,
					fontWeight: '300',
				}}>
				{contentData}
			</Text>
			<CImage
				src={image}
				style={{ width: 60, height: 60, marginBlock: 30 }}
			/>
			<CButton
				title={id}
				onClick={() => {}}
				style={{
					width: '100%',
					height: 40,
					borderRadius: 5,
					background: COLORS.primary,
					color: COLORS.white,
				}}
			/>
		</LatestReserveCard>
	)
}

export default LatestReserveItem
