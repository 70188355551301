import React from 'react'
import { useTranslation } from 'react-i18next'
import { MView, MText } from 'styles/mobilePageStyles'
import { Select } from 'antd'
import { MUserFeedbackSelectSection } from './styles'

const UserFeedbackCategory = ({ category, setCategory }) => {
	const { t } = useTranslation()
	const handleChange = (value: string) => {
		setCategory(value)
	}
	return (
		<MView style={{ marginTop: 30 }}>
			<MText style={{ marginBottom: 10, fontSize: 14 }}>
				{t('category')}
			</MText>
			<MUserFeedbackSelectSection>
				<Select
					value={t(category)}
					style={{ background: 'transparent' }}
					onChange={handleChange}
					options={[
						{
							value: 'safety',
							label: `${t('safety')}`,
						},
						{
							value: 'advanced',
							label: `${t('advanced')}`,
						},
					]}
				/>
			</MUserFeedbackSelectSection>
		</MView>
	)
}

export default UserFeedbackCategory
