import React, { useContext } from 'react'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	title?: string
	content?: string
	contentColor?: string
	desc?: string
}
const PerformanceDetailBodyList = ({
	title,
	content,
	desc,
	contentColor,
}: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				marginBottom: 20,
			}}>
			<Text size={16}>{title}</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={14}
					style={{
						color: contentColor ? contentColor : textw,
						fontWeight: '600',
					}}>
					{content}
				</Text>
				{desc ? (
					<Text
						size={14}
						style={{
							fontWeight: '600',
							marginLeft: 3,
						}}>
						{desc}
					</Text>
				) : null}
			</View>
		</View>
	)
}

export default PerformanceDetailBodyList
