import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'

export const OtherHeaderSection = styled.div<{ isDarkMode?: any }>`
	height: 50px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* padding-inline: 16px; */
	font-size: 3vw;
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${props => props.theme.COLORS.background};
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`
