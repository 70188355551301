import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import AboutProoftText from './AboutProoftText'
import { useTranslation } from 'react-i18next'

const AboutProoftFirst = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				flexDirection: 'row',
				marginBottom: 20,
				width: '100%',
				alignItems: 'flex-start',
			}}>
			<View
				style={{
					width: '30%',
					alignItems: 'flex-start',
				}}>
				<Text
					size={20}
					style={{
						color: textw,
						lineHeight: 1,
						fontWeight: '500',
					}}>
					{t('whatIsProoftReserves')}
				</Text>
			</View>
			<View
				style={{
					width: '70%',
					lineHeight: 1.5,
					overflow: 'hidden',
				}}>
				<AboutProoftText text={`explainProoftReserves1`} />
				<AboutProoftText text={`explainProoftReserves2`} />
				<AboutProoftText text={`explainProoftReserves3`} />
				<AboutProoftText text={`explainProoftReserves4`} />
			</View>
		</View>
	)
}

export default AboutProoftFirst
