import React from 'react'
import { View } from 'styles/reactnative'
import CurrentTrades from './CurrentTrades'
import PastTrades from './PastTrades'

const StatisticsCopiedTrades = () => {
	return (
		<View style={{ alignItems: 'flex-start' }}>
			<CurrentTrades />
			<PastTrades />
		</View>
	)
}

export default React.memo(StatisticsCopiedTrades)
