/**
 * Asynchronously loads the component for TraderDetailPage
 */

import { lazyLoad } from 'utils/loadable'

export const TraderDetailPage = lazyLoad(
	() => import('./index'),
	module => module.TraderDetailPage
)
