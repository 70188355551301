import styled from 'styled-components'

export const TermOfServiceSection = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
	.section1 {
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.highlight {
		font-size: large;
		left: 0px;
		letter-spacing: -1px;
		margin: 20px 0;
	}
	.paragraph {
		left: 0px;
		letter-spacing: 0px;
	}

	& p {
		line-height: 1.5;
	}
`
