import React, { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { CInput } from 'app/components/Common'
import DepositChainTypeLists from './DepositChainTypeLists'
import CustomChainTypeComp from './CustomChainTypeComp'
import { useDepositStore } from '../../../../hooks/deposit/store'
import { ThemeContext } from '../../../../../theme/ThemeProvider'

const DepositChainType = () => {
	const ref = useRef<HTMLInputElement>(null)
	const { t } = useTranslation()
	const [modal, setModal] = useState(true)

	const { textw } = useContext(ThemeContext).theme.COLORS

	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'KRW',
	})

	const search = depositStore?.network

	const handleChange = (event: any) => {
		depositStore.network = event.target.value
		setModal(false)
	}

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
			}}>
			<Text size={14}>{t('chainType')}</Text>
			<CInput
				ref={ref}
				placeholder={t('selectType')}
				value={search}
				type={'text'}
				onClick={() => {
					setModal(!modal)
				}}
				onChange={handleChange}
				vStyle={{ width: '100%' }}
				CustomComp={false ? <CustomChainTypeComp /> : null}
				style={{
					flexDirection: 'row',
					border: `1px solid ${textw}`,
					marginTop: 10,
					width: '100%',
					justifyContent: 'space-between',
					padding: 8,
					borderRadius: 5,
					cursor: 'pointer',
					background: 'transparent',
					color: textw,
				}}
			/>
			{search?.length && !modal ? (
				<DepositChainTypeLists setModal={setModal} />
			) : null}
		</View>
	)
}

export default DepositChainType
