import React from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import ProfitDetailBodyList from './ProfitDetailBodyList'
const ProfitDetailBody = () => {
	return (
		<View style={{ padding: '16px 24px' }}>
			<ProfitDetailBodyList
				name={'fre**@***'}
				profit={'+102.19'}
				roi={'+0.96%'}
				profitColor={COLORS.lightGreen}
				roiColor={COLORS.lightGreen}
			/>
			<ProfitDetailBodyList
				name={'39****46'}
				profit={'+102.19'}
				roi={'+1.12%'}
				profitColor={COLORS.lightGreen}
				roiColor={COLORS.lightGreen}
			/>
			<ProfitDetailBodyList
				name={'urs**@***'}
				profit={'+38.28'}
				roi={'+8.52%'}
				profitColor={COLORS.lightGreen}
				roiColor={COLORS.lightGreen}
			/>
			<ProfitDetailBodyList
				name={'blu**@***'}
				profit={'0.00'}
				roi={'0.00%'}
			/>
			<ProfitDetailBodyList
				name={'dga**@***'}
				profit={'0.00'}
				roi={'0.00%'}
			/>
			<Text style={{ alignSelf: 'flex-end' }}>Measured in: USDT</Text>
		</View>
	)
}

export default ProfitDetailBody
