import styled from 'styled-components'

export const HotDerivativeSection = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;
	::-webkit-scrollbar {
		display: none;
	}
	flex-wrap: nowrap;
	padding-bottom: 4px;
`
export const HotDerivativeItemSection = styled.div`
	flex: 0 0 134px;
	margin-right: 10px;
	padding: 10px;
	height: 100%;
	background: ${p => p.theme.COLORS.cardOpacity};
	box-shadow: 2px 2px 5px ${p => p.theme.COLORS.cardOpacity};
	border-radius: 5px;
`
