import React, { useContext } from 'react'
import moment from 'moment'
import { Text, View } from 'styles/reactnative'
import { CButton } from 'app/components/Common'
import { IconShare } from '@tabler/icons'
import { ThemeContext } from 'theme/ThemeProvider'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

const HelpListDetailHeader = ({ item }: any) => {
	const { t } = useTranslation()
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				width: '100%',
				height: '12%',
				flexDirection: 'row',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				paddingInline: 20,
			}}>
			<View
				style={{
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '60%',
					height: '100%',
					paddingBlock: 10,
				}}>
				<Text style={{ color: textw, fontSize: 14 }}>
					{t(item?.title)}
				</Text>
				<Text style={{ color: textsilver, fontSize: 13 }}>
					{moment(item?.date).format('YYYY-MM-DD')}
				</Text>
			</View>
		</View>
	)
}

export default HelpListDetailHeader
