import React from 'react'
import { FeedbackCategorySection } from '../../styles'
import FeedbackContainerLeft from './FeedbackContainerLeft'
import { View } from 'styles/reactnative'
import FeedbackImage from './FeedbackImage'

import { useTranslation } from 'react-i18next'

const FeedbackFooter = ({ images, setImages }) => {
	return (
		<View
			style={{
				width: '100%',
				height: '30%',
				alignItems: 'flex-start',
			}}>
			<FeedbackCategorySection
				style={{
					width: '100%',
					height: '90%',
					marginTop: 20,
				}}>
				<FeedbackContainerLeft
					text={'file'}
					style={{ justifyContent: 'center' }}
				/>
				<View style={{ width: '80%', height: '100%' }}>
					<FeedbackImage images={images} setImages={setImages} />
				</View>
			</FeedbackCategorySection>
		</View>
	)
}

export default FeedbackFooter
