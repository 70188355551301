/**
 * Asynchronously loads the component for OrdersPage
 */

import { lazyLoad } from 'utils/loadable'

export const OrdersPage = lazyLoad(
	() => import('./index'),
	module => module.OrdersPage
)
