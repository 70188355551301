import styled from 'styled-components'

export const LastestReserveSection = styled.div`
	margin-top: 120px;
	.ant-select-selector {
		background-color: transparent !important;
		border: 1px solid ${p => p.theme.COLORS.borderColor} !important;
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-arrow {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: ${p => p.theme.COLORS.content};
	}
`
export const LatestReserveCard = styled.div`
	height: 350px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 43%;
	padding-block: 20px;
	padding-inline: 40px;
	background-color: ${p => p.theme.COLORS.drawerBgColor};
	border-radius: 10px;
`
