import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import CheckBoxDropdown from './CheckBoxDropdown'
import DerivativesCheckBox from './DerivativesCheckBox'
import useTradeStore from 'app/hooks/trade/tradeStore'

const DerivativesCheckBoxs = () => {
	const { data, mutate: mutateTradeStore } = useTradeStore()
	return (
		<DerivativesCheckBoxSection>
			<View
				style={{
					alignItems: 'flex-start',
				}}>
				<DerivativesCheckBox
					title={'postOnly'}
					checked={data?.post_only}
					action={checked => {
						mutateTradeStore({ ...data, post_only: checked })
					}}
				/>
				<DerivativesCheckBox
					title={'reduceOnly'}
					checked={data?.reduce_only}
					action={checked => {
						mutateTradeStore({ ...data, reduce_only: checked })
					}}
				/>
			</View>
			{/* <CheckBoxDropdown /> */}
		</DerivativesCheckBoxSection>
	)
}
const DerivativesCheckBoxSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	color: ${COLORS.lightGray1};
	margin-top: 10;
	width: 100%;
`
export default DerivativesCheckBoxs
