import React, { useState } from 'react'
import { useFavourite } from '../../../../hooks'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import FavouriteTableList from './FavouriteTableList'
import MarketTableList from './MarketTableLists'
import MarketTopTabs from './MarketTopTabs'
import { MarketTableListSection } from './styles'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'

const MarketTableTabs = () => {
	const Favorites = 'favourites'
	const Derivatives = 'futures'
	const [tab, setTab] = useState(Favorites)
	const tabs = [Favorites, Derivatives]

	let { EPs } = useExchangeStore()
	const data1 = EPs
	const { data: data2 } = useFavourite()

	return (
		<MarketTableListSection>
			<MarketTopTabs tab={tab} setTab={setTab} tabs={tabs} />
			{tab === Favorites ? <FavouriteTableList data={data2} /> : null}
			{tab === Derivatives ? <MarketTableList data={data1} /> : null}
		</MarketTableListSection>
	)
}

export default React.memo(MarketTableTabs)
