import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import DerivativesBody from './DerivativesBody'
import DerivativesHeader from './DerivativesHeader'
import DerivativesNavBarTabs from './DerivativesNavBarTabs'
import { DerivativesTransactionWrapper } from './styles'

const DerivativesTransaction = () => {
	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()

	if (!tradeStore?.limit_market) {
		mutateTradeStore({ ...tradeStore, limit_market: 'L' })
	}
	const setTab = data => {
		mutateTradeStore({ ...tradeStore, limit_market: data, order_amount: 0 })
	}
	const [percentage, setPercentage] = useState('')

	return (
		<DerivativesTransactionWrapper>
			<DerivativesHeader />
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingRight: 10,
				}}>
				<DerivativesNavBarTabs
					tab={tradeStore?.limit_market}
					setTab={setTab}
				/>
			</View>
			<DerivativesBody
				percentage={percentage}
				setPercentage={setPercentage}
			/>
		</DerivativesTransactionWrapper>
	)
}

export default React.memo(DerivativesTransaction)
