import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { TransferAmountAll } from '../styles'

const TransferAll = ({ balance, setAmount, coinType }) => {
	const { t } = useTranslation()
	return (
		<TransferAmountAll>
			<CButton
				title={t('all')}
				onClick={() => {
					setAmount(balance >= 0.001 ? balance : 0)
				}}
				style={{
					color: COLORS.primary,
					marginRight: 7,
					paddingInline: 5,
				}}
			/>
			<Text size={14}>{coinType}</Text>
		</TransferAmountAll>
	)
}

export default TransferAll
