import React from 'react'
import { CImage } from 'app/components/Common'
import { View } from 'styles/reactnative'
import phoneInside from 'assets/images/phoneInside.png'
import phoneOutside from 'assets/images/phoneOutside.png'
const SpecialRightSection = () => {
	return (
		<View style={{ width: '40%', height: '100%', marginBottom: -50 }}>
			<CImage
				src={phoneOutside}
				alt="announcement"
				width={'auto'}
				height={'auto'}
				resizeMode={'cover'}
				style={{ position: 'absolute', marginLeft: -350 }}
			/>
			<CImage
				src={phoneInside}
				alt="announcement"
				width={'auto'}
				height={'auto'}
				resizeMode={'cover'}
				style={{ position: 'absolute', marginTop: -50 }}
			/>
		</View>
	)
}

export default SpecialRightSection
