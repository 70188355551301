import React, { useContext } from 'react'
import { IconArrowsUpDown } from '@tabler/icons'
import { MButton } from 'app/mobile/components'
import { ThemeContext } from 'theme/ThemeProvider'
import { MarketListHeaderSection } from './styles'

const MarketListHeader = () => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<MarketListHeaderSection>
			<MButton
				title={'name'}
				style={{
					width: '50%',
					flexDirection: 'row',
					justifyContent: 'flex-start',
				}}
				textStyle={{ marginLeft: 3, fontSize: 10, color: textsilver }}
				icon={
					<IconArrowsUpDown
						color={textsilver}
						size={12}
						style={{ marginLeft: 5 }}
					/>
				}
			/>
			<MButton
				title={'lastPrice'}
				style={{
					width: '25%',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
				textStyle={{ marginLeft: 3, fontSize: 10, color: textsilver }}
				icon={<IconArrowsUpDown color={textsilver} size={12} />}
			/>
			<MButton
				title={'change'}
				style={{
					width: '25%',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
				textStyle={{ marginLeft: 3, fontSize: 10, color: textsilver }}
				icon={<IconArrowsUpDown color={textsilver} size={12} />}
			/>
		</MarketListHeaderSection>
	)
}

export default MarketListHeader
