import styled from 'styled-components'

export const AboutTheBitSection = styled.div`
	margin: auto;
	margin-top: 100px;
`

export const AboutTheBitItems = styled.div`
	width: 85%;
	margin-block: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
`
export const AboutTheBitList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
`
