import styled from 'styled-components'

export const TradeDetailContainer = styled.div`
	width: 100%;
	height: 99%;
	background-color: ${props => props.theme.COLORS.bgColor};

	& * ::-webkit-scrollbar{
		width: 12px;
		height: 12px;
	}

	.widgetClose {
		display: none;
	}

	&:hover {
		.widgetClose {
			display: flex;
		}
	}

	@media (max-width: 900px) {
		height: 500px;
	}
	overflow: hidden;
`
export const TradeDetailHeaderSection = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 10px;
	padding-top: 5px;
	padding-bottom: 0;
	border-bottom: 1px solid ${props => props.theme.COLORS.gray};
`

export const TradeDetailNavBarSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 10px;
	padding-bottom: 0;
`
export const TradeDetailListsWrapper = styled.div`
	width: 100%;
	height: 96%;
	flex: 1;
	overflow-y: hidden;
`
