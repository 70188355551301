import React, { useEffect, useState } from 'react'
import { COLORS } from 'app/constants/'
import { View } from 'styles/reactnative'
import { CButton } from 'app/components/Common/'

import { TradeTransactionContainer } from './styles'
import TransactionChoice from './TransactionChoice'
import TransactionForm from './TransactionForm'
import TransactionHeader from './TransactionHeader'
import TransactionNavBar from './TransactionNavBar'
import AuthButton from 'app/components/AuthButton'
import { useTranslation } from 'react-i18next'

//  Not Using
const TradeTransaction = () => {
	const { t } = useTranslation()
	const [buy, setBuy] = useState(true)
	const [choose, setChoose] = useState('limit')
	const [orderPrice, setOrderPrice] = useState(0)
	const [quantity, setQuantity] = useState(0)
	const [total, setTotal] = useState(0)
	useEffect(() => {
		setTotal(orderPrice * quantity)
	}, [orderPrice, quantity])

	return (
		<TradeTransactionContainer>
			<TransactionHeader />
			<TransactionNavBar buy={buy} setBuy={setBuy} />
			<TransactionChoice choose={choose} setChoose={setChoose} />
			<TransactionForm
				orderPrice={orderPrice}
				setOrderPrice={setOrderPrice}
				quantity={quantity}
				setQuantity={setQuantity}
				total={total}
				setTotal={setTotal}
			/>

			<View style={styles.authButton}>
				<AuthButton />
			</View>
			<View>
				<CButton
					title={t('sell')}
					onClick={() => {
						console.log('buy now')
					}}
					style={{
						...styles.loginButton,
						backgroundColor: !buy
							? COLORS.lightRed
							: COLORS.lightGreen,
					}}
				/>
			</View>
		</TradeTransactionContainer>
	)
}

const styles = {
	authButton: {
		backgroundColor: '#2c2c33',
		width: '90%',
		margin: 'auto',
		borderRadius: 5,
	},
	loginButton: {
		padding: 7,
		fontWeight: 'bold',
		color: COLORS.grayWhite,
		width: '50%',
		marginTop: 20,
	},
}
export default React.memo(TradeTransaction)
