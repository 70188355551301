import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CButton, CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { ConvertFormWrapper } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

const ConvertForm = ({ amount, setAmount }) => {
	const { gray, textsilver, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<ConvertFormWrapper>
			<CInput
				placeholder={''}
				value={amount}
				type={'text'}
				onChange={(e: any) => setAmount(e.target.value)}
				vStyle={{
					width: '100%',
					marginTop: 7,
				}}
				style={{
					background: gray,
					border: `1px solid ${textsilver}`,
					color: textw,
					padding: 8,
					paddingLeft: 15,
					width: '100%',
				}}
			/>
			<CButton
				title={t('requestQuote')}
				style={{
					background: COLORS.primary,
					marginLeft: 10,
					marginTop: -5,
					color: COLORS.white,
				}}
			/>
		</ConvertFormWrapper>
	)
}

export default ConvertForm
