import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'

const AboutProoftReserve = () => {
	const { t } = useTranslation()
	return (
		<View style={{ width: '100%', alignItems: 'flex-start' }}>
			<View style={{ alignItems: 'flex-start' }}>
				<Text style={{ fontSize: 16, lineHeight: 2 }}>
					{t('whatIsProoftReserves')}
				</Text>
			</View>
			<View>
				<Text
					style={{ fontSize: 14, fontWeight: '300', lineHeight: 2 }}>
					{t('proofOfReservesEvent')}
				</Text>
			</View>
		</View>
	)
}

export default AboutProoftReserve
