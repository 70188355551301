import React from 'react'
import { CDrawerSection } from './styles'

const CDrawer = ({ renderDrawer, displayDrawer }) => {
	return (
		<CDrawerSection display={displayDrawer}>
			{renderDrawer()}
		</CDrawerSection>
	)
}

export default React.memo(CDrawer)
