import React from 'react'
import { AboutProofReserveHalf, AboutProofReserveSection } from './styles'

import AboutProoftFirst from './AboutProoftFirst'
import AboutProoftSecond from './AboutProoftSecond'

const AboutProofReserves = () => {
	return (
		<AboutProofReserveSection>
			<AboutProofReserveHalf>
				<AboutProoftFirst />
				<AboutProoftSecond />
			</AboutProofReserveHalf>
		</AboutProofReserveSection>
	)
}

export default AboutProofReserves
