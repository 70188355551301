import React from 'react'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useWindowDimensions } from 'app/hooks'

const HomeLeftSide = () => {
	const navigation = useHistory()
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	const { textw } = React.useContext(ThemeContext).theme.COLORS
	const checkTablet = width > 900
	return (
		<View
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
			}}>
			<View>
				<Text size={40} style={{ color: textw, fontWeight: 'bold', whiteSpace:'nowrap' }}>
					{t('homeEventTitle')}
				</Text>
				<Text size={20} style={{ color: textw, marginTop: 20 }}>
					{t('homeSubEventTitle')}
				</Text>
				<CButton
					title={t('getStartedNow')}
					onClick={() => {
						goTo(navigation, '/trade/2')
					}}
					style={{
						background:
							`linear-gradient(90deg, ${COLORS.grad1} 0%, ${COLORS.grad2} 100%)`,
						color: COLORS.white,
						width: 120,
						height: 40,
						marginTop: 50,
					}}
				/>
			</View>
		</View>
	)
}

export default HomeLeftSide
