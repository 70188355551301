import React, { useContext, useState } from 'react'
import { CButton, CSwitch } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { FloatBtnSection3 } from '../../../../../components/CFloatBtns/styles'
import { useTradeModalStore } from '../../../../../hooks/trade/modalStore'
import { Button, Popover, Space, Switch } from 'antd'
import { IconMaximize } from '@tabler/icons'
import useTradeStore from '../../../../../hooks/trade/tradeStore'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { useWindowDimensions } from '../../../../../hooks'

const TradeChartNavBar = ({ tab, setTab, tabs, isMobile = true }) => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()

	const { width } = useWindowDimensions()

	const { isDarkMode, theme } = useContext(ThemeContext)

	const { data: store, mutate } = useTradeStore()

	const alltabs = [
		'1m',
		'3m',
		'5m',
		'15m',
		'30m',
		'1h',
		'2h',
		'4h',
		'6h',
		'8h',
		'12h',
		'1d',
		'1w',
		'1M',
	]

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				backgroundColor: theme?.COLORS?.bgColor,
			}}>
			{isMobile && (
				<>
					<FloatBtnSection3
						className="widgetClose"
						onClick={() => {
							muateTradeModalStore({
								...tradeModalStore,
								chartshow: tradeModalStore?.chartshow
									? false
									: true,
							})
						}}>
						X
					</FloatBtnSection3>
					{width > 880 && (
						<Button
							size={'small'}
							icon={<IconMaximize size={14} />}
							style={{
								fontSize: 12,
								right: 3,
								position: 'absolute',
								background: theme.COLORS.bgColor,
								color: theme.COLORS.textw,
							}}
							onClick={() => {
								mutate(store)
								window.open(
									`/trade/chart/${store?.ep_id}/${
										store?.pairs
									}/1m/${!isDarkMode ? 'dark' : 'light'}/pc`,
									'_blank',
									'width=800,height=600,resizable=yes, status=no, toolbar=no, menubar=no, location=no, scrollbars=yes, resizable=yes'
								)
							}}>
							새창
						</Button>
					)}
				</>
			)}
			<View
				style={{
					flexDirection: 'row',
					padding: 3,
					borderRadius: 3,
					backgroundColor: theme?.COLORS?.bgColor,
					flex: 0,
				}}>
				{tabs.map((item: any, index: any) => (
					<CButton
						key={index.toString()}
						title={item}
						onClick={() => {
							setTab(item)
						}}
						textHover={
							tab === item ? COLORS.secondary : COLORS.greyWhite
						}
						style={{
							fontWeight: '500',
							paddingBlock: 3,
							paddingInline: 5,
							fontSize: '1.1rem',
							backgroundColor: theme?.COLORS?.bgColor,
							color:
								tab === item
									? COLORS.secondary
									: COLORS.lightGray5,
						}}
					/>
				))}
				<Popover
					onOpenChange={e => {
						setOpen(e)
					}}
					trigger={'click'}
					content={
						<Space.Compact size="small">
							{alltabs.map((item: any, index: any) => (
								<Button
									key={index.toString()}
									type={
										tradeModalStore?.tabs?.includes(item)
											? 'primary'
											: 'default'
									}
									onClick={() => {
										if (
											tradeModalStore?.tabs?.includes(
												item
											)
										) {
											let temp =
												tradeModalStore?.tabs?.filter(
													(i: any) => i != item
												)
											// alltabs 기준 정렬
											temp.sort(function (a, b) {
												return (
													alltabs.indexOf(a) -
													alltabs.indexOf(b)
												)
											})

											muateTradeModalStore({
												...tradeModalStore,
												tabs: temp,
											})
										} else {
											let temp = [
												...tradeModalStore?.tabs,
												item,
											]
											// alltabs 기준 정렬
											temp.sort(function (a, b) {
												return (
													alltabs.indexOf(a) -
													alltabs.indexOf(b)
												)
											})
											muateTradeModalStore({
												...tradeModalStore,
												tabs: temp,
											})
										}
									}}>
									{item}
								</Button>
							))}
						</Space.Compact>
					}
					placement="bottom"
					open={open}>
					<View
						style={{
							flexDirection: 'row',
							marginLeft: 20,
							marginTop: -2,
							cursor: 'pointer',
						}}>
						<Text
							style={{
								color: open ? COLORS.primary : COLORS.lightGray,
								marginRight: 5,
								marginTop: 5,
								whiteSpace: 'nowrap',
								fontSize: '1.1rem',
							}}>
							{t('modify')}
						</Text>
					</View>
				</Popover>
			</View>
			{!isMobile && (
				<View
					style={{ width: '100%', height: 25 }}
					className={'drag-handle'}
				/>
			)}

			{isMobile && width > 800 && (
				<Space
					direction="horizontal"
					style={{
						position: 'absolute',
						right: 60,
						top: 3,
						alignItems: 'center',
						alignContent: 'center',
						justifyContent: 'flex-end',
						whiteSpace: 'nowrap',
						color: !isDarkMode ? '#ececec' : '#000',
					}}>
					{t('position/order')}
					<Switch
						checked={tradeModalStore?.showpos}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						onChange={e => {
							setImmediate(() => {
								muateTradeModalStore({
									...tradeModalStore,
									showpos: e,
								})
							})
						}}
					/>
				</Space>
			)}
		</View>
	)
}

export default React.memo(TradeChartNavBar)
