import React, { useState } from 'react'
import OrderContainer from './OrderContainer'
import OrderSubTabs from '../OrderSubTabs'
import { UsdcDerivativeSection } from './styles'

const UsdcDerivatives = () => {
	// Container Header Tabs
	const usdcPerp = 'usdcPerp'
	const usdcOptions = 'usdcOptions'
	const [tab, setTab] = useState(usdcPerp)
	const data = [usdcPerp]

	// Container Tabs
	const filled = 'filled'
	const orderHistory = 'orderHistory'
	const plHistory = 'plHistory'
	const [cTab, setCTab] = useState(filled)
	const cTabs = [filled, orderHistory, plHistory]

	return (
		<UsdcDerivativeSection>
			<OrderSubTabs data={data} tab={tab} setTab={setTab} />
			<OrderContainer cTab={cTab} setCTab={setCTab} cTabs={cTabs} />
		</UsdcDerivativeSection>
	)
}

export default UsdcDerivatives
