import React, { useContext } from 'react'
import { CButton, CImage } from 'app/components/Common'
import { useFavourite } from 'app/hooks'
import { Text, View } from 'styles/reactnative'
import { IconStarFilled, IconStar } from '@tabler/icons-react'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from 'app/constants'

const ListCoin = ({ item }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { data: favourites, mutate: mutateFavourite } = useFavourite()
	const favourite = favourites?.find?.((f: any) => f?.id === item?.id)
	const removeFavourite = favourites?.filter?.((f: any) => f?.id !== item?.id)
	const icon = favourite ? (
		<IconStarFilled size={20} style={{ color: COLORS.primary }} />
	) : (
		<IconStar color={textw} size={20} />
	)
	return (
		<View
			style={{
				width: '35%',
				alignItems: 'center',
				justifyContent: 'flex-start',
				flexDirection: 'row',
			}}>
			<CButton
				icon={icon}
				style={{ marginRight: 5 }}
				onClick={() => {
					if (!favourite) {
						mutateFavourite([
							...(favourites ? favourites : []),
							item,
						])
					} else {
						mutateFavourite(removeFavourite)
					}
				}}
			/>
			<View style={{ flexDirection: 'row' }}>
				<CImage
					src={item?.image}
					alt="logo"
					width={18}
					height={18}
					resizeMode={'cover'}
					style={{ borderRadius: 50 }}
				/>
				<Text
					style={{
						marginLeft: 5,
						fontWeight: 'bold',
						fontSize: 12,
					}}>
					{item?.pairs}
				</Text>
			</View>
		</View>
	)
}

export default React.memo(ListCoin)
