import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const EventTitle = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<View style={{ width: 450 }}>
			<Text
				size={40}
				style={{
					color: textw,
					textAlign: 'center',
					fontWeight: 'bold',
					lineHeight: 1.3,
				}}>
				{t('referralEventTitle')}
			</Text>
		</View>
	)
}

export default EventTitle
