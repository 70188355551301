import useSWR from 'swr'
import { THEME_STORE } from 'app/constants/LOCALKEYS'
import { getPersistData } from '../persist'

const KEY = THEME_STORE

let swrData: string | null = JSON.parse(localStorage.getItem(KEY) || '{}')
export function useThemeStore(initData?: any) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			swrData = JSON.parse(localStorage.getItem(KEY) || '{}')
			return swrData
		},
		{ refreshInterval: 20 }
	)

	return {
		data,
		mutate: (value: any) => {
			swrData = value
			localStorage.setItem(KEY, JSON.stringify(value))
			return mutate()
		},
	}
}
