import React, { useContext } from 'react'
import { COLORS } from 'app/constants/'
import { View } from 'styles/reactnative'
import { CTab } from 'app/components/Common'
import SubDropdownBody from './SubDropdownBody'
import { ThemeContext } from 'theme/ThemeProvider'

const SubDropdownLists = ({ headerTab, setHeaderTab, types, pairs }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View>
			<View
				style={{
					borderBottomColor: COLORS.lightGray4,
					borderBottomWidth: 1,
					borderBottomStyle: 'outset',
					width: '100%',
				}}>
				<CTab
					fontSize={12}
					color={COLORS.grayWhite}
					active={headerTab}
					setActive={setHeaderTab}
					types={types}
					style={{ padding: 5, color: textw }}
					onPressOrClick={value => {
						console.log(value)
						if (value === 'all') {
						} else if (value === 'trending') {
						} else if (value === 'new') {
						}
					}}
				/>
			</View>
			<SubDropdownBody
				headerTab={headerTab}
				types={types}
				pairs={pairs}
			/>
		</View>
	)
}

export default React.memo(SubDropdownLists)
