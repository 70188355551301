import { COLORS, FONTS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'

const BotTableTitle = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{ flexDirection: 'row', marginLeft: 10, marginBottom: 24 }}>
			<Text style={{ ...FONTS.h2, fontWeight: '500' }}>
				{t('leaderboard')}
			</Text>
			<Text
				style={{
					...FONTS.h4,
					background: COLORS.lightGray2,
					color: COLORS.primary,
					padding: 5,
					marginLeft: 10,
				}}>
				{t('spotGridBot')}
			</Text>
		</View>
	)
}

export default BotTableTitle
