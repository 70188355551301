import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { Upload } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { beforeUpload } from 'utils/ExpensiveFunction'
import { FeedbackImageSection } from '../../styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'

const FeedbackImage = ({ images, setImages }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const onChange: UploadProps['onChange'] = (file: any) => {
		setImages(file?.fileList)
	}

	const onPreview = async (file: UploadFile) => {
		let src = file.url as string
		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(file.originFileObj as RcFile)
				reader.onload = () => resolve(reader.result as string)
			})
		}
		const image = new Image()
		image.src = src
		const imgWindow = window.open(src)
		imgWindow?.document.write(image.outerHTML)
	}

	return (
		<FeedbackImageSection>
			<Upload
				name="avatar"
				listType="picture-card"
				className="avatar-uploader"
				fileList={images}
				onChange={onChange}
				beforeUpload={beforeUpload}
				style={{
					borderColor: 'blue',
				}}
				onPreview={onPreview}
				multiple={true}>
				{images.length < 4 && '+ Upload'}
			</Upload>
			<View
				style={{
					width: '100%',
					alignItems: 'flex-end',
					justifyContent: 'flex-end',
				}}>
				<Text size={14} style={{ color: textw, fontWeight: '300' }}>
					{t('imageSizeInfo')}
				</Text>
			</View>
		</FeedbackImageSection>
	)
}

export default FeedbackImage
