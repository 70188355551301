import * as React from 'react'

import { Header, SubFooter, Footer, Notification } from 'app/organisms/'
import {
	ContentContainer,
	ContentWrapper,
} from '../../../styles/globalPage-styles'
import { CFloatBtns } from 'app/components'

const NotiSettingDesktop = () => {
	const [visible, setVisible] = React.useState(false)
	return (
		<ContentContainer>
		<Header showSelect={visible} setShowSelect={setVisible} />
			<CFloatBtns />
			<ContentWrapper>
				<Notification />
			</ContentWrapper>
			<SubFooter />
			<Footer />
		</ContentContainer>
	)
}
export default NotiSettingDesktop
