export function getPersistData(key) {
	/*const cache =
		sessionStorage.getItem('save') == '1'
			? localStorage.getItem('app-cache')
			: null*/
	const cache = localStorage.getItem('app-cache')
	if (cache) {
		const map = new Map(JSON.parse(cache))
		const data: any = map.get(key)
		if (data) {
			return data.data
		}
		return null
	} else {
		return null
	}
}
