import React, { useContext, useEffect, useState } from 'react'
import { HelpCenterContainerSection } from './styles'
import HelpCenterHeader from './HelpCenterHeader'
import HelpCenterLists from './HelpCenterLists'
import { Pagination } from 'antd'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { searchData } from 'utils/ExpensiveFunction'
import HelpCenterListDetail from './HelpCenterListDetail'

const HelpCenterContainer = ({ dummyData }) => {
	const [search, setSearch] = useState('')
	const [page, setPage] = useState(1)
	const [originalData, setOriginalData] = useState(dummyData)
	const [readDetail, setReadDetail] = useState(false)
	const [data, setData] = useState(originalData)
	const [itemDetail, setItemDetail] = useState<any>(null)
	useEffect(() => {
		const filteredData = searchData({
			data: originalData,
			search,
			searchField: 'title',
		})
		setPage(1)
		setData(filteredData)
	}, [search, originalData])

	const itemsPerPage = 8
	const { textw } = useContext(ThemeContext).theme.COLORS
	const itemRender = (current, type, originalElement) => {
		if (type === 'page') {
			return <span style={{ color: textw }}>{current}</span>
		}
		return originalElement
	}

	const indexOfLastItem = page * itemsPerPage
	const indexOfFirstItem = indexOfLastItem - itemsPerPage
	const currentItems = data.slice(indexOfFirstItem, indexOfLastItem)

	return (
		<HelpCenterContainerSection>
			{readDetail ? (
				<HelpCenterListDetail
					data={data}
					item={itemDetail}
					setReadDetail={setReadDetail}
				/>
			) : (
				<View style={{ width: '100%', height: '100%' }}>
					<HelpCenterHeader search={search} setSearch={setSearch} />
					<View
						style={{
							width: '100%',
							height: '80%',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
						}}>
						{currentItems.map((item, index) => (
							<HelpCenterLists
								key={index.toString()}
								item={item}
								setItemDetail={setItemDetail}
								index={index}
								setReadDetail={setReadDetail}
							/>
						))}
					</View>
					<View style={{ width: '100%', height: 50 }}>
						<Pagination
							current={page}
							className="custom-pagination"
							total={data.length}
							itemRender={itemRender}
							pageSize={itemsPerPage}
							onChange={page => setPage(page)}
						/>
					</View>
				</View>
			)}
		</HelpCenterContainerSection>
	)
}

export default HelpCenterContainer
