import React from 'react'
import { Text, View } from 'styles/reactnative'
import { UserFeedbackHalf, UserFeedbackEventSection } from '../styles'
import { useTranslation } from 'react-i18next'

import { COLORS } from 'app/constants'

const UserFeedbackEvent = () => {
	const { t } = useTranslation()

	return (
		<UserFeedbackEventSection>
			<UserFeedbackHalf>
				<View
					style={{
						width: '73%',
						height: '100%',
						alignItems: 'flex-start',
					}}>
					<Text
						size={30}
						style={{
							color: COLORS.white,
							fontWeight: '600',
							lineHeight: 2,
						}}>
						{t('userFeedback')}
					</Text>
					<Text
						size={18}
						style={{
							color: COLORS.lightGray2,
							fontWeight: '300',
							lineHeight: 1.5,
						}}>
						{t('userFeedbackEvent')}
					</Text>
				</View>
			</UserFeedbackHalf>
		</UserFeedbackEventSection>
	)
}

export default UserFeedbackEvent
