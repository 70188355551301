import React from 'react'
import { View } from 'styles/reactnative'
import TpslFormField from './TpslFormField'

const TpslDetail = ({
	tpslPercentage,
	setTpslPercentage,
	stopLossPercentage,
	setStopLossPercentage,
	tpslValue,
	setTpslValue,
	stopLossValue,
	setStopLossValue,
	tradeTpslType,
	setTradeTpslType,
	tradeLossType,
	setTradeLossType,
}) => {
	return (
		<View style={{ width: '100%', alignItems: 'flex-start' }}>
			<TpslFormField
				percentage={tpslPercentage}
				setPercentage={setTpslPercentage}
				value={tpslValue}
				setValue={setTpslValue}
				title={'takeProfitUsdt'}
				tradeType={tradeTpslType}
				setTradeType={setTradeTpslType}
			/>
			<TpslFormField
				percentage={stopLossPercentage}
				setPercentage={setStopLossPercentage}
				value={stopLossValue}
				setValue={setStopLossValue}
				title={'stopLossUsdt'}
				tradeType={tradeLossType}
				setTradeType={setTradeLossType}
			/>
		</View>
	)
}

export default TpslDetail
