import React from 'react'
import MCoinLists from 'app/mobile/components/MCoinLists'
import { MMarketListSection } from '../../styles'
import { useFavourite } from 'app/hooks'
import MarketListHeader from '../../MarketListHeader'

const MarketFavouriteDerivatives = () => {
	const { data: favourites } = useFavourite()

	return (
		<MMarketListSection>
			<MarketListHeader />
			<MCoinLists data={favourites} />
		</MMarketListSection>
	)
}

export default MarketFavouriteDerivatives
