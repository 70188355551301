import styled from 'styled-components'

export const AccordionSectionList = styled.div`
	list-style: none;
	margin: 0px;
`
export const LanguageBtn = styled.div`
	background-color: transparent;
	padding: 10px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	border-top: 1px;
	border-style: outset;
	border-color: ${p => p.theme.COLORS.borderColor};
`
