import React from 'react'
import { useTranslation } from 'react-i18next'
import TradersFollowersItems from './TradersFollowersItems'

export const CopiedTradeTableData = () => {
	const { t } = useTranslation()
	return [
		{
			field: t('userId'),
			minWidth: 200,
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<TradersFollowersItems item={'sil**@***'} />
			),
			sortable: true,
		},
		{
			field: t('cumulativeCost'),
			unSortIcon: true,
			minWidth: 200,
			cellRenderer: ({ data }) => (
				<TradersFollowersItems item={'0.00 USDT'} />
			),
		},
		{
			field: t('totalProfitReceived'),
			unSortIcon: true,
			minWidth: 200,
			cellRenderer: ({ data }) => (
				<TradersFollowersItems item={'0.00 USDT'} />
			),
		},
		{
			field: t('totalROIFollower'),
			unSortIcon: true,
			minWidth: 200,
			cellRenderer: ({ data }) => (
				<TradersFollowersItems item={'0.00%'} />
			),
		},
		{
			field: t('daysofFollowing'),
			unSortIcon: true,
			minWidth: 200,
			cellRenderer: ({ data }) => (
				<TradersFollowersItems item={'1 Days'} />
			),
		},
	]
}
