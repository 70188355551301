import styled from 'styled-components'

export const MarketTraderListSection = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	@media only screen and (min-width: 1200px) {
		flex-wrap: nowrap;
	}
`

export const MarketTableHeaderLists = styled.div`
	width: 50%;
	height: 330px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	overflow: hidden;
	@media only screen and (max-width: 900px) {
		width: 100%;
	}
`

export const MarketTableHeaderListItems = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	border-radius: 5px;
	padding: 20px;
`
