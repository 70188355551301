import React from 'react'
import Switch from 'react-switch'
import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

interface IProps {
	title?: string
	checked?: any
	setChecked?: any
}
const NotiSwitch = ({ title, checked, setChecked }: IProps) => {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-start',
				marginTop: 30,
			}}>
			<Switch
				onChange={e => setChecked(!checked)}
				checked={checked}
				onColor={COLORS.primary}
				checkedIcon={
					<Text
						size={12}
						style={{
							paddingTop: width < 600 ? 12 : 8,
							fontWeight: '500',
							color: COLORS.white,
						}}>
						{t('on')}
					</Text>
				}
				uncheckedIcon={
					<Text
						size={12}
						style={{
							paddingTop: width < 600 ? 12 : 8,
							fontWeight: '500',
							color: COLORS.white,
						}}>
						{t('off')}
					</Text>
				}
			/>
			<Text
				size={16}
				style={{
					marginLeft: 20,
					fontWeight: '400',
				}}>
				{title}
			</Text>
		</View>
	)
}
export default NotiSwitch
