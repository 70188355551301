import { COLORS } from 'app/constants/STYLES'
import styled from 'styled-components'

export const TradeTransactionContainer = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${COLORS.HeaderBarBackground};

	@media (max-width: 1200px) {
		height: 99%;
	}
	@media (max-width: 900px) {
		margin-top: 5px;
		height: 100%;
	}
`
export const TransactionHeaderSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	padding-top: 7px;
	border-bottom: 1px solid ${COLORS.gray};
`
export const TransactionHeaderText = styled.div`
	font-size: 1.5rem;
	font-weight: 600;
	color: ${COLORS.grayWhite};
`

export const TransactionNavBarSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
`
export const TransactionNavBarWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 90%;
	background-color: #2c2c33;
`

export const TransactionAvailableSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

export const TransactionFormSection = styled.div`
	padding: 15px;
`

export const TransactionInputContainer = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	width: 100%;
`
export const TransactionInput = styled.input`
	padding: 13px;
	width: 100%;
	color: ${COLORS.grayWhite};
	background: #2c2c33;
	border-radius: 5px;
	margin-bottom: 10px;
`
export const TransactionInputText = styled.div`
	color: ${COLORS.lightGray4};
	position: absolute;
	font-size: 12px;
`

export const TransactionTotalText = styled.div`
	display: flex;
	justify-content: flex-end;
	color: ${COLORS.lightGray};
	padding-right: 10px;
	font-size: 14px;
	overflow: hidden;
`

export const TransactionSliderSection = styled.div``
export const TransactionRangeSlider = styled.div`
	width: 98%;
	display: flex;
	margin-top: 10px;
`

// eslint-disable-next-line prettier/prettier
export const CustomDot = styled.div<{item?:number}>`
	width: 13px;
	height: 13px;
	background-color: ${COLORS.lightGray};
	border-radius: 50px;
	position: absolute;
	margin-top: 1px;
	border: 2px solid ${COLORS.HeaderBarBackground};
	overflow: hidden;
	z-index: 0;
	@media (max-width: 1200px) {
		${({ item }) => {
			return item && `margin-left:${item * 1.5}%`
		}}
	}
	@media (max-width: 900px) {
		${({ item }) => {
			return item && `margin-left:${item * 2.6}%`
		}}
	}
	@media (max-width: 900px) {
		${({ item }) => {
			return item && `margin-left:${item * 2.7}%`
		}}
	}
	${({ item }) => {
		return item && `margin-left:${item + 1.5}%`
	}}
`
export const TransactionSliderPercent = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
`
