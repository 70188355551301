import styled from 'styled-components'

export const MProofOfSelectSection = styled.div`
	.ant-select-selector {
		background-color: transparent !important;
		border: 1px solid ${p => p.theme.COLORS.borderColor} !important;
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-arrow {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: ${p => p.theme.COLORS.content};
	}
`
