import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'

const UserFeedbackSatisfaction = ({ tab, setTab }) => {
	const { t } = useTranslation()
	return (
		<MView>
			<MText style={{ marginBottom: 10, fontSize: 14 }}>
				{t('satisfaction')}
			</MText>
			<MView
				style={{
					flexDirection: 'row',
					flexWrap: 'wrap',
					width: 250,
				}}>
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
					<MView
						key={index.toString()}
						onClick={() => setTab(item)}
						style={{
							background:
								tab === item ? COLORS.secondary : '#eee',
							width: 35,
							height: 37,
							alignItems: 'center',
							justifyContent: 'center',
							margin: 5,
							borderRadius: 5,
							cursor: 'pointer',
						}}>
						<MText
							style={{
								fontSize: 14,
								color: tab === item ? '#fff' : '#333',
							}}>
							{item}
						</MText>
					</MView>
				))}
				<MView
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '90%',
						paddingInline: 5,
						marginTop: 10,
					}}>
					<MText style={{ fontSize: 11 }}>
						{t('notAtAllLikely')}
					</MText>
					<MText style={{ fontSize: 11 }}>
						{t('extreamelyLikely')}
					</MText>
				</MView>
			</MView>
		</MView>
	)
}

export default UserFeedbackSatisfaction
