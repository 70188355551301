import React from 'react'
import { View } from 'styles/reactnative'
import UserFeedbackEvent from './UserFeedbackEvent'
import { UserFeedbackHalf } from './styles'
import UserFeedbackContainer from './UserFeedbackEvent/UserFeedbackContainer'

const UserFeedback = () => {
	return (
		<View style={{ width: '100%' }}>
			<UserFeedbackEvent />
			<UserFeedbackHalf style={{ marginBlock: 30 }}>
				<UserFeedbackContainer />
			</UserFeedbackHalf>
		</View>
	)
}

export default UserFeedback
