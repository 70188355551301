import React from 'react'
import { CSSProperties } from 'styled-components'
import { Text } from 'styles/reactnative'
import { CheckboxWrapper } from './styles'

interface IProps {
	label?: string
	disabled?: boolean
	value?: boolean
	onChange?: any
	inputStyle?: CSSProperties
	labelStyle?: CSSProperties
	textStyle?: CSSProperties
	borderColor?: string
}

const CICheckBox = ({
	label,
	value,
	onChange,
	inputStyle,
	labelStyle,
	textStyle,
	borderColor,
}: IProps) => {
	return (
		<CheckboxWrapper borderColor={borderColor}>
			<label
				style={{
					display: 'flex',
					flexDirection: 'row',
					cursor: 'pointer',
					...labelStyle,
				}}>
				<input
					type="checkbox"
					checked={value}
					onChange={onChange}
					style={{ ...inputStyle }}
				/>
				{label ? (
					<Text
						size={14}
						style={{
							fontWeight: '300',
							...textStyle,
						}}>
						{label}
					</Text>
				) : null}
			</label>
		</CheckboxWrapper>
	)
}

export default React.memo(CICheckBox)
