import { CoinLogo } from 'app/components'
import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useInfo24H } from 'app/hooks/trade/info24H'
import BigNumber from 'bignumber.js'
import React, { useContext, useDeferredValue } from 'react'
import { useHistory } from 'react-router-dom'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { SearchHistoryItems } from './styles'

const SearchHistoryItem = ({ item, setOpenSearch }) => {
	const navigation = useHistory()
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS

	let { data: info24H } = useInfo24H(item?.id)
	let { data: indexPrice } = useIndexPrice(item?.id)
	info24H = useDeferredValue(info24H)

	if (!indexPrice) {
		indexPrice = [{ price: 0 }]
	}
	const rateBackground =
		info24H?.rate > 0 ? COLORS.lightGreen : COLORS.lightRed

	return (
		<SearchHistoryItems
			onClick={() => {
				goTo(navigation, `/trade/${item?.id}`)
				setOpenSearch(false)
			}}>
			<MView
				style={{
					height: '20%',
					width: '100%',
					flexDirection: 'row',
					alignItems: 'center',
				}}>
				<CoinLogo src={item?.image} size={20} />
				<MText style={{ marginLeft: 5 }}>{item?.symbol}</MText>
				<MText style={{ marginLeft: 5, color: textsilver }}>
					{item?.sub_symbol}
				</MText>
			</MView>
			<MView
				style={{
					width: '100%',
					height: '80%',
					justifyContent: 'center',
					flexDirection: 'row',
				}}>
				<MView
					style={{
						width: '15%',
						height: '100%',
						justifyContent: 'center',
					}}>
					<MView
						style={{
							height: '70%',
							width: 3,
							background: rateBackground,
							borderRadius: 10,
						}}
					/>
				</MView>
				<MView
					style={{
						width: '75%',
						height: '100%',
						justifyContent: 'space-between',
						paddingBlock: 12,
					}}>
					<MText style={{ color: textsilver }}>Spot</MText>
					<MText style={{ color: textw }}>
						{BigNumber(indexPrice?.[0]?.price).toFormat(2)}
					</MText>
					<MText style={{ color: rateBackground, fontSize: 12 }}>
						{BigNumber(info24H?.rate).toFormat(2) + '%'}
					</MText>
				</MView>
			</MView>
		</SearchHistoryItems>
	)
}

export default React.memo(SearchHistoryItem)
