import styled from 'styled-components'

export const MNotiSettingSection = styled.div`
	width: 100%;
	height: 100%;
`
export const MNotiSettingItemContainer = styled.div`
	width: 100%;
	border-bottom: 1px solid ${p => p.theme.COLORS.borderColor};
`
export const MNotiSettingItemSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-inline: 16px;
	padding-bottom: 16px;
	padding-top: 10px;
`
