import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { MView } from '../../../../../styles/mobilePageStyles'
import { Text } from '../../../../../styles/reactnative'
import { FONTS } from '../../../../constants'

interface IProps {
	title: any
	content: any
	contentColor?: any
}

const OrderItem = ({ title, content, contentColor }: IProps) => {
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<MView
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginTop: 10,
				width: '100%',
			}}>
			<MView style={{ flexDirection: 'row' }}>
				<Text style={{ ...FONTS.h6, color: THEMECOLORS.textw }}>
					{t(title)}
				</Text>
			</MView>
			<MView>
				<Text
					style={{
						...FONTS.h6,
						color: contentColor
							? contentColor
							: THEMECOLORS.textsilver,
					}}>
					{content}
				</Text>
			</MView>
		</MView>
	)
}
export default React.memo(OrderItem)
