import styled from 'styled-components'
import bgDark from 'assets/images/abouts.png'
import bgLight from 'assets/images/abouts.png'
import appview from 'assets/images/aboutappview.png'
import Experience from 'assets/images/about-experience.png'

export const AboutUsSection = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	height: 363px;
	background-color: transparent;
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media only screen and (min-width: 600px) {
		height: 400px;
	}
	@media only screen and (min-width: 992px) {
		height: 450px;
	}
	@media only screen and (min-width: 1200px) {
		height: 513px;
	}
`
export const AboutUsSectionContent = styled.div`
	width: 40%;
	text-align: center;
	margin-top: 20px;
	@media only screen and (min-width: 600px) {
		width: 80%;
	}
	@media only screen and (min-width: 992px) {
		width: 60%;
	}
	@media only screen and (min-width: 1200px) {
		width: 40%;
	}
`
export const AboutAppView = styled.div<{ isDarkMode?: any }>`
	height: 900px;

	background-color: transparent;
	background-image: url(${p => (p.isDarkMode ? appview : appview)});
	/* background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%; */
	background-position: center;
	margin-top: 119px;
	display: flex;
	align-self: center;
	width: 80%;
	z-index: 999;

	@media only screen and (min-width: 600px) {
		width: 100%;
		height: 530px;
	}
	@media only screen and (min-width: 992px) {
		width: 100%;
		height: 550px;
	}
	@media only screen and (min-width: 1200px) {
		width: 80%;
		height: 513px;
	}
	@media only screen and (min-width: 1400px) {
		width: 70%;
		height: 513px;
	}
`
export const AboutAppViewContent = styled.div`
	width: 40%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	align-items: center;

	@media only screen and (min-width: 600px) {
		width: 60%;
	}
	@media only screen and (min-width: 992px) {
		width: 60%;
	}
	@media only screen and (min-width: 1200px) {
		width: 60%;
	}
`

export const AboutExperienceSection = styled.div<{ isDarkMode?: any }>`
	background-color: transparent;
	background-image: url(${p => (p.isDarkMode ? Experience : Experience)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	margin-top: 119px;
	height: 380px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	@media only screen and (min-width: 600px) {
		height: 620px;
	}
	@media only screen and (min-width: 992px) {
		height: 620px;
	}
	@media only screen and (min-width: 1200px) {
		height: 400px;
	}
`
export const AboutExperienceContainer = styled.div`
	margin: auto;
	height: 280px;
	margin-top: 100px;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 68%;
	}
	@media only screen and (max-width: 968px) {
		width: 80%;
	}
	@media screen and (max-width: 1200px) {
		height: 550px;
	}
`
export const ExperienceListItem = styled.div`
	width: 285px;
	height: 173px;
	margin: 10px;
	padding-inline: 30px;
	padding-block: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	@media screen and (max-width: 1200px) {
		width: 45%;
	}
	@media screen and (max-width: 900px) {
		width: 40%;
	}
	@media screen and (min-width: 1200px) {
		width: 23%;
	}
`
export const ExperienceLists = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 1200px) {
		flex-wrap: wrap;
		justify-content: flex-start;
		& > ${ExperienceListItem} {
			width: calc(25% - 20px);
			margin: 10px;
		}
	}

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
		& > ${ExperienceListItem} {
			width: calc(50% - 20px);
			margin: 10px;
		}
	}
`
export const AboutFounderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-self: center;
	margin-top: 163px;
	background-color: ${props =>
		props.theme.isDarkMode
			? 'rgba(255, 255, 255, 0.3)'
			: 'rgba(52, 49, 64, 0.8)'};

	width: 100%;
	height: 653px;
`
export const AboutFounderSection = styled.div`
	margin: auto;
	height: 100%;

	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 68%;
	}
	@media only screen and (max-width: 968px) {
		width: 80%;
	}
`
export const AboutServiceSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-self: center;
	margin-top: 163px;
`
export const AboutServiceListItem = styled.div`
	width: 250px;
	height: 250px;
	margin: 10px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	@media screen and (max-width: 1200px) {
		width: 45%;
	}
	@media screen and (max-width: 900px) {
		width: 40%;
	}
	@media screen and (min-width: 1200px) {
		width: 23%;
	}
`
export const AboutServiceListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 1200px) {
		flex-wrap: wrap;
		justify-content: flex-start;
		& > ${AboutServiceListItem} {
			width: calc(25% - 20px);
			margin: 10px;
		}
	}

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
		& > ${AboutServiceListItem} {
			width: calc(50% - 20px);
			margin: 10px;
		}
	}
`

export const AboutServiceListSection = styled.div`
	margin: auto;
	height: 280px;
	margin-top: 100px;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 68%;
	}
	@media only screen and (max-width: 968px) {
		width: 80%;
	}
	@media screen and (max-width: 1200px) {
		height: 550px;
	}
`
