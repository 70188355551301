import { CTabs } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'

const OrderContainerTabs = ({ cTab, setCTab, cTabs }) => {
	const { width } = useWindowDimensions()
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	return (
		<div>
			<CTabs
				tabs={cTab}
				setTabs={setCTab}
				data={cTabs}
				vStyle={{
					borderBottom: `1px solid ${borderColor}`,
					justifyContent: width < 600 ? 'flex-start' : 'flex-start',
				}}
				activeColor={COLORS.secondary}
				borderActiveColor={COLORS.secondary}
				style={{
					fontSize: 12,
					fontWeight: '600',
					marginRight: 20,
				}}
			/>
		</div>
	)
}

export default OrderContainerTabs
