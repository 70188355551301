import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CButton, CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useChangeLanguage } from '../hooks'
import { LanguageModalBodyWrapper } from './styles'
import { useFetcherPublic } from 'app/hooks/fetcher'
import { ThemeContext } from 'theme/ThemeProvider'
import { View } from 'styles/reactnative'
import { IconCheck } from '@tabler/icons'

const LanguageModalBody = ({ closeModal }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { i18n } = useTranslation()
	const [lang, setLang] = useState(i18n.language)
	useChangeLanguage(lang)

	const { data } = useFetcherPublic('/system/language', false)

	return (
		<LanguageModalBodyWrapper>
			{data?.result?.result?.map((item: any, index: number) => (
				<View
					key={index.toString()}
					style={{
						flexDirection: 'row',
						width: '50%',
						background:
							item?.code?.toLowerCase()?.substring(0, 2) === lang
								? isDarkMode
									? '#f1f1f1'
									: 'rgba(217, 217, 217, 0.1)'
								: 'transparent',
						alignItems: 'center',
						justifyContent: 'space-between',
						paddingLeft: 15,
						paddingBlock: 10,
						borderRadius: 5,
						marginTop: 4,
						paddingRight: 15,
					}}>
					<CButton
						title={item?.name}
						icon={
							<CImage
								src={item?.image}
								alt="logo"
								width={18}
								height={18}
								resizeMode={'cover'}
								style={{ borderRadius: 50, marginRight: 7 }}
							/>
						}
						onClick={() => {
							setLang(item?.code?.toLowerCase())
							setImmediate(() => {
								closeModal && closeModal()
							}, 0)
						}}
						style={{
							fontSize: 13,
							color:
								item?.code?.toLowerCase()?.substring(0, 2) ===
								lang
									? COLORS.primary
									: textw,
							flexDirection: 'row-reverse',
						}}
					/>
					{item?.code?.toLowerCase()?.substring(0, 2) === lang ? (
						<IconCheck size={14} color={COLORS.primary} />
					) : null}
				</View>
			))}
		</LanguageModalBodyWrapper>
	)
}

export default LanguageModalBody
