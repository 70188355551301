/* eslint-disable prettier/prettier */
import { DropdownContainer } from 'app/components/Common/CDropDown/AdvancedDropdown/styles'
import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const TradeRealTimeFilterSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-inline: 10px;
	width: 100%;
	height: 45%;
	${DropdownContainer}:after {
		content: ' ';
		position: absolute;
		right: 15px;
		top: -8px;
		border-top: none;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		border-bottom: 10px solid ${COLORS.gray};
	}
`
export const FilterText = styled.div<{ selected?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 100%;
	&:hover {
		background-color: ${COLORS.grey};
		color: ${COLORS.primary};
	}
	${({ selected }) =>
		selected &&
		`  
       background-color: ${COLORS.grey};
       color: ${COLORS.primary};
    `}
`

export const OrderBookContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`

export const OrderBookHeaderSection = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	font-weight: bold;
	color: ${COLORS.greyWhite};
	margin-bottom: 4px;
	width: 100%;
	padding-inline: 10px;
	/* padding-block: 2px; */
`

export const OrderBookRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	padding: 0 8px;
	color: ${props => props.theme.COLORS.textw};
	margin-bottom: 2px;
	&:hover {
		background-color: ${props => props.theme.COLORS.gray};
	}
	cursor: pointer;
`

export const BidRow = styled(OrderBookRow)``
export const AskRow = styled(OrderBookRow)``
export const Price = styled.div<{ asks?: boolean }>`
	flex: 1.5;
	font-size: 13px;
	${({ asks }) => `color:${asks ? COLORS.lightRed : COLORS.lightGreen};`}
`

export const BidBar = styled.div<{ asks?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	padding: 0 8px;
	color: ${props => props.theme.COLORS.textw};
	margin-bottom: 2px;
	${({ asks }) => `background:${asks ? COLORS.lightRed : COLORS.lightGreen};`}
`

export const Quantity = styled.div`
	flex: 1;
	text-align: right;
	font-size: 12px;

	&.flash {
		animation-name: flash;
		animation-duration: 0.5s;
		animation-iteration-count: 1;
	}
`

export const Total = styled.div`
	flex: 1;
	text-align: right;
	font-size: 12px;
`
export const OrderBookCurrentPriceWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 30px;
	width: 100%;
	justify-content: flex-start;
	margin-top: -5px;
`
