import React, { useState, useContext } from 'react'
import { IconCategory, IconFlame } from '@tabler/icons'
import { CTabs } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'

import AllTraderMaster from './AllTraderMaster'
import TopTraderMaster from './TopTraderMaster'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const TopHighestTraders = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const TopMasters = 'topMasterTraders'
	const AllMasters = 'allMasterTraders'
	const [tabs, setTabs] = useState(TopMasters)
	const data = [TopMasters, AllMasters]
	const icons = [
		<IconFlame size={16} style={{ marginRight: 5 }} color={textw} />,
		<IconCategory size={16} style={{ marginRight: 5 }} />,
	]

	return (
		<View style={{ width: '100%', height: '100%' }}  >
			<div
				style={{
					position: 'fixed',
					zIndex: 99999,
					background: 'rgba(0,0,0,0.5)',
					width: '100%',
					height: '100%',
					top: 0,
					color: '#fff',
					fontSize: 50,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					COMMING SOON
				</div>
			<CTabs
				tabs={tabs}
				setTabs={setTabs}
				data={data}
				style={{
					fontSize: width > 900 ? 18 : 14,
					fontWeight: '600',
					paddingBottom: 15,
					marginRight: 20,
				}}
				activeColor={textw}
				borderActiveColor={textw}
				iconLeft={true}
				icon={icons}
			/>
			{tabs === TopMasters ? <TopTraderMaster setTabs={setTabs} /> : null}
			{tabs === AllMasters ? <AllTraderMaster /> : null}
		</View>
	)
}

export default React.memo(TopHighestTraders)
