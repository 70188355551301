import { IconArrowLeft, IconShare } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AnnouncementDetailHeader = ({ show, setShow }) => {
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	//거래소 이름 치환
	const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

	const currentname = t('title')

	const title = show?.title?.replace(
		new RegExp(list.join('|'), 'gi'),
		currentname
	)
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',
				height: 100,
				padding: 20,
			}}>
			<View
				style={{
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%',
					paddingBlock: 10,
					flexDirection: 'row',
				}}>
				<CButton
					icon={<IconArrowLeft color={textw} />}
					onClick={() => {
						setShow({
							...show,
							show: !show?.show,
						})
					}}
					style={{
						marginTop: 10,
						paddingRight: 20,
						height: 30,
					}}
				/>
				<View
					style={{
						alignItems: 'flex-start',
						marginTop: 5,
					}}>
					<Text size={18}>{title}</Text>
					<Text size={14} style={{ color: textsilver, marginTop: 5 }}>
						{moment(show.rdate).format('YYYY-MM-DD')}
					</Text>
				</View>
			</View>
			<CButton icon={<IconShare color={textw} />} />
		</View>
	)
}

export default AnnouncementDetailHeader
