import React, { useEffect, useState } from 'react'
import { View } from 'styles/reactnative'
import { CFlatList } from 'app/components/Common'
import { loadData } from 'utils/api'
import { RankingTabsWrapper, RankSection } from './styles'
import BotTableTitle from './BotTableTitle'
import RankingTopTabs from './RankingTopTabs'
import RankItem from './RankItem'
import RankTableHeader from './RankTableHeader'

const BotLeaderBoard = () => {
	const DailyRank = 'dailyRanking'
	const WeekRank = '7DayRanking'
	const TodayRank = '24HArbitrageRanking'
	const [tab, setTab] = useState(DailyRank)
	const tabs = [DailyRank, WeekRank, TodayRank]

	const [data, setData] = useState<any[]>([])

	useEffect(() => {
		loadData().then(res => {
			const slice = res.slice(0, 20)
			if (tab === WeekRank) {
				setData(slice.reverse())
			} else {
				setData(slice)
			}
		})
	}, [tab])
	const renderItem = (item: any, index: any) => {
		return <RankItem item={item} index={index} />
	}
	return (
		<View style={{ alignItems: 'flex-start', width: '100%' }}>
			<BotTableTitle />
			<RankingTopTabs tab={tab} setTab={setTab} tabs={tabs} />
			<RankingTabsWrapper>
				<RankSection>
					<RankTableHeader />
					<CFlatList
						data={data}
						renderItem={renderItem}
						itemSize={50}
					/>
				</RankSection>
			</RankingTabsWrapper>
		</View>
	)
}

export default React.memo(BotLeaderBoard)
