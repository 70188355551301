import React from 'react'

import { View } from 'styles/reactnative'
import AuthButtonLists from '../AuthButtonLists'

const AssetsFooter = () => {
	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
			}}>
			<AuthButtonLists title={'overview'} go={'/assets?tab=1'} />
			<AuthButtonLists title={'spot'} go={'/assets?tab=2'} />
			<AuthButtonLists title={'derivatives'} go={'/assets?tab=3'} />
			{/* <AuthButtonLists title={'earn'} go={'assets'} /> */}
		</View>
	)
}

export default AssetsFooter
