import styled from 'styled-components'

export const TradingFeeRateSection = styled.div`
	width: 100%;
	margin-top: 50px;
`
export const TradingFeeRateContainer = styled.div`
	width: 100%;
	min-height: 500px;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
`
