import React from 'react'
import { TradeDrawerListItemSection } from '../styles'
import { useInfo24H } from 'app/hooks/trade/info24H'
import ListCoin from './ListCoin'
import PriceHigh from './PriceHigh'
import ListVolume from './ListVolume'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'

const HeaderSearchItem = ({ item, index }: any) => {
	const navigation = useHistory()
	const { data: info24H } = useInfo24H(item?.id)
	let { getPrice } = useIndexPrice(item?.id)

	return (
		<TradeDrawerListItemSection
			onClick={() => {
				goTo(navigation, `/trade/${item?.id}`)
			}}>
			<ListCoin item={item} />
			<PriceHigh indexPrice={getPrice(item?.id)} info24H={info24H} />
			<ListVolume volume={info24H?.volume} />
		</TradeDrawerListItemSection>
	)
}

export default HeaderSearchItem
