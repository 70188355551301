import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { AboutAppViewContent, AboutServiceSection } from './styles'
import AboutContext from './AboutContext'

const AboutService = () => {
	const { t } = useTranslation()
	return (
		<AboutServiceSection>
			<View>
				<Text size={42}>{t('ourServices')}</Text>
				<AboutAppViewContent>
					<AboutContext context={t('ourServiceContent')} />
				</AboutAppViewContent>
			</View>
		</AboutServiceSection>
	)
}

export default AboutService
