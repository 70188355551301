import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const UnAuthHeaderMenu = () => {
	const navigate = useHistory()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	const nav = (url: string) => navigate.push(url)
	return (
		<View style={{ flexDirection: 'row', marginInline: 20 }}>
			<CButton
				title={t('login')}
				onClick={() => nav('/login')}
				style={{
					width: 100,
					height: 32,
					background: COLORS.grad2,
					borderRadius: 15,
					marginRight: 10,
					color: COLORS.white,
				}}
			/>
			<CButton
				title={t('signUp')}
				onClick={() => nav('/signup')}
				style={{
					color: textw,
					width: 100,
					height: 32,
					border: `1px solid ${textw}`,
					borderRadius: 15,
				}}
			/>
		</View>
	)
}

export default UnAuthHeaderMenu
