import styled from 'styled-components'

export const TraderDetailInfoRightSideSection = styled.div`
	width: 65%;
	display: flex;
	flex-direction: column;
	background-color: ${p => p.theme.COLORS.bgColor};
	margin-left: 30px;
	border-radius: 12px;
	@media only screen and (max-width: 1200px) {
		margin-left: 0px;
		width: 98%;
		align-self: center;
	}
`

export const TraderTableListSection = styled.div`
	width: 100%;
	background-color: transparent;
`
export const TraderTableContainer = styled.div`
	.ag-cell-focus,
	.ag-cell-no-focus {
		border: none !important;
	}
	/* This CSS is to not apply the border for the column having 'no-border' class */
	.no-border.ag-cell:focus {
		border: none !important;
		outline: none;
	}
	.ag-root-wrapper {
		border: none !important;
		padding: 10px;
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
	.ag-header-cell {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
	.ag-header-cell-text {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-row-position-absolute {
		cursor: pointer;
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-row-summary-panel {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-page-summary-panel {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-button {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-row-even {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
	.ag-row-odd {
		background-color: ${props => props.theme.COLORS.bgColor} !important;
	}
`
