import { P } from 'app/components/NotFoundPage/P'
import styled from 'styled-components'

export const FooterContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	color: #ccc;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid ${props => props.theme.COLORS.borderColor};
`

export const FooterWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-block: 20px;
`

export const Copyright = styled.div`
	display: flex;
	align-items: center;
`
export const CopyrightText = styled.div``
export const PrivacyBtn = styled.div`
	display: flex;
`
