import React, { useState, useContext } from 'react'
import { IconChevronDown } from '@tabler/icons'
import { COLORS } from 'app/constants/'
import CButton from '../../CButton'
import FilterDropdown from './FilterDropdown'
import { CDropDownSection, DropdownContainer } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

const NormalDropdown = ({
	type,
	data,
	title,
	icon,
	style,
	btnStyle,
	selected,
	setSelected,
	bgHover,
	textHover,
	boxShadow,
	borderColor,
	onPress,
}: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const [show, setShow] = useState(false)

	return (
		<CDropDownSection borderColor={borderColor}>
			<CButton
				icon={
					icon ? (
						icon
					) : (
						<IconChevronDown
							size={12}
							style={{ marginLeft: 3 }}
							color={textw}
						/>
					)
				}
				title={title != undefined ? title : ''}
				onClick={() => {
					setShow(!show)
				}}
				backgroundHover={bgHover}
				textHover={textHover}
				style={{
					color: COLORS.grayWhite,
					paddingInline: 10,
					fontSize: 12,
					paddingBlock: 5,
					...btnStyle,
				}}
			/>
			{show ? (
				<DropdownContainer
					style={{
						display: 'flex',
						flexDirection: 'column',
						boxShadow: boxShadow
							? boxShadow
							: `3px 5px 13px ${COLORS.grey}`,
						...style,
					}}>
					<FilterDropdown
						type={type}
						onPress={() => {
							if (onPress) {
								onPress()
							}
						}}
						data={data}
						show={show}
						setShow={setShow}
						selected={selected}
						setSelected={setSelected}
						backgroundHover={bgHover}
						textHover={textHover}
					/>
				</DropdownContainer>
			) : null}
		</CDropDownSection>
	)
}

export default React.memo(NormalDropdown)
