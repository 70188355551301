import React, { useEffect, useRef, useState } from 'react'
import { TradeDetailContainer, TradeDetailListsWrapper } from './styles'
import TradeActiveTable from './TradeActiveTable'
import TradeCancelTable from './TradeCancelTable'
import TradeClosedPnlTable from './TradeClosedPnlTable'

import TradeDetailHeader from './TradeDetailHeader'
import TradeFilledTable from './TradeFilledTable'
import TradeOrderHistoryTable from './TradeOrderHistoryTable'
import TradePositionsTable from './TradePositionsTable'
import { useWindowDimensions } from '../../../hooks'

const TradeDetail = ({ isResizing }) => {
	//positions', 'closedpnl', 'active', 'filled', 'orderhistory'
	const [tab, setTab] = useState('positions')
	const [height, setHeight] = useState(0)

	const { width: w, height: h } = useWindowDimensions()

	const parentRef = useRef<any>(null)

	useEffect(() => {
		if (parentRef.current) {
			if (parentRef.current?.clientHeight != height) {
				setTimeout(() => {
					setHeight(parentRef.current?.clientHeight)
				}, 100)
				setTimeout(() => {
					setHeight(parentRef.current?.clientHeight)
				}, 500)
			}
		}
	}, [w, h, parentRef.current?.clientHeight])

	return (
		<TradeDetailContainer ref={parentRef}>
			<TradeDetailHeader tabs={tab} setTabs={setTab} />

			<TradeDetailListsWrapper>
				{tab === 'positions' && (
					<TradePositionsTable parentHeight={height} />
				)}
				{tab === 'closedpnl' && (
					<TradeClosedPnlTable parentHeight={height} />
				)}
				{tab === 'active' && <TradeActiveTable parentHeight={height} />}
				{tab === 'filled' && <TradeFilledTable />}
				{tab === 'cancel' && <TradeCancelTable parentHeight={height} />}
				{tab === 'orderhistory' && <TradeOrderHistoryTable />}
			</TradeDetailListsWrapper>
		</TradeDetailContainer>
	)
}

export default React.memo(TradeDetail)
