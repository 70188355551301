import React from 'react'
import { IconStar } from '@tabler/icons'
import { View } from 'styles/reactnative'
import { FavouriteSection } from './styles'

const Favourite = () => {
	return (
		<FavouriteSection>
			<View>
				<IconStar size={14} />
			</View>
		</FavouriteSection>
	)
}

export default Favourite
