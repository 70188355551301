import { STORE_EXCHANGE } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import { getPersistData } from '../persist'
import { useToken } from '../auth/token'
import API from '../../../utils/api'
import { useMemo } from 'react'

const KEY = STORE_EXCHANGE

let swrData: any = getPersistData(KEY)

export function useExchangeStore(initData?: any, reset?: boolean) {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const result = await API.get(
				'/exchanges?id=1',
				token + '',
				{},
				false
			)
			swrData = result.result

			return swrData
		},
		{ dedupingInterval: 1000 * 60 * 30 }
	)

	const EPs = useMemo(
		() =>
			data?.length > 0
				? data?.filter((item: any) => {
						return true
				  })
				: [],
		[data]
	)

	return {
		data,
		EPs: EPs,
		mutate: (value?: any) => {
			if (value !== undefined) swrData = value
			return mutate()
		},
	}
}
