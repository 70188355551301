import React from 'react'
import useSWRInfinite from 'swr/infinite'
import NotificationHeader from './NotificationHeader'
import NotificationLists from './NotificationListItems'
import { HeaderNotificationSection } from './styles'
import { useToken } from 'app/hooks'
import { fetcher } from 'app/hooks/fetcher'

const HeaderNotification = () => {
	const { data: token } = useToken()
	const PAGE_SIZE = 20
	const { data: res } = useSWRInfinite(
		index =>
			token
				? `/system/notification/?page=${
						index + 1
				  }&pagesize=${PAGE_SIZE}&type=0`
				: null,
		async key => {
			return await fetcher(key, token, false)
		}
	)
	let data = res?.[0]?.result?.result?.result || []
	const count = res?.[0]?.result?.result?.count || 0
	return (
		<HeaderNotificationSection>
			<NotificationHeader count={count} />
			{data?.map((item: any, index: any) => (
				<NotificationLists
					item={item}
					index={index}
					key={index.toString()}
				/>
			))}
		</HeaderNotificationSection>
	)
}

export default React.memo(HeaderNotification)
