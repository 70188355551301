import styled from 'styled-components'

// eslint-disable-next-line prettier/prettier
export const SectionContainer = styled.div < { bgColor?: string; color?: string} >`
	position: static;
	top: 0;
	/* z-index: 1; */
	width: 100%;
	/* height: 290px; */
	color: ${props => props.color};
	background-color: ${props => props.bgColor};
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const SectionWrap = styled.div`
	width: 100%;
	align-items: center;
	max-width: 1280px;
	display: flex;
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: space-between;
`

export const EventButtonSection = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 30px;
`
export const EventBoxSection = styled.div`
	background-color: transparent;
	border-radius: 10px;
	cursor: pointer;
	color: #000;
`
