import React from 'react'
import { MView } from 'styles/mobilePageStyles'
import { Text } from 'styles/reactnative'
import {
	PrivacyPolicyListContent,
	PrivacyPolicyListSection,
	PrivacyPolicyListTitle,
} from './styles'
import { useTranslation } from 'react-i18next'

const PrivacyPolicyList = () => {
	const { t } = useTranslation()
	return (
		<MView style={{ width: '100%' }}>
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400' }}>
						{t('mobileRiskWarningTitle1')}
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent>
					<Text size={14} style={{ lineHeight: 1.5 }}>
						{t('mobileRiskWarningContent1')}
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>

			{/* 2 */}
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400' }}>
						{t('mobileRiskWarningTitle2')}
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent>
					<Text size={14} style={{ lineHeight: 1.5 }}>
						{t('mobileRiskWarningContent2')}
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>
			{/* 3 */}
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400' }}>
						{t('mobileRiskWarningTitle3')}
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent
					style={{
						flexDirection: 'column',
					}}>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20 }}>
						{t('mobileRiskWarningContent3-1')}
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20 }}>
						{t('mobileRiskWarningContent3-2')}
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20 }}>
						{t('mobileRiskWarningContent3-3')}
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20 }}>
						{t('mobileRiskWarningContent3-4')}
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20 }}>
						{t('mobileRiskWarningContent3-5')}
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>

		</MView>
	)
}

export default PrivacyPolicyList
