import React from 'react'
import { TraderDetailInfoSection } from './styles'
import TraderDetailInfoLeftSide from './TraderDetailInfoLeftSide'
import TraderDetailInfoRightSide from './TraderDetailInfoRightSide'
const TraderDetailInfo = () => {
	return (
		<TraderDetailInfoSection>
			<TraderDetailInfoLeftSide />
			<TraderDetailInfoRightSide />
		</TraderDetailInfoSection>
	)
}

export default TraderDetailInfo
