import React from 'react'
import { CFlatList } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import { MView } from 'styles/mobilePageStyles'

import MOrderPositionItems from './MOrderPositionItems'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'

const MOrderPositions = () => {
	const { height } = useWindowDimensions()

	const { data } = useTradePosition()

	const renderItem = (item: any, index: any) => (
		<MOrderPositionItems item={item} />
	)
	return (
		<MView
			style={{
				width: '100%',
				height: height - 85,
				paddingTop: 10,
				paddingLeft: 10,
				paddingRight: 10,
			}}>
			<CFlatList
				data={data?.length ? data : []}
				renderItem={renderItem}
				noDataTitle={'currentPositionTitle'}
				itemSize={350}
			/>
		</MView>
	)
}

export default MOrderPositions
