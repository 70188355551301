import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../../../styles/reactnative'
import { COLORS } from '../../../../constants'
import { TradeDetailListItemSection, TradeText } from './styles'
import { useExchangeSetting } from '../../../../hooks/trade/exchangeSetting'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import KrwPrice from '../../../../components/KrwPrice'

const TradeDetailListItem = ({ item, index, dataType }: any) => {
	const coin = item?.pairs?.split('/')[0]
	const currency = item?.pairs?.split('/')[1]
	const { t, i18n } = useTranslation()
	const { EPs } = useExchangeStore()
	const EP = EPs.find((ep: any) => +ep.ep_id === +item?.exchange_pairs_id)
	return (
		<TradeDetailListItemSection>
			<TradeText style={{ flexDirection: 'column' }}>
				<Text>{item?.pairs}</Text>
				<Text style={{ color: 'orange' }}>
					{item?.cross_iso === 'C' ? t('cross') : t('iso')}{' '}
					{item?.leverage}X
				</Text>
			</TradeText>
			<TradeText style={{ width: 150 }}>
				{BigNumber(item?.amount)
					.multipliedBy(item?.leverage)
					.toFormat(EP?.dp2 || EP?.dp || 3)}
			</TradeText>
			<TradeText style={{ minWidth: 180 }}>
				{item?.trade_price || item?.order_price} {currency}
			</TradeText>
			<TradeText>
				{item?.limit_market === 'L' ? t('limit') : t('market')}
			</TradeText>
			<TradeText
				style={{
					color:
						item?.long_short === 'L'
							? COLORS.lightGreen
							: COLORS.lightRed,
				}}>
				{item?.long_short === 'L' ? t('long') : t('short')}
			</TradeText>
			<TradeText style={{ width: 200 }}>
				{BigNumber(item?.margin).toFormat(3)} {currency}
			</TradeText>

			<TradeText style={{ width: 90 }}>
				<KrwPrice
					price={-BigNumber(item?.fee_price).toNumber()}
					krw={true}
					dp={3}
				/>
			</TradeText>
			<TradeText style={{ width: 170 }}>
				{new Date(item?.created_at).toLocaleString(i18n.language)}
			</TradeText>
			<TradeText style={{ width: 100 }}>
				{item?.status === 1
					? t('opened')
					: item?.status === 2
					? t('filled')
					: t('canceled')}
			</TradeText>
		</TradeDetailListItemSection>
	)
}

export default React.memo(TradeDetailListItem)
