import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { useHistory } from 'react-router-dom'
import { goTo } from 'app/hooks/navigation'
import { CoinLogo } from 'app/components/'
import { useHeader } from 'app/hooks'
import { SubDropdownListItemSection } from './styles'

import { COLORS } from 'app/constants'

const SubDropdownListItem = ({ item, index }) => {
	const { t } = useTranslation()
	const navigation = useHistory()
	const { data: header, mutate } = useHeader()

	return (
		<SubDropdownListItemSection
			onClick={() => {
				mutate({
					...header,
					display: 'none',
				})
				goTo(navigation, `/trade/${item?.id}`)
			}}>
			<CoinLogo size={26} src={item?.image} style={{ marginRight: 10 }} />
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<Text
					size={13}
					style={{
						fontWeight: '600',
						marginBottom: 3,
					}}>
					{item?.pairs}
				</Text>

				<Text
					size={12}
					style={{
						fontWeight: '300',
						color: COLORS.lightGray,
					}}>
					{item?.name} {item?.sub_symbol} {t('perpetual')}
				</Text>
			</View>
		</SubDropdownListItemSection>
	)
}

export default React.memo(SubDropdownListItem)
