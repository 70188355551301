import React, { FC, useEffect } from 'react'
import { Text } from 'styles/reactnative'
import {
	ModalBody,
	ModalCloseButton,
	ModalContainer,
	ModalHeader,
	ModalOverlay,
} from './styles'

type ModalProps = {
	visible: boolean
	onClose: () => void
	children?: any
	title?: string
	bgColor?: string
}
const CModal: FC<ModalProps> = ({
	visible,
	onClose,
	children,
	title,
	bgColor,
}) => {
	useEffect(() => {
		if (visible) {
			// Disable scrolling on the window when the modal is open
			document.body.style.overflow = 'hidden'
			// Re-enable scrolling when the modal is closed
			return () => {
				document.body.style.overflow = 'visible'
			}
		}
	}, [visible])
	return (
		<>
			{visible && (
				<ModalOverlay onClick={onClose}>
					<ModalContainer
						style={{ background: bgColor ? bgColor : '#fff' }}
						onClick={e => e.stopPropagation()}>
						{title ? (
							<ModalHeader>
								<Text
									size={20}
									style={{
										fontWeight: 'bold',
									}}>
									{title ? title : ''}
								</Text>
								<ModalCloseButton onClick={onClose}>
									x
								</ModalCloseButton>
							</ModalHeader>
						) : null}

						<ModalBody>{children}</ModalBody>
					</ModalContainer>
				</ModalOverlay>
			)}
		</>
	)
}

export default React.memo(CModal)
