import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { AboutWelcomeSection } from './styles'

const AboutWelcome = () => {
	const { t } = useTranslation()

	return (
		<AboutWelcomeSection>
			<MText style={{ color: COLORS.white, fontSize: 20 }}>
				{t('aboutWelcome')}
			</MText>
			<MView
				style={{
					width: '100%',
					paddingInline: 30,
					textAlign: 'center',
					marginTop: 20,
				}}>
				<MText
					style={{
						color: COLORS.white,
						fontSize: 14,
						lineHeight: 1.2,
					}}>
					{t('aboutWelcomeContent')}
				</MText>
			</MView>
		</AboutWelcomeSection>
	)
}

export default AboutWelcome
