import React, { useContext } from 'react'
import { useWindowDimensions } from 'app/hooks'
import { View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import { useWSize } from 'utils/ExpensiveFunction'
import ConvertType from './ConvertType'
import ConvertAmount from './ConvertAmount'
import ConvertQuote from './ConvertQuote'
import { ThemeContext } from 'theme/ThemeProvider'

const ConvertModal = ({ setShowModal }) => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const { height } = useWindowDimensions()

	return (
		<View
			style={{
				width: useWSize(100),
				height: height / 1.5,
				borderRadius: 5,
				backgroundColor: drawerBgColor,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={'convert'}
				closeModal={() => setShowModal(false)}
			/>
			<View style={{ width: '100%' }}>
				<ConvertType />
				<ConvertAmount />
				<ConvertQuote setShowModal={setShowModal} />
			</View>
		</View>
	)
}

export default React.memo(ConvertModal)
