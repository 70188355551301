import React, { useState, useContext } from 'react'

import { Text, View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import { useWSize } from 'utils/ExpensiveFunction'
import TransferFromTo from './TransferFromTo'
import TransferCoin from './TransferCoin'
import TransferAmount from './TransferAmount'
import TransferFooter from './TransferFooter'
import { useBalance } from 'app/hooks/user/mybalance'
import { ThemeContext } from 'theme/ThemeProvider'
import BigNumber from 'bignumber.js'
import { Input } from 'antd'
import { useFetcherPublic } from '../../hooks/fetcher'
import { useTranslation } from 'react-i18next'

const TransferModal = ({ setShowModal }) => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()

	const [transferFrom, setTransferFrom] = useState('spotwallet')
	const [transferTo, setTransferTo] = useState('futurewallet')
	const [amount, setAmount] = useState('')
	// const { data: tradeStore } = useTradeStore()
	let coinType = 'USDT'

	let type = 0
	if (transferFrom === 'futurewallet') {
		type = 1
	} else if (transferFrom === 'globalwallet') {
		coinType = 'KRW'
		type = 9
	}

	const { data: balance } = useBalance(coinType, type)

	const currencyList = useFetcherPublic('/system/currency')

	const equivusd = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'KRW'
	)

	return (
		<View
			style={{
				width: '100%',
				height: 450,
				borderRadius: 5,
				backgroundColor: drawerBgColor,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={'transfer'}
				closeModal={() => setShowModal(false)}
			/>
			<View style={{ width: '100%', paddingInline: 20 }}>
				<TransferFromTo
					transferFrom={transferFrom}
					setTransferFrom={setTransferFrom}
					transferTo={transferTo}
					setTransferTo={setTransferTo}
				/>
				<TransferCoin
					coinType={transferTo === 'globalwallet' ? 'KRW' : coinType}
				/>
				<TransferAmount
					balance={new BigNumber(balance?.balance)
						.minus(balance?.lockbalance)
						.toNumber()}
					amount={amount}
					setAmount={setAmount}
					coinType={coinType}
				/>

				{(transferFrom === 'globalwallet' ||
					transferTo == 'globalwallet') && (
					<View
						style={{
							width: '100%',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
							marginTop: 20,
						}}>
						<Text size={14}>
							{t('transferamount') + ' : ' + t('currencyrate')} (
							{equivusd.price})
						</Text>
						<Input
							style={{
								fontWeight: 'bold',
							}}
							readOnly
							value={
								transferFrom === 'globalwallet'
									? BigNumber(amount || 0)
											.dividedBy(equivusd?.price)
											.toFormat(6) + ' USDT'
									: BigNumber(amount || 0)
											.multipliedBy(equivusd?.price)
											.toFormat(0) + ' KRW'
							}
						/>
					</View>
				)}

				<TransferFooter
					transferFrom={
						transferFrom === 'spotwallet'
							? 0
							: transferFrom == 'globalwallet'
							? 9
							: 1
					}
					transferTo={
						transferTo === 'spotwallet'
							? 0
							: transferTo == 'globalwallet'
							? 9
							: 1
					}
					amount={amount}
					coinType={coinType}
					balance={new BigNumber(balance?.balance)
						.minus(balance?.lockbalance)
						.toNumber()}
				/>
			</View>
		</View>
	)
}

export default React.memo(TransferModal)
