import React from 'react'
import { View } from 'styles/reactnative'
import { FilterText } from './styles'

const FilterDropdown = ({
	type,
	data,
	setShow,
	show,
	setSelected,
	selected,
	backgroundHover,
	textHover,
	onPress,
}: any) => {
	return (
		<View style={{ padding: 5, width: '100%' }}>
			{data.map((item: any, index: any) => {
				return (
					<FilterText
						key={index.toString()}
						style={{
							padding: 5,
						}}
						selected={selected === item}
						onClick={() => {
							setSelected(item)
							setShow(!show)

							if (item === type) {
								onPress()
							}
						}}
						backgroundHover={backgroundHover}
						textHover={textHover}>
						{item}
					</FilterText>
				)
			})}
		</View>
	)
}

export default React.memo(FilterDropdown)
