import styled from 'styled-components'

export const AssetTotalValueLeftSection = styled.div`
	width: 50%;
	height: 100%;
	padding-left: 40px;
	margin-top: 30px;
	@media only screen and (max-width: 600px) {
		width: 100%;
		padding-left: 10px;
		height: 60%;
	}
`
export const AssetTotalValueRightSection = styled.div`
	width: 50%;
	height: 100%;
	padding-right: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media only screen and (max-width: 600px) {
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		height: 100%;
		justify-content: flex-start;
	}
`
