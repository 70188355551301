import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import ProofReserveCoinItems from './ProofReserveCoinItems'
import avatar from 'assets/images/avatar.png'
const ProofReserveCoin = () => {
	const { t } = useTranslation()

	const data = [
		{
			main_symbol: 'USDT',
			symbol_name: 'Theather USD',
			image: avatar,
			platform_assets: 100000000,
			user_assets: 1572.61,
			reserve_ratio: 100,
		},
	]
	return (
		<View
			style={{
				width: '100%',
				background: 'rgba(255,255,255,0.1)',
				justifyContent: 'space-between',
				marginBlock: 20,
				paddingBlock: 10,
				borderRadius: 10,
			}}>
			<View style={{ width: '100%', height: 50, flexDirection: 'row' }}>
				<View style={{ width: '25%' }}>
					<Text style={{ fontSize: 13 }}>{t('coin')}</Text>
				</View>
				<View style={{ width: '25%' }}>
					<Text style={{ fontSize: 13 }}>{t('platformAssets')}</Text>
				</View>
				<View style={{ width: '25%' }}>
					<Text style={{ fontSize: 13 }}>{t('userAssets')}</Text>
				</View>
				<View style={{ width: '25%' }}>
					<Text style={{ fontSize: 13 }}>{t('reserveRatio')}</Text>
				</View>
			</View>
			<View
				style={{
					width: '100%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				{data?.map((item, index) => (
					<ProofReserveCoinItems item={item} />
				))}
			</View>
		</View>
	)
}

export default ProofReserveCoin
