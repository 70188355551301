import styled from 'styled-components'

export const SocialButtonSection = styled.div`
	width: 100%;
	height: 100%;
	color: #ccc;
	font-size: 1.3rem;
	display: flex;
	flex-direction: column;
`
export const WebsiteLogoSection = styled.div`
	margin-bottom: 20px;
`
export const Buttons = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-self: flex-start;
	justify-content: flex-start;
	align-items: flex-start;
`
