import styled from 'styled-components'

export const OrderContainerTableSection = styled.div`
	display: flex;
	flex-direction: column;
`
export const OrderContainerHeaderSection = styled.div`
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 100%;
`
export const OrderContainerListSection = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
`
export const OrderHeaderItems = styled.div`
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${p => p.theme.COLORS.textw};
	border-bottom: 1px solid ${p => p.theme.COLORS.borderColor};
	height: 100%;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
	}
	@media only screen and (max-width: 900px) {
		font-size: 9px;
	}
	@media only screen and (max-width: 600px) {
		font-size: 9px;
	}
`

export const OrderListItems = styled.div`
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${p => p.theme.COLORS.textw};
	height: 30px;
	/* border-bottom: 1px solid ${p => p.theme.COLORS.borderColor}; */
`

export const OrderContainerFooterSection = styled.div`
	height: 40px;
	position: absolute;
	bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
