import React, { useEffect, useState } from 'react'
import { useTradeSocket } from '../../hooks/trade/tradeSocket'
import useTradeStore from '../../hooks/trade/tradeStore'
import { SOCKET_URL } from '../../constants/APIKEYS'

const CRenderEmpty = ({ id }) => {
	const [check, setCheck] = useState(false)
	const { ep_id } = useTradeStore()
	const { joinGroup, leaveGroup, mutate } = useTradeSocket(SOCKET_URL)

	useEffect(() => {
		if (id) {
			console.log('CRENDEREMPTY join user', id)
			joinGroup('user', id)
		} else {
			console.log('join all')
			joinGroup('all')
		}

		return () => {
			console.log('leave all')
			if (id) {
				if (id != ep_id) {
					leaveGroup('user', id)
				}
			} else {
				leaveGroup('all')
			}
		}
	}, [])

	return null
}
export default CRenderEmpty
