import React from 'react'
import CButton from '../Common/CButton'
import { COLORS } from 'app/constants/STYLES'
import { AuthButtonSection } from './styles'
import { Text } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const AuthButton = () => {
	const { t } = useTranslation()
	const nav = useHistory()
	return (
		<AuthButtonSection>
			<CButton
				title={t('login')}
				onClick={() => {
					nav.push('/login')
				}}
				style={{
					padding: 7,
					fontWeight: 'bold',
					color: COLORS.primary,
				}}
			/>
			<Text style={{ color: COLORS.grayWhite }}>Or</Text>
			<CButton
				title={t('signUp')}
				onClick={() => {
					nav.push('/signup')
				}}
				style={{
					padding: 7,
					fontWeight: 'bold',
					color: COLORS.primary,
				}}
			/>
		</AuthButtonSection>
	)
}

export default AuthButton
