import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconInfoCircle, IconShare } from '@tabler/icons'
import { ThemeContext } from '../../../theme/ThemeProvider'
import { MView } from '../../../styles/mobilePageStyles'
import { CoinLogo, CoinRate } from '../../components'
import { Text } from '../../../styles/reactnative'
import { COLORS, FONTS } from '../../constants'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import { Button, Checkbox, Divider, Modal } from 'antd'
import { TraderDetailBackground } from '../EventSection/TraderDetail/styles'
import { IMAGES } from 'app/constants'
import { useIndexPrice } from '../../hooks/trade/indexprice'
import BigNumber from 'bignumber.js'
import EntryTime from '../../components/EntryTime'
import { useFetcher, useFetcherPublic } from '../../hooks/fetcher'
import QRCode from 'react-qr-code'
import SocialBtns from '../../components/SubFooter/SocialButtons/SocialBtns'
import html2canvas from 'html2canvas'

function useImageDownload() {
	const captureArea = useRef<HTMLDivElement>(null)

	/* 다운로드 버튼 함수 */
	const onImageDownload = async () => {
		if (captureArea.current) {
			const canvas = await html2canvas(captureArea.current, { scale: 4 })
			const element = document.createElement('a')
			element.href = canvas.toDataURL('image/png')
			element.download = 'THEBIT_SHARE.png'
			element.click()
		}
	}

	// 이미지 blob 형태로 가져오기, 이부분은 아직 미완성, 필요할때 완성할 예정
	const getImageBlob = async () => {
		if (captureArea.current) {
			const canvas = await html2canvas(captureArea.current)
			canvas.toBlob(blob => {
				if (!blob) return
				const url = URL.createObjectURL(blob)
				return url
			})
		}
	}

	return {
		captureArea,
		onImageDownload,
		getImageBlob,
	}
}

const ShareModal = ({ shareData, setShareData, type = 1 }) => {
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { EPs } = useExchangeStore()
	const [lastprice, setLastPrice] = React.useState(0)

	const [showpnl, setShowpnl] = React.useState(true)
	const [showprice, setShowprice] = React.useState(true)
	const [showreferral, setShowreferral] = React.useState(true)
	const { t } = useTranslation()

	const { captureArea, onImageDownload, getImageBlob } = useImageDownload()

	const { getPrice } = useIndexPrice(shareData?.exchange_pairs_id)
	const { data } = useFetcher('/referral/code', false)
	const currencyList = useFetcherPublic('/system/currency')

	const equivusd = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'KRW'
	)

	useEffect(() => {
		if (!shareData) return
		if (type == 1) {
			const price = getPrice(shareData?.exchange_pairs_id)
			setLastPrice(price)
			if (!price) {
				setTimeout(() => {
					setLastPrice(getPrice(shareData?.exchange_pairs_id))
				}, 1000)
			}
		} else {
			setLastPrice(shareData?.pos_price)
		}
		setShowpnl(true)
		setShowprice(true)
		setShowreferral(true)
	}, [shareData])

	if (!shareData) {
		return null
	}
	const referralCode = data?.result?.result?.code || ''

	const EP = EPs.find(ep => ep.id === shareData?.exchange_pairs_id)

	let pnl = 0
	let pnlRate = 0

	if (type == 1) {
		if (shareData?.long_short == 'S') {
			pnl = BigNumber(BigNumber(shareData?.price).minus(lastprice))
				.multipliedBy(shareData?.amount)
				.multipliedBy(shareData?.leverage)
				.toNumber()
		} else {
			pnl = BigNumber(BigNumber(lastprice).minus(shareData?.price))
				.multipliedBy(shareData?.amount)
				.multipliedBy(shareData?.leverage)
				.toNumber()
		}

		console.log('pnl', pnl)

		pnlRate = BigNumber(pnl)
			.dividedBy(shareData?.margin)
			.multipliedBy(100)
			.toNumber()
	} else {
		pnl = shareData?.pnl_price
		pnlRate = new BigNumber(shareData?.pnl_price)
			.dividedBy(shareData?.margin)
			.multipliedBy(100)
			.toNumber()
	}

	return (
		<Modal
			title={t('realizedProfitShare')}
			open={shareData ? true : false}
			footer={<></>}
			onCancel={() => {
				setShareData(null)
			}}>
			<MView
				style={{
					justifyContent: 'center',
					width: '100%',
					borderRadius: 20,
					alignContent: 'center',
					justifyItems: 'center',
					alignItems: 'center',
				}}>
				<MView
					ref={captureArea}
					style={{
						flexDirection: 'column',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						padding: '20px',
						background: `url(${IMAGES.traderBg}) no-repeat center center / cover black`,
						minHeight: 200,
						width: '350px',
					}}>
					<MView
						style={{
							flexDirection: 'row',
							width: '100%',
							marginTop: 10,
						}}>
						<img
							alt="Thebit"
							src={'/images/darkLogo.png'}
							style={{ height: 20 }}
						/>
					</MView>

					<MView
						style={{
							flexDirection: 'row',
							width: '100%',
							alignItems: 'center',
							marginTop: 10,
						}}>
						<Text
							style={{
								...FONTS.h5,
								fontWeight: '500',
								color: 'white',
								marginRight: 5,
							}}>
							{EP?.pairs}
						</Text>
						<Text
							style={{
								...FONTS.h5,
								fontWeight: '500',
								color:
									type == 1
										? shareData?.long_short == 'S'
											? COLORS.lightRed
											: COLORS.lightGreen
										: shareData?.long_short == 'S'
										? COLORS.lightGreen
										: COLORS.lightRed,
								marginRight: 5,
							}}>
							{type == 1
								? shareData?.long_short == 'S'
									? t('short')
									: t('long')
								: shareData?.long_short == 'S'
								? t('long')
								: t('short')}
						</Text>
						<Text
							style={{
								color: 'white',
							}}>
							{shareData?.leverage}X
						</Text>
					</MView>

					<MView
						style={{
							flexDirection: 'row',
							width: '100%',
							marginTop: 10,
						}}>
						<Text
							size={45}
							style={{
								color:
									pnl > 0
										? COLORS.lightGreen
										: pnl < 0
										? COLORS.lightRed
										: 'white',
							}}>
							{pnl > 0 ? '+' : ''}
							{pnlRate.toFixed(2)}%
						</Text>
					</MView>
					{showpnl && (
						<MView
							style={{
								flexDirection: 'column',
								width: '100%',
								marginTop: -10,
							}}>
							<Text
								size={26}
								style={{
									color:
										pnl > 0
											? COLORS.lightGreen
											: pnl < 0
											? COLORS.lightRed
											: 'white',
								}}>
								{pnl > 0 ? '+' : ''}
								{pnl.toFixed(3)} USDT
							</Text>
							<Text style={{ color: '#aaa', marginLeft: 10 }}>
								≒{' '}
								{new BigNumber(pnl)
									.multipliedBy(equivusd?.price)
									.toFormat(0)}
								{' 원'}
							</Text>
						</MView>
					)}

					<MView
						style={{
							flexDirection: 'row',
							marginTop: 40,
							justifyContent: 'flex-start',
						}}>
						<Text
							style={{
								minWidth: 120,
								justifyContent: 'flex-start',
								color: 'white',
							}}>
							{t(type == 1 ? 'markPrice' : 'filledPrice')}
						</Text>
						<MView style={{ flexDirection: 'column' }}>
							<Text style={{ marginLeft: 0, color: 'white' }}>
								$
								{new BigNumber(lastprice).toFormat(EP?.dp || 3)}
							</Text>

							<Text style={{ color: '#aaa', marginLeft: 10 }}>
								≒{' '}
								{new BigNumber(lastprice)
									.multipliedBy(equivusd?.price)
									.toFormat(0)}
								{' 원'}
							</Text>
						</MView>
					</MView>
					{showprice && (
						<>
							<MView
								style={{ flexDirection: 'row', marginTop: 10 }}>
								<Text
									style={{
										minWidth: 120,
										justifyContent: 'flex-start',
										color: 'white',
									}}>
									{t(type == 1 ? 'entryPrice' : 'exitPrice')}
								</Text>

								<MView style={{ flexDirection: 'column' }}>
									<Text
										style={{
											marginLeft: 0,
											color: 'white',
										}}>
										$
										{new BigNumber(
											type == 1
												? shareData?.price
												: shareData?.trade_price
										).toFormat(EP?.dp || 3)}
									</Text>
									<Text
										style={{
											color: '#aaa',
											marginLeft: 10,
										}}>
										≒{' '}
										{new BigNumber(
											type == 1
												? shareData?.price
												: shareData?.trade_price
										)
											.multipliedBy(equivusd?.price)
											.toFormat(0)}
										{' 원'}
									</Text>
								</MView>
							</MView>
							<MView
								style={{ flexDirection: 'row', marginTop: 10 }}>
								<Text
									style={{
										minWidth: 120,
										justifyContent: 'flex-start',
										color: 'white',
									}}>
									{t(type == 1 ? 'entryTime' : 'endTime')}
								</Text>
								<EntryTime
									time={shareData?.created_at}
									style={{
										color: 'white',
									}}
								/>
							</MView>
						</>
					)}
					{showreferral && (
						<MView>
							<Text
								style={{
									color: THEMECOLORS.textsilver,
									marginTop: 20,
								}}>
								{t('referralCode')}
							</Text>

							<QRCode
								size={256}
								style={{
									height: 'auto',
									maxWidth: 80,
									width: 80,
								}}
								value={`http://bitkorea.net/signup?ref=${referralCode}`}
								viewBox={`0 0 80 80`}
							/>
							<Text
								style={{
									color: 'white',
									marginTop: 5,
								}}>
								{referralCode}
							</Text>
						</MView>
					)}
				</MView>

				<MView style={{ flexDirection: 'row', marginTop: 10 }}>
					<Text
						style={{
							justifyContent: 'flex-start',
							color: 'black',
						}}>
						<IconInfoCircle size={15} style={{ marginRight: 5 }} />
						{t('sharedesc')}
					</Text>
				</MView>
				<MView style={{ flexDirection: 'row' }}>
					<Checkbox
						checked={showprice}
						onChange={e => setShowprice(e.target.checked)}>
						{t('price')}
					</Checkbox>
					<Checkbox
						checked={showpnl}
						onChange={e => setShowpnl(e.target.checked)}>
						{t('pnl')}
					</Checkbox>
					<Checkbox
						checked={showreferral}
						onChange={e => setShowreferral(e.target.checked)}>
						{t('referralCode')}
					</Checkbox>
				</MView>

				<Divider />
				<MView>
					<Button
						style={{ width: '100%' }}
						onClick={() => {
							onImageDownload()
						}}>
						{t('imagedownload')}
					</Button>
				</MView>
			</MView>
		</Modal>
	)
}
export default React.memo(ShareModal)
