import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CButton } from 'app/components/Common/'
import { COLORS, FONTS } from 'app/constants/STYLES'
import { ThemeContext } from 'theme/ThemeProvider'
import { FooterItemsList } from './styles'

interface Iprops {
	text?: any
	subText?: any
	goTo?: string
}
const FooterListItems = ({ text, subText, goTo }: Iprops) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const navigate = useHistory()
	const navigateTo = (url: any) => {
		navigate.push(url)
	}

	return (
		<FooterItemsList>
			<CButton
				title={`${t(text)} ${t(subText)}`}
				onClick={() => navigateTo(goTo)}
				style={{
					...FONTS.h8,
					color: textw,
					lineHeight: 1.5,
				}}
				textHover={COLORS.BTN_HOVER}
			/>
		</FooterItemsList>
	)
}

export default React.memo(FooterListItems)
