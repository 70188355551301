import React, { useState } from 'react'
import { CModal } from 'app/components/Common'
import TraderDetails from '../TraderDetails'
import { HighestProfitListSection, ProfitItemSection } from './styles'
import CopyTradeModal from '../../CopyTradeModal'

const HighestProfitLists = () => {
	const [selectedData, setSelectedData] = useState<any>(null)
	const [showModal, setShowModal] = useState(false)
	const closeModal = () => setShowModal(false)
	const openModal = item => {
		setShowModal(true)
		setSelectedData(item)
	}

	const data = [0, 1, 2, 3, 4, 5, 6, 7]

	return (
		<HighestProfitListSection>
			<CModal visible={showModal} onClose={closeModal}>
				<CopyTradeModal
					selectedData={selectedData}
					setShowModal={setShowModal}
				/>
			</CModal>
			{data.map((item, index) => (
				<ProfitItemSection key={index.toString()}>
					<TraderDetails
						item={item}
						index={index}
						openModal={openModal}
					/>
				</ProfitItemSection>
			))}
		</HighestProfitListSection>
	)
}

export default React.memo(HighestProfitLists)
