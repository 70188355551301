import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useChangeLanguage = (language: string) => {
	const [lang, setLang] = useState('en')
	const { i18n } = useTranslation()
	useEffect(() => {
		setLang(language)
		i18n.changeLanguage(language)
	}, [i18n, language])
	return lang
}
