import { COLORS } from 'app/constants/STYLES'
import styled from 'styled-components'

export const TradeDetailListSection = styled.div`
	height: 100%;
	min-width: 900px;
	overflow-y: hidden;
	overflow-x: hidden;
	padding-inline: 10px;
`
export const TradeDetailListHeaderSection = styled.div`
	height: 10%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: center;
	color: ${COLORS.lightGray5};
`

export const TradeDetailListItemSection = styled.div`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	align-self: center;
	display: flex;
	flex-direction: row;

	margin-top: 10px;
`

export const TradeText = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: flex-start;
	width: 100px;
	font-size: 14px;
	color: ${props => props.theme.COLORS.textw};
	@media (max-width: 900px) {
		font-size: 12px;
	}
	@media (max-width: 600px) {
		font-size: 10px;
	}
`
