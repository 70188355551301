import React, { useState } from 'react'
import { useWindowDimensions } from 'app/hooks'
import { OrderModalWrapper } from './styles'
import OrderModalHeader from './OrderModalHeader'
import OrderModalFooter from './OrderModalFooter'
import OrderModalBody from './OrderModalBody'
import useTradeStore from 'app/hooks/trade/tradeStore'

const OrderPreferenceModal = ({ setOpen }) => {
	// UI
	const { height } = useWindowDimensions()

	// UI
	// API
	const { data: store, mutate: mutateStore } = useTradeStore()
	const usdt = store?.coin_type === 'USDT'
	const orderQty = store?.main_sub == 'M' ? true : false
	const setOrderQty = () => {
		mutateStore({
			...store,
			main_sub: orderQty ? 'M' : 'S',
		})
	}
	const [orderByValue, setOrderByValue] = useState(usdt ? true : false)

	return (
		<OrderModalWrapper
			style={{
				width: '100%',
				height: height > 600 ? 400 : 350,
			}}>
			<OrderModalHeader
				closeModal={() => {
					setOpen && setOpen()
				}}
			/>
			<OrderModalBody
				orderQty={orderQty}
				setOrderQty={setOrderQty}
				orderByValue={orderByValue}
				setOrderByValue={setOrderByValue}
				symbol={store?.symbol}
			/>
			<OrderModalFooter
				closeModal={() => {
					setOpen && setOpen()
				}}
				action={() => {
					mutateStore({
						...store,
						coin_type: orderQty ? store?.symbol : 'USDT',
					})
				}}
			/>
		</OrderModalWrapper>
	)
}

export default React.memo(OrderPreferenceModal)
