import React from 'react'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { QuantityCosts } from '../styles'

const QuantityCostFormList = ({ title, bids, asks, type }) => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				marginBlock: 3,
			}}>
			<Text style={{ color: COLORS.lightGray5, fontSize: 12 }}>
				{title && t(title)}
			</Text>
			<QuantityCosts>
				<Text style={{ color: COLORS.lightGreen, fontSize: 12 }}>
					{bids}
				</Text>
				<Text style={{ fontSize: 12 }}>/</Text>
				<Text style={{ color: COLORS.lightRed, fontSize: 12 }}>
					{asks}
				</Text>
			</QuantityCosts>
			<Text style={{ fontSize: 12 }}>{type}</Text>
		</View>
	)
}

export default QuantityCostFormList
