import React, { useEffect, useState } from 'react'
import KrwPrice from '../../components/KrwPrice'
import EntryTime from '../../components/EntryTime'
import BankInfo from '../../components/BankInfo'
import { Button, DatePicker, Radio, Space, Tag } from 'antd'
import TableAPI from '../../components/TableAPI'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { COLORS } from '../../constants'

import dayjs from 'dayjs'
import moment from 'moment'

const CalcList = () => {
	const now = moment()

	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const [startDate, setStartDate] = useState<any>(now.format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState<any>(now.format('YYYY-MM-DD'))

	const [isAll, setIsAll] = useState(false)
	const order = ' daydate asc, id asc '

	//startdate, enddate, excepttype, userid, isall, type, order, searchWord, maxData, page
	console.log(startDate, endDate)

	const url = `/trade/dailyresult?t=
		${(startDate && `&startdate=${startDate}`) || ''}
		${(endDate && `&enddate=${endDate}`) || ''}
		${(isAll && `&isall=${isAll}`) || ''}
		${(order && `&order=${order}`) || ''}
	`
	return (
		<TableAPI
			title={
				<Space.Compact>
					<DatePicker
						placeholder="시작일"
						allowClear={true}
						onChange={e => {
							const temp: any = e
							setStartDate(e ? e?.format('YYYY-MM-DD') : null)
						}}
						defaultValue={
							startDate
								? dayjs(startDate, 'YYYY-MM-DD')
								: undefined
						}
					/>
					<DatePicker
						placeholder="종료일"
						allowClear={true}
						onChange={e => {
							const temp: any = e
							setEndDate(e ? e?.format('YYYY-MM-DD') : null)
						}}
						defaultValue={
							endDate ? dayjs(endDate, 'YYYY-MM-DD') : undefined
						}
					/>
					<Button
						onClick={() => {
							setStartDate('')
							setEndDate('')
						}}>
						전체
					</Button>
				</Space.Compact>
			}
			defaultMaxData={50}
			stylewidth={'100%'}
			height={650}
			width={500}
			columns={() => [
				{
					title: '날짜',
					dataIndex: 'daydate',
					key: 'daydate',
					align: 'center',
					width: 80,
					render: (text, data) => {
						return (
							<EntryTime
								time={text}
								format={'YYYY-MM-DD'}
								style={{ color: 'black' }}
							/>
						)
					},
				},
				{
					title: '입출금',
					align: 'center',
					children: [
						{
							title: '입금총액',
							dataIndex: 'total_deposit_amount',
							key: 'total_deposit_amount',
							align: 'center',
							width: 150,
							render: (text, data) => {
								return <KrwPrice price={Math.abs(text)} equiv={true}/>
							},
						},
						{
							title: '출금총액',
							dataIndex: 'total_withdraw_amount',
							key: 'total_withdraw_amount',
							align: 'center',
							width: 150,
							render: (text, data) => {
								return <KrwPrice price={-Math.abs(text)} equiv={true}/>
							},
						},
					],
				},
				{
					title: '손익현황',
					align: 'center',
					children: [
						{
							title: '총매매손익',
							dataIndex: 'total_trade_price',
							key: 'total_trade_price',
							align: 'center',
							width: 150,
							rowScope: 'row',
							render: (text, data) => {
								return <KrwPrice price={text} dp={0} equiv={true}/>
							},
						},
						{
							title: '수수료',
							dataIndex: 'total_fee_price',
							key: 'total_fee_price',
							align: 'center',
							width: 150,
							render: (text, data) => {
								return (
									<KrwPrice price={-Math.abs(text)} dp={0} equiv={true}/>
								)
							},
						},
						{
							title: '실현손익',
							dataIndex: 'total_pnl_price',
							key: 'total_pnl_price',
							align: 'center',
							width: 150,
							render: (text, data) => {
								return <KrwPrice price={text} equiv={true}/>
							},
						},
					],
				},
				{
					title: '마지막잔액',
					align: 'center',
					children: [
						{
							title: '예수금',
							dataIndex: 'last_balance',
							key: 'last_balance',
							align: 'center',
							width: 150,
							render: (text, data) => {
								return (
									<KrwPrice
										price={text}
										dp={0}
										color={false}
										theme={false}
										equiv={true}
									/>
								)
							},
						},
					],
				},
			]}
			apikey={url + '&t='}
		/>
	)
}

export default CalcList
