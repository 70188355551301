import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	name?: string
	profit?: string
	roi?: string
	profitColor?: string
	roiColor?: string
}
const ProfitDetailBodyList = ({
	name,
	profit,
	roi,
	profitColor,
	roiColor,
}: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				marginBottom: 15,
			}}>
			<Text size={16} style={{ lineHeight: 2 }}>
				{name}
			</Text>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<View style={{ alignItems: 'flex-start' }}>
					<Text size={16} style={{ marginBottom: 3 }}>
						{t('cumulativeProfit')}
					</Text>
					<Text
						size={16}
						style={{
							color: profitColor ? profitColor : COLORS.black,
							fontWeight: '600',
						}}>
						{profit}
					</Text>
				</View>
				<View style={{ alignItems: 'flex-end' }}>
					<Text size={16} style={{ marginBottom: 3 }}>
						{t('totalROI')}
					</Text>
					<Text
						size={16}
						style={{
							color: roiColor ? roiColor : textw,
							fontWeight: '600',
						}}>
						{roi}
					</Text>
				</View>
			</View>
		</View>
	)
}

export default ProfitDetailBodyList
