/**
 * Asynchronously loads the component for AssetPage
 */

import { lazyLoad } from 'utils/loadable'

export const AssetPage = lazyLoad(
	() => import('./index'),
	module => module.AssetPage
)
