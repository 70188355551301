import React from 'react'
// import SocialBtns from './SocialBtns'
import { SocialButtonSection } from './styles'
import WebsiteLogo from './WebsiteLogo'

const SocialButtons = () => {
	return (
		<SocialButtonSection>
			<WebsiteLogo />
		</SocialButtonSection>
	)
}

export default React.memo(SocialButtons)
