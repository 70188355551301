import React, { useState } from 'react'
import { ScrollableTabs } from 'app/mobile/components/'
import { MAnnouncementSection } from './styles'
import MAllAnnouncements from './AllAnnouncements'
import MListingAnnouncements from './ListingAnnouncements'
import MActivitiesAnnouncements from './ActivitiesAnnouncements'
import MProductAnnouncements from './ProductAnnouncements'
import MAnnouncementDetail from './AnnouncementDetail'
import { MView } from 'styles/mobilePageStyles'

const MAnnouncements = () => {
	const [tab, setTab] = useState(0)
	const [show, setShow] = useState({
		show: false,
	})
	const tabs = [
		{
			title: 'all',
			tab: 0,
		},
		{
			title: 'newListings',
			tab: 1,
		},
		{
			title: 'latestActivities',
			tab: 2,
		},
		{
			title: 'productUpdates',
			tab: 3,
		},
	]
	return (
		<MAnnouncementSection>
			{!show?.show && (
				<MView style={{ width: '100%', height: '100%' }}>
					<ScrollableTabs tab={tab} setTab={setTab} tabs={tabs} />
					{tab === 0 ? (
						<MAllAnnouncements show={show} setShow={setShow} />
					) : null}
					{tab === 1 ? (
						<MListingAnnouncements show={show} setShow={setShow} />
					) : null}
					{tab === 2 ? (
						<MActivitiesAnnouncements
							show={show}
							setShow={setShow}
						/>
					) : null}
					{tab === 3 ? (
						<MProductAnnouncements show={show} setShow={setShow} />
					) : null}
				</MView>
			)}

			{show?.show ? (
				<MAnnouncementDetail show={show} setShow={setShow} />
			) : null}
		</MAnnouncementSection>
	)
}

export default MAnnouncements
