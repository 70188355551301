import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'
import { HiddenEye } from 'app/components'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ThemeContext } from 'theme/ThemeProvider'

const AssetSpotHeaderTop = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	const btnData = [
		{ title: 'transfer', goTo: '' },
		{ title: 'deposit', goTo: '' },
		{ title: 'withdraw', goTo: '' },
	]

	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				height: '30%',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}>
			<View
				style={{
					alignItems: 'center',
					flexDirection: 'row',
				}}>
				<Text
					size={width < 600 ? (width < 400 ? 14 : 16) : 22}
					style={{
						marginRight: 10,
						fontWeight: '500',
					}}>
					{t('usdcDerivatiesAccount')}
				</Text>
				<HiddenEye style={{ color: textw }} />
			</View>
			<View
				style={{
					alignItems: 'center',
					flexDirection: 'row',
					flexWrap: 'wrap',
				}}>
				{btnData?.map((item: any, index: any) => (
					<CButton
						key={index.toString()}
						title={t(item.title)}
						onClick={() => {
							if (item?.title === 'transfer') {
								muatateModalStore({
									...modalStore,
									transferModal: true,
								})
							} else if (item?.title === 'deposit') {
								muatateModalStore({
									...modalStore,
									depositModal: true,
								})
							} else if (item?.title === 'withdraw') {
								muatateModalStore({
									...modalStore,
									withdrawModal: true,
								})
							}
						}}
						style={{
							fontSize: width < 600 ? 10 : 14,
							justifyContent: 'center',
							paddingBlock: width > 1100 ? 10 : 5,
							paddingInline: width > 1100 ? 20 : 10,
							marginRight: 10,
							background:
								item?.title === 'transfer'
									? COLORS.white
									: COLORS.secondary,
							color:
								item?.title === 'transfer'
									? COLORS.secondary
									: COLORS.white,
						}}
					/>
				))}
			</View>
		</View>
	)
}

export default React.memo(AssetSpotHeaderTop)
