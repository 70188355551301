import React from 'react'
import CImage from 'app/components/Common/CImage'
import { CSSProperties } from 'styled-components'
import { Checkbox, Col, Divider, Modal, Row, message } from 'antd'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import useTradeStore from '../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import API from '../../../utils/api'
import { useToken } from '../../hooks'
import { toast } from 'react-toastify'
import { useOrderBook } from '../../hooks/trade/orderBook'
import { useFetcher } from '../../hooks/fetcher'
import { useTradePosition } from '../../hooks/trade/tradePosition'
import { useBalance } from '../../hooks/user/mybalance'
import { useTradeSetting } from '../../hooks/trade/tradeSetting'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { useIndexPrice } from '../../hooks/trade/indexprice'

interface IProps {
	open: boolean
	setOpen: (open: boolean) => void
	long_short: string
}
const CheckPositionModal = ({ open, setOpen, long_short }: IProps) => {
	const { t } = useTranslation()
	const { data: token } = useToken()
	const { data: tradeStore } = useTradeStore()

	const { getPrice } = useIndexPrice(tradeStore?.ep_id)
	const { data: tradeModalStore, mutate: mutateTradeModalStore } =
		useTradeModalStore()
	const { data: tradeSetting } = useTradeSetting(tradeStore?.ep_id)
	const { mutate: mutateOrderBook } = useOrderBook(tradeStore?.ep_id)

	const { mutate: mutatePosition } = useTradePosition()
	const main = tradeStore?.pairs?.split('/')[0]
	const currency = tradeStore?.pairs?.split('/')[1]

	const sendOrder = async () => {
		if (!tradeStore?.order_amount)
			return message.error(t('pleaseFillAllFields') + '')

		if (tradeStore?.tpsl) {
			if (isNaN(tradeStore?.tp) || isNaN(tradeStore?.sl)) {
				return message.error('CHECK TP/SL')
			}
		}
		const orderParams = {
			ep_id: tradeStore?.ep_id,
			main_sub: tradeStore?.main_sub || 'M',
			long_short: long_short,
			cross_iso: tradeSetting?.cross_iso,
			leverage: tradeSetting?.leverage,
			reduce_position: tradeStore.reduce_only ? 1 : 0,
			post_only: tradeStore?.post_only ? 1 : 0,
			limit_market:
				tradeStore?.limit_market?.toUpperCase() == 'L' ||
				tradeStore?.limit_market?.toUpperCase() == 'LIMIT'
					? 'L'
					: 'M',
			price: tradeStore?.order_price,
			amount: new BigNumber(tradeStore?.order_amount)
				.dividedBy(tradeSetting?.leverage)
				.toNumber(),
			tpsl: tradeStore?.tpsl,
			tp: tradeStore?.tp,
			sl: tradeStore?.sl,
			tptype: tradeStore?.tptype,
			sltype: tradeStore?.sltype,
		}

		const result = await API.post('/trade/order', '' + token, orderParams)

		//mutateStore({ ...tradeStore, order_amount: 0 })

		if (result.result.success) {
			mutateOrderBook()
			mutatePosition()

			setTimeout(() => {
				mutateOrderBook()
				mutatePosition(null)
			}, 500)

			toast(t('ordersuccess') + '', {
				type: 'success',
				delay: 100,
			})
		} else {
			if (result?.result?.message == 'ERROR_MAXAMOUNT') {
				toast(
					t('ERROR_MAXAMOUNT') +
						' / MAX AMOUNT : ' +
						BigNumber(result?.result?.max).toFormat(3),
					{
						type: 'error',
					}
				)
			} else {
				toast(t(result?.result?.message + '') + '', {
					type: 'error',
				})
			}
		}
	}

	let margin = new BigNumber(tradeStore?.order_amount)
		.multipliedBy(
			tradeStore?.limit_market == 'M'
				? getPrice(tradeStore?.ep_id)
				: tradeStore?.order_price
		)
		.dividedBy(tradeSetting?.leverage)
		.toFormat(4)

		if(tradeStore?.main_sub == 'S'){
			margin = new BigNumber(tradeStore?.order_amount)
			.dividedBy(tradeSetting?.leverage)
			.toFormat(3)
		
		}

	return (
		<Modal
			open={open}
			title={t('ordercheck') + ''}
			onCancel={() => {
				setOpen(false)
			}}
			onOk={() => {
				sendOrder()
				setOpen(false)
			}}
			cancelText={t('cancel')}
			okText={t('confirm')}>
			<Divider />
			<Row
				style={{ borderBottom: '1px solid #efefef', padding: '5px 0' }}>
				<Col span={12}>{t('pairs')}</Col>
				<Col span={12} style={{ fontWeight: 'bold' }}>
					{tradeStore?.pairs}
				</Col>
			</Row>
			<Row
				style={{ borderBottom: '1px solid #efefef', padding: '5px 0' }}>
				<Col span={12}>{t('price')}</Col>
				<Col span={12} style={{ fontWeight: 'bold' }}>
					{tradeStore?.limit_market == 'M'
						? t('market')
						: tradeStore?.order_price}
				</Col>
			</Row>
			<Row
				style={{ borderBottom: '1px solid #efefef', padding: '5px 0' }}>
				<Col span={12}>{t('filledType')}</Col>
				<Col span={12} style={{ fontWeight: 'bold' }}>
					{long_short == 'L' ? t('long') : t('short')}
				</Col>
			</Row>
			<Row
				style={{ borderBottom: '1px solid #efefef', padding: '5px 0' }}>
				<Col span={12}>{t('amount')}</Col>
				<Col span={12} style={{ fontWeight: 'bold' }}>
					{tradeStore?.order_amount}{' '}
					{tradeStore?.main_sub == 'S' ? currency : main}
				</Col>
			</Row>
			<Row
				style={{ borderBottom: '1px solid #efefef', padding: '5px 0' }}>
				<Col span={12}>{t('leverage')}</Col>
				<Col span={12} style={{ fontWeight: 'bold' }}>
					{t(tradeSetting?.cross_iso == 'C' ? 'cross' : 'iso')}{' '}
					{tradeSetting?.leverage}X
				</Col>
			</Row>
			<Row
				style={{ borderBottom: '1px solid #efefef', padding: '5px 0' }}>
				<Col span={12}>{t('margin')}</Col>
				<Col span={12} style={{ fontWeight: 'bold' }}>
					{margin} {currency}
				</Col>
			</Row>

			<Checkbox
				style={{ marginTop: 10 }}
				checked={tradeModalStore?.skip}
				onChange={e => {
					mutateTradeModalStore({
						...tradeModalStore,
						skip: e.target.checked,
					})
				}}>
				{t('notagain')}
			</Checkbox>
		</Modal>
	)
}

export default React.memo(CheckPositionModal)
