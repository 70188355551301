import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { DepositRightSectionWrapper } from './styles'
import { MView } from '../../../styles/mobilePageStyles'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { goTo } from '../../hooks/navigation'

const WithdrawRightSection = () => {
	const { textsilver, descBox } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const nav = useHistory()
	return (
		<DepositRightSectionWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					background: `${descBox}`,
					marginRight: 30,
					padding: 10,
					height: '95%',
					overflow: 'hidden',
				}}>
				<MView
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						alignContent: 'center',
						width: '100%',
					}}>
					<Text size={16}>{t('important')}</Text>
					<Button
						type="primary"
						style={{}}
						size="small"
						onClick={() => {
							goTo(nav, '/supports?tab=2')
						}}>
						{t('Manual')}
					</Button>
				</MView>
				<View style={{ paddingBlock: 8, paddingInline: 5 }}>
					<Text
						size={11}
						style={{ color: textsilver, lineHeight: 1 }}>
						{t('depositFirstDesc')}
					</Text>
					<Text
						size={12}
						style={{
							color: textsilver,
							marginTop: 15,
							lineHeight: 1.2,
						}}>
						{t('depositSecondDesc')}
					</Text>
					<Text
						size={12}
						style={{
							color: textsilver,
							marginTop: 15,
							lineHeight: 1.2,
						}}>
						{t('depositFourthDesc')}
					</Text>
				</View>
			</View>
		</DepositRightSectionWrapper>
	)
}

export default WithdrawRightSection
