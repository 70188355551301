// // import {RNS3} from 'react-native-aws3';
// import S3 from 'react-aws-s3'

// export const awsUploadImage = async (item, index, folder, userId) => {
// 	const config = {
// 		bucketName: S3_BUCKET,
// 		dirName: folder,
// 		region: REGION,
// 		accessKeyId: ACCESS_KEY,
// 		secretAccessKey: SECRET_ACCESS_KEY,
// 		// s3Url: 'http:/your-custom-s3-url.com/' /* optional */,
// 	}
// 	const fileName = `${userId}_${new Date().getTime()}_${
// 		Math.random() * 10000
// 	}_${index}.png`
// 	const ReactS3Client = new S3(config)
// 	try {
// 		const result = await ReactS3Client.uploadFile(item, fileName)
// 		return result.location
// 	} catch (error) {
// 		console.log(error, 'AWS UPLOAD IMAGE ERROR')
// 	}
// }

import S3 from 'react-s3'

const S3_BUCKET = 'coinsexchange'
const REGION = 'ap-northeast-2'
const ACCESS_KEY = 'AKIA5NMNQHKZVRK2NSHH'
const SECRET_ACCESS_KEY = '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'

const s3Config = {
	bucketName: S3_BUCKET,
	region: REGION,
	accessKeyId: ACCESS_KEY,
	secretAccessKey: SECRET_ACCESS_KEY,
}
export const awsUploadImage = async (item, index, folder, userId) => {
	const fileName = `${userId}_${new Date().getTime()}_${
		Math.random() * 10000
	}_${index}.png`

	try {
		const res = await S3.uploadFile(item.file, {
			...s3Config,
			fileName: fileName, // Add fileName to the S3 upload options
		})
		return res.location
	} catch (exception) {
		console.log(exception)
		/* handle the exception */
	}
}
