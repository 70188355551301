import React from 'react'
import { Text, View } from 'styles/reactnative'
import { HelpCenterListSection } from './styles'

const HelpCenterLists = ({ item, index, setReadDetail, setItemDetail }) => {
	return (
		<HelpCenterListSection
			onClick={() => {
				setReadDetail(true)
				setItemDetail(item)
			}}>
			<View style={{ width: 60 }}>
				<Text style={{ fontSize: 14 }}>{item?.id}</Text>
			</View>
			<View>
				<Text style={{ fontSize: 14 }}>{item?.title}</Text>
			</View>
		</HelpCenterListSection>
	)
}

export default HelpCenterLists
