import React from 'react'
import PerformanceDetailBody from './PerformanceDetailBody'
import PerformanceDetailHeader from './PerformanceDetailHeader'
import PerformanceDetailNavBar from './PerformanceDetailNavBar'
import { TraderDetailLeftSection } from '../styles'

const TraderPerformanceDetail = () => {
	return (
		<TraderDetailLeftSection>
			<PerformanceDetailHeader />
			<PerformanceDetailNavBar />
			<PerformanceDetailBody />
		</TraderDetailLeftSection>
	)
}

export default React.memo(TraderPerformanceDetail)
