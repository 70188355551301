import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import AboutServiceCards from './AboutServiceCards'
import AboutWelcome from './AboutWelcome'
import ExperienceCard from './ExperienceCard'
import { MobileAppViewSection } from './styles'
import MAboutFounder from './MAboutFounder'
import AboutJoinCrypto from './AboutJoinCrypto'
import MAboutService from './MAboutService'
import AboutValues from './AboutValues'

const MAbouts = () => {
	const { t } = useTranslation()
	return (
		<MView style={{ width: '100%' }}>
			<AboutWelcome />
			<MobileAppViewSection />
			<MView
				style={{
					width: '100%',
					alignItems: 'center',
					marginBlock: 50,
				}}>
				<MText style={{ fontSize: 24 }}>{t('ourMission')}</MText>
				<MView style={{ marginTop: 20, paddingInline: 20 }}>
					<MText
						style={{
							fontSize: 14,
							textAlign: 'center',
							lineHeight: 1.2,
						}}>
						{t('ourMissionContent')}
					</MText>
				</MView>
			</MView>
			<ExperienceCard />
			<MAboutService />
			<AboutServiceCards />
			{/* <MAboutFounder /> */}
			<AboutValues />
			<AboutJoinCrypto />
		</MView>
	)
}

export default MAbouts
