import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const MProofOfReservesTitle = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView style={{ width: '100%', alignItems: 'center', marginBlock: 30 }}>
			<MText style={{ color: textw, fontSize: 20 }}>
				{t('latestReservesRatio')}
			</MText>
		</MView>
	)
}

export default MProofOfReservesTitle
