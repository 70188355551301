import React from 'react'
import { CImage } from 'app/components/Common'
import { COLORS, IMAGES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { LeaderBoardMainViewSection } from './styles'
import { useWindowDimensions } from 'app/hooks'
import { useTranslation } from 'react-i18next'

const LeaderBoardMainView = () => {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	return (
		<LeaderBoardMainViewSection>
			<View
				style={{
					alignItems: width < 600 ? 'center' : 'flex-start',
					width: '100%',
					paddingBottom: width < 600 ? 0 : 70,
					paddingLeft: width < 600 ? 0 : 70,
				}}>
				<CImage
					src={IMAGES.leaderBoard}
					alt="logo"
					width={'73%'}
					height={48}
					resizeMode={'cover'}
					style={{ borderRadius: 3 }}
				/>

				<View style={{ width: '60%', marginTop: 20 }}>
					<Text
						size={17}
						style={{
							color: COLORS.lightGray,
							lineHeight: 1.5,
						}}>
						{t('leaderBoardTitle')}
					</Text>
				</View>
			</View>
			<View
				style={{
					alignItems: width < 600 ? 'center' : 'flex-start',
					width: '100%',
					paddingBottom: width < 600 ? 0 : 30,
				}}>
				<CImage
					src={IMAGES.crown}
					alt="logo"
					width={'65%'}
					resizeMode={'cover'}
					style={{ borderRadius: 3 }}
				/>
			</View>
		</LeaderBoardMainViewSection>
	)
}

export default LeaderBoardMainView
