import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const MUserFeedbackSelectSection = styled.div`
	.ant-select-selector {
		background-color: transparent !important;
		border: 1px solid ${p => p.theme.COLORS.borderColor} !important;
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-arrow {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: ${p => p.theme.COLORS.content};
	}
`

export const FeedbackCategorySection = styled.div`
	margin-top: 20px;
	width: 100%;
	height: 300px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	.ant-select-selector {
		background-color: transparent !important;
		border: 1px solid ${p => p.theme.COLORS.borderColor} !important;
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-arrow {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-input-data-count {
		color: ${p => p.theme.COLORS.textw};
	}
`

export const FeedbackImageSection = styled.div`
	height: 130;
	margin-top: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 3;
	.ant-input::placeholder {
		color: #000;
	}

	.ant-upload-wrapper
		.ant-upload-list.ant-upload-list-picture-card
		.ant-upload-list-item-error {
		border-color: skyblue;
	}
	.ant-upload-wrapper.ant-upload-picture-card-wrapper
		.ant-upload.ant-upload-select {
		width: 70 !important;
		height: 35px !important;
		margin-top: 10px;
		background-color: ${COLORS.secondary};
		color: ${COLORS.white};
	}

	.ant-upload-wrapper.ant-upload-picture-card-wrapper
		.ant-upload-list.ant-upload-list-picture-card
		.ant-upload-list-item-container {
		width: 55px !important;
		height: 55px !important;
	}
	.ant-image-preview-operations {
		background-color: rgba(0, 21, 42, 1);
	}
`
