import React, { useState } from 'react'
import { TraderDetailInfoRightSideSection } from './styles'
import TraderDetailInfoRightSideHeader from './TraderDetailInfoRightSideHeader'

import Statistics from './Statistics'
import StatisticsCopiedTrades from './StatisticsCopiedTrades'
import TradersFollowers from './TradersFollowers'

const tabStatisc = 'statistics'
const copiedTrades = 'statisticsCopiedTrade'
const followers = 'followerss'

const TraderDetailInfoRightSide = () => {
	const [tab, setTab] = useState(tabStatisc)

	const tabs = [tabStatisc, copiedTrades, followers]
	return (
		<TraderDetailInfoRightSideSection>
			<TraderDetailInfoRightSideHeader
				tab={tab}
				setTab={setTab}
				tabs={tabs}
			/>
			{tabStatisc === tab ? <Statistics /> : null}
			{copiedTrades === tab ? <StatisticsCopiedTrades /> : null}
			{followers === tab ? <TradersFollowers /> : null}
		</TraderDetailInfoRightSideSection>
	)
}

export default TraderDetailInfoRightSide
