import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'

export const HeaderSection = styled.div<{ isDarkMode?: any }>`
	height: 50px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-inline: 16px;
	font-size: 3vw;
	z-index: 1001;
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${props => props.theme.COLORS.background};
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`
export const MHeaderSearchSection = styled.div`
	display: flex;
	flex-direction: row;
`
export const HeaderInput = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	height: 34px;
	padding-inline: 10px;
	border-radius: 15px;
`
