import React from 'react'
import { View } from 'styles/reactnative'
import { FeeTableItems } from './styles'

const FeeTableList = () => {
	const feeTableList = [
		{
			level: '1',
			trade_volume: '500',
			and_or: 'or',
			bnb_balance: '0',
			usdt_maker: '0.04',
			usdt_taker: '0.03',
			usdt_maker_bnb: '0.07',
			usdt_taker_bnb: '0.02',
			busd_maker: '0.03',
			busd_taker: '0.04',
			busd_maker_off: '0.09',
			busd_taker_off: '0.08',
		},
		{
			level: '2',
			trade_volume: '5000000',
			and_or: 'or',
			bnb_balance: '0',
			usdt_maker: '0.04',
			usdt_taker: '0.03',
			usdt_maker_bnb: '0.07',
			usdt_taker_bnb: '0.02',
			busd_maker: '0.03',
			busd_taker: '0.04',
			busd_maker_off: '0.09',
			busd_taker_off: '0.08',
		},
		{
			level: '3',
			trade_volume: '5000000',
			and_or: 'or',
			bnb_balance: '0',
			usdt_maker: '0.04',
			usdt_taker: '0.03',
			usdt_maker_bnb: '0.07',
			usdt_taker_bnb: '0.02',
			busd_maker: '0.03',
			busd_taker: '0.04',
			busd_maker_off: '0.09',
			busd_taker_off: '0.08',
		},
	]
	return (
		<View
			style={{
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: 1500,
			}}>
			{feeTableList.map((item, i) => (
				<View
					style={{
						flexDirection: 'row',
						marginBottom: 20,
						width: '100%',
					}}
					key={i.toString()}>
					<FeeTableItems style={{ width: 150 }}>
						{item.level}
					</FeeTableItems>
					<FeeTableItems style={{ width: 400 }}>
						{'<'} {item.trade_volume} BUSD
					</FeeTableItems>
					<FeeTableItems style={{ width: 150 }}>
						{item.and_or}
					</FeeTableItems>
					<FeeTableItems style={{ width: 200 }}>
						≥ {item.bnb_balance} BNB
					</FeeTableItems>
					<FeeTableItems style={{ width: 300 }}>
						{item.usdt_taker}/{item.usdt_maker}
					</FeeTableItems>
					<FeeTableItems style={{ width: 400 }}>
						{item.usdt_taker_bnb}/{item.usdt_maker_bnb}
					</FeeTableItems>
					<FeeTableItems style={{ width: 300 }}>
						{item.busd_maker}/{item.busd_taker}
					</FeeTableItems>
					<FeeTableItems style={{ width: 400 }}>
						{item.busd_maker_off}/{item.busd_taker_off}
					</FeeTableItems>
				</View>
			))}
		</View>
	)
}
export default FeeTableList
