import React from 'react'
import { View } from 'styles/reactnative'
import announcement from 'assets/images/announcement.png'
import { CImage } from 'app/components/Common'
import Lottie from 'react-lottie'
import cryptoanimate from 'assets/animation/crypto.json'
const AnnouncementSectionImage = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: cryptoanimate,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}
	return (
		<View style={{ width: '40%' }}>
			{/* <Lottie options={defaultOptions} /> */}
			<CImage
				src={announcement}
				alt="announcement"
				width={'auto'}
				height={'auto'}
				resizeMode={'cover'}
				style={{}}
			/>
		</View>
	)
}

export default AnnouncementSectionImage
