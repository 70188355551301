import styled from 'styled-components'

export const MNoticeSection = styled.div`
	width: 100%;
	height: 120px;
	margin-block: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`
