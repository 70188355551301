import { useTimezone } from 'app/hooks/timezone'
import moment from 'moment'
import React from 'react'
import { Text } from 'styles/reactnative'

const EntryTime = ({
	time,
	format = 'YYYY-MM-DD HH:mm:ss',
	style = {
		fontSize: 14,
		letterSpacing: -1,
	},
	relative = false,
	relativeAdd = false,
	offset = 0,
}: any) => {

	const printtime = useTimezone(time, format, offset)
	if (!time) return <></>
	let displayTime

	if (relative) {
		const now = moment()
		const diffMonths = now.diff(time, 'months')
		if (diffMonths >= 12) {
			const diffYears = Math.floor(diffMonths / 12)
			const remainingMonths = diffMonths % 12
			displayTime = `${diffYears}년 ${remainingMonths}개월 전`
		} else if (diffMonths > 1) {
			displayTime = `${diffMonths}개월 전`
		} else {
			const diff = moment.duration(moment().diff(moment(time)))

			if (diff.asDays() > 1) {
				displayTime = `${Math.floor(diff.asDays())}일 전`
			} else if (diff.asHours() > 0) {
				displayTime = `${Math.floor(diff.asHours())}시간 전`
			} else if (diff.asMinutes() > 0) {
				displayTime = `${Math.floor(diff.asMinutes())}분 전`
			} else {
				displayTime = `${Math.floor(diff.asSeconds())}초 전`
			}
		}
	}

	return (
		<>
			{relativeAdd == true ? (
				<>
					<Text style={style}>{printtime}</Text>
					{relative && <Text style={style}>{displayTime}</Text>}
				</>
			) : (
				<>
					<Text style={style}>
						{relative ? displayTime : printtime}
					</Text>
				</>
			)}
		</>
	)
}

export default React.memo(EntryTime)
