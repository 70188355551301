import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import BigNumber from 'bignumber.js'

const AssetUSDTTableList = ({ data }) => {
	const { t } = useTranslation()
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	return (
		<View
			style={{
				width: '100%',
			}}>
			{data?.map((item: any, index: number) => (
				<View
					key={index.toString()}
					style={{
						width: '100%',
						height: 50,
						flexDirection: 'row',
					}}>
					<Text
						style={{
							fontSize: 12,
							width: `${100 / data?.length}%`,
						}}>
						{item?.coin}
					</Text>
					<Text
						style={{
							fontSize: 12,
							width: `${100 / data?.length}%`,
						}}>
						{BigNumber(item?.balance).toFormat(4)}
					</Text>
					<Text
						style={{
							fontSize: 12,
							width: `${100 / data?.length}%`,
						}}>
						{BigNumber(item?.availableBalance).toFormat(4)}
					</Text>
					<Text
						style={{
							fontSize: 12,
							width: `${100 / data?.length}%`,
						}}>
						{item?.inOrderWithdrawals}
					</Text>
					<View style={{ width: '100%', flexDirection: 'row' }}>
						<CButton
							title={t('deposit')}
							style={{ color: COLORS.secondary }}
							onClick={() => {
								muatateModalStore({
									...modalStore,
									depositModal: true,
								})
							}}
						/>
						{/* <CButton
							title={t('withdraw')}
							style={{ color: COLORS.secondary }}
						/> */}
						<CButton
							title={t('transfer')}
							style={{ color: COLORS.secondary }}
							onClick={() => {
								muatateModalStore({
									...modalStore,
									transferModal: true,
								})
							}}
						/>
						<CButton
							title={t('convert')}
							style={{ color: COLORS.secondary }}
							onClick={() => {
								muatateModalStore({
									...modalStore,
									withdrawModal: true,
								})
							}}
						/>
					</View>
				</View>
			))}
		</View>
	)
}

export default AssetUSDTTableList
