import React, { useContext } from 'react'
import { HomeEventSection, SectionWrap } from './styles'
// import HomeRightSides from './HomeRightSide'
// import HomeLeftSide from './HomeLeftSide'
import { ThemeContext } from 'theme/ThemeProvider'
import HomeLeftSide from './HomeLeftSide'
import HomeRightSide from './HomeRightSide'
import HomeSlider from './HomeSlider'

const HomeEvent = () => {
	const { isDarkMode } = useContext(ThemeContext)

	return (
		<HomeEventSection
			isDarkMode={isDarkMode}
			style={{ flexDirection: 'column' }}>
			<SectionWrap style={{ marginTop: 50, maxWidth: 1180, position:'relative' }}>
				<HomeLeftSide />
				<HomeRightSide />
			</SectionWrap>
		</HomeEventSection>
	)
}

export default HomeEvent
