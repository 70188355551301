import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import CurrentTradesHeader from './PastTradesHeader'
import CurrentTradesTable from './PastTradesTable'

const CurrentTrades = () => {
	const [selectCoin, setSelectCoin] = useState('All Contacts')
	const coinLists = ['All Contacts', 'BTCUSDT', 'ETHUSDT']

	return (
		<View style={{ width: '100%', height: 850, padding: 20 }}>
			<CurrentTradesHeader
				coinLists={coinLists}
				selectCoin={selectCoin}
				setSelectCoin={setSelectCoin}
			/>
			<CurrentTradesTable />
		</View>
	)
}

export default React.memo(CurrentTrades)
