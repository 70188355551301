import React, { useContext } from 'react'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	title?: string
}
const ProfileSecurityHeader = ({ title }: IProps) => {
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
				height: 77,
				alignItems: 'flex-start',
				paddingBlock: 24,
				paddingInline: 32,
				borderBottom: `1px solid ${borderColor}`,
			}}>
			<Text size={26} style={{ fontWeight: '500' }}>
				{title}
			</Text>
		</View>
	)
}

export default ProfileSecurityHeader
