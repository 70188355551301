import React, { useContext } from 'react'
import { CButton, CInput } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { ClosingPriceQtySection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import useTradeStore from '../../../../../hooks/trade/tradeStore'

const ClosingQtyInput = ({ amount, setAmount }) => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	const {data: tradeStore, mutate: mutateTradeStore} = useTradeStore()


	return (
		<ClosingPriceQtySection>
			<CButton
				title={`${t('closedQty')} (${tradeStore?.pairs?.split('/')[0]})`}
				onClick={() => {}}
				style={{
					alignSelf: 'flex-start',
					marginLeft: -5,
					color: textw,
					fontSize: 13,
				}}
			/>
			<CInput
				placeholder={
					amount ? `${BigNumber(amount)?.toFormat(2)}` : '0.0000'
				}
				value={`${BigNumber(amount)?.toFormat(2)}`}
				type={'text'}
				onChange={(e: any) => {
					setAmount(e.target.value)
				}}
				vStyle={{ padding: 0, flexDirection: 'row', width: '100%' }}
				style={{
					background: gray,
					color: textw,
					border: 'none',
					marginTop: 5,
					padding: 10,
					width: '100%',
					paddingRight: 60,
				}}
			/>
		</ClosingPriceQtySection>
	)
}

export default ClosingQtyInput
