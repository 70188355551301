import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { MText, MView } from 'styles/mobilePageStyles'
import MReferralForm from './ReferralForm'
import { MReferralCommissionSection } from './styles'

import { CButton } from 'app/components/Common'

import { ThemeContext } from 'theme/ThemeProvider'
import QRCode from 'react-qr-code'
import { copyText } from 'utils/ExpensiveFunction'
import { toast } from 'react-toastify'
const MReferralCommission = ({
	open,
	setOpen,
	referralCode,
	setReferralCode,
}) => {
	const { t } = useTranslation()
	const { cardOpacity } = useContext(ThemeContext).theme.COLORS

	return (
		<MReferralCommissionSection>
			<MView
				style={{
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'space-between',
				}}>
				<MText style={{ fontSize: 14, fontWeight: '300' }}>
					{t('myCommission')}
				</MText>
				<MText style={{ color: COLORS.secondary, fontSize: 16 }}>
					20%
				</MText>
			</MView>
			<MReferralForm
				title={'referralCode'}
				value={referralCode}
				setValue={setReferralCode}
			/>
			<MReferralForm
				title={'referralLink'}
				referral={`http://bitkorea.net/signup?ref=${referralCode}`}
			/>
			<MView
				style={{
					width: '100%',
					marginTop: 10,
				}}>
				<CButton
					icon={
						<QRCode
							size={80}
							value={`http://bitkorea.net/signup?ref=${referralCode}`}
						/>
					}
					onClick={() => {
						copyText(
							`http://bitkorea.net/signup?ref=${referralCode}`
						)
						toast(t('copied') + '  Qr Code')
					}}
					style={{
						width: 70,
						height: 70,
						justifyContent: 'center',
						alignItems: 'center',
						background: cardOpacity,
					}}
				/>
			</MView>
		</MReferralCommissionSection>
	)
}

export default MReferralCommission
