import React from 'react'
import MCoinLists from 'app/mobile/components/MCoinLists'
import { MMarketListSection } from '../../styles'
import { useFetcherPublic } from 'app/hooks/fetcher'
import MarketListHeader from '../../MarketListHeader'

const MarketDerivativesUsdc = () => {

	return (
		<MMarketListSection>
			<MarketListHeader />
			<MCoinLists data={[]} />
		</MMarketListSection>
	)
}

export default MarketDerivativesUsdc
