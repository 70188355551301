import React, { useContext } from 'react'
import moment from 'moment'
import { Text, View } from 'styles/reactnative'
import { CButton } from 'app/components/Common'
import { IconShare } from '@tabler/icons'
import { ThemeContext } from 'theme/ThemeProvider'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

const HelpListDetailHeader = ({ item }: any) => {
	const { t } = useTranslation()
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				width: '100%',
				height: '12%',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}>
			<View
				style={{
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '60%',
					height: '100%',
					paddingBlock: 10,
				}}>
				<Text size={18} style={{ color: textw }}>
					{t(item?.title)}
				</Text>
				<Text size={14} style={{ color: textsilver }}>
					{moment(item?.date).format('YYYY-MM-DD')}
				</Text>
			</View>
			<CButton
				onClick={() => {
					message.info(t('notWorkingNow'))
				}}
				icon={<IconShare size={16} color={textw} />}
			/>
		</View>
	)
}

export default HelpListDetailHeader
