import styled from 'styled-components'

export const TradingParisSection = styled.div`
	display: flex;
`
export const TradingParisContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 5px;
`
export const FavouriteSection = styled.div`
	margin-right: 10px;
	display: flex;
	justify-content: center;
`
