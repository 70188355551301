import styled from 'styled-components'
import { COLORS } from 'app/constants'

export const CreateBotItems = styled.div`
	width: 100%;
	height: 154px;
	margin-top: 24px;
	background-color: ${COLORS.white};
	border-radius: 16px;
	box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
	display: flex;
	flex-direction: row;
	cursor: pointer;
`
