import React, { useState, useContext } from 'react'
import { IconChevronDown } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
const DropdownCoin = () => {
	const { t } = useTranslation()
	const { gray, grey, textw, textsilver } =
		useContext(ThemeContext).theme.COLORS
	const [convertType, setConvertType] = useState('spot')
	const [display, setDisplay] = useState<boolean>(false)
	const list = ['spot', 'futures']
	return (
		<View style={{ width: '40%', height: 30, background: gray }}>
			<CButton
				title={t(convertType)}
				onClick={() => setDisplay(!display)}
				icon={<IconChevronDown size={10} />}
				style={{
					height: '100%',
					width: '100%',
					color: textw,
					justifyContent: 'space-between',
				}}
			/>
			{display ? (
				<View
					style={{
						position: 'absolute',
						width: '32%',
						background: grey,
						zIndex: 99,
						marginTop: 90,
					}}>
					{list.map((item, index) => (
						<CButton
							key={index.toString()}
							title={t(item)}
							onClick={() => {
								setConvertType(item)
								setDisplay(!display)
							}}
							backgroundHover={textsilver}
							style={{
								width: '100%',
								alignItems: 'flex-start',
								padding: 5,
								cursor: 'pointer',
								color:
									convertType === item
										? COLORS.secondary
										: textw,
							}}
						/>
					))}
				</View>
			) : null}
		</View>
	)
}

export default DropdownCoin
