import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const AuditSettingSection = styled.div`
	width: 100%;
	height: 100%;
`

export const IdentifyVerificationSection = styled.div`
	width: 100%;
	border-radius: 15px;
	background-color: ${p => p.theme.COLORS.opacityBgColor};
	margin-bottom: 20px;
`

export const ProfileTradingViewAlertSection = styled.div`
	width: 100%;
	height: 600px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-block: 24px;
	padding-inline: 32px;
	border-radius: 15px;
	background-color: ${COLORS.white};
`
export const ProfileTradingAlertHeaderSection = styled.div`
	height: 76px;
	width: 100%;
	padding-block: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`
