import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'

// eslint-disable-next-line prettier/prettier
export const DrawerSection = styled.div<{ display: string; isDarkMode?: any }>`
	position: absolute;
	top: 0px;
	right: 0%;
	width: 330px;
	// later change width size
	height: 100%;
	background-color: ${props => props.theme.COLORS.background};
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	position: fixed;
	z-index: 2 !important;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(0); /* changed it */
	transition: transform 0.3s ease-out;
	${({ display }) =>
		display === 'none' &&
		`
      transform: translateX(100%); /* changed it */
    `}
`
