import React from 'react'

import { View, Text } from 'styles/reactnative'
import TraderDetailDropdowns from '../../TraderDetailDropdowns'

const CurrentTradesHeader = ({ coinLists, selectCoin, setSelectCoin }) => {
	return (
		<View
			style={{
				width: '100%',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}>
			<Text size={14} style={{ fontWeight: '600' }}>
				Past Trades
			</Text>
			<TraderDetailDropdowns
				data={coinLists}
				selected={selectCoin}
				setSelected={setSelectCoin}
			/>
		</View>
	)
}

export default React.memo(CurrentTradesHeader)
