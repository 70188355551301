import useSWR from 'swr'
import API from '../../../utils/api'
import { INDEXPIRCE, TRADEAPI_URL } from '../../constants/TRADEAPIKEYS'
import { useToken } from '../auth/token'
import { getPersistData } from '../persist'

let swrData: any = []
let swrData2: any = []
let mutateData: any = []
let ep_id: any = null

export function useIndexPrice(ep: number) {
	const { data: token } = useToken()

	let KEY: string | null = INDEXPIRCE + '?ep_id=' + ep
	if (ep == null || ep === undefined) KEY = null

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData[ep]) return swrData[ep]

			const TEMP = getPersistData(KEY)
			if (TEMP) return TEMP

			const response = await API.get(
				INDEXPIRCE + '?ep_id=' + ep,
				token + '',
				{},
				false,
				TRADEAPI_URL
			)
			swrData[ep] = response.result
			return swrData[ep]
		},
		{
			revalidateOnFocus: true,
			revalidateIfStale: true,
			dedupingInterval: 0,
		}
	)
	swrData2[ep] = data
	mutateData[ep] = mutate

	return {
		data: swrData2[ep_id],
		mutate: (value?: any, epid?: number) => {
			try {
				if (value !== undefined) {
					if (epid) {
						swrData[epid] = value
					}
				}
				if (epid && typeof mutateData[epid] == 'function') {
					mutate()
					return mutateData[epid]()
				} else {
					return mutate()
				}
			} catch (e) {
				return mutate()
			}
		},
		getPrice: (epid?: number) => {
			try {
				ep_id = epid ? epid : ep_id
				if (epid) {
					return (
						swrData2[epid]?.[0]?.price ||
						swrData[epid]?.[0]?.price ||
						0
					)
				} else {
					return (
						swrData2[ep_id]?.[0]?.price ||
						swrData[ep_id]?.[0]?.price ||
						0
					)
				}
			} catch (e) {
				return 0
			}
		},
	}
}
