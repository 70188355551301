import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import { useWSize } from 'utils/ExpensiveFunction'
import DepositLeftSection from './DepositLeftSection'
import DepositRightSection from './DepositRightSection'
import { ThemeContext } from 'theme/ThemeProvider'

const DepositModal = ({ setShowModal }) => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				width: useWSize(),
				height: 550,
				borderRadius: 5,
				backgroundColor: drawerBgColor,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={'deposit'}
				closeModal={() => setShowModal(false)}
			/>
			<View
				style={{ width: '100%', height: '100%', flexDirection: 'row' }}>
				<DepositLeftSection />
				<DepositRightSection />
			</View>
		</View>
	)
}

export default React.memo(DepositModal)
