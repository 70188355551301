import React, { useContext } from 'react'

import { TermOfServiceSection } from './styles'
import { ThemeContext } from '../../../theme/ThemeProvider'
import styled from 'styled-components'

const TermOfService = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		//입력한대로 표시 개행문자
		<TermOfServiceSection
			style={{
				minHeight: 500,
				height: '100%',
				color: textw,
				marginTop: 50,
			}}>
			<section className="section1">
				<h5 className="highlight">I. 일반</h5>
				<p className="paragraph">
					이 계약에는 고객에게 알려진 일반적인 자금 세탁 방지 정책,
					개인 정보 보호 정책 및 GlobalBIT가 이후 발행하거나 게시할 수
					있는 모든 규칙이 포함되어 있습니다. GlobalBIT 서비스를 사용하기
					전에 전체 계약을 주의 깊게 읽어야 합니다. 귀하는 본 계약을
					읽지 않았거나 GlobalBIT로부터 응답을 받지 못했다는 이유로 본
					계약이 무효라고 주장하거나 본 계약의 취소를 요청해서는 안
					됩니다. GlobalBIT는 본 계약 및 모든 종류의 규칙을 수시로 만들고
					수정할 수 있는 권리를 보유합니다. 수정된 계약이 게시되면
					이전 계약을 대체하고 즉시 효력이 발생합니다. 사용자는
					언제든지 최신 계약을 찾을 수 있습니다. GlobalBIT 서비스를 방문,
					사용 또는 사용하려고 시도하면 최신 버전의 사용 약관을
					수락하고 동의한 것으로 간주됩니다. 동의하지 않는 경우
					GlobalBIT에서 제공하는 서비스를 방문하거나 이용할 수 없습니다.
				</p>
				<p className="paragraph1">
					*당사가 위의 개인정보를 수집하는 것을 원하지 않는 경우
					데이터 보호 담당자에게 서면으로 통지하여 장치 설정에서 개인
					데이터 수집에 대한 동의를 거부하거나 철회할 수 있습니다(예:
					Android 또는 IOS 장치에서 해당 설정을 해제하여 GPS 데이터
					수집을 거부할 수 있음). 단, 귀하의 개인정보 수집, 사용 또는
					처리에 대한 동의를 거부하거나 철회하면 서비스 사용에 제한이
					있을 수 있습니다.
				</p>
				<h5 className="highlight">가입 자격</h5>
				<p className="paragraph2">
					1. GlobalBIT 가입 된 개인, 법인 또는 기타 단체는 민/형사 분쟁
					발생 시 귀하가 자격이 없는 경우 귀하와 귀하의 보호자가 모든
					소송을 부담해야 하며, GlobalBIT는 귀하의 계정을 취소하거나
					영구적으로 동결하고 귀하와 귀하의 보호자가 이의를 제기할 수
					있는 권리를 가집니다.
				</p>
				<h5 className="highlight">가입 및 계정</h5>
				<p className="paragraph3">
					2. 가입 페이지에 따라 정보를 입력하고, 본 계약에 동의하고
					모든 등록 절차를 완료하였거나, 실사용을 한경우 해당 계약에
					구속됨을 고지합니다. GlobalBIT에 가입할 하기 위해서는
					GlobalBIT에서 허용하는 이메일, 휴대폰 번호 또는 기타 수단을
					사용할 수 있습니다. 또한 실명, 증명서, 주민등록번호, 기타
					법령을 기재해야 합니다. 등록 시 제공한 정보가 정확하지 않은
					경우 GlobalBIT는 이에 대한 책임을 지지 않으며 이로 인해
					발생하는 직간접적인 손실 및 불리한 결과는 귀하가 부담합니다.
					GlobalBIT는 사업 운영을 위한 시장과 사법 관할권을 선택할 권리가
					있습니다. 일부 국가에서는 이 서비스의 제공을 제한하거나
					거부할 수 있습니다.
				</p>
				<h5 className="highlight">사용자 정보</h5>
				<p className="paragraph4">
					3. 가입 또는 이용활성화 프로세스를 완료하려면 법률 및 규정의
					요구 사항을 준수하고 해당 도움말 페이지에 따라 정확하고
					완전하게 정보를 업데이트해야 합니다. 귀하가 제공한 정보에
					오류, 부정확, 오래되었거나 불완전하다고 의심할만한 합당한
					이유가 있는 경우 GlobalBIT는 귀하에게 문의 통지 또는 정정
					요청을 제공하거나 해당 자료를 직접 삭제하거나 전체 또는
					서비스 일부를 종료합니다. GlobalBIT는 이에 대해 어떠한 책임도
					지지 않으며, 이로 인해 발생하는 직간접적인 손실 및 불리한
					결과는 귀하가 부담합니다. 귀하는 GlobalBIT 또는 다른 사용자가
					귀하와 효과적으로 의사소통할 수 있도록 귀하의 이메일 주소,
					전화번호, 주소, 우편번호 및 연락처 정보를 정확하게 작성하고
					업데이트해야 합니다. 이러한 연락 방법을 통해 당사에 연락하지
					않아 서비스 이용 과정에서 손실 또는 추가 비용이 발생한 경우
					해당 비용은 전적으로 귀하의 책임입니다. 귀하는 귀하가 제공한
					연락처 정보의 유효성을 유지할 의무가 있으며 변경 또는
					업데이트가 필요한 경우 GlobalBIT의 요구 사항에 따라 운영되어야
					함을 이해하고 이에 동의합니다.
				</p>
				<h5 className="highlight">계정 보안</h5>
				<p className="paragraph5">
					4. GlobalBIT 계정 및 암호의 기밀 유지 및 GlobalBIT 계정에서
					수행하는 모든 활동(정보 공개, 정보 공개, 온라인 클릭 동의
					또는 다양한 규칙 제출을 포함하되 이에 국한되지 않음)에 대한
					책임은 전적으로 귀하에게 있습니다. 귀하는 다음에 동의합니다.
					<br />
					(a) 승인되지 않은 사람이 귀하의 GlobalBIT 계정을 사용하거나
					기밀 유지 조항을 위반하는 기타 상황을 즉시 GlobalBIT에
					알립니다.
					<br />
					(b) 귀하는 웹사이트/서비스의 모든 보안, 인증, 거래, 입금,
					현금 징수 메커니즘 또는 프로세스를 엄격히 준수해야 합니다.
					<br />
					(c) 각 인터넷 시간대가 끝나면 올바른 절차에 따라
					웹사이트/서비스를 종료해야 합니다. GlobalBIT는 귀하가 본 계약의
					조항을 준수하지 않아 발생하는 손해에 대해 책임을 지지
					않습니다. 귀하는 GlobalBIT가 귀하의 요청에 따라 조치를 취하는
					데 합리적인 시간이 소요되며 GlobalBIT는 조치를 취하기 전에
					발생하는 결과(귀하의 손해를 포함하되 이에 국한되지 않음)에
					대해 책임을 지지 않는다는 점을 이해합니다.
				</p>
				<h5 className="highlight">서비스 내용</h5>
				<p className="paragraph6">
					6. GlobalBIT에서 거래하는 동안 귀하와 다른 사용자 사이에 무역
					분쟁이 있는 경우 귀하 또는 다른 사용자가 중재를 위해 분쟁을
					GlobalBIT에 제출하는 경우 GlobalBIT는 일방적인 중재 결정을 내릴
					권리를 보유합니다. 귀하는 GlobalBIT의 그러한 결정을 이해하고
					동의합니다.
				</p>
				<p className="paragraph7">
					7. 귀하는 GlobalBIT가 모든 정부 부서(사법 및 행정 부서 포함)의
					요청에 따라 GlobalBIT에 제공된 정보 및 거래 기록을 제공할
					권리가 있음을 이해하고 이에 동의합니다. 타인의 지적 재산권
					및 기타 정당한 권리와 이익을 침해한 것으로 의심되는 경우,
					GlobalBIT는 침해 주장에 대한 초기 결정이 내려진 경우 해당
					소유자에게 필요한 식별 정보를 제공할 권리를 보유합니다.
				</p>
				<div className="paragraph8">
					8. GlobalBIT 서비스를 사용하는 동안 발생하는 과세 소득과 모든
					하드웨어, 소프트웨어, 서비스 및 기타 비용에 대한 책임은
					전적으로 귀하에게 있습니다.
				</div>
				<p className="paragraph9">
					9. GlobalBIT에서 서비스를 이용함에 있어 귀하는 다음과 같은
					사항을 준수할 것을 약속합니다.
					<br />
					(a) GlobalBIT의 서비스 이용 시 발생하는 모든 행위는 해당 국가의
					법률, 규정 및 기타 규범 문서에 따라 수행되어야 하며, 공익
					또는 미풍양속에 해를 끼치거나 타인의 법적 권리 및 이익을
					해쳐서는 안 됩니다. 또한 조세를 포탈하거나 본 약관 및 관련
					규정에 위배되는 행위가 없어야 합니다. 전술한 의무를 이행하지
					않거나 법적 결과를 초래하는 경우 귀하는 귀하의 이름으로 모든
					법적 책임을 져야 하며 GlobalBIT가 어떠한 손실도 입지 않도록
					해야 합니다.
					<br />
					(b) 귀하는 거래 시 신의성실의 원칙을 준수하고 불공정한 경쟁
					또는 정상적인 온라인 거래의 질서를 방해하는 행위를 하지
					않아야 합니다.
					<br />
					(c) 귀하는 GlobalBIT의 사전 서면 동의 없이 복제 또는 배포를
					포함한 상업적 목적으로 GlobalBIT의 데이터를 사용할 수 없습니다.
					여기에는 GlobalBIT 사이트의 데이터 사용이 포함되며 이에
					국한되지 않습니다.
					<br />
					(d) 전술한 사항 외에도 다음을 수행해서는 안 됩니다.
					<br />
					(i) 딥 링크, 웹 크롤러, 봇, 스파이더, 기타 자동화 장치,
					프로그램, 스크립트, 알고리즘, 방법 또는 GlobalBIT가 자산의
					일부를 액세스, 획득, 복제, 모니터링하기 위해 GlobalBIT의 단독
					재량에 따라 결정할 수 있는 유사하거나 동등한 수동 또는 자동
					프로세스를 사용하는 행위, 혹은 GlobalBIT의 서비스에서 의도하지
					않은 방식으로 자료, 문서 또는 정보를 획득하거나 획득하려고
					시도하기 위해 GlobalBIT의 구조나 서비스 재현을 복제하거나
					우회하는 행위.
					<br />
					(ii) 해킹, 암호 마이닝 또는 기타 불법적이거나 금지된 수단을
					통해 승인 없이 자산의 일부 또는 기능에 액세스하거나 GlobalBIT
					서비스, GlobalBIT 서버 또는 GlobalBIT 서비스를 구성하는 다른
					시스템이나 네트워크에 액세스하려고 시도하는 행위
					<br />
					(iii) GlobalBIT 서비스 또는 자산에 연결된 네트워크의 취약성을
					조사, 스캔 또는 테스트하거나 GlobalBIT 서비스 또는 GlobalBIT
					서비스에 연결된 네트워크의 보안 또는 인증 조치를 위반하는
					행위
					<br />
					(iv) GlobalBIT 서비스의 다른 사용자 또는 방문자의 정보를
					추적하기 위해 역 조회, 추적 또는 조사하는 행위
					<br />
					(v) GlobalBIT 서비스, GlobalBIT 시스템 또는 이에 연결된 시스템의
					인프라나 네트워크에 지나치거나 불균형하게 큰 부하를 가하는
					행위
					<br />
					(vi) 장치, 소프트웨어 또는 루틴 프로그램을 사용하여 GlobalBIT의
					정상적인 작동을 방해하거나 GlobalBIT에서의 거래 또는 다른
					사람의 GlobalBIT 서비스 사용을 방해하는 행위.
					<br />
					(vii) 귀하의 신원 또는 GlobalBIT에 대한 메시지 또는 전송의
					출처를 위장하기 위해 헤더를 위조, 가장하거나, ID를 조작하는
					행위
					<br />
					(viii) 불법적인 방식이나 공정성 및 커뮤니티 규범을 위반하는
					위해 GlobalBIT 서비스를 사용하는 행위.
				</p>
				<p className="paragraph10">
					10. 귀하는 다음 사항을 이해하고 동의합니다.
					<br />
					(a) GlobalBIT는 귀하가 위에서 언급한 조건을 위반했는지 여부에
					대해 일방적인 결정을 내리고 이러한 그 결정에 따라 귀하에
					대한 서비스 제공을 처리하거나 종료하기 위해 본 계약의 규칙을
					적용할 권리를 보유합니다. 또는 사전 통지, GlobalBIT에서 거래
					주문 및 거래 보안을 유지하기 위해 GlobalBIT는 악의적인 매도
					또는 매수로 인해 시장의 정상적인 거래 주문이 중단되는
					상황에서 적절한 위험 관리 조치를 취할 권리를 보유합니다.
					해당 매매주문, 해당 계좌의 동결, 영향을 받은 거래정보의
					원상회복, 해당 손실의 복구하고 해당 사건을 현지 사법당국에
					신고합니다.
					<br />
					(b) 귀하가 불법 또는 불법 행위를 저질렀음을 입증하기 위한
					국가 행정 및 사법 당국의 유효한 법적 문서 또는 자체 판단에
					따라 귀하의 행위가 본 계약 및 규칙의 조항을 위반하거나
					위반한 것으로 의심되는 경우 GlobalBIT는 해당 위반 또는 계약
					위반 혐의에 조치할 권리를 가집니다.
					<br />
					당사는 귀하에 대해 취한 조치를 게시할 권리가 있습니다.
					법률을 위반하거나 타인의 합법적인 권리를 침해하거나 본
					계약을 위반한 것으로 의심되는 정보를 GlobalBIT에 게시한 경우
					GlobalBIT는 귀하에게 알리지 않고 그러한 정보를 삭제할 권리를
					보유하며 본 계약에 따라 처벌을 부과할 수 있습니다.
					<br />
					(c) 사용자에게 영향을 미치는 귀하의 조치에 대해 GlobalBIT는
					귀하의 조치 및 특성이 본 계약을 위반하는지 여부를 일방적으로
					결정할 권리를 보유합니다. 귀하는 귀하의 행동과 관련된 모든
					증거를 보관해야 하며 추가 증거를 제시하지 못하는 데 따른
					불리한 결과를 받아들여야 합니다. 귀하의 계약 위반 혐의로
					인해 제3자에게 발생한 손해에 대해 귀하는 전적으로 책임을
					집니다.
				</p>
				<h5 className="highlight">책임의 범위 및 책임의 제한</h5>
				<p className="paragraph11">
					11. GlobalBIT는 현재 상태 및 가용성에 따라 GlobalBIT 서비스를
					제공합니다. 그러나 GlobalBIT는 특정 목적에 대한 서비스의 적용
					가능성, 오류 또는 누락, 연속성, 정확성, 신뢰성 또는 적합성을
					포함하되 이에 국한되지 않는 서비스와 관련하여 어떠한 명시적
					또는 묵시적 보증도 하지 않습니다. 동시에 GlobalBIT는 GlobalBIT
					서비스와 관련된 기술 및 정보의 유효성, 정확성, 신뢰성, 품질,
					안정성, 무결성 및 적시성과 관련하여 어떠한 약속이나 보장도
					하지 않습니다.
				</p>
				<div className="paragraph12">
					12. 귀하는 GlobalBIT의 정보 시스템이 이용자가 직접 배포하며
					위험과 결함이 있을 수 있음을 이해합니다.
				</div>
				<p className="paragraph13">
					13. GlobalBIT는 거래 사이트로만 사용됩니다. GlobalBIT는 가상화폐
					정보와 함께 가상화폐로 거래할 수 있는 물건만 찾을 수 있고,
					가상화폐 거래협상 및 사업사이트 등 가상화폐도 찾을 수 있지만
					GlobalBIT는 가상화폐와 가상화폐의 품질을 포함합니다. 귀하는
					관련 디지털 자산 및 정보의 진위, 적법성 및 유효성을 귀하의
					재량에 따라 결정하고 이로 인해 발생하는 모든 책임과 손실을
					귀하의 비용으로 부담해야 합니다. 법률 및 규정에서 명시적으로
					요구하지 않는 한 GlobalBIT는 다음 경우를 제외하고 모든 고객
					데이터, 디지털 자산 정보, 거래 활동 및 기타 거래 관련 문제를
					사전 검토할 의무가 없습니다. 특정 사용자 또는 특정 거래에
					의한 중대한 불법 행위 또는 불이행일 수 있습니다. 또는
					GlobalBIT에서 귀하의 GlobalBIT 행위가 불법이거나 부적절하다고
					의심할 합당한 근거가 있습니다.
				</p>
				<p className="paragraph14">
					14. GlobalBIT 또는 GlobalBIT가 승인한 제3자 또는 귀하와 GlobalBIT가
					상호 승인한 제3자는 취소 불가능한 권리에 따라 거래에서
					발생하는 다른 사용자와의 분쟁을 수락하고 결정을 내리기 위해
					분쟁과 관련된 사실과 해당 규칙을 일방적으로 판단할 권리가
					있습니다. 귀하는 최종 결정을 준수해야 합니다. 규정된 기간
					내에 결정이 이행 및 처리되지 않는 경우, GlobalBIT는 GlobalBIT에
					개설된 현재 계정에 있는 금액 또는 GlobalBIT 또는 그 파트너를
					대신하여 지불한 보증금을 사용하여 직접 지불을 완료할 권리를
					갖습니다.
					<br />
					보증금의 마진을 적시에 회수하고 GlobalBIT 및 그 계열사의 손실을
					보상해야 합니다. 그렇지 않으면 GlobalBIT 및 그 계열사는 다른
					계약에 따라 귀하의 권리와 이익을 직접 공제하고 계속해서
					복구할 권리가 있습니다. 귀하는 GlobalBIT나 GlobalBIT가 승인한
					제3자 또는 귀하와 GlobalBIT가 상호 동의한 제3자가 사법 기관이
					아니며 해당 증거는 일반인의 자격으로만 인증되어야 함을
					이해하고 동의합니다. GlobalBIT 또는 귀하와 GlobalBIT가 승인한
					제3자는 분쟁을 해결할 수 있는 취소 불가능한 권리를 기반으로
					하며 분쟁 해결 결과가 귀하의 기대를 충족할 것이라고 보장할
					수 없으며 그러한 계약의 체결에 대해 책임을 지지 않습니다. 그
					결과로 입은 손실의 경우 수혜자에게 보상을 청구하는 데
					동의합니다.
				</p>
				<div className="paragraph15">
					15. GlobalBIT는 특정 사용자 또는 특정 거래가 중대한 법률 또는
					계약 위반을 포함할 수 있다고 믿을 수 있는 합리적인 근거가
					있습니다. GlobalBIT는 GlobalBIT에 대한 귀하의 행위가 불법이거나
					부적절하다고 판단할 합당한 근거가 있습니다.
				</div>
				<p className="paragraph16">
					16. GlobalBIT는 정상적인 장비 유지 보수, 네트워크 연결 장애,
					컴퓨터, 통신 또는 기타 시스템, 정전, 파업, 노동 분쟁, 폭동,
					반란, 폭동, 생산 및 생산, 화재, 홍수, 폭풍, 폭발, 전쟁, 정부
					또는 행정 당국의 명령으로 인해 서비스 제공이 실패하거나
					지연되는 경우 당사는 책임을 지지 않습니다. 또는 제3자의
					행위를 하지 않을 수 있습니다.
				</p>
				<h5 className="highlight">계약 해지</h5>
				<p className="paragraph17">
					17. 귀하는 GlobalBIT가 단독 재량에 따라 사전 통지 없이 서비스의
					전부 또는 일부를 어떤 이유로든 일시 중단, 종료 또는
					영구적으로 동결(취소)할 수 있다는 데 동의합니다. GlobalBIT에
					대한 귀하의 계정 권한이며 당사는 귀하 또는 제3자에 대해
					책임을 지지 않습니다. 그러나 GlobalBIT는 거래 데이터, 기록 및
					계정, 애플리케이션 및 사용과 관련된 기타 정보를 보유할
					권리를 보유합니다. GlobalBIT는 다음과 같은 경우 귀하의 계정을
					취소하고 GlobalBIT 계정 권한을 영구적으로 동결(취소)함으로써 본
					계약을 직접 종료할 수 있는 권리를 보유합니다.
					<br />
					(a) GlobalBIT가 귀하에게 서비스 제공을 중단한 후 귀하가
					GlobalBIT의 사용자로 또는 다른 사람의 이름으로 직간접적으로
					재등록했다고 의심하는 경우
					<br />
					(b) 귀하가 제공한 이메일 주소가 존재하지 않거나 이메일을
					수신할 수 없고 귀하에게 연락할 수 있는 다른 방법이 없거나
					GlobalBIT에서 귀하의 이메일 정보를 다른 연락 방법으로
					변경하도록 통지했지만 귀하는 여전히 GlobalBIT로부터 통지를 받은
					후 영업일 기준 3일 이내에 유효한 이메일 주소가 주소로
					변경되지 않는 경우
					<br />
					(c) 귀하가 제공하는 중요한 사용자 정보가 거짓이거나 부정확한
					경우
					<br />
					(d) 규칙이 변경되면 귀하는 서비스에 대한 새로운 계약을
					수락할 의사가 없음을 GlobalBIT에 명시적으로 통지해야 합니다.
					<br />
					(e) GlobalBIT가 서비스를 종료하는 데 필요하다고 판단하는 기타
					조건. 귀하의 계정 서비스가 종료되거나 계정이 GlobalBIT에 의해
					영구적으로 동결(취소)된 경우 GlobalBIT는 귀하의 계정에 있는
					정보를 유지 또는 공개하거나 귀하가 가지고 있는 정보를 귀하
					또는 제3자에게 전달할 의무가 없습니다.
				</p>
				<p className="paragraph18">
					18. 귀하는 다음에 동의합니다.
					<br />
					(a) GlobalBIT와의 계약 관계가 종료된 후에도 GlobalBIT는 여전히
					다음과 같은 권리를 갖습니다. 당사는 GlobalBIT 서비스를 사용하는
					동안 사용자 정보 및 모든 거래 정보를 계속 저장합니다.
					<br />
					(b) GlobalBIT는 귀하가 서비스를 이용하는 동안 본 약관에
					위배되는 불법 행위 또는 행위를 저지른 경우 본 약관에 따라
					귀하를 상대로 소송을 제기할 수 있습니다.
					<br />
					(c) GlobalBIT가 귀하를 위해 서비스를 중단 또는 종료하는 경우
					다음 원칙에 따라 서비스 중단 또는 종료 전에 기록된 거래
					데이터를 처리합니다. GlobalBIT가 그러한 손실 또는 비용으로부터
					면책되도록 해야 합니다.
					<br />
					(d) 서비스 중단 또는 해지 이전에 다른 회원과 구매계약을
					체결하였으나 실제 계약이 이행되지 아니한 경우 GlobalBIT는 해당
					구매계약 정보 및 해당 거래정보를 삭제할 권리를 가집니다.
					<br />
					(e) 계정 서비스 종료 또는 GlobalBIT에 의한 귀하의 계정 영구
					정지(취소) 시, GlobalBIT는 귀하의 계정의 모든 정보를 보관 또는
					공개하거나 귀하 또는 제3자에게 귀하가 읽거나 전달하지 않은
					정보를 귀하 또는 제3자에게 전달할 의무가 없습니다.
					<br />
					(f) 서비스 중단 또는 해지 이전에 다른 회원과 구매계약을
					체결하고 그 계약의 일부가 이행된 경우 해당 거래를 삭제하지
					않을 수 있습니다. GlobalBIT가 서비스 중단 또는 종료 시 관련
					상황을 상대방에게 알릴 수 있습니다.
				</p>
				<h5 className="highlight">지적 재산 보호</h5>
				<p className="paragraph19">
					19. 웹사이트 로고, 데이터베이스, 웹사이트 디자인, 텍스트 및
					그래픽, 소프트웨어, 사진, 비디오, 음악, 사운드 및 소프트웨어
					편집에 대한 지적 재산권, 관련 소스 코드 및 소프트웨어를
					포함하되 이에 국한되지 않는 GlobalBIT에 포함된 모든 지적 성과는
					GlobalBIT가 소유합니다. 상업적 목적으로 상기 자료 또는 콘텐츠를
					복사, 수정, 복사, 전송 또는 사용할 수 없습니다.
				</p>
				<p className="paragraph20">
					20. 본 계약을 수락한 경우 귀하는 자신의 자유의지에 근거하여
					저작권, 배포권, 대여권, 전시권, 공연권, 상영권, 방송권,
					정보네트워크 전파권 등의 모든 형태의 정보를 GlobalBIT에 무료로
					양도하고 배정한 것으로 간주합니다. 저작권소유자가 받을
					권리가 있는 기타 양도권, 무단전재 및 기타 양도가능권 등에
					대해 GlobalBIT는 해당 저작권 침해에 대한 소송을 제가할 권리가
					있으며, 그러한 침해에 대한 완전한 보상을 받을 권리가
					있습니다. 본 계약은 GlobalBIT에 게시되고 저작권법에 의해
					보호되는 모든 콘텐츠에 적용되며, 콘텐츠가 본 계약 체결
					전후에 생성되었는지 여부에 관계없이 적용됩니다.
				</p>
				<p className="paragraph21">
					21. 귀하는 GlobalBIT에서 제공하는 서비스를 이용하는 동안 GlobalBIT
					기타 타인의 지적재산권을 무단으로 사용하거나 처분하여서는
					안됩니다. 귀하가 GlobalBIT에 게시하는 모든 정보는 다른
					웹사이트가 어떠한 방식으로든 이러한 정보를 사용하도록
					게시하거나 승인할 수 없습니다.
				</p>
				<div className="paragraph22">
					22. 귀하의 GlobalBIT 로그인 또는 GlobalBIT에서 제공하는 서비스
					사용은 당사가 귀하에게 지적 재산을 양도하는 것으로 간주되지
					않습니다.
				</div>
				<h5 className="highlight">관련 법률</h5>
				<p className="paragraph23">
					23. 본 계약 전체는 싱가포르 공화국 법률에 따라 체결되며
					싱가포르 공화국의 해당 법률이 그 성립, 해석, 내용 및 집행을
					규율합니다. 본 계약에서 합의된 서비스로 인해 또는 이와
					관련하여 발생하는 모든 청구 또는 소송은 싱가포르 법률에 따라
					규율, 해석 및 집행됩니다. 의심의 여지를 없애기 위해 이
					조항은 당사에 대한 모든 불법 행위 청구에 명시적으로
					적용됩니다. 당사에 대한 또는 당사와 관련된 청구 또는 조치에
					대한 관할 법원 또는 재판소는 싱가포르 공화국에 있습니다.
					귀하는 싱가포르 공화국 법원에서 진행되는 법원 절차 및
					항소에서 전속 관할권에 무조건적으로 접근할 수 있습니다. 또한
					귀하는 본 계약과 관련된 모든 분쟁 또는 문제 또는 본 계약으로
					인해 발생하는 청구 및 조치에 대한 장소 또는 관할 법원이
					전적으로 싱가포르 공화국에 있다는 데 무조건적으로
					동의합니다. 이 웹사이트의 다른 사업이 관할권에 대한 특별
					계약의 적용을 받는 경우 해당 계약이 우선합니다. Forum Non
					Convenience 원칙은 본 서비스 약관에 따라 귀하가 선택한
					법원에는 적용되지 않습니다.
				</p>
				<h5 className="highlight">II. 고객정보 및 자금세탁방지정책</h5>
				<p className="paragraph24">
					1. 우리는 고객 및 자금세탁 방지법과 규정을 준수하고 고객 및
					자금세탁 방지 정책을 고의로 위반하지 않습니다. 합리적인 통제
					범위 내에서 당사는 자금 세탁 손실을 최대한 방지하기 위해
					안전하고 보안이 유지되는 서비스를 제공하는 데 필요한 조치와
					기술을 채택할 것입니다. 고객 노하우 및 자금 세탁 방지 정책은
					다음과 같습니다.
					<br />
					(a) 당사는 해당 법률 및 규정에 명시된 기준을 충족하기 위해
					KYC 확인 및 자금 세탁 방지(AML) 정책을 게시하고
					업데이트합니다.
					<br />
					(b) 우리는 이 웹사이트의 운영에 관한 몇 가지 지침과 규칙을
					게시하고 업데이트하며, 우리 직원은 지침과 규칙에 따라 전체
					프로세스 서비스를 제공할 것입니다.
					<br />
					(c) 엄격한 본인 확인 절차 등 내부 감시 및 거래 통제를 위한
					절차를 설계 및 완성하고 자금세탁방지를 전담하는 전문팀을
					구성합니다.
					<br />
					(d) 우리는 고객과 관련하여 실사 및 지속적인 감독을 수행하기
					위해 위험 예방 기반 접근 방식을 채택합니다.
					<br />
					(e) 기존 거래를 검토하고 정기적으로 검사합니다.
					<br />
					(f) 관할 당국에 의심스러운 거래를 보고합니다.
				</p>
				<h5 className="highlight">신분증 정보</h5>
				<p className="paragraph25">
					2. 해당 관할권의 법률 및 규정에 따라 관련 기관의 특성에 따라
					당사가 수집하는 귀하의 정보 내용이 다를 수 있습니다.
					원칙적으로 귀하가 개인으로 등록할 때 귀하의 정보를
					수집합니다.
					<br />
					(a) 기본 개인 정보: 귀하의 이름, 주소, 생년월일 및 국적,
					기타 사용 가능한 정보. 신원 인증은 여권, 신분증 또는 해당
					관할권에서 요구하고 발행한 기타 신분증과 같이 공무원 또는
					기타 유사한 기관에서 발행한 문서를 기반으로 합니다. 귀하가
					제공하는 주소는 귀하가 사용하는 교통수단에 대한 요금 카드,
					이자율 청구서 또는 유권자 등록 확인과 같은 적절한 방식으로
					확인됩니다.
					<br />
					(b) 유효한 사진: 등록 전에 가슴 앞에서 신분증을 들고 있는
					사진 제공
					<br />
					(c) 연락처 정보: 전화/휴대전화 번호 및 유효한 이메일 주소.
				</p>
				<p className="paragraph26">
					3. 귀하가 회사 또는 기타 유형의 법인인 경우 당사는 귀하의
					계정 또는 신탁 계정의 최종 수혜자를 결정하기 위해 귀하로부터
					다음 정보를 수집합니다.
					<br />
					(a) 회사 등록 및 회사 등록 증명서 회사 문서 사본설립 및 각서
					<br />
					(b) 회사의 소유권 구조 및 소유권 설명에 대한 자세한 인증
					문서, 웹 사이트에서 회사 계정을 개설하고 운영할 책임이 있는
					회사의 공식 대리인을 임명하기 위한 이사회의 결정
					<br />
					(c) 관련 규정에 따라 제공되어야 하는 웹사이트의 이사, 회사의
					대주주 및 회사 계정의 승인된 서명인의 신분 증명서
					<br />
					(d) 회사의 주요 사업장 주소와 회사의 주요 사업장 주소와 다를
					경우 회사의 주요 사업장 주소 및 우편 주소. 회사의 현지
					주소가 주 사업장 주소와 다른 경우 회사는 고위험 고객으로
					간주되며, 회사는 추가 문서를 제공해야 합니다.
					<br />
					(e) 관할 당국에서 발행한 기타 인증 문서 및 관련 관할권의
					법률 및 규정에 비추어 귀사 법인의 특정 특성에 비추어
					필요하다고 판단되는 기타 문서.
				</p>
				<div className="paragraph27">
					4. 귀하의 신원 정보는 영어와 중국어 버전만 받습니다. 신원
					정보가 두 언어 중 하나로 작성되지 않은 경우 신원 정보를
					영어로 번역하고 정식으로 공증해야 합니다. 단, 별도 고지를
					통해 예외가 있을 수 있습니다.
				</div>
				<p className="paragraph28">
					5. 확인
					<br />
					(a) 신분증의 앞면과 뒷면을 모두 제공해야 합니다.
					<br />
					(b) 가슴 앞에서 신분증을 들고 있는 사진을 제공해야 합니다.
					<br />
					(c) 인증된 문서 사본은 원본과 비교하여 확인해야 합니다.
					그럼에도 불구하고 신뢰할 수 있고 적합한 인증자가 해당 사본이
					원본의 정확하고 포괄적인 사본임을 증명할 수 있는 경우 그러한
					사본은 허용 가능한 것으로 간주됩니다. 이러한 인증에는 대사,
					사법부 구성원, 치안 판사 등이 포함됩니다.
					<br />
					(d) 계정의 최종 수혜자 및 관리자의 식별은 개인이 궁극적으로
					고객을 직접 소유하거나 통제한다는 결정 또는 진행 중인 거래가
					다른 사람에 의해 수행되는지 여부를 결정하는 데 기반해야
					합니다. 사업체인 경우 대주주의 신원(예: 사업체 의결권 지분
					10% 이상 보유)을 확인해야 합니다.
				</p>
				<h5 className="highlight">거래 감독</h5>
				<p className="paragraph29">
					6. 보안요건 및 거래실태에 따라 현금인출한도를 상시 설정 및
					조정합니다.
				</p>
				<p className="paragraph30">
					7. 등록된 계정의 거래가 자주 발생하거나 합리적인 상황을
					초과하는 경우 당사의 전문 팀이 해당 거래가 의심스러운지
					평가하고 결정합니다.
				</p>
				<p className="paragraph31">
					8. 당사의 평가에 따라 특정 거래가 의심스럽다고 판단되면
					거래를 중단하거나 거래를 거부하는 등의 제한 조치를 취할 수
					있으며, 가능한 한 빨리 거래를 취소하고 관할 당국에 연락할 수
					있습니다.
				</p>
				<p className="paragraph32">
					9. 당사는 국제 자금 세탁 방지 표준을 준수하지 않거나 정치 및
					공인으로 간주될 수 있는 신청자의 등록 신청을 거부할 권리가
					있습니다. 당사는 자체 평가에 따라 의심스러운 것으로 식별된
					거래를 일시 중단하거나 종료할 권리가 있지만 이용자님에 대한
					당사의 의무 및 임무를 위반하지 않습니다.지금까지 GlobalBIT는
					북한, 쿠바, 수단, 시리아, 이란, 크리미아, 중국 본토,
					인도네시아, 싱가포르, 베네수엘라, 미국 및 캐나다(통칭하여
					&quot;금지 국가&quot;라고 함) 사용자의 거래 또는 등록 신청을
					거부했습니다. 금지된 국가 목록은 완전하지 않을 수 있으며
					수시로 GlobalBIT에서 검토할 수 있습니다. 귀하가 금지된 국가에
					거주하거나 거주할 예정이거나 금지된 국가에 거주하는 사용자를
					알고 있는 경우 즉시 당사에 알려야 합니다. 귀하는 귀하의 위치
					또는 거주지에 대한 잘못된 정보를 제공했다고 판단되는 경우
					귀하의 계정을 즉시 종료하고 오픈된 포지션을 청산하는 것을
					포함하여 관련 법률 및 규정에 따라 적절한 조치를 취할 권리가
					있음을 이해하고 인정합니다.
				</p>
			</section>
		</TermOfServiceSection>
	)
}

export default TermOfService
