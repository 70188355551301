import React from 'react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'

import { TradeDrawerTabBarSection } from './styles'
import { useTranslation } from 'react-i18next'

const TradeDrawerTabBar = ({ tab, setTab, favourite }: any) => {
	const data = [favourite, 'USDT',]
	const { t } = useTranslation()
	return (
		<TradeDrawerTabBarSection>
			{data.map((item, index) => (
				<CButton
					key={index.toString()}
					title={t(item)}
					onClick={() => {
						setTab(item)
					}}
					textHover={COLORS.secondary}
					style={{
						padding: 7,
						paddingBottom: 10,
						color: tab === item ? COLORS.primary : COLORS.lightGray,
						borderBottomWidth: 1,
						borderBottomColor:
							tab === item ? COLORS.primary : 'transparent',
						borderRadius: 0,
						// fontSize: width > 900 ? 14 : width > 600 ? 10 : 8,
					}}
				/>
			))}
		</TradeDrawerTabBarSection>
	)
}

export default TradeDrawerTabBar
