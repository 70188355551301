import { Select } from 'antd'
import React from 'react'
import { MProofOfSelectSection } from './styles'

const MProofOfSelect = () => {
	const handleChange = (value: string) => {
		console.log(`selected ${value}`)
	}
	return (
		<MProofOfSelectSection>
			<Select
				defaultValue="lucy"
				style={{ width: '100%', background: 'transparent' }}
				onChange={handleChange}
				options={[
					{
						value: 'THEBIT',
						label: 'Snapshot Time 2023-08-03 (GMT+8)',
					},
				]}
			/>
		</MProofOfSelectSection>
	)
}

export default MProofOfSelect
