import React from 'react'
import { View } from 'styles/reactnative'
import HelpListDetailHeader from './HelpListDetailHeader'
import HelpListDetailBody from './HelpListDetailBody'
import HelpListDetailFooter from './HelpListDetailFooter'

const HelpCenterListDetail = ({ data, setReadDetail, item }: any) => {
	const [currentItem, setCurrentItem] = React.useState(item)
	return (
		<View
			style={{
				width: '100%',
				height: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<HelpListDetailHeader item={currentItem} />
			<HelpListDetailBody item={currentItem} />
			<HelpListDetailFooter
				item={item}
				data={data}
				setReadDetail={setReadDetail}
				setCurrentItem={setCurrentItem}
			/>
		</View>
	)
}

export default HelpCenterListDetail
