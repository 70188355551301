import React, { useContext } from 'react'
import { IconConfetti } from '@tabler/icons'
import { MButton } from 'app/mobile/components'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const ProfileSettingNavBar = () => {
	const { t } = useTranslation()
	const navigation = useHistory()
	const { textw, cardOpacity } = useContext(ThemeContext).theme.COLORS
	return (
		<MView style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
			<MButton
				title={'referralProgram'}
				icon={<IconConfetti size={14} color={textw} />}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					flex: 1,
					padding: 10,
					background: cardOpacity,
					marginRight: 15,
					borderRadius: 5,
				}}
				textStyle={{ marginLeft: 3 }}
				onClick={() => {
					goTo(navigation, '/referral')
				}}
			/>
			<MButton
				title={'myRewards'}
				icon={<IconConfetti size={14} color={textw} />}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					flex: 1,
					padding: 10,
					background: cardOpacity,
					borderRadius: 5,
				}}
				textStyle={{ marginLeft: 3 }}
				onClick={() => {
					toast(t('notWorkingNow') + '')
				}}
			/>
		</MView>
	)
}

export default ProfileSettingNavBar
