import styled from 'styled-components'

export const MyAssetLeftSectionContainer = styled.div`
	height: 100%;
	width: 50%;
	@media only screen and (max-width: 600px) {
		width: 60%;
	}
`

export const MyAssetRightSectionContainer = styled.div`
	height: 100%;
	width: 50%;
	padding-inline: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media only screen and (max-width: 600px) {
		width: 40%;
	}
`
export const MyAssetBothSection = styled.div`
	width: 100;
	height: 90%;
	display: flex;
	flex-direction: row;
`
