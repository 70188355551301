import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const CFloatBtnsSection = styled.div`
	position: fixed;
	right: 120px;
	bottom: 50px;
	display: flex;

	flex-direction: row;
	z-index: 99999 !important;
`
export const FloatBtnSection = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #7438ff, #3860ff);
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
	color: ${COLORS.white};
	cursor: pointer;
	transition: ease border-bottom 250ms;
	&:active {
		transform: translateY(2px);
	}
`

export const FloatBtnSection2 = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #99888b90;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
	color: ${COLORS.white};
	cursor: pointer;
	transition: ease border-bottom 250ms;
	&:active {
		transform: translateY(2px);
	}
`

export const FloatBtnSection3 = styled.div`
	position: absolute;
	right: 10px;
	top: 10;
	width: 15px;
	height: 15px;
	border-radius: 30px;
	font-size: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #99888b90;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
	color: ${COLORS.white};
	cursor: pointer;
	transition: ease border-bottom 250ms;
	&:hover{
		background: #99888b;
	}
`
