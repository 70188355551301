import React, { useEffect } from 'react'
import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

import { TpslInputSection } from '../styles'
import TpslPercentage from './TpslPercentage'
import TpslDropdown from './TpslDropdown'
import { ThemeContext } from 'theme/ThemeProvider'
import useTradeStore from '../../../../../../../hooks/trade/tradeStore'
import { Button, Input, Space } from 'antd'
import { useTradeSetting } from '../../../../../../../hooks/trade/tradeSetting'
import { useIndexPrice } from '../../../../../../../hooks/trade/indexprice'
import BigNumber from 'bignumber.js'

const TpslFormField = ({
	percentage,
	setPercentage,
	value,
	setValue,
	title,
	tradeType,
	setTradeType,
}) => {
	const { textw, gray, bgColor } = React.useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { data: tradeStore } = useTradeStore()
	const currency = tradeStore?.pairs?.split('/')[1]

	const { data: store } = useTradeStore()
	const { data: setting } = useTradeSetting(store?.ep_id)
	const [MS, setMS] = React.useState(store?.main_sub || 'M')

	const { getPrice } = useIndexPrice(store?.ep_id)

	const leverage = setting?.leverage || 20
	let price = store?.order_price || 0
	const amount = store?.order_amount || 0

	if (store?.limit_market != 'L') {
		price = getPrice(store?.ep_id)
	}

	let v =
		new BigNumber(price)
			.multipliedBy(amount)
			.dividedBy(leverage)
			.toNumber() || 1

	let bidsvalue = Number(
		(price * amount).toFixed(store?.dp2 || 2)
	).toLocaleString('ko-kr')
	let asksvalue = Number(
		(price * amount).toFixed(store?.dp2 || 2)
	).toLocaleString('ko-kr')

	let calcpricelong = new BigNumber(value || 1)
		.minus(price)
		.multipliedBy(amount)
	let calcpriceshort = new BigNumber(price)
		.minus(value || 1)
		.multipliedBy(amount)

	if (MS === 'S') {
		v = new BigNumber(amount).dividedBy(leverage).toNumber()
		bidsvalue = new BigNumber(amount).multipliedBy(leverage).toFormat(2)
		asksvalue = new BigNumber(amount).multipliedBy(leverage).toFormat(2)
	}

	useEffect(() => {
		setMS(store?.main_sub)
	}, [store?.main_sub])

	return (
		<TpslInputSection>
			<View style={{ alignSelf: 'flex-start' }}>
				<Text style={{ color: textw, fontSize: 12 }}>
					{currency + ' ' + t(title)}
				</Text>
			</View>
			<View style={{ flexDirection: 'row', width: '100%' }}>
				<Input
					placeholder={''}
					value={value}
					type={'number'}
					onChange={(e: any) => setValue(e.target.value)}
					style={{
						background: gray,
						border: 'none',
						color: textw,
						padding: 8,
						marginBottom: 5,
						width: '100%',
					}}
				/>
				USDT
			</View>
			<Space style={{ color: textw, marginTop: 5, whiteSpace: 'nowrap' }}>
				{t(title == 'stopLossUsdt' ? 'pnlloss' : 'pnlprice') +
					' ' +
					t('long')}
				<Input
					readOnly
					value={
						!value || value == 0
							? '-'
							: calcpricelong?.toFormat(2) +
							  ' (' +
							  ((+calcpricelong.toNumber() / v) * 100).toFixed(
									2
							  ) +
							  '%)'
					}
					style={{
						background: bgColor,
						color:
							+calcpricelong.toNumber() > 0
								? COLORS.lightGreen
								: +calcpricelong.toNumber() < 0
								? COLORS.lightRed
								: textw,
						fontSize: 11,
					}}
				/>
			</Space>
			<Space style={{ color: textw, marginTop: 5, whiteSpace: 'nowrap' }}>
				{t(title == 'stopLossUsdt' ? 'pnlloss' : 'pnlprice') +
					' ' +
					t('short')}
				<Input
					readOnly
					value={
						!value || value == 0
							? '-'
							: calcpriceshort?.toFormat(2) +
							  ' (' +
							  ((+calcpriceshort.toNumber() / v) * 100).toFixed(
									2
							  ) +
							  '%)'
					}
					style={{
						background: bgColor,
						color:
							+calcpriceshort.toNumber() > 0
								? COLORS.lightGreen
								: +calcpriceshort.toNumber() < 0
								? COLORS.lightRed
								: textw,
						fontSize: 11,
					}}
				/>
			</Space>
			<Text
				style={{
					marginTop: 10,
					color: COLORS.lightGray,
					fontSize: 10,
				}}></Text>
		</TpslInputSection>
	)
}

export default TpslFormField
