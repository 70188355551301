import React from 'react'
import { COLORS, SIZES } from 'app/constants'
import { View, Text } from 'styles/reactnative'
const TraderChart = () => {
	return (
		<View
			style={{
				justifyContent: 'space-between',
				flexDirection: 'row',
				width: '100%',
				paddingTop: 20,
			}}>
			<View style={{ alignItems: 'flex-start' }}>
				<View style={{ flexDirection: 'row' }}>
					<Text
						style={{
							fontSize: 14,
							marginRight: 5,
							fontWeight: '300',
						}}>
						ROI
					</Text>
					<Text
						style={{
							paddingBlock: 1,
							paddingInline: 4,
							border: `1px solid ${COLORS.lightGray}`,
							fontSize: 10,
							fontWeight: '300',

							borderRadius: 5,
						}}>
						30D
					</Text>
				</View>
				<Text
					style={{
						color: COLORS.lightGreen,
						lineHeight: 2.5,
						fontSize: SIZES.h3,
						fontWeight: '600',
					}}>
					+65.72%
				</Text>
			</View>
			<Text>+65.72%</Text>
		</View>
	)
}

export default React.memo(TraderChart)
