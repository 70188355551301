import React from 'react'
import { CFlatList } from 'app/components/Common'
import { useFavourite } from 'app/hooks'

import { HeaderSearchListsSection } from './styles'
import HeaderSearchItem from './HeaderSearchItem'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'

const HeaderSearchLists = ({ search }) => {
	const { data: favourites } = useFavourite()

	const { EPs } = useExchangeStore()

	let filteredData: any

	if (search?.length) {
		filteredData = EPs?.filter(item =>
			item?.pairs?.toLowerCase()?.includes(search?.toLowerCase())
		)
	}
	const renderItem = (item: any, index: any) => (
		<HeaderSearchItem item={item} index={index} />
	)
	return (
		<HeaderSearchListsSection>
			{/* {loading ? <CSkeleton count={5} /> : null} */}
			<CFlatList
				data={filteredData?.length ? filteredData : favourites}
				renderItem={renderItem}
				noDataTitle={'favouriteCoinNotAddYet'}
			/>
		</HeaderSearchListsSection>
	)
}

export default HeaderSearchLists
