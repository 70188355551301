import React from 'react'
import { AssetOverviewTotalValueSection } from '../styles'
import AssetTotalValueLeft from './AssetTotalValueLeft'
import AssetTotalValueRight from './AssetTotalValueRight'

const AssetOverviewTotalValue = () => {
	return (
		<AssetOverviewTotalValueSection>
			<AssetTotalValueLeft />
			<AssetTotalValueRight />
		</AssetOverviewTotalValueSection>
	)
}

export default AssetOverviewTotalValue
