/**
 * Asynchronously loads the component for CopyTradePage
 */

import { lazyLoad } from 'utils/loadable'

export const CopyTradePage = lazyLoad(
	() => import('./index'),
	module => module.CopyTradePage
)
