import React from 'react'
import { TradeDrawerListItemSection } from '../styles'
import { useInfo24H } from 'app/hooks/trade/info24H'
import ListCoin from './ListCoin'
import PriceHigh from './PriceHigh'
import ListVolume from './ListVolume'
import { useIndexPrice } from 'app/hooks/trade/indexprice'

const DrawerListItem = ({ item, index, setDisplayDrawer }: any) => {
	const { data: info24H } = useInfo24H(item?.id)
	let { getPrice } = useIndexPrice(item?.id)

	return (
		<TradeDrawerListItemSection>
			<ListCoin item={item} setDisplayDrawer={setDisplayDrawer} />

			<PriceHigh
				indexPrice={getPrice(item?.id) || 0}
				info24H={info24H}
				setDisplayDrawer={setDisplayDrawer}
				item={item}
			/>
			<ListVolume
				volume={info24H?.volume}
				setDisplayDrawer={setDisplayDrawer}
				item={item}
			/>
		</TradeDrawerListItemSection>
	)
}

export default React.memo(DrawerListItem)
