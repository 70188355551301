import BigNumber from 'bignumber.js'
import React from 'react'
import { useBalance } from '../../../../hooks/user/mybalance'
import { AssetDerivativesTableSection } from '../styles'
import AssetDerivativesTableHeader from './AssetDerivativesTableHeader'
import AssetDerivativesTableList from './AssetDerivativesTableList'

const AssetDerivativesTable = () => {
	const header = [
		'coin',
		'balance',
		'availableBalance',
		'inOrderWithdrawals',
		'action',
	]

	const { data: spot } = useBalance('USDT', 1)

	const data = [
		{
			coin: 'USDT',
			balance: spot?.balance,
			availableBalance: BigNumber(spot?.balance || 0)
				.minus(spot?.lockbalance || 0)
				.toNumber(),
			lockBalance: BigNumber(spot?.lockbalance || 0).toFormat(5),
		},
	]
	return (
		<AssetDerivativesTableSection>
			<AssetDerivativesTableHeader header={header} />
			<AssetDerivativesTableList data={data} />
		</AssetDerivativesTableSection>
	)
}

export default AssetDerivativesTable
