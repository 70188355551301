import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const UserFeedbackContainerTitle = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				height: '15%',
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<Text size={22} style={{ color: textw }}>
				{t('productSuggestionRequest')}
			</Text>
		</View>
	)
}

export default UserFeedbackContainerTitle
