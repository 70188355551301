import useSWR from 'swr'
import API from '../../../utils/api'
import { MYSETTINGS } from '../../constants/APIKEYS'

import { useToken } from '../auth/token'

export function getSetting(data: any, key: string, defaultValue: any = null) {
	if (data === null) return null
	key = key.toUpperCase()
	const result = data?.find((item: any) => key in item)

	if (result?.[key] === undefined) return defaultValue
	return result?.[key]
}

export function useSetting(type: string | number) {
	let KEY: any = MYSETTINGS + '?type=' + type
	const { data: token } = useToken()
	if (!token) KEY = null

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {}, false)
			return response.result.result
		},
		{ revalidateOnFocus: true }
	)

	return {
		data,
		mutate: (value: any) => {
			return mutate(value)
		},
		updateSetting: async (key: string, value: any) => {
			await API.put(MYSETTINGS, token + '', { type, key, value })
			const updatedata = { [key]: value }

			if (data === null || data === undefined) return mutate([updatedata])
			return mutate([...data, updatedata])
		},
	}
}
