import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'

const OrderPreferenceNote = () => {
	const { t } = useTranslation()
	return (
		<View style={{ width: '100%', alignItems: 'flex-start' }}>
			<Text size={12}>
				<Text style={{ color: 'red', marginRight: 5 }}>*</Text>
				{t('note')}
			</Text>
			<Text
				size={12}
				style={{
					color: COLORS.lightGray,
					marginTop: 5,
					lineHeight: 1.2,
				}}>
				{t('orderNoteDesc')}
			</Text>
		</View>
	)
}

export default OrderPreferenceNote
