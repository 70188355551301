import React, { useContext } from 'react'
import { CTabs } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import { Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'

const DerivativesNavBarTabs = ({ tab, setTab, isMobile = false }) => {
	const tabs = ['L', 'M', 'CL', 'CM']

	const { bgColor, textw, grey, drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	return (
		<View
			style={{
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				paddingLeft: 10,
				paddingTop: 10,
				width: '100%',
			}}>
			<Space.Compact
				size="small"
				style={{ width: '100%', flexWrap: 'wrap', marginBottom: 1 }}>
				<Button
					style={
						tab != 'L'
							? {
									color: textw,
									background: isMobile ? drawerBgColor : bgColor,
									borderColor: grey,
									width: '50%',
							  }
							: { width: '50%' }
					}
					onClick={() => setTab('L')}
					type={tab == 'L' ? 'primary' : 'default'}>
					{t('limit')}
				</Button>
				<Button
					style={
						tab != 'M'
							? {
									color: textw,
									background: isMobile ? drawerBgColor : bgColor,
									borderColor: grey,
									width: '50%',
							  }
							: { width: '50%' }
					}
					onClick={() => setTab('M')}
					type={tab == 'M' ? 'primary' : 'default'}>
					{t('market')}
				</Button>
			</Space.Compact>
			<Space.Compact
				size="small"
				style={{ width: '100%', flexWrap: 'wrap' }}>
				<Button
					style={
						tab != 'CL'
							? {
									color: textw,
									background: isMobile ? drawerBgColor : bgColor,
									borderColor: grey,
									width: '50%',
							  }
							: { width: '50%' }
					}
					onClick={() => setTab('CL')}
					danger
					type={tab == 'CL' ? 'primary' : 'default'}>
					{t('climit')}
				</Button>
				<Button
					style={
						tab != 'CM'
							? {
									color: textw,
									background: isMobile ? drawerBgColor : bgColor,
									borderColor: grey,
									width: '50%',
							  }
							: { width: '50%' }
					}
					onClick={() => setTab('CM')}
					danger
					type={tab == 'CM' ? 'primary' : 'default'}>
					{t('cmarket')}
				</Button>
			</Space.Compact>
		</View>
	)
}

export default DerivativesNavBarTabs
