import React from 'react'

import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

const CurrentTradesTableHeader = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',

				padding: 5,
				borderRadius: 5,
			}}>
			<View style={{ width: '20%', alignItems: 'flex-start' }}>
				<Text size={12}>{t('positions')}</Text>
			</View>
			<View style={{ width: '15%', alignItems: 'flex-start' }}>
				<Text size={12}>{t('entryPrice')}</Text>
			</View>
			<View style={{ width: '15%', alignItems: 'flex-start' }}>
				<Text size={12}>{t('currentPositionMargin')}</Text>
			</View>
			<View style={{ width: '10%', alignItems: 'flex-start' }}>
				<Text size={12}>{t('orderQty')}</Text>
			</View>
			<View style={{ width: '15%', alignItems: 'flex-start' }}>
				<Text size={12}>{t('unrealizedP&L')}</Text>
			</View>
			<View style={{ width: '15%', alignItems: 'flex-start' }}>
				<Text size={12}>{t('openedOn')}</Text>
			</View>
			<View style={{ width: '10%', alignItems: 'flex-end' }}>
				<Text size={12}>{t('tpSL')}</Text>
			</View>
		</View>
	)
}

export default React.memo(CurrentTradesTableHeader)
