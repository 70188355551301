import React from 'react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'

const CoinRate = ({ type, times, style, textStyle }: any) => {
	return (
		<View
			style={{
				background: COLORS.bgGreen,
				borderRadius: 2,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				...style,
			}}>
			<Text
				size={12}
				style={{
					color: COLORS.green,
					paddingInline: 4,
					fontWeight: '400',
					lineHeight: 1.5,

					...textStyle,
				}}>
				{type} {times ? `${times}x` : null}
			</Text>
		</View>
	)
}

export default React.memo(CoinRate)
