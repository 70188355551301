import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'

const AssetsBody = () => {
	const { t } = useTranslation()
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	return (
		<View
			style={{
				alignItems: 'flex-start',
				marginTop: 20,
				justifyContent: 'space-between',
				width: '100%',
			}}></View>
	)
}

export default React.memo(AssetsBody)
