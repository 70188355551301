import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import { View } from 'styles/reactnative'
import HeaderBottomLists from './HeaderBottomLists'
import { useBalance } from '../../../../hooks/user/mybalance'
import BigNumber from 'bignumber.js'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useFetcher, useFetcherPublic } from '../../../../hooks/fetcher'
import { useLastPrice } from '../../../../hooks/trade/lastprice'

const AssetDerivativesHeaderBottom = () => {
	const { width } = useWindowDimensions()

	const { data: spot } = useBalance('USDT', 1)

	const balance = BigNumber(spot?.balance)

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const settingCurrency = getSetting(setting, 'CURRENCY', 'KRW')

	const currencyList = useFetcherPublic('/system/currency')
	const equiv = currencyList?.data?.result?.result?.find(
		(item: any) => item.code === settingCurrency
	)

	const equivValue = BigNumber(spot?.balance || 0)
		.multipliedBy(equiv?.price || 1)
		.toNumber()

	const data = [
		{
			title: 'totalEquity',
			usd: balance.toFormat(5),
			currency: settingCurrency,
			currencyValue: equivValue,
			lock: spot?.lockbalance || 0,
		},
		{
			title: 'unrealizedP&L',
			usd: 0,
			currency: settingCurrency,
			currencyValue: 0,
		},
	]

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: width < 1100 ? '100%' : '60%',
				height: '70%',
			}}>
			{data.map((item, index) => (
				<HeaderBottomLists
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</View>
	)
}

export default React.memo(AssetDerivativesHeaderBottom)
