import React from 'react'
import { View } from 'styles/reactnative'
import HelpCenterDetailList from './HelpCenterDetailList'
import { useTranslation } from 'react-i18next'

const HelpListDetailBody = ({ item }) => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				overflowX: 'hidden',
				overflowY: 'auto',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				padding: 20,
			}}>
			<HelpCenterDetailList text={t(item?.content)} />
		</View>
	)
}

export default HelpListDetailBody
