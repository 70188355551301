import styled from 'styled-components'
import darkEvent from 'assets/images/homeEventDark.png'
import lightEvent from 'assets/images/homeEventLight.png'
import { COLORS } from 'app/constants'
export const HomeEventSection = styled.div<{ isDarkMode?: any }>`
	position: static;
	top: 0;
	width: 100%;
	height: 460px;
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;

	border-bottom: 1px solid ${p => p.theme.COLORS.borderColor};
	
	background-image: ${props =>
		props.isDarkMode ? `url(${lightEvent})` : `url(${darkEvent})`};

	background-position-x: center;

	@media only screen and (max-width: 900px) {
		background-position: center;
	}
`

export const SectionWrap = styled.div`
	width: 100%;
	align-items: center;
	max-width: 1280px;
	height: 100%;
	display: flex;
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: center;
	flex-direction: row;
	@media only screen and (max-width: 900px) {
		flex-direction: column;
		justify-content: space-evenly;
	}
`
export const HomeSliderSection = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
	background: ${COLORS.white};
	position: absolute;
	display: flex;
	margin-top: 520px;
	border-radius: 10px;
	padding-block: 20px;
`
