import BigNumber from 'bignumber.js'
import React from 'react'
import { useBalance } from '../../../../hooks/user/mybalance'
import { AssetSpotTableSection } from '../styles'
import AssetSpotTableHeader from './AssetSpotTableHeader'
import AssetSpotTableList from './AssetSpotTableList'

const AssetSpotTable = () => {
	const header = [
		'coin',
		'balance',
		'availableBalance',
		'inOrderWithdrawals',
		'action',
	]

	const { data:spot } = useBalance('USDT', 0)

	const data = [
		{
			coin: 'USDT',
			balance: spot?.balance,
			availableBalance: BigNumber(spot?.balance).minus(spot?.lockbalance).toNumber(),
			inOrderWithdrawals: 0,
		},
	]
	return (
		<AssetSpotTableSection>
			<AssetSpotTableHeader header={header} />
			<AssetSpotTableList data={data} />
		</AssetSpotTableSection>
	)
}

export default AssetSpotTable
