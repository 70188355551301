import React from 'react'
import { COLORS, FONTS } from 'app/constants'
import { View } from 'styles/reactnative'
import { RankTableText } from './styles'
import { useTranslation } from 'react-i18next'

const DailyRankHeader = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				borderTop: `1px solid ${COLORS.lightGray2}`,
				paddingBlock: 10,
			}}>
			<RankTableText
				style={{ ...FONTS.h6, width: '40%', color: COLORS.lightGray5 }}>
				{t('user')}
			</RankTableText>
			<RankTableText style={{ ...FONTS.h6, color: COLORS.lightGray5 }}>
				{t('pair')}
			</RankTableText>
			<RankTableText style={{ ...FONTS.h6, color: COLORS.lightGray5 }}>
				{t('activeDuration')}
			</RankTableText>
			<RankTableText style={{ ...FONTS.h6, color: COLORS.lightGray5 }}>
				{t('gridAPR')}
			</RankTableText>
			<RankTableText style={{ ...FONTS.h6, color: COLORS.lightGray5 }}>
				{t('action')}
			</RankTableText>
		</View>
	)
}

export default React.memo(DailyRankHeader)
