import styled from 'styled-components'

export const CopyTradeSettingWrapper = styled.div`
	width: 60%;
	height: 100%;
	overflow: hidden;
	padding-inline: 20px;
`
export const CopyTradeSettingHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 12%;
`
