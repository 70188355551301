import useSWR from 'swr'
import { useToken } from '../auth/token'
import API from '../../../utils/api'

export function useTradePosition() {
	const { data: token } = useToken()
	const KEYURL = '/trade/status'

	const { data, mutate } = useSWR(
		token ? KEYURL : null,
		async () => {
			const response = await API.get(KEYURL, token + '', {}, false)
			return response?.result?.result
		},
		{ revalidateOnFocus: true, refreshInterval: 60 * 1000 }
	)

	return {
		data: data?.open_positions,
		orderlist: data?.order,
		spot: data?.spot,
		futures: data?.futures,
		global: data?.global,
		mutate,
	}
}
