import React from 'react'

import { TradingListItemSection } from './styles'
import TradingListHeader from './TradingListHeader'
import TradingListsItems from './TradingListsItems'

const TradingListItem = ({ item, index }) => {
	return (
		<TradingListItemSection>
			<TradingListHeader item={item} index={index} />
			<TradingListsItems item={item} />
		</TradingListItemSection>
	)
}

export default TradingListItem
