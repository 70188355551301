import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IconSearch } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { ThemeContext } from 'theme/ThemeProvider'
import { HeaderInput, MHeaderSearchSection } from './styles'
import MobileSearch from '../MobileSearch'

const MHeaderSearch = () => {
	const { t } = useTranslation()
	const [openSearch, setOpenSearch] = useState(false)
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	const containerRef = useRef<HTMLInputElement>(null)

	const iconStyle = { marginRight: 10 }
	const Search = <IconSearch size={18} color={textw} style={iconStyle} />

	const handleOutsideClick = useCallback(
		event => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target)
			) {
				//	setOpenSearch(false)
			}
		},
		[containerRef]
	)

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick)
		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [handleOutsideClick])
	return (
		<MHeaderSearchSection ref={containerRef} style={{width:50}}>
			<HeaderInput style={{width:50}}>
				<CButton
					iconLeft={true}
					style={{
						flexDirection: 'row',
						color: textsilver,
						width: 50,
						height: '100%',
						justifyContent: 'flex-start',
					}}
					icon={Search}
					onClick={() => {
						setOpenSearch(true)
					}}
				/>
			</HeaderInput>

			{openSearch ? <MobileSearch setOpenSearch={setOpenSearch} /> : null}
		</MHeaderSearchSection>
	)
}

export default MHeaderSearch
