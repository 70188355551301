import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import { ProfileEditModalWrapper } from './styles'
import ProfileEditHeader from './ProfileEditHeader'
import ProfileEditBody from './ProfileEditBody'
// import LanguageModalHeader from './LanguageModalHeader'
// import LanguageModalBody from './LanguageModalBody'

const LanguageModal = ({ closeModal }: any) => {
	const { width: w, height } = useWindowDimensions()
	const wSize = w > 1400 ? w / 3.6 : w > 1200 ? w / 5 : w / 4

	return (
		<ProfileEditModalWrapper
			style={{ width: wSize, height: height / 3.6, minHeight: 230 }}>
			<ProfileEditHeader closeModal={closeModal} />
			<ProfileEditBody closeModal={closeModal} />
		</ProfileEditModalWrapper>
	)
}

export default LanguageModal
