import React, { useContext } from 'react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { AssetTotalValueRightSection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { useWindowDimensions } from 'app/hooks'

const AssetTotalValueRight = () => {
	const { width } = useWindowDimensions()
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	return (
		<AssetTotalValueRightSection>
			<CButton
				title={t('deposit')}
				onClick={() => {
					muatateModalStore({ ...modalStore, depositModal: true })
				}}
				border={{ width: '1px', color: borderColor }}
				style={{
					justifyContent: 'center',
					padding: 10,
					fontSize: width < 900 ? 10 : 12,
					color: COLORS.secondary,
					background: COLORS.white,
					fontWeight: '500',
					marginRight: 10,
				}}
			/>
			<CButton
				title={t('withdraw')}
				onClick={() => {
					muatateModalStore({
						...modalStore,
						withdrawModal: true,
					})
				}}
				style={{
					justifyContent: 'center',
					padding: 10,
					fontSize: width < 900 ? 10 : 12,
					color: COLORS.white,
					background: COLORS.secondary,
					fontWeight: '500',
					marginRight: 10,
				}}
			/>
			<CButton
				title={t('transfer')}
				onClick={() => {
					muatateModalStore({ ...modalStore, transferModal: true })
				}}
				style={{
					justifyContent: 'center',
					padding: 10,
					fontSize: width < 900 ? 10 : 12,
					color: COLORS.white,
					background: COLORS.secondary,
					fontWeight: '500',
				}}
			/>
		</AssetTotalValueRightSection>
	)
}

export default AssetTotalValueRight
