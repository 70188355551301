import React, { useContext } from 'react'
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import { data, getIntroOfPage } from '../chartData'
import { ThemeContext } from 'theme/ThemeProvider'

const CustomTooltip = ({ active, payload, label }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip" style={{ color: textw }}>
				<p className="label">{`${label} : ${payload[0].value}`}</p>
				<p className="intro">{getIntroOfPage(label)}</p>
				<p className="desc">Anything you want can be displayed here.</p>
			</div>
		)
	}
	return null
}
const StatisticProfitChart = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ width: '100%', height: '80%', marginTop: 20 }}>
			<ResponsiveContainer width={'100%'} height="100%">
				<BarChart
					width={600}
					height={250}
					data={data}
					style={{}}
					margin={{ top: 10, right: -25, left: -25, bottom: 0 }}>
					<defs>
						<linearGradient
							id="colorUv"
							x1="0"
							y1="0"
							x2="0"
							y2="1">
							<stop
								offset="5%"
								stopColor="#8884d8"
								stopOpacity={0.8}
							/>
							<stop
								offset="95%"
								stopColor="#8884d8"
								stopOpacity={0}
							/>
						</linearGradient>
						<linearGradient
							id="colorPv"
							x1="0"
							y1="0"
							x2="0"
							y2="1">
							<stop
								offset="5%"
								stopColor="#82ca9d"
								stopOpacity={0.8}
							/>
							<stop
								offset="95%"
								stopColor="#82ca9d"
								stopOpacity={0}
							/>
						</linearGradient>
					</defs>
					<YAxis
						style={{ fontSize: '1.1rem' }}
						stroke={COLORS.lightGray}
						tick={{ fill: textw, fontSize: 13 }}
					/>

					<XAxis
						dataKey="name"
						style={{ fontSize: '1.1rem' }}
						stroke={COLORS.lightGray}
						tick={{ fill: textw, fontSize: 13 }}
					/>
					<CartesianGrid strokeDasharray="3 " horizontal={true} />

					<Tooltip
						cursor={{
							stroke: COLORS.lightGray4,
							strokeWidth: 2,
							strokeDasharray: 2,
						}}
						separator={' '}
						itemStyle={{}}
						wrapperStyle={{}}
						contentStyle={{}}
						labelStyle={{}}
						label={'Hello world'}
						content={<CustomTooltip />}
					/>

					<Bar dataKey="pv" fill="#8884d8" />
					<Bar dataKey="uv" fill="#82ca9d" />
				</BarChart>
			</ResponsiveContainer>
		</View>
	)
}

export default React.memo(StatisticProfitChart)
// const res = Array(25).fill(0).map(Math.random);
