import React from 'react'
import CSwiper from 'app/components/Common/CSwiper'

import useWindowDimensions from 'app/hooks/useWindowDimensions'

import TradingListItem from './TradingListItem'
import { SectionContainer, SectionWrap } from '../../globalOrganismStyles'
import { SectionHeader } from 'app/components'

import { useFetcherPublic } from '../../../hooks/fetcher'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
// import { useTradeSocket }  from '../../../hooks/trade/tradeSocket'

const TradingListSection = () => {
	const { width } = useWindowDimensions()
	let { EPs } = useExchangeStore()

	const listData = EPs

	const data = [
		{
			title: 'hotCoins',
			list: listData,
		},
		{
			title: 'hotDerivatives',
			list: listData,
		},
		{
			title: 'leaderboard',
			list: listData,
		},
	]
	const renderHeader = () => (
		<SectionHeader title={'marketTrend'} goTo={'/trade/2'} />
	)
	const renderItem = (item: any, index: any) => (
		<TradingListItem item={item} index={index} />
	)
	return (
		<SectionContainer>
			<SectionWrap>
				<CSwiper
					data={data}
					renderItem={renderItem}
					slidesPerView={width > 1200 ? 3 : width > 900 ? 2 : 1}
					slidesPerGroup={width > 1200 ? 3 : width > 900 ? 2 : 1}
					SwiperHeader={renderHeader}
				/>
			</SectionWrap>
		</SectionContainer>
	)
}

export default React.memo(TradingListSection)
