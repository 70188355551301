import styled from 'styled-components'

export const SubFooterListSection = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 100px;
	width: 100%;
`
export const SubFooterLists = styled.div`
	padding-top: 50px;
	flex: 1;
	width: 100%;
	height: 100%;
	background-color: transparent;
	display: flex;
	justify-content: center;
	padding-left: 30px;
`
