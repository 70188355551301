import { CButton } from 'app/components/Common'
import { COLORS, FONTS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const MarketNestedBtn = ({ tabs, tab, setTab }) => {
	const { drawerBgColor, borderColor, header } =
		useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'flex-start',
				background: 'transparent',
				position: 'sticky',
				top: 0,
				zIndex: 1,
				backgroundColor: drawerBgColor,
				borderBottom: `0.5px solid ${borderColor}`,
				height: 70,
				paddingInline: 20,
			}}>
			{tabs.map((item: any, index: any) => (
				<CButton
					key={index.toString()}
					title={t(item)}
					onClick={() => {
						setTab(item)
					}}
					iconLeft={true}
					style={{
						...FONTS.h5,
						color: tab === item ? COLORS.white : COLORS.lightGray,
						fontWeight: tab === item ? '500' : '400',
						alignItems: 'center',
						justifyContent: 'center',
						paddingInline: 12,
						paddingBlock: 4,
						borderRadius: 10,
						backgroundColor: tab === item ? COLORS.primary : header,
						// borderTopRightRadius: 10,
						// borderTopLeftRadius: 10,
						zIndex: tab === item ? 1 : 0,
						marginRight: 10,
					}}
				/>
			))}
		</View>
	)
}

export default MarketNestedBtn
