import React from 'react'
import { useMobileDimensions } from 'app/hooks'

import NotiSettingDesktop from './NotiSettingDesktop'
import NotiSettingMobile from './NotiSettingMobile'
import { CToast } from 'app/components/Common'
import { MView } from 'styles/mobilePageStyles'

export function NotiSettingPage() {
	const { isMobile } = useMobileDimensions()

	return (
		<MView>
			<CToast />
			{isMobile ? <NotiSettingMobile /> : <NotiSettingDesktop />}
		</MView>
	)
}
