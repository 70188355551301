import useSWR from 'swr'
import API from '../../../utils/api'
import { EXCHANGESETTINGS } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'

let KEY: string | null = EXCHANGESETTINGS

let swrData: any = []

export function useExchangeSetting(ep_id: number) {
	KEY = `${EXCHANGESETTINGS}/${ep_id}`

	if (!ep_id) KEY = null

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData[ep_id]) return swrData[ep_id]

			const response = await API.get(KEY + '', '', {}, false)
			swrData[ep_id] = response.result
			return swrData[ep_id]
		},
		{ revalidateOnFocus: true, dedupingInterval: 0 }
	)

	return {
		data,
		mutate: (value: any) => {
			if (value !== undefined) swrData[ep_id] = value
			return mutate()
		},
		getData: (epid?: number) => {
			ep_id = epid ? epid : ep_id

			if (!swrData[ep_id]) {
				KEY = `${EXCHANGESETTINGS}/${ep_id}`
				mutate()
			}

			try {
				if (epid) {
					return swrData[epid]
				} else {
					return swrData[ep_id]
				}
			} catch (e) {
				return null
			}
		},
	}
}
