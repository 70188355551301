import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const SwiperContainer = styled.div`
	width: 105%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const StyledRoot = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100% !important;

	button {
		padding: 0;
		background: none;
		border: none;
	}
	.swiper {
		&-wrapper,
		&-container {
			width: 130rem;
			margin: 0;
		}
		&-container {
			margin: 0 3.2rem;
		}
		&-button-disabled {
			visibility: hidden;
		}
	}
`

export const StyledButton = styled.div`
	background-color: ${COLORS.BTN_HOVER};
	border-radius: 50px;
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
	margin-right: 5px;
	cursor: pointer;
`
export const SwiperHeaderSection = styled.div`
	background-color: transpareent;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100% !important;
`
export const ShadowSwiper = styled.div`
	background-color: ${COLORS.backgroundColor};
	opacity: 0.4;
	box-shadow: 10px 10px 30px ${COLORS.backgroundColor};
	z-index: 0;
	position: 'absolute';
`
