import useSWR from 'swr'
import { getPersistData } from '../persist'
import { useState } from 'react'

let swrData: any = getPersistData('LOCAL@LOGQUEUE') || []

let myip = ''

export function useLogQueue() {
	const { data, mutate } = useSWR<any>('LOCAL@LOGQUEUE', async () => {
		return swrData || []
	})

	return {
		myip,
		data,
		setIp: ip => {
			myip = ip
		},
		popLog: () => {
			try {
				if (swrData.length > 0) {
					const log = swrData.shift()
					mutate([...swrData])
					return log
				} else {
					return null
				}
			} catch (e) {
				return mutate()
			}
		},
		mutate: logdata => {
			try {
				if (logdata !== undefined) {
					swrData.push(logdata)
					return mutate([...swrData])
				} else {
					return mutate()
				}
			} catch (e) {
				return mutate()
			}
		},
	}
}
