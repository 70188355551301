import React from 'react'
import { Icon3dCubeSphere } from '@tabler/icons'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'

const TraderInfoHeader = () => {
	return (
		<View style={{ flexDirection: 'row', marginBottom: 20 }}>
			<Text size={26}>Trading-Mafia01</Text>
			<View style={{ flexDirection: 'row', marginLeft: 15 }}>
				<Icon3dCubeSphere color={COLORS.primary} size={20} />
				<Text size={16} style={{ marginLeft: 10 }}>
					Bronze
				</Text>
			</View>
		</View>
	)
}

export default TraderInfoHeader
