import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import { COLORS } from 'app/constants'

import { PercentageFormWrapper } from './styles'

const PercentageForm = ({ percentage, setPercentage, amount, setAmount }) => {
	const data = ['10', '25', '50', '75', '100']

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [originalAmount, setOriginalAmount] = useState(amount)

	const handlePercentClick = item => {
		if (item === percentage) {
			if (item === '100') {
				setAmount(originalAmount)
				setPercentage('100')
			}
			return
		}
		setPercentage(item)
		if (item < 100) {
			const newAmount = (originalAmount * item) / 100
			setAmount(newAmount)
		} else {
			setAmount(originalAmount)
		}
	}
	return (
		<PercentageFormWrapper>
			{data.map((item, index) => (
				<View
					onClick={() => handlePercentClick(item)}
					key={index.toString()}
					style={{
						background:
							item === percentage
								? COLORS.lightGray5
								: 'transparent',
						color:
							item === percentage
								? COLORS.white
								: COLORS.lightGray,
						cursor: 'pointer',
						padding: 5,
						fontSize: 12,
						width: '20%',
						borderRadius: 5,
					}}>
					{item}%
				</View>
			))}
		</PercentageFormWrapper>
	)
}

export default PercentageForm
