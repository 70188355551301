import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const NoData = ({ title, mt = '10px' }) => {
	const { t } = useTranslation()
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
				height: '100%',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: mt,
			}}>
			<Text size={14} style={{ color: textsilver }}>
				{t(title)}
			</Text>
		</View>
	)
}

export default NoData
