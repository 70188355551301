import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { View } from 'styles/reactnative'
import { CButton } from '../Common'

const AuthCheckBtns = () => {
	const { t } = useTranslation()
	const navigation = useHistory()
	return (
		<View style={{ width: '100%', alignItems: 'flex-start' }}>
			<CButton
				title={t('signUp')}
				onClick={() => {
					navigation.push('/signup')
				}}
				style={{
					background: COLORS.primary,
					color: COLORS.white,
					width: '100%',
					height: 35,
				}}
			/>
			<CButton
				title={t('login')}
				onClick={() => {
					navigation.push('/login')
				}}
				style={{
					background: COLORS.gray,
					color: COLORS.white,
					marginTop: 10,
					width: '100%',
					height: 35,
				}}
			/>
		</View>
	)
}

export default AuthCheckBtns
