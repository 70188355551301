import React from 'react'
import { FollowerOrdersSection, FollowerOrderWrapper } from './styles'

const FollowerOrders = () => {
	return (
		<FollowerOrdersSection style={{}}>
			<FollowerOrderWrapper></FollowerOrderWrapper>
		</FollowerOrdersSection>
	)
}

export default FollowerOrders
