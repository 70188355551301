import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const AccordionItemListSection = styled.div`
	color: green;
`
export const ListItems = styled.button`
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	outline: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	color: ${p => p.theme.COLORS.textw};
	&:hover {
		color: ${COLORS.white};
	}
`
