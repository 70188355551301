import React from 'react'

import { View } from 'styles/reactnative'
import HeaderBottomLists from './HeaderBottomLists'

const AssetSpotHeaderBottom = () => {
	const data = [
		{ title: 'equity', usd: '0.00', btc: '0.0' },
		{ title: 'walletBalance', usd: '0.00', btc: '0.0' },
		{ title: 'marginBalance', usd: '0.00', btc: '0.0' },
		{ title: 'availableBalance', usd: '0.00', btc: '0.0' },
		{ title: 'perpUpl', usd: '0.00', btc: '0.0' },
	]

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				height: '70%',
				flexWrap: 'nowrap',
			}}>
			{data.map((item, index) => (
				<HeaderBottomLists
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</View>
	)
}

export default React.memo(AssetSpotHeaderBottom)
