import styled from 'styled-components'
import ProofEventImage from 'assets/images/proof-reserves.png'
export const ProofOfReserveSection = styled.div`
	display: flex;
	flex-direction: column;
`

export const ProofEventSection = styled.div<{ isDarkMode?: any }>`
	background-color: transparent;
	background-image: url(${ProofEventImage});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	height: 320px;
	width: 100%;
	margin: auto;
	justify-content: center;
	align-items: center;
`

export const ProofHalf = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: auto;
	height: 100%;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
