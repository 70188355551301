/* eslint-disable prettier/prettier */
import { COLORS } from 'app/constants/STYLES'
import styled from 'styled-components'

export const TradeButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`

export const TradeButtonContent = styled.div<{ contentColor?: string }>`
	margin-left: 0.5rem;
	padding-right: 2rem;
	color: ${COLORS.lightGray4};
	font-size: 14px;
	white-space: nowrap;
	color: ${props => props.theme.COLORS.textw};
	${({ contentColor }) => contentColor && ` color:${contentColor}`}
`
