import styled from 'styled-components'

export const TransferFromToWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`
export const TransferCoinWrapper = styled.div`
	width: 100%;
	margin-top: 20px;
`
export const TransferStaticInput = styled.div`
	width: 100%;
	background: ${props => props.theme.COLORS.bgColor};
	padding: 12px;
	border-radius: 7px;
	margin-top: 7px;
	display: flex;
	align-items: flex-start;
`
export const TransferCoinListsWrapper = styled.div`
	position: absolute;
	width: 93%;
	top: 190px;
	left: 20px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 140px;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 999;
	border-radius: 5px;
	background-color: ${props => props.theme.COLORS.gray};
	box-shadow: 5px 5px 10px ${props => props.theme.COLORS.gray};
`
export const TransferAmountWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
`
export const TransferAmountAll = styled.div`
	position: absolute;
	right: 35px;
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`
export const TransferCoinListItems = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-block: 15px;
	padding-inline: 20px;
	cursor: pointer;
	&:hover {
		background-color: ${props => props.theme.COLORS.textsilver};
	}
`
