import React, { useContext, useEffect } from 'react'
import ProfileSecurityHeader from '../ProfileSettingsHeader'
import AccountInfoLists from '../AccountSecurityLists'
import AccountInfoNavBar from './AccountInfoNavBar'
import { IMAGES } from 'app/constants'

import { ProfileSecurities } from '../styles'
import { useTranslation } from 'react-i18next'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import { CModal } from '../../../../components/Common'
import { Modal } from 'antd'
import ProfileEditContainer from '../../../../mobile/organisms/ProfileSetting/ProfileSettingBody/MAccountSecurity/ProfileEditContainer'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import PasswordContainer from '../../../../mobile/organisms/ProfileSetting/ProfileSettingBody/MAccountSecurity/PasswordContainer'
import SmsAuthContainer from '../../../../mobile/organisms/ProfileSetting/ProfileSettingBody/MAccountSecurity/SmsAuthContainer'

const AccountInfo = () => {
	const { t } = useTranslation()
	const { data: myinfo, mutate } = useMyProfile()

	const [showModal, setShowModal] = React.useState(false)
	const [showModal2, setShowModal2] = React.useState(false)
	const [showModal3, setShowModal3] = React.useState(false)

	const { isDarkMode, theme } = useContext(ThemeContext)

	const verifiedCheck = myinfo?.level === 0 ? t('notverified') : t('verified')

	console.log(myinfo)

	useEffect(() => {
		mutate()
	}, [])

	const data = [
		{
			image: IMAGES.annotation,
			title: t('profilePicture'),
			content: t('notSetup'),
			desc: t('pleaseUploadAProfilePicture'),
			button: {
				title: 'settings',
				goTo: '',
				modelOpen: () => setShowModal(true),
			},
		},
		{
			image: IMAGES.atsign,
			title: t('emailAuthentication'),
			content: myinfo?.email,
			contentIconColor: myinfo?.email ? 'green' : 'red',
			contentColor: myinfo?.email ? 'green' : 'red',
			desc: t(
				'For Login, withdraw, password retrieval, security settings change and API management verification'
			),
			button: null,
		},
		{
			image: IMAGES.idcard,
			title: t('sMSAuthentication'),
			content: myinfo?.phone
				? (myinfo?.phone_code || '82') + ' ' + myinfo?.phone
				: t('notYetConfigured'),
			desc: t('forLoginPasswordReset'),
			contentIconColor: myinfo?.phone ? 'green' : 'red',
			contentColor: myinfo?.phone ? 'green' : 'red',
			button: {
				title: 'change',
				goTo: '',
				modelOpen: () => setShowModal3(true),
			},
		},
		{
			image: IMAGES.idcard,
			title: t('identityVerification'),
			content: `${verifiedCheck} LV.${myinfo?.level}`,
			contentIconColor: myinfo?.level == 0 ? 'red' : 'green',
			contentColor: myinfo?.level == 0 ? 'red' : 'green',
			desc: t('Complete verification to increase daily withdrawal limit'),
			button: null,
		},
		{
			image: IMAGES.lockalt,
			title: t('password'),
			content: t('settings'),
			button: {
				title: 'change',
				goTo: '',
				modelOpen: () => setShowModal2(true),
			},
		},
	]

	return (
		<ProfileSecurities>
			<ProfileSecurityHeader title={t('accountInfo')} />
			<AccountInfoNavBar />
			<AccountInfoLists data={data} />
			<CModal
				onClose={() => setShowModal(false)}
				visible={showModal}
				bgColor={theme.COLORS.bgColor}>
				<ProfileEditContainer />
			</CModal>
			<CModal
				onClose={() => setShowModal2(false)}
				visible={showModal2}
				bgColor={theme.COLORS.bgColor}>
				<PasswordContainer />
			</CModal>
			<CModal
				onClose={() => setShowModal3(false)}
				visible={showModal3}
				bgColor={theme.COLORS.bgColor}>
				<SmsAuthContainer setOpen={setShowModal3} refresh={mutate}/>
			</CModal>
		</ProfileSecurities>
	)
}

export default AccountInfo
