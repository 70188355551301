import React from 'react'
import InviteFriend from './InviteFriend'
import ReferralCodeDetail from './ReferralCodeDetail'
import ReferralCodeLink from './ReferralCodeLink'
import { ReferralCodeContainer, ReferralCodeSection } from './styles'
import { useFetcher } from '../../../../hooks/fetcher'

const ReferralCode = () => {
	const { data } = useFetcher('/referral/code', false)
	const referralCode = data?.result?.result?.code || ''
	const referralLink =
		process.env.REACT_APP_URL + '/signup?code=' + referralCode
	return (
		<ReferralCodeSection>
			<ReferralCodeContainer>
				<ReferralCodeDetail referralCode={referralCode} />
				<ReferralCodeLink referralLink={referralLink} />
			</ReferralCodeContainer>
			<InviteFriend referralLink={referralLink} />
		</ReferralCodeSection>
	)
}

export default ReferralCode
