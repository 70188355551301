import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AssetUSDTTableHeader = ({ header }) => {
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				height: 50,
				flexDirection: 'row',
				justifyContent: 'space-between',
				borderBottom: `1px solid ${borderColor}`,
			}}>
			{header?.map((item: any, index: number) => (
				<View
					key={index.toString()}
					style={{
						width: `${
							item === 'action' ? 20 : 100 / header?.length
						}%`,
					}}>
					<Text style={{ fontSize: 12 }}>{t(item)}</Text>
				</View>
			))}
		</View>
	)
}

export default AssetUSDTTableHeader
