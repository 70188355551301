import React, { useRef, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { CInput } from 'app/components/Common'
import TransferCoinLists from './TransferCoinLists'
import CustomCoinComp from './CustomCoinComp'
import { ThemeContext } from 'theme/ThemeProvider'

const TransferCoin = ({ coinType }) => {
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	const ref = useRef<HTMLInputElement>(null)
	const { t } = useTranslation()
	const [search] = useState('')
	const [display, setDisplay] = useState(false)
	const [alert, setAlert] = useState(false)
	const [coinDetail, setCoinDetail] = useState({})
	// const handleChange = (event: any) => {
	// 	setSearch(event.target.value)
	// }

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
			}}>
			<Text size={14}>{t('coin')}</Text>
			<CInput
				ref={ref}
				placeholder={''}
				value={search}
				type={'text'}
				vStyle={{ width: '100%' }}
				CustomComp={
					<CustomCoinComp
						coinType={coinType}
						setDisplay={setDisplay}
						setAlert={setAlert}
					/>
				}
				style={{
					flexDirection: 'row',
					background: gray,
					marginTop: 10,
					width: '100%',
					justifyContent: 'space-between',
					padding: 8,
					borderRadius: 5,
					cursor: 'pointer',
					border: 'none',
					color: textw,
				}}
			/>
			{display ? (
				<TransferCoinLists
					coinDetail={coinDetail}
					setCoinDetail={setCoinDetail}
					setDisplay={setDisplay}
				/>
			) : null}
			{alert ? (
				<View style={{ alignItems: 'flex-start' }}>
					<Text size={12} style={{ color: COLORS.lightRed }}>
						{t('transferCoinAlert')}
					</Text>
				</View>
			) : null}
		</View>
	)
}

export default TransferCoin
