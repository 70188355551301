import React from 'react'
import { View } from 'styles/reactnative'
import ProfileSettingBox from '../ProfileSettingBox'
import CurrencyBox from './CurrencyBox'
import DepositBox from './DepositBox'
import EmailBox from './EmailBox'
import LanguageBox from './LanguageBox'
import NotificationBox from './NotificationBox'
import { ProfileSettingContainerSection } from './styles'
import TimeZoneBox from './TimeZoneBox'
import TradeBox from './TradeBox'

const ProfileSettingContainer = () => {
	return (
		<ProfileSettingContainerSection>
			<View
				style={{
					flexDirection: 'row',
					width: '100%',
					height: '100%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
				}}>
				<ProfileSettingBox>
					<LanguageBox />
				</ProfileSettingBox>
				<ProfileSettingBox>
					<TimeZoneBox />
				</ProfileSettingBox>
				<ProfileSettingBox>
					<CurrencyBox />
				</ProfileSettingBox>
				<ProfileSettingBox>
					<NotificationBox />
				</ProfileSettingBox>
				<ProfileSettingBox>
					<TradeBox />
				</ProfileSettingBox>
				<ProfileSettingBox>
					<EmailBox />
				</ProfileSettingBox>
				<ProfileSettingBox>
					<DepositBox />
				</ProfileSettingBox>
			</View>
		</ProfileSettingContainerSection>
	)
}

export default ProfileSettingContainer
