import React from 'react'
import { View } from 'styles/reactnative'
import BidsSection from './BidsSection'
import AsksSection from './AsksSection'
import OrderBookHeader from './OrderBookHeader'
import OrderBookCurrentPrice from './OrderBookCurrentPrice'
import { useOrderBook } from '../../../../hooks/trade/orderBook'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import { trade } from '../../../../constants/UIData/HEADER'
import { group } from 'console'
// import useSWR from 'swr'
// import { FLASH_QUEUE } from '../../../../constants/LOCALKEYS'

const OrderBook = ({ filterTab, height = 500, selected }: any) => {
	const { data: tradeStore } = useTradeStore()
	const { data: orderBook } = useOrderBook(tradeStore?.ep_id)

	let { long, short } = orderBook || { long: [], short: [] }

	let sumAmount = new BigNumber(0)
	let max: BigNumber | number = new BigNumber(0)


	long = long?.map((jsonitem: any, index: number) => {
		const temp = jsonitem.split('|')
		const item = {
			price: new BigNumber(temp[0]).toNumber(),
			amount: new BigNumber(temp[1]).toNumber(),
			sumAmount: 0,
		}
		sumAmount = sumAmount.plus(item?.amount)
		item.sumAmount = sumAmount.toNumber()
		if (sumAmount > max) max = sumAmount
		return item
	})

	// 99999999 이상 제거
	long = long?.filter((item: any) => item.price < 99999999)

	//그룹핑
	let groupedList: any = []
	let currentGroup: any = null

	/*
	if (selected === 0) selected = 1
	for (const item of long) {
		const groupPrice = Math.floor(item.price / selected) * selected

		if (!currentGroup || groupPrice !== currentGroup.price) {
			currentGroup = {
				price: groupPrice,
				amount: item.amount,
				sumAmount: item.sumAmount,
			}
			groupedList.push(currentGroup)
		} else {
			currentGroup.amount += item.amount
			currentGroup.sumAmount += item.sumAmount || 0
		}
	}
	*/

	sumAmount = new BigNumber(0)
	groupedList = []
	currentGroup = null

	short = short
		?.reverse()
		?.map((jsonitem: any, index: number) => {
			const temp = jsonitem.split('|')
			const item = {
				price: new BigNumber(temp[0]).toNumber(),
				amount: new BigNumber(temp[1]).toNumber(),
				sumAmount: 0,
			}
			sumAmount = sumAmount.plus(item?.amount)
			item.sumAmount = sumAmount.toNumber()
			if (sumAmount > max) max = sumAmount
			return item
		})
		.reverse()
		
	// 99999999 이상 제거
	short = short?.filter((item: any) => item.price > 0.0001)

	/*
	for (const item of short) {
		const groupPrice = Math.ceil(item.price / selected) * selected

		if (!currentGroup || groupPrice !== currentGroup.price) {
			currentGroup = {
				price: groupPrice,
				amount: item.amount,
				sumAmount: item.sumAmount,
			}
			groupedList.push(currentGroup)
		} else {
			currentGroup.amount += item.amount
			currentGroup.sumAmount += item.sumAmount || 0
		}
	}

	short = groupedList

	*/
	max = max.toNumber()

	return (
		<View style={{ ...styleSize, justifyContent: 'flex-start' }}>
			<OrderBookHeader />

			<div style={{ width: '100%', height: height, overflowY: 'hidden' }}>
				{filterTab === 'one' ? (
					<View style={{ ...styleSize }}>
						<AsksSection
							asks={short}
							max={max}
							selected={selected}
						/>
						<OrderBookCurrentPrice />
						<BidsSection
							bids={long}
							max={max}
							selected={selected}
						/>
					</View>
				) : filterTab === 'two' ? (
					<View style={{ ...styleSize }}>
						<BidsSection
							bids={long}
							max={max}
							selected={selected}
						/>
					</View>
				) : (
					<View style={{ ...styleSize }}>
						<AsksSection
							asks={short}
							max={max}
							selected={selected}
						/>
					</View>
				)}
			</div>
		</View>
	)
}
const styleSize = {
	width: '100%',
	height: '100%',
}
export default OrderBook
