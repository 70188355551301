import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
const PerformanceDetailNavBar = () => {
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				justifyContent: 'space-between',
				width: '90%',
				alignItems: 'flex-start',
				alignSelf: 'center',
				paddingBlock: 15,
				borderBottom: `1px solid ${borderColor}`,
			}}>
			<View
				style={{
					flexDirection: 'row',
					width: '100%',
					padding: 10,
					paddingBottom: 20,
				}}>
				<View
					style={{
						cursor: 'pointer',
						width: '50%',
						alignItems: 'flex-start',
					}}>
					<Text size={16} style={{ marginBottom: 5 }}>
						180-{t('dayROI')}
					</Text>
					<Text size={22} style={{ color: COLORS.lightGreen }}>
						+9.93%
					</Text>
				</View>
				<View style={{ width: '50%', alignItems: 'flex-end' }}>
					<Text size={16} style={{ marginBottom: 5 }}>
						{t('mastersP&L')}
					</Text>
					<Text size={22} style={{ color: COLORS.lightGreen }}>
						+9.93%
					</Text>
				</View>
			</View>
			<View style={{ flexDirection: 'row', width: '100%', padding: 10 }}>
				<View style={{ width: '50%', alignItems: 'flex-start' }}>
					<Text size={16} style={{ marginBottom: 5 }}>
						{t('maxDrawdown')}
					</Text>
					<Text size={22}>26.61%</Text>
				</View>
				<View style={{ width: '50%', alignItems: 'flex-end' }}>
					<Text size={16} style={{ marginBottom: 5 }}>
						{t('followersP&L')}
					</Text>
					<Text size={22} style={{ color: COLORS.lightGreen }}>
						+9.93%
					</Text>
				</View>
			</View>
		</View>
	)
}

export default PerformanceDetailNavBar
