import styled from 'styled-components'
import bgDark from 'assets/images/abouts.png'
import bgLight from 'assets/images/abouts.png'
import appViewLight from 'assets/images/mobileAppView.png'
import appViewDark from 'assets/images/mobileAppView.png'

import Experience from 'assets/images/about-experience.png'

export const AboutWelcomeSection = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	height: 300px;
	background-image: url(${props => (props.isDarkMode ? bgLight : bgDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 50px;
`
export const MobileAppViewSection = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	height: 298px;
	background-image: url(${p => (p.isDarkMode ? appViewLight : appViewDark)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-top: 30px;
`

export const ExperienceCardSection = styled.div<{ isDarkMode?: any }>`
	background-color: transparent;
	background-image: url(${p => (p.isDarkMode ? Experience : Experience)});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-block: 40px;
`

export const AboutFounderContainer = styled.div`
	margin-top: 50px;
	width: 100%;
`
