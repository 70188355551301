import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MView } from 'styles/mobilePageStyles'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { ExperienceCardSection } from './styles'

const ExperienceCard = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { isDarkMode } = useContext(ThemeContext)
	const data = [
		{ title: 170, content: 'yearExperience' },
		{ title: 35, content: 'awardWin' },
		{ title: 1750, content: 'happyCustomers' },
		{ title: 120, content: 'ourExpertStaffs' },
	]
	return (
		<ExperienceCardSection isDarkMode={isDarkMode}>
			{data?.map((item, index) => (
				<View
					key={index.toString()}
					style={{
						border: `1px solid ${COLORS.white}`,
						height: 173,
						width: 240,
						paddingInline: 30,
						marginRight: 10,
						justifyContent: 'space-evenly',
						paddingBlock: 10,
						marginTop: 20,
						borderRadius: 3,
					}}>
					<Text size={40}>{item?.title}+</Text>
					<MView
						style={{
							width: '50%',
							height: 1.5,
							background: textw,
						}}
					/>
					<Text size={24}>{t(item?.content)}</Text>
				</View>
			))}
		</ExperienceCardSection>
	)
}

export default ExperienceCard
