import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import cryptocurrencies from 'assets/images/cryptocurrencies.png'
import trading from 'assets/images/trading.png'
import blueChip from 'assets/images/blue-chip.png'
import { CImage } from 'app/components/Common'

const AboutServiceCards = () => {
	const { t } = useTranslation()
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const data = [
		{
			title: 'spot',
			content: 'assets',
			count: 270,
			image: cryptocurrencies,
		},
		{
			title: 'perpetual',
			content: 'contracts',
			count: 200,
			image: trading,
		},
		{
			title: 'customerSupport',
			content: 'happyCustomers',
			count: '24 / 7',
			image: blueChip,
		},
		{
			title: 'countries',
			content: 'ourExpertStaffs',
			count: 160,
			image: cryptocurrencies,
		},
	]
	return (
		<View
			style={{
				width: '100%',
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				paddingInline: 5,
			}}>
			{data?.map((item, index) => (
				<View
					key={index.toString()}
					style={{
						background: drawerBgColor,
						height: 200,
						width: '48%',
						paddingInline: 10,
						justifyContent: 'space-between',
						paddingBlock: 30,
						marginBlock: 10,
					}}>
					<Text size={20}>{t(item?.title)}</Text>
					<CImage
						src={item?.image}
						style={{ height: 62, width: 62 }}
					/>
					<Text size={16} style={{ fontWeight: '400' }}>
						{item?.count} {'  '}
						{t(item?.content)}
					</Text>
				</View>
			))}
		</View>
	)
}

export default AboutServiceCards
