import React from 'react'
import { Text, View } from 'styles/reactnative'
import { HelpCenterListSection } from './styles'
import { useTranslation } from 'react-i18next'

const HelpCenterLists = ({ item, index, setReadDetail, setItemDetail }) => {
	const { t } = useTranslation()
	return (
		<HelpCenterListSection
			onClick={() => {
				setReadDetail(true)
				setItemDetail(item)
			}}>
			<View style={{ width: 60 }}>
				<Text>{item?.id}</Text>
			</View>
			<View>
				<Text>{t(item?.title)}</Text>
			</View>
		</HelpCenterListSection>
	)
}

export default HelpCenterLists
