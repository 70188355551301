import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ReferralTableListHeader = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	const headerLists = [
		'friendsAccount',
		'friendsId',
		'invitationTime',
		'totalUsdt',
		'futuresUsdt',
	]

	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				paddingBlock: 15,
			}}>
			{headerLists?.map((item, index) => (
				<View
					key={index.toString()}
					style={{
						width: `${100 / headerLists.length}%`,
					}}>
					<Text size={14} style={{ color: textw }}>
						{t(item)}
					</Text>
				</View>
			))}
		</View>
	)
}

export default ReferralTableListHeader
