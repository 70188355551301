import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import SpecialDerivativeItem from './SpecialDerivativeItem'

const MSpecialDerivative = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const data = [
		{
			title: 'announcementListTitle',
			content: 'announcementListContent1',
		},
		{
			title: 'announcementListTitle',
			content: 'announcementListContent2',
		},
		{
			title: 'announcementListTitle',
			content: 'announcementListContent3',
		},
	]
	return (
		<MView style={{ padding: 16, width: '100%' }}>
			<MView style={{ marginBottom: 20 }}>
				<MText style={{ color: textw, fontSize: 18 }}>
					{t('derivativesTradingSpecialList')}
				</MText>
			</MView>
			<MView style={{ width: '100%' }}>
				{data.map((item, index) => (
					<SpecialDerivativeItem
						item={item}
						index={index}
						key={index.toString()}
					/>
				))}
			</MView>
		</MView>
	)
}

export default MSpecialDerivative
