import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const AuthButtonLists = ({ title, go, action, style, textStyle }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const navigation = useHistory()
	const { t } = useTranslation()
	return (
		<CButton
			title={title && t(title)}
			onClick={() => {
				action && action()
				go && navigation.push(go)
			}}
			style={{
				fontWeight: '500',
				alignSelf: 'flex-start',
				color: textw,
				paddingBlock: 10,
				fontSize: 14,
				cursor: 'pointer',
				...style,
			}}
			textHover={COLORS.secondary}
		/>
	)
}

export default React.memo(AuthButtonLists)
