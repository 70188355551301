import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import React from 'react'
import { View } from 'styles/reactnative'
import CopyTraderDetail from './CopyTraderDetail'
import CopyTradeSetting from './CopyTradeSetting'

const CopyTradeModal = ({ selectedData, setShowModal }: any) => {
	const { width, height } = useWindowDimensions()
	const wSize =
		width < 772 ? width / 1.03 : width < 1400 ? width / 1.2 : width / 2
	return (
		<View
			style={{
				width: wSize,
				height: height / 1.5,
				borderRadius: 50,
				backgroundColor: COLORS.white,
				alignItems: 'flex-start',
				flexDirection: 'row',
			}}>
			<CopyTraderDetail selectedData={selectedData} />
			<CopyTradeSetting
				setShowModal={setShowModal}
				selectedData={selectedData}
			/>
		</View>
	)
}

export default CopyTradeModal
