import styled from 'styled-components'

export const AssetOverviewSection = styled.div`
	display: flex;
	flex-direction: column;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const AssetOverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	margin-top: 30px;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
	}
`
export const AssetOverviewTotal = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	height: 100%;
	justify-content: space-between;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		height: 50%;
	}
`
export const AssetOverviewTotalValueSection = styled.div`
	width: 100%;
	height: 30%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	background: ${p => p.theme.COLORS.drawerBgColor};
	@media only screen and (max-width: 1200px) {
		height: 40%;
	}
	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
`

export const MyAssetSection = styled.div`
	width: 100%;
	height: 69%;
	background: ${p => p.theme.COLORS.drawerBgColor};
	@media only screen and (max-width: 1200px) {
		height: 59%;
	}
`

export const RecentDepositSection = styled.div`
	width: 29%;
	height: 100%;

	background: ${p => p.theme.COLORS.drawerBgColor};
	@media only screen and (max-width: 1200px) {
		width: 100%;
		height: 49%;
	}
`

export const RecentDepositTableSection = styled.div`
	padding-inline: 10px;
	overflow-x: hidden;
	overflow-y: auto;
	height: 88%;
	width: 100%;
`
