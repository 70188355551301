import React, { useContext, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useTradeSetting } from '../../../../../hooks/trade/tradeSetting'
import useTradeStore from '../../../../../hooks/trade/tradeStore'
import { useBalance } from '../../../../../hooks/user/mybalance'

import { Slider } from 'antd'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { useIndexPrice } from '../../../../../hooks/trade/indexprice'
import { useExchangeSetting } from '../../../../../hooks/trade/exchangeSetting'
import { useTradePosition } from '../../../../../hooks/trade/tradePosition'
import { useMyProfile } from '../../../../../hooks/user/myprofile'
import { useTradeOrder } from '../../../../../hooks/trade/tradeOrder'

const PercentageForm = ({ percentage, setPercentage }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { data: store, mutate: mutateStore } = useTradeStore()
	const { data: settings } = useTradeSetting(store?.ep_id)
	const { data: setting, getData } = useExchangeSetting(store?.ep_id)

	const { fee } = useMyProfile()

	const maxfee = Math.max(fee.maker_fee, fee.taker_fee)

	const { data: position } = useTradePosition()

	const { data: orders} = useTradeOrder(store?.ep_id)

	const currency = store?.pairs?.split('/')[1]
	const { data: balance } = useBalance(currency, 1)

	const { getPrice } = useIndexPrice(store?.ep_id || 0)

	const availableBalance = new BigNumber(balance?.balance || 0)
		.minus(balance?.lockbalance || 0)
		.toNumber()

	let reversemax = 0

	const currentEPPos = position?.find(
		item => +item.exchange_pairs_id == +store?.ep_id
	)


	let max_amount_lev = 0
	// 최대수량 가져오기
	if (setting?.max_amount_lev) {
		let max_amount = setting?.max_amount_lev?.split(':')
		//1-800000000:20-10000000:60-500000:100-50000

		for (let i = 0; i < max_amount.length; i++) {
			let [lev, max] = max_amount[i].split('-')
			if (settings?.leverage >= +lev) {
				max_amount_lev = +max
			}
		}
		if (store?.reduce_only) {
			reversemax = new BigNumber(max_amount_lev)
				.dividedBy(settings?.leverage)
				.toNumber()
		} else {
			reversemax = new BigNumber(max_amount_lev)
				.dividedBy(settings?.leverage)
				.minus(currentEPPos?.margin || 0)
				.toNumber()
		}
	}
	console.log('reversemax', orders)

	let ordermarinsum = 0;

	if(orders && orders.length > 0){
		orders.map((item)=>{
			if(item?.reduce_position == 0){
				ordermarinsum += item?.margin
			}
		})
	
	}

	reversemax = reversemax - ordermarinsum


	let maxAmount = BigNumber(Math.min(availableBalance, reversemax))

	let calcprice = store?.order_price

	if (store?.limit_market === 'M') {
		calcprice = getPrice(store?.ep_id)
	}

	if (store?.main_sub != 'S') {
		maxAmount = maxAmount.multipliedBy(
			BigNumber(settings?.leverage).dividedBy(calcprice)
		)
	} else {
		maxAmount = maxAmount.multipliedBy(BigNumber(settings?.leverage))
	}

	const feeprice = BigNumber(store?.order_amount || 1)
		.multipliedBy(maxfee || 1)
		.dividedBy(100)
		.multipliedBy(settings?.leverage)

	maxAmount = maxAmount.minus(feeprice).minus(store?.main_sub != 'S'?0.00001:2)

	useEffect(() => {
		mutateStore({
			...store,
			order_amount: Math.max(
				+maxAmount
					.multipliedBy(+percentage)
					.dividedBy(100)
					.toFixed(5),
				0
			),
		})
	}, [
		store?.order_price,
		store?.reduce_only,
		currentEPPos,
		settings?.leverage,
	])
	return (
		<Slider
			marks={{
				0: {
					style: {
						color: textw,
					},
					label: <strong style={{ fontSize: 11 }}>{0}</strong>,
				},
				25: {
					style: {
						color: textw,
					},
					label: <strong style={{ fontSize: 11 }}>25%</strong>,
				},
				50: {
					style: {
						color: textw,
					},
					label: <strong style={{ fontSize: 11 }}>50%</strong>,
				},
				75: {
					style: {
						color: textw,
					},
					label: <strong style={{ fontSize: 11 }}>75%</strong>,
				},
				100: {
					style: {
						color: textw,
					},
					label: <strong style={{ fontSize: 11 }}>100%</strong>,
				},
			}}
			railStyle={{ background: textw }}
			value={percentage}
			onChange={value => {
				setPercentage(value)
				mutateStore({
					...store,
					order_amount: Math.max(
						+maxAmount
							.multipliedBy(+value)
							.dividedBy(100)
							.toFixed(5),
						0
					),
				})
			}}
		/>
	)
}

export default PercentageForm
