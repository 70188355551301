import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const NotificationHeaderSection = styled.div`
	background-color: ${p => p.theme.COLORS.borderColor};
	width: 100%;
	border-radius: 8px;
	padding: 10px;
	padding-block: 10px;
	position: sticky;
	top: 0;
	z-index: 1;
	display: flex;
	flex-direction: row;
	align-self: center;
	align-items: center;
	justify-content: space-between;
`

export const NotificationListItemSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	color: ${COLORS.black};
	padding-block: 10px;
	padding-inline: 5px;
	border-radius: 5px;
	&:hover {
		background-color: ${p => p.theme.COLORS.borderColor};
	}
`
export const HeaderNotificationSection = styled.div`
	padding: 20px;
	height: 100%;
	background-color: ${p => p.theme.COLORS.header};
`
