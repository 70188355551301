import React, { useState } from 'react'
import { UserFeedbackSection } from '../styles'
import UserFeedbackContainerTitle from './UserFeedbackContainerTitle'
import { View } from 'styles/reactnative'
import Satisfaction from './UserFeedbackForms/Satisfaction'
import FeedbackCategory from './UserFeedbackForms/FeedbackCategory'
import FeedbackDetail from './UserFeedbackForms/FeedbackDetail'
import FeedbackFooter from './UserFeedbackForms/FeedbackFooter'
import { UploadFile } from 'antd'
import { CButton } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { createFeedback } from 'app/ActionApi/feedback'

const UserFeedbackContainer = () => {
	const { t } = useTranslation()
	const [satisfaction, setSatisfaction] = useState(1)
	const [category, setCategory] = useState('safety')
	const [content, setContent] = useState('')
	const [images, setImages] = useState<UploadFile[]>([])

	const addFeedBack = async () => {
		const res = createFeedback(images, content, satisfaction, category)
		setSatisfaction(1)
		setCategory('safety')
		setContent('')
		setImages([])
	}
	return (
		<UserFeedbackSection>
			<UserFeedbackContainerTitle />
			<View
				style={{
					width: '100%',
					height: '85%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<Satisfaction
					satisfaction={satisfaction}
					setSatisfaction={setSatisfaction}
				/>
				<FeedbackCategory
					category={category}
					setCategory={setCategory}
				/>
				<FeedbackDetail content={content} setContent={setContent} />
				<FeedbackFooter images={images} setImages={setImages} />
				<CButton
					title={t('submit')}
					onClick={addFeedBack}
					style={{
						paddingInline: 30,
						background: COLORS.secondary,
						paddingBlock: 5,
						color: COLORS.white,
						borderRadius: 5,
					}}
				/>
			</View>
		</UserFeedbackSection>
	)
}

export default UserFeedbackContainer
