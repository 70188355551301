import { useWindowDimensions } from 'app/hooks'
import React from 'react'
import { Text } from 'styles/reactnative'
import { OrderContainerListSection, OrderListItems } from './styles'

const OrderContainerLists = ({ data }) => {
	const { width } = useWindowDimensions()
	const fSize = width < 1200 ? 10 : 12
	return (
		<OrderContainerListSection>
			{data?.map((item: any, index: number) => (
				<OrderListItems
					key={index.toString()}
					style={{ width: '100%' }}>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.contracts ? item?.contracts : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.exitType ? item?.exitType : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.filledTotal ? item?.filledTotal : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.tradingFees ? item?.tradingFees : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.filledPrice ? item?.filledPrice : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.orderPrice ? item?.orderPrice : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.tradeType ? item?.tradeType : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.orderType ? item?.orderType : '-'}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.tradeNo}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.orderNo}
					</Text>
					<Text
						style={{
							width: `${100 / data.length}%`,
							fontSize: fSize,
						}}>
						{item?.orderTime}
					</Text>
				</OrderListItems>
			))}
		</OrderContainerListSection>
	)
}

export default OrderContainerLists
