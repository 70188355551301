import React, { useContext } from 'react'
import { VerticalTimelineElement } from 'react-vertical-timeline-component'
import { IconPoint } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const StatisticTimeline = ({ item, index }: any) => {
	const { bgColor, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<VerticalTimelineElement
			position={'left'}
			className="vertical-timeline-element--work"
			contentStyle={{
				background: bgColor,
				color: textw,
				marginTop: -40,
				marginLeft: 40,
				boxShadow: 'none',
				marginBottom: -15,
			}}
			iconStyle={{
				background: textw,
				color: textw,
				width: 10,
				height: 10,
				borderWidth: 0,
				marginRight: -20,
				left: 15,
				top: 15,
			}}
			icon={<IconPoint />}>
			<View
				style={{
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				}}>
				<View style={{ flexDirection: 'row' }}>
					<View
						style={{
							paddingBlock: 2,
							paddingInline: 5,
							background: COLORS.lightGray,
							marginRight: 5,
							borderRadius: 3,
						}}>
						<Text size={16}>Open</Text>
					</View>
					<Text style={{ marginInline: 5 }}>ETHUSDT</Text>
					<View
						style={{
							paddingBlock: 2,
							paddingInline: 5,
							background: 'rgba(32,178,108,.08)',
							marginRight: 10,
							borderRadius: 3,
						}}>
						<Text size={12} style={{ color: COLORS.lightGreen }}>
							Long 10x
						</Text>
					</View>
				</View>
				<View style={{ flexDirection: 'row', marginTop: 10 }}>
					<Text size={14} style={{ marginRight: 5 }}>
						Entry Price
					</Text>
					<Text
						size={14}
						style={{
							fontWeight: '600',
							marginRight: 5,
						}}>
						1,651.61 USDT
					</Text>
					<Text size={14} style={{ marginRight: 5 }}>
						Quantity
					</Text>
					<Text
						size={14}
						style={{
							fontWeight: '600',
							marginRight: 5,
						}}>
						0.021 BTC
					</Text>
					<Text size={14} style={{ marginRight: 5 }}>
						| 36
					</Text>
				</View>
			</View>
		</VerticalTimelineElement>
	)
}

export default React.memo(StatisticTimeline)
