import React, { useContext } from 'react'
import { CDivider } from 'app/components/Common'
import { View } from 'styles/reactnative'
import StatisticEarning from './StatisticEarning'
import StatisticHistory from './StatisticHistory'
import StatisticProfit from './StatisticProfit'
import StatisticTimeline from './StatisticTimeline'
import { ThemeContext } from 'theme/ThemeProvider'

const Statistics = () => {
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ padding: 20 }}>
			<StatisticEarning />
			<CDivider
				style={{ width: '100%', paddingBlock: 30 }}
				color={borderColor}
			/>
			<StatisticProfit />
			<CDivider
				style={{ width: '100%', paddingBlock: 30 }}
				color={borderColor}
			/>
			<StatisticHistory />
			<StatisticTimeline />
		</View>
	)
}

export default Statistics
