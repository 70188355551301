import { createGlobalStyle } from 'styled-components'

export const MainColor = '#FF445C'
export const MainColorText = '#ffffff'

export const GlobalStyle = createGlobalStyle`
  html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  width: 100vw;
  line-height: 1;
  font-family: 'Roboto';
}
ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
}
a:hover,
a:active {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: #001B43;
}

::-webkit-scrollbar-thumb {
  background: #744Ff8;
  border-radius: 0px;
  height: 10px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  background: #999;
}

* { font-family:'Spoqa Han Sans Neo','Roboto', 'sans-serif'; }

/* prevent bouncing */
body {
  min-height: 100vh;
  overscroll-behavior: none; /* or overscroll-behavior: none; */
}

body > #root > div {
  min-height: 100vh;
}

html {
  font-size: 62.5%;
}

*:focus {
  outline: none;
}

input[type=checkbox]{
  background:transparent;
}


.warning{
  color:red;
  font-size:13px;
  padding:10px 5px;
}

.modalConfirm {
  font-size: 14px;
}

.modalConfirm h1{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #99f;
}
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}



[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 1005 !important;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

@media (max-width: 1400px) {


}


@keyframes flashgreen {
  20% {
    background-color: rgba(0,0,255,0.5);
  }
}


@keyframes flashred {
  20% {
    background-color: rgba(255,0,0,0.5);
  }
}


.tabledark{
	& .ant-card {
		background: #404040 !important;
		border: 0;
		color: #ffffff;
		border-radius: 0;
	}
	& .ant-tag{
		color: #ffffff;

	}

	& .ant-table {
		border-radius: 0;
		background: #000000 !important;
		color: #ffffff;
	}

	& .ant-table-container {
		border-start-start-radius: 0 !important;
		border-start-end-radius: 0 !important;
		background: transparent !important;
	}

	& .ant-table-header {
		border-radius: 0 !important;
		padding: 0;
		background: transparent !important;
	}
	& .ant-table-header::before {
		width: 0 !important;
	}

	& .ant-table-thead .ant-table-cell {
		border-radius: 0 !important;
		padding: 0;
		background: transparent !important;
		border-bottom: 1px solid #505050 !important;
		color: #ffffff;
		border: 0;
		box-shadow: none !important;
	}

	& .ant-table-header .ant-table-cell {
		border-radius: 0 !important;
		padding: 0;
		background: transparent !important;
		color: #ffffff;
		border: 0;
		box-shadow: none !important;
	}
	& .ant-table-thead .ant-table-cell::before {
		width: 0 !important;
	}

	& .ant-table-header .ant-table-cell::before {
		width: 0 !important;
	}

	& .ant-table-body .ant-table-cell {
		background: #000000 !important;
		color: #ffffff;
		border-bottom: 1px solid #505050 !important;
	}

	& .ant-table-row:hover .ant-table-cell {
		background: #000000 !important;
		color: #ffffff;
	}

	& .ant-table-footer {
		background: #000000 !important;
		color: #ffffff;
		border-radius: 0;
	}
	& .ant-pagination .ant-pagination-item a {
		background: #000000 !important;
		color: #ffffff;
	}
	& .ant-pagination li button {
		background: #000000 !important;
		color: #ffffff !important;
	}
}

.triggerdark{
	.ant-select-selection-item{
		color: #ffffff !important;
	}
}

.chartselectdart{
	& .ant-select-selector{
		background-color: #000000 !important;
		color: #ffffff !important;
	}
}
.chartselectlight{
	& .ant-select-selector{
		background-color: #000000 !important;
		color: #ffffff !important;
	}
}

.ovlist input{
	color: #000000;
}

.ovlist.light input{
	color: #ffffff !important;
}



`
