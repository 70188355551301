import styled from 'styled-components'
import referralRegister from 'assets/images/referralRegister.png'
export const RefferalSection = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
export const ReferralTableSection = styled.div`
	width: 100%;
	min-height: 300px;
	margin-block: 30px;
	border-radius: 15px;
`
export const ReferralEventSection = styled.div`
	height: 570px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
export const ReferralRegisterSection = styled.div`
	height: 178px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-inline: 50px;
	background-image: url(${referralRegister});
	background-size: cover;
	border-radius: 15px;
	@media only screen and (max-width: 900px) {
		padding-inline: 20px;
		flex-direction: column;
		align-items: flex-start;
		height: 100px;
	}
	@media only screen and (max-width: 600px) {
		padding-inline: 10px;
		padding-block: 10px;
		width: 95%;
		align-self: center;
		align-items: center;
		margin: auto;
	}
`
