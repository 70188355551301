import React from 'react'
import { IMAGES } from 'app/constants'
import ProfileSecurityHeader from '../ProfileSettingsHeader'
import AccountInfoLists from '../AccountSecurityLists'
import { ProfileSecurities } from '../styles'
import { useTranslation } from 'react-i18next'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import { useToken } from '../../../../hooks'
import API from '../../../../../utils/api'
import { message } from 'antd'

const AdvancedProtection = () => {
	const { t } = useTranslation()
	const { data: myinfo, mutate } = useMyProfile()
	const { data: token } = useToken()

	const data = [
		{
			image: IMAGES.shieldcheck,
			title: t('twoFactorAuthentication'),
			content: t('settings')+ ' : ' + t(myinfo?.twofa == 1 ? 'ON' : 'OFF'),
			desc: t('forLoginPasswordResetAndApiManagement'),
			button: {
				title: myinfo?.twofa == 1 ? 'disable' : 'enable',
				goTo: '',
				modelOpen: () => {
					API.put('/user/my/info', token + '', {
						two: myinfo?.twofa == 1 ? 0 : 1,
					}).then(res => {
						message.success(t('success'))
						mutate()
					})
				},
			},
		},
		/*
		{
			image: IMAGES.avatar,
			title: t('googleTwoFactorAuthentication'),
			content: t('settings'),
			desc: t('forLoginPasswordResetAndApiManagement'),
			button: {
				title: 'disable',
				goTo: '',
				modelOpen: '',
			},
		},
		{
			image: IMAGES.avatar,
			title: t('antiphishingCode'),
			content: t('notYetConfigured'),
			desc: t('afterSuccessfullyAntiPhishingCode'),
			button: {
				title: 'settings',
				goTo: '',
				modelOpen: '',
			},
		},
		{
			image: IMAGES.avatar,
			title: t('yubiKeyAuthentication'),
			content: t('notSetup'),
			desc: t('protectYourAccountWithYubiKey'),
			button: {
				title: 'settings',
				goTo: '',
				modelOpen: '',
			},
		},*/
	]

	return (
		<ProfileSecurities>
			<ProfileSecurityHeader title={t('Advanced Protection')} />
			<AccountInfoLists data={data} />
		</ProfileSecurities>
	)
}

export default AdvancedProtection
