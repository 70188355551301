import styled from 'styled-components'

export const ExploreProductItemSection = styled.div`
	display: flex;
	flex-direction: row;
	height: 240px;
	width: 240px;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 50%;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		box-shadow: 2px 3px 20px #666;
	}

	@media (max-width: 768px) {
		width: 200px;
		height: 200px;
	}
`
export const ExploreProductContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
