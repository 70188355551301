import { message } from 'antd'
import { anyTruthy } from 'utils/ExpensiveFunction'
import API from 'utils/api'
import { awsUploadImage } from 'utils/awsImageStorage'

export const createFeedback = async (
	images: any,
	content: any,
	satisfaction: any,
	category: any
) => {
	if (anyTruthy(content)) {
		if (images.length <= 4) {
			let resultImages: any = []
			if (images?.length) {
				resultImages = await Promise.all(
					images.map(async (file, index) => {
						const resultImage = await awsUploadImage(
							{ file: file?.originFileObj },
							index + 1,
							'profile',
							'user'
						)
						return resultImage
					})
				)
			}
			console.log(resultImages, content, satisfaction, category)
			// const res: any = API.send('POST', '/feedback', null, {
			// 	images: resultImages,
			// 	content: content,
			// 	satisfaction: satisfaction,
			// 	category: category,
			// })
			// if (res?.result?.success) {
			// 	console.log(res)
			// } else {
			// 	console.log('ERROR: send feedback')
			// }
		} else {
			message.info('이메지 4 너무면 안 됩니다.')
		}
	} else {
		message.info('내용을 추가해야 함')
	}
}
