import React, { useContext } from 'react'
import { MView } from '../../../../../styles/mobilePageStyles'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { Text, View } from '../../../../../styles/reactnative'
import { useTranslation } from 'react-i18next'
import { COLORS, FONTS } from '../../../../constants'
import OrderItem from './OrderItem'
import { useExchangeSetting } from '../../../../hooks/trade/exchangeSetting'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import OrderItemHeader from './OrderItemHeader'
import BigNumber from 'bignumber.js'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import ClosedBy from '../../../../organisms/Trade/TradeDetail/TradePositionsTable/closedBy'

const MOrderPositionItems = ({ item }) => {
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { getData } = useExchangeSetting(item?.exchange_pairs_id)
	const EP = getData(item?.exchange_pairs_id)
	let { getPrice } = useIndexPrice(item?.exchange_pairs_id)
	const currency = EP?.pairs?.split('/')[1]

	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	const openModal = () => {
		muatateModalStore({
			...modalStore,
			positionClose: {
				close: true,
				item,
			},
		})
	}

	let pnl = 0
	const indexPrice = getPrice(item?.exchange_pairs_id)

	if (item?.long_short === 'L') {
		pnl = BigNumber(indexPrice)
			.minus(item?.price)
			.multipliedBy(item?.amount)
			.multipliedBy(item?.leverage)
			.toNumber()
	} else {
		pnl = BigNumber(item?.price)
			.minus(indexPrice)
			.multipliedBy(item?.amount)
			.multipliedBy(item?.leverage)
			.toNumber()
	}
	const longShort = item?.long_short === 'L' ? 'long' : 'short'
	const lsColor =
		item?.long_short === 'L' ? COLORS.lightGreen : COLORS.lightRed
	return (
		<MView
			style={{
				alignSelf: 'center',
				marginTop: 10,
				borderRadius: 10,
				paddingBottom: 10,
				padding: 10,
				backgroundColor: THEMECOLORS.bgColor,
				width: '100%',
			}}>
			<MView style={{ paddingTop: 10, width: '100%' }}>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<MView style={{ flexDirection: 'row' }}>
						<Text style={{ ...FONTS.h6, color: THEMECOLORS.textw }}>
							{t(item?.title)}
						</Text>
					</MView>
					<MView>
						<Text
							style={{
								...FONTS.h6,
								color: THEMECOLORS.textsilver,
							}}>
							{item?.content}
						</Text>
					</MView>
				</MView>
				<MView style={{ padding: 10, width: '100%' }}>
					<OrderItemHeader
						pairs={EP?.pairs}
						longShort={longShort}
						lsColor={lsColor}
					/>
					<OrderItem
						title={'positionSize'}
						content={new BigNumber(item?.amount)
							.multipliedBy(item?.leverage)
							.toNumber()}
						contentColor={lsColor}
					/>

					<OrderItem
						title={'entryPrice'}
						content={`${BigNumber(item?.price).toFormat(3)} ${
							EP?.pairs.split('/')[0]
						}`}
					/>
					<OrderItem
						title={'markPrice'}
						content={`${BigNumber(indexPrice).toFormat(3)} ${
							EP?.pairs.split('/')[0]
						}`}
					/>
					<OrderItem
						title={'estimatedLiqPrice'}
						contentColor={
							pnl > 0
								? COLORS.lightGreen
								: pnl < 0
								? COLORS.lightRed
								: THEMECOLORS.textw
						}
						content={`${item?.liquid_price?.toFixed(2)} ${
							EP?.pairs.split('/')[1]
						}`}
					/>
					<OrderItem title={'margin'} content={item?.margin} />
					<OrderItem
						title={'unrealized'}
						contentColor={
							pnl > 0
								? COLORS.lightGreen
								: pnl < 0
								? COLORS.lightRed
								: THEMECOLORS.textw
						}
						content={`${pnl > 0 ? '+' : ''}${pnl?.toFixed(
							2
						)}${currency}(${((pnl / item?.margin) * 100).toFixed(
							2
						)}%)`}
					/>
					<OrderItem
						title={'realized'}
						contentColor={
							item?.pnl_price > 0
								? COLORS.lightGreen
								: COLORS.lightRed
						}
						content={item?.pnl_price?.toFixed(2)}
					/>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							padding: 10,
						}}>
						<ClosedBy
							item={item}
							closeAll={false}
							isMobile={true}
							setCloseAll={() => {}}
						/>
					</View>
				</MView>
			</MView>
		</MView>
	)
}

export default MOrderPositionItems
