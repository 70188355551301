import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'

import { EventHalf, OtherEventSection } from './styles'

const OtherEvents = ({ title }) => {
	const { t } = useTranslation()
	return (
		<OtherEventSection>
			<EventHalf>
				<Text
					style={{
						justifyContent: 'flex-start',
						fontSize: 30,
						color: COLORS.white,
					}}>
					{t(title)}
				</Text>
			</EventHalf>
		</OtherEventSection>
	)
}

export default OtherEvents
