import React, { useContext } from 'react'
import { CoinLogo } from 'app/components'
import mobilelogo from 'assets/icons/logo.png'
import logo from 'assets/icons/logo.png'
import { CButton } from 'app/components/Common'
import { HeaderSection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { IconBell, IconUserCircle } from '@tabler/icons'
import MHeaderSearch from './MHeaderSearch'
import { useHistory } from 'react-router-dom'
import { goTo } from 'app/hooks/navigation'
import DarkModeToggle from '../../../organisms/Header/DarkModeToggle/mobile'
import { View } from '../../../../styles/reactnative'
import { Button, Space } from 'antd'
import { useToken } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { GLOBAL_URL } from '../../../constants/APIKEYS'

const Header = ({
	showSelect = false,
	setShowSelect = e => {
		//navigation.push('/?skip=true')
	},
}) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const navigation = useHistory()
	const { t } = useTranslation()
	const { data: token } = useToken()

	return (
		<HeaderSection isDarkMode={isDarkMode}>
			<CoinLogo
				src={
					!isDarkMode
						? '/images/darkLogo.png'
						: '/images/lightLogo.png'
				}
				style={{ marginRight: 5, width: 'auto' }}
				onClick={() => {
					setShowSelect(true)
					//navigation.push('/?skip=true')
				}}
			/>

			<View
				style={{
					flex: 1,
					flexDirection: 'row',
					justifyContent: 'flex-start',
				}}>
				<Space.Compact size="small">
					<Button
						type="primary"
						size="large"
						onClick={() => {
							window.location.href = '/'
							setShowSelect(false)
						}}
						style={{
							background: !isDarkMode ? '#2675AE' : '#2675AE',
							fontSize: 12,
						}}>
						{t('COINFUTURES2')}
					</Button>
					<Button
						type="default"
						size="large"
						style={{
							background: !isDarkMode ? '#121212' : '#f6f6f6',
							color: !isDarkMode ? '#AEAEAE' : '#828282',
							border: '1px solid #AEAEAE',
							fontSize: 12,
						}}
						onClick={() => {
							window.location.href =
								GLOBAL_URL +
								'/login?' +
								(token ? 'ssotoken=' + token : 'skip=true') +
								'&darkmode=' +
								(isDarkMode ? 'dark' : 'light')
						}}>
						{t('GLOBAL2')}
					</Button>
				</Space.Compact>
			</View>

			<MHeaderSearch />
			<DarkModeToggle />
			<CButton
				onClick={() => {
					goTo(navigation, '/notifications')
				}}
				icon={
					<IconBell
						size={20}
						color={textw}
						style={{ marginRight: 10 }}
					/>
				}
			/>
			<CButton
				onClick={() => {
					goTo(navigation, '/settings?tab=1')
				}}
				icon={<IconUserCircle size={20} color={textw} />}
			/>
		</HeaderSection>
	)
}

export default Header
