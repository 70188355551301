import React from 'react'
import CopyTradeSettingBody from './CopyTradeSettingBody'
import CopyTradeSettingFooter from './CopyTradeSettingFooter'
import CopyTradeSettingHeader from './CopyTradeSettingHeader'
import { CopyTradeSettingWrapper } from './styles'
interface IProps {
	setShowModal?: any
	selectedData?: any
}
const CopyTradeSetting: React.FC<IProps> = ({ setShowModal, selectedData }) => {
	return (
		<CopyTradeSettingWrapper>
			<CopyTradeSettingHeader setShowModal={setShowModal} />
			<CopyTradeSettingBody />
			<CopyTradeSettingFooter />
		</CopyTradeSettingWrapper>
	)
}

export default CopyTradeSetting
