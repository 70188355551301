import React, { useContext } from 'react'
import { IMAGES } from 'app/constants'
import ProfileSecurityHeader from '../ProfileSettingsHeader'
import AccountInfoLists from '../AccountSecurityLists'
import { ProfileSecurities } from '../styles'
import { useTranslation } from 'react-i18next'
import { useFetcher } from '../../../../hooks/fetcher'
import { useTimezone } from '../../../../hooks/timezone'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { CModal } from '../../../../components/Common'
import TableAPI from '../../../../components/TableAPI'
import KrwPrice from '../../../../components/KrwPrice'
import BankInfo from '../../../../components/BankInfo'
import EntryTime from '../../../../components/EntryTime'
import { Modal } from 'antd'
import { Text } from '../../../../../styles/reactnative'

const AccountActivities = () => {
	const { t } = useTranslation()
	const { COLORS } = useContext(ThemeContext).theme
	const [showModal, setShowModal] = React.useState(false)

	const { data: res } = useFetcher('/user/my/recenthistory', true)

	const last = res?.result?.result?.[0]

	console.log(last)

	const data = [
		{
			image: IMAGES.userlockalt1,
			title: t('accountActivities'),
			content: t('accountAbnormalDeactivate'),
			desc: useTimezone(last?.created_at) + ' ' + t(last?.log),
			button: {
				title: 'more',
				goTo: '',
				modelOpen: () => setShowModal(true),
			},
		},
	]

	return (
		<ProfileSecurities>
			<ProfileSecurityHeader title={t('accountActivities')} />
			<AccountInfoLists data={data} />
			<Modal
				open={showModal}
				centered={true}
				width={1200}
				onCancel={() => setShowModal(false)}>
				<TableAPI
					defaultMaxData={50}
					stylewidth={'100%'}
					height={650}
					width={500}
					columns={() => [
						{
							title: '일자',
							dataIndex: 'created_at',
							align: 'center',
							render: (text, record) => {
								return (
									<EntryTime
										time={record?.created_at}
										style={{ color: 'black' }}
										offset={9}
									/>
								)
							},
						},
						{
							title: '내용',
							dataIndex: 'log',
							align: 'center',
							render: (text, record) => {
								return <Text style={{color:'black'}}>{t(text)}</Text>
							},
						},
						{
							title: '아이피',
							dataIndex: 'ip',
							align: 'center',
							render: (text, record) => {
								return <Text style={{color:'black'}}>{text !='undefined' ? text : ''}</Text>
							},
						},
					]}
					apikey={'/user/my/recenthistory?t='}
				/>
			</Modal>
		</ProfileSecurities>
	)
}

export default AccountActivities
