import { useWindowDimensions } from 'app/hooks'
import MCoinLists from 'app/mobile/components/MCoinLists'
import React from 'react'
import { MView } from 'styles/mobilePageStyles'

const QuickWatchLists = ({ data }) => {
	const { width } = useWindowDimensions()
	return (
		<MView
			style={{
				width: '95%',
				height: width < 420 ? 350 : 500,
				marginTop: 5,
			}}>
			<MCoinLists data={data} noData={'noCoins'} />
		</MView>
	)
}

export default QuickWatchLists
