import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import { useWSize } from 'utils/ExpensiveFunction'
import { ThemeContext } from 'theme/ThemeProvider'
import WithdrawLeftSection from './WithdrawLeftSection'
import WithdrawRightSection from './WithdrawRightSection'

const WithdrawModal = ({ setShowModal }) => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				width: useWSize(),
				height: 550,
				borderRadius: 5,
				backgroundColor: drawerBgColor,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={'withdraw'}
				closeModal={() => setShowModal(false)}
			/>
			<View
				style={{
					width: '100%',
					height: '100%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexDirection: 'row',
				}}>
				<WithdrawLeftSection setShowModal={setShowModal}/>
				<WithdrawRightSection />
			</View>
		</View>
	)
}

export default React.memo(WithdrawModal)
