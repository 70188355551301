import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Text } from 'styles/reactnative'

const CopyTradeLeftSide = () => {
	const { t } = useTranslation()
	return (
		<Column alignItems={'flex-start'}>
			<Text
				size={30}
				style={{
					color: COLORS.white,
					lineHeight: 1.3,
				}}>
				{t('discoverTopTraders')}
			</Text>
		</Column>
	)
}

export default CopyTradeLeftSide
