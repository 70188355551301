import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import RickWarningText from './RickWarningText'
import RicketWarningContext from './RicketWarningContext'
const MRiskWarning = () => {
	const { t } = useTranslation()
	return (
		<MView style={{ paddingInline: 16, marginBottom: 100 }}>
			<RickWarningText
				title={'mobileRiskWarningTitle1'}
				content={'mobileRiskWarningContent1'}
			/>
			<RickWarningText
				title={'mobileRiskWarningTitle2'}
				content={'mobileRiskWarningContent2'}
			/>
			<MView style={{ marginBlock: 20 }}>
				<MView>
					<MText
						style={{
							fontSize: 16,
							fontWeight: '500',
							lineHeight: 1.5,
						}}>
						{t('mobileRiskWarningTitle3')}
					</MText>
				</MView>
				<RicketWarningContext text={'mobileRiskWarningContent3-1'} />
				<RicketWarningContext text={'mobileRiskWarningContent3-2'} />
				<RicketWarningContext text={'mobileRiskWarningContent3-3'} />
				<RicketWarningContext text={'mobileRiskWarningContent3-4'} />
				<RicketWarningContext text={'mobileRiskWarningContent3-5'} />
			</MView>
		</MView>
	)
}

export default MRiskWarning
