import React from 'react'
import { useTranslation } from 'react-i18next'
import { CImage } from 'app/components/Common/'
import { COLORS } from 'app/constants/STYLES'
import { Text, View } from 'styles/reactnative'

import { ExploreProductItemSection, ExploreProductContent } from './styles'

const ExploreProductItem = ({ item, index }: any) => {
	const { t } = useTranslation()

	let bgColor = '#7663FC'
	if (index === 0) {
		bgColor = '#7663FC'
	} else if (index === 1) {
		bgColor = '#00DBF9'
	} else if (index === 2) {
		bgColor = '#0095FF'
	} else {
		bgColor = '#7663FC'
	}
	return (
		<ExploreProductItemSection
			style={{
				background: bgColor,
			}}>
			<ExploreProductContent onClick={()=>{
				item?.action()
			}}>
				<Text
					size={18}
					style={{
						color: COLORS.white,
						marginBottom: 20,
					}}>
					{t(item.title)}
				</Text>
				<CImage
					src={item.image}
					alt="logo"
					width={50}
					height={50}
					resizeMode={'cover'}
				/>
				<View style={{ width: '70%', marginTop: 20 }}>
					<Text
						size={12}
						style={{
							fontWeight: '300',
							color: COLORS.black,
							textAlign: 'center',
							lineHeight: 1.2,
						}}>
						{t(item.content)}
					</Text>
				</View>
			</ExploreProductContent>
		</ExploreProductItemSection>
	)
}

export default ExploreProductItem
