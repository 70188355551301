import { Button, message } from 'antd'
import { CInput } from 'app/components/Common'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import API from '../../../../../../../utils/api'
import { useToken } from '../../../../../../hooks'
import PhoneInputSection from '../../../../AuthContainers/InputSection/PhoneInputSection'

const SmsAuthContainer = ({setOpen, refresh}) => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const [code, setCode] = useState('')
	const [phone, setPhone] = useState('')
	const onChangeId = e => {
		setPhone(e.target.value)
	}

	const [verifyId, setVerifyId] = useState('' as any)

	const { data: token } = useToken()
	return (
		<MView
			style={{
				width: '100%',
				paddingInline: 16,
				paddingTop: 30,
			}}>
			<MText style={{ marginBottom: 10 }}>{t('addPhoneNumber')}</MText>
			<PhoneInputSection
				id={phone}
				onChangeId={onChangeId}
				type={'phone'}
				vStyle={{ width: '100%' }}
			/>
			<MView>
				<Button
					onClick={() => {
						if (phone?.length < 5) {
							message.error(t('Check your phone number'))
						}
						API.post('/auth/sms', token + '', {
							phone: phone,
						}).then(res => {
							if (res?.result) {
								setVerifyId(res?.result)
								message.success(
									'Sent the verification code to your phone number'
								)
							} else {
								message.error(
									'Failed to send the verification code to your phone number'
								)
							}
						})
					}}
					style={{
						fontSize: 14,
						fontWeight: '300',
						width: '100%',
					}}>
					{t('sendTheVerificationCodeTo')}
				</Button>
			</MView>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<CInput
					placeholder={t('smsVerificationCode')}
					value={code}
					type={'text'}
					onChange={(e: any) => setCode(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 7,
					}}
					style={{
						background: gray,
						border: 0,
						color: textw,
						padding: 8,
						paddingLeft: 15,
						width: '100%',
					}}
				/>
			</MView>
			<Button
				onClick={() => {
					if (verifyId?.length < 5) {
						message.error(
							t('Send the verification code to your phone number')
						)
						return
					}
					if (code?.length < 5) {
						message.error(t('Check your verification code'))
						return
					}

					API.post('/auth/smscheck', token + '', {
						phone: phone,
						verifyId: verifyId,
						code: code,
					}).then(res => {
						if (res?.result) {
							//성공
							message.success('Changed your phone number')
							refresh()
							setOpen(false)
						} else {
							//실패
							message.error('Failed to change your phone number')
						}

						refresh()
					})
				}}
				style={{
					fontSize: 14,
					fontWeight: '300',
					width: '100%',
				}}>
				{t('save')}
			</Button>
		</MView>
	)
}

export default SmsAuthContainer
