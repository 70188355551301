import styled from 'styled-components'

export const TheBitCardItemSection = styled.div`
	width: 250px;
	height: 300px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	@media screen and (max-width: 1200px) {
		width: 45%;
	}
	@media screen and (max-width: 900px) {
		width: 40%;
	}
	@media screen and (min-width: 1200px) {
		width: 30%;
	}
`
export const TheBitCardListSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 1200px) {
		flex-wrap: wrap;
		justify-content: flex-start;
		& > ${TheBitCardItemSection} {
			width: calc(32% - 20px);
			margin: 10px;
		}
	}

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
		& > ${TheBitCardItemSection} {
			width: calc(50% - 20px);
			margin: 10px;
		}
	}
`
