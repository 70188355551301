import React from 'react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'

const TradeDetailError = () => {
	return (
		<View>
			<Text size={16} style={{ color: COLORS.lightRed }}>
				Error...
			</Text>
		</View>
	)
}

export default TradeDetailError
