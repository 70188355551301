import React, { useState } from 'react'
import { CopyTradeSettingBodyWrapper } from './styles'
import CopyTradeSettingInputs from './CopyTradeSettingInputs'

const CopyTradeSettingBody = () => {
	const [fundCopy, setFundCopy] = useState('')
	const [stopLoss, setStopLoss] = useState('')
	return (
		<CopyTradeSettingBodyWrapper>
			<CopyTradeSettingInputs
				title={'fundsToCopy'}
				value={fundCopy}
				setValue={setFundCopy}
				placeholder={'pleaseEnterAValue'}
			/>
			<CopyTradeSettingInputs
				title={'copyStopLoss(CSL)'}
				value={stopLoss}
				setValue={setStopLoss}
				placeholder={'pleaseEnterCopyStopLoss'}
			/>
		</CopyTradeSettingBodyWrapper>
	)
}

export default CopyTradeSettingBody
