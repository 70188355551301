import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import CurrentTradesHeader from './CurrentTradesHeader'
import CurrentTradesTable from './CurrentTradesTable'

const CurrentTrades = () => {
	const { t } = useTranslation()
	const ALL_CONTRACTS = t('allContracts')
	const [selectCoin, setSelectCoin] = useState(ALL_CONTRACTS)
	const coinLists = [ALL_CONTRACTS, 'BTCUSDT', 'ETHUSDT']
	const [tab, setTab] = useState('byOrder')
	return (
		<View style={{ width: '100%', padding: 20 }}>
			<CurrentTradesHeader
				coinLists={coinLists}
				selectCoin={selectCoin}
				setSelectCoin={setSelectCoin}
				tab={tab}
				setTab={setTab}
			/>
			<CurrentTradesTable />
		</View>
	)
}

export default React.memo(CurrentTrades)
