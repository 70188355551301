import React, { useState } from 'react'
import { IconStarFilled } from '@tabler/icons-react'
import { ScrollableTabs } from 'app/mobile/components'
import { MMarketSection } from './styles'
import MMarketFavourite from './MarketFavourite'
import MMarketDerivatives from './MarketDerivatives'
import MMarketSpot from './MarketSpot'
import CRenderEmpty from '../../../components/CRenderEmpty'

const MMarkets = () => {
	const [tab, setTab] = useState(0)

	const tabs = [
		{
			title: 'favourites',
			icon: (
				<IconStarFilled
					size={14}
					style={{ marginRight: 3, marginTop: -2 }}
				/>
			),
			tab: 0,
		},
		{
			title: 'derivatives',
			tab: 1,
		},
		{
			title: 'spot',
			tab: 2,
		},
	]
	return (
		<MMarketSection>
			<ScrollableTabs
				tab={tab}
				setTab={setTab}
				tabs={tabs}
				noBorder={true}
			/>
			{tab === 0 ? <MMarketFavourite /> : null}
			{tab === 1 ? <MMarketDerivatives /> : null}
			{tab === 2 ? <MMarketSpot /> : null}

			<CRenderEmpty id="2" />
		</MMarketSection>
	)
}

export default MMarkets
