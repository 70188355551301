import React from 'react'
import { FONTS } from 'app/constants/STYLES'
import { MarketTableListContainer } from '../styles'
import Favourite from './Favourite'
import { TradingParisContent, TradingParisSection } from './styles'
import { CoinLogo } from 'app/components'
import { Text } from 'styles/reactnative'
import { useHistory } from 'react-router-dom'

const TradingPairs = ({ data: item }) => {
	const navigation = useHistory()
	console.log(item)
	return (
		<MarketTableListContainer
			onClick={() => {
				navigation.push(`/trade/${item?.id}`)
			}}>
			<TradingParisSection>
				<Favourite />
				<CoinLogo
					src={item?.image}
					style={{
						marginRight: 5,
						alignSelf: 'center',
						display: 'flex',
					}}
				/>

				<TradingParisContent>
					<Text
						size={12}
						style={{
							...FONTS.h7,
						}}>
						{item?.pairs}
					</Text>
				</TradingParisContent>
			</TradingParisSection>
		</MarketTableListContainer>
	)
}

export default TradingPairs
