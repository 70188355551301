import { IconMail } from '@tabler/icons'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import SettingTitle from '../SettingTitle'
import IdentifyVerification from './IdentifyVerification'
import { AuditSettingContainer, AuditSettingSection } from './styles'

const AuditSetting = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const levelOne = [
		{
			level: 1,
			title: 'successfullyRegistered',
			icon: <IconMail color={textw} size={18} />,
		},
	]
	const levelTwo = [
		{
			level: 2,
			title: 'selfie',
			icon: <IconMail color={textw} size={18} />,
		},
	]
	const levelThree = [
		{
			level: 3,
			title: 'proofOfAddress',
			icon: <IconMail color={textw} size={18} />,
		},
	]
	return (
		<AuditSettingSection>
			<SettingTitle title={'audit'} />
			<AuditSettingContainer>
				<IdentifyVerification title={'beginner'} level={levelOne} />
				<IdentifyVerification title={'basic'} level={levelTwo} />
				<IdentifyVerification
					title={'advance'}
					level={levelThree}
					last={true}
				/>
			</AuditSettingContainer>
		</AuditSettingSection>
	)
}

export default AuditSetting
