import styled from 'styled-components'

export const MSearchListSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-inline: 15px;
	width: 100%;
	height: 100%;
`
