import React from 'react'
import { TraderDetailLeftSection } from '../styles'
import ProfitDetailBody from './ProfitDetailBody'
import ProfitDetailHeader from './ProfitDetailHeader'

const TraderProfitDetail = () => {
	return (
		<TraderDetailLeftSection>
			<ProfitDetailHeader />
			<ProfitDetailBody />
		</TraderDetailLeftSection>
	)
}

export default React.memo(TraderProfitDetail)
