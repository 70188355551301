/**
 * Asynchronously loads the component for FAQPage
 */

import { lazyLoad } from 'utils/loadable'

export const FAQPage = lazyLoad(
	() => import('./index'),
	module => module.FAQPage
)
