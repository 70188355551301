import styled from 'styled-components'

export const SkeletonComp = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 5;
	width: 100%;
`
export const SubSkeletonComp = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	align-self: flex-start;
`
