import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { View } from 'styles/reactnative'
import { useTradeModalStore } from '../../../../../../../hooks/trade/modalStore'

const AccountTransfer = ({ bgColor, color }) => {
	const { t } = useTranslation()

	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	const openModal = () => {
		muatateModalStore({ ...modalStore, transferModal: true })
	}
	return (
		<View style={{ flex: 1 }}>
			<CButton
				title={t('transfer')}
				onClick={() => {
					openModal()
				}}
				style={{
					background: bgColor,
					color: color,
					height: 30,
					paddingBlock: 7,
					width: '95%',
					marginRight: 7,
				}}
			/>
		</View>
	)
}

export default AccountTransfer
