/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react'
import { CButton } from 'app/components/Common/'

import { TradeDetailListSection, TradeText } from './styles'

import useTradeStore from '../../../../hooks/trade/tradeStore'
import { useToken } from 'app/hooks'
import { AuthCheckText } from 'app/components'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import NoData from '../../../../components/Common/CFlatList/NoData'
import { Text, View } from '../../../../../styles/reactnative'
import {
	Button,
	Input,
	Popover,
	Select,
	Space,
	Table,
	Tag,
	message,
} from 'antd'
import KrwPrice from '../../../../components/KrwPrice'
import { useTranslation } from 'react-i18next'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useHistory } from 'react-router-dom'
import { goTo } from '../../../../hooks/navigation'
import { COLORS } from '../../../../constants'
import BigNumber from 'bignumber.js'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import API from '../../../../../utils/api'
import { CANCELORDER } from '../../../../constants/APIKEYS'
import { useTimezone } from '../../../../hooks/timezone'
import EntryTime from '../../../../components/EntryTime'
import { IconEdit } from '@tabler/icons'
import TPSLEDIT from '../TPSLEDIT'
import { useMitOrder } from '../../../../hooks/trade/mitorder'
import { useTradeSetting } from '../../../../hooks/trade/tradeSetting'

const TradeActiveTable = ({ parentHeight }) => {
	const { data: token } = useToken()
	const { textw, content, header, textsilver } =
		useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { t } = useTranslation()
	const nav = useHistory()

	const [shareData, setShareData] = React.useState<any>(null)

	const [closeAll, setCloseAll] = React.useState(false)

	const { mutate: mutateModalStore, data: modalStore } = useTradeModalStore()

	const { data: tradeStore } = useTradeStore()

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const equivshow = getSetting(setting, 'EQUIVSHO', 1) == 1 ? true : false

	const { getPrice } = useIndexPrice(tradeStore?.ep_id || 0)

	const { data: tradeSetting } = useTradeSetting(tradeStore?.ep_id)

	const { EPs } = useExchangeStore()
	const { data, orderlist, mutate } = useTradePosition()

	const { data: mitdata, mutate: mutateMit } = useMitOrder()

	let list = orderlist?.length > 0 ? [...orderlist] : []

	const [mit, setMit] = useState<any>([])

	list = [...mit, ...list]

	useEffect(() => {
		const keys = Object.keys(mitdata || [])

		for (const key of keys) {
			if (key == 'NO') continue

			let mitlist: any = []
			const item = mitdata[key]

			item?.map((mit: any) => {
				const temp = {
					status: 10,
					exchange_pairs_id: parseInt(key?.replace('MIT', '')),
					leverage: tradeSetting?.leverage || 1,
					limit_market: mit.limit_market == 'CL' ? 'L' : 'M',
					order_price: mit.price,
					trigger_price: mit.trigger_price,
					trigger_type: mit.trigger_type,
					long_short: mit.longshort,
					amount: mit?.amount,
					created_at: mit?.created_at,
				}
				mitlist.push(temp)
			})
			setMit([...mitlist])
		}

		return () => {
			setMit([])
		}
	}, [mitdata])

	data?.map((item: any) => {
		if (item?.tpsl) {
			const TPSL = item?.tpsl?.[0]

			if (TPSL) {
				if (+TPSL?.tp != 0) {
					list = [
						{
							...item,
							posid: item?.id,
							posprice: item?.price,
							poslongshort: item?.long_short,
							status: 1,
							limit_market: 'L',
							long_short: item?.long_short == 'L' ? 'S' : 'L',
							order_price: +TPSL?.tp,
							type: 'TP',
							tpsltype: TPSL?.tp + ' ' + TPSL?.tptype,
							tpsl: TPSL,
						},
						...list,
					]
				}
				if (+TPSL?.sl != 0) {
					list = [
						{
							...item,
							posid: item?.id,
							posprice: item?.price,
							poslongshort: item?.long_short,
							status: 1,
							limit_market: 'L',
							long_short: item?.long_short == 'L' ? 'S' : 'L',
							order_price: +TPSL?.sl,
							type: 'SL',
							tpsltype: TPSL?.sl + ' ' + TPSL?.sltype,
							tpsl: TPSL,
						},
						...list,
					]
				}
			}
		}
	})

	if (modalStore?.iscurrent) {
		list = list?.filter(
			(item: any) => item?.exchange_pairs_id == tradeStore?.ep_id
		)
	}

	useEffect(() => {
		if (closeAll) {
			{
				orderlist?.map((item: any) => {
					API.post(CANCELORDER, token + '', {
						orderid: item?.id,
					}).then(res => {
						mutate()
					})
				})
			}
		}
	}, [closeAll])

	return (
		<TradeDetailListSection>
			{token ? null : <AuthCheckText />}

			{list?.length ? (
				<Table
					showHeader={true}
					bordered={false}
					dataSource={list}
					rootClassName={'table' + (!isDarkMode ? 'dark' : 'light')}
					columns={[
						{
							title: t('contracts') + '',
							dataIndex: 'contracts',
							align: 'center',
							key: 'contracts',
							width: 100,
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								return (
									<TradeText style={{ flexDirection: 'row' }}>
										<TradeText
											style={{
												flexDirection: 'column',
												cursor: 'pointer',
											}}
											onClick={() => {
												goTo(nav, '/trade/' + EP?.id)
											}}>
											<Text>{EP?.pairs}</Text>
											<Text
												style={{
													color: 'orange',
													fontSize: 10,
													whiteSpace: 'nowrap',
												}}>
												{item?.cross_iso === 'C'
													? t('cross')
													: t('iso')}{' '}
												{item?.leverage}X
											</Text>
										</TradeText>
										<Text
											style={{
												marginLeft: 5,
												color:
													item?.long_short === 'L'
														? COLORS.lightGreen
														: COLORS.lightRed,
											}}>
											{item?.long_short === 'L'
												? t('long')
												: t('short')}
										</Text>
									</TradeText>
								)
							},
						},
						{
							title: t('qty') + '',
							dataIndex: 'amount',
							key: 'amount',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								const coin = EP?.pairs?.split('/')[0]
								return (
									<TradeText
										style={{
											whiteSpace: 'nowrap',
											flexDirection: 'column',
											width: '100%',
											alignContent: 'flex-end',
											alignItems: 'flex-end',
										}}>
										{BigNumber(item?.amount)
											.multipliedBy(item?.leverage)
											.toFormat(
												EP?.dp2 || EP?.dp || 3
											)}{' '}
										{coin}
									</TradeText>
								)
							},
						},
						{
							title: t('orderPrice') + '',
							dataIndex: 'open_price',
							key: 'open_price',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)

								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											lineHeight: 1,
											width: '100%',
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										{item?.status == 10
											? item?.limit_market == 'L'
												? t('climit') +
												  ' (' +
												  BigNumber(
														item?.order_price
												  ).toFormat(EP?.dp2 || 3) +
												  ')'
												: t('cmarket')
											: ''}

										{item?.status == 10 ? (
											<Text>
												<Text
													style={{
														color: textsilver,
													}}>
													{t('triggerPrice')}
												</Text>{' '}
												{item?.trigger_type == 'BIG'
													? '>='
													: '<='}
												{item?.trigger_price}
											</Text>
										) : (
											<Text>
												{item?.limit_market == 'L'
													? item?.order_price
													: t('market')}
											</Text>
										)}
									</TradeText>
								)
							},
						},
						{
							title: t('markPrice') + '',
							dataIndex: 'mark_price',
							key: 'mark_price',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											width: '100%',
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										{BigNumber(
											getPrice(item?.exchange_pairs_id)
										).toFormat(EP?.dp || 3)}
									</TradeText>
								)
							},
						},
						{
							title: t('margin') + '',
							dataIndex: 'margin',
							key: 'margin',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								const currency = EP?.pairs?.split('/')[1]
								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											width: '100%',
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										<KrwPrice
											price={item?.margin}
											currency={currency}
											color={false}
											equiv={true}
											dp={3}
										/>
									</TradeText>
								)
							},
						},
						{
							title: t('orderTime') + '',
							dataIndex: 'created_at',
							key: 'created_at',
							align: 'center',
							render: (text: any, item) => {
								return <EntryTime time={item?.created_at} />
							},
						},
						{
							title: (
								<Space size={'small'}>
									<Button
										type="primary"
										size="small"
										onClick={() => {
											setCloseAll(true)
										}}>
										{t('entire')} {t('cancel') + ''}
									</Button>
								</Space>
							),
							dataIndex: 'close_by',
							width: 100,
							key: 'close_by',
							align: 'center',
							render: (text: any, item) => {
								if (item?.type == 'TP' || item?.type == 'SL') {
									return (
										<View style={{ flexDirection: 'row' }}>
											<Tag>{t(item?.type)}</Tag>
											<TPSLEDIT
												posid={item?.posid}
												tpsl={item?.tpsl}
												price={item?.posprice}
												amount={item?.leverage}
												long_short={item?.poslongshort}
												leverage={item?.leverage}
												margin={item?.margin}
											/>
										</View>
									)
								}
								return (
									<CButton
										title={t('cancel')}
										onClick={() => {
											if (item?.status == 10) {
												const temp = mitdata[
													'MIT' +
														item?.exchange_pairs_id
												]?.filter(
													(mit: any) =>
														!(
															mit.limit_market ==
																'C' +
																	item?.limit_market &&
															+mit.price ==
																+item?.order_price &&
															mit.longshort ==
																item?.long_short
														)
												)

												mutateMit(
													temp,
													item?.exchange_pairs_id
												)
												setTimeout(() => {
													mutate()
												}, 100)
												message.success(
													t(
														item?.limit_market ==
															'L'
															? 'climit'
															: 'cmarket'
													) +
														' 주문 취소 완료되었습니다.'
												)
											} else {
												API.post(
													CANCELORDER,
													token + '',
													{
														orderid: item?.id,
													}
												).then(res => {
													mutate()
												})
											}
										}}
										style={{
											background: COLORS.BTN_HOVER,
											marginLeft: 10,
											marginTop: -5,
										}}
									/>
								)
							},
						},
					]}
					pagination={false}
					style={{ width: '100%', height: '100%' }}
					sticky={true}
					scroll={
						list?.length * 50 >= parentHeight - 100
							? { x: true, y: parentHeight - 100 }
							: { x: true }
					}
					size="small"
				/>
			) : (
				<View style={{ flex: 1, height: '100%' }}>
					<NoData title={'noOrderYet'} />
				</View>
			)}
		</TradeDetailListSection>
	)
}

export default TradeActiveTable
