import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const PaginationLists = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const PaginationItems = styled.div`
	border: 1px solid ${COLORS.greyWhite};
	padding-block: 8px;
	padding-inline: 10px;
	cursor: pointer;
	border-radius: 5px;
`
