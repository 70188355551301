import styled from 'styled-components'

export const ConvertQuoteWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 40px;
	padding-left: 30px;
	height: 170px;
	width: 100%;
`
