import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'
import { DropdownContent } from './HeaderDropdown/styles'
//768px
export const HeaderContainer = styled.div<{ background: string }>`
	position: static;
	background-color: ${props => props.background};
	top: 0;
	z-index: 2;

	font-size: 1.4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	width: 100%;
`

export const HeaderButtonWrap = styled.div<{ display: string }>`
	display: ${props => props.display};
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: flex-start;

	display: flex !important;
	flex-direction: row;
	justify-content: center;
`

export const NavBarToggle = styled.span`
	position: absolute;
	right: 20px;
	cursor: pointer;
	align-self: center;
	color: rgba(255, 255, 255, 0.8);
	font-size: 24px;
	@media (min-width: 900px) {
		display: none;
	}
`
export const HeaderButtonSection = styled.div<{ hover?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 80px;
	height: 32px;
	&:hover {
		color: ${COLORS.white};
		background-color: ${COLORS.primary};
	}
	color: ${props => props.theme.COLORS.textw};
	${({ hover }) =>
		hover &&
		`&:hover ${DropdownContent} {
		display: block;
	}`}
`
export const HeaderButtonText = styled.div`
	margin-left: 1rem;
	padding-right: 0.3rem;
	font-size: 13px;
	&:hover {
		color: ${COLORS.white};
	}
`
export const MobileView = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	padding-right: 20px;
	@media (min-width: 900px) {
		display: none;
	}
`
