import React from 'react'

import { View, Text } from 'styles/reactnative'
import TraderDetailDropdowns from '../../TraderDetailDropdowns'

const StatisticEarningBody = ({
	coinLists,
	selectCoin,
	setSelectCoin,
	dayLists,
	selectByDay,
	setSelectByDay,
}) => {
	return (
		<View style={{ width: '100%' }}>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<Text
					size={14}
					style={{
						fontWeight: '600',
						marginBottom: 7,
					}}>
					Profit
				</Text>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: 10,
					}}>
					<Text size={12}>Derivatives Pair</Text>

					<TraderDetailDropdowns
						data={coinLists}
						selected={selectCoin}
						setSelected={setSelectCoin}
					/>
					<TraderDetailDropdowns
						data={dayLists}
						selected={selectByDay}
						setSelected={setSelectByDay}
					/>
				</View>
			</View>
		</View>
	)
}

export default React.memo(StatisticEarningBody)
