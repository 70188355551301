import React from 'react'
import { CheckBox } from 'app/components/Common'
import { View } from 'styles/reactnative'

interface IProps {
	title?: string
	checked?: boolean
	setChecked?: any
}
const DepositSettingList = ({ title, checked, setChecked }: IProps) => {
	return (
		<View style={{ marginBottom: 20 }}>
			<CheckBox
				labelStyle={{ marginLeft: 10 }}
				label={title}
				value={checked}
				onChange={() => {
					setChecked(!checked)
				}}
			/>
		</View>
	)
}

export default DepositSettingList
