import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconChevronRight } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { CButton } from 'app/components/Common'
import { Modal } from 'antd'
import DWList from '../../../Header/DWList'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import CalcList from '../../../Header/CalcList'

const RecentDepositHeader = () => {
	const { t } = useTranslation()
	const [show, setShow] = React.useState(false)
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				height: 50,
				paddingInline: 10,
			}}>
			<Text
				size={14}
				style={{
					borderLeft: `2px solid  ${COLORS.primary}`,
					fontWeight: '600',
					paddingLeft: 10,
				}}>
				{t('recentDepositWithdrawalHistory')}
			</Text>

			<CButton
				title={t('all')}
				onClick={() => {
					setShow(true)

					mutateModalStore({
						...modalStore,
						depositType: "*",
					})
				}}
				icon={
					<IconChevronRight
						size={14}
						style={{ color: COLORS.primary }}
					/>
				}
				style={{ color: COLORS.primary }}
			/>
			<Modal
				width={1400}
				open={show}
				onOk={() => {
					setShow(false)
				}}
				onCancel={() => {
					setShow(false)
				}}>
				<DWList />
			</Modal>
		</View>
	)
}

export default RecentDepositHeader
