import styled from 'styled-components'

export const AccountSecurityListSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
export const AccountSecurityListItemSection = styled.div`
	display: flex;
	align-self: center;
	justify-content: space-between;
	flex-direction: row;
	width: 95%;
	padding: 20px 0;
	@media only screen and (max-width: 970px) {
		flex-direction: column;
	}
`
export const InfoLeftLists = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
`
