import React from 'react'

interface IProps {
	width?: number | 'auto' | string
	height?: number | 'auto' | string
	resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center'
	src?: any
	alt?: string
	style?: any
	onClick?: () => void
}

const CImage = ({
	src,
	alt,
	width,
	height,
	resizeMode,
	style,
	onClick,
}: IProps) => {
	return (
		<img
			src={src}
			alt={alt ? alt : 'random'}
			width={width}
			height={height}
			style={{ backgroundSize: resizeMode, ...style }}
			onClick={onClick}
		/>
	)
}

export default React.memo(CImage)
