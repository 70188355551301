import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AnnouncementSectionTitle = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ marginBottom: 15 }}>
			<Text style={{ color: textw, fontSize: 30, fontWeight: '600' }}>
				{t('announcementSectionTitle')}
			</Text>
		</View>
	)
}

export default AnnouncementSectionTitle
