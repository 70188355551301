import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'

const UserFeedbackTitle = () => {
	const { t } = useTranslation()
	return (
		<MView style={{ marginBlock: 20 }}>
			<MText style={{ fontSize: 20 }}>
				{t('productSuggestionRequest')}
			</MText>
		</MView>
	)
}

export default UserFeedbackTitle
