import React from 'react'
import AppLogo from 'app/components/AppLogo'
import { WebsiteLogoSection } from './styles'

const WebsiteLogo = () => {
	return (
		<WebsiteLogoSection>
			<AppLogo width={120}/>
		</WebsiteLogoSection>
	)
}

export default React.memo(WebsiteLogo)
