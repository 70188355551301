import React, { useContext } from 'react'
import { CTabs } from 'app/components/Common'
import { View } from 'styles/reactnative'
import { WindowSize } from 'utils/ExpensiveFunction'
import { ThemeContext } from 'theme/ThemeProvider'

const TraderDetailInfoRightSideHeader = ({ tab, setTab, tabs }: any) => {
	const { borderColor, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				alignItems: 'flex-start',
				alignSelf: 'flex-start',
				borderBottom: `1px solid ${borderColor}`,
				width: '100%',
				paddingInline: 24,
				paddingBlock: 12,
			}}>
			<View style={{ marginLeft: -35 }}>
				<CTabs
					tabs={tab}
					setTabs={setTab}
					data={tabs}
					activeColor={textw}
					fixedSize={WindowSize(14, 12, 11)}
					style={{
						fontWeight: '500',
						marginRight: 15,
						marginBottom: -12,
					}}
				/>
			</View>
		</View>
	)
}

export default TraderDetailInfoRightSideHeader
