import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const HelpCenterDetailList = ({ text }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ marginBlock: 10 }}>
			<Text
				style={{
					color: textw,
					fontWeight: '300',
					fontSize: 14,
					lineHeight: 1.5,
				}}>
				{text}
			</Text>
		</View>
	)
}

export default HelpCenterDetailList
