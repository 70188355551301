import { STORE_HEADER_BUTTON } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'

const KEY = STORE_HEADER_BUTTON

let swrData: any = KEY
export function useHeaderBtn(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (reset === true) {
			swrData = null
			return null
		}
		if (initData !== undefined) {
			swrData = initData
		}
		return swrData
	})

	return {
		data,
		mutate: (value: any) => {
			swrData = value
			return mutate()
		},
	}
}
