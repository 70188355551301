import { useNotiSetting } from 'app/hooks/stateStore'
import PageBorder from 'app/mobile/components/PageBorder'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import MNotiSettingItems from './MNotiSettingItems'
import { MNotiSettingSection } from './styles'

const MNotiSetting = () => {
	const { t } = useTranslation()
	const { data: notiSettting } = useNotiSetting()

	return (
		<MNotiSettingSection>
			<MView style={{ paddingTop: 10 }}>
				<MView style={{ paddingInline: 16 }}>
					<MText style={{ fontSize: 14 }}>{t('doNotDisturb')}</MText>
				</MView>
				<MNotiSettingItems
					title={'nightTimeDoNotDisturb'}
					content={'receiveNoPushNotification'}
					notiType={'NIGHT'}
					notiSwitch={notiSettting?.NIGHT}
					notiSettting={notiSettting}
				/>
				<MNotiSettingItems
					title={'appUsageNotiPopup'}
					content={'appUsageNotiPopupContent'}
					notiType={'FORGROUND'}
					notiSwitch={notiSettting?.FORGROUND}
					notiSettting={notiSettting}
				/>
			</MView>
			<PageBorder style={{ height: 3 }} />
			<MView style={{ paddingTop: 10 }}>
				<MView style={{ paddingInline: 16 }}>
					<MText style={{ fontSize: 14 }}>
						{t('notificationSetting')}
					</MText>
				</MView>
				<MNotiSettingItems
					title={'receiveNotification'}
					content={'turnOffToNotReceive'}
					notiType={'ALL'}
					notiSwitch={notiSettting?.ALL}
					notiSettting={notiSettting}
				/>
				<MNotiSettingItems
					title={'generalNotifications'}
					content={'generalNotificationDesc'}
					notiType={'NORMAL'}
					notiSwitch={notiSettting?.NORMAL}
					notiSettting={notiSettting}
				/>
			</MView>
		</MNotiSettingSection>
	)
}

export default MNotiSetting
