import useSWR from 'swr'
import API from '../../../utils/api'
import { MYBALANCE } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'
// import { getPersistData } from '../persist'

// const KEY = MYBALANCE

export function useBalance(symbol?: string, type = 0) {
	const { data: token } = useToken()
	if (symbol === undefined) symbol = 'USDT'
	//let SWRKEY: any = MYBALANCE + '?symbol=' + symbol + '&type=' + type

	let SWRKEY: any = '/trade/status'

	if (!token) SWRKEY = null

	const { data, mutate } = useSWR<any>(
		SWRKEY,
		async () => {
			const response = await API.get(SWRKEY, token + '', {}, false)
			return response.result.result
		},
		{
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			revalidateIfStale: true,
		}
	)

	const spot = data?.spot
	const futures = data?.futures
	const global = data?.global

	return {
		data: type === 0 ? spot : type == 9 ? global : futures,
		mutate: (value?: any) => {
			return mutate()
		},
	}
}
