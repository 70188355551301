import styled from 'styled-components'

export const SubFooterContainer = styled.div`
	width: 100%;
	color: #ccc;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const SubFooterWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
