import React from 'react'
import { useTranslation } from 'react-i18next'
import AllTraderMasterItem from './AllTraderMasterItem'
import CopyActions from './CopyActions'
import TraderMasterDetail from './TraderMasterDetail'

export const AllTraderMasterTableData = () => {
	const { t } = useTranslation()
	return [
		{
			field: t('nickname'),
			unSortIcon: true,
			cellRenderer: ({ data }) => <TraderMasterDetail data={data} />,
			sortable: true,
		},
		{
			field: t('7Droi'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<AllTraderMasterItem data={data.english_name.length} />
			),
		},
		{
			field: t('7DMasterP&L'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<AllTraderMasterItem data={data.english_name.length} />
			),
		},
		{
			field: t('7DMaxFollowers'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<AllTraderMasterItem data={data.english_name.length} />
			),
		},
		{
			field: t('7DFollowersP&L'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<AllTraderMasterItem data={data.english_name.length} />
			),
		},
		{
			field: t('7DWinRate'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<AllTraderMasterItem data={data.english_name.length} />
			),
		},
		{
			field: t('stabilityIndex'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<AllTraderMasterItem data={data.english_name.length} />
			),
		},
		{
			field: t('action'),
			unSortIcon: true,
			cellRenderer: ({ data }) => <CopyActions item={data} />,
		},
	]
}
