import React from 'react'
import { CButton } from 'app/components/Common'
import { IconMinus } from '@tabler/icons'
import { View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { WindowSize } from 'utils/ExpensiveFunction'
import { useTranslation } from 'react-i18next'
const StatiscHeadersNavBar = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				alignSelf: 'flex-start',
				marginBottom: 10,
			}}>
			<CButton
				icon={
					<IconMinus
						color="blue"
						size={14}
						style={{ marginRight: 5 }}
					/>
				}
				title={t('cumulativeROI')}
				onClick={() => {
					console.log('Hello ROI')
				}}
				style={{
					color: COLORS.lightGray4,
					alignSelf: 'flex-start',
					marginLeft: -7,
					flexDirection: 'row-reverse',
					fontSize: WindowSize(12, 11, 10),
				}}
				textHover={COLORS.BTN_HOVER}
			/>
			<CButton
				icon={
					<IconMinus
						color="orange"
						size={14}
						style={{ marginRight: 5 }}
					/>
				}
				title={`${t('cumulativeProfit')}(USDT)`}
				onClick={() => {
					console.log('Hello ROI')
				}}
				style={{
					color: COLORS.lightGray4,
					alignSelf: 'flex-start',
					marginLeft: -7,
					flexDirection: 'row-reverse',
					fontSize: WindowSize(12, 11, 10),
				}}
				textHover={COLORS.BTN_HOVER}
			/>
		</View>
	)
}

export default StatiscHeadersNavBar
