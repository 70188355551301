import React from 'react'

import fairness from 'assets/icons/fairness.png'
import innovation from 'assets/icons/innovation.png'
import safety from 'assets/icons/safety.png'
import satisfaction from 'assets/icons/satisfaction.png'
import transparency from 'assets/icons/transparency.png'

import { AboutTheBitList, AboutTheBitSection } from './styles'

import AboutValueItems from './AboutValueItems'
import AboutValueTitle from './AboutValueTitle'

const AboutValues = () => {
	const data = [
		{
			title: 'fairness',
			content: 'fairnessContent',
			image: fairness,
		},
		{
			title: 'transparency',
			content: 'transparencyContent',
			image: transparency,
		},
		{
			title: 'safety',
			content: 'safetyContent',
			image: safety,
		},
		{
			title: 'innovation',
			content: 'innovationContent',
			image: innovation,
		},
		{
			title: 'customerSatisfaction',
			content: 'customerSatisfactionContent',
			image: satisfaction,
		},
	]
	return (
		<AboutTheBitSection>
			<AboutValueTitle />
			<AboutTheBitList>
				{data?.map((item, index) => (
					<AboutValueItems
						key={index.toString()}
						item={item}
						index={index}
					/>
				))}
			</AboutTheBitList>
		</AboutTheBitSection>
	)
}

export default AboutValues
