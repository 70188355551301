import React from 'react'
import { useWindowDimensions } from 'app/hooks/'

import { CImage } from 'app/components/Common/'
import { EventBoxSection } from './styles'
import { IMAGES } from 'app/constants'

const TradingBotRightSide = () => {
	const { width } = useWindowDimensions()
	return (
		<EventBoxSection
			style={{
				width: width > 900 ? '40%' : '100%',
				height: width > 900 ? 300 : 300,
				marginBottom: width > 900 ? 0 : 20,
				borderRadius: 55,
			}}>
			<CImage
				src={IMAGES.botImage}
				alt="logo"
				width={'100%'}
				height={'100%'}
				resizeMode={'cover'}
				style={{
					borderRadius: 22,
				}}
			/>
		</EventBoxSection>
	)
}

export default React.memo(TradingBotRightSide)
