import { COLORS } from 'app/constants'
import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import FollowerDashbard from './FollowerDashboard'
import FollowerOrders from './FollowerOrders'

import FollowerTraderTabs from './FollowerTraderTabs'
import MasterTrader from './MasterTrader'

const FollowerTraderSection = () => {
	const [tabs, setTabs] = useState('dashboard')
	const data = ['dashboard', 'masterTrader', 'orders']
	return (
		<View
			style={{
				alignItems: 'flex-start',
				marginTop: 100,
				width: '100%',
			}}>
			<View
				style={{
					borderBottom: `1px solid ${COLORS.greyWhite}`,
					width: '100%',
				}}>
				<FollowerTraderTabs tabs={tabs} setTabs={setTabs} data={data} />
			</View>
			{tabs === 'dashboard' ? <FollowerDashbard /> : null}
			{tabs === 'masterTrader' ? <MasterTrader /> : null}
			{tabs === 'orders' ? <FollowerOrders /> : null}
		</View>
	)
}

export default React.memo(FollowerTraderSection)
