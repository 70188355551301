import { IconEye, IconEyeOff } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { SETTING_TYPE_SYSTEM } from 'app/constants/SETTING'
import { useWindowDimensions } from 'app/hooks'
import { useFetcherPublic } from 'app/hooks/fetcher'
import { useBalance } from 'app/hooks/user/mybalance'
import { getSetting, useSetting } from 'app/hooks/user/mysetting'
import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { AssetTotalValueLeftSection } from './styles'
import { useSystemStore } from '../../../../hooks/stateStore/systemStore'

const AssetTotalValueLeft = () => {
	const { width } = useWindowDimensions()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	const { data: spot } = useBalance('USDT', 0)
	const { data: derivatives } = useBalance('USDT', 1)

	const total = BigNumber(spot?.balance || 0).plus(derivatives?.balance || 0)

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const currency = getSetting(setting, 'CURRENCY', 'KRW')

	const currencyList = useFetcherPublic('/system/currency')
	const equiv = currencyList?.data?.result?.result?.find(
		(item: any) => item.code === currency
	)

	const equivValue = total.multipliedBy(equiv?.price || 1)
	const { data: systemStore, mutate: mutateSystemStore } = useSystemStore()
	return (
		<AssetTotalValueLeftSection>
			<View
				style={{
					flexDirection: 'row',
					marginBottom: 20,
					justifyContent: 'flex-start',
				}}>
				<Text size={18}>{t('totalAssetValue')}</Text>
				<CButton
					onClick={() => {
						mutateSystemStore({
							...systemStore,
							hiddenCoin: !systemStore?.hiddenCoin,
						})
					}}
					icon={
						systemStore?.hiddenCoin ? (
							<IconEyeOff color={textw} />
						) : (
							<IconEye color={textw} />
						)
					}
				/>
			</View>
			<View style={{ alignItems: 'flex-start' }}>
				<View style={{ flexDirection: 'row' }}>
					<Text size={width < 900 ? 14 : 22}>
						{total.toFormat(2)}
					</Text>
					<Text
						size={width < 900 ? 14 : 18}
						style={{ marginLeft: 5 }}>
						USDT
					</Text>
				</View>
				<View style={{ marginTop: 10 }}>
					<Text size={width < 900 ? 14 : 18}>
						≒ {equivValue.toFormat(0)} {currency}
					</Text>
				</View>
			</View>
		</AssetTotalValueLeftSection>
	)
}

export default AssetTotalValueLeft
