import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CSwitch } from 'app/components/Common'
import { useNotiSetting } from 'app/hooks/stateStore'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MNotiSettingItemContainer, MNotiSettingItemSection } from './styles'

const MNotiSettingItems = ({
	title,
	content,
	notiType,
	notiSwitch,
	notiSettting,
}: any) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const [filter, setFilter] = useState(notiSwitch)
	const { mutate: mutateNotiSetting } = useNotiSetting()
	const { t } = useTranslation()
	return (
		<MNotiSettingItemContainer>
			<MNotiSettingItemSection>
				<MView style={{ width: '80%' }}>
					<MText style={{ fontSize: 12 }}>{t(title)}</MText>
					<MText
						style={{
							fontSize: 10,
							paddingTop: 5,
							color: textsilver,
						}}>
						{content && t(content)}
					</MText>
				</MView>

				<MView style={{ width: '20%', alignItems: 'flex-end' }}>
					<CSwitch
						checked={filter}
						setChecked={setFilter}
						action={() => {
							toast(t(title) + '', t('notiChangedConfirm'))
							mutateNotiSetting({
								...notiSettting,
								[`${notiType}`]: Boolean(!filter),
							})
						}}
					/>
				</MView>
			</MNotiSettingItemSection>
		</MNotiSettingItemContainer>
	)
}

export default MNotiSettingItems
