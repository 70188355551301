import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { CButton } from '../Common'
import { SectionHeaderContainer } from './styles'
import { IconArrowNarrowRight } from '@tabler/icons'
import { CSSProperties } from 'styled-components'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
import { useWindowDimensions } from 'app/hooks'
import { useHistory } from 'react-router-dom'

interface Iprops {
	title?: any
	content?: any
	marginLeft?: Boolean
	goTo?: any
	style?: CSSProperties
}
const SectionHeader = ({ title, content, marginLeft, goTo, style }: Iprops) => {
	const navigate = useHistory()
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	const mobile = width < 600
	return (
		<SectionHeaderContainer>
			<View
				style={{
					alignItems: 'flex-start',
					marginLeft: marginLeft ? 0 : 40,
				}}>
				<Text
					size={mobile ? 18 : 30}
					style={{ fontWeight: '600', ...style }}>
					{t(title)}
				</Text>
				{content ? (
					<Text size={18} style={{ color: textsilver, marginTop: 8 }}>
						{t(content)}
					</Text>
				) : null}
			</View>
			{goTo ? (
				<CButton
					title={t('seeMore')}
					icon={
						<IconArrowNarrowRight
							size={18}
							color={COLORS.secondary}
						/>
					}
					onClick={() => {
						navigate.push(goTo)
					}}
					style={{
						color: COLORS.secondary,
						padding: mobile ? 3 : 5,
						fontSize: 12,
						// backgroundColor: COLORS.primary,
						marginRight: marginLeft ? 0 : 35,
					}}
					textHover={COLORS.BTN_HOVER}
				/>
			) : null}
		</SectionHeaderContainer>
	)
}

export default React.memo(SectionHeader)
