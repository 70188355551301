import React from 'react'
import { RiskHalf, RiskWarningList } from './styles'
import RiskWarningLeft from './RiskWarningLeft'
import RiskWarningTitle from './RiskWarningTitle'
import RiskWarningRight from './RiskWarningRight'
import { useTranslation } from 'react-i18next'

const RiskWarning = () => {
	const { t } = useTranslation()

	return (
		<RiskHalf>
			<RiskWarningTitle />
			{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
				(item, index) => (
					<RiskWarningList key={index.toString()}>
						<RiskWarningLeft
							title={t(`rickWarningTitle${index + 1}`)}
						/>
						<RiskWarningRight
							content={t(`rickWarningContent${index + 1}`)}
						/>
					</RiskWarningList>
				)
			)}
		</RiskHalf>
	)
}

export default RiskWarning
