import React from 'react'
import { AssetSpotHeaderSection } from '../styles'
import AssetSpotHeaderBottom from './AssetSpotHeaderBottom'
import AssetSpotHeaderTop from './AssetSpotHeaderTop'

const AssetSpotHeader = () => {
	return (
		<AssetSpotHeaderSection>
			<AssetSpotHeaderTop />
			<AssetSpotHeaderBottom />
		</AssetSpotHeaderSection>
	)
}

export default AssetSpotHeader
