import styled from 'styled-components'

export const HelpCenterContainerSection = styled.div`
	margin-top: 50px;
	width: 100%;
	height: 600px;
	padding: 20px;
	background-color: ${p => p.theme.COLORS.drawerBgColor};
	.ant-pagination-item-active {
		background-color: transparent;
	}
	.anticon {
		color: ${p => p.theme.COLORS.textw};
	}
`
export const HelpCenterHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	width: 100%;
`
export const HelpCenterListSection = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	border-top: 1px solid ${p => p.theme.COLORS.borderColor};
	cursor: pointer;
	&:hover {
		background: ${p => p.theme.COLORS.borderColor};
	}
`
export const HeaderDetailNextList = styled.div`
	height: 60%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	&:hover {
		background: ${p => p.theme.COLORS.borderColor};
	}
	cursor: pointer;
`
