import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

import { useThemeStore } from 'app/hooks'
import { useTimezone } from 'app/hooks/timezone'
const NotiItems = ({ item }) => {
	const { t } = useTranslation()

	const { borderColor } = useContext(ThemeContext).theme.COLORS
	const { data: themeStore } = useThemeStore()

	const getType = type => {
		switch (type) {
			case 1:
				return 'SYSTEM'
			case 2:
				return 'TRADE'
			case 3:
				return 'NEWS'
			default:
				return 'SYSTEM'
		}
	}

	return (
		<MView
			onClick={() => {
				// goTo(navigation, '/notiSetting')
			}}
			style={{
				height: 130,
				width: '100%',
				paddingInline: 16,
				paddingBlock: 23,
				borderTop: `1px solid ${borderColor}`,
				justifyContent: 'space-between',
				cursor: 'pointer',
				backgroundColor: item?.isread
					? 'rgba(100,100,100,0.1)'
					: 'transparent',
				borderBottomColor: themeStore?.darkMode
					? 'rgba(255, 255, 255, 0.1)'
					: 'rgba(0,0,0,0.1)',
			}}>
			<MView>
				<MText>[{t(getType(item?.type) + '')}]</MText>
			</MView>
			<MView style={{ paddingBlock: 10 }}>
				<MText style={{ marginBottom: 5 }}>{item?.title}</MText>
				<MText>{item?.content}</MText>
			</MView>
			<MView>
				<MText>
					{useTimezone(item?.senddate, 'YYYY-MM-DD HH:mm:ss', 9)}
				</MText>
			</MView>
		</MView>
	)
}

export default React.memo(NotiItems)
