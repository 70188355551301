import React, { useContext } from 'react'
import { Text } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import {
	NotificationItemBody,
	AnnouncementItemLeft,
	AnnouncementListItemSection,
} from './styles'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

const AnnouncementListItems = ({ item, index, show, setShow }) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	//거래소 이름 치환
	const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

	const currentname = t('title')

	const title = item?.title?.replace(
		new RegExp(list.join('|'), 'gi'),
		currentname
	)
	return (
		<AnnouncementListItemSection
			style={{ height: 60 }}
			onClick={() => {
				setShow({
					...show,
					show: !show.show,
					...item,
				})
			}}>
			<AnnouncementItemLeft>
				<Text style={{ color: textsilver }}>{item?.id}</Text>
			</AnnouncementItemLeft>
			<NotificationItemBody style={{ paddingBlock: 5 }}>
				<Text>{title}</Text>
				<Text
					style={{
						color: textsilver,
						width: '100%',
					}}>
					{/* <MarkdownComponent
						markdown={truncateText(item?.content, 50)}
					/> */}
				</Text>
				<Text style={{ color: COLORS.lightGray }}>
					{moment(item.rdate).format('YYYY-MM-DD')}
				</Text>
			</NotificationItemBody>
		</AnnouncementListItemSection>
	)
}

export default AnnouncementListItems
