import React from 'react'
import { MasterTraderSection, MasterTraderWrapper } from './styles'

const MasterTrader = () => {
	return (
		<MasterTraderSection style={{}}>
			<MasterTraderWrapper></MasterTraderWrapper>
		</MasterTraderSection>
	)
}

export default MasterTrader
