import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AboutValueTitle = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ marginBottom: 30 }}>
			<Text size={25} style={{ color: textw }}>
				{t('aboutTheBitValueTitle')}
			</Text>
		</View>
	)
}

export default AboutValueTitle
