import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const CopyTraderDetailWrapper = styled.div`
	width: 40%;
	height: 100%;
	background: linear-gradient(274.44deg, #090b1d, #020036);
	background-image: none();
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	color: ${COLORS.white};
`
