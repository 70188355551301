import React from 'react'
import { COLORS, FONTS } from 'app/constants'
import { Text, View } from 'styles/reactnative'

const CoinRate = ({ color, content }: any) => {
	return (
		<View
			style={{
				paddingLeft: 3,
				paddingRight: 3,
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 3,
				backgroundColor: `${color}22`,
			}}>
			<Text style={{ ...FONTS.h7, color }}>{content}</Text>
		</View>
	)
}

export default React.memo(CoinRate)
