import { useFetcherPublic } from 'app/hooks/fetcher'
import MCoinLists from 'app/mobile/components/MCoinLists'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MSearchHotSection } from './styles'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'

const MSearchHot = ({ setOpenSearch }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	let { EPs } = useExchangeStore()

	return (
		<MSearchHotSection>
			<MText style={{ color: textw, fontSize: 16, marginBottom: 5 }}>
				{t('hot')}
			</MText>

			<MCoinLists data={EPs} />
		</MSearchHotSection>
	)
}

export default MSearchHot
