import React from 'react'
import { View } from 'styles/reactnative'
import TraderProfileImage from './TraderProfileImage'
import TraderDetailInfo from './TraderDetailInfo'

const TraderDetailLeftSide = () => {
	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				alignSelf: 'flex-start',
				zIndex: 1,
				width: '100%',
				height: '100%',
				flexDirection: 'row',
			}}>
			<TraderProfileImage />
			<TraderDetailInfo />
		</View>
	)
}

export default React.memo(TraderDetailLeftSide)
