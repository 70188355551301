import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AnnounListItems = ({ item, index }) => {
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				marginBottom: 30,
				flexDirection: 'row',
			}}>
			<Text size={50} style={{ color: textsilver, marginRight: 20 }}>
				0{index + 1}
			</Text>
			<View style={{ alignItems: 'flex-start' }}>
				<Text
					size={20}
					style={{
						color: textw,
						fontWeight: '400',
						lineHeight: 2,
					}}>
					{t(item.title)}
				</Text>
				<Text
					size={16}
					style={{
						color: textw,
						fontWeight: '300',
						lineHeight: 1.5,
					}}>
					{t(item.content)}
				</Text>
			</View>
		</View>
	)
}

export default AnnounListItems
