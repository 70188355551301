import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AboutProoftText = ({ text }) => {
	const { t } = useTranslation()
	const { content } = useContext(ThemeContext).theme.COLORS
	return (
		<Text
			size={16}
			style={{
				color: content,
				lineHeight: 1.2,
				fontWeight: '400',
				marginBottom: 20,
			}}>
			{t(text)}
		</Text>
	)
}

export default AboutProoftText
