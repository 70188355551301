/* eslint-disable prettier/prettier */
import { COLORS } from 'app/constants/STYLES'
import styled, { keyframes } from 'styled-components'

const breatheAnimation = keyframes`
   0% {
        transform: translateZ(-100px) transLateY(50px)
    }
    80% {
        transform: translateZ(-10px) transLateY(0px)
    }
    100% {
        transform: translateZ(0px) transLateY(0px)
    }
`
export const CDropDownSection = styled.div``

export const DropdownContainer = styled.div`
	width: 100px;
	z-index: 1;
	position: absolute;
	background-color: ${props => props.theme.COLORS.bgColor};
	color: ${COLORS.lightGray3};
	margin-left: -50px;
	margin-top: 20px;
	font-size: 12px;
	border-radius: 5px;
	animation-name: ${breatheAnimation};
	animation-duration: 0.3s;
	box-shadow: 3px 5px 13px ${COLORS.lightGray};
`

export const DropdownText = styled.div<{ selected: any }>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	margin-block: 3px;
	padding-inline: 5px;
	&:hover {
		background-color: ${COLORS.grey};
		color: ${COLORS.primary};
	}
	${({ selected }) =>
		selected &&
		`
    background-color: ${COLORS.grey};
    color: ${COLORS.primary};
    `}
`
