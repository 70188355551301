import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import AccountDeposit from './AccountDeposit'
// import AccountConvert from './AccountConvert'
import AccountTransfer from './AccountTransfer'
import { ThemeContext } from 'theme/ThemeProvider'

const DerivativesAccountBtns = () => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
				flexDirection: 'row',
				justifyContent: 'space-between',
				marginTop: 10,
			}}>
			<AccountDeposit bgColor={gray} color={textw} />
			{/* <AccountConvert bgColor={gray} color={textw} /> */}
			<AccountTransfer bgColor={gray} color={textw} />
		</View>
	)
}

export default DerivativesAccountBtns
