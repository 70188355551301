/* eslint-disable prettier/prettier */
import { COLORS } from 'app/constants';
import styled from 'styled-components';

export const CustomTabSection = styled.div``;

export const StyledOuterSliders = styled.div`
    overflow: hidden;
    width: 100%;
`;

export const StyledSliders = styled.div<{offset ? : any; duration?:any}>`
    display: flex;
    flex-wrap: no-wrap;
    width: 100%;
    transform: translateX(${props => `${props.offset}%`});
    transition: transform ${props => props.duration}ms;
    div {
        flex-shrink: 0;
        width: 100% !important;
    }
`;
export const StyledTabs = styled.div`
    position: relative;
    list-style: none;
    height: 30px;
    width: 35%;
    display:flex;
    border-bottom:1px solid ${COLORS.grayWhite};
    padding-bottom: 40px;
`;
export const StyledTabIndicator = styled.div<{tabCount?:any;offset?:any;duration?:any}>`
    position: absolute;
    width: ${props => 100 / props.tabCount}%;
    top: 100%;
    left: 0;
    transform: translate(${props => props.offset}, -100%);
    transition: transform ${props => props.duration}ms;
    border-top-style: solid;
    border-top-width:2px;
    border-top-color: ${COLORS.BTN_HOVER};
`;

export  const StyledTab = styled.li<{isFocused?:any}>`
    /* flex:1; */
    margin-right: 20px;
    height: 100%;
    button {
        cursor: pointer;
        transition: color 0.3s;
        color: ${props => (props.isFocused ? COLORS.black : COLORS.lightGray5)};
        border: none;
        width: 100%;
        height: 100%;
        font-size: 15px;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0);
        
    }
`;
