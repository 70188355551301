import React from 'react'
import {
	CopyTradeEventHeader,
	CopyTradeRightSideSection,
	CopyTradeEventBody,
} from './styles'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { IconChevronRight } from '@tabler/icons'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CopyTradeRightSide = () => {
	const { t } = useTranslation()
	const navigation = useHistory()
	return (
		<CopyTradeRightSideSection
			onClick={() => {
				goTo(navigation, '/followertrader')
			}}>
			<CopyTradeEventHeader>
				<View>
					<Text size={16} style={{ color: COLORS.white }}>
						{t('myCopyTrading')}
					</Text>
				</View>
				<Text size={12} style={{ color: COLORS.white }}>
					{t('measuredIn')}: USDT
				</Text>
			</CopyTradeEventHeader>
			<CopyTradeEventBody>
				<View style={{ alignItems: 'flex-start' }}>
					<Text
						size={12}
						style={{ color: COLORS.white, marginBottom: 7 }}>
						{t('totalAssets')}
					</Text>
					<Text size={16} style={{ color: COLORS.white }}>
						****.** USDT
					</Text>
				</View>
				<View style={{ alignItems: 'flex-start' }}>
					<Text
						size={12}
						style={{ color: COLORS.white, marginBottom: 7 }}>
						{t('copyTradingP&L')}
					</Text>
					<Text size={16} style={{ color: COLORS.white }}>
						****.** USDT
					</Text>
				</View>
				<View>
					<IconChevronRight />
				</View>
			</CopyTradeEventBody>
		</CopyTradeRightSideSection>
	)
}

export default React.memo(CopyTradeRightSide)
