import { goTo } from 'app/hooks/navigation'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Text, View } from 'styles/reactnative'
import { toShortNumber } from '../../../../../utils/ciutils'

const ListVolume = ({ volume, item, setDisplayDrawer }) => {
	const navigation = useHistory()
	return (
		<View
			onClick={() => {
				goTo(navigation, `/trade/${item?.id}`)
				setDisplayDrawer(false)
			}}
			style={{
				width: '20%',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'row',
			}}>
			<Text
				style={{
					width: '40%',
					fontSize: 12,
				}}>
				{toShortNumber(volume)}
			</Text>
		</View>
	)
}

export default ListVolume
