import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const RiskWarningRight = ({ content }) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '70%',
				alignItems: 'flex-start',
			}}>
			<Text
				size={16}
				style={{
					color: textsilver,
					lineHeight: 1.7,
					fontWeight: '300',
				}}>
				{content}
			</Text>
		</View>
	)
}

export default RiskWarningRight
