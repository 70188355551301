import React, { useContext } from 'react'
import { MView } from 'styles/mobilePageStyles'
import OrderItem from './OrderItem'
import BigNumber from 'bignumber.js'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
import { useExchangeSetting } from '../../../../hooks/trade/exchangeSetting'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { COLORS, FONTS } from '../../../../constants'
import { Text } from '../../../../../styles/reactnative'
import OrderItemHeader from './OrderItemHeader'
import { CButton } from '../../../../components/Common'
import API from '../../../../../utils/api'
import { CANCELORDER } from '../../../../constants/APIKEYS'
import { useToken } from '../../../../hooks'
import { useTradeOrder } from '../../../../hooks/trade/tradeOrder'
import { toast } from 'react-toastify'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'

const MOrderCurrentItems = ({ item }) => {
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const { EPs } = useExchangeStore()
	const EP = EPs?.find((item2: any) => +item2.id == +item?.exchange_pairs_id)
	let { getPrice } = useIndexPrice(item?.exchange_pairs_id)
	const { data: token } = useToken()
	const currency = EP?.pairs?.split('/')[1]
	let pnl = 0

	const indexPrice = getPrice(item?.exchange_pairs_id)

	if (item?.long_short === 'L') {
		pnl = BigNumber(indexPrice)
			.minus(item?.price)
			.multipliedBy(item?.amount)
			.multipliedBy(item?.leverage)
			.toNumber()
	} else {
		pnl = BigNumber(item?.price)
			.minus(indexPrice)
			.multipliedBy(item?.amount)
			.multipliedBy(item?.leverage)
			.toNumber()
	}
	const longShort = item?.long_short === 'L' ? 'long' : 'short'
	const lsColor =
		item?.long_short === 'L' ? COLORS.lightGreen : COLORS.lightRed

	const { mutate: mutateOrderList } = useTradePosition()

	let buttonlock = false
	let tempdelete = false

	if (tempdelete) {
		return null
	}

	return (
		<MView
			style={{
				alignSelf: 'center',
				borderRadius: 10,
				padding: 10,
				paddingBottom: 15,
				paddingTop: 15,
				backgroundColor: THEMECOLORS.bgColor,
				width: '100%',
			}}>
			<MView style={{ width: '100%' }}>
				<OrderItemHeader
					pairs={EP?.pairs}
					longShort={longShort}
					lsColor={lsColor}
				/>
			</MView>
			<MView style={{ width: '100%', flexDirection: 'row' }}>
				<OrderItem
					title={'orderType'}
					content={
						item?.limit_market == 'L' ? t('limit') : t('market')
					}
					contentColor={THEMECOLORS.textw}
				/>
				<OrderItem
					title={'filledTotal'}
					content={new BigNumber(item?.amount)
						?.multipliedBy(item?.leverage)
						.toNumber()}
					contentColor={THEMECOLORS.textw}
				/>
				<OrderItem
					title={'orderPrice'}
					content={item?.order_price}
					contentColor={THEMECOLORS.textw}
				/>
			</MView>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}>
				<CButton
					title={t('cancel')}
					onClick={() => {
						if (tempdelete || buttonlock) return
						buttonlock = true

						API.post(CANCELORDER, token + '', {
							orderid: item?.id,
						}).then(res => {
							buttonlock = false
							tempdelete = true
							mutateOrderList()
							toast(t('cancel') + '')
						})
					}}
					style={{
						background: THEMECOLORS.borderColor,
						color: THEMECOLORS.textw,
						borderRadius: 5,
						marginLeft: 10,
						marginTop: 5,
					}}
				/>
			</MView>
		</MView>
	)
}
export default MOrderCurrentItems
