import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { MyAssetSection } from '../styles'
import MyAssetLeftSection from './MyAssetLeftSection'
import MyAssetRightSection from './MyAssetRightSection'
import { MyAssetBothSection } from './styles'

const MyAssets = () => {
	const { t } = useTranslation()
	return (
		<MyAssetSection>
			<View
				style={{
					width: '100%',
					height: '10%',
					alignItems: 'flex-start',
					paddingInline: 25,
					marginTop: 15,
				}}>
				<Text size={20}>{t('assets')}</Text>
			</View>
			<MyAssetBothSection>
				<MyAssetLeftSection />
				<MyAssetRightSection />
			</MyAssetBothSection>
		</MyAssetSection>
	)
}

export default MyAssets
