import React, { useState, useContext } from 'react'
import { IconMenu2, IconRefresh, IconX } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
	HeaderContainer,
	HeaderButtonWrap,
	NavBarToggle,
	MobileView,
} from './styles'

import { AppLogo } from 'app/components/'
import { useToken, useWindowDimensions } from 'app/hooks'
import { Text, View } from 'styles/reactnative'
import { market, trade, tools, crypto } from '../../constants/UIData/HEADER'
import { useCheckTradePage } from 'utils/ExpensiveFunction'

import BtnLanguageChange from './BtnLanguageChange'
import HeaderDrawer from './HeaderDrawer'
import HeaderSearch from './HeaderSearch'
import HeaderButton from './HeaderButton'
import AuthHeaderMenu from './AuthHeaderMenu'
import DarkModeToggle from './DarkModeToggle'
import UnAuthHeaderMenu from './UnAuthHeaderMenu'
import { ThemeContext } from 'theme/ThemeProvider'
import { CToast } from 'app/components/Common'
import TradeModal from '../../pages/TradeModal'
import { Button, Popover, Space } from 'antd'
import { ContentSplit } from '../../../styles/login'
import { GLOBAL_URL } from '../../constants/APIKEYS'

const Header = ({
	zIndex = 2,
	showSelect = false,
	setShowSelect = e => {},
}) => {
	const { width } = useWindowDimensions()
	const { theme, isDarkMode } = useContext(ThemeContext)
	const { textw, bgColor, content, stroke } = theme.COLORS

	const { t } = useTranslation()
	const navigate = useHistory()

	const { data: token, expire, refreshToken } = useToken()

	const [displayNav, setDisplayNav] = useState('none')

	const nav = (url: string) => navigate.push(url)
	const toggleNavBar = () => {
		setDisplayNav(prevState => (prevState === 'none' ? 'flex' : 'none'))
	}
	const isTrade = useCheckTradePage()

	return (
		<HeaderContainer
			background={bgColor}
			style={{
				height: isTrade ? 45 : 80,
				zIndex: zIndex,
				borderBottom: '1px solid ' + stroke,
			}}>
			{/* navbar Toggle  Menu Icon*/}
			<NavBarToggle onClick={() => toggleNavBar()}>
				<IconMenu2 color={textw} />
			</NavBarToggle>
			{/* Navbar Header Drawer Menu Component */}
			<HeaderDrawer
				displayNav={displayNav}
				setDisplayNav={() => toggleNavBar()}
			/>
			{/* App Logo */}
			<MobileView>
				<AppLogo height={30} onPress={() => nav('/')} />
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					{width > 600 ? <HeaderSearch /> : null}
					{!token ? width > 600 ? <UnAuthHeaderMenu /> : null : null}
					{token ? <AuthHeaderMenu /> : null}
				</View>
			</MobileView>
			<HeaderButtonWrap display={displayNav}>
				{/* Menu Left Side */}
				<View
					style={{
						flexDirection: 'row',
						minWidth: 550,
					}}>
					<View style={{ flexDirection: 'row' }}>
						<HeaderButton label={t('buyCrypto')} data={crypto} />
						<HeaderButton label={t('btnMarkets')} data={market} />
						<HeaderButton label={t('trade')} data={trade} />
					</View>
					<HeaderSearch />
				</View>

				<AppLogo
					width={100}
					onPress={() => {
						nav('/')
						//setShowSelect(true)
					}}
				/>
				<View
					style={{
						position: 'absolute',
						marginLeft: token ? (isTrade ? 210 : 250) : 250,
					}}>
					<Space.Compact size="small">
						<Button
							type="primary"
							size="large"
							onClick={() => {
								window.location.href = '/'
								setShowSelect(false)
							}}
							style={{
								background: !isDarkMode ? '#2675AE' : '#2675AE',
							}}>
							{t('COINFUTURES')}
						</Button>
						<Button
							type="default"
							size="large"
							style={{
								background: !isDarkMode ? '#121212' : '#f6f6f6',
								color: !isDarkMode ? '#AEAEAE' : '#828282',
								border: '1px solid #AEAEAE',
							}}
							onClick={() => {
								window.location.href =
									GLOBAL_URL +
									'/login?' +
									(token
										? 'ssotoken=' + token
										: 'skip=true') +
									'&darkmode=' +
									(isDarkMode ? 'dark' : 'light')
							}}>
							{t('GLOBAL')}
						</Button>
					</Space.Compact>
				</View>
				<View
					style={{
						flexDirection: 'row',
						minWidth: isTrade ? 580 : 550,
						justifyContent: 'flex-end',
					}}>
					{!token ? <UnAuthHeaderMenu /> : null}
					{token ? <AuthHeaderMenu /> : null}
					<View
						style={{
							width: 1,
							height: 15,
							marginRight: 10,
							background: '#f1f1f1',
						}}
					/>
					<DarkModeToggle />
					<BtnLanguageChange label={'Language'} />
				</View>
			</HeaderButtonWrap>
			<CToast />
			<TradeModal />

			{/* <SessionDisplay /> */}
		</HeaderContainer>
	)
}

export default React.memo(Header)
