import React from 'react'
import { View } from 'styles/reactnative'
import cryptocurrencies from 'assets/images/cryptocurrencies.png'
import LatestReserveItem from './LatestReserveItem'
import { useTranslation } from 'react-i18next'

const LatestReserveCards = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',
				marginTop: 40,
			}}>
			<LatestReserveItem
				title={t('latestReserveCardTitle1')}
				contentData={t('latestReserveCardContent1')}
				image={cryptocurrencies}
				id={'e062597b792cefa9'}
			/>
			<LatestReserveItem
				title={t('latestReserveCardTitle2')}
				contentData={t('latestReserveCardContent2')}
				image={cryptocurrencies}
				id={'246%'}
			/>
		</View>
	)
}

export default LatestReserveCards
