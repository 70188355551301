import React from 'react'
import { SectionContainer, SectionWrap } from '../../globalOrganismStyles'
import { View } from 'styles/reactnative'
import AnnouncementSectionList from './AnnouncementSectionList'
import AnnouncementSectionImage from './AnnouncementSectionImage'
import AnnouncementSectionTitle from './AnnouncementSectionTitle'

const AnnouncementSection = () => {
	return (
		<SectionContainer>
			<SectionWrap
				style={{
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					flexDirection: 'column',
				}}>
				<AnnouncementSectionTitle />
				<View
					style={{
						width: '100%',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						height: 500,
						flexDirection: 'row',
					}}>
					<AnnouncementSectionList />
					<AnnouncementSectionImage />
				</View>
			</SectionWrap>
		</SectionContainer>
	)
}

export default AnnouncementSection
