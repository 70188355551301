import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const RicketWarningContext = ({ text }) => {
	const { t } = useTranslation()
	const { content } = useContext(ThemeContext).theme.COLORS
	return (
		<MView style={{ marginTop: 10 }}>
			<MText
				style={{
					fontSize: 14,
					fontWeight: '400',
					lineHeight: 1.5,
					color: content,
				}}>
				{t(text)}
			</MText>
		</MView>
	)
}

export default RicketWarningContext
