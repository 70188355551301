import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import NotificationMenu from './NotificationMenu'
import { NotificationNavBarSection } from './styles'

const NotificationNavBar = ({ tab, setTab, count, search, setSearch }) => {
	const { textsilver, gray, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	// Order Type
	const all = 'all'
	// const systemNotification = 'systemNotification'
	// const trade = 'trade'
	// const NEWS = 'NEWS'

	const notificationMenus = [
		{
			name: all,
			index: 0,
		},
	]

	return (
		<NotificationNavBarSection>
			<View style={{ flexDirection: 'row', height: '100%' }}>
				<Text size={20}>{t('total')}</Text>
				<Text
					size={16}
					style={{ color: COLORS.secondary, marginLeft: 10 }}>
					{count}
				</Text>
			</View>
			<View style={{ flexDirection: 'row' }}>
				<NotificationMenu
					menus={notificationMenus}
					tab={tab}
					setTab={setTab}
				/>
				<CInput
					placeholder={t('searchNotification')}
					value={search}
					type={'text'}
					onChange={(e: any) => setSearch(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 8,
					}}
					style={{
						background: gray,
						border: `1px solid ${textsilver}`,
						color: textw,
						padding: 5,
						paddingLeft: 15,
						width: '100%',
					}}
				/>
			</View>
		</NotificationNavBarSection>
	)
}

export default NotificationNavBar
