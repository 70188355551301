import { CImage } from 'app/components/Common'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MNoticeSection } from './styles'
import hat from 'assets/images/hat.png'

import google from 'assets/images/google.png'
import apple from 'assets/images/apple.png'
import { Text, View } from '../../../../../styles/reactnative'
import { message } from 'antd'
import { COLORS } from '../../../../constants'

const MNotice = () => {
	const { t } = useTranslation()
	const { cardOpacity } = useContext(ThemeContext).theme.COLORS

	const data = [
		{
			title: 'googlePlay',
			image: google,
			goTo: '',
		},
		{
			title: 'appStore',
			image: apple,
			goTo: '',
		},
	]
	return (
		<MNoticeSection>
			<MView
				style={{
					background: cardOpacity,
					width: '94%',
					height: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: 30,
				}}>
				<MView style={{ width: '70%', height: '100%' }}>
					<MText
						style={{
							fontSize: 18,
							fontWeight: 'bold',
						}}>
						{t('downloadApp')}
					</MText>
				</MView>
				<View
					style={{
						width: '100%',
						height: '80%',
						justifyContent: 'space-evenly',
						flexDirection: 'row',
					}}>
					{data.map((item, index) => (
						<View
							key={index.toString()}
							onClick={() => {
								message.info(t('comingSoon'))
							}}
							style={{ cursor: 'pointer', marginTop: 10 }}>
							<CImage
								src={item?.image}
								alt="logo"
								width={26}
								height={29}
								resizeMode={'cover'}
							/>
							<Text
								size={12}
								style={{
									color: COLORS.lightGray,
									marginTop: 5,
								}}>
								{t(item?.title)}
							</Text>
						</View>
					))}
				</View>
			</MView>
		</MNoticeSection>
	)
}

export default MNotice
