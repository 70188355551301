import React, { useContext } from 'react'
import { CButton } from 'app/components/Common'
import { View } from 'styles/reactnative'
import { COLORS, FONTS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
import { IconStarFilled } from '@tabler/icons-react'

const MarketTopTabs = ({ tab, setTab, tabs }: any) => {
	const { textw, drawerBgColor, borderColor } =
		useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'flex-start',
				background: 'transparent',
				zIndex: 1,
				backgroundColor: drawerBgColor,
				borderBottom: `1px solid ${borderColor}`,
				height: 70,
				paddingInline: 20,
			}}>
			{tabs.map((item: any, index: any) => (
				<CButton
					key={index.toString()}
					title={t(item)}
					onClick={() => {
						setTab(item)
					}}
					iconLeft={true}
					icon={
						item === 'favourites' ? (
							<IconStarFilled
								size={18}
								style={{
									color:
										tab === item ? textw : COLORS.lightGray,
									marginRight: 5,
									marginTop: -2,
								}}
							/>
						) : null
					}
					style={{
						...FONTS.h5,
						color: tab === item ? textw : COLORS.lightGray,
						fontWeight: tab === item ? '500' : '400',
						paddingRight: 15,
						alignItems: 'center',
						backgroundColor:
							tab === item ? drawerBgColor : 'transparent',
						// borderTopRightRadius: 10,
						// borderTopLeftRadius: 10,
						zIndex: tab === item ? 1 : 0,
						borderRadius: 0,
					}}
				/>
			))}
		</View>
	)
}

export default React.memo(MarketTopTabs)
