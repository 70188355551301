import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { VerticalTimeline } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import StatisticTimelineItem from './StatisticTimelineItem'
import { IconChevronRight } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { ThemeContext } from 'theme/ThemeProvider'

const StatisticTimeline = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				marginTop: 20,
			}}>
			<VerticalTimeline
				layout={'1-column-left'}
				lineColor={COLORS.greyWhite}>
				{[0, 1, 2, 3, 4, 5].map((item, index) => (
					<StatisticTimelineItem
						item={item}
						index={index}
						key={index.toString()}
					/>
				))}
			</VerticalTimeline>

			<CButton
				title={'View All'}
				icon={
					<IconChevronRight
						className="viewBtnIcon"
						size={16}
						style={{ marginTop: -2 }}
					/>
				}
				onClick={() => {
					console.log('View All')
				}}
				style={{
					color: textw,
					alignSelf: 'flex-start',
					marginLeft: 25,
				}}
				textHover={COLORS.primary}
			/>
		</View>
	)
}

export default React.memo(StatisticTimeline)
