import styled from 'styled-components'

export const ProfileEditModalWrapper = styled.div`
	background-color: ${p => p.theme.COLORS.subHeader};
	border-radius: 5px;
	align-items: flex-start;
	flex-direction: row;
	padding: 20px;
`
export const LanguageModalBodyWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 20px;
	width: 100%;
`
