import React, { useContext } from 'react'
import { MButton } from 'app/mobile/components/'
import { EventTabSection } from './styles'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'

const EventTabs = ({ tab, setTab }) => {
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS
	const tabs = [
		{
			title: 'hotCoins',
			tab: 0,
		},
	]
	return (
		<EventTabSection>
			{tabs?.map((item, index) => (
				<MButton
					key={index.toString()}
					title={item?.title}
					borderColor={item?.tab === tab ? COLORS.secondary : null}
					onClick={() => {
						setTab(item.tab)
					}}
					textStyle={{
						color: item?.tab === tab ? textw : textsilver,
					}}
				/>
			))}
		</EventTabSection>
	)
}

export default EventTabs
