import React, { useRef } from 'react'
import {
	AskRow,
	BidBar,
	OrderBookContainer,
	Price,
	Quantity,
	Total,
} from './styles'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import { getPercentageFromPrice, padDP } from '../../../../../utils/ciutils'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import { Text } from '../../../../../styles/reactnative'
import { COLORS } from '../../../../constants'
import BigNumber from 'bignumber.js'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index'

const AsksSection = ({ asks, max, selected }: any) => {
	const { data, mutate: mutateTradeStore } = useTradeStore()
	const animateRef = useRef<HTMLDivElement>(null)

	const { orderlist: list } = useTradePosition()

	if (
		animateRef.current &&
		animateRef.current.classList.contains('flashred')
	) {
		const animationEnd = () => {
			animateRef.current?.classList.remove('flashred')
		}
		animateRef.current.addEventListener('animationend', animationEnd)
	}

	return (
		<OrderBookContainer style={{ justifyContent: 'flex-end' }}>
			{asks?.map((item: any, index: number) => {
				//const flash = flashQueue?.includes(item?.price+'')

				const check = list?.find(
					(i: any) => i?.order_price === item?.price
				)

				return (
					<AskRow
						key={index.toString()}
						onClick={() => {
							mutateTradeStore({
								...data,
								order_price: new BigNumber(item?.price).toNumber(),
							})
						}}>
						<Price asks={true} style={{whiteSpace:'nowrap'}}>
							{padDP(item?.price, data?.dp)}
							{check ? (
								<span
									style={{
										color: COLORS.lightRed,
										fontSize: 16,
										fontWeight: 'bold',
										position:'relative',
										left: 1,
										zIndex:0,
									}}>
									ㆍ
								</span>
							) : null}
						</Price>
						<Quantity className={'flashred'}>
							{BigNumber(item?.amount || 0)
								.plus(check?.remain_amount || 0)
								.toFixed(data?.dp2 || data?.dp | 3)}
						</Quantity>
						<Total
							style={{
								minWidth: '90px',
								transition: 'all 300ms ease',
								backgroundSize: `${getPercentageFromPrice(
									item?.sumAmount,
									0,
									max
								)}% 100%`,
								backgroundPosition: `${getPercentageFromPrice(
									item?.sumAmount,
									0,
									max
								)}% left`,
								marginLeft: '10px',
								backgroundImage: `linear-gradient(to right, #284cce28 ${getPercentageFromPrice(
									item?.sumAmount,
									0,
									max
								)}%  0%)`,
							}}>
							{padDP(item?.sumAmount, data?.dp2 || data?.dp)}
							<BidBar
								asks={false}
								style={{ position: 'absolute', width: '100%' }}
							/>
						</Total>
					</AskRow>
				)
			})}
		</OrderBookContainer>
	)
}

export default React.memo(AsksSection)
