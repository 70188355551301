import { FONTS } from 'app/constants'
import React, { useContext } from 'react'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const CloseContext = () => {
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				background: isDarkMode
					? 'rgba(52, 49, 64, 0.1)'
					: 'rgba(52, 49, 64, 0.8)',
				marginTop: 20,
				padding: 10,
			}}>
			<Text
				style={{
					...FONTS.h7,
					lineHeight: 1.2,
				}}>
				contract (s) will be closed at last price,and your (inclusive of est.closing
				fees 0.0006 USDT)
			</Text>
		</View>
	)
}

export default CloseContext
