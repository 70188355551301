import React, {
	useState,
	useMemo,
	useRef,
	useCallback,
	useContext,
} from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { useWindowDimensions } from 'app/hooks/'
import { View } from 'styles/reactnative'
import { loadData } from 'utils/api'

import { CopiedTradeTableData } from './PastTradesTableData'
import { TraderTableListSection, TraderTableContainer } from '../styles'
import { ThemeContext } from 'theme/ThemeProvider'

const TradersFollowersTable = () => {
	const { bgColor } = useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const gridRef = useRef<any>()
	const [rowData, setRowData] = useState([])
	const [columnDefs] = useState(CopiedTradeTableData())

	const onGridReady = useCallback(params => {
		loadData().then(res => {
			setRowData(res)
		})
	}, [])

	const defaultColDef = useMemo(
		() => ({
			flex: width > 1400 ? 1 : 0,
			minWidth: 100,
			sortable: true,
			resizable: true,
		}),
		[width]
	)

	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				width: '100%',
				height: 1080,
			}}>
			<TraderTableListSection>
				<TraderTableContainer
					className="ag-theme-alpine"
					style={{ width: '100%', height: 750 }}>
					<AgGridReact
						rowStyle={{
							backgroundColor: bgColor,
							justifyContent: 'center',
							alignItems: 'center',
							alignSelf: 'center',
							paddingTop: 10,
						}}
						animateRows={true}
						ref={gridRef}
						rowData={rowData}
						columnDefs={columnDefs}
						// onRowClicked={({ data }: any) => {
						//     const coinName = data?.market?.slice(4);
						//     goTo(navigation, `/trade/${coinName}`);
						// }}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
						// enableRangeSelection={true}
						rowHeight={60}
						paginationAutoPageSize={true}
						pagination={true}
					/>
				</TraderTableContainer>
			</TraderTableListSection>
		</View>
	)
}

export default React.memo(TradersFollowersTable)
