import styled from 'styled-components'

export const OrderContainerSection = styled.div<{ theme: any }>`
	width: 100%;
	min-height: 600px;
	height: 100%;
	background: ${props => props.theme.COLORS.drawerBgColor};

	& .ant-card {
		background: ${props => props.theme.COLORS.drawerBgColor} !important;
		border: 0;
		color: ${props => props.theme.COLORS.textw};
		border-radius: 0;
	}

	& .ant-table {
		border-radius: 0;
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
	}

	& .ant-table-container {
		border-start-start-radius: 0 !important;
		border-start-end-radius: 0 !important;
	}

	& .ant-table-header {
		border-radius: 0 !important;
	}

	& .ant-table-header .ant-table-cell {
		border-radius: 0;
	}

	& .ant-table-body .ant-table-cell {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
		border-bottom: 1px solid ${props => props.theme.COLORS.gray} !important;
	}
	
	& .ant-table-row:hover .ant-table-cell{
		background: ${props => props.theme.COLORS.drawerBgColor} !important;
		color: ${props => props.theme.COLORS.textw};
	}

	& .ant-table-footer {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
		border-radius: 0;
	}
	& .ant-pagination .ant-pagination-item a {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw};
	}
	& .ant-pagination li button {
		background: ${props => props.theme.COLORS.bgColor} !important;
		color: ${props => props.theme.COLORS.textw}  !important;
	}
`
