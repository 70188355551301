import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const PerformanceDetailHeader = () => {
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				alignItems: 'flex-start',
				alignSelf: 'flex-start',
				borderBottom: `1px solid ${borderColor}`,
				width: '100%',
				padding: '16px 24px',
			}}>
			<Text size={16} style={{ fontWeight: '600' }}>
				{t('performance')}
			</Text>
		</View>
	)
}

export default PerformanceDetailHeader
