import styled from 'styled-components'

export const AssetUsdcDerivativeSection = styled.div`
	display: flex;
	flex-direction: column;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
export const AssetUsdcDerivativeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	margin-top: 30px;
`
export const AssetUsdcSectionWrapper = styled.div`
	height: 250px;
	width: 100%;
	padding: 20px;
	background: ${p => p.theme.COLORS.drawerBgColor};
`
