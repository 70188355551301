import { useThemeStore } from 'app/hooks'
import React from 'react'
import { MView } from 'styles/mobilePageStyles'

const PageBorder = ({ style }: any) => {
	const { data: themeStore } = useThemeStore()

	return (
		<MView
			style={{
				height: 4,
				width: '100%',
				background: themeStore?.darkMode
					? 'rgba(0,0,0,0.05)'
					: 'rgba(255,255,255,0.05)',
				...style,
			}}
		/>
	)
}

export default PageBorder
