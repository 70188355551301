import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const RickWarningText = ({ title, content }) => {
	const { content: contentColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<MView style={{ marginBlock: 20 }}>
			<MView>
				<MText
					style={{
						fontSize: 16,
						fontWeight: '500',
						lineHeight: 1.5,
					}}>
					{t(title)}
				</MText>
			</MView>
			<MView style={{ marginTop: 10 }}>
				<MText
					style={{
						fontSize: 14,
						fontWeight: '400',
						lineHeight: 1.5,
						color: contentColor,
					}}>
					{t(content)}
				</MText>
			</MView>
		</MView>
	)
}

export default RickWarningText
