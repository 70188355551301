import React from 'react'
import { fetcher } from 'app/hooks/fetcher'
import { Routes } from 'app/routes'
import { SWRConfig } from 'swr'
import ThemeProvider from 'theme/ThemeProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_CLIEND_ID } from 'app/constants/APIKEYS'

const cacheProvider: any = map => {
	// const map = new Map(
	// 	sessionStorage.getItem('save') == '1'
	// 		? JSON.parse(localStorage.getItem('app-cache') || '[]')
	// 		: []
	// )

	map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))
	window.addEventListener('beforeunload', () => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
		//sessionStorage.setItem('save', '1')
	})
	window.addEventListener('pagehide', () => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
		//sessionStorage.setItem('save', '1')
	})

	setInterval(() => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
	}, 5000)

	return map
}

const App = () => {
	return (
		<GoogleOAuthProvider clientId={GOOGLE_CLIEND_ID}>
			<SWRConfig
				value={{
					fetcher: fetcher,
					provider: cacheProvider,
				}}>
				<ThemeProvider>
					<Routes />
				</ThemeProvider>
			</SWRConfig>
		</GoogleOAuthProvider>
	)
}

export default App
