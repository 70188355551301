import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TradingFeeRateContainer, TradingFeeRateSection } from './styles'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import ReservesDetailHeader from './ReservesDetailHeader'
import ReservesDetailItems from './ReservesDetailItems'
import TradingLevelFee from '../TradingLevelFee'
import { useFetcher } from '../../../../hooks/fetcher'
const TradingFeeRate = ({ title, tableHeader, tableList }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<TradingFeeRateSection>
			<View
				style={{
					width: '100%',
					alignItems: 'flex-start',
					marginBottom: 20,
				}}>
				<TradingLevelFee title={title} />
				<Text
					size={16}
					style={{ color: textw, fontWeight: '500', marginTop: 20 }}>
					{t('feeRate')}
				</Text>
			</View>
			<TradingFeeRateContainer>
				<ReservesDetailHeader header={tableHeader} />
				<View style={{ width: '100%' }}>
					{tableList?.map((item, index) => (
						<ReservesDetailItems
							key={index.toString()}
							item={item}
							data={tableList}
						/>
					))}
				</View>
			</TradingFeeRateContainer>
		</TradingFeeRateSection>
	)
}

export default TradingFeeRate
