import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'

import VerificationList from './VerificationList'

const VerifyContainer = () => {
	const { t } = useTranslation()
	const [show, setShow] = useState(false)
	return (
		<MView
			style={{
				width: '100%',
				minHeight: 580,
				paddingInline: 5,
			}}>
			<MView
				style={{
					width: '100%',
					alignItems: 'center',
					paddingBlock: 20,
				}}>
				<MText style={{ fontSize: 16 }}>
					{t('identityVerification')}
				</MText>
			</MView>
			<VerificationList level={1} />
			<VerificationList level={2} show={show} setShow={setShow} />
		</MView>
	)
}

export default VerifyContainer
