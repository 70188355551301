import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { CButton } from '../Common'

const AuthCheckText = () => {
	const navigation = useHistory()
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				color: COLORS.greyWhite,
				marginTop: 70,
			}}>
			<Text>{t('please')}</Text>
			<CButton
				title={t('login')}
				onClick={() => {
					navigation.push('/login')
				}}
				style={{ color: COLORS.primary }}
			/>
			<Text>{t('or')}</Text>
			<CButton
				title={t('signUp')}
				onClick={() => {
					navigation.push('/signup')
				}}
				style={{ color: COLORS.primary }}
			/>
			<Text>{t('first')}</Text>
		</View>
	)
}

export default AuthCheckText
