import React, { useEffect, useMemo, useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import NotificationList from './NotificationList'
import NotificationHeader from './NotificationHeader'

import { NotificationContainer, NotificationSection } from './styles'
import NotificationNavBar from './NotificationNavBar'
import { useToken } from 'app/hooks'
import { fetcher } from 'app/hooks/fetcher'

const Notification = () => {
	const [search, setSearch] = useState('')
	const [tab, setTab] = useState({ index: 0, name: 'all' })
	const { data: token } = useToken()
	const [page, setPage] = useState(20)
	const [list, setList] = useState([])

	const { data: res } = useSWRInfinite(
		index =>
			token
				? `/system/notification/?page=${
						index + 1
				  }&pagesize=${page}&type=${tab?.index}`
				: null,
		async key => {
			return await fetcher(key, token, false)
		}
	)
	const data = useMemo(() => res?.[0]?.result?.result?.result || [], [res])
	const count = res?.[0]?.result?.result?.count || 0

	useEffect(() => {
		const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		const result = data?.filter(item => {
			const regex = new RegExp(escapedSearch, 'i')
			return regex.test(item?.title)
		})

		setList(result)
	}, [search, data])
	return (
		<NotificationSection>
			<NotificationHeader />
			<NotificationContainer>
				<NotificationNavBar
					tab={tab}
					setTab={setTab}
					count={count}
					search={search}
					setSearch={setSearch}
				/>
				<NotificationList
					data={list}
					page={page}
					setPage={setPage}
					count={count}
				/>
			</NotificationContainer>
		</NotificationSection>
	)
}

export default React.memo(Notification)
