import React from 'react'
import MCoinLists from 'app/mobile/components/MCoinLists'
import { MMarketListSection } from '../../styles'
import { useFetcherPublic } from 'app/hooks/fetcher'
import MarketListHeader from '../../MarketListHeader'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'

const MarketDerivativesInverse = () => {
	let { EPs } = useExchangeStore()

	return (
		<MMarketListSection>
			<MarketListHeader />
			<MCoinLists data={EPs} />
		</MMarketListSection>
	)
}

export default MarketDerivativesInverse
