import React, { useState } from 'react'
import { Text, View } from 'styles/reactnative'

import { COLORS } from 'app/constants'
import { DepositChainTypeListWrapper } from '../../styles'
import { useDepositStore } from '../../../../hooks/deposit/store'

const DepositChainTypeLists = ({ setModal }) => {
	const [data] = useState(['TRC20', 'BSC', 'ERC20'])

	const { data: depositStore, mutate } = useDepositStore({
		network: 'TRC20',
		coin: 'USDT',
	})

	return (
		<DepositChainTypeListWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					width: '100%',
					height: '100%',
				}}>
				{data?.map((item: any, index) => (
					<View
						onClick={() => {
							depositStore.network = item
							setModal(true)
							mutate({ ...depositStore, network: item })
						}}
						key={index.toString()}
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							padding: 5,
							cursor: 'pointer',
						}}>
						<View style={{ flexDirection: 'row' }}>
							<Text
								size={14}
								style={{
									marginLeft: 5,
									color: COLORS.lightGray,
								}}>
								{item}
							</Text>
						</View>
					</View>
				))}
			</View>
		</DepositChainTypeListWrapper>
	)
}

export default DepositChainTypeLists
