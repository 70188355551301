/**
 * Asynchronously loads the component for AnnouncementPage
 */

import { lazyLoad } from 'utils/loadable'

export const AboutsPage = lazyLoad(
	() => import('./index'),
	module => module.AboutsPage
)
