import BigNumber from 'bignumber.js'

/* eslint-disable no-useless-escape */
export default class CIUtils {
	static stockid(id: number) {
		let n: string = id + ''
		return n.length >= 6 ? n : new Array(6 - n.length + 1).join('0') + n
	}

	static stockidFromResult(result: any) {
		if (!result) return result

		result.map((item: any) => {
			this.stockid(item.id)
			return result
		})

		return result
	}
	static validateEmail = email => {
		const regex =
			/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
		return regex.test(email)
	}

	static removeWhitespace = text => {
		const regex = /\s/g
		return text.replace(regex, '')
	}

	static padId(id: number) {
		let n: string = id + ''
		return (
			'43' +
			(n.length >= 6 ? n : new Array(6 - n.length + 1).join('0') + n)
		)
	}
}

export const getPercentageFromPrice = (
	price: number,
	min: number,
	max: number
) => {
	return Math.ceil(((price - min) / (max - min)) * 100)
}

export const padDP = (number: number, dp: number) => {
	try {
		if (!dp) dp = 1
		const n = new BigNumber(number)
		return n.toFormat(dp)
	} catch (e) {
		console.error(e)
		return number.toFixed(dp)
	}
}

export const toShortNumber = value => {
	const number = new BigNumber(value)
	let shortNumber: any = number

	if (number.isGreaterThanOrEqualTo(1e12)) {
		shortNumber = number.dividedBy(1e12).toFixed(2) + 'T'
	} else if (number.isGreaterThanOrEqualTo(1e9)) {
		shortNumber = number.dividedBy(1e9).toFixed(2) + 'B'
	} else if (number.isGreaterThanOrEqualTo(1e6)) {
		shortNumber = number.dividedBy(1e6).toFixed(2) + 'M'
	} else if (number.isGreaterThanOrEqualTo(1e3)) {
		shortNumber = number.dividedBy(1e3).toFixed(2) + 'K'
	} else {
		shortNumber = number.toFormat(3)
	}

	return shortNumber?.toString()
}

export const maskNumber = (number: number | string, isMask: boolean) => {
	if (isMask) return number

	// 숫자를 문자열로 변환
	const strNumber = number?.toString()

	// 문자열의 첫 번째 숫자와 마지막 숫자를 제외하고, 나머지 숫자를 "*"로 대체
	const maskedNumber =
		'*'.repeat(strNumber?.length - 1) +
		strNumber?.substring(strNumber?.length - 1)

	return maskedNumber
}

export async function copyTextToClipboard(text) {
	if (navigator.clipboard && window.isSecureContext) {
		await navigator.clipboard.writeText(text)
	} else {
		// Use the 'out of viewport hidden text area' trick
		const textArea = document.createElement('textarea')
		textArea.value = text

		// Move textarea out of the viewport so it's not visible
		textArea.style.position = 'absolute'
		textArea.style.left = '-999999px'

		document.body.prepend(textArea)
		textArea.select()

		try {
			document.execCommand('copy')
		} catch (error) {
			console.error(error)
		} finally {
			textArea.remove()
		}
	}
}

export const isIos = () => {
	const userAgent = navigator.userAgent.toLowerCase()
	return /iphone|ipad|ipod/.test(userAgent)
}
