import React, { useContext } from 'react'

// import { TradeDetailListSection } from './styles'

// import TradeDetailListHeader from './TradeDetailListHeader'
// import TradeDetailListItem from './TradeDetailListItem'
// import TradeDetailError from './TradeDetailError'
// import TradeDetailLoading from './TradeDetailLoading'
import { useFetcher } from 'app/hooks/fetcher'
import useTradeStore from '../../../../hooks/trade/tradeStore'

import { TradeDetailListSection, TradeText } from './styles'
import { useToken } from 'app/hooks'
import { AuthCheckText } from 'app/components'
import { Text, View } from '../../../../../styles/reactnative'
import NoData from '../../../../components/Common/CFlatList/NoData'
import EntryTime from '../../../../components/EntryTime'
import KrwPrice from '../../../../components/KrwPrice'
import BigNumber from 'bignumber.js'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import { Button, Table, Tag } from 'antd'
import { goTo } from '../../../../hooks/navigation'
import { COLORS } from '../../../../constants'
import ShareModal from '../../../ShareModal'
import { IconShare2 } from '@tabler/icons-react'

const TradeClosedPnlTable = ({ parentHeight }) => {
	const { data: token } = useToken()
	let KEYURL = '/trade/closedpnl?open_close=C&ep_id=*'

	const { data, isLoading } = useFetcher(KEYURL, false)
	const { theme, isDarkMode } = useContext(ThemeContext)
	const { textw } = theme.COLORS

	const { t } = useTranslation()
	const nav = useHistory()

	const [shareData, setShareData] = React.useState<any>(null)

	const { data: modalStore } = useTradeModalStore()

	const { data: tradeStore } = useTradeStore()

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const equivshow = getSetting(setting, 'EQUIVSHO', 1) == 1 ? true : false
	const { EPs } = useExchangeStore()

	let list = data?.result

	if (modalStore?.iscurrent) {
		list = list?.filter(
			(item: any) => item?.exchange_pairs_id == tradeStore?.ep_id
		)
	}

	return (
		<TradeDetailListSection>
			<TradeDetailListSection>
				{token ? null : <AuthCheckText />}

				{list?.length ? (
					<Table
						showHeader={true}
						loading={isLoading}
						bordered={false}
						rowKey={item => 'CLOSEDROW' + item?.id}
						rootClassName={
							'table' + (!isDarkMode ? 'dark' : 'light')
						}
						dataSource={isLoading ? [] : list}
						columns={[
							{
								title: t('contracts') + '',
								dataIndex: 'contracts',
								align: 'center',
								key: 'contracts',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)
									return (
										<TradeText
											style={{ flexDirection: 'row' }}>
											<TradeText
												style={{
													flexDirection: 'column',
													cursor: 'pointer',
												}}
												onClick={() => {
													goTo(
														nav,
														'/trade/' + EP?.id
													)
												}}>
												<Text>{EP?.pairs}</Text>
												<Text
													style={{
														color: 'orange',
														fontSize: 10,
														whiteSpace: 'nowrap',
													}}>
													{item?.cross_iso === 'C'
														? t('cross')
														: t('iso')}{' '}
													{item?.leverage}X
												</Text>
											</TradeText>
											<Text
												style={{
													marginLeft: 5,
													color:
														item?.long_short === 'L'
															? COLORS.lightGreen
															: COLORS.lightRed,
												}}>
												{item?.long_short === 'L'
													? t('long')
													: t('short')}
											</Text>
										</TradeText>
									)
								},
							},
							{
								title: t('qty') + '',
								dataIndex: 'amount',
								key: 'amount',
								align: 'right',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)
									const coin = EP?.pairs?.split('/')[0]
									return (
										<TradeText
											style={{
												whiteSpace: 'nowrap',
												flexDirection: 'column',
												width: '100%',
												alignContent: 'flex-end',
												alignItems: 'flex-end',
											}}>
											{BigNumber(item?.amount)
												.multipliedBy(item?.leverage)
												.toFormat(
													EP?.dp2 || EP?.dp || 3
												)}{' '}
											{coin}
										</TradeText>
									)
								},
							},
							{
								title: t('orderPrice') + '',
								dataIndex: 'open_price',
								key: 'open_price',
								align: 'center',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)

									return (
										<TradeText
											style={{
												letterSpacing: 0,
												minWidth: 90,
												width: '100%',
												lineHeight: 1,
												flexDirection: 'column',
												alignContent: 'center',
												alignItems: 'center',
											}}>
											{item?.limit_market == 'L'
												? BigNumber(
														item?.order_price
												  ).toFormat(EP?.dp || 3)
												: t('market')}
										</TradeText>
									)
								},
							},
							{
								title: t('tradePrice') + '',
								dataIndex: 'trade_price',
								key: 'trade_price',
								align: 'right',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)
									return (
										<TradeText
											style={{
												letterSpacing: 0,
												minWidth: 90,
												width: '100%',
												lineHeight: 1,
												flexDirection: 'column',
												alignContent: 'center',
												alignItems: 'flex-end',
											}}>
											{BigNumber(text).toFormat(
												EP?.dp || 3
											)}
										</TradeText>
									)
								},
							},
							{
								title: t('realizedpl') + '',
								dataIndex: 'realized_pl',
								key: 'realized_pl',
								align: 'right',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)
									const currency = EP?.pairs?.split('/')[1]

									return (
										<View
											style={{
												minWidth: 90,
												width: '100%',
												justifyContent: 'flex-end',
												flexDirection: 'row',
											}}>
											<KrwPrice
												price={item?.pnl_price}
												currency={currency}
												ratemargin={item?.margin}
												equiv={equivshow}
												dp={3}
											/>
											<Button
												type="ghost"
												size="small"
												icon={
													<IconShare2
														color={
															item?.pnl_price > 0
																? COLORS.lightGreen
																: item?.pnl_price <
																  0
																? COLORS.lightRed
																: textw
														}
														size={16}
													/>
												}
												onClick={() => {
													setShareData(null)
													setTimeout(() => {
														setShareData(item)
													}, 100)
												}}
											/>
										</View>
									)
								},
							},
							{
								title: t('fee') + '',
								dataIndex: 'fee_price',
								key: 'fee_price',
								align: 'right',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)
									const currency = EP?.pairs?.split('/')[1]

									return (
										<View
											style={{
												whiteSpace: 'nowrap',
												flexDirection: 'column',
												width: '100%',
												alignContent: 'flex-end',
												alignItems: 'flex-end',
											}}>
											<KrwPrice
												price={
													-Math.abs(item?.fee_price)
												}
												currency={currency}
												equiv={equivshow}
												dp={3}
											/>
										</View>
									)
								},
							},
							{
								title: t('margin') + '',
								dataIndex: 'margin',
								key: 'margin',
								align: 'right',
								render: (text: any, item) => {
									const EP = EPs?.find(
										(item2: any) =>
											+item2.id ==
											+item?.exchange_pairs_id
									)
									const currency = EP?.pairs?.split('/')[1]
									return (
										<TradeText
											style={{
												letterSpacing: 0,
												lineHeight: 1,
												width: '100%',
												flexDirection: 'column',
												alignContent: 'center',
												alignItems: 'flex-end',
											}}>
											<KrwPrice
												price={item?.margin}
												currency={currency}
												color={false}
												equiv={true}
												dp={3}
											/>
										</TradeText>
									)
								},
							},
							{
								title: t('endTime') + '',
								dataIndex: 'created_at',
								key: 'created_at',
								align: 'center',
								render: (text: any, item) => {
									return <EntryTime time={item?.created_at} />
								},
							},
							{
								title: t('status') + '',
								dataIndex: 'status',
								key: 'status',
								align: 'center',
								width: 50,
								render: (text: any, item) => {
									return (
										<TradeText>
											{item?.force != 0 && (
												<Tag>
													{item?.force == 2
														? t('TP')
														: item?.force == 3
														? t('SL')
														: item?.force == 1
														? t('close')
														: ''}
												</Tag>
											)}
										</TradeText>
									)
								},
							},
						]}
						pagination={false}
						style={{ width: '100%', height: '100%' }}
						sticky={true}
						scroll={
							list?.length * 50 >= parentHeight - 100
								? { x: true, y: parentHeight - 100 }
								: { x: true }
						}
						size="small"
					/>
				) : (
					<View style={{ flex: 1, height: '100%' }}>
						<NoData title={'noOrderYet'} />
					</View>
				)}
			</TradeDetailListSection>
			<ShareModal
				shareData={shareData}
				setShareData={setShareData}
				type={2}
			/>
		</TradeDetailListSection>
	)
}

export default React.memo(TradeClosedPnlTable)
