import React from 'react'
import { CFlatList } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import { MView } from 'styles/mobilePageStyles'
import MOrderCurrentItems from './MOrderCurrentItems'
import { useFetcher } from '../../../../hooks/fetcher'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'

const MOrderCurrent = () => {
	const { height } = useWindowDimensions()
	const { orderlist: data } = useTradePosition()

	const renderItem = (item: any, index: any) => (
		<MOrderCurrentItems item={item} />
	)
	return (
		<MView
			style={{
				width: '100%',
				height: height - 85,
				paddingTop: 10,
				paddingLeft: 10,
				paddingRight: 10,
			}}>
			<CFlatList
				data={data?.length ? data : []}
				renderItem={renderItem}
				noDataTitle={'noOrderYet'}
				itemSize={50}
			/>
		</MView>
	)
}

export default MOrderCurrent
