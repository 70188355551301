import React from 'react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import TraderProfile from './TraderProfile'
import TraderChart from './TraderChart'
import TraderDetailLists from './TraderDetailLists'
import { useHistory } from 'react-router-dom'
import { goTo } from 'app/hooks/navigation'
interface IProps {
	item?: any
	index: number
	openModal?: any
}
const TraderDetails = ({ item, index, openModal }: IProps) => {
	const { t } = useTranslation()
	const navigation = useHistory()
	return (
		<View
			style={{
				width: '100%',
				height: '100%',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				padding: 20,
			}}>
			<View
				style={{ width: '100%', alignItems: 'flex-start' }}
				onClick={() => {
					goTo(navigation, '/traderdetail')
				}}>
				<TraderProfile />
				<TraderChart />
				<TraderDetailLists
					title={t('roi')}
					content={'90D'}
					amount={'+64.98%'}
				/>
				<TraderDetailLists
					title={t('drawdown')}
					content={'30D'}
					amount={'+59.35%'}
				/>
				<TraderDetailLists
					title={t('aum')}
					content={'30D'}
					amount={'3,185,562,12'}
				/>
			</View>
			<CButton
				title={t('copy')}
				onClick={() => {
					if (openModal) {
						openModal(item)
					}
				}}
				style={{
					marginTop: 10,
					width: '100%',
					paddingBlock: 10,
					backgroundColor: COLORS.primary,
					color: COLORS.white,
				}}
			/>
		</View>
	)
}

export default TraderDetails
