import React from 'react'

import { View, Text } from 'styles/reactnative'
import TraderInfoHeader from './TraderInfoHeader'
import TraderInfoFooter from './TraderInfoFooter'
import TraderInfoBody from './TraderInfoBody'

const TraderDetailInfo = () => {
	return (
		<View
			style={{
				marginLeft: 30,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<TraderInfoHeader />
			<TraderInfoBody />
			<Text size={14} style={{ marginTop: 10 }}>
				votre réussite est notre réussite (Bio)
			</Text>
			<TraderInfoFooter />
		</View>
	)
}

export default React.memo(TraderDetailInfo)
