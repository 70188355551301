import { CoinLogo, CoinRate } from 'app/components'
import React from 'react'
import { Text, View } from 'styles/reactnative'

const TraderMasterDetail = ({ data }) => {
	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CoinLogo
				size={26}
				src={`http://static.upbit.com/logos/${data?.market?.slice(
					4
				)}.png`}
				style={{ marginRight: 10 }}
			/>
			<View style={{ alignItems: 'flex-start' }}>
				<Text
					style={{
						height: 20,
					}}>
					{data.english_name}
				</Text>
				<CoinRate type={'Money Maker'} />
			</View>
		</View>
	)
}

export default React.memo(TraderMasterDetail)
