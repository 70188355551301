import React, { useState } from 'react'
import { CFlatList } from 'app/components/Common'
import { TradeDrawerListSection } from '../styles'
import TradeListTitle from '../TradeListTitle'
import { useFavourite } from 'app/hooks'
import DrawerListItem from '../DrawerListItem'
import CRenderEmpty from '../../../CRenderEmpty'

const TradeFavouriteCoin = ({ search, setDisplayDrawer }) => {
	const [top, setTop] = useState(0)
	const { data: favourites } = useFavourite()
	let filteredData: any
	filteredData = favourites?.filter(item =>
		item?.pairs?.toLowerCase()?.includes(search?.toLowerCase())
	)

	const renderItem = (item: any, index: any) => {
		return (
			<DrawerListItem
				item={item}
				index={index}
				setDisplayDrawer={setDisplayDrawer}
			/>
		)
	}
	return (
		<TradeDrawerListSection>
			<TradeListTitle top={top} setTop={setTop} />
			<CFlatList
				data={filteredData?.length ? filteredData : []}
				renderItem={renderItem}
				noDataTitle={
					filteredData?.length
						? 'favouriteCoinNotAddYet'
						: 'coinNotFound'
				}
			/>
		</TradeDrawerListSection>
	)
}

export default TradeFavouriteCoin
