import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconChevronLeft, IconSearch, IconX } from '@tabler/icons'
import { CButton, CInput } from 'app/components/Common/'
import { COLORS } from 'app/constants/STYLES'
// import HeaderSearchList from './HeaderSearchList'
import { ThemeContext } from 'theme/ThemeProvider'
import { SearchInput, MobileHeaderSearch } from './styles'

const MHeaderSearch = ({ setOpenSearch, text, setText }) => {
	const { gray, textw } = useContext(ThemeContext).theme.COLORS

	const inputRef = useRef<HTMLInputElement>(null)
	const containerRef = useRef<HTMLInputElement>(null)

	const [focused, setFocused] = useState(false)
	const { t } = useTranslation()
	const handleChange = (event: any) => {
		setText(event.target.value)
	}
	const clearInput = () => {
		setText('')
		if (inputRef.current) {
			inputRef.current.value = ''
		}
	}
	const handleOutsideClick = event => {
		if (
			containerRef.current &&
			!containerRef.current.contains(event.target)
		) {
			setFocused(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick)
		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [])

	return (
		<MobileHeaderSearch ref={containerRef}>
			<CButton
				style={{ height: '100%' }}
				icon={<IconChevronLeft color={textw} />}
				onClick={() => {
					setOpenSearch(false)
				}}
			/>

			<SearchInput className={'inputWithIcon'}>
				<CInput
					ref={inputRef}
					placeholder={t('searchCoin')}
					value={text}
					type={'text'}
					onChange={handleChange}
					vStyle={{
						width: '100%',
					}}
					style={{
						backgroundColor: gray,
						height: 34,
						paddingLeft: 35,
						width: '100%',
						color: textw,
						borderRadius: 15,
						borderColor: focused ? COLORS.secondary : 'transparent',
						borderWidth: 1,
						transition: 'all 0.5s ease-out',
						overflow: 'hidden',
					}}
					onFocus={() => setFocused(true)}
				/>
				<div className="left-icon">
					<IconSearch size={14} color={textw} />
				</div>
				{text?.length ? (
					<div className="right-icon" onClick={clearInput}>
						<IconX size={12} />
					</div>
				) : null}
			</SearchInput>
			<CButton
				style={{ height: '100%', marginInline: 18, color: textw }}
				title={t('cancel')}
				onClick={() => {
					setOpenSearch(false)
				}}
			/>
			{/* {focused ? <HeaderSearchList text={text} /> : null} */}
		</MobileHeaderSearch>
	)
}

export default React.memo(MHeaderSearch)
