import React, { useContext } from 'react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const CopyActions = ({ item }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	return (
		<View>
			<CButton
				title={t('copy')}
				onClick={() => {}}
				style={{
					marginTop: 5,
					width: '100%',
					paddingBlock: 8,
					backgroundColor: COLORS.primary,
					color: textw,
				}}
			/>
		</View>
	)
}

export default CopyActions
