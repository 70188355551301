import React from 'react'
import { View } from 'styles/reactnative'
import CurrentTradesTableBody from './CurrentTradesTableBody'
import CurrentTradesTableHeader from './CurrentTradesTableHeader'

const CurrentTradesTable = () => {
	const data = [
		{
			positions: 'BTCUSDT',
			entryPrice: '23,323.0',
			currentPositions: '39.86',
			orderQty: '0.017',
			unrealizedp: '-5.0405',
			unrealizedl: '-12.71%',
			openedTime: '2023-02-05 09:48:13',
			tpsl: '24,800.0/--',
		},
		{
			positions: 'BTCUSDT',
			entryPrice: '23,323.0',
			currentPositions: '39.86',
			orderQty: '0.017',
			unrealizedp: '-5.0405',
			unrealizedl: '-12.71%',
			openedTime: '2023-02-05 09:48:13',
			tpsl: '24,800.0/--',
		},
		{
			positions: 'BTCUSDT',
			entryPrice: '23,323.0',
			currentPositions: '39.86',
			orderQty: '0.017',
			unrealizedp: '-5.0405',
			unrealizedl: '-12.71%',
			openedTime: '2023-02-05 09:48:13',
			tpsl: '24,800.0/--',
		},
	]
	return (
		<View style={{ width: '100%' }}>
			<CurrentTradesTableHeader />
			<CurrentTradesTableBody data={data} />
		</View>
	)
}

export default React.memo(CurrentTradesTable)
