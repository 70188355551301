import React, { useContext } from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
import { MView } from '../../../../styles/mobilePageStyles'
import CImage from '../CImage'
import { FONTS, IMAGES } from '../../../constants'
import { Text, View } from '../../../../styles/reactnative'

const CNoDataFound = ({
	noDataTitle,
	noDataContent,
	noDataSize,
	noDataHeight,
	lottieSize,
}) => {
	const { theme } = useContext(ThemeContext)
	const { t } = useTranslation()
	return (
		<View
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				alignSelf: 'flex-start',
				width: '100%',
				position: 'relative',
				minHeight: noDataHeight ? noDataHeight : '80%',
				backgroundColor: theme.COLORS.background,
				borderRadius: 10,
			}}>
			<MView style={{ marginBottom: 5, marginTop: 10 }}>
				<CImage
					src={IMAGES.nodata}
					style={{
						width: 'auto',
						height: 35,
					}}
					alt={''}
				/>
			</MView>
			{noDataTitle ? (
				<MView
					style={{
						paddingTop: 10,
						paddingBottom: 10,
						paddingInline: 10,
					}}>
					<Text
						style={{ ...FONTS.h7, color: theme.COLORS.textsilver }}>
						{t(noDataTitle)}
					</Text>
				</MView>
			) : null}
			{noDataContent ? (
				<MView style={{ paddingTop: 50, paddingBottom: 50 }}>
					<Text
						style={{
							...FONTS.h7,
							color: theme.COLORS.textw,
							letterSpacing: 1.2,
							textAlign: 'center',
						}}>
						{t(noDataContent)}
					</Text>
				</MView>
			) : null}
		</View>
	)
}
export default React.memo(CNoDataFound)
