import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { IconShare, IconStar } from '@tabler/icons'
import { TraderDetailRightSideSection } from '../styles'
import TraderDetailRightSIdeBtn from './TraderDetailRightSIdeBtn'
import { ThemeContext } from 'theme/ThemeProvider'

const TraderDetailRightSide = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<TraderDetailRightSideSection>
			<View
				style={{
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'space-between',
				}}>
				<View style={{ flexDirection: 'row' }}>
					<IconShare size={16} color={textw} />
					<Text size={14} style={{ marginLeft: 5 }}>
						Share
					</Text>
				</View>
				<View
					style={{
						width: 1,
						height: 15,
						backgroundColor: COLORS.lightGray5,
					}}
				/>
				<View style={{ flexDirection: 'row' }}>
					<IconStar size={16} color={textw} />
					<Text size={14} style={{ marginLeft: 5 }}>
						Share
					</Text>
				</View>
			</View>
			<TraderDetailRightSIdeBtn />
		</TraderDetailRightSideSection>
	)
}

export default React.memo(TraderDetailRightSide)
