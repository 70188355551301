import React from 'react'
import OrderContainerFooter from './OrderContainerFooter'
import OrderContainerHeader from './OrderContainerHeader'
import OrderContainerLists from './OrderContainerLists'
import { OrderContainerTableSection } from './styles'

const OrderContainerTable = ({ headers, data }) => {
	const dLength = data?.length
	return (
		<OrderContainerTableSection
			style={{ height: dLength < 5 ? 400 : dLength > 7 ? 600 : 800 }}>
			<OrderContainerHeader headers={headers} />
			<OrderContainerLists data={data} />
			<OrderContainerFooter data={data} />
		</OrderContainerTableSection>
	)
}

export default OrderContainerTable
