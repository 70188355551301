import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from 'styles/reactnative'
import { CImage } from 'app/components/Common'
import { TheBitCardItemSection } from './styles'

const TheBitCardItems = ({ item, index }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<TheBitCardItemSection>
			<View
				style={{
					width: '100%',
					height: '80%',
					borderRadius: 50,
				}}>
				<CImage
					src={`http://picsum.photos/id/${index + 1}/200`}
					style={{ height: '100%', width: '100%' }}
				/>
			</View>
			<View
				style={{
					width: '100%',
					height: '20%',
					alignItems: 'flex-start',
					paddingInline: 10,
				}}>
				<Text
					style={{
						fontWeight: '300',
						color: textw,
						textAlign: 'center',
						lineHeight: 1.3,
						fontSize: 14,
					}}>
					{item?.content}
				</Text>
			</View>
		</TheBitCardItemSection>
	)
}

export default TheBitCardItems
