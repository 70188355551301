import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ConvertQuoteWrapper } from '../styles'
import ConvertQuoteFooter from './ConvertQuoteFooter'

const ConvertQuote = ({ setShowModal }) => {
	const { t } = useTranslation()
	return (
		<ConvertQuoteWrapper>
			<Text size={16}>{t('quote')}</Text>
			<View style={{ marginTop: 20 }}>
				<Text>----</Text>
			</View>
			<View style={{ marginTop: 20 }}>
				<Text>----</Text>
			</View>
			<View
				style={{
					width: '95%',
					alignItems: 'flex-start',
					position: 'absolute',
					bottom: 30,
				}}>
				<View style={{ flexDirection: 'row', marginTop: 30 }}>
					<Text style={{ marginRight: 10, color: COLORS.lightGray }}>
						{t('limitPerTransaction')}
					</Text>
					<Text style={{ color: COLORS.lightGray }}>
						0.001-20 BTC
					</Text>
				</View>
				<ConvertQuoteFooter setShowModal={setShowModal} />
			</View>
		</ConvertQuoteWrapper>
	)
}

export default ConvertQuote
