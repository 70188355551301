import { CTabs } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React from 'react'
import { View } from 'styles/reactnative'

const FollowerTraderTabs = ({ tabs, setTabs, data }) => {
	return (
		<View
			style={{
				justifyContent: 'flex-start',
				width: '100%',
				marginLeft: -15,
			}}>
			<CTabs
				tabs={tabs}
				setTabs={setTabs}
				data={data}
				activeColor={COLORS.black}
				activeSize={16}
				style={{
					fontWeight: '500',
					marginRight: 15,
				}}
			/>
		</View>
	)
}

export default React.memo(FollowerTraderTabs)
