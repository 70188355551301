import React from 'react'
import { Text, View } from 'styles/reactnative'

interface IProps {
	text?: string
	style?: any
	vStyle?: any
}

const CText = ({ text, style, vStyle }: IProps) => {
	return (
		<View style={vStyle}>
			<Text style={style}>{text}</Text>
		</View>
	)
}

export default React.memo(CText)
