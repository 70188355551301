import styled from 'styled-components'

export const TradeDrawerSection = styled.div`
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	height: 78%;
`
export const TradeSearch = styled.div``

export const TradeDrawerTabBarSection = styled.div`
	display: flex;
	flex-direction: row;
	padding-inline: 20px;
	border-bottom: 1px solid ${props => props.theme.COLORS.silvertext};
`

export const TradeDrawerListSection = styled.div`
	width: 100%;
	height: 100%;
	padding: 5px;
`

export const TradeDrawerListItemSection = styled.div`
	padding: 10px;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
`

export const TradeDrawerItems = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
`
export const TradeListTitleSection = styled.div`
	padding: 10px;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	width: 100%;
`
export const TitleText = styled.div`
	font-size: 14px;
	color: ${props => props.theme.COLORS.textw};
`
