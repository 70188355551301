import React from 'react'
import { IconEye } from '@tabler/icons'
import { CButton } from '../Common'

const HiddenEye = ({ style, iconSize }: any) => {
	// const { width } = useWindowDimensions();
	// const iconSizing =
	//     width < 600
	//         ? iconSize - 4
	//         : width > 600
	//         ? iconSize - 3
	//         : width < 762
	//         ? iconSize - 2
	//         : width < 768
	//         ? iconSize - 1
	//         : iconSize;
	return (
		<CButton
			icon={<IconEye size={iconSize ? iconSize : 20} />}
			onClick={() => {
				console.log('You Clicked My Eyes')
			}}
			style={{
				...style,
			}}
		/>
	)
}

export default React.memo(HiddenEye)
