import React from 'react'
import { MView } from 'styles/mobilePageStyles'
import MReferralHistoryHeader from './ReferralHistoryHeader'
import { MReferralHistorySection } from './styles'
import { CFlatList } from '../../../../components/Common'
import { useFetcher } from '../../../../hooks/fetcher'
import MReferralHistoryItem from './MReferralHistoryItem'

const MReferralHistory = () => {
	const { data: result } = useFetcher('/referral/users')
	let data = []

	if (result?.result?.result) {
		data = result?.result?.result
	}
	const renderItem = (item: any, index: any) => (
		<MReferralHistoryItem item={item} />
	)
	return (
		<MReferralHistorySection>
			<MReferralHistoryHeader />
			<MView
				style={{
					width: '100%',
					height: '500px',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
				<CFlatList
					data={data?.length ? data : []}
					renderItem={renderItem}
					itemSize={30}
					noDataTitle="currentlyNoData"
				/>
			</MView>
		</MReferralHistorySection>
	)
}

export default MReferralHistory
