import { IconChevronRight } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import BigNumber from 'bignumber.js'
import { SETTING_TYPE_SYSTEM } from 'app/constants/SETTING'
import { useFetcherPublic } from 'app/hooks/fetcher'
import useTradeStore from 'app/hooks/trade/tradeStore'
import { useBalance } from 'app/hooks/user/mybalance'
import { getSetting, useSetting } from 'app/hooks/user/mysetting'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from '../../../../constants'
import { goTo } from '../../../../hooks/navigation'
import { useHistory } from 'react-router'

const MyAssetsRightSectionList = ({ title, balanceType }) => {
	const { borderColor, textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const nav = useHistory()
	const { data: tradeStore } = useTradeStore()
	const currency = tradeStore?.pairs?.split('/')[1]
	const { data: balance } = useBalance(currency, balanceType)
	const balanceAmount = balance?.balance
		? BigNumber(balance?.balance).toFormat(2)
		: '0'

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const settingCurrency = getSetting(setting, 'CURRENCY', 'KRW')

	const currencyList = useFetcherPublic('/system/currency')
	const equiv = currencyList?.data?.result?.result?.find(
		(item: any) => item.code === settingCurrency
	)

	const equivValue = BigNumber(balance?.balance || 0).multipliedBy(
		equiv?.price || 1
	)
	return (
		<View
			onClick={() => {
				goTo(nav, '/assets?tab=' + (parseInt(balanceType) + 2))
			}}
			style={{
				border: `1px solid ${borderColor}`,
				alignItems: 'center',
				padding: 10,
				marginTop: 10,
			}}>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
					marginBottom: 10,
				}}>
				<View style={{ flexDirection: 'row' }}>
					<View
						style={{
							background:
								balanceType == 1 ? COLORS.primary : 'white',
							width: 20,
							height: 20,
							borderRadius: 50,
							marginRight: 10,
						}}
					/>
					<Text>{t(title)}</Text>
				</View>
				<CButton
					icon={<IconChevronRight size={14} color={textsilver} />}
				/>
			</View>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<Text size={13}>{balanceAmount} USDT</Text>
				<Text size={11}>
					{equivValue.toFormat(0)} {settingCurrency}
				</Text>
			</View>
		</View>
	)
}

export default MyAssetsRightSectionList
