import { COLORS } from 'app/constants/STYLES'
import styled from 'styled-components'

export const TradeRealTimeContainer = styled.div`
	background-color: ${props => props.theme.COLORS.bgColor};
	height: 100%;
	width: 100%;
	overflow: hidden;

	.widgetClose {
		display: none;
	}

	&:hover {
		.widgetClose {
			display: flex;
		}
	}
	@media (max-width: 900px) {
		width: 100%;
		margin-top: 5px;
	}
`

export const TradeRealTimeTopTabSection = styled.div`
	display: flex;
	width: 100%;
	height: 55%;
	flex-direction: row;
	border-bottom: 1px solid ${COLORS.lightGray};
	@media (max-width: 900px) {
		height: 50%;
	}
`
