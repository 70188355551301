import React, { useState } from 'react'
import { CoinLogo } from 'app/components'
import { CBottomSheet, MButton } from 'app/mobile/components'
import { MText, MView } from 'styles/mobilePageStyles'
import CIUtils from 'utils/ciutils'
import { firstChar } from 'utils/ExpensiveFunction'
import ProfileEditContainer from '../ProfileSettingBody/MAccountSecurity/ProfileEditContainer'

const ProfileSettingHeader = ({ profile }) => {
	const [profileEdit, setProfileEdit] = useState(false)
	return (
		<MView
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				width: '100%',
			}}>
			<MView
				style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
				<MView>
					<CoinLogo
						src={profile?.profileimage}
						size={30}
						style={{ borderRadius: 50 }}
					/>
				</MView>
				<MView style={{ marginLeft: 10 }}>
					<MText style={{ fontSize: 14, fontWeight: '400' }}>
						Hi , {firstChar(profile?.nickname)}
					</MText>
					<MText style={{ fontWeight: '300', paddingTop: 3 }}>
						UID: {profile?.id ? CIUtils.padId(profile?.id) : ''}
					</MText>
				</MView>
			</MView>
			<MButton
				title={'edit'}
				style={{}}
				onClick={() => {
					setProfileEdit(!profileEdit)
				}}
			/>
			<CBottomSheet
				maxHeight={300}
				open={profileEdit}
				setOpen={setProfileEdit}
				BottomContainer={ProfileEditContainer}
			/>
		</MView>
	)
}

export default ProfileSettingHeader
