import styled from 'styled-components'

export const AnnouncementNavBarSection = styled.div`
	width: 100%;
	height: 7%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 20px;
	border-bottom: 1px solid ${p => p.theme.COLORS.borderColor};
`
