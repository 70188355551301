import React from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'
import { useTranslation } from 'react-i18next'

const TraderFollowerTitle = () => {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-start',
				width: '100%',
				paddingBottom: 10,
				cursor: 'pointer',
				paddingLeft: width < 600 ? 40 : 0,
			}}>
			<Text
				size={42}
				style={{
					color: COLORS.white,
					lineHeight: 2,
				}}>
				{t('userCenterFollower')}
			</Text>
		</View>
	)
}

export default TraderFollowerTitle
