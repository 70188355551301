import React from 'react'
import { IconCoinBitcoin, IconMenu } from '@tabler/icons'
import {
	TradeListItemSection,
	TradeTextInnerContent,
	TradeTextInnerTitle,
	TradeTextWrapper,
} from './styles'
import { COLORS } from 'app/constants/STYLES'

interface IProps {
	title?: string
	content?: string
	titleColor?: string
	contentColor?: string
	titleBold?: boolean
	contentBold?: boolean
	icon?: boolean
	onPress?: () => void
}

const TradeListItem = ({
	title,
	content,
	icon,
	titleColor,
	contentColor,
	titleBold,
	contentBold,
	onPress,
}: IProps) => {
	return (
		<TradeListItemSection onClick={onPress ? onPress : () => {}}>
			{icon ? <IconCoinBitcoin color={COLORS.primary} /> : null}
			<TradeTextWrapper>
				<TradeTextInnerTitle
					titleColor={titleColor}
					titleBold={titleBold}>
					{title}
					<IconMenu style={{ marginLeft: 10 }} size={13} />
				</TradeTextInnerTitle>
				<TradeTextInnerContent
					contentColor={contentColor}
					contentBold={contentBold}>
					{content}
				</TradeTextInnerContent>
			</TradeTextWrapper>
		</TradeListItemSection>
	)
}

export default React.memo(TradeListItem)
