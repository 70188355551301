import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import AssetsTitle from '../AssetsTitle'
import AssetOverviewTotalValue from './AssetOverviewTotalValue'
import {
	AssetOverviewContainer,
	AssetOverviewSection,
	AssetOverviewTotal,
} from './styles'
import MyAssets from './MyAssets'
import RecentDeposit from './RecentDeposit'

const AssetOverview = ({ title }) => {
	const { height, width } = useWindowDimensions()
	const hSize = width < 1200 ? height / 1 : height / 1.5
	return (
		<AssetOverviewSection>
			<AssetsTitle title={title} />
			<AssetOverviewContainer style={{ height: hSize }}>
				<AssetOverviewTotal>
					<AssetOverviewTotalValue />
					<MyAssets />
				</AssetOverviewTotal>
				<RecentDeposit />
			</AssetOverviewContainer>
		</AssetOverviewSection>
	)
}

export default AssetOverview
