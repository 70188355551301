import React, { useContext, useState } from 'react'
import { CButton } from 'app/components/Common'
import { MenuContainer, MenuItems, NotificationMenuSection } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { IconChevronDown } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'

const NotificationMenu = ({ menus, tab, setTab }) => {
	const { t } = useTranslation()
	const [show, setShow] = useState(false)
	const { borderColor, textsilver, textw } =
		useContext(ThemeContext).theme.COLORS

	return (
		<NotificationMenuSection>
			<CButton
				title={t(tab?.name)}
				style={{
					border: `1px solid ${borderColor}`,
					width: 130,
					height: 30,
					justifyContent: 'space-between',
					color: textw,
					fontSize: 10,
				}}
				icon={<IconChevronDown size={16} color={textsilver} />}
				onClick={() => {
					setShow(!show)
				}}
			/>
			{show ? (
				<MenuContainer>
					{menus?.map((item: any, index: number) => (
						<MenuItems
							style={{
								color: item === tab ? COLORS.secondary : textw,
							}}
							key={index.toString()}
							onClick={() => {
								setTab({
									...item,
									index: item?.index,
								})
								setShow(!show)
							}}>
							{t(item?.name)}
						</MenuItems>
					))}
				</MenuContainer>
			) : null}
		</NotificationMenuSection>
	)
}

export default NotificationMenu
