import React, { useContext, useState } from 'react'
import { useWindowDimensions } from 'app/hooks'
import CModalHeader from 'app/components/Modal/CModalHeader'
import { View } from 'styles/reactnative'
import { useWSize } from 'utils/ExpensiveFunction'
import PositionCloseTabs from './PositionCloseTabs'
import PositionCloseLimit from './PositionCloseLimit'
import PositionCloseMarket from './PositionCloseMarket'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ThemeContext } from 'theme/ThemeProvider'

const PositionCloseContainer = ({ setShowModal }) => {
	const { drawerBgColor } = useContext(ThemeContext).theme.COLORS
	const [tab, setTab] = useState('L')

	const { height } = useWindowDimensions()
	const { data: modalStore } = useTradeModalStore()
	const item = modalStore?.positionClose?.item

	return (
		<View
			style={{
				width: useWSize(150),
				height: height / 1.8,
				borderRadius: 5,
				backgroundColor: drawerBgColor,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<CModalHeader
				title={'positionClose'}
				closeModal={() => setShowModal(false)}
			/>
			<PositionCloseTabs tab={tab} setTab={setTab} />
			{tab === 'L' ? (
				<PositionCloseLimit item={item} setShowModal={setShowModal} />
			) : (
				<PositionCloseMarket item={item} setShowModal={setShowModal} />
			)}
		</View>
	)
}

export default React.memo(PositionCloseContainer)
