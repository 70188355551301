import React from 'react'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

import TimezoneSelect from 'react-timezone-select'
import { getSetting, useSetting } from 'app/hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from 'app/constants/SETTING'

const TimezoneSetting = () => {
	const { t } = useTranslation()

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const timezone = getSetting(setting, 'TIMEZONE', 'Asia/Seoul')

	const updateTimezone = (e: any) => {
		updateSetting('TIMEZONE', e.value)
	}

	const customStyles = {
		container: (provided: any) => ({
			...provided,
			width: '100%',
		}),
	}

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
				padding: 10,
			}}>
			<Text size={18} style={{ fontWeight: '500' }}>
				{t('Timezone')}
			</Text>
			<View
				style={{ marginTop: 20, width: 300, alignItems: 'flex-start' }}>
				<Text size={16} style={{ fontWeight: '400' }}>
					{t('TimezoneDescription')}
				</Text>
				<View style={{ width: '100%', marginTop: 10 }}>
					<TimezoneSelect
						styles={customStyles}
						value={timezone}
						onChange={updateTimezone}
					/>
				</View>
			</View>
		</View>
	)
}

export default React.memo(TimezoneSetting)
