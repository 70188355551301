/* eslint-disable prettier/prettier */
import { COLORS } from 'app/constants/STYLES'
import styled, { keyframes } from 'styled-components'

const breatheAnimation = keyframes`
   0% {
        transform: translateZ(-100px) transLateY(50px)
    }
    80% {
        transform: translateZ(-10px) transLateY(0px)
    }
    100% {
        transform: translateZ(0px) transLateY(0px)
    }
`

export const DropdownContainer = styled.div`
	width: 100px;
	z-index: 1;
	position: absolute;
	background-color: ${props => props.theme.COLORS.bgColor};
	color: ${COLORS.lightGray3};
	margin-top: 20px;
	font-size: 12px;
	border-radius: 5px;
	animation-name: ${breatheAnimation};
	animation-duration: 0.3s;
`
export const CDropDownSection = styled.div<{ borderColor?: string }>`
	${DropdownContainer}:after {
		content: ' ';
		position: absolute;
		right: 15px;
		top: -10px;
		border-top: none;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;

		border-bottom: 10px solid ${props => props.theme.COLORS.gray};
	}
`
export const DropdownText = styled.div<{ selected: any }>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	margin-block: 3px;
	padding-inline: 5px;
	&:hover {
		background-color: ${COLORS.grey};
		color: ${COLORS.primary};
	}
	${({ selected }) =>
		selected &&
		`
    background-color: ${COLORS.grey};
    color: ${COLORS.primary};
    `}
`
export const FilterText = styled.div<{
	selected?: boolean
	backgroundHover?: string
	textHover?: string
}>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	width: 100%;
	color: ${COLORS.lightGray};
	${({ backgroundHover }) => `  
      &:hover {
        background-color: ${backgroundHover ? backgroundHover : COLORS.grey};
      }
    `}
	${({ textHover }) => `  
      &:hover {
        color: ${textHover ? textHover : COLORS.white};
      }
    `}
   ${({ selected, backgroundHover, textHover }) =>
		selected &&
		`  
       background-color: ${
			backgroundHover ? backgroundHover : COLORS.lightGray
		};
       color: ${textHover ? textHover : COLORS.white};
    `}
`
