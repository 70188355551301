import React from 'react'
// import { IconBrush, IconSettings } from '@tabler/icons'
import { Text } from 'styles/reactnative'
import { NotificationHeaderSection } from '../styles'
// import { COLORS } from 'app/constants'
// import { CButton } from 'app/components/Common'
import { useTranslation } from 'react-i18next'

const NotificationHeader = () => {
	const { t } = useTranslation()
	return (
		<NotificationHeaderSection>
			<Text size={32} style={{ fontWeight: '500' }}>
				{t('notificationCenter')}
			</Text>
			{/* <View style={{ flexDirection: 'row' }}>
				<CButton
					title={t('markAllasRead')}
					icon={<IconBrush size={20} style={{ marginRight: 5 }} />}
					iconLeft={true}
					style={{
						fontSize: 16,
					}}
					textHover={COLORS.primary}
				/>
				<CButton
					title={t('messageSetting')}
					icon={<IconSettings size={20} style={{ marginRight: 5 }} />}
					iconLeft={true}
					style={{
						fontSize: 16,
					}}
					textHover={COLORS.primary}
				/>
			</View> */}
		</NotificationHeaderSection>
	)
}

export default NotificationHeader
