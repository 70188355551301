import React, { useContext } from 'react'
import { FeedbackCategorySection } from '../../styles'
import FeedbackContainerLeft from './FeedbackContainerLeft'
import { Input } from 'antd'
import { ThemeContext } from 'theme/ThemeProvider'
import { View } from 'styles/reactnative'
const { TextArea } = Input

const FeedbackDetail = ({ content, setContent }) => {
	const { drawerBgColor, textw } = useContext(ThemeContext).theme.COLORS
	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setContent(e.target.value)
	}
	return (
		<FeedbackCategorySection
			style={{
				height: '50%',
				width: '100%',
			}}>
			<FeedbackContainerLeft
				text={'details'}
				style={{ justifyContent: 'center' }}
			/>
			<View style={{ width: '80%', height: '100%' }}>
				<TextArea
					allowClear
					showCount
					maxLength={1000}
					style={{ height: '100%', resize: 'none' }}
					styles={{
						textarea: {
							background: drawerBgColor,
							border: 'transparent',
							color: textw,
						},
					}}
					value={content}
					onChange={onChange}
					placeholder="disable resize"
				/>
			</View>
		</FeedbackCategorySection>
	)
}

export default FeedbackDetail
