import React from 'react'
import { TraderDetailInfoLeftSideSection } from './styles'
import TraderPerformanceDetail from './TraderPerformanceDetail'
import TraderProfitDetail from './TraderProfitDetail'

const TraderDetailInfoLeftSide = () => {
	return (
		<TraderDetailInfoLeftSideSection>
			<TraderPerformanceDetail />
			<TraderProfitDetail />
		</TraderDetailInfoLeftSideSection>
	)
}

export default TraderDetailInfoLeftSide
