import { CPagination } from 'app/components/Common'
import React, { useEffect, useState } from 'react'
import AnnouncementListItems from './AnnouncementListItems'
import { AnnouncementListSection } from './styles'
import { MText, MView } from 'styles/mobilePageStyles'

const AnnouncementList = ({
	data,
	page,
	setPage,
	show,
	setShow,
	search,
	count,
}) => {
	const [list, setList] = useState([])
	const itemsPerPage = 20
	const totalPages = Math.ceil(data?.count / itemsPerPage)
	const isLastPage =
		page !== totalPages && data?.count % itemsPerPage !== 0
	useEffect(() => {
		const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		const result = data?.list?.filter(item => {
			const regex = new RegExp(escapedSearch, 'i')
			return regex.test(item?.title)
		})

		setList(result)
	}, [search, data])

	return (
		<AnnouncementListSection>
			{data?.list?.length ? (
				list?.map((item, index) => (
					<AnnouncementListItems
						key={index.toString()}
						item={item}
						index={index}
						show={show}
						setShow={setShow}
					/>
				))
			) : (
				<MView>
					<MText>No Data!</MText>
				</MView>
			)}

		</AnnouncementListSection>
	)
}

export default AnnouncementList
