import styled from 'styled-components'

export const CryptoPriceSection = styled.div`
	width: 100%;
	height: 412px;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	border-radius: 20px;
	box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	padding-bottom: 10px;
`

export const CryptoPriceHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-inline: 30px;
	padding-block: 20px;
`
