import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FooterListItems from './FooterListItems'
import {
	FooterListItemsContainer,
	FooterListSection,
	FooterListTitle,
} from './styles'
import CButton from 'app/components/Common/CButton'
import { IconChevronDown, IconChevronUp } from '@tabler/icons'
import useWindowDimensions from 'app/hooks/useWindowDimensions'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const About = ({ item, index }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const [show, setShow] = useState<boolean>(true)
	const { width } = useWindowDimensions()
	useEffect(() => {
		if (width < 1200) {
			setShow(true)
		}
	}, [width])
	return (
		<FooterListSection>
			<FooterListTitle>
				<Text
					size={24}
					style={{
						lineHeight: 1.3,
						alignItems: 'flex-start',
					}}>
					{t(item?.title)}
				</Text>
				{width < 1200 ? (
					<CButton
						onClick={() => setShow(!show)}
						icon={
							show ? (
								<IconChevronUp color={textw} />
							) : (
								<IconChevronDown color={textw} />
							)
						}
					/>
				) : null}
			</FooterListTitle>
			<FooterListItemsContainer show={show}>
				{item?.list?.map((item: any, index: any) => (
					<FooterListItems
						key={index.toString()}
						text={item?.text}
						subText={item?.subText}
						goTo={item?.goTo}
					/>
				))}
			</FooterListItemsContainer>
		</FooterListSection>
	)
}

export default React.memo(About)
