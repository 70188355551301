import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const FeedbackContainerLeft = ({ text, style }: any) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '15%',
				height: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				paddingTop: 10,
				...style,
			}}>
			<Text size={16} style={{ color: textw, fontWeight: '500' }}>
				{t(text)}
			</Text>
		</View>
	)
}

export default FeedbackContainerLeft
