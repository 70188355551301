import useSWR from 'swr'
import API from '../../../utils/api'
import { LASTPRICE, TRADEAPI_URL } from '../../constants/TRADEAPIKEYS'
import { useToken } from '../auth/token'
import useTradeStore from './tradeStore'
import { useIndexPrice } from './indexprice'

export function useLastPrice(ep_id: number) {
	const { data, mutate, getPrice } = useIndexPrice(ep_id)

	return {
		data,
		mutate,
		getPrice,
	}
}
