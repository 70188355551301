import React from 'react'
import { COLORS } from 'app/constants'
import Sparkline from './SparkLine'
import Trade from './Trade'
import TradingPairs from './TradingPairs'
import MarketTableListItems from './MarketTableListItems'
import { useTranslation } from 'react-i18next'

export const MarketTableData = () => {
	const { t } = useTranslation()
	return [
		{
			field: t('tradingPairs'),
			unSortIcon: true,
			cellRenderer: ({ data }) => <TradingPairs data={data} />,
			minWidth: 230,
			sortable: true,
		},
		{
			field: t('lastTradedPrice'),
			unSortIcon: true,
			cellRenderer: ({ data }) => {
				return (
					<MarketTableListItems
						item={'12,937.150'}
						type={'volume'}
						ep_id={data?.id}
					/>
				)
			},
		},
		{
			field: t('24HChange'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<MarketTableListItems
					item={'+0.9 %'}
					type={'rate'}
					ep_id={data?.id}
					color={COLORS.lightGreen}
				/>
			),
		},
		{
			field: t('24HHigh'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<MarketTableListItems
					item={'17,031.50'}
					type={'high'}
					ep_id={data?.id}
				/>
			),
		},
		{
			field: t('24HLow'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<MarketTableListItems
					item={'16,675.00'}
					type={'low'}
					ep_id={data?.id}
				/>
			),
		},
		{
			field: t('24HTurnover'),
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<MarketTableListItems item={'1.51B(USDT)'} />
			),
		},
		{
			field: t('charts'),
			unSortIcon: true,
			cellRenderer: ({ data }) => <Sparkline data={data} />,
		},
		{
			field: t('trade'),
			unSortIcon: true,
			cellRenderer: ({ data }) => <Trade data={data} />,
		},
	]
}
