import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconSearch } from '@tabler/icons'
import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { AnnouncementNavBarSection } from './styles'

const AnnouncementNavBar = ({ search, setSearch, count }) => {
	const { textsilver, gray, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	return (
		<AnnouncementNavBarSection>
			<View style={{ flexDirection: 'row', height: '100%' }}>
				<Text size={20}>{t('total')}</Text>
				<Text
					size={16}
					style={{ color: COLORS.secondary, marginLeft: 10 }}>
					{count}
				</Text>
			</View>
			<View style={{ flexDirection: 'row' }}>
				<CInput
					placeholder={t('searchNotification')}
					value={search}
					type={'text'}
					onChange={(e: any) => setSearch(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 8,
					}}
					style={{
						background: gray,
						border: `1px solid ${textsilver}`,
						color: textw,
						padding: 5,
						paddingLeft: 35,
						width: '100%',
					}}
				/>
				<IconSearch
					size={16}
					color={textw}
					style={{
						position: 'absolute',
						marginLeft: -145,
						marginTop: -3,
					}}
				/>
			</View>
		</AnnouncementNavBarSection>
	)
}

export default AnnouncementNavBar
