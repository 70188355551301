import React from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'

const TraderDetailLists = ({ title, content, amount }) => {
	return (
		<View
			style={{
				justifyContent: 'space-between',
				flexDirection: 'row',
				width: '100%',
				paddingBottom: 15,
			}}>
			<View style={{ flexDirection: 'row' }}>
				<Text
					style={{
						fontSize: 14,
						marginRight: 5,
						fontWeight: '300',
					}}>
					{title}
				</Text>
				<Text
					style={{
						paddingBlock: 1,
						paddingInline: 4,
						border: `1px solid ${COLORS.lightGray}`,
						fontSize: 10,
						fontWeight: '300',

						borderRadius: 5,
					}}>
					{content}
				</Text>
			</View>
			<Text
				style={{
					fontSize: 12,
					fontWeight: '500',
				}}>
				{amount}
			</Text>
		</View>
	)
}

export default React.memo(TraderDetailLists)
