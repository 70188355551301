import React from 'react'
import { IconChevronLeft } from '@tabler/icons'
import { View, Text } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { useHistory } from 'react-router-dom'
import { useWindowDimensions } from 'app/hooks'
import { useTranslation } from 'react-i18next'

const TraderFollowerBackBtn = () => {
	const { t } = useTranslation()
	const navigation = useHistory()
	const { width } = useWindowDimensions()
	return (
		<View
			onClick={() => {
				//이전페이지가 logout 이면 홈으로
				//goback 로그아웃 페이지면 홈으로 

				if (navigation.location.pathname.includes('logout')) {
					return
				}
				navigation.goBack()
			}}
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-start',
				width: '100%',
				paddingBlock: 10,
				cursor: 'pointer',
				paddingLeft: width < 600 ? 40 : 0,
			}}>
			<IconChevronLeft color={COLORS.lightGray} />
			<Text size={18} style={{ color: COLORS.lightGray }}>
				{t('back')}
			</Text>
		</View>
	)
}

export default TraderFollowerBackBtn
