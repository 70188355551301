import React, { memo, useRef } from 'react'
import { FixedSizeList as List, areEqual } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import memoize from 'memoize-one'

import { CFlatListSection } from './styles'
import { isItemLoaded, loadMoreItems } from './loadMoreItems'
import { Text } from 'styles/reactnative'
import { COLORS } from 'app/constants'
// import NoData from './NoData'
import CNoDataFound from '../CNoDataFound'
import { useWindowDimensions } from 'app/hooks'

const createItemData = memoize(items => ({ items }))

const CFlatList = ({
	data,
	renderItem,
	itemSize,
	noDataTitle,
	noData = null,
}: any) => {
	const { width } = useWindowDimensions()

	const getItemHeight = item => {
		if (isNaN(item)) item = 50

		if (width >= 500 && width <= 600) {
			return item + 20
		} else if (width >= 400 && width < 500) {
			return item + 5
		} else if (width >= 250 && width < 400) {
			return item - 5
		} else {
			return item ? item : 50
		}
	}

	const infiniteLoaderRef = useRef(null)
	const Row = memo(({ data, index, style }: any) => {
		return (
			<div style={{ ...style }}>
				{!isItemLoaded(index) ? (
					<Text style={{ color: COLORS.grayWhite }}>Loading....</Text>
				) : (
					renderItem(data[index], index)
				)}
			</div>
		)
	}, areEqual)

	return (
		<CFlatListSection>
			{data?.length ? (
				<AutoSizer>
					{({ height, width }) => (
						<InfiniteLoader
							isItemLoaded={isItemLoaded}
							itemCount={createItemData(data)?.items?.length}
							loadMoreItems={loadMoreItems}
							ref={infiniteLoaderRef}>
							{({ onItemsRendered, ref }) => (
								<List
									itemData={createItemData(data)?.items}
									className="List"
									height={height}
									itemCount={
										createItemData(data)?.items?.length
									}
									itemSize={getItemHeight(itemSize)}
									width={width}
									onItemsRendered={onItemsRendered}
									ref={ref}>
									{Row}
								</List>
							)}
						</InfiniteLoader>
					)}
				</AutoSizer>
			) : (
				<CNoDataFound
					noDataTitle={noDataTitle}
					noDataContent={undefined}
					noDataSize={undefined}
					noDataHeight={undefined}
					lottieSize={undefined}
				/>
			)}
		</CFlatListSection>
	)
}

export default React.memo(CFlatList)
