import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const DerivativesHeaderWrapper = styled.div`
	height: 100px;
	width: 100%;
	padding: 15px;
`
export const CrossModalWrapper = styled.div`
	background-color: ${p => p.theme.COLORS.drawerBgColor};
	border-radius: 30px;
	align-items: flex-start;
	flex-direction: row;
	padding: 20px;
`
export const DerivativesHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	padding: 7px;
	border-radius: 5px;
`
export const CrossModalDescriptionBox = styled.div`
	background: ${p => `${p.theme.COLORS.header}77`};
	padding: 10px;
	border-radius: 5px;
	margin-top: 20px;
	line-height: 1.5;
`
export const CrossModalLeverage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-block: 20px;
	width: 100%;
`
export const CrossTradeAmount = styled.div`
	background: ${p => p.theme.COLORS.bgColor};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	margin-top: 10px;
	padding-top: 10px;
	border-radius: 5px;
`

interface SliderBarProps extends React.InputHTMLAttributes<HTMLInputElement> {
	maxLeverage: string
}
export const SliderBar = styled.input.attrs({
	type: 'range',
})<SliderBarProps>`
	-webkit-appearance: none;
	height: 8px;
	width: 100%;
	border-radius: 25px;
	background: ${({ value, maxLeverage }: any) =>
		`linear-gradient(to right, ${COLORS.primary} ${
			(value / maxLeverage) * 100
		}%, ${COLORS.lightGray2} ${(value / maxLeverage) * 100}%)`};
	outline: none;
	opacity: 1;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	&:hover {
		opacity: 1;
	}
	&:focus {
		outline: none;
	}
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: ${p => p.theme.COLORS.textsilver};
		cursor: pointer;
	}
	&::-moz-range-thumb {
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: ${p => p.theme.COLORS.textsilver};
		cursor: pointer;
	}
`
export const SliderButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	user-select: none;
`

export const SliderButton = styled.button`
	background-color: transparent;
	border: none;
	font-size: 12px;
	color: ${p => p.theme.COLORS.textw};
	cursor: pointer;
	margin-top: 5px;
`
