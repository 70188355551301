import React from 'react'
import ReferralTableListHeader from './ReferralTableListHeader'
import ReferralTableLists from './ReferralTableLists'
import { ReferralTableListSection } from './styles'

const ReferralTableList = ({ data }) => {
	return (
		<ReferralTableListSection>
			<ReferralTableListHeader />
			{data.map((item, index) => (
				<ReferralTableLists data={item} key={'rlist' + index} />
			))}
		</ReferralTableListSection>
	)
}

export default ReferralTableList
