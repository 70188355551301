import React from 'react'
import { TradingBotSection, SectionWrap } from './styles'
import TradingBotLeftSide from './TradingBotLeftSide'
import TradingBotRightSide from './TradingBotRightSide'
const TradingBotEvent = () => {
	return (
		<TradingBotSection>
			<SectionWrap>
				<TradingBotLeftSide />
				<TradingBotRightSide />
			</SectionWrap>
		</TradingBotSection>
	)
}

export default TradingBotEvent
