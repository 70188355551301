import React from 'react'
import { useTranslation } from 'react-i18next'
import { SectionContainer, SectionWrap } from '../../globalOrganismStyles'
import ExploreProductItem from './ExploreProductItem'
import { Text, View } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'
import trading from 'assets/icons/trading.png'
import blueChip from 'assets/icons/blue-chip.png'
import counter from 'assets/icons/counter.png'
import cryptos from 'assets/icons/cryptos.png'
import { message } from 'antd'
import { goTo } from '../../../hooks/navigation'
import { useHistory } from 'react-router-dom'
const EventSection = () => {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	const navigation = useHistory()
	const data = [
		{
			title: 'spot',
			content: 'spotContent',
			image: cryptos,
			action: ()=>{
				message.info('Coming Soon')
			}
		},
		{
			title: 'derivatives',
			content: 'derivativeContent',
			image: blueChip,
			action: ()=>{
				goTo(navigation, '/trade/2');
			}
		},
		{
			title: 'copyTrading',
			content: 'copyTradingContent',
			image: counter,
			action: ()=>{
				message.info('Coming Soon')
			}
		},
	]
	const mobile = width < 600
	return (
		<SectionContainer>
			<SectionWrap style={{ flexDirection: 'column' }}>
				<View
					style={{
						alignSelf: 'flex-start',
						paddingBottom: 30,
						paddingLeft: mobile ? 10 : 0,
					}}>
					<Text size={mobile ? 20 : 30}>{t('allProducts')}</Text>
				</View>
				<View
					style={{
						flexDirection: 'row',
						flexWrap: 'wrap',
						justifyContent: 'space-evenly',
						width: '100%',
						paddingLeft: mobile ? 10 : 0,
						paddingRight: mobile ? 10 : 0,
						marginBottom: width < 600 ? 200 : width < 900 ? 200 : 0,
					}}>
					{data?.map((item, index) => (
						<ExploreProductItem
							item={item}
							index={index}
							key={index.toString()}
						/>
					))}
				</View>
			</SectionWrap>
		</SectionContainer>
	)
}

export default React.memo(EventSection)
