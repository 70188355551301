import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { useTradeSetting } from '../../../../../../../hooks/trade/tradeSetting'
import { useTradeModalStore } from '../../../../../../../hooks/trade/modalStore'
interface IProps {
	cross_iso: string
	leverage: number
	ep_id: number
	closeModal?: () => void
}
const CrossModalFooter = ({
	cross_iso,
	leverage,
	ep_id,
	closeModal,
}: IProps) => {
	const { t } = useTranslation()
	const { mutate: mutateTradeSetting } = useTradeSetting(ep_id)
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '92%',
				marginTop: 20,
				position: 'absolute',
				bottom: 30,
			}}>
			<CButton
				title={t('confirm')}
				style={{
					flex: 1,
					padding: 10,
					background: COLORS.primary,
					marginRight: 10,
					color: COLORS.white,
				}}
				onClick={() => {
					mutateTradeSetting({ leverage, cross_iso }, ep_id)
					muatateModalStore({
						...modalStore,
						crossModal: false,
					})
				}}
			/>
			<CButton
				title={t('cancel')}
				style={{
					flex: 1,
					padding: 10,
					background: COLORS.lightGray5,
					color: COLORS.greyWhite,
				}}
				onClick={() => {
					muatateModalStore({
						...modalStore,
						crossModal: false,
					})
				}}
			/>
		</View>
	)
}

export default CrossModalFooter
