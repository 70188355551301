import styled from 'styled-components'

export const DepositLeftSectionWrapper = styled.div`
	width: 60%;
	height: 100%;
`
export const DepositRightSectionWrapper = styled.div`
	width: 40%;
	height: 100%;
`

export const DepositCoinListWrapper = styled.div`
	position: absolute;
	width: 55.5%;
	top: 135px;
	left: 15px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	max-height: 150px;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 9;
	background-color: ${props => props.theme.COLORS.gray};
`

export const DepositChainTypeListWrapper = styled.div`
	position: absolute;
	width: 55.5%;
	top: 225px;
	left: 15px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100px;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 9;
	background-color: ${props => props.theme.COLORS.gray};
`
