import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const HeaderBottomLists = ({ item, index }) => {
	const { borderColor } = useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	return (
		<View
			style={{
				alignItems: 'flex-start',
				width: '100%',
				paddingBottom: width < 1100 ? 10 : 0,
				marginBottom: width < 1100 ? 10 : 0,
				borderBottom: width < 1100 ? `1px solid ${borderColor}` : 0,
			}}>
			<Text size={14}>{t(item.title)}</Text>
			<Text
				size={width < 600 ? 16 : 20}
				style={{
					marginRight: 10,

					fontWeight: '500',
					marginBlock: 10,
				}}>
				{item.usd} USD
			</Text>
			<Text size={width < 600 ? 12 : 16}>≈ {item.btc} BTC</Text>
		</View>
	)
}

export default React.memo(HeaderBottomLists)
