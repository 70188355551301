import React from 'react'
import SettingTitle from '../SettingTitle'
import AccountActivities from './AccountActivities'
import AccountInfo from './AccountInfo'
import AdvancedProtection from './AdvancedProtection'
import { AccountSecuritySettingSection } from './styles'
import WithdrawalSecurity from './WithdrawalSecurity'

const AccountSecuritySetting = () => {
	return (
		<AccountSecuritySettingSection>
			<SettingTitle title={'accountSecurity'} />
			<AccountInfo />
			<AdvancedProtection />
			<AccountActivities />
		</AccountSecuritySettingSection>
	)
}

export default AccountSecuritySetting
