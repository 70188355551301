import { COLORS, IMAGES } from 'app/constants'
import styled from 'styled-components'

export const TraderFollowerSection = styled.div`
	position: static;
	top: 0;
	width: 100%;
	height: 250px;
	background-color: ${COLORS.darkBlue};
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: 900px) {
		height: 284px;
	}
`

export const SectionWrap = styled.div`
	width: 100%;
	height: 100%;
	align-items: flex-start;
	max-width: 1280px;
	display: flex;
	flex-direction: column;
	background-size: cover;
	background-image: url(${IMAGES.followerTrader});
	padding-top: 10px;
	@media only screen and (max-width: 900px) {
		flex-direction: column;
	}
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
