import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import MOrderHistoryHeader from './MOrderHistoryHeader'
import OrderHistoryList from './OrderHistoryList'
import { COLORS } from '../../../../constants'

const MOrderHistoryItems = ({ item }) => {
	const { t } = useTranslation()
	const { cardOpacity } = useContext(ThemeContext).theme.COLORS
	return (
		<MView
			style={{
				width: '95%',
				alignSelf: 'center',
				margin: 'auto',
				marginTop: 10,
				borderRadius: 7,
				paddingBottom: 10,
				background: cardOpacity,
			}}>
			<MOrderHistoryHeader item={item} />
			<MView style={{ paddingInline: 10, width: '100%' }}>
				<OrderHistoryList
					title={'qty'}
					content={new BigNumber(item?.amount)
						?.multipliedBy(item?.leverage)
						.toNumber()}
				/>
				<OrderHistoryList
					title={'orderPrice'}
					content={
						item?.limit_market == 'M'
							? t('market')
							: item?.order_price
					}
				/>
				<OrderHistoryList
					title={'tradePrice'}
					content={item?.trade_price}
				/>
				<OrderHistoryList
					title={'orderType'}
					content={
						item?.limit_market === 'L' ? t('limit') : t('market')
					}
				/>
				<OrderHistoryList
					title={'pnl'}
					contentColor={
						item?.pnl_price > 0
							? COLORS.lightGreen
							: COLORS.lightRed
					}
					content={item?.pnl_price}
				/>

				<OrderHistoryList
					title={'fee'}
					contentColor={COLORS.lightRed}
					content={-Math.abs(item?.fee_price)}
				/>

				<OrderHistoryList
					title={'status'}
					content={
						item?.status === 1
							? t('opened')
							: item?.status === 2
							? t('filled')
							: t('canceled')
					}
				/>
			</MView>
		</MView>
	)
}

export default MOrderHistoryItems
