import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const LeaderBoardTableSection = styled.div`
	width: 100%;
	max-width: 1280px;
	margin: auto;
	margin-top: 30px;
	border-radius: 15px;
	border: 1px solid ${p => p.theme.COLORS.borderColor};
`
export const LeaderBoardHeaderText = styled.div`
	color: ${COLORS.lightGray};
	width: 20%;
	font-weight: 500;
	display: flex;
`
