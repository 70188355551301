import React from 'react'
import { CImage } from 'app/components/Common'
import { View } from 'styles/reactnative'
// import { AnnouncementText } from './styles'
import { MarkdownComponent } from 'app/components'
import { useTranslation } from 'react-i18next'

const AnnouncementBody = ({ show }) => {
	const images = show?.images ? JSON.parse(show?.images || []) : []
	
	const { t } = useTranslation()

	//거래소 이름 치환
	const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

	const currentname = t('title')

	const content = show?.content?.replace(
		new RegExp(list.join('|'), 'gi'),
		currentname
	)

	return (
		<View style={{ padding: 20, alignItems: 'flex-start' }}>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					flex: 1,
					flexWrap: 'wrap',
				}}>
				{images?.map((item: any, index: any) => (
					<CImage
						key={index.toString()}
						src={item}
						alt="image"
						width={'auto'}
						height={'auto'}
						resizeMode={'cover'}
						style={{
							maxWidth: '100%',
							marginBottom: 5,
							borderRadius: 5,
						}}
					/>
				))}
			</View>
			<View
				style={{
					alignItems: 'flex-start',
					paddingInline: 40,
					width: '100%',
				}}>
				<MarkdownComponent markdown={content} />
				{/* <AnnouncementText>{show?.content}</AnnouncementText> */}
			</View>
		</View>
	)
}

export default AnnouncementBody
