import React, { useContext } from 'react'
import { SectionContainer } from '../../globalOrganismStyles'
import { View } from 'styles/reactnative'
import { ContentHalf } from 'styles/globalPage-styles'
import { ThemeContext } from 'theme/ThemeProvider'
import SpecialLeftSection from './SpecialLeftSection'
import SpecialRightSection from './SpecialRightSection'
const SpecialSection = () => {
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<SectionContainer>
			<View
				style={{
					background: isDarkMode
						? 'rgba(255,255,255,0.3)'
						: 'rgba(52, 49, 64, 0.8)',
					height: 560,
					width: '100%',
				}}>
				<ContentHalf style={{ height: '100%', paddingInline: 30 }}>
					<View
						style={{
							width: '100%',
							height: '100%',
							flexDirection: 'row',
						}}>
						<SpecialLeftSection />
						<SpecialRightSection />
					</View>
				</ContentHalf>
			</View>
		</SectionContainer>
	)
}

export default SpecialSection
