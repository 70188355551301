import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import DepositSettingList from './DepositSettingList'

const DepositSetting = () => {
	const { t } = useTranslation()
	// const [checkedOne, setCheckedOne] = useState<boolean>(false)
	const [checkedTwo, setCheckedTwo] = useState<boolean>(false)
	// const [checkedThree, setCheckedThree] = useState<boolean>(false)
	const [checkedFour, setCheckedFour] = useState<boolean>(false)

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
				padding: 10,
			}}>
			<Text
				size={18}
				style={{
					fontWeight: '500',
					marginBottom: 20,
				}}>
				{t('deposit')}
			</Text>
			{/* <DepositSettingList
				checked={checkedOne}
				setChecked={setCheckedOne}
				title={'autoTransferOfFunds'}
			/> */}
			<DepositSettingList
				checked={checkedTwo}
				setChecked={setCheckedTwo}
				title={t('yourAssetsWillBeAutoDepositedSpotAccount')}
			/>
			{/* <DepositSettingList
				checked={checkedThree}
				setChecked={setCheckedThree}
				title={'depositsWillBeChanneled'}
			/> */}
			<DepositSettingList
				checked={checkedFour}
				setChecked={setCheckedFour}
				title={t('yourAssetsWillBeAutoDepositedUsdcDerivatives')}
			/>
		</View>
	)
}

export default React.memo(DepositSetting)
