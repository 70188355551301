import React, {
	useState,
	useMemo,
	useRef,
	useCallback,
	useContext,
} from 'react'
import { AgGridReact } from 'ag-grid-react'

import { useWindowDimensions } from 'app/hooks/'
import { MarketTableData } from './MarketTableData'
import { MarketTableListSection, TableContainer } from './styles'
// import MarketTableHeader from './MarketTableHeader'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ThemeContext } from 'theme/ThemeProvider'
// import MarketSubMenu from './MarketSubMenu'
import { Text, View } from 'styles/reactnative'

import { useTranslation } from 'react-i18next'

const FavouriteTableList = ({ data }) => {
	const { t } = useTranslation()
	const { textw, drawerBgColor } = useContext(ThemeContext).theme.COLORS

	const { width } = useWindowDimensions()
	const gridRef = useRef<any>()

	const [columnDefs] = useState(MarketTableData())

	const onGridReady = useCallback(params => {}, [])

	const defaultColDef = useMemo(
		() => ({
			flex: width > 1400 ? 1 : 0,
			minWidth: 150,
			sortable: true,
			resizable: true,
		}),
		[width]
	)

	const dLength = data?.length
	const hLength = dLength < 4 ? 500 : dLength < 7 ? 1000 : 2000
	const derivatives = 'derivatives'
	const spot = 'spot'

	const [tab, setTab] = useState(derivatives)
	const subMenu = [derivatives, spot]

	const LoadingComponent = () => (
		<View
			style={{
				background: drawerBgColor,
				width: 1200,
				height: 700,
			}}>
			<Text size={30}>{t('currentlyNoData')}</Text>
		</View>
	)
	return (
		<MarketTableListSection>
			<TableContainer
				className="ag-theme-alpine ag-theme-custom"
				style={{ width: '100%', height: 500 }}>
				<AgGridReact
					rowStyle={{
						justifyContent: 'center',
						alignItems: 'center',
						alignSelf: 'center',
						paddingTop: 10,
						color: textw,
						border: 'none',
					}}
					animateRows={true}
					ref={gridRef}
					rowData={data}
					columnDefs={columnDefs}
					// onRowClicked={({ data }: any) => {
					// 	navigation.push(`/trade/${data?.id}`)
					// }}
					// loadingOverlayComponent={<LoadingComponent />}
					noRowsOverlayComponent={() => <LoadingComponent />}
					suppressLoadingOverlay={true}
					defaultColDef={defaultColDef}
					onGridReady={onGridReady}
					// enableRangeSelection={true}
					rowHeight={60}
					paginationAutoPageSize={true}
					pagination={true}
				/>
			</TableContainer>
		</MarketTableListSection>
	)
}

export default FavouriteTableList
