import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'

const CopyTradeSettingInputs = ({ value, setValue, placeholder, title }) => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				width: '100%',
				marginBottom: 10,
			}}>
			<Text size={16} style={{ fontWeight: '400', color: COLORS.black }}>
				{t(title)}
			</Text>
			<CInput
				placeholder={t(placeholder)}
				value={value}
				type={'text'}
				onChange={(e: any) => setValue(e.target.value)}
				vStyle={{ padding: 3, flexDirection: 'row', width: '100%' }}
				style={{
					background: COLORS.greyWhite,
					border: 0,
					marginTop: 10,
					color: COLORS.black,
					padding: 10,
					width: '100%',
					paddingRight: 80,
				}}
			/>

			<View style={{ position: 'absolute', right: 50 }}>
				<Text size={14} style={{ marginTop: 40, color: COLORS.black }}>
					{t('usdt')}
				</Text>
			</View>
		</View>
	)
}

export default React.memo(CopyTradeSettingInputs)
