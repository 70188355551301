import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import AssetsTitle from '../AssetsTitle'
import { AssetSpotContainer, AssetDerivativeSection } from './styles'
import AssetDerivativesTable from './AssetDerivativesTable'
import AssetDerivativesHeader from './AssetDerivativesHeader'

const AssetDerivatives = ({ title }) => {
	const { height, width } = useWindowDimensions()
	const hSize = width < 1200 ? height / 1 : height / 1.5
	return (
		<AssetDerivativeSection>
			<AssetsTitle title={title} />
			<AssetSpotContainer style={{ height: hSize }}>
				<AssetDerivativesHeader />
				<AssetDerivativesTable />
			</AssetSpotContainer>
		</AssetDerivativeSection>
	)
}

export default AssetDerivatives
