import React, { useContext } from 'react'
import { MText } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MAnnouncementItemSection } from './styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const MAnnouncementItems = ({ item, show, setShow }: any) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	//거래소 이름 치환
	const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

	const currentname = t('title')

	const title = item?.title?.replace(
		new RegExp(list.join('|'), 'gi'),
		currentname
	)

	return (
		<MAnnouncementItemSection
			onClick={() => {
				setShow &&
					setShow({
						show: true,
						...item,
					})
			}}>
			<MText style={{ fontWeight: '300' }}>{item?.title}</MText>
			<MText style={{ color: textsilver, fontSize: 10, paddingTop: 8 }}>
				{moment(item?.rdate)
					.tz('Asia/Seoul')
					.format('YYYY-MM-DD HH:mm:ss')}
			</MText>
		</MAnnouncementItemSection>
	)
}

export default MAnnouncementItems
