import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import EarningChart from './EarningCharts'
import StatisticEarningHeader from './StatisticEarningHeader'

const StatisticEarning = () => {
	const [selectByDay, setSelectByDay] = useState('7 Days')
	const [selectCoin, setSelectCoin] = useState('All Contacts')
	const coinLists = [
		'All Contacts',
		'BTCUSDT',
		'ETHUSDT',
		'XRPUSDT',
		'LDCUSDT',
		'LINKUSDT',
	]
	const dayLists = ['7 Days', '30 Days', '90 Days', '180 Days']
	return (
		<View
			style={{
				width: '100%',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				height: 330,
			}}>
			<StatisticEarningHeader
				coinLists={coinLists}
				selectCoin={selectCoin}
				setSelectCoin={setSelectCoin}
				dayLists={dayLists}
				selectByDay={selectByDay}
				setSelectByDay={setSelectByDay}
			/>
			<EarningChart />
		</View>
	)
}

export default React.memo(StatisticEarning)
