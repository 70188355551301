import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import FeedbackContainerLeft from './FeedbackContainerLeft'

const Satisfaction = ({ satisfaction, setSatisfaction }) => {
	const { t } = useTranslation()
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
				height: '10%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				flexDirection: 'row',
			}}>
			<FeedbackContainerLeft text={'satisfaction'} />

			<View
				style={{
					width: '80%',
					height: '100%',
					alignItems: 'flex-start',
				}}>
				<View>
					<View
						style={{
							width: '100%%',
							height: '50%',
							flexDirection: 'row',
						}}>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
							<CButton
								title={item}
								key={index.toString()}
								onClick={() => {
									setSatisfaction(item)
								}}
								style={{
									background:
										satisfaction === item
											? COLORS.secondary
											: textsilver,
									color: COLORS.white,
									width: 30,
									height: 33,
									marginRight: 10,
									borderRadius: 7,
								}}
							/>
						))}
					</View>

					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							height: '100%',
							width: '100%',
							marginTop: 10,
						}}>
						<Text
							size={12}
							style={{ color: textw, fontWeight: '300' }}>
							{t('notAtAllLikely')}
						</Text>
						<Text
							size={12}
							style={{ color: textw, fontWeight: '300' }}>
							{t('extreamelyLikely')}
						</Text>
					</View>
				</View>
			</View>
		</View>
	)
}

export default Satisfaction
