import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import PerformanceDetailBodyList from './PerformanceDetailBodyList'

const PerformanceDetailBody = () => {
	const { t } = useTranslation()
	return (
		<View style={{ padding: '16px 24px' }}>
			<PerformanceDetailBodyList
				title={t('cumFollowers')}
				content={'43'}
			/>
			<PerformanceDetailBodyList
				title={t('7DayWinRate')}
				content={'+81.25%'}
				contentColor={COLORS.lightGreen}
			/>
			<PerformanceDetailBodyList
				title={t('totalTrades')}
				content={'223'}
			/>
			<PerformanceDetailBodyList title={t('winTrades')} content={'148'} />
			<PerformanceDetailBodyList title={t('loseTrades')} content={'73'} />
			<PerformanceDetailBodyList
				title={t('avgP&L')}
				content={'+0.20 USDT'}
				desc={'/ Trade'}
				contentColor={COLORS.lightGreen}
			/>
			<PerformanceDetailBodyList
				title={t('7DP&LRatio')}
				content={'106.9919 : 1'}
			/>
			<PerformanceDetailBodyList
				title={t('avgHoldingTime')}
				content={'2.31Days'}
			/>
			<PerformanceDetailBodyList
				title={t('tradingFrequency(Weekly)')}
				content={'8.75'}
			/>
			<PerformanceDetailBodyList
				title={t('lastTradedAt')}
				content={'2023-02-03 21:44:03'}
			/>
			<Text style={{ alignSelf: 'flex-end' }}>
				{t('measuredIn')}: USDT
			</Text>
		</View>
	)
}

export default React.memo(PerformanceDetailBody)
