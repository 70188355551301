import styled from 'styled-components'

export const NotificationSection = styled.div`
	min-height: 1200px;
	max-height: auto;
	margin-bottom: 200px;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
export const NotificationContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: ${p => p.theme.COLORS.drawerBgColor};
`

export const NotificationHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100px;
	justify-content: space-between;
	/* padding: 40px; */
`
