import React from 'react'
import { AssetDerivativesHeaderSection } from '../styles'
import AssetDerivativesHeaderBottom from './AssetDerivativesHeaderBottom'
import AssetDerivativesHeaderTop from './AssetDerivativesHeaderTop'

const AssetDerivativesHeader = () => {
	return (
		<AssetDerivativesHeaderSection>
			<AssetDerivativesHeaderTop />
			<AssetDerivativesHeaderBottom />
		</AssetDerivativesHeaderSection>
	)
}

export default AssetDerivativesHeader
