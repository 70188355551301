import React, { useCallback, useEffect, useState } from 'react'
import { IconArrowDown, IconArrowUp } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { OrderBookCurrentPriceWrapper } from './styles'
import { useLastPrice } from '../../../../hooks/trade/lastprice'
import BigNumber from 'bignumber.js'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import useTradeStore from '../../../../hooks/trade/tradeStore'

import throttle from 'lodash/throttle' // Lodash 추가

const OrderBookCurrentPrice = () => {
	//const { data: priceStore, mutate } = usePriceStore()
	const { data: store } = useTradeStore()
	let { data: lastPrice } = useLastPrice(store?.ep_id)
	let { getPrice } = useIndexPrice(store?.ep_id)

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const currency = getSetting(setting, 'CURRENCY', 'KRW')

	const { data: result } = useFetcherPublic('/system/currency')
	const currentCurrency = result?.result?.result?.find(
		(item: any) => item.code === currency
	)

	const indexPrice = getPrice(store?.ep_id)
	if (!lastPrice) lastPrice = [{ price: 0 }, { price: 0 }]

	const [throttledPrice, setThrottledPrice] = useState(indexPrice)

	
	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		throttle(newPrice => {
			// 여기서 가격 업데이트 로직을 실행합니다.
			setThrottledPrice(newPrice) // 쓰로틀된 가격을 상태로 업데이트
		}, 50), // 2초마다 최대 1번 실행
		[] // 의존성 배열 비움
	)

	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(indexPrice)
	}, [indexPrice, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함


	return (
		<OrderBookCurrentPriceWrapper>
			<View style={{ flexDirection: 'row', marginRight: 10 }}>
				{lastPrice?.length > 0 &&
					lastPrice?.[0]?.price >= lastPrice?.[1]?.price && (
						<IconArrowUp size={20} color={COLORS.lightGreen} />
					)}
				{lastPrice?.length > 0 &&
					lastPrice?.[0]?.price < lastPrice?.[1]?.price && (
						<IconArrowDown size={20} color={COLORS.lightRed} />
					)}
				<Text
					size={18}
					style={{
						color:
							lastPrice?.length > 0 &&
							lastPrice?.[0]?.price >= lastPrice?.[1]?.price
								? COLORS.lightGreen
								: COLORS.lightRed,
						fontWeight: 'bold',
					}}>
					{BigNumber(throttledPrice).toFormat(store?.dp)}
				</Text>
			</View>
			<View>
				<Text size={12} style={{ color: COLORS.lightGray }}>
					{' '}
					≒{' '}
					{new BigNumber(throttledPrice)
						.multipliedBy(currentCurrency?.price || 1)
						.toFormat(0)}{' '}
					{currentCurrency?.symbol}
				</Text>
			</View>
		</OrderBookCurrentPriceWrapper>
	)
}

export default React.memo(OrderBookCurrentPrice)
