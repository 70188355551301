import styled from 'styled-components'

export const HeaderSearchListsSection = styled.div`
	width: 100%;
	height: 400px;
	overflow-x: hidden;
	overflow-y: auto;
`
export const HeaderSearchListItemSection = styled.div``
export const TradeDrawerListItemSection = styled.div`
	padding: 10px;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
`
