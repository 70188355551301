import { COLORS, SIZES } from 'app/constants'
import styled from 'styled-components'

export const RankingTabsWrapper = styled.div`
	width: 100%;
	height: 1100px;
	background-color: ${COLORS.white};
	border-radius: 20px;
	border-top-left-radius: 0px;
	box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	padding-bottom: 10px;
	z-index: 1;
`

export const RankItemSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	border-top: 1px solid ${COLORS.lightGray2};
	&:hover {
		background-color: ${COLORS.lightGray2};
	}
`
export const RankTableText = styled.div`
	background: transparent;
	display: flex;
	align-items: center;
	width: 15%;
	cursor: pointer;
	font-size: ${SIZES.h5};
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
`
export const RankSection = styled.div`
	width: 100%;
	height: 100%;
	background-color: transparent;
	padding: 20px;
`
