import React from 'react'
import { FollowerDashboardSection, FollowerDashboardWrapper } from './styles'

const FollowerDashbard = () => {
	return (
		<FollowerDashboardSection style={{}}>
			<FollowerDashboardWrapper></FollowerDashboardWrapper>
		</FollowerDashboardSection>
	)
}

export default FollowerDashbard
