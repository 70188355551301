import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	title?: string
	amount?: string
}
const ContaractDetailsList = ({ title, amount }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '100%',
				marginBottom: 8,
			}}>
			<Text style={{ color: COLORS.lightGray5, fontSize: 12 }}>
				{title}
			</Text>
			{amount ? (
				<Text style={{ color: textw, fontSize: 12 }}>{amount}</Text>
			) : null}
		</View>
	)
}

export default React.memo(ContaractDetailsList)
