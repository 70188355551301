import React from 'react'
import { View } from 'styles/reactnative'
import { CModal } from 'app/components/Common'

import { COLORS } from 'app/constants'
import CrossModal from 'app/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesHeader/CrossModal'
import OrderPreferenceModal from 'app/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesBody/OrderValueInput/OrderPreferenceModal'
import PositionCloseContainer from 'app/organisms/Trade/TradeDetail/TradePositionsTable/PositionCloseContainer'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ConvertModal, DepositModal, TransferModal } from 'app/components'
import WithdrawModal from '../components/WithdrawModal'

const TradeModal = () => {
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()

	return (
		<View style={{ color: COLORS.greyWhite }}>
			<CModal
				visible={modalStore?.depositModal}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						depositModal: false,
					})
				}}>
				<DepositModal
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							depositModal: value,
						})
					}}
				/>
			</CModal>
			<CModal
				visible={modalStore?.transferModal}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						transferModal: false,
					})
				}}>
				<TransferModal
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							transferModal: value,
						})
					}}
				/>
			</CModal>
			<CModal
				visible={modalStore?.withdrawModal}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						withdrawModal: false,
					})
				}}>
				<WithdrawModal
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							withdrawModal: value,
						})
					}}
				/>
			</CModal>
			<CModal
				visible={modalStore?.accountConvert}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						accountConvert: false,
					})
				}}>
				<ConvertModal
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							accountConvert: value,
						})
					}}
				/>
			</CModal>
			<CModal
				visible={modalStore?.crossModal}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						crossModal: false,
					})
				}}
				bgColor={'transparent'}>
				<CrossModal
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							crossModal: value,
						})
					}}
				/>
			</CModal>

			<CModal
				visible={modalStore?.orderPlace}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						orderPlace: false,
					})
				}}
				bgColor={'transparent'}>
				<OrderPreferenceModal
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							orderPlace: value,
						})
					}}
				/>
			</CModal>

			<CModal
				visible={modalStore?.positionClose?.close}
				onClose={() => {
					muatateModalStore({
						...modalStore,
						positionClose: {
							close: false,
						},
					})
				}}
				bgColor={'transparent'}>
				<PositionCloseContainer
					setShowModal={value => {
						muatateModalStore({
							...modalStore,
							positionClose: {
								close: value,
							},
						})
					}}
				/>
			</CModal>
		</View>
	)
}

export default TradeModal
