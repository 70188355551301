import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const TraderFollowerInfoSection = styled.div`
	width: 100%;
	min-height: 168px;
	background-color: ${COLORS.white};
	border: 1px solid #efefef;
	box-shadow: 0 4px 44px rgb(0 0 0 / 8%);
	border-radius: 8px;
	z-index: 1;
	padding: 24px;
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: 600px) {
		width: 97%;
		align-self: center;
	}
`
export const TraderFollowInfoListSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	@media only screen and (max-width: 600px) {
		width: 15%;
	}
	@media only screen and (min-width: 600px) {
		width: 15%;
	}
	@media only screen and (min-width: 768px) {
		width: 16%;
	}
	@media only screen and (min-width: 992px) {
		width: 15%;
	}
	@media only screen and (min-width: 1200px) {
		width: 10%;
	}
`
