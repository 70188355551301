import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import { useBalance } from '../../../../../../hooks/user/mybalance'
import DerivativesAccountBtns from './DerivativesAccountBtns'
import DerivativesAccountList from './DerivativesAccountList'
import { ThemeContext } from 'theme/ThemeProvider'
import { useFetcher } from '../../../../../../hooks/fetcher'
import { useLastPrice } from '../../../../../../hooks/trade/lastprice'
import { useTradePosition } from '../../../../../../hooks/trade/tradePosition'
import { useMyProfile } from '../../../../../../hooks/user/myprofile'
import { useIndexPrice } from '../../../../../../hooks/trade/indexprice'

const DerivativesAccount = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { data: tradeStore } = useTradeStore()
	const currency = tradeStore?.pairs?.split('/')[1]

	const { data: position } = useTradePosition()

	const { getPrice } = useIndexPrice(tradeStore?.ep_id)

	const open_pos = position

	let pnl = 0
	for (let i = 0; i < open_pos?.length; i++) {
		if(open_pos[i].cross_iso == 'I') continue

		if (open_pos[i].long_short === 'L') {
			pnl +=
				(getPrice(open_pos?.[i]?.exchange_pairs_id) -
					open_pos?.[i]?.open_price) *
				open_pos[i]?.amount *
				open_pos[i]?.leverage
		} else {
			pnl +=
				(open_pos[i]?.open_price -
					getPrice(open_pos?.[i]?.exchange_pairs_id)) *
				open_pos[i]?.amount *
				open_pos[i]?.leverage
		}
	}

	const { data: balance } = useBalance(currency, 1)

	let availableBalance = new BigNumber(balance?.balance || 0)
		.minus(balance?.lockbalance || 0)
		.plus(pnl)
		.toNumber()

	availableBalance = Math.max(availableBalance, 0)

	return (
		<View
			style={{
				alignItems: 'flex-start',
				marginTop: 20,
				borderTop: `1px solid ${COLORS.gray}`,
				paddingTop: 10,
				width: '100%',
			}}>
			<Text size={14} style={{ color: textw, marginBottom: 10 }}>
				{t('derivativesAccount')}
			</Text>

			<DerivativesAccountList
				title={'equity'}
				amount={
					isNaN(BigNumber(balance?.balance).plus(pnl).toNumber())
						? 0
						: BigNumber(balance?.balance).plus(pnl).toNumber()
				}
			/>
			{/*사용가능자산PC*/}
			<DerivativesAccountList
				title={'availableBalance'}
				amount={availableBalance}
			/>

			<DerivativesAccountBtns />
		</View>
	)
}

export default DerivativesAccount
