import styled from 'styled-components'

export const MCoinListSection = styled.div`
	width: 100%;
	height: 100%;
`
export const MCoinItemSection = styled.div`
	display: flex;
	align-items: center;
`
