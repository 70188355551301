import React, { useState } from 'react'
import { CFlatList } from 'app/components/Common'
import { TradeDrawerListSection } from '../styles'
import TradeListTitle from '../TradeListTitle'
import { useFetcherPublic } from 'app/hooks/fetcher'
import DrawerListItem from '../DrawerListItem'
import { useFavourite } from '../../../../hooks'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'

const TradeDataList = ({ search, setDisplayDrawer, tab }) => {
	const [top, setTop] = useState(0)
	const { EPs } = useExchangeStore()

	const { data: favourites } = useFavourite()
	let favData: any
	favData =
		favourites?.filter(item =>
			item?.pairs?.toLowerCase()?.includes(search?.toLowerCase())
		) || []

	let filteredData: any
	if (search?.length) {
		filteredData = EPs.filter(item =>
			item.pairs.toLowerCase().includes(search.toLowerCase())
		)
	} else {
		filteredData = EPs
	}

	filteredData = EPs?.filter(
		item => !favData?.find(fav => fav.pairs === item.pairs)
	)

	filteredData = favData?.concat(filteredData)

	//정렬
	filteredData = filteredData?.sort((a, b) => {
		if (top === 0) {
			return 0
		} else if (top === 1) {
			return a.pair > b.pair ? 1 : -1
		} else if (top === 2) {
			return b.pair > a.pair ? 1 : -1
		}
	})

	// const renderItem = (item: any, index: any) => {
	// 	return (
	// 		<DrawerListItem
	// 			item={item}
	// 			index={index}
	// 			setDisplayDrawer={setDisplayDrawer}
	// 		/>
	// 	)
	// }
	return (
		<TradeDrawerListSection>
			<TradeListTitle top={top} setTop={setTop} />
			{filteredData?.map((item, index) => {
				return (
					<DrawerListItem
						key={'LIST' + index}
						item={item}
						index={index}
						setDisplayDrawer={setDisplayDrawer}
					/>
				)
			})}
		</TradeDrawerListSection>
	)
}

export default React.memo(TradeDataList)
