import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CText from 'app/components/Common/CText'
import { FONTS } from 'app/constants/STYLES'
import {
	FooterContainer,
	FooterWrapper,
	Copyright,
	CopyrightText,
} from './styles'

import { ThemeContext } from 'theme/ThemeProvider'

const Footer = () => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()

	return (
		<FooterContainer>
			<FooterWrapper>
				<Copyright>
					<CopyrightText>
						<CText
							text={t('copyRight')}
							style={{
								...FONTS.h5,
								fontSize: 14,
								fontWeight: '500',
								color: textsilver,
							}}
						/>
					</CopyrightText>
				</Copyright>
			</FooterWrapper>
		</FooterContainer>
	)
}

export default React.memo(Footer)
