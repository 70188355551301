import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const AccordionHeaderText = styled.div``
export const AccordionList = styled.li`
	border-width: 1px;
	border-style: outset;
	border-color: ${p => p.theme.COLORS.borderColor};
	margin-top: 5px;
	&:hover {
		background-color: ${COLORS.primary};
	}
	&:hover ${AccordionHeaderText} {
		color: ${p => p.theme.COLORS.textw};
	}
`
export const AccordionHeader = styled.div`
	width: 100%;
	margin: 0;
`

export const AccordionHeaderBtn = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	background-color: transparent;
	color: ${p => p.theme.COLORS.textw};
	border: 0;
	padding: 15px 20px;
	font-size: 18px;
	font-weight: 400;
	cursor: pointer;
`

export const AccordionHeaderIcon = styled.div<{ isOpen: boolean }>`
	content: '';
	flex-shrink: 0;
	width: 18px;
	height: 18px;
	background-color: transparent;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat; */
	background-size: 100%;
	transition: transform 0.1s ease-in-out;
	${({ isOpen }) =>
		isOpen &&
		`
        transform: rotate(-180deg);
    `}
`
export const AccordionBodyContainer = styled.div`
	transition: height 0.2s ease-in-out;
	overflow: hidden;
`
export const AccordionContent = styled.div`
	padding: 0px;
	padding-left: 20px;
	padding-right: 20px;
`
