import axios from 'axios'
import useSWR from 'swr'

import { CHART_HISTORY } from '../../constants/APIKEYS'
import { useRef } from 'react'

export function useChart(symbol, resolution) {
	let swrData = useRef<any>(null)

	const KEY = `${CHART_HISTORY}?symbol=${symbol}&resolution=${resolution}`
	const { data, mutate } = useSWR(
		KEY,
		async () => {
			if (swrData.current !== null) {
				return swrData.current
			}
			let res = await axios.get<any, any>(KEY)
			res = res.data

			if (res?.c) {
				swrData.current = res?.c?.slice(-60)
				return swrData.current
			} else {
				swrData.current = [0]
				return swrData.current
			}
		},
		{
			revalidateOnFocus: true,
			dedupingInterval: 5000,
		}
	)

	return {
		data,
		mutate: value => {
			if (value !== undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
