import React from 'react'

import { View, Text } from 'styles/reactnative'

const TableListItems = ({ item, CoinType }: any) => {
	return (
		<View
			style={{
				alignItems: 'center',
				justifyContent: 'flex-start',
				flexDirection: 'row',
			}}>
			<Text size={12}>{item}</Text>
			{CoinType ? <CoinType /> : null}
		</View>
	)
}

export default React.memo(TableListItems)
