import React from 'react'
import { AboutAppViewContent } from './styles'
import { Text } from 'styles/reactnative'
import AboutContext from './AboutContext'
import { useTranslation } from 'react-i18next'

const AboutMission = () => {
	const { t } = useTranslation()
	return (
		<AboutAppViewContent style={{ marginTop: 73 }}>
			<Text size={42}>{t('ourMission')}</Text>
			<AboutContext context={t('ourMissionContent')} />
		</AboutAppViewContent>
	)
}

export default AboutMission
