import React, { useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import { useToken } from 'app/hooks'
import MNotificationHeader from './MNotificationHeader'
import MNotificationLists from './MNotificationLists'
import { MNotificationSection } from './styles'
import { fetcher } from 'app/hooks/fetcher'

const MNotifications = () => {
	const { data: token } = useToken()
	const PAGE_SIZE = 20
	const [tab, setTab] = useState({ index: 0, name: 'all' })
	const { data: res } = useSWRInfinite(
		index =>
			token
				? `/system/notification/?page=${
						index + 1
				  }&pagesize=${PAGE_SIZE}&type=${tab?.index}`
				: null,
		async key => {
			return await fetcher(key, token, false)
		}
	)
	let data = res?.[0]?.result?.result?.result || []
	const count = res?.[0]?.result?.result?.count || 0

	return (
		<MNotificationSection>
			<MNotificationHeader tab={tab} setTab={setTab} count={count} />
			<MNotificationLists data={data} />
		</MNotificationSection>
	)
}

export default MNotifications
