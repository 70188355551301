import React from 'react'
import { HighestProfitSection } from './styles'
import HighestProfitHeader from './HighestProfitHeader'
import HighestProfitLists from './HighestProfitLists'

const HighestProfit = () => {
	return (
		<HighestProfitSection>
			<HighestProfitHeader />
			<HighestProfitLists />
		</HighestProfitSection>
	)
}

export default HighestProfit
