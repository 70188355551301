import React from 'react'
import { Sparklines, SparklinesCurve } from 'react-sparklines'
import { MarketTableListContainer } from '../styles'

const SparkLine = ({ data }) => {
	return (
		<MarketTableListContainer>
			<Sparklines data={[5, 10, 5, 20]}>
				<SparklinesCurve />
			</Sparklines>
		</MarketTableListContainer>
	)
}

export default SparkLine
