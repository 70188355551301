import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { TraderFollowerInfoSection } from './styles'
import TraderFolowerInfoListItems from './TraderFolowerInfoListItems'
const TraderFollowerInfo = () => {
	const { t } = useTranslation()
	return (
		<TraderFollowerInfoSection>
			<View
				style={{
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'flex-start',
					height: '70%',
				}}>
				<TraderFolowerInfoListItems
					title={t('totalEquity')}
					amount={'0.00'}
				/>
				<TraderFolowerInfoListItems equal={true} />
				<TraderFolowerInfoListItems
					title={t('availableBalance')}
					amount={'0.00'}
				/>
				<TraderFolowerInfoListItems equal={true} />
				<TraderFolowerInfoListItems
					title={t('marginBalance')}
					amount={'0.00'}
				/>
				<TraderFolowerInfoListItems equal={true} />
				<TraderFolowerInfoListItems
					title={t('unrealizedP&L')}
					amount={'0.00'}
				/>
			</View>
			<View
				style={{
					width: '100%',
					height: '30%',
					alignItems: 'center',
					justifyContent: 'flex-start',
					flexDirection: 'row',
				}}>
				<View style={{ flexDirection: 'row', marginRight: 30 }}>
					<Text size={16} style={{ color: COLORS.lightGray5 }}>
						{t('realizedProfitShare')}
					</Text>
					<Text
						size={16}
						style={{ marginLeft: 10, color: COLORS.black }}>
						0.00
					</Text>
				</View>
				<View style={{ flexDirection: 'row' }}>
					<Text size={16} style={{ color: COLORS.lightGray5 }}>
						{t('realizedProfitShare')}
					</Text>
					<Text
						size={16}
						style={{ marginLeft: 10, color: COLORS.black }}>
						0.00
					</Text>
				</View>
			</View>
		</TraderFollowerInfoSection>
	)
}

export default TraderFollowerInfo
