import React, { useContext } from 'react'
import { CoinLogo } from 'app/components'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ReservesDetailItems = ({ item }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ width: '100%', flexDirection: 'row', marginBottom: 30 }}>
			<View
				style={{
					width: '16%',
					flexDirection: 'row',
					alignItems: 'flex-start',
				}}>
				<CoinLogo size={30} src={item.image} />
				<View style={{ marginLeft: 10, alignItems: 'flex-start' }}>
					<Text size={16} style={{ color: textw }}>
						{item?.main_symbol}
					</Text>
					<Text size={14} style={{ color: textw }}>
						{item?.symbol_name}
					</Text>
				</View>
			</View>
			<View style={{ width: '28%' }}>
				<Text size={14} style={{ color: textw }}>
					{item.platform_assets} USDT
				</Text>
			</View>
			<View style={{ width: '28%' }}>
				<Text size={14} style={{ color: textw }}>
					{item.user_assets} BTC
				</Text>
			</View>
			<View style={{ width: '28%', flexDirection: 'row' }}>
				<Text size={14} style={{ color: textw, marginRight: 10 }}>
					{item.reserve_ratio}%
				</Text>
				<CButton
					title={'Sufficiency'}
					style={{
						height: 30,
						background: COLORS.secondary,
						color: COLORS.white,
						borderRadius: 3,
						fontSize: 12,
					}}
				/>
			</View>
		</View>
	)
}

export default ReservesDetailItems
