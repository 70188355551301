import styled from 'styled-components'

export const TraderDetailInfoSection = styled.div`
	width: '100%';
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-self: flex-start;
	margin-top: 15px;
	@media only screen and (max-width: 1199px) {
		flex-direction: column;
	}
	@media only screen and (min-width: 1200px) {
		flex-direction: row;
	}
`
