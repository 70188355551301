import { Switch } from 'antd'
import { COLORS } from 'app/constants'
import React from 'react'

const CSwitch = ({ checked, setChecked, action, style = {} }: any) => {
	return (
		<Switch
			checked={checked}
			onChange={() => {
				if (setChecked) {
					setChecked(!checked)
				}
				if (action) {
					action(checked)
				}
			}}
		/>
	)
}

export default React.memo(CSwitch)
