/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const TradingListItemSection = styled.div`
	height: 520px;
	width: 100%;
	margin: 5px;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 6px 0 rgba(50, 50, 50, 0.1);
`
export const TradingListHeaderSection = styled.div`
	padding-block: 10px;
	padding-left: 15px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	display: flex;
	flex-direction: row;
`
export const TradingListsItemsSection = styled.div`
	height: 100%;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
`
export const ListItemSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	padding-inline: 20px;
	cursor: pointer;
`
export const ListItemContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
