import React from 'react'
import { IconChartPie, IconWallet } from '@tabler/icons'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
const TraderInfoFooter = () => {
	return (
		<View style={{ flexDirection: 'row', marginTop: 20 }}>
			<View style={{ flexDirection: 'row' }}>
				<IconWallet color={COLORS.primary} size={16} />
				<Text size={14} style={{ marginLeft: 5 }}>
					Total Assets 545.32 USDT
				</Text>
			</View>
			<View
				style={{
					width: 1,
					height: 10,
					backgroundColor: COLORS.borderColor,
					marginInline: 20,
				}}
			/>
			<View style={{ flexDirection: 'row' }}>
				<IconChartPie color={COLORS.primary} size={16} />
				<Text size={14} style={{ marginLeft: 5 }}>
					Profit Sharing Ratio 10%
				</Text>
			</View>
		</View>
	)
}

export default React.memo(TraderInfoFooter)
