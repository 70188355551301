import useSWR from 'swr'
import API from '../../../utils/api'
import { INFO24H, TRADEAPI_URL } from '../../constants/TRADEAPIKEYS'
import { useToken } from '../auth/token'
// import { getPersistData } from '../persist'

// let KEY: any = INFO24H

let swrData: any = []
let lastUpdate: any = 0

export function useInfo24H(ep_id: number) {
	const { data: token } = useToken()
	let KEY: any = INFO24H + '?ep_id=0'

	if (!ep_id) KEY = null

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if(!ep_id) return swrData
			if (!swrData[ep_id] && swrData[ep_id] != 0 && swrData[ep_id] != undefined) return swrData

			// 10초 이내에 요청이 들어온 경우 마지막 데이터를 반환
			if (Date.now() - lastUpdate < 10000) return swrData

			const response = await API.get(
				KEY,
				token + '',
				{},
				false,
				TRADEAPI_URL
			)
			for (const key in response.result) {
				const temp = response.result?.[key]
				if (temp?.ep_id) {
					swrData[temp?.ep_id] = temp
				}
			}
			lastUpdate = Date.now()

			return swrData
		},
		{
			revalidateOnFocus: true,
			refreshInterval: 60000,
			revalidateOnReconnect: true,
		}
	)


	return {
		data: data?.[ep_id],
		mutate: (value?: any, epid?: number) => {
			try {
				if (value !== undefined) swrData[epid ? epid : ep_id] = value

				return mutate()
			} catch (e) {
				return mutate()
			}
		},
	}
}
