import styled from 'styled-components'

export const MarketTableListContainer = styled.div`
	background-color: ${props => props.theme.COLORS.drawerBgColor} !important;
`
export const MarketTableListSection = styled.div`
	width: 100%;
	background-color: ${props => props.theme.COLORS.drawerBgColor} !important;
`

export const TableContainer = styled.div`
	.ag-cell-focus,
	.ag-cell-no-focus {
		border: none !important;
	}
	/* This CSS is to not apply the border for the column having 'no-border' class */
	.no-border.ag-cell:focus {
		border: none !important;
		outline: none;
	}
	.ag-root-wrapper {
		border: none !important;
		padding: 10px;
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
	}
	.ag-header-cell {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
		border: none !important;
	}
	.ag-header-cell-text {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-row-position-absolute {
		cursor: pointer;
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-row-summary-panel {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-page-summary-panel {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-paging-button {
		color: ${props => props.theme.COLORS.textw} !important;
	}
	.ag-row-even {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
	}
	.ag-row-odd {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
	}
	.ag-root-wrapper {
		padding-inline: 20px;
	}
	.ag-row-even,
	.ag-row-odd {
		border: 0;
	}
	.ag-header {
		border: none !important;
	}
	.ag-center-cols-viewport {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
		color: ${p => p.theme.COLORS.textw};
	}
	.ag-overlay-loading-center {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
	}
	.ag-custom-loading-cell {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
	}
	.ag-root-wrapper {
		background-color: ${props =>
			props.theme.COLORS.drawerBgColor} !important;
	}
`
