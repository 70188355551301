import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'

const ConvertQuoteFooter = ({ setShowModal }) => {
	const { t } = useTranslation()
	return (
		<View style={{ width: '97%', marginTop: 20 }}>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<CButton
					title={t('convert')}
					onClick={() => {
						setShowModal(false)
					}}
					style={{
						background: COLORS.primary,
						flex: 1,
						paddingBlock: 10,
						color: COLORS.white,
					}}
				/>
				<View style={{ width: 20 }} />
				<CButton
					title={t('cancel')}
					onClick={() => {
						setShowModal(false)
					}}
					style={{
						background: COLORS.gray2,
						flex: 1,
						paddingBlock: 10,
						color: COLORS.white,
						marginRight: 10,
					}}
				/>
			</View>
			{/* <CButton
				title={t('toLoan')}
				icon={<IconChevronRight size={14} />}
				style={{ marginTop: 15, color: COLORS.primary }}
			/> */}
		</View>
	)
}

export default ConvertQuoteFooter
