import { COLORS } from 'app/constants'
import React, { useEffect, useState } from 'react'
import { View } from 'styles/reactnative'
import TpslCheckBox from './TpslCheckBox'
import TpslDetail from './TpslDetail'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'

const DerivativesTpSl = () => {
	const { data: tradeStore, mutate } = useTradeStore()

	const [buyTpsl, setBuyTpsl] = useState<boolean>(false)
	const [display, setDisplay] = useState<boolean>(false)
	const [tpslPercentage, setTpslPercentage] = useState('')
	const [stopLossPercentage, setStopLossPercentage] = useState('')
	const [tpslValue, setTpslValue] = useState('')
	const [stopLossValue, setStopLossValue] = useState('')
	const [tradeTpslType, setTradeTpslType] = useState('USDT')
	const [tradeLossType, setTradeLossType] = useState('USDT')

	useEffect(() => {
		mutate({
			...tradeStore,
			tpsl: buyTpsl,
			tp: tpslValue,
			sl: stopLossValue,
			tptype: tradeTpslType,
			sltype: tradeLossType,
		})
	}, [tpslValue, stopLossValue, tradeTpslType, tradeLossType])

	useEffect(() => {
		if (buyTpsl === true) {
			setDisplay(true)
		} else {
			if (buyTpsl === false) {
				setDisplay(false)
			}
		}
	}, [buyTpsl])

	return (
		<View
			style={{
				width: '100%',
				marginBottom: 15,
			}}>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
				}}>
				<TpslCheckBox
					title={'buyLongWithTpsl'}
					checked={buyTpsl}
					setChecked={setBuyTpsl}
					color={COLORS.lightGreen}
				/>
			</View>
			{display ? (
				<TpslDetail
					tpslPercentage={tpslPercentage}
					setTpslPercentage={setTpslPercentage}
					tpslValue={tpslValue}
					setTpslValue={setTpslValue}
					stopLossValue={stopLossValue}
					setStopLossValue={setStopLossValue}
					stopLossPercentage={stopLossPercentage}
					setStopLossPercentage={setStopLossPercentage}
					tradeTpslType={tradeTpslType}
					setTradeTpslType={setTradeTpslType}
					tradeLossType={tradeLossType}
					setTradeLossType={setTradeLossType}
				/>
			) : null}
		</View>
	)
}

export default DerivativesTpSl
