import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconX } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
interface IProps {
	closeModal?: () => void
}
const CrossModalHeader = ({ closeModal }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}>
			<Text size={16}>{t('marginMode')}</Text>
		</View>
	)
}

export default CrossModalHeader
