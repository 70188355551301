import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { CButton } from 'app/components/Common'
import TraderDetailDropdowns from '../../TraderDetailDropdowns'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
const CurrentTradesHeader = ({
	coinLists,
	selectCoin,
	setSelectCoin,
	tab,
	setTab,
}) => {
	const { bgColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const tabs = ['byOrder', 'byContract']
	return (
		<View style={{ width: '100%' }}>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<Text
					size={14}
					style={{
						fontWeight: '600',
						marginBottom: 7,
					}}>
					{t('currentTrades')}
				</Text>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: 10,
					}}>
					<TraderDetailDropdowns
						data={coinLists}
						selected={selectCoin}
						setSelected={setSelectCoin}
					/>
					<View style={{ flexDirection: 'row', marginLeft: 20 }}>
						{tabs.map((item, index) => (
							<CButton
								key={index.toString()}
								title={t(item)}
								onClick={() => {
									setTab(item)
								}}
								style={{
									borderWidth: 1,
									background:
										tab === item
											? COLORS.secondary
											: bgColor,
									borderColor:
										tab === item
											? COLORS.secondary
											: bgColor,
									color:
										tab === item
											? COLORS.white
											: COLORS.lightGray,
									borderRadius: 3,
									paddingBlock: 4,
									paddingInline: 20,
									marginLeft: -2,
									zIndex: tab === item ? 1 : 0,
								}}
							/>
						))}
					</View>
				</View>
			</View>
		</View>
	)
}

export default React.memo(CurrentTradesHeader)
