import React from 'react'
import { Text, View } from 'styles/reactnative'
import { toShortNumber } from 'utils/ciutils'

const ListVolume = ({ volume }) => {
	return (
		<View
			style={{
				width: '20%',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'row',
			}}>
			<Text
				style={{
					width: '40%',
					fontSize: 12,
				}}>
				{toShortNumber(volume)}
			</Text>
		</View>
	)
}

export default ListVolume
