import React from 'react'
import { IconFlame } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'

const TraderDetailRightSIdeBtn = () => {
	return (
		<View
			style={{
				flexDirection: 'row',
				background: 'linear-gradient(90.1deg,red .08%,#f90 99.91%)',
				padding: 10,
				width: '100%',
				marginTop: 15,
				borderRadius: 4,
				cursor: 'pointer',
			}}>
			<IconFlame color={COLORS.primary} size={20} />
			<View
				style={{
					width: 1,
					height: 15,
					backgroundColor: COLORS.lightGray,
					marginInline: 20,
					alignSelf: 'flex-end',
				}}
			/>
			<Text size={16} style={{ color: COLORS.white }}>
				회원: 1명
			</Text>
		</View>
	)
}

export default React.memo(TraderDetailRightSIdeBtn)
