import React from 'react'
import { AssetUsdcSectionWrapper } from '../styles'
import AssetSpotHeaderBottom from './AssetSpotHeaderBottom'
import AssetSpotHeaderTop from './AssetSpotHeaderTop'

const AssetUsdcSection = () => {
	return (
		<AssetUsdcSectionWrapper>
			<AssetSpotHeaderTop />
			<AssetSpotHeaderBottom />
		</AssetUsdcSectionWrapper>
	)
}

export default AssetUsdcSection
