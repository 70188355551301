import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import useTradeStore from '../../../../../../hooks/trade/tradeStore'
import { useTradeSetting } from '../../../../../../hooks/trade/tradeSetting'
import API from '../../../../../../../utils/api'
import { useToken } from '../../../../../../hooks'
import { useOrderBook } from '../../../../../../hooks/trade/orderBook'
import { toast } from 'react-toastify'
import { useFetcher } from '../../../../../../hooks/fetcher'
import { useBalance } from '../../../../../../hooks/user/mybalance'
import { useTradePosition } from '../../../../../../hooks/trade/tradePosition'
import { Col, Modal, Row, message } from 'antd'
import BigNumber from 'bignumber.js'
import { useTradeModalStore } from '../../../../../../hooks/trade/modalStore'
import CheckPositionModal from '../../../../../../components/CheckPositionModal'
import { useMyProfile } from '../../../../../../hooks/user/myprofile'
import { ThemeContext } from '../../../../../../../theme/ThemeProvider'
import { useMitOrder } from '../../../../../../hooks/trade/mitorder'

const DerivativesFormBtns = () => {
	const { t } = useTranslation()
	const { data: tradeStore } = useTradeStore()
	const [long_short, setLongShort] = React.useState('L')

	const [open, setOpen] = React.useState(false)

	const { textw } = useContext(ThemeContext).theme.COLORS

	const { data: mitdata, mutate: mutateMit } = useMitOrder()
	const { fee } = useMyProfile()

	const { data: tradeModalStore, mutate: mutateModalStore } =
		useTradeModalStore()
	const { data: tradeSetting } = useTradeSetting(tradeStore?.ep_id)
	const { data: token } = useToken()
	const { mutate: mutateOrderBook } = useOrderBook(tradeStore?.ep_id)

	const { mutate: mutatePosition } = useTradePosition()
	const currency = tradeStore?.pairs?.split('/')[1]
	const coin = tradeStore?.pairs?.split('/')[0]

	const [checkmit, setCheckmit] = React.useState('')

	const mitorder = useMemo(() => {
		return (longshort, price) => {
			if (!tradeStore?.order_amount) {
				message.error('수량을 입력해주세요.')
				return
			}

			if (!tradeStore?.trigger_price) {
				message.error('트리거 가격을 입력해주세요.')
				return
			}

			let tempdata: any = []
			let check: any = false

			// if (mitdata?.['MIT' + tradeStore?.ep_id]) {
			// 	tempdata = [...mitdata?.['MIT' + tradeStore?.ep_id]]

			// 	check = mitdata?.['MIT' + tradeStore?.ep_id]?.find(
			// 		i =>
			// 			i?.price === price &&
			// 			i?.longshort === longshort &&
			// 			i?.limit_market == tradeStore?.limit_market
			// 	)
			// }

			let amount = new BigNumber(tradeStore?.order_amount)
				.dividedBy(tradeSetting?.leverage)
				.toNumber()

			if (check) {
				amount = BigNumber(amount)
					.plus(check?.amount || 0)
					.toNumber()
				tempdata[tempdata.indexOf(check)].amount = amount
			} else {
				tempdata.push({
					type: 'MIT',
					amount: amount,
					trigger_price: tradeStore?.trigger_price,
					trigger_type: tradeStore?.trigger_type,
					limit_market: tradeStore?.limit_market,
					longshort: longshort,
					main_sub: tradeStore?.main_sub || 'M',
					ep_id: tradeStore?.ep_id,
					cross_iso: tradeSetting?.cross_iso,
					leverage: tradeSetting?.leverage,
					post_only: tradeStore?.post_only ? 1 : 0,
					reduce_position: tradeStore?.reduce_only ? 1 : 0,
					tpsl: tradeStore?.tpsl,
					tp: tradeStore?.tp,
					tptype: tradeStore?.tptype,
					sl: tradeStore?.sl,
					sltype: tradeStore?.sltype,
					price: price,
					created_at: new Date(),
				})
			}

			mutateMit(tempdata, tradeStore?.ep_id)

			message.success('조건부 주문이 등록되었습니다.')
		}
	}, [
		tradeStore?.ep_id,
		mitdata,
		mutateMit,
		tradeStore?.order_amount,
		tradeStore?.leverage,
	])

	const sendOrder = async long_short => {
		if (tradeStore?.tpsl) {
			if (isNaN(tradeStore?.tp) || isNaN(tradeStore?.sl)) {
				return message.error('CHECK TP/SL')
			}
		}

		const orderParams = {
			ep_id: tradeStore?.ep_id,
			main_sub: tradeStore?.main_sub || 'M',
			long_short: long_short,
			cross_iso: tradeSetting?.cross_iso,
			leverage: tradeSetting?.leverage,
			reduce_position: tradeStore.reduce_only ? 1 : 0,
			post_only: tradeStore?.post_only ? 1 : 0,
			limit_market:
				tradeStore?.limit_market?.toUpperCase() == 'L' ||
				tradeStore?.limit_market?.toUpperCase() == 'LIMIT'
					? 'L'
					: 'M',
			price: tradeStore?.order_price,
			amount: new BigNumber(tradeStore?.order_amount)
				.dividedBy(tradeSetting?.leverage)
				.toNumber(),
			tpsl: tradeStore?.tpsl,
			tp: tradeStore?.tp,
			sl: tradeStore?.sl,
			tptype: tradeStore?.tptype,
			sltype: tradeStore?.sltype,
		}

		const result = await API.post('/trade/order', '' + token, orderParams)

		//mutateStore({ ...tradeStore, order_amount: 0 })

		if (result.result.success) {
			mutateOrderBook()
			mutatePosition()

			setTimeout(() => {
				mutateOrderBook()
				mutatePosition(null)
			}, 500)

			toast(t('ordersuccess') + '', {
				type: 'success',
				delay: 100,
			})
		} else {
			if (result?.result?.message == 'ERROR_MAXAMOUNT') {
				toast(
					t('ERROR_MAXAMOUNT') +
						' / MAX AMOUNT : ' +
						BigNumber(result?.result?.max).toFormat(3),
					{
						type: 'error',
					}
				)
			} else {
				toast(t(result?.result?.message + '') + '', {
					type: 'error',
				})
			}
		}
	}
	return (
		<>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<Modal
					open={checkmit == 'L' || checkmit == 'S'}
					title={t('ordercheck') + ''}
					onCancel={() => {
						setCheckmit('')
					}}
					onOk={() => {
						mitorder(checkmit, tradeStore?.order_price)
						setCheckmit('')
					}}
					cancelText={t('cancel')}
					okText={t('confirm')}>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>{t('pairs')}</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{tradeStore?.pairs}
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>가격</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{tradeStore?.limit_market == 'CM'
								? t('market')
								: tradeStore?.order_price}
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>조건</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{t('market')}
							{tradeStore?.trigger_type == 'BIG'
								? ' >='
								: ' <='}{' '}
							{tradeStore?.trigger_price}
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>수량</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{tradeStore?.order_amount}
						</Col>
					</Row>
				</Modal>

				<CButton
					title={t('buyLong')}
					onClick={() => {
						if (
							tradeStore?.limit_market == 'CM' ||
							tradeStore?.limit_market == 'CL'
						) {
							if (!tradeModalStore?.skip) {
								if (!tradeStore?.order_amount) {
									message.error('수량을 입력해주세요.')
									return
								}
								setCheckmit('L')
							} else {
								mitorder(
									'L',
									tradeStore?.order_price ||
										tradeStore?.trigger_price
								)
							}
						} else {
							if (tradeModalStore?.skip) {
								if (!tradeStore?.order_amount) {
									message.error('수량을 입력해주세요.')
									return
								}
								sendOrder('L')
							} else {
								setLongShort('L')
								setOpen(true)
							}
						}
					}}
					style={{
						flex: 1,
						background: COLORS.lightGreen,
						color: COLORS.white,
						marginRight: 10,
						padding: 8,
						borderRadius: 3,
					}}
				/>
				<CButton
					title={t('sellShort')}
					onClick={() => {
						if (
							tradeStore?.limit_market == 'CM' ||
							tradeStore?.limit_market == 'CL'
						) {
							if (!tradeModalStore?.skip) {
								if (!tradeStore?.order_amount) {
									message.error('수량을 입력해주세요.')
									return
								}
								setCheckmit('S')
							} else {
								mitorder(
									'S',
									tradeStore?.order_price ||
										tradeStore?.trigger_price
								)
							}
						} else {
							if (tradeModalStore?.skip) {
								if (!tradeStore?.order_amount) {
									message.error('수량을 입력해주세요.')
									return
								}
								sendOrder('S')
							} else {
								setLongShort('S')
								setOpen(true)
							}
						}
					}}
					style={{
						flex: 1,
						background: COLORS.lightRed,
						color: COLORS.white,
						padding: 8,
						borderRadius: 3,
					}}
				/>

				<CheckPositionModal
					open={open}
					setOpen={setOpen}
					long_short={long_short}
				/>
			</View>

			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
					marginTop: 8,
					padding: 5,
				}}>
				<Text style={{ fontSize: 12, color: COLORS.lightGray }}>
					{t('feeRate')}
				</Text>
				<Text
					style={{
						fontSize: 12,
						color: COLORS.white,
						background: COLORS.primary,
						padding: 5,
						whiteSpace: 'nowrap',
						letterSpacing: -0.25,
					}}>
					Maker {fee?.maker_fee}% / Taker {fee?.taker_fee}%
				</Text>
			</View>

			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
					marginTop: 3,
					padding: 5,
				}}>
				<Text style={{ fontSize: 12, color: COLORS.lightGray }}>
					{t('minmargin')}
				</Text>
				<Text style={{ fontSize: 12, color: textw }}>
					{tradeStore?.minimum_price || 0} {coin}
				</Text>
			</View>
		</>
	)
}

export default DerivativesFormBtns
