import React from 'react'
import { t } from 'i18next'
import { CText, CButton } from 'app/components/Common/'
import {
	RibbonRate,
	ListImageBox,
	ListItemContent,
	ListItemSection,
	MarketListItemSection,
} from './styles'
import { COLORS, FONTS } from 'app/constants/STYLES'
import { CoinLogo } from 'app/components'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useTimezone } from '../../../../hooks/timezone'

import BigNumber from 'bignumber.js'

const MarketListItem = ({ item, index }) => {
	const navigation = useHistory()

	return (
		<MarketListItemSection>
			<ListItemSection>
				<RibbonRate>
					<CText
						text={useTimezone(item?.opened_at, 'MM.DD')}
						style={{
							...FONTS.h6,
							color: COLORS.primary,
						}}
					/>
				</RibbonRate>
				<ListImageBox>
					<CoinLogo
						src={item?.image}
						size={25}
						style={{
							alignSelf: 'center',
							display: 'flex',
							alignItems: 'center',
						}}
					/>
				</ListImageBox>
				<ListItemContent>
					<CText
						text={item?.pairs}
						style={{
							...FONTS.h4,
							fontWeight: 'bold',
							marginBottom: 20,
						}}
					/>
					<CText
						text={BigNumber(item?.price).toFormat(2)}
						style={{
							...FONTS.h5,
							fontWeight: '300',
							marginBottom: 8,
						}}
					/>
					<CText
						text={
							item.rate > 0
								? '+' +
								  BigNumber(item.rate)
										.multipliedBy(100)
										.toFixed(2) +
								  '%'
								: BigNumber(item.rate)
										.multipliedBy(100)
										.toFixed(2) + '%'
						}
						style={{
							...FONTS.h3,
							fontWeight: '600',
							color:
								item.rate > 0
									? COLORS.lightGreen
									: COLORS.lightRed,
							marginBottom: 10,
						}}
					/>
				</ListItemContent>
				<CButton
					title={t('tradeNow')}
					onClick={() => {
						goTo(navigation, `/trade/${item.id}`)
					}}
					style={{
						justifyContent: 'center',
						width: '100%',
						padding: 10,
						backgroundColor: COLORS.primary,
						marginTop: 10,
						...FONTS.h5,
						color: COLORS.lightGray2,
						fontWeight: '500',
					}}
				/>
			</ListItemSection>
		</MarketListItemSection>
	)
}

export default React.memo(MarketListItem)
