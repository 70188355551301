import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const RiskWarningLeft = ({ title }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '30%',
				alignItems: 'flex-start',
			}}>
			<Text
				size={20}
				style={{
					color: textw,
					lineHeight: 1.7,
					fontWeight: '500',
				}}>
				{title}
			</Text>
		</View>
	)
}

export default RiskWarningLeft
