import React, { useContext, useState } from 'react'
import {
	IconAnalyze,
	IconCoinBitcoin,
	IconGift,
	IconListDetails,
	IconWallet,
} from '@tabler/icons'

import { useTranslation } from 'react-i18next'
import { MText } from 'styles/mobilePageStyles'
import { CategoryBtn, MCatergorySection } from './styles'
import { CBottomSheet } from 'app/mobile/components'
import CryptoContainer from './CryptoContainer'
import { ThemeContext } from 'theme/ThemeProvider'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { useToken } from '../../../../hooks'
import { Alert, Spin } from 'antd'
import { GLOBAL_URL } from '../../../../constants/APIKEYS'

const MCatergory = () => {
	const navigation = useHistory()
	const [open, setOpen] = useState(false)
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	const { data: token } = useToken()
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()
	const data: any = [
		{
			title: 'buyCrypto',
			icon: <IconCoinBitcoin size={20} color={textw} />,
			action: () => setOpen(!open),
		},
		{
			title: 'deposit',
			icon: <IconWallet size={20} color={textw} />,
			action: () =>
				muatateModalStore({ ...modalStore, depositModal: true }),
		},
		{
			title: 'referral',
			icon: <IconGift size={20} color={textw} />,
			goTo: '/referral',
		},
		{
			title: 'notice',
			icon: <IconListDetails size={20} color={textw} />,
			goTo: '/abouts?tab=2',
		},
	]

	data?.push({
		title: t('global'),
		icon: (
			<>
				{loading && (
					<Spin
						tip="접속중..."
						style={{ position: 'absolute' }}></Spin>
				)}
				<IconAnalyze size={20} color={textw} />
			</>
		),
		action: () => {
			setLoading(true)
			window.location.href =
				GLOBAL_URL +
				'/login?' +
				(token ? 'ssotoken=' + token : 'skip=true') +
				'&darkmode=' +
				(isDarkMode ? 'dark' : 'light')

			return true
		},
	})

	return (
		<MCatergorySection>
			{data?.map((item, index) => (
				<CategoryBtn
					key={index.toString()}
					className={'customBtn'}
					type="button"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						alignSelf: 'center',
						justifyContent: 'center',
						borderWidth: 0,
					}}
					onClick={() => {
						if (item?.action) {
							item?.action()
						}
						if (item?.goTo) {
							goTo(navigation, item?.goTo)
						}
					}}>
					{item?.icon}
					<MText style={{ marginTop: 5 }}>{t(item?.title)}</MText>
				</CategoryBtn>
			))}
			<CBottomSheet
				open={open}
				maxHeight={250}
				setOpen={setOpen}
				BottomContainer={CryptoContainer}
			/>
		</MCatergorySection>
	)
}

export default MCatergory
