import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import NotiSwitch from '../../NotiSwitch'

const EmailBox = () => {
	const { t } = useTranslation()
	const [promotion, setPromotion] = useState<boolean>(true)
	const [trading, setTrading] = useState<boolean>(true)
	const [crypto, setCrypto] = useState<boolean>(true)

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				padding: 10,
				marginTop: 20,
				paddingBottom: 30,
			}}>
			<Text size={18} style={{ fontWeight: '500' }}>
				{t('emailSubscription')}
			</Text>
			<NotiSwitch
				title={t('promotionOurLatestEvents')}
				checked={promotion}
				setChecked={setPromotion}
			/>
			<NotiSwitch
				title={t('exChangeLearnTheFundamentals')}
				checked={trading}
				setChecked={setTrading}
			/>

			<NotiSwitch
				title={t('exChangeInsightsOurLatestCrypto')}
				checked={crypto}
				setChecked={setCrypto}
			/>
		</View>
	)
}

export default React.memo(EmailBox)
