import React from 'react'
import CImage from 'app/components/Common/CImage'
import { CSSProperties } from 'styled-components'

interface IProps {
	src?: any
	size?: number
	style?: CSSProperties
	onClick?: () => void
}
const CoinLogo = ({ src, size, style = {}, onClick }: IProps) => {
	return (
		<CImage
			onClick={onClick}
			src={src || '/images/square-user-check.png'}
			alt="logo"
			width={size ? size : 20}
			height={size ? size : 20}
			resizeMode={'cover'}
			style={style}
		/>
	)
}

export default React.memo(CoinLogo)
