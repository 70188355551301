import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const ReferralCodeSection = styled.div`
	width: 100%;
	height: 115px;
	border-radius: 10px;
	background-color: ${COLORS.white};
	margin-top: 130px;
	display: flex;
	@media only screen and (max-width: 968px) {
		flex-direction: column;
		padding-inline: 10px;
		padding-top: 10px;
		height: 135px;
	}
`
export const ReferralCodeDetailSection = styled.div`
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-inline: 20px;
	@media only screen and (max-width: 968px) {
		padding-inline: 0;
	}
`
export const ReferralCodeContainer = styled.div`
	width: 70%;
	display: flex;
	@media only screen and (max-width: 968px) {
		width: 100%;
	}
`
export const ReferralCodeLinkSection = styled.div`
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-inline: 20px;
`
export const InviteFriendSection = styled.div`
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: flex-start;
	@media only screen and (max-width: 968px) {
		width: 100%;
	}
`
