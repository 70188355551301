import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

// eslint-disable-next-line prettier/prettier
export const DropdownContent = styled.div`
	display: none;
	position: absolute;
	margin-right: 210px;
	width: 300px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	z-index: 999 !important;
	padding: 0 !important;
`

export const HeaderButtonSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	min-width: 90px;
	height: 32px;
	align-self: center;
	&:hover {
		color: ${COLORS.white};
		background-color: ${COLORS.primary};
	}
	color: ${props => props.theme.COLORS.textw};
	&:hover ${DropdownContent} {
		display: block;
	}
`
export const HeaderProfieSection = styled.div`
	padding-inline: 20px;
	padding-block: 10px;
	background-color: ${p => p.theme.COLORS.header};
`
export const HeaderProfieUid = styled.div`
	margin-left: 4px;
	padding-block: 10px;
`
export const HeaderButtonText = styled.div`
	margin-left: 1rem;
	padding-right: 0.3rem;
	font-size: 13px;
	&:hover {
		color: ${COLORS.white};
	}
`
export const AuthHeaderMobile = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	@media (max-width: 900px) {
		display: none;
	}
`
