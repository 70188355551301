import React from 'react'
import { CImage } from 'app/components/Common'
import { COLORS, IMAGES, SIZES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

const ProfileUserDetail = () => {
	const { t } = useTranslation()
	return (
		<View style={{ flexDirection: 'row' }}>
			<CImage
				src={IMAGES.eventImage}
				alt="logo"
				width={50}
				height={50}
				resizeMode={'cover'}
				style={{ borderRadius: 50, marginRight: 10 }}
			/>

			<View style={{ alignItems: 'flex-start' }}>
				<Text
					style={{
						fontSize: SIZES.h5,
						lineHeight: 1.5,
						fontWeight: '600',
					}}>
					Mickey
				</Text>
				<View style={{ flexDirection: 'row' }}>
					<Text
						style={{
							fontSize: SIZES.h7,
						}}>
						924 {t('followerss')}
					</Text>
					<Text
						style={{
							fontSize: SIZES.h7,
							color: COLORS.lightGreen,
						}}>
						1.20%
					</Text>
				</View>
			</View>
		</View>
	)
}

export default React.memo(ProfileUserDetail)
