import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { MButton } from 'app/mobile/components'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const EmailAuthContainer = () => {
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const [errorText, setErrorText] = useState('')
	const [email, setEmail] = useState('')
	const modifyUsername = () => {
		toast(t('notWorkingNow') + '')
		setErrorText('notWorkingNow')
		setTimeout(() => {
			setErrorText('')
		}, 5000)
	}
	return (
		<MView
			style={{
				width: '100%',
				paddingInline: 16,
			}}>
			<MView>
				<MText style={{ color: 'red' }}>{t(errorText)}</MText>
				<MText
					style={{
						fontSize: 14,
						fontWeight: '300',
					}}>
					{t('username')}
				</MText>
			</MView>
			<MView
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<CInput
					placeholder={t('enterUsername')}
					value={email}
					type={'text'}
					onChange={(e: any) => setEmail(e.target.value)}
					vStyle={{
						width: '100%',
						marginTop: 7,
					}}
					style={{
						background: gray,
						border: 0,
						color: textw,
						padding: 8,
						paddingLeft: 15,
						width: '100%',
					}}
				/>
				<MButton
					title={'save'}
					onClick={modifyUsername}
					style={{
						paddingBlock: 8,
						background: COLORS.primary,
						marginTop: -3,
						marginLeft: 15,
						borderRadius: 5,
					}}
					textStyle={{ color: COLORS.white }}
				/>
			</MView>
			<MText style={{ fontSize: 10, color: COLORS.lightGray3 }}>
				{t('changeUsernameGuide')}
			</MText>
		</MView>
	)
}

export default EmailAuthContainer
