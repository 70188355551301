import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import SpecialAuth from './SpecialAuth'
import { useToken, useWindowDimensions } from 'app/hooks'

const SpecialLeftSection = () => {
	const { data: token } = useToken()

	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	const { textw, content } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)

	const tShadow = isDarkMode ? { textShadow: '1px 1px 5px #ddd' } : {}
	return (
		<View
			style={{
				width: width < 1200 ? '100%' : '60%',
				height: '70%',
				alignItems: 'flex-start',
				zIndex: width < 1400 ? 999 : 0,
			}}>
			<View
				style={{
					height: '50%',
					width: '100%',
					alignItems: 'flex-start',
				}}>
				<Text
					size={26}
					style={{
						color: textw,
						fontWeight: 'bold',
						...tShadow,
					}}>
					{t('specialSectionTitle')}
				</Text>
				<View style={{ alignItems: 'flex-start', paddingTop: 30 }}>
					<Text
						size={20}
						style={{
							color: textw,
							fontWeight: '500',
							lineHeight: 3,
							...tShadow,
						}}>
						{t('specialSectionContent')}
					</Text>
					<Text
						size={18}
						style={{
							color: content,
							fontWeight: '400',
							...tShadow,
						}}>
						{t('avoidTrading')}
					</Text>
				</View>
			</View>
			{!token ? <SpecialAuth /> : null}
		</View>
	)
}

export default SpecialLeftSection
