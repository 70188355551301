import React, { useState } from 'react'
import { useWindowDimensions } from 'app/hooks'
import { OrderModalWrapper } from './styles'
import OrderModalHeader from './OrderModalHeader'
import OrderModalFooter from './OrderModalFooter'
import OrderModalBody from './OrderModalBody'
import useTradeStore from 'app/hooks/trade/tradeStore'
import { mutate } from 'swr'

const OrderPreferenceModal = ({ setShowModal }) => {
	// UI
	const { width, height } = useWindowDimensions()
	const wSize =
		width < 772
			? width / 1.5
			: width < 1400
			? width < 900
				? width / 2
				: width / 3
			: width / 4
	// UI
	// API
	const { data: store, mutate: mutateStore } = useTradeStore()
	const usdt = store?.coin_type === 'USDT'
	const [orderByValue, setOrderByValue] = useState(store?.main_sub == 'S')

	const orderQty = store?.main_sub != 'S'
	const setOrderQty = qty => {
		mutateStore({ ...store, order_amount: 0, main_sub: qty ? 'M' : 'S' })
	}

	return (
		<OrderModalWrapper
			style={{
				width: width > 600 ? wSize : '100%',
				height: height > 600 ? 400 : 350,
				borderRadius: width > 600 ? 30 : 0,
			}}>
			<OrderModalHeader closeModal={() => setShowModal(false)} />
			<OrderModalBody
				orderQty={orderQty}
				setOrderQty={setOrderQty}
				orderByValue={orderByValue}
				setOrderByValue={setOrderByValue}
				symbol={store?.symbol}
			/>
			<OrderModalFooter
				closeModal={() => {
					setShowModal(false)
				}}
				action={() => {
					mutateStore({
						...store,
						coin_type: orderQty ? store?.symbol : 'USDT',
					})
				}}
			/>
		</OrderModalWrapper>
	)
}

export default React.memo(OrderPreferenceModal)
