import React from 'react'
import { useFetcher } from '../../../../../hooks/fetcher'
import { RecentDepositTableSection } from '../../styles'
import OverviewRightTableList from './OverviewRightTableList'

const RecentDepositTable = () => {
	const { data: res, refresh } = useFetcher(
		'/cash/list?type=*&page=1&maxData=30',
		true,
		{
			revalidateOnFocus: true,
			revalidateOnMount: true,
			refreshInterval: 2000,
		}
	)
	const data = res?.result?.result?.list

	return (
		<RecentDepositTableSection>
			{data?.map((item: any, index: number) => {
				return <OverviewRightTableList key={index} data={item} />
			})}
		</RecentDepositTableSection>
	)
}

export default React.memo(RecentDepositTable)
