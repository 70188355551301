import { IconStar } from '@tabler/icons'
import { IconStarFilled } from '@tabler/icons-react'
import { CoinLogo } from 'app/components'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useFavourite } from 'app/hooks'
import { goTo } from 'app/hooks/navigation'
import { useSearchHistory } from 'app/hooks/stateStore'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useInfo24H } from 'app/hooks/trade/info24H'
import BigNumber from 'bignumber.js'
import React, { useContext, useDeferredValue } from 'react'
import { useHistory } from 'react-router-dom'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MCoinItemSection } from './styles'

const MCoinItems = ({ item, index, search }) => {
	const navigation = useHistory()
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	const { data: favourites, mutate: mutateFavourite } = useFavourite()
	const favourite = favourites?.find?.((f: any) => f?.id === item?.id)
	const removeFavourite = favourites?.filter?.((f: any) => f?.id !== item?.id)
	let { data: info24H } = useInfo24H(item?.id)
	let { getPrice } = useIndexPrice(item?.id)
	info24H = useDeferredValue(info24H)
	const { addToHistory } = useSearchHistory(search)
	const indexPrice = getPrice(item?.id)

	const rateBackground =
		info24H?.rate > 0 ? COLORS.lightGreen : COLORS.lightRed

	const icon = favourite ? (
		<IconStarFilled size={18} style={{ color: COLORS.primary }} />
	) : (
		<IconStar color={textw} size={18} />
	)

	return (
		<MCoinItemSection>
			<CButton
				icon={icon}
				style={{
					width: '10%',
					justifyContent: 'flex-start',
				}}
				onClick={() => {
					if (!favourite) {
						mutateFavourite([
							...(favourites ? favourites : []),
							item,
						])
					} else {
						mutateFavourite(removeFavourite)
					}
				}}
			/>
			<MView
				onClick={() => {
					addToHistory && addToHistory(item)
					goTo(navigation, `/trade/${item?.id}`)
				}}
				style={{
					width: '90%',
					alignItems: 'center',
					flexDirection: 'row',
				}}>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						width: '50%',
					}}>
					<CoinLogo size={18} src={item?.image} />
					<MText style={{ color: textw, marginInline: 10 }}>
						{item?.symbol}
					</MText>
					<MText style={{ color: textsilver }}>
						{item?.sub_symbol}
					</MText>
				</MView>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						width: '50%',
					}}>
					<MView style={{ width: '10%', height: '100%' }}>
						<MView
							style={{
								height: 17,
								width: 2,
								background: rateBackground,
							}}
						/>
					</MView>
					<MView style={{ width: '50%' }}>
						<MText style={{ color: textw }}>
							{BigNumber(indexPrice).toFormat(2)}
						</MText>
					</MView>
					<MView style={{ width: '40%', alignItems: 'flex-end' }}>
						<MText style={{ color: rateBackground, fontSize: 12 }}>
							{BigNumber(info24H?.rate).toFormat(2) + '%'}
						</MText>
					</MView>
				</MView>
			</MView>
		</MCoinItemSection>
	)
}

export default React.memo(MCoinItems)
