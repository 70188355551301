import React from 'react'
import { CoinRate } from 'app/components'
import TableListItems from './TableListItems'
import { useTranslation } from 'react-i18next'

export const CopiedTradeTableData = () => {
	const { t } = useTranslation()
	return [
		{
			field: t('positions'),
			minWidth: 200,
			unSortIcon: true,
			cellRenderer: ({ data }) => (
				<TableListItems
					item={data.english_name}
					CoinType={() => (
						<CoinRate
							type={'long'}
							times={'10'}
							style={{ marginLeft: 5 }}
						/>
					)}
				/>
			),
			sortable: true,
		},
		{
			field: t('orderQty'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => <TableListItems item={'0.50 ETH'} />,
		},
		{
			field: t('roi'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => <TableListItems item={'-0.79%'} />,
		},
		{
			field: t('entryPrice'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => (
				<TableListItems item={'1,679.25 USDT'} />
			),
		},
		{
			field: t('openedOn'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => (
				<TableListItems item={'2023-02-05 05:59:31'} />
			),
		},
		{
			field: t('closingPrice'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => (
				<TableListItems item={'1,679.93 USDT'} />
			),
		},
		{
			field: t('closedOn'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => (
				<TableListItems item={'2023-02-05 07:44:56'} />
			),
		},
		{
			field: t('currentPositionMargin'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => <TableListItems item={'84.41 USDT'} />,
		},
		{
			field: t('followerss'),
			unSortIcon: true,
			minWidth: 150,
			cellRenderer: ({ data }) => <TableListItems item={'84.41 USDT'} />,
		},
	]
}
