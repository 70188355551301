/**
 * Asynchronously loads the component for SupportsPage
 */

import { lazyLoad } from 'utils/loadable'

export const SupportsPage = lazyLoad(
	() => import('./index'),
	module => module.SupportsPage
)
