import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const FollowerDashboardSection = styled.div`
	width: 100%;
	height: 1000px;
	margin-top: 30px;
	margin-bottom: 80px;
	padding: 32px 24px;
	border-radius: 5px;
	border: 1px solid ${COLORS.greyWhite};
`
export const FollowerDashboardWrapper = styled.div`
	width: 100%;
	height: 100%;
`
