import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import AssetsTitle from '../AssetsTitle'
import { AssetSpotContainer, AssetSpotSection } from './styles'
import AssetSpotTable from './AssetSpotTable'
import AssetSpotHeader from './AssetSpotHeader'

const AssetSpot = ({ title }) => {
	const { height, width } = useWindowDimensions()
	const hSize = width < 1200 ? height / 1 : height / 1.5
	return (
		<AssetSpotSection>
			<AssetsTitle title={title} />
			<AssetSpotContainer style={{ height: hSize }}>
				<AssetSpotHeader />
				<AssetSpotTable />
			</AssetSpotContainer>
		</AssetSpotSection>
	)
}

export default AssetSpot
