import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'

import TraderDetailDropdowns from '../../TraderDetailDropdowns'

const StatisticHistoryHeader = ({ dayLists, selectByDay, setSelectByDay }) => {
	const { t } = useTranslation()
	return (
		<View style={{ width: '100%' }}>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<Text
					size={14}
					style={{
						fontWeight: '600',
						marginBottom: 7,
					}}>
					{t('orderHistory')}
				</Text>

				<TraderDetailDropdowns
					data={dayLists}
					selected={selectByDay}
					setSelected={setSelectByDay}
				/>
			</View>
		</View>
	)
}

export default React.memo(StatisticHistoryHeader)
