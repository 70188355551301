import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const RankingTabsWrapper = styled.div`
	width: 100%;
	height: 1280px;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	border-radius: 20px;
	border-top-left-radius: 0px;
	box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	padding-bottom: 10px;
	z-index: 1;
`

export const WorldRankingSectorSetion = styled.div`
	width: 100%;
	height: 100%;
	background-color: transparent;
	padding: 20px;
`

export const WorldRankingItems = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-top: 1px solid ${COLORS.lightGray2};
	padding-block: 14px;
	&:hover {
		background-color: ${COLORS.lightBlue}22;
	}
`
