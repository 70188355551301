import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const AuditSettingSection = styled.div``

export const AuditSettingContainer = styled.div`
	display: flex;
	border-radius: 5px;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	height: 500px;
	background-color: ${p => p.theme.COLORS.drawerBgColor};
	margin-top: 20px;
	padding: 10px;
	margin-bottom: 50px;
	@media only screen and (max-width: 900px) {
		flex-direction: column-reverse;
		height: 1200px;
	}
`
export const IdentifyVerificationSection = styled.div`
	width: 32%;
	height: 100%;
	border-radius: 15px;
	@media only screen and (max-width: 900px) {
		width: 100%;
		margin-top: 20px;
	}
`

export const ProfileTradingViewAlertSection = styled.div`
	width: 100%;
	height: 600px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-block: 24px;
	padding-inline: 32px;
	border-radius: 15px;
	background-color: ${COLORS.white};
`
export const ProfileTradingAlertHeaderSection = styled.div`
	height: 76px;
	width: 100%;
	padding-block: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`
