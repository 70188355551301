import React, { useState } from 'react'
import { MView } from 'styles/mobilePageStyles'
import EventTabs from './EventTabs'
import HotCoins from './HotCoins'
import HotDerivatives from './HotDerivatives'
import MLeaderBoard from './MLeaderBoard'
import { EventSliderSection } from './styles'

const MEventSlider = () => {
	const [tab, setTab] = useState(0)
	return (
		<EventSliderSection>
			<EventTabs tab={tab} setTab={setTab} />
			<MView style={{ width: '100%', height: 130 }}>
				{tab === 0 ? <HotCoins /> : null}
				{tab === 1 ? <HotDerivatives /> : null}
			</MView>
		</EventSliderSection>
	)
}

export default MEventSlider
