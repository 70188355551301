import axios from 'axios'
import API from 'utils/api'
import useSWR from 'swr'
import { useToken } from './auth/token'

export const useFetcherSetting = (
	url: any,
	logout: boolean = true,
	options: any = {
		revalidateOnFocus: true,
		revalidateOnMount: true,
	}
) => {
	const { data: token } = useToken()

	const { data, mutate, error, isValidating } = useSWR(
		url,
		async url => {
			if (!token && !logout) {
				return []
			}
			const setting = await fetcher(
				'/system/settingsbygroup?pubkey=' + url,
				token,
				logout
			)

			return setting?.result?.result || 'ERROR'
		},
		options
	)
	return {
		data: data,
		isLoading: isValidating,
		isError: error,
		refresh: mutate,
	}
}
export const useFetcher = (
	url: any,
	logout: boolean = true,
	options: any = {
		revalidateOnFocus: true,
		revalidateOnMount: true,
	}
) => {
	const { data: token } = useToken()

	const { data, mutate, error, isValidating } = useSWR(
		token ? url : null,
		async url => {
			if (!token && !logout) {
				return []
			}
			return await fetcher(url, token, logout)
		},
		options
	)
	return {
		data: data,
		isLoading: isValidating,
		isError: error,
		refresh: mutate,
	}
}



export const useFetcherPublic = (
	url: any,
	logout: boolean = false,
	options = {}
) => {
	const { data: token } = useToken()

	const { data, mutate, error, isValidating } = useSWR(
		url,
		async url => {
			return await fetcher(url, token, logout)
		},
		options
	)
	return {
		data: data,
		isLoading: isValidating,
		isError: error,
		refresh: mutate,
	}
}

export const fetcher = async (url: string, token: any, logout: boolean) => {
	if (!url) {
		return {}
	}
	const response = await API.send('GET', url, token, logout)
	return response
}

export const fetcherPost = async (url: string, { arg }: any) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
	}
	console.log(arg)
	return axios.post(url, arg, options).then(res => res.data)
}

export const fetcherPut = async (url: string, { args }: any) => {
	let APIURL = process.env.REACT_APP_API_URL
	url = APIURL + url

	let options = {}
	return axios.put(url, options).then(res => res.data)
}

export const fetcherDelete = async (url: string, { args }: any) => {
	let APIURL = process.env.REACT_APP_API_URL
	url = APIURL + url

	let options = {}
	return axios.put(url, options).then(res => res.data)
}
export const fetcherWithToken = async (
	url: string,
	method?: string,
	token?: string | null
) => {
	let APIURL = process.env.REACT_APP_API_URL
	url = APIURL + url

	let options = {}

	if (token) {
		options = { headers: { Authorization: 'Bearer ' + token } }
	}

	if (method === 'POST') {
		return axios.post(url, options).then(res => res.data)
	} else if (method === 'PUT') {
		return axios.put(url, options).then(res => res.data)
	} else if (method === 'DELETE') {
		return axios.delete(url, options).then(res => res.data)
	} else {
		return axios.get(url, options).then(res => res.data)
	}
}
