import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import ReferralTableMenu from './ReferralTableMenu'
import { ThemeContext } from 'theme/ThemeProvider'

const ReferralTableHeader = ({ title, menuList, tab, setTab }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	return (
		<View
			style={{
				height: 50,
				paddingInline: 40,
				width: '100%',
				flexDirection: 'row',
				justifyContent: 'space-between',
				color: textw,
			}}>
			<Text size={18}>{t(title)}</Text>
			<ReferralTableMenu menuList={menuList} tab={tab} setTab={setTab} />
		</View>
	)
}

export default ReferralTableHeader
