import { CoinLogo } from 'app/components'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const HistoryChartTableList = ({ item, index }) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
				flexDirection: 'row',
				justifyContent: 'space-between',
				paddingBlock: 10,
				marginTop: index === 0 ? 15 : 0,
			}}>
			<View style={{ width: '33.33%', flexDirection: 'row' }}>
				<CoinLogo
					src={`http://static.upbit.com/logos/${item?.market?.slice?.(
						4
					)}.png`}
					size={20}
					style={{
						alignSelf: 'center',
						display: 'flex',
						marginRight: 10,
					}}
				/>
				<Text size={16} style={{ fontWeight: '600' }}>
					{item?.market?.slice?.(4)}USDT
				</Text>
				<View
					style={{
						backgroundColor: textsilver,
						paddingBlock: 2,
						paddingInline: 5,
						borderRadius: 5,
						marginLeft: 10,
					}}>
					<Text size={12}>{index + 2}5.71%</Text>
				</View>
			</View>
			<View style={{ width: '33.33%' }}>
				<Text>5</Text>
			</View>

			<View style={{ width: '33.33%' }}>
				<Text style={{ color: COLORS.lightGreen }}>+36.18</Text>
			</View>
		</View>
	)
}

export default React.memo(HistoryChartTableList)
