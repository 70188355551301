import React from 'react'
import Accordion from './Accordion'
import { AccordionSectionList } from './styles'
import { market, trade, tools } from '../../../../constants/UIData/HEADER'

const accordionItems = [
	{
		title: 'btnMarkets',
		content: market,
	},
	{
		title: 'trade',
		content: trade,
	},
	{
		title: 'tools',
		content: tools,
	},
]

const DrawerAccordion = () => {
	return (
		<AccordionSectionList>
			<Accordion items={accordionItems} />
		</AccordionSectionList>
	)
}

export default React.memo(DrawerAccordion)
