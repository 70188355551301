import React from 'react'
import { useMyProfile } from 'app/hooks/user/myprofile'
import { MView } from 'styles/mobilePageStyles'

import ProfileSettingHeader from './ProfileSettingHeader'
import ProfileSettingNavBar from './ProfileSettingNavBar'

const ProfileSettingInfo = () => {
	const { data: profile } = useMyProfile()

	return (
		<MView
			style={{
				paddingBlock: 10,
				width: '100%',
				paddingInline: 16,
			}}>
			<ProfileSettingHeader profile={profile} />
			<ProfileSettingNavBar />
		</MView>
	)
}

export default ProfileSettingInfo
