import React from 'react'
import FeeTableHeader from './FeeTableHeader'
import FeeTableList from './FeeTableList'
import { FeeTableSectionContainer } from './styles'

const FeeTableSection = () => {
	return (
		<FeeTableSectionContainer>
			<FeeTableHeader />
			<FeeTableList />
		</FeeTableSectionContainer>
	)
}

export default FeeTableSection
