import React, { useContext } from 'react'
import { IconSearch } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { CInput } from 'app/components/Common'
import { TradeSearch } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

const TradeHeaderSearch = ({ search, setSearch }) => {
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<TradeSearch>
			<CInput
				placeholder={t('search')}
				value={search}
				type={'text'}
				onChange={(e: any) => setSearch(e.target.value)}
				vStyle={{ padding: 3 }}
				icon={
					<IconSearch
						size={14}
						style={{ position: 'absolute', left: 30 }}
						color={textw}
					/>
				}
				style={{
					background: 'transparent',
					border: `1px solid ${textsilver}`,
					marginTop: 10,
					color: textw,
					padding: 8,
					paddingLeft: 35,
					width: '90%',
				}}
			/>
		</TradeSearch>
	)
}

export default React.memo(TradeHeaderSearch)
