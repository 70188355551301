import { CFlatList } from 'app/components/Common'
import { useWindowDimensions } from 'app/hooks'
import React from 'react'

import NotiItems from './NotiItems'
import { MNotificationListSection } from './styles'

const MNotificationLists = ({ data }) => {
	const { height } = useWindowDimensions()

	const renderItem = (item: any, index: any) => <NotiItems item={item} />
	return (
		<MNotificationListSection style={{ height: height - 50 }}>
			<CFlatList
				data={data}
				renderItem={renderItem}
				itemSize={130}
				noDataTitle={'notificationNoData'}
			/>
		</MNotificationListSection>
	)
}

export default MNotificationLists
