import React from 'react'
import { View } from 'styles/reactnative'
import HighestProfit from './HighestProfit'
import ViewAllMasterFooter from './ViewAllMasterFooter'
import TraderSlider from './TraderSlider'

const TopTraderMaster = ({ setTabs }: any) => {
	return (
		<View style={{ width: '100%', height: '100%' }}>
			<TraderSlider />
			<HighestProfit />
			<TraderSlider />
			<HighestProfit />
			<ViewAllMasterFooter setTabs={setTabs} />
		</View>
	)
}

export default TopTraderMaster
