import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useSWR from 'swr'
import API from '../../../utils/api'
import { MYTRADESETTINGS } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'

let KEY = MYTRADESETTINGS

let swrData: any = []

export function useTradeSetting(ep_id: string | number) {
	const { t } = useTranslation()

	KEY = `${MYTRADESETTINGS}?ep_id=${ep_id}`
	const { data: token } = useToken()

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (!token) {
				return { leverage: 1, cross_iso: 'C' }
			}

			const response = await API.get(KEY, token + '', {}, false)
			if (!response?.result?.success)
				return { leverage: 1, cross_iso: 'C' }
			swrData[ep_id] = response.result.result
			return swrData[ep_id]
		},
		{ revalidateOnFocus: true }
	)

	return {
		data,
		mutate: async (value: any, ep_id?: any) => {
			if (!token) {
				return mutate()
			}

			//update settings
			const response = await API.post(MYTRADESETTINGS, token + '', {
				...value,
				ep_id,
			})
			if (response?.result?.success) {
				swrData[ep_id] = value
				return mutate()
			} else {
				const message =
					response?.result?.message && t(response?.result?.message)
				toast(message, { type: 'error', autoClose: 700 })
				return () => {}
			}
		},
	}
}
