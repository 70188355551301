import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import AssetsTitle from '../AssetsTitle'
import AssetUsdcSection from './AssetUsdcSection'
import {
	AssetUsdcDerivativeContainer,
	AssetUsdcDerivativeSection,
} from './styles'
import AssetUSDTTable from './AssetDerivativesTable'

const AssetUsdcDerivatives = ({ title }) => {
	const { height } = useWindowDimensions()
	return (
		<AssetUsdcDerivativeSection>
			<AssetsTitle title={title} />
			<AssetUsdcDerivativeContainer style={{ height: height / 1.5 }}>
				<AssetUsdcSection />
				<AssetUSDTTable />
			</AssetUsdcDerivativeContainer>
		</AssetUsdcDerivativeSection>
	)
}

export default AssetUsdcDerivatives
