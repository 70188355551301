import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconCrown } from '@tabler/icons'
import { CImage } from 'app/components/Common'
import { COLORS, IMAGES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { CopyTraderProfileWrapper } from './styles'

const CopyTraderProfile = () => {
	const { t } = useTranslation()
	return (
		<CopyTraderProfileWrapper>
			<CImage
				src={IMAGES.fullStar}
				alt="logo"
				width={120}
				height={120}
				resizeMode={'cover'}
				style={{ borderRadius: 100 }}
			/>
			<View style={{ color: COLORS.greyWhite, marginTop: 20 }}>
				<Text size={16}>Soe Thet Paing Htwe</Text>
				<View style={{ flexDirection: 'row', marginTop: 5 }}>
					<IconCrown size={16} />
					<Text size={12} style={{ marginLeft: 5 }}>
						{t('silver')}
					</Text>
				</View>
			</View>
		</CopyTraderProfileWrapper>
	)
}

export default CopyTraderProfile
