import React from 'react'
import { IconX } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { Text } from 'styles/reactnative'
import { CopyTradeSettingHeaderWrapper } from './styles'
import { COLORS } from 'app/constants'
interface IProps {
	setShowModal?: any
}
const CopyTradeSettingHeader: React.FC<IProps> = ({ setShowModal }) => {
	const { t } = useTranslation()
	return (
		<CopyTradeSettingHeaderWrapper>
			<Text size={18} style={{ color: COLORS.black }}>
				{t('copy')} Soe Thet Paing Htwe
			</Text>
			<CButton
				onClick={() => {
					setShowModal(false)
				}}
				icon={<IconX size={16} color={COLORS.lightGray5} />}
				style={{
					border: `1px solid ${COLORS.lightGray5}`,
					borderRadius: 50,
					paddingBlock: 5,
				}}
			/>
		</CopyTradeSettingHeaderWrapper>
	)
}

export default CopyTradeSettingHeader
