import useSWR from 'swr'
import { STORE_DEPOSIT } from '../../constants/LOCALKEYS'

const KEY = STORE_DEPOSIT

let swrData = null

export function useDepositStore(init) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData == null) {
				return init
			} else {
				return swrData
			}
		},
		{
		}
	)

	return {
		data,
		mutate: (value: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
