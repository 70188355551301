import BigNumber from 'bignumber.js'
import React from 'react'
import { getPercentageFromPrice, padDP } from '../../../../../utils/ciutils'
import { COLORS } from '../../../../constants'
import { useFetcher, useFetcherPublic } from '../../../../hooks/fetcher'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import { BidRow, OrderBookContainer, Price, Quantity, Total } from './styles'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'

const BidsSection = ({ bids, max }: any) => {
	const { data, mutate: mutateTradeStore } = useTradeStore()

	const { orderlist: list } = useTradePosition()

	return (
		<OrderBookContainer>
			{bids?.length &&
				bids?.map((item: any, index: number) => {
					const check = list?.find(
						(i: any) => i?.order_price == item?.price
					)

					return (
						<BidRow
							key={index.toString()}
							onClick={() => {
								mutateTradeStore({
									...data,
									order_price: item?.price,
								})
							}}>
							<Price asks={false}>
								{padDP(item?.price, data?.dp)}
							</Price>
							<Quantity>
								{check ? (
									<span
										style={{
											color: COLORS.lightGreen,
											fontSize: 16,
											fontWeight: 'bold',
										}}>
										ㆍ
									</span>
								) : null}
								{BigNumber(item?.amount || 0)
									.plus(check?.remain_amount || 0)
									.toFixed(data?.dp2 || data?.dp || 3)}
							</Quantity>
							<Total
								style={{
									transition: 'all 300ms ease',
									minWidth: '90px',
									backgroundSize: `${getPercentageFromPrice(
										item?.sumAmount,
										0,
										max
									)}% 100%`,
									backgroundPosition: `${getPercentageFromPrice(
										item?.sumAmount,
										0,
										max
									)}% left`,
									marginLeft: '10px',
									backgroundImage: `linear-gradient(to right, #c52d3242 ${getPercentageFromPrice(
										item?.sumAmount,
										0,
										max
									)}%  0%)`,
								}}>
								{padDP(item?.sumAmount, data?.dp2 || data?.dp)}
							</Total>
						</BidRow>
					)
				})}
		</OrderBookContainer>
	)
}

export default React.memo(BidsSection)
