import React, { useContext } from 'react'
import { FeedbackCategorySection } from './styles'
import { Input } from 'antd'
import { ThemeContext } from 'theme/ThemeProvider'

const { TextArea } = Input

const UserFeedbackDetail = () => {
	const { drawerBgColor, textw } = useContext(ThemeContext).theme.COLORS
	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		console.log('Change:', e.target.value)
	}
	return (
		<FeedbackCategorySection>
			<TextArea
				allowClear
				showCount
				maxLength={1000}
				style={{ height: '100%', resize: 'none' }}
				styles={{
					textarea: {
						background: drawerBgColor,
						border: 'transparent',
						color: textw,
					},
				}}
				onChange={onChange}
				placeholder="Leave your comments here."
			/>
		</FeedbackCategorySection>
	)
}

export default UserFeedbackDetail
