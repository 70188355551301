import React from 'react'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { DerivativesHeaderSection, DerivativesHeaderWrapper } from './styles'
import { useTranslation } from 'react-i18next'

import { COLORS } from 'app/constants'
import useTradeStore from '../../../../../hooks/trade/tradeStore'
import { useTradeSetting } from '../../../../../hooks/trade/tradeSetting'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ThemeContext } from 'theme/ThemeProvider'
import { IconChevronDown } from '@tabler/icons'

const DerivativesHeader = () => {
	const { textw } = React.useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()

	const { data: tradeStore } = useTradeStore()
	const { data: tradeSetting } = useTradeSetting(tradeStore?.ep_id)
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	return (
		<DerivativesHeaderWrapper>
			<DerivativesHeaderSection>
				<View
					style={{
						borderRight: `1px solid ${COLORS.lightGray5}`,
						paddingLeft: 15,
						minWidth: 50,
						paddingRight: 20,
					}}>
					<Text
						size={14}
						style={{ letterSpacing: 1.1, flex: 1, minWidth: 40 }}>
						{t(tradeSetting?.cross_iso === 'C' ? 'cross' : 'iso')}
					</Text>
				</View>
				<CButton
					title={`${tradeSetting?.leverage}x`}
					onClick={() => {
						mutateModalStore({
							...modalStore,
							crossModal: true,
						})
					}}
					iconLeft={false}
					lefticon={<div></div>}
					icon={<IconChevronDown size={18} color={textw} />}
					style={{
						width: '100%',
						fontSize: 16,
						justifyContent: 'space-between',
						fontWeight: '600',
						color: COLORS.primary,
						letterSpacing: 1.3,
						height: 30,
						borderRadius: 0,
					}}
				/>
			</DerivativesHeaderSection>
		</DerivativesHeaderWrapper>
	)
}

export default DerivativesHeader
