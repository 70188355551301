import React from 'react'
import { HeaderSearchListSection } from './styles'
import SearchIntialRender from './SearchIntialRender'
import { useCheckTradePage } from 'utils/ExpensiveFunction'
import { useWindowDimensions } from 'app/hooks'
import HeaderSearchLists from './HeaderSearchLists'

interface Iprops {
	text?: string
}
const HeaderSearchList = ({ text }: Iprops) => {
	const { width } = useWindowDimensions()
	const checkSize = width < 900
	const otherPage = checkSize ? 80 : 60
	const size = checkSize ? { right: 50 } : {}
	const left = width < 600 ? { left: 0 } : { ...size }
	return (
		<HeaderSearchListSection
			style={{
				top: useCheckTradePage() ? 40 : otherPage,
				...left,
			}}>
			{text?.length ? (
				<HeaderSearchLists search={text} />
			) : (
				<SearchIntialRender />
			)}
		</HeaderSearchListSection>
	)
}

export default React.memo(HeaderSearchList)
