import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'

interface IProps {
	closeModal?: () => void
	action?: () => void
}
const OrderModalFooter = ({ closeModal, action }: IProps) => {
	const { t } = useTranslation()

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '92%',
				marginTop: 20,
				position: 'absolute',
				bottom: 30,
			}}>
			<CButton
				title={t('confirm')}
				style={{
					flex: 1,
					padding: 10,
					background: COLORS.primary,
					marginRight: 10,
					color: COLORS.white,
				}}
				onClick={() => {
					closeModal && closeModal()
					action && action()
				}}
			/>
			<CButton
				title={t('cancel')}
				style={{
					flex: 1,
					padding: 10,
					background: COLORS.lightGray5,
					color: COLORS.greyWhite,
				}}
				onClick={() => {
					closeModal && closeModal()
				}}
			/>
		</View>
	)
}

export default OrderModalFooter
