import React, { useState } from 'react'
import { PageMenuTabs } from 'app/components'
import { COLORS } from 'app/constants'
import { MView } from 'styles/mobilePageStyles'
import { TheBitCardListSection } from './styles'
import TheBitCardItems from './TheBitCardItems'

const all = 'all'
const perpetual = 'perpetual'
const derivatives = 'derivatives'
const spot = 'spot'
const MTheBitLearn = () => {
	const [tab, setTab] = useState(all)
	const tabs = [all, perpetual, derivatives, spot]
	const data = [
		{ content: '암호화폐 받는 방법 알아보기' },
		{ content: '선물거래 가이드' },
		{ content: '선물거래 수익 및 수수료의 계산방법' },
		{ content: '선물거래 포지션 및 자금 확인 방법' },
		{ content: '선물거래 가이드' },
		{ content: '선물거래 수익 및 수수료의 계산방법' },
		{ content: '선물거래 포지션 및 자금 확인 방법' },
		{ content: '선물거래 가이드' },
	]
	return (
		<MView style={{ marginBottom: 80 }}>
			<PageMenuTabs
				tab={tab}
				setTab={setTab}
				data={tabs}
				bgColor={'transparent'}
				style={{
					alignItems: 'flex-start',
					paddingLeft: 20,
				}}
				activeColor={true}
				borderColor={COLORS.white}
			/>
			<TheBitCardListSection>
				{data?.map((item, index) => (
					<TheBitCardItems
						key={index.toString()}
						item={item}
						index={index}
					/>
				))}
			</TheBitCardListSection>
		</MView>
	)
}

export default MTheBitLearn
