import { useToken } from 'app/hooks'
import { useFetcher } from 'app/hooks/fetcher'
import { goTo } from 'app/hooks/navigation'
import { CBottomSheet } from 'app/mobile/components'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MText, MView } from 'styles/mobilePageStyles'
import MInviteContainer from './InviteContainer'
import MReferralFooterBtn from './MReferralFooterBtn'
import MReferralCommission from './ReferralCommission'
import MReferralHistory from './ReferralHistory'
import { MReferralSection } from './styles'

const MReferral = () => {
	const { t } = useTranslation()
	const { data } = useFetcher('/referral/code', false)
	const referral = data?.result?.result?.code
	const [referralCode, setReferralCode] = useState(referral)
	const [open, setOpen] = useState(false)
	const { data: token } = useToken()
	const navigation = useHistory()
	useEffect(() => {
		if (!token) {
			goTo(navigation, '/login')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token])

	return (
		<MReferralSection>
			<MView
				style={{
					width: '100%',
					height: 80,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<MText style={{ fontSize: 16, fontWeight: '600' }}>
					{t('earnTogether')}
				</MText>
				<MText
					style={{ fontSize: 16, fontWeight: '600', paddingTop: 5 }}>
					{t('referYourFriends')}
				</MText>
			</MView>
			<MReferralCommission
				open={open}
				setOpen={setOpen}
				referralCode={referralCode}
				setReferralCode={setReferralCode}
			/>
			<MReferralHistory />
			<MReferralFooterBtn
				open={open}
				setOpen={setOpen}
				referral={referralCode}
			/>
			<CBottomSheet
				open={open}
				setOpen={setOpen}
				BottomContainer={MInviteContainer}
				item={referralCode}
				setItem={setReferralCode}
			/>
		</MReferralSection>
	)
}

export default MReferral
