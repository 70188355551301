import styled from 'styled-components'
import { IMAGES } from 'app/constants'

export const GlobalTrendSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-block: 30px;
	height: 282px;
	width: 100%;
	@media (max-width: 1200px) {
		flex-wrap: wrap;
		height: 600px;
	}
	@media (max-width: 900px) {
		flex-wrap: wrap;
		height: 600px;
		justify-content: center;
		align-items: center;
	}
	@media (max-width: 600px) {
		flex-direction: column;
		height: 1000px;
		justify-content: center;
		align-items: center;
	}
`
export const GlobalTrendItemSection = styled.div`
	height: 240px;
	width: 24%;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	border-radius: 16px;
	box-shadow: 0 2px 20px rgb(0 0 0 / 2%);
	display: flex;
	flex-direction: column;
	cursor: pointer;
	@media (max-width: 1200px) {
		width: 32%;
	}
	@media (max-width: 900px) {
		width: 45%;
		margin-bottom: 10px;
		margin-right: 10px;
	}
	@media (max-width: 600px) {
		width: 70%;
		margin-right: 0px;
		margin-bottom: 10px;
	}
`

export const GlobalTrendItemHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
`
export const GlobalTrendItemBody = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* background-image: url(${IMAGES.graph}); */
	background-size: cover;
`
