import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { IconChevronDown } from '@tabler/icons'
import { ThemeContext } from 'theme/ThemeProvider'

const DepositAutoChanneled = () => {
	const { textsilver, descBox } = useContext(ThemeContext).theme.COLORS
	const [depositType, setDepositType] = useState('spot')
	const [display, setDisplay] = useState(false)
	const { t } = useTranslation()
	const list = ['spot', 'tradeaccount']
	return (
		<View
			style={{ width: '100%', alignItems: 'flex-start', marginTop: 20 }}>
			<Text>{t('depositsAutoChanneledTo')}</Text>
			<View
				onClick={() => setDisplay(!display)}
				style={{
					border: `1px solid ${COLORS.lightGray5}`,
					width: '100%',
					alignItems: 'flex-start',
					padding: 7,
					marginTop: 8,
					borderRadius: 3,
					cursor: 'pointer',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<Text size={14}>{t(depositType)}</Text>
				<IconChevronDown size={14} />
			</View>
			{display ? (
				<View
					style={{
						position: 'absolute',
						width: '55%',
						background: descBox,
						marginTop: 115,
						alignItems: 'flex-start',
						border: `1px solid ${textsilver}`,
					}}>
					{list?.map((item, index) => (
						<View
							key={index.toString()}
							onClick={() => {
								setDepositType(item)
								setDisplay(!display)
							}}
							style={{
								alignItems: 'flex-start',
								padding: 8,
								cursor: 'pointer',
								background:
									item === depositType
										? COLORS.primary
										: COLORS.gray,
								width: '100%',
							}}>
							<Text
								size={14}
								style={{
									color:
										item === depositType
											? COLORS.white
											: COLORS.white,
								}}>
								{t(item)}
							</Text>
						</View>
					))}
				</View>
			) : null}
		</View>
	)
}

export default DepositAutoChanneled
