import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import TradingFeeTableHeader from '../TradingFeeTableHeader'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ReferTable = () => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				marginTop: 20,
				alignItems: 'flex-start',
				width: '100%',
			}}>
			<TradingFeeTableHeader title={'referFriendsFee'} level={1} />
			{/* <View
				style={{
					marginBlock: 20,
					alignItems: 'flex-start',
					paddingInline: 16,
				}}>
				<Text
					style={{
						fontSize: 16,
						color: textw,
						fontWeight: '500',
					}}>
					{t('feeRate')}
				</Text>
			</View> */}
			{/* <FeeTableSection /> */}
		</View>
	)
}

export default ReferTable
