import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const ModalOverlay = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999 !important;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ModalContainer = styled.div`
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	max-width: 100%;
	position: relative;
	z-index: 9999;
`

export const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-block: 10px;
	padding-inline: 20px;
`

export const ModalCloseButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	font-size: 16px;
	padding: 0;
	background-color: ${COLORS.grey};
	padding-inline: 6px;
	border-radius: 50px;
	color: ${COLORS.white};
`

export const ModalBody = styled.div`
	padding: 0;
`
