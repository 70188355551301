import React, { useContext, useEffect, useState } from 'react'
import { View } from 'styles/reactnative'
import { DepositLeftSectionWrapper } from '../styles'
import DepositAutoChanneled from './DepositAutoChanneled'
import DepositChainType from './DepositChainType'
import DepositCoins from './DepositCoins'
import DepositQrCode from './DepositQrCode'
import DepositWalletAddress from './DepositWalletAddress'
import { useDepositStore } from '../../../hooks/deposit/store'
import {
	Button,
	Col,
	Divider,
	Input,
	InputNumber,
	Row,
	Space,
	message,
} from 'antd'
import { IconSend } from '@tabler/icons'
import API from '../../../../utils/api'
import { useToken } from '../../../hooks'
import { useFetcherPublic, useFetcherSetting } from '../../../hooks/fetcher'
import BigNumber from 'bignumber.js'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useMyProfile } from '../../../hooks/user/myprofile'

const DepositLeftSection = ({ width = '60%' }) => {
	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'KRW',
	})

	const { textw } = useContext(ThemeContext).theme.COLORS

	const [refresh, setRefresh] = useState(false)
	const currencyList = useFetcherPublic('/system/currency')

	const equivusd = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'KRW'
	)

	useEffect(() => {
		setTimeout(() => {
			setRefresh(!refresh)
		}, 500)
	}, [depositStore?.coin, depositStore?.network])

	const { data } = useMyProfile()

	/*
	const { data: bank1 } = useFetcherSetting('CBANK1')
	const { data: bank2 } = useFetcherSetting('CBANK2')
	const { data: bank3 } = useFetcherSetting('CBANK3')
	*/

	const { data: token } = useToken()
	const [amount, setAmount] = useState<any>(0)
	const [name, setName] = useState('')
	return (
		<DepositLeftSectionWrapper style={{ width: width }}>
			<View style={{ paddingInline: 15, paddingBlock: 10 }}>
				<DepositCoins />
				{depositStore?.coin == 'KRW' ? (
					<View
						style={{
							flexDirection: 'column',
							alignItems: 'flex-start',
							marginBottom: 20,
							width: '100%',
						}}>
						<Row align={'middle'} style={{ marginBottom: 5 }}>
							<Col span={6} style={{ color: textw }}>
								신청금액
							</Col>
							<Col span={13}>
								<InputNumber
									value={amount}
									formatter={value =>
										`${value}`
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
											?.replace(/[^0-9,]/g, '')
									}
									parser={value =>
										value!
											.replace(/\$\s?|(,*)/g, '')
											?.replace(/[^0-9]/g, '')
									}
									step={10000}
									onChange={e => setAmount(e)}
									style={{
										textAlign: 'right',
										width: '100%',
										flex: 1,
									}}
								/>
							</Col>
							<Col span={1} style={{ color: textw }}>
								원
							</Col>
							<Col span={4}>
								<Button
									size="small"
									onClick={() => {
										setAmount(0)
									}}>
									재입력
								</Button>
							</Col>
						</Row>
						<Row
							align={'middle'}
							style={{
								width: '100%',
								flex: 1,
								marginBottom: 8,
								paddingBottom: 8,
								borderBottom: '1px solid #999',
							}}>
							<Col
								span={24}
								style={{
									width: '100%',
									display: 'flex',
									flex: 1,
								}}>
								<Space.Compact
									size={'small'}
									style={{ flex: 1, width: '100%' }}>
									<Button
										size="small"
										style={{ width: '100%', flex: 1 }}
										onClick={() => {
											setAmount(amount + 1000000)
										}}>
										+100만
									</Button>
									<Button
										size="small"
										style={{ width: '100%', flex: 1 }}
										onClick={() => {
											setAmount(amount + 500000)
										}}>
										+50만
									</Button>
									<Button
										size="small"
										style={{ width: '100%', flex: 1 }}
										onClick={() => {
											setAmount(amount + 100000)
										}}>
										+10만
									</Button>
									<Button
										size="small"
										style={{ width: '100%', flex: 1 }}
										onClick={() => {
											setAmount(amount + 50000)
										}}>
										+5만
									</Button>
									<Button
										size="small"
										style={{ width: '100%', flex: 1 }}
										onClick={() => {
											setAmount(amount + 10000)
										}}>
										+1만
									</Button>
								</Space.Compact>
							</Col>
						</Row>
						<Row
							align={'middle'}
							style={{ width: '100%', flex: 1, marginBottom: 5 }}>
							<Col span={6} style={{ color: textw }}>
								환산금액
							</Col>
							<Col
								span={18}
								style={{
									flexDirection: 'row',
									display: 'flex',
									alignItems: 'center',
								}}>
								<Input
									type="text"
									style={{
										flex: 1,
										background: '#eeeeff',
										fontWeight: 'bold',
										textAlign: 'right',
										paddingRight: 50,
									}}
									value={new BigNumber(amount)
										.dividedBy(equivusd?.price)
										.toFixed(2)}
									readOnly></Input>{' '}
								<div
									style={{
										color: 'black',
										position: 'absolute',
										right: 5,
									}}>
									USDT
								</div>
							</Col>
						</Row>
						{/* <Row
							align={'middle'}
							style={{ width: '100%', flex: 1, marginBottom: 5 }}>
							<Col span={6} style={{ color: textw }}>
								입금자명
							</Col>
							<Col
								span={18}
								style={{
									flexDirection: 'row',
									display: 'flex',
									alignItems: 'center',
								}}>
								<Input
									type="text"
									value={name}
									onChange={e =>
										setName(e.target.value)
									}></Input>
							</Col>
						</Row> */}
						<Row
							align={'middle'}
							style={{
								width: '100%',
								flex: 1,
								marginBottom: 5,
								borderBottom: '1px solid #888',
								paddingBottom: 8,
							}}>
							<Col span={6} style={{ color: textw }}>
								$ 환율
							</Col>
							<Col span={18}>
								<Input
									type="text"
									value={equivusd?.price}
									readOnly></Input>
							</Col>
						</Row>
						<Button
							type="primary"
							icon={<IconSend size={14} />}
							style={{ width: '100%', flex: 1 }}
							onClick={() => {
								// if (!name) {
								// 	message.error('입금자명은 필수입니다.')
								// 	return
								// }

								if (!amount) {
									message.error('신청금액은 필수입니다.')
									return
								}

								API.post('/cash/request', token + '', {
									type: 'D',
									amount: new BigNumber(amount)
										.dividedBy(equivusd?.price)
										.toFixed(2),
									bankaccount: data?.vaccount,
									name,
								}).then(res => {
									if (res?.result?.success) {
										message.success(
											'입금신청이 완료되었습니다.'
										)
									} else {
										message.error(
											res?.result?.message ||
												'입금신청에 실패하였습니다.'
										)
									}
								})
							}}>
							입금신청
						</Button>
					</View>
				) : (
					<>
						<DepositChainType />
						<DepositQrCode />
					</>
				)}
				<DepositWalletAddress />
				<DepositAutoChanneled />
			</View>
		</DepositLeftSectionWrapper>
	)
}

export default DepositLeftSection
