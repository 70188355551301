import React from 'react'
import SearchHistoryItem from './SearchHistoryItem'
import { SearchHistoryListSection } from './styles'
import { useSearchHistory } from 'app/hooks/stateStore'
import { useTranslation } from 'react-i18next'
import CNoDataFound from '../../../../components/Common/CNoDataFound'

const SearchHistoryList = ({ setOpenSearch }) => {
	const { data } = useSearchHistory(true)
	const { t } = useTranslation()

	return (
		<SearchHistoryListSection>
			{data?.length === 0 ? (
				<CNoDataFound
					noDataTitle={t('searchHistoryContent')}
					noDataContent={undefined}
					noDataSize={undefined}
					noDataHeight={undefined}
					lottieSize={undefined}
				/>
			) : null}

			{data?.map((item: any, index: any) => (
				<SearchHistoryItem
					key={index.toString()}
					item={item}
					setOpenSearch={setOpenSearch}
				/>
			))}
		</SearchHistoryListSection>
	)
}

export default React.memo(SearchHistoryList)
