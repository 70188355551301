import React from 'react'
import { CButton, CImage } from 'app/components/Common'
import { View } from 'styles/reactnative'
import phoneInside from 'assets/images/phoneInside.png'
import phoneOutside from 'assets/images/phoneOutside.png'
import { useWindowDimensions } from 'app/hooks'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { COLORS } from 'app/constants'
const SpecialMobileImages = () => {
	const navigation = useHistory()
	const { width } = useWindowDimensions()
	return (
		<View style={{ width: '100%', height: 500 }}>
			<CImage
				src={phoneOutside}
				alt="announcement"
				width={300}
				height={'auto'}
				resizeMode={'cover'}
				style={{ position: 'absolute', marginLeft: -150 }}
			/>
			<CImage
				src={phoneInside}
				alt="announcement"
				width={350}
				height={'auto'}
				resizeMode={'cover'}
				style={{
					position: 'absolute',
					marginTop: width < 420 ? -37 : -25,
					marginRight: -30,
				}}
			/>
		</View>
	)
}

export default SpecialMobileImages
