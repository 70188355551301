import { StyledButton } from 'app/components/Common/CButton/styles'
import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const SubDropdownSection = styled.div<{ display?: any }>`
	display: none;
	position: absolute;
	background-color: ${p => p.theme.COLORS.subHeader};
	top: 0px;
	left: 300px;
	width: 300px;
	height: 100%;
	margin-top: 0px;
	-moz-box-shadow: 3px 3px 10px ${p => p.theme.COLORS.shadow};
	-webkit-box-shadow: 3px 3px 10px ${p => p.theme.COLORS.shadow};
	box-shadow: 3px 3px 10px ${p => p.theme.COLORS.shadow};
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	z-index: 999 !important;
`
// eslint-disable-next-line prettier/prettier
export const DropdownContent = styled.div<{ sizeCheck?: boolean }>`
	display: none;
	position: absolute;
	background-color: ${p => p.theme.COLORS.header};
	margin-left: 220px;
	width: 300px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
	/* border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px; */
	z-index: 999 !important;
	padding: 0 !important;
	${({ sizeCheck }) =>
		sizeCheck &&
		`
      &:hover {
        height:500px
       }
    `}
`
export const DropdownList = styled.div`
	padding: 0;
	width: 100%;
	color: #000;
`
export const DropDownContainer = styled.div<{ display?: any }>`
	&:hover ${SubDropdownSection} {
		display: ${props => props.display};
	}
`

export const DropDownSubContainer = styled.div`
	&:hover {
		background-color: ${p => p.theme.COLORS.subHeader};
		border-radius: 10px;
	}
	&:hover ${StyledButton} {
		color: ${COLORS.primary}!important;
	}
`
export const DropDownLeft = styled.div`
	display: flex;
	align-items: flex-start;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`
