import React from 'react'
import { COLORS, FONTS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { Column, Text } from 'styles/reactnative'
import BotButtonCointaner from './BotButtonCointaner'

const TradingBotLeftSide = () => {
	const { t } = useTranslation()
	return (
		<Column alignItems={'flex-start'}>
			<Text
				style={{
					...FONTS.h1,
					fontSize: 44,
					lineHeight: 1.3,
					color: COLORS.white,
				}}>
				{t('botEventTitle')}
			</Text>
			<Text
				style={{
					...FONTS.h3,
					fontSize: 28,
					lineHeight: 1,
					color: COLORS.primary,
					marginTop: 10,
				}}>
				{t('smartTradesMadeEasy')}
			</Text>

			<BotButtonCointaner />
		</Column>
	)
}

export default TradingBotLeftSide
