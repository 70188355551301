import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const HelpCenterDetailList = ({ text }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ marginBlock: 20 }}>
			<Text size={14} style={{ color: textw, fontWeight: '300' }}>
				{text}
			</Text>
		</View>
	)
}

export default HelpCenterDetailList
