import styled from 'styled-components'

export const OtherEventSection = styled.div`
	height: 160px;
	width: 100%;
	background: linear-gradient(180deg, #7438ff 0%, #3860ff 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
export const EventHalf = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
		padding-left: 10px;
	}
`
