import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconArrowNarrowRight } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'

const HighestProfitHeader = () => {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: width < 600 ? 'center' : 'space-between',
				alignItems: 'center',
				margin: width < 600 ? 'auto' : 0,
				width: width < 600 ? '90%' : '100%',
			}}>
			<View style={{ alignItems: 'flex-start' }}>
				<Text
					style={{
						fontWeight: '600',
						lineHeight: 2,
						fontSize: width > 900 ? 20 : 14,
					}}>
					{t('tradersWithTheHighestProfitForFollowers')}
				</Text>
				<Text
					style={{
						fontWeight: '400',
						color: COLORS.lightGray5,
						fontSize: width > 900 ? 18 : 12,
					}}>
					{t('tradersTheHighestProfits')}
				</Text>
			</View>
			<CButton
				title={t('viewMore')}
				icon={<IconArrowNarrowRight color={COLORS.primary} />}
				onClick={() => {}}
				style={{
					color: COLORS.primary,
					width: width < 900 ? '30%' : 'auto',
				}}
			/>
		</View>
	)
}

export default React.memo(HighestProfitHeader)
