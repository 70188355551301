import React from 'react'
import { View } from 'styles/reactnative'
import OrderPreferenceCheckBox from './OrderPreferenceCheckBox'
import OrderPreferenceNote from './OrderPreferenceNote'

const OrderModalBody = ({
	orderQty,
	setOrderQty,
	orderByValue,
	setOrderByValue,
	symbol,
}) => {
	return (
		<View
			style={{
				alignItems: 'flex-start',
				width: '100%',
			}}>
			<OrderPreferenceCheckBox
				checked={orderQty}
				setOrderByValue={setOrderByValue}
				orderByValue={orderByValue}
				setOrderQty={setOrderQty}
				orderQty={orderQty}
				type={'qty'}
				title={'orderByQty'}
				content={'orderByQtyDesc'}
				coinType={symbol}
			/>
			<OrderPreferenceCheckBox
				checked={orderByValue}
				setOrderByValue={setOrderByValue}
				orderByValue={orderByValue}
				setOrderQty={setOrderQty}
				orderQty={orderQty}
				type={'value'}
				title={'orderByValue'}
				content={'orderByValueDesc'}
				coinType={'USDT'}
			/>
			{orderByValue ? <OrderPreferenceNote /> : null}
		</View>
	)
}

export default OrderModalBody
