import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import AuthHeaderDropdown from './AuthHeaderDropdown'
import { HeaderButtonSection, HeaderButtonText } from './styles'
import { useMyProfile } from '../../../hooks/user/myprofile'

interface IProps {
	label?: string
	onPress?: () => void
	notIcon?: boolean
	Icon?: any
	type?: string
	width?: number
}



const AuthHeaderButton = ({ label, onPress, Icon, type, width }: IProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [hoverState, setHoverState] = useState(false)

	const { data: profile } = useMyProfile()

	return (
		<HeaderButtonSection
			style={
				width
					? { minWidth: width }
					: Icon
					? { minWidth: 40 }
					: { minWidth: 80 }
			}
			onClick={onPress}
			onMouseOver={() => setHoverState(true)}
			onMouseOut={() => setHoverState(false)}>
			{type == 'user' && profile?.level &&  (profile?.level <= 0 || profile?.level == 6) && (
				<div
					style={{
						position: 'absolute',
						marginTop: '26px',
						background: 'black',
						color: 'white',
						fontSize: '11px',
						padding: '3px',
					}}>
					{profile?.level == 6 ? '모의' : '미승인'}
				</div>
			)}
			{/* Dropdown */}
			<AuthHeaderDropdown type={type} />
			{Icon ? <Icon /> : null}
			{label ? (
				<View style={{ flexDirection: 'row' }}>
					<HeaderButtonText> {label}</HeaderButtonText>
					{/* {hoverState ? (
						<IconCaretUp size={10} stroke={5} />
					) : (
						<IconCaretDown size={10} stroke={5} />
					)} */}
				</View>
			) : null}
		</HeaderButtonSection>
	)
}

export default React.memo(AuthHeaderButton)
