import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { CInput } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const PhoneInputSection = ({ id, onChangeId, type, vStyle }: any) => {
	const { gray, textw } = React.useContext(ThemeContext).theme.COLORS
	const { t, i18n } = useTranslation()
	const [mobile, setMobile] = useState<any>({})
	const [dropdownOpen, setDropdownOpen] = useState(false)

	useEffect(() => {
		onChangeId({
			target: {
				value: mobile,
			},
		})
	}, [mobile, onChangeId])

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'flex',
				alignItems: 'flex-start',
				...vStyle,
			}}>
			<PhoneInput
				showDropdown={dropdownOpen}
				country={i18n?.language === 'KO' ? 'kr' : 'us'}
				placeholder="Phone Number"
				value={id?.mobile}
				onChange={(value, country: any) => {
					setMobile({
						...mobile,
						country_code: country?.countryCode,
						iso_code: country.dialCode,
					})
				}}
				containerStyle={{ width: 38 }}
				inputStyle={{
					display: 'none',
					padding: 0,
					margin: 0,
					position: 'absolute',
					height: '0%',
					width: '0%',
					background: gray,
				}}
				buttonStyle={{
					background: gray,
					padding: 0,
					width: 38,
					height: 40,
					border: 'none',
				}}
			/>
			<View
				onClick={() => {
					setDropdownOpen(true)
				}}
				style={{
					height: 40,
					background: gray,
					color: textw,
					borderTopRightRadius: 5,
					borderBottomRightRadius: 5,
					width: 45,
					cursor: 'pointer',
				}}>
				<Text size={14} style={{ color: textw }}>
					{mobile?.iso_code}
				</Text>
			</View>
			<CInput
				placeholder={t(type)}
				value={id.mobile}
				type={'text'}
				onChange={e => {
					setMobile({
						...mobile,
						mobile: e.target.value,
					})
				}}
				vStyle={{
					flexBasis: '50%',
					flexGrow: 1,
					marginLeft: 5,
				}}
				style={{
					height: 40,
					width: '100%',
					border: 'none',
					background: gray,
					color: textw,
				}}
			/>
		</View>
	)
}

export default PhoneInputSection
