import styled from 'styled-components'

export const TradeChartSectionWrapper = styled.div`
	width: 100%;
	height: 102.6%;

	.widgetClose {
		display: none;
	}

	&:hover {
		.widgetClose {
			display: flex;
		}
	}
`
