import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

const CBottomSheet = ({
	open,
	setOpen,
	BottomContainer,
	item,
	setItem,
	maxHeight = 600,
	expandOnContentDrag = true,
}: any) => {
	return (
		<BottomSheet
			open={open}
			maxHeight={maxHeight}
			onDismiss={() => {
				setOpen(!open)
			}}
			snapPoints={({ maxHeight }) => [maxHeight]}
			expandOnContentDrag={expandOnContentDrag}>
			{BottomContainer ? (
				<BottomContainer
					setOpen={setOpen}
					item={item}
					setItem={setItem}
				/>
			) : null}
		</BottomSheet>
	)
}

export default CBottomSheet
