import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ReferralTableMenu = ({ menuList, tab, setTab }) => {
	const [openMenu, setOpenMenu] = useState(false)
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	return (
		<View>
			<CButton
				title={t(tab)}
				style={{
					border: `1px solid ${textw}`,
					width: 130,
					color: textw,
				}}
				onClick={() => {
					setOpenMenu(!openMenu)
				}}
			/>
			{openMenu ? (
				<View
					style={{
						marginTop: 210,
						position: 'absolute',
						width: 130,
						background: COLORS.lightGray,
						alignItems: 'flex-start',
						padding: 5,
					}}>
					{menuList?.map((item: string, index: number) => (
						<View
							key={index.toString()}
							style={{ paddingBlock: 5, cursor: 'pointer' }}
							onClick={() => {
								setTab(item)
								setOpenMenu(false)
							}}>
							<Text
								style={{
									color:
										item === tab
											? COLORS?.white
											: COLORS.lightGray4,
								}}>
								{t(item)}
							</Text>
						</View>
					))}
				</View>
			) : null}
		</View>
	)
}

export default ReferralTableMenu
