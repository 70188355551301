import React from 'react'

import OrderContainerNavBar from './OrderContainerNavBar'
import OrderContainerTable from './OrderContainerTable'
import OrderContainerTabs from './OrderContainerTabs'
import { OrderContainerSection } from './styles'

const OrderContainer = ({ cTab, setCTab, cTabs }) => {
	const headers = [
		'contracts',
		'exitType',
		'filledTotal',
		'tradingFees',
		'filledPrice',
		'orderPrice',
		'tradeType',
		'orderType',
		'orderTime',
	]
	const data = [
		// {
		// 	contracts: 5,
		// 	exitType: 'Stop loss',
		// 	filledTotal: 3,
		// 	tradingFees: 0.25,
		// 	filledPrice: 245.5,
		// 	orderPrice: 246.25,
		// 	tradeType: 'Long',
		// 	orderType: 'Limit',
		// 	tradeNo: 'ABC123',
		// 	orderNo: 'DEF456',
		// 	orderTime: '2022-03-20 10:30:15',
		// },
		// {
		// 	contracts: 10,
		// 	exitType: 'Take profit',
		// 	filledTotal: 7,
		// 	tradingFees: 0.5,
		// 	filledPrice: 124.75,
		// 	orderPrice: 123.5,
		// 	tradeType: 'Short',
		// 	orderType: 'Market',
		// 	tradeNo: 'XYZ789',
		// 	orderNo: 'GHI012',
		// 	orderTime: '2022-03-21 16:45:22',
		// },
	]
	return (
		<OrderContainerSection>
			<OrderContainerTabs cTab={cTab} setCTab={setCTab} cTabs={cTabs} />
			<OrderContainerNavBar />
			<OrderContainerTable headers={headers} data={data} />
		</OrderContainerSection>
	)
}

export default OrderContainer
