import * as React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as PAGE from './pagesImport'
import { GlobalStyle } from 'styles/global-styles'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import { useToken } from './hooks'
import Logout from './pages/Logout'
import { View } from '../styles/reactnative'

export function Routes() {
	const { i18n, t } = useTranslation()
	const { data: token } = useToken()

	var useragt = navigator.userAgent.toLowerCase()
	if (useragt.match(/kakaotalk/i)) {
		return (
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					fontSize: 20,
				}}>
				카카오톡 외부브라우저에서 접속해주세요.
			</View>
		)
	}

	return (
		<BrowserRouter>
			<Helmet
				titleTemplate="%s"
				defaultTitle={t('title') + ' - Coin Futures'}
				htmlAttributes={{ lang: i18n.language }}>
				<meta name="description" content={t('title')} />
			</Helmet>
			<Switch>
				<Route
					exact
					path="/trade/chart"
					component={PAGE.ZMobileTradingView}
				/>
				<Route
					exact
					path="/trade/chart/*"
					component={PAGE.ZMobileTradingView}
				/>
				{/* Security Page */}
				{/* Primary Page */}
				<Route exact path="/" component={PAGE.HomePage} />
				{/* Authentication Page */}
				<Route
					exact
					path="/login/"
					component={token ? PAGE.ProfileSettingPage : PAGE.LoginPage}
				/>
				<Route
					exact
					path="/logout/"
					component={token ? Logout : PAGE.LoginPage}
				/>
				<Route
					exact
					path="/signup/"
					component={
						token ? PAGE.ProfileSettingPage : PAGE.SignUpPage
					}
				/>
				{/* Authentication Page */}

				{/* Trading View Page */}
				<Route exact path="/trade/" component={PAGE.TradePage} />
				<Route exact path="/trade/*" component={PAGE.TradePage} />
				{/* Trading View Page */}

				{/* Markets  Page */}
				<Route exact path="/markets/" component={PAGE.MarketPage} />

				<Route
					exact
					path="/markets/trends"
					component={PAGE.MarketTrendPage}
				/>
				{/* Markets  Page */}
				<Route exact path="/referral/" component={PAGE.ReferralPage} />

				{/* Orders Page */}
				<Route exact path="/orders/" component={PAGE.OrdersPage} />
				<Route
					exact
					path="/tradingbot/"
					component={PAGE.TradingBotPage}
				/>
				<Route
					exact
					path="/leaderboard/"
					component={PAGE.LeaderBoardPage}
				/>
				<Route
					exact
					path="/copytrade/"
					component={PAGE.CopyTradePage}
				/>
				<Route
					exact
					path="/followertrader/"
					component={PAGE.FollowerTraderPage}
				/>
				<Route
					exact
					path="/traderdetail/"
					component={PAGE.TraderDetailPage}
				/>
				<Route
					exact
					path="/assets/"
					component={token ? PAGE.AssetPage : PAGE.LoginPage}
				/>
				<Route
					exact
					path="/notifications/"
					component={PAGE.NotificationPage}
				/>
				<Route
					exact
					path="/notiSetting/"
					component={PAGE.NotiSettingPage}
				/>
				<Route exact path="/abouts/" component={PAGE.AboutsPage} />
				<Route exact path="/supports/" component={PAGE.SupportsPage} />
				<Route
					exact
					path="/settings/"
					component={token ? PAGE.ProfileSettingPage : PAGE.LoginPage}
				/>
				{/* secoundary Page */}
				{/* <Route exact path="/about/" component={PAGE.AboutPage} /> */}
				<Route
					exact
					path="/contactus/"
					component={PAGE.ContactUsPage}
				/>
				<Route exact path="/faq/" component={PAGE.FAQPage} />
				<Route
					exact
					path="/terms-service/"
					component={PAGE.PrivacyPolicyPage}
				/>
				<Route path="*" component={NotFoundPage} />
			</Switch>
			<GlobalStyle />
		</BrowserRouter>
	)
}
