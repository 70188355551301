/**
 * Asynchronously loads the component for TradingBotPage
 */

import { lazyLoad } from 'utils/loadable'

export const TradingBotPage = lazyLoad(
	() => import('./index'),
	module => module.TradingBotPage
)
