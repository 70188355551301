import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import CreateBotItem from './CreateBotItem'

const CreateBot = () => {
	const { t } = useTranslation()
	const data = [
		{
			title: t('spotGridBot'),
			content: t('buyLow&SellHighAutomatically'),
			active: '24,268',
			apr: '595,351%',
		},
		{
			title: t('dcaBot'),
			content: t('investOnAutoPilot'),
			active: '1,853',
			apr: '19,690%',
		},
		{
			title: t('futuresGridBot'),
			content: t('multiplyYourEarning'),
			active: '6,918',
			apr: '374,308%',
		},
	]
	return (
		<View style={{ width: '100%', marginBottom: 48, marginTop: 24 }}>
			{data.map((item, index) => (
				<CreateBotItem
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</View>
	)
}

export default React.memo(CreateBot)
