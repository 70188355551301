import styled from 'styled-components'

export const MButtonSection = styled.button`
	display: flex;
	background-color: transparent;
	outline: 0;
	cursor: pointer;
	transition: ease border-bottom 250ms;
	&:active {
		transform: translateY(1px);
	}
`
