import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import OrderMenus from '../../../OrderMenus'
import { OrderContainerNavBarSection } from './styles'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const OrderContainerNavBar = () => {
	const { t } = useTranslation()
	const all = t('all')
	const [startDate, setStartDate] = useState<any>(new Date())
	// Contracts
	const contractMenus = [all, 'BTCUSD', 'ETHUSD', 'GRTUSD']
	const [contract, setContract] = useState(all)

	// Trade Type
	const buy = t('buy')
	const sell = t('sell')
	const [tradeType, setTradeType] = useState(all)
	const tradeTypeMenus = [all, buy, sell]

	// Exit Type
	const trade = t('trade')
	const liquidation = t('liquidation')
	const delivery = t('delivery')
	const [orderExit, setOrderExit] = useState(all)
	const orderExitMenus = [all, trade, liquidation, delivery]
	return (
		<OrderContainerNavBarSection>
			<View style={{ flexDirection: 'row', height: '100%' }}>
				<OrderMenus
					title={t('contracts')}
					menus={contractMenus}
					tab={contract}
					setTab={setContract}
				/>
				<OrderMenus
					title={t('tradeType')}
					menus={tradeTypeMenus}
					tab={tradeType}
					setTab={setTradeType}
				/>
				<OrderMenus
					title={t('exitType')}
					menus={orderExitMenus}
					tab={orderExit}
					setTab={setOrderExit}
				/>
			</View>
			<View style={{ flexDirection: 'row', height: '100%' }}>
				<View
					style={{
						alignSelf: 'center',
						alignItems: 'flex-start',
						justifyContent: 'center',
						height: '100%',
						paddingInline: 10,
					}}>
					<Text style={{ marginBottom: 5 }}>{t('date')}</Text>
					<DatePicker
						selected={startDate}
						onChange={date => setStartDate(date)}
					/>
				</View>
				<CButton
					title={t('search')}
					style={{
						width: 100,
						height: 24,
						justifyContent: 'center',
						alignItems: 'center',
						background: COLORS.secondary,
						color: COLORS.white,
						marginTop: 20,
						borderRadius: 3,
					}}
				/>
			</View>
		</OrderContainerNavBarSection>
	)
}

export default OrderContainerNavBar
