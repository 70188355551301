import { useFetcherPublic } from 'app/hooks/fetcher'
import MCoinLists from 'app/mobile/components/MCoinLists'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MText } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MSearchListSection } from './styles'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'

const MSearchLists = ({ search }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	let { EPs } = useExchangeStore()

	let filteredData: any
	if (search?.length) {
		filteredData = EPs?.filter(item =>
			item?.symbol?.toLowerCase()?.includes(search?.toLowerCase())
		)
	}
	return (
		<MSearchListSection>
			<MText
				style={{
					color: textw,
					fontSize: 16,
					paddingLeft: 16,
					marginBlock: 10,
				}}>
				{t('searchResultList')}
			</MText>
			<MCoinLists
				data={filteredData?.length ? filteredData : []}
				noData={'coinNotFound'}
				search={true}
			/>
		</MSearchListSection>
	)
}

export default MSearchLists
