import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'

const OrderPreferenceCheckBox = ({
	checked,
	setOrderByValue,
	orderByValue,
	setOrderQty,
	orderQty,
	type,
	title,
	content,
	coinType,
}) => {
	const { t } = useTranslation()
	const handleChange = () => {
		if (type === 'qty') {
			if (orderQty) {
				setOrderQty(false)
				setOrderByValue(true)
			} else {
				setOrderQty(true)
				setOrderByValue(false)
			}
		} else if (type === 'value') {
			if (orderByValue) {
				setOrderQty(true)
				setOrderByValue(false)
			} else {
				setOrderByValue(true)
				setOrderQty(false)
			}
		}
	}
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				marginBlock: 20,
			}}>
			<CheckBox
				value={checked}
				onChange={handleChange}
				borderColor={COLORS.lightGray5}
			/>
			<View style={{ alignItems: 'flex-start', width: '100%' }}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
					}}>
					<Text size={12} style={{ marginBottom: 5 }}>
						{title && t(title)}
					</Text>
					<Text
						size={12}
						style={{ color: COLORS.primary, marginBottom: 5 }}>
						{coinType}
					</Text>
				</View>
				<Text size={12} style={{ color: COLORS.lightGray }}>
					{content && t(content)}
				</Text>
			</View>
		</View>
	)
}

export default OrderPreferenceCheckBox
