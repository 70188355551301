import { COLORS } from 'app/constants'
import React from 'react'
import QRCode from 'react-qr-code'
import { View } from 'styles/reactnative'
import { useDepositStore } from '../../../../hooks/deposit/store'
import { useFetcher } from '../../../../hooks/fetcher'
const DepositQrCode = () => {
	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'USDT',
	})

	const { data: res } = useFetcher(
		`/user/my/wallet?coin=${depositStore?.coin}&network=${depositStore?.network}`
	)

	const walletAddress = res?.result?.result?.wallet_address || ''

	return (
		<View style={{ width: '100%', marginBlock: 10 }}>
			<View style={{ width: 100, height: 100, background: COLORS.white }}>
				<QRCode
					size={256}
					style={{ height: 'auto', maxWidth: 80, width: 80 }}
					value={walletAddress}
					viewBox={`0 0 256 256`}
				/>
			</View>
		</View>
	)
}

export default DepositQrCode
