import { useFetcher } from 'app/hooks/fetcher'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MText } from 'styles/mobilePageStyles'
import QuickWatchLists from './QuickWatchLists'
import QuickWatchTabs from './QuickWatchTabs'
import QuickWatchViewMore from './QuickWatchViewMore'
import { MQuickWatchSection } from './styles'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'

const MQuickWatch = () => {
	const { t } = useTranslation()
	const [tab, setTab] = useState(0)
	let { EPs } = useExchangeStore()
	return (
		<MQuickWatchSection>
			<MText style={{ fontSize: 14 }}>{t('quickWatch')}</MText>
			<QuickWatchTabs tab={tab} setTab={setTab} />
			{tab === 0 ? (
				<QuickWatchLists data={EPs?.slice(0, 10)} />
			) : tab === 1 ? (
				<QuickWatchLists data={[]} />
			) : tab === 2 ? (
				<QuickWatchLists data={EPs?.slice(0, 10)} />
			) : (
				<QuickWatchLists data={[]} />
			)}
		</MQuickWatchSection>
	)
}

export default MQuickWatch
