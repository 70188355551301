import styled from 'styled-components'

export const AboutProofReserveSection = styled.div`
	background-color: ${p => p.theme.COLORS.drawerBgColor};
	height: 600px;
	width: 100%;
	margin: auto;
	justify-content: center;
	align-items: center;
	margin-top: 120px;
`

export const AboutProofReserveHalf = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
	height: 100%;

	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
