import { message } from 'antd'
import { CoinLogo } from 'app/components'
import { CButton } from 'app/components/Common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText } from 'styles/mobilePageStyles'
import { Text, View } from 'styles/reactnative'

const ProofReserveCoinItems = ({ item }) => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				flexDirection: 'row',
				marginBottom: 10,
				justifyContent: 'flex-start',
			}}>
			<View style={{ width: '25%', flexDirection: 'row' }}>
				<CoinLogo size={24} src={item.image} />
				<View style={{ alignItems: 'flex-start', marginLeft: 10 }}>
					<MText style={{ fontSize: 10 }}>BTC</MText>
					<MText style={{ fontSize: 10 }}>USDT</MText>
				</View>
			</View>
			<View style={{ width: '25%', flexDirection: 'row' }}>
				<MText style={{ fontSize: 10 }}>
					{item?.platform_assets} USDT
				</MText>
			</View>
			<View style={{ width: '25%', flexDirection: 'row' }}>
				<MText style={{ fontSize: 10 }}>{item?.user_assets} BTC</MText>
			</View>
			<View style={{ width: '25%', flexDirection: 'row' }}>
				<MText style={{ marginRight: 10, fontSize: 10 }}>534%</MText>
				<CButton
					title={t('sufficiency')}
					onClick={() => {
						message.info(t('notWorkingNow'))
					}}
					style={{
						background: 'rgba(0, 149, 255, 0.2)',
						color: '#0095FF',
						paddingInline: 5,
						paddingBlock: 3,
						borderRadius: 5,
						fontSize: 10,
					}}
				/>
			</View>
		</View>
	)
}

export default ProofReserveCoinItems
