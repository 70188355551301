import React from 'react'
import { useTranslation } from 'react-i18next'
import { CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import qrCode from 'assets/images/qrCodeLogo.png'
import google from 'assets/images/google.png'
import apple from 'assets/images/apple.png'
import { ThemeContext } from 'theme/ThemeProvider'
import { useWindowDimensions } from 'app/hooks'
import { message } from 'antd'
const HomeRightSide = () => {
	const { width } = useWindowDimensions()
	const { isDarkMode } = React.useContext(ThemeContext)
	const { t } = useTranslation()
	const data = [
		{
			title: 'googlePlay',
			image: google,
			goTo: '',
		},
		{
			title: 'appStore',
			image: apple,
			goTo: '',
		},
	]
	const checkTablet = width > 900
	return (
		<View
			style={{
				position: 'absolute',
				right: 0,
				height: 200,
				alignItems: 'flex-end',

			}}>
			<View
				style={{
					width: checkTablet ? 80 : 200,
					height: checkTablet ? 230 : 50,
					paddingBlock: 10,
					background: isDarkMode
						? 'rgba(0,0,0,0.1)'
						: 'rgba(255,255,255,0.1)',
					borderRadius: 10,
				}}>
				{checkTablet ? (
					<Text
						size={14}
						style={{
							height: '50px',
							textAlign: 'center',
							color: isDarkMode
								? COLORS.lightGray
								: COLORS.lightGray3,
							marginBottom: 10,
						}}>
						{t('downloadApp')}
					</Text>
				) : null}

				<View
					style={{
						width: '100%',
						height: '80%',
						justifyContent: 'space-between',
						flexDirection: checkTablet ? 'column' : 'row',
						paddingInline: checkTablet ? 0 : 20,
					}}>
					{data.map((item, index) => (
						<View
							key={index.toString()}
							onClick={() => {message.info(t('comingSoon'))}}
							style={{ cursor: 'pointer' }}>
							<CImage
								src={item?.image}
								alt="logo"
								width={26}
								height={29}
								resizeMode={'cover'}
							/>
							{checkTablet ? (
								<Text
									size={12}
									style={{
										color: COLORS.lightGray,
										marginTop: 5,
									}}>
									{t(item?.title)}
								</Text>
							) : null}
						</View>
					))}
				</View>
			</View>
		</View>
	)
}

export default HomeRightSide
