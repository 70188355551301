import React, {
	useState,
	useMemo,
	useRef,
	useCallback,
	useContext,
} from 'react'
import { AgGridReact } from 'ag-grid-react'

import { useWindowDimensions } from 'app/hooks/'
import { MarketTableData } from './MarketTableData'
import { MarketTableListSection, TableContainer } from './styles'
// import MarketTableHeader from './MarketTableHeader'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ThemeContext } from 'theme/ThemeProvider'
import MarketSubMenu from './MarketSubMenu'
import MarketNestedBtn from './MarketNestedBtn'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

const MarketTableList = ({ data }) => {
	const { t } = useTranslation()
	const { textw, drawerBgColor } = useContext(ThemeContext).theme.COLORS

	const { width } = useWindowDimensions()
	const gridRef = useRef<any>()

	const [columnDefs] = useState(MarketTableData())

	const onGridReady = useCallback(params => {}, [])

	const defaultColDef = useMemo(
		() => ({
			flex: width > 1400 ? 1 : 0,
			minWidth: 150,
			sortable: true,
			resizable: true,
		}),
		[width]
	)

	const dLength = data?.length
	const hLength = dLength < 4 ? 500 : dLength < 10 ? 700 : 1500
	const all = 'all'
	const usdtPerpetual = 'usdtPerpetual'
	const [tab, setTab] = useState(all)
	const subMenu = [all, usdtPerpetual]

	const nestedData = [
		'hot',
		'trending',
		'new',
		'ai',
		'ethStaking',
		'metaverse',
	]
	const [nestedTab, setNestedTab] = useState('hot')

	const LoadingComponent = () => (
		<View
			style={{
				background: drawerBgColor,
				width: 1200,
				height: 700,
			}}>
			<Text size={30}>{t('currentlyNoData')}</Text>
		</View>
	)
	return (
		<MarketTableListSection>
			<MarketSubMenu tab={tab} setTab={setTab} tabs={subMenu} />
			<MarketNestedBtn
				tabs={nestedData}
				tab={nestedTab}
				setTab={setNestedTab}
			/>
			<TableContainer
				className="ag-theme-alpine ag-theme-custom"
				style={{ width: '100%', height: hLength }}>
				<AgGridReact
					rowStyle={{
						justifyContent: 'center',
						alignItems: 'center',
						alignSelf: 'center',
						paddingTop: 10,
						color: textw,
						border: 'none',
					}}
					animateRows={true}
					ref={gridRef}
					rowData={data}
					columnDefs={columnDefs}
					noRowsOverlayComponent={() => <LoadingComponent />}
					// onRowClicked={({ data }: any) => {
					// 	navigation.push(`/trade/${data?.id}`)
					// }}
					defaultColDef={defaultColDef}
					onGridReady={onGridReady}
					// enableRangeSelection={true}
					rowHeight={60}
					paginationAutoPageSize={true}
					pagination={true}
				/>
			</TableContainer>
		</MarketTableListSection>
	)
}

export default MarketTableList
