import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const ReservesDetailHeader = ({ header }) => {
	const { t } = useTranslation()
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<View style={{ width: '100%', height: 100, flexDirection: 'row' }}>
			{header.map((item, index) => (
				<View
					key={index.toString()}
					style={{ width: `${item.width}%` }}>
					<Text size={14} style={{ color: textsilver }}>
						{t(item.title)}
					</Text>
				</View>
			))}
		</View>
	)
}

export default ReservesDetailHeader
