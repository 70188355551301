import React from 'react'
import AnnounListItems from './AnnounListItems'
import { View } from 'styles/reactnative'

const AnnouncementSectionList = () => {
	const data = [
		{
			title: 'announcementListTitle',
			content: 'announcementListContent1',
		},
		{
			title: 'announcementListTitle',
			content: 'announcementListContent2',
		},
		{
			title: 'announcementListTitle',
			content: 'announcementListContent3',
		},
	]
	return (
		<View
			style={{
				width: '60%',
				height: '100%',
				alignItems: 'flex-start',
			}}>
			{data.map((item, index) => (
				<AnnounListItems
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</View>
	)
}

export default AnnouncementSectionList
