import styled from 'styled-components'
import UserFeedback from 'assets/images/userfeedback.png'
import { COLORS } from 'app/constants'
export const UserFeedbackEventSection = styled.div<{ isDarkMode?: any }>`
	background-color: transparent;
	background-image: url(${UserFeedback});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	height: 320px;
	width: 100%;
	margin: auto;
	justify-content: center;
	align-items: center;
`

export const UserFeedbackHalf = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: auto;
	height: 100%;
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
export const UserFeedbackSection = styled.div`
	width: 100%;
	height: 782px;
	padding: 30px;
	background-color: ${p => p.theme.COLORS.drawerBgColor};
`
export const FeedbackCategorySection = styled.div`
	width: 100%;
	height: 10%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	.ant-select-selector {
		background-color: transparent !important;
		border: 1px solid ${p => p.theme.COLORS.borderColor} !important;
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-arrow {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: ${p => p.theme.COLORS.content};
	}
	.ant-input-data-count {
		color: ${p => p.theme.COLORS.textw};
	}
`
export const FeedbackImageSection = styled.div`
	height: 130;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3;
	.ant-input::placeholder {
		color: #000;
	}

	.ant-upload-wrapper
		.ant-upload-list.ant-upload-list-picture-card
		.ant-upload-list-item-error {
		border-color: skyblue;
	}
	.ant-upload-wrapper.ant-upload-picture-card-wrapper
		.ant-upload.ant-upload-select {
		width: 70 !important;
		height: 35px !important;
		margin-top: 10px;
		background-color: ${COLORS.secondary};
		color: ${COLORS.white};
	}

	.ant-upload-wrapper.ant-upload-picture-card-wrapper
		.ant-upload-list.ant-upload-list-picture-card
		.ant-upload-list-item-container {
		width: 55px !important;
		height: 55px !important;
	}
	.ant-image-preview-operations {
		background-color: rgba(0, 21, 42, 1);
	}
`
