import React from 'react'
import { CFlatList } from 'app/components/Common/'

import { TradeDetailListSection } from './styles'

import TradeDetailListHeader from './TradeDetailListHeader'
import TradeDetailListItem from './TradeDetailListItem'
import TradeDetailError from './TradeDetailError'
import TradeDetailLoading from './TradeDetailLoading'
import { useFetcher } from 'app/hooks/fetcher'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import { AuthCheckText } from 'app/components'
import { useToken } from 'app/hooks'

const TradeFilledTable = () => {
	const { data: token } = useToken()
	const { data: store } = useTradeStore()

	let KEYURL = '/trade/orderlist?status=2&ep_id=*'

	const { data, isLoading, isError } = useFetcher(KEYURL, false)

	const list = data?.result

	const dataType = [
		'contracts',
		'amount',
		'filledPrice',
		'orderPrice',
		'orderType',
		'margin',
		'fee',
		'orderTime',
	]
	const renderItem = (item: any, index: any) => {
		return (
			<TradeDetailListItem
				item={item}
				index={index}
				dataType={dataType.length}
			/>
		)
	}

	return (
		<TradeDetailListSection>
			<TradeDetailListHeader dataType={dataType} />
			{isError ? <TradeDetailError /> : null}

			{token ? null : <AuthCheckText />}

			{list?.length ? (
				<CFlatList data={list} renderItem={renderItem} />
			) : null}
		</TradeDetailListSection>
	)
}

export default TradeFilledTable
