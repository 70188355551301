import React from 'react'
import AccountSecurityListItems from './AccountSecurityListItems'
import { AccountSecurityListSection } from './styles'

const AccountSecurityLists = ({ data }: any) => {
	return (
		<AccountSecurityListSection>
			{data.map((item: any, index: number) => (
				<AccountSecurityListItems
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</AccountSecurityListSection>
	)
}

export default AccountSecurityLists
