import styled from 'styled-components'

export const AssetsHeaderSection = styled.div`
	width: 100%;
	border-radius: 8px;
	padding-block: 10px;
	padding-inline: 20px;
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
`
export const HeaderAssetSection = styled.div`
	padding: 20px;
	color: ${p => p.theme.COLORS.textw};
	background-color: ${p => p.theme.COLORS.header};
`
