import styled from 'styled-components'

export const CopyTradeRightSideSection = styled.div`
	background: linear-gradient(
		289.57deg,
		rgba(15, 19, 35, 0.2) 6.82%,
		hsla(0, 0%, 100%, 0.092) 79.78%
	);
	border: 1px solid rgba(178, 203, 221, 0.3);
	border-radius: 8px;
	cursor: pointer;
	color: #fff;
	position: relative;
	min-width: 340px;
	height: 118px;
	backdrop-filter: blur(10px);
	padding: 16px;
	@media only screen and (max-width: 900px) {
		margin-top: 30px;
	}
`

export const CopyTradeEventHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

export const CopyTradeEventBody = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 20px;
`
