import React, { useContext } from 'react'
import { COLORS } from 'app/constants'
import { Text } from 'styles/reactnative'
import {
	GlobalTrendItemSection,
	GlobalTrendItemBody,
	GlobalTrendItemHeader,
} from './styles'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const GlobalTrendItem = ({ item, index }: any) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<GlobalTrendItemSection key={index.toString()}>
			<GlobalTrendItemHeader>
				<Text size={14}>{t(item?.title)}</Text>
				{item.icon()}
			</GlobalTrendItemHeader>
			<GlobalTrendItemBody>
				<Text
					size={item?.type === '0' ? 80 : 22}
					style={{
						fontWeight: '600',
						color: item?.type === '0' ? COLORS.primary : textw,
					}}>
					{item.content}
				</Text>
				<Text
					size={14}
					style={{
						color:
							item?.type === '0'
								? COLORS.primary
								: COLORS.lightGreen,
						marginTop: 10,
					}}>
					{item.subContent}
				</Text>
			</GlobalTrendItemBody>
		</GlobalTrendItemSection>
	)
}

export default React.memo(GlobalTrendItem)
