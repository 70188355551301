import React from 'react'
import { IconWallet } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import CopyTraderDetailAssets from './CopyTraderDetailAssets'
import CopyTraderDetailList from './CopyTraderDetailList'
import { useTranslation } from 'react-i18next'

const CopyTraderDetailSection = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				width: '100%',
				height: '40%',
				justifyContent: 'flex-start',
			}}>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-evenly',
					alignItems: 'center',
				}}>
				<CopyTraderDetailList
					title={'followerss'}
					amount={'629'}
					follow={true}
				/>
				<CopyTraderDetailList title={'aum'} amount={'348,326'} />
				<CopyTraderDetailList title={'maxDrawdown'} amount={'0.87%'} />
			</View>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-evenly',
					width: '100%',
					marginTop: 30,
				}}>
				<CopyTraderDetailAssets
					title={t('totalAssets')}
					amount={'15,444.1008 USDT'}
					icon={() => (
						<IconWallet size={14} color={COLORS.lightGray} />
					)}
				/>
				<CopyTraderDetailAssets
					title={t('profitSharingRatio')}
					amount={'12 %'}
					icon={() => (
						<IconWallet size={14} color={COLORS.lightGray} />
					)}
				/>
			</View>
		</View>
	)
}

export default CopyTraderDetailSection
