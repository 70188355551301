import React, { useState } from 'react'
import Select from 'react-select'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { useFetcherPublic } from 'app/hooks/fetcher'
import { getSetting, useSetting } from 'app/hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from 'app/constants/SETTING'
import NotiSwitch from '../../NotiSwitch'

interface IProps {
	select?: string
	setSelect?: any
	title?: string
	content?: string
}
const NotificationBox = ({ select, setSelect, title, content }: IProps) => {
	const { t } = useTranslation()

	const { data: supportLanguage } = useFetcherPublic(
		'/system/language',
		false
	)

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const notification = getSetting(setting, 'NOTILANG', 'EN')

	const [event, setEvent] = useState<Boolean>(true)
	const [announcement, setAnnouncement] = useState<Boolean>(true)
	const [alert, setAlert] = useState<Boolean>(true)

	const notificationsOptions = supportLanguage?.result?.result?.map(
		(item: any) => {
			return {
				value: item?.code,
				label: item?.name,
			}
		}
	)

	const customStyles = {
		container: (provided: any) => ({
			...provided,
			width: '100%',
		}),
	}

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
				padding: 10,
			}}>
			<Text size={18} style={{ fontWeight: '500' }}>
				{t('notification')}
			</Text>
			<View
				style={{ marginTop: 20, width: 300, alignItems: 'flex-start' }}>
				<Text size={16} style={{ fontWeight: '400' }}>
					{t('notificationLanguage')}
				</Text>
				<View style={{ width: '100%', marginTop: 10 }}>
					<Select
						defaultValue={notificationsOptions?.find(
							(item: any) => item.value === notification
						)}
						options={notificationsOptions}
						styles={customStyles}
						onChange={({ value }: any) => {
							updateSetting('NOTILANG', value)
						}}
					/>
				</View>
			</View>
			<NotiSwitch
				title={t('latestEvents')}
				checked={event}
				setChecked={setEvent}
			/>
			<NotiSwitch
				title={t('announcement')}
				checked={announcement}
				setChecked={setAnnouncement}
			/>
			<NotiSwitch
				title={t('tradingViewAlerts')}
				checked={alert}
				setChecked={setAlert}
			/>
		</View>
	)
}

export default React.memo(NotificationBox)
