import { COLORS } from 'app/constants'
import React, { useContext, useEffect, useRef } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import MButton from '../MButton'
import { ScrollableTabSection } from './styles'

const ScrollableTabs = ({ tab, setTab, tabs, noBorder, style }: any) => {
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS

	const selectedTabRef = useRef<HTMLButtonElement>(null)
	const scrollableTabSectionRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (selectedTabRef.current && scrollableTabSectionRef.current) {
			const parent = scrollableTabSectionRef.current
			const child = selectedTabRef.current
			parent.scrollLeft =
				child.offsetLeft -
				parent.offsetWidth / 2 +
				child.offsetWidth / 2
		}
	}, [tab])

	return (
		<ScrollableTabSection ref={scrollableTabSectionRef}>
			{tabs?.map((item, index) => (
				<MButton
					icon={item?.icon}
					key={index.toString()}
					title={item?.title}
					borderColor={
						noBorder
							? null
							: item?.tab === tab
							? COLORS.secondary
							: null
					}
					onClick={() => {
						setTab(item.tab)
					}}
					style={{
						marginRight: 10,
						flexDirection: 'row',
						alignItems: 'center',
						color: textsilver,
					}}
					textStyle={{
						color: item?.tab === tab ? textw : textsilver,
						...style,
					}}
					refs={item.tab === tab ? selectedTabRef : null}
				/>
			))}
		</ScrollableTabSection>
	)
}

export default ScrollableTabs
