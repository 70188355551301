import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import TradeSettingList from './TradeSettingList'

const TradeSetting = () => {
	const { t } = useTranslation()
	const [checkedOne, setCheckedOne] = useState<boolean>(false)
	const [checkedTwo, setCheckedTwo] = useState<boolean>(false)
	const [checkedThree, setCheckedThree] = useState<boolean>(false)
	const [checkedFour, setCheckedFour] = useState<boolean>(false)
	const [checkedFive, setCheckedFive] = useState<boolean>(false)

	// const { data } = useSetting()

	// console.log('SETTINGS', data)

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				padding: 10,
				marginTop: 20,
			}}>
			<Text
				size={18}
				style={{
					fontWeight: '500',
					marginBottom: 20,
				}}>
				{t('trade')}
			</Text>
			<TradeSettingList
				checked={checkedOne}
				setChecked={setCheckedOne}
				title={t('promptConfirmationWindow')}
			/>
			<TradeSettingList
				checked={checkedTwo}
				setChecked={setCheckedTwo}
				title={t('showConfirmationWindow')}
			/>
			<TradeSettingList
				checked={checkedThree}
				setChecked={setCheckedThree}
				title={t('promptConfirmationWindowForLeverage')}
			/>
			<TradeSettingList
				checked={checkedFour}
				setChecked={setCheckedFour}
				title={t('turnOneOrderBook')}
			/>
			<TradeSettingList
				checked={checkedFive}
				setChecked={setCheckedFive}
				title={t('promptCancelAllConfirmationWindow')}
			/>
		</View>
	)
}

export default React.memo(TradeSetting)
