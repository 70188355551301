import React, { forwardRef } from 'react'
import { CSSProperties } from 'styled-components'
import { View } from 'styles/reactnative'
import { InputStyle } from './styles'

interface IProps {
	placeholder: string
	type: string
	value: string | number | undefined
	defaultValue?: string | number
	style?: any
	onChange?: any
	onFocus?: any
	onBlur?: any
	ref?: any
	vStyle?: CSSProperties
	icon?: any
	maxLength?: number
	CustomComp?: any
	onClick?: any
	onInputClick?: any
	readonly?: boolean
	onKeyDown?: any
}

const CInput = forwardRef(
	(
		{
			type,
			value,
			defaultValue,
			style,
			placeholder,
			onChange,
			onFocus,
			onBlur,
			vStyle,
			icon,
			maxLength,
			CustomComp,
			onClick,
			onInputClick,
			readonly,
			onKeyDown=(e)=>{},
		}: IProps,
		ref?: any
	) => {
		return (
			<View style={vStyle} onClick={onClick}>
				{icon ? icon : null}
				<InputStyle
					ref={ref}
					defaultValue={defaultValue}
					onClick={onInputClick}
					value={value ? value : ''}
					type={type}
					onKeyDown={e => {
						if (e.key == 'Enter') {
							e.preventDefault()
							onKeyDown(e)
						}
					}}
					placeholder={placeholder}
					style={{
						...style,
					}}
					onChange={onChange}
					maxLength={maxLength ? maxLength : 100}
					onFocus={onFocus}
					onBlur={onBlur}
					readOnly={readonly}
					disabled={readonly}
				/>

				{CustomComp ? CustomComp : null}
			</View>
		)
	}
)

export default React.memo(CInput)
// const Input = forwardRef((props:ComponentProps<any>,ref) => {
//     return (
//         <StyledInput ref={ref} id={props.id} name={props.name} autoComplete={"off"} maxLength={props.maxlength} onBlur={props.onBlur} onChange={props.onChange} {...props}></StyledInput>
//     )
// });
// export default Input;
//   <Input
//       ref={emailRef}
//       name="email"
//       type="email"
//       width={'200px'}
//       auto="off"
//       id="Email"
//       maxlength="50"
//       onBlur={onBlur}
//       onChange={onChange}
//   ></Input>;
