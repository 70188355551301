import React, { useState } from 'react'
import MCoinLists from 'app/mobile/components/MCoinLists'
import { MMarketListSection } from '../../styles'
import MarketListHeader from '../../MarketListHeader'

const MarketSpotDai = () => {
	const [data] = useState([])
	return (
		<MMarketListSection>
			{data?.length ? <MarketListHeader /> : null}
			<MCoinLists data={data} noData={'noCoins'} />
		</MMarketListSection>
	)
}

export default MarketSpotDai
