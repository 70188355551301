import React from 'react'
import CopyTraderDetailSection from './CopyTraderDetailSection'
import CopyTraderProfile from './CopyTraderProfile'
import { CopyTraderDetailWrapper } from './styles'

interface IProps {
	selectedData?: any
}
const CopyTraderDetail: React.FC<IProps> = ({ selectedData }) => {
	return (
		<CopyTraderDetailWrapper>
			<CopyTraderProfile />
			<CopyTraderDetailSection />
		</CopyTraderDetailWrapper>
	)
}

export default CopyTraderDetail
