import React, { useContext } from 'react'
import { IconX } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	closeModal?: () => void
}
const LanguageModalHeader = ({ closeModal }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				paddingLeft: 0,
				paddingRight: 0,
			}}>
			<Text size={16} style={{ marginTop: 10 }}>
				{t('chooseYourLanguage')}
			</Text>
			<CButton
				icon={<IconX size={16} color={textw} />}
				style={{
					// background: COLORS.lightGray5,
					padding: 3,
					borderRadius: 50,
				}}
				onClick={() => {
					closeModal && closeModal()
				}}
			/>
		</View>
	)
}

export default LanguageModalHeader
