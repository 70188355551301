import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { useTimezone } from '../../../../hooks/timezone'
import ReferralTableListItems from './ReferralTableListItems'

const ReferralTableLists = ({ data }) => {
	const { i18n } = useTranslation()

	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				paddingBlock: 15,
			}}>
			<ReferralTableListItems item={data?.nickname} width={'20%'} />
			<ReferralTableListItems item={data?.id} width={'20%'} />
			<ReferralTableListItems
				item={useTimezone(data?.created_at)}
				width={'20%'}
			/>
			<ReferralTableListItems item={data?.balance} width={'20%'} />
			<ReferralTableListItems item={data?.futuresbalance} width={'20%'} />
		</View>
	)
}

export default ReferralTableLists
