import { IconHourglassLow } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import { Col, Input, Modal, Row } from 'antd'
import API from '../../../../../utils/api'

const IdentifyBody = ({ level, last }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { data: myinfo } = useMyProfile()
	let verified = false
	const mylevel = parseInt(myinfo?.level)
	const currentlevel = parseInt(level?.[0]?.level)

	const [level2, setLevel2] = React.useState(false)
	const [level3, setLevel3] = React.useState(false)
	const [fullname, setFullname] = React.useState(myinfo?.fullname)
	const [bank1, setBank1] = React.useState(myinfo?.bank_name)
	const [bank2, setBank2] = React.useState(myinfo?.bank_account)

	console.log(myinfo)
	if (currentlevel == 1) {
		if (mylevel >= 1 || mylevel == -1) {
			verified = true
		}
	} else if (currentlevel == 2) {
		if (mylevel >= 2) {
			verified = true
		}
	} else if (currentlevel == 3) {
		if (mylevel >= 3) {
			verified = true
		}
	}

	return (
		<View
			style={{
				height: '90%',
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				padding: 15,
			}}>
			<View style={{ alignItems: 'flex-start' }}>
				<View
					style={{
						borderLeft: `2px solid ${COLORS.secondary}`,
						paddingLeft: 5,
					}}>
					<Text>{t('requirements')}</Text>
				</View>
				<View style={{ flexDirection: 'row', marginTop: 10 }}>
					{level?.map((item: any, index: any) => (
						<View
							style={{
								flexDirection: 'row',
								marginRight: 20,
								borderRight:
									level?.length - 1 > index
										? `2px solid ${textw}`
										: 0,
								paddingRight: 10,
							}}
							key={index.toString()}>
							{item?.icon}
							<Text style={{ marginLeft: 5 }}>
								{t(item?.title)}
							</Text>
						</View>
					))}
				</View>
				<View
					style={{
						alignItems: 'center',
						flexDirection: 'row',
						marginTop: 30,
					}}>
					<IconHourglassLow size={18} color={textw} />
					<Text size={14} style={{ marginLeft: 10 }}>
						{t('reviewDuration')}
					</Text>
				</View>
			</View>
			{!verified ? (
				<CButton
					title={t('verifyNow')}
					onClick={() => {
						if (level?.[0]?.level == 2) {
							setLevel2(true)
						} else if (level?.[0]?.level == 3) {
							setLevel3(true)
						}
					}}
					style={{
						width: '100%',
						height: 40,
						background: COLORS.primary,
						color: COLORS.white,
					}}
				/>
			) : null}

			<Modal
				open={level2}
				onCancel={() => setLevel2(false)}
				okText={t('save')}
				onOk={() => {
					setLevel2(false)

				}}>
				인증요청
				<Row align={'middle'} style={{ marginTop: 10 }}>
					<Col>실명</Col>
					<Col style={{ marginLeft: 10 }}>
						<Input
							value={fullname}
							placeholder="실명"
							maxLength={15}
							onChange={e => {
								setFullname(e.target.value)
							}}
						/>
					</Col>
				</Row>
				<Row align={'middle'} style={{ marginTop: 20 }}>
					<Col>은행</Col>
					<Col style={{ marginLeft: 10 }}>
						<Input
							value={bank1}
							placeholder="은행명"
							maxLength={10}
							width={50}
							style={{ marginRight: 10, width: 70 }}
							onChange={e => {
								setBank1(e.target.value)
							}}
						/>
					</Col>
					<Col>
						<Input
							value={bank2}
							placeholder="계좌번호"
							onChange={e => {
								setBank2(e.target.value)
							}}
						/>
					</Col>
				</Row>
			</Modal>
		</View>
	)
}

export default IdentifyBody
