import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const DerivativesHeaderWrapper = styled.div`
	width: 100%;
	padding: 10px;
	padding-bottom: 0px;
`
export const CrossModalWrapper = styled.div`
	border-radius: 30px;
	align-items: flex-start;
	flex-direction: row;
	padding: 20px;
`
export const DerivativesHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	background-color: ${props => props.theme.COLORS.grey};
	border-radius: 5px;
`
export const CrossModalDescriptionBox = styled.div`
	background: ${COLORS.gray};
	padding: 10px;
	border-radius: 5px;
	margin-top: 10px;
	line-height: 1.5;
`
export const CrossModalLeverage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
`
export const CrossTradeAmount = styled.div`
	background-color: ${COLORS.gray};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	margin-top: 10px;
	border-radius: 5px;
`
export const SliderBar = styled.input.attrs({
	type: 'range',
})`
	-webkit-appearance: none;
	height: 5px;
	width: 100%;
	border-radius: 25px;
	background: ${props =>
		`linear-gradient(to right, ${COLORS.BTN_HOVER} ${props.value}%, white ${props.value}%)`};
	outline: none;
	opacity: 1;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;

	&:hover {
		opacity: 1;
	}

	&:focus {
		outline: none;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: ${COLORS.greyWhite};
		cursor: pointer;
	}

	&::-moz-range-thumb {
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fff;
		cursor: pointer;
	}
`

export const SliderButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
`

export const SliderButton = styled.button`
	background-color: transparent;
	border: none;
	font-size: 12px;
	color: ${COLORS.lightGray5};
	cursor: pointer;
	margin-top: 5px;
`
