import React from 'react'
import {
	IconRobot,
	IconChartHistogram,
	IconVocabulary,
	IconSettings,
	IconCalculator,
} from '@tabler/icons'
import { TradeButtonContainer, TradeButtonContent } from './styles'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	label: string
	onPress?: () => void
	data?: any
	icon?: string
}
const TradeButton = ({ label, icon, onPress }: IProps) => {
	const { textsilver } = React.useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<TradeButtonContainer
			onClick={() => {
				if (onPress) {
					onPress()
				}
			}}>
			{icon === 'settings' ? (
				<IconSettings color={textsilver} size={18} />
			) : null}
			{icon === 'robot' ? (
				<IconRobot color={textsilver} size={18} />
			) : null}
			{icon === 'chart' ? (
				<IconChartHistogram color={textsilver} size={18} />
			) : null}
			{icon === 'calc' ? (
				<IconCalculator color={textsilver} size={18} />
			) : null}
			{icon === 'guide' ? (
				<IconVocabulary color={textsilver} size={18} />
			) : null}
			<TradeButtonContent>{t(label)}</TradeButtonContent>
		</TradeButtonContainer>
	)
}

export default React.memo(TradeButton)
