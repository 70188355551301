import { IconX } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
interface IProps {
	closeModal?: () => void
}
const OrderModalHeader = ({ closeModal }: IProps) => {
	const { borderColor, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				borderBottom: `1px solid ${borderColor}`,
				paddingBottom: 10,
			}}>
			<Text size={16}>{t('orderPlacementPreferences')}</Text>
			<CButton
				icon={<IconX size={16} color={textw} />}
				style={{
					background: borderColor,
					color: textw,
					padding: 3,
					borderRadius: 50,
				}}
				onClick={() => {
					closeModal && closeModal()
				}}
			/>
		</View>
	)
}

export default OrderModalHeader
