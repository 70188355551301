import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const DerivativesTransactionWrapper = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${props => props.theme.COLORS.bgColor};
	color: ${COLORS.greyWhite};
	overflow-y: auto;
	@media only screen and (max-width: 1200px) {
		height: 100%;
	}
	@media only screen and (max-width: 600px) {
		height: auto;
	}
`
