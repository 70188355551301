import styled from 'styled-components'

export const CopyTradeSection = styled.div`
	position: static;
	top: 0;
	width: 100%;
	height: 160px;
	background: linear-gradient(180deg, #7438ff 0%, #3860ff 100%);
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: 900px) {
		height: 284px;
	}
`

export const SectionWrap = styled.div`
	width: 100%;
	align-items: center;
	max-width: 1280px;
	display: flex;
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: space-between;
	@media only screen and (max-width: 900px) {
		flex-direction: column;
	}
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
