import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CImage } from 'app/components/Common'
import cryptocurrencies from 'assets/images/cryptocurrencies.png'
import trading from 'assets/images/trading.png'
import blueChip from 'assets/images/blue-chip.png'
import { Text } from 'styles/reactnative'
import {
	AboutServiceListContainer,
	AboutServiceListItem,
	AboutServiceListSection,
} from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

const AboutServiceList = () => {
	const { t } = useTranslation()

	const { drawerBgColor, content } = useContext(ThemeContext).theme.COLORS
	const data = [
		{
			title: 'spot',
			content: 'serviceSpotContent',
			image: cryptocurrencies,
		},
		{
			title: 'derivatives',
			content: 'servicederivativesContent',
			image: trading,
		},
		// {
		// 	title: 'usdcDerivatives',
		// 	content: 'serviceusdcDerivativesContent',
		// 	image: blueChip,
		// },
		{
			title: 'copyTrading',
			content: 'serviceCopyTradingContent',
			image: cryptocurrencies,
		},
	]
	return (
		<AboutServiceListSection>
			<AboutServiceListContainer>
				{data?.map((item, index) => (
					<AboutServiceListItem
						key={index.toString()}
						style={{
							background: drawerBgColor,
						}}>
						<Text size={22}>{t(item?.title)}</Text>
						<CImage
							src={item?.image}
							style={{ height: 62, width: 62 }}
						/>
						<Text
							size={12}
							style={{
								fontWeight: '300',
								color: content,
								textAlign: 'center',
								lineHeight: 1.3,
							}}>
							{t(item?.content)}
						</Text>
					</AboutServiceListItem>
				))}
			</AboutServiceListContainer>
		</AboutServiceListSection>
	)
}

export default AboutServiceList
