import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useMyProfile } from '../../../hooks/user/myprofile'

const TradingLevelFee = ({ title }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { fee } = useMyProfile()

	return (
		<View style={{ flexDirection: 'row' }}>
			<Text size={18} style={{ color: textw, fontWeight: '300' }}>
				{t(title)}
			</Text>
			<CButton
				title={t('level') + ' ' + (fee?.level || 0)}
				style={{
					background: 'rgba(118, 99, 252, 0.5)',
					width: 50,
					height: 30,
					color: COLORS.white,
					marginLeft: 15,
					borderRadius: 5,
				}}
			/>
		</View>
	)
}

export default TradingLevelFee
