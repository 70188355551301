import React, { useState } from 'react'
import { IconWorld } from '@tabler/icons'
import { useTranslation } from 'react-i18next'

import { HeaderButtonWrap, HeaderButtonInner } from './styles'
import { CModal } from 'app/components/Common'
import LanguageModal from './LanguageModal'

interface IProps {
	label: string
	onPress?: () => void
	fontSize?: number
	iconSize?: number
}

const BtnLanguageChange = ({ label, onPress, fontSize, iconSize }: IProps) => {
	const { i18n } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const closeModal = () => setShowModal && setShowModal(false)
	const openModal = () => {
		setShowModal(true)
	}
	return (
		<HeaderButtonWrap onClick={openModal}>
			<CModal
				visible={showModal}
				onClose={closeModal}
				bgColor={'transparent'}>
				<LanguageModal closeModal={closeModal} />
			</CModal>
			<IconWorld size={iconSize ? iconSize : 20} />
			<HeaderButtonInner style={fontSize ? { fontSize } : {}}>
				{i18n.language?.toUpperCase()}
			</HeaderButtonInner>
		</HeaderButtonWrap>
	)
}

export default React.memo(BtnLanguageChange)
