import styled from 'styled-components'

export const SubDropdownBodySection = styled.div`
	width: 100%;
	height: 450px;
	min-height: 450px;
	margin-top: 5px;
	padding: 10px;
`
export const SubDropdownListItemSection = styled.div`
	display: flex;
	flex-direction: row;
	padding: 10px;
	padding-right: 25px;
	&:hover {
		background-color: ${p => p.theme.COLORS.header};
		border-radius: 10px;
	}
`
