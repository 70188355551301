import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CButton, CheckBox } from 'app/components/Common'
import { COLORS, FONTS } from 'app/constants'
import { View, Text } from 'styles/reactnative'

const CopyTradeSettingFooter = () => {
	const { t } = useTranslation()
	const [checked, setChecked] = useState<boolean>(false)
	const handleChange = () => {
		setChecked(!checked)
	}
	return (
		<View
			style={{
				justifyContent: 'center',
				alignItems: 'flex-start',
				width: '100%',
				height: '18%',
			}}>
			<View style={{ flexDirection: 'row', marginBottom: 10 }}>
				<CheckBox value={checked} onChange={handleChange} />
				<Text size={14} style={{ color: COLORS.gray }}>
					{t('iHaveReadAndAgreed')}
				</Text>
				<Text
					size={14}
					style={{ color: COLORS.BTN_HOVER, marginLeft: 10 }}>
					{t('exChangeCopyTradingAgreement')}
				</Text>
			</View>
			<CButton
				title={t('copyNow')}
				style={{
					...FONTS.h5,
					paddingBlock: 10,
					paddingInline: 20,
					backgroundColor: COLORS.primary,
					width: '100%',
					color: COLORS.white,
				}}
				textHover={COLORS.white}
			/>
		</View>
	)
}

export default CopyTradeSettingFooter
