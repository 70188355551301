import styled from 'styled-components'

export const InputStyle = styled.input`
	padding: 13px;
	width: 100%;

	border-radius: 5px;
	margin-bottom: 10px;

	&:-webkit-autofill {
		box-shadow: 0 0 0px 1000px ${props => props.theme.COLORS.gray} inset !important;
		-webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.COLORS.gray}
			inset !important;
		transition: background-color 5000s ease-in-out 0s;
	}

	&:-webkit-autofill:focus {
		box-shadow: 0 0 0px 1000px ${props => props.theme.COLORS.gray} inset !important;
		-webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.COLORS.gray}
			inset !important;
		transition: background-color 5000s ease-in-out 0s;
	}
`
