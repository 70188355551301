import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const MarketListItemSection = styled.div`
	width: 100%;
	margin: 5px;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	background-color: ${props => props.theme.COLORS.drawerBgColor};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 6px 0 rgba(50, 50, 50, 0.1);
`

export const ListItemSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 270px;
	padding: 20px;
	cursor: pointer;
`
export const ListItemContent = styled.div`
	padding-left: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
`
export const ListImageBox = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
`
export const RibbonRate = styled.div`
	border-left: 25px solid ${COLORS.primaryOpacity};
	border-right: 25px solid ${COLORS.primaryOpacity};
	border-bottom: 13px solid transparent;
	position: absolute;
	left: 25px;
	top: 10px;
	padding-block: 5px;
	padding-top: 10px;
	width: 40px;
`
