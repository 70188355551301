import React, { useContext } from 'react'
import { Select } from 'antd'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTranslation } from 'react-i18next'

const LatestReservesHeader = () => {
	const { t } = useTranslation()
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	const handleChange = (value: string) => {
		console.log(`selected ${value}`)
	}
	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				width: '100%',
			}}>
			<View
				style={{
					width: '65%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<Text
					size={25}
					style={{
						fontWeight: '500',
						color: textw,
						lineHeight: 1.5,
					}}>
					{t('latestReservesRatio')}
				</Text>
				<Text
					size={16}
					style={{
						fontWeight: '300',
						color: textsilver,
						lineHeight: 1.3,
					}}>
					{t('latestReservesRatioContent')}
				</Text>
			</View>
			<View style={{ width: '35%', alignItems: 'flex-end' }}>
				<Select
					defaultValue="THEBIT"
					style={{ width: '100%', background: 'transparent' }}
					onChange={handleChange}
					options={[
						{
							value: 'THEBIT',
							label: '2023-07-03 12:00:00 (GMT+8)',
						},
					]}
				/>
			</View>
		</View>
	)
}

export default LatestReservesHeader
