export const data = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 1400,
		date: '2023-01-27  00:20:16',
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 5398,
		date: '2023-01-27  00:20:16',
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 3500,
		date: '2023-02-27  00:20:16',
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3008,
		date: '2023-03-27  00:20:16',
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 3800,
		date: '2023-03-27  00:20:16',
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 2800,
		date: '2023-05-27  00:20:16',
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		date: '2023-05-27  00:20:16',
	},
	{
		name: 'Page H',
		uv: 4000,
		pv: 2400,
		date: '2023-04-27  00:20:16',
	},
	{
		name: 'Page I',
		uv: 3000,
		pv: 4398,
		date: '2023-04-27  00:20:16',
	},
	{
		name: 'Page J',
		uv: 2000,
		pv: 2800,
		date: '2023-06-27  00:20:16',
	},
	{
		name: 'Page K',
		uv: 2780,
		pv: 4008,
		date: '2023-06-27  00:20:16',
	},
	{
		name: 'Page L',
		uv: 1890,
		pv: 800,
		date: '2023-07-27  00:20:16',
	},
	{
		name: 'Page N',
		uv: 2390,
		pv: 800,
		date: '2023-07-27  00:20:16',
	},
	{
		name: 'Page M',
		uv: 3490,
		pv: 4300,
		date: '2023-08-27  00:20:16',
	},
]

export const getIntroOfPage = (label: any) => {
	if (label === 'Page A') {
		return "Page A is about men's clothing"
	}
	if (label === 'Page B') {
		return "Page B is about women's dress"
	}
	if (label === 'Page C') {
		return "Page C is about women's bag"
	}
	if (label === 'Page D') {
		return 'Page D is about household goods'
	}
	if (label === 'Page E') {
		return 'Page E is about food'
	}
	if (label === 'Page F') {
		return 'Page F is about baby food'
	}
	return ''
}
