import styled from 'styled-components'

export const MCatergorySection = styled.div`
	height: 60px;
	width: 100%;
	padding-inline: 16px;
	display: flex;
	justify-content: space-between;
`
export const CategoryBtn = styled.button`
	display: flex;
	background-color: transparent;
	outline: 0;
	cursor: pointer;
	transition: ease border-bottom 250ms;
	&:active {
		transform: translateY(1px);
	}
`
