import React from 'react'
import { useFetcherPublic } from 'app/hooks/fetcher'
import SearchHot from './SearchHot'
import SearchNew from './SearchNew'
import SearchRecommend from './SearchRecommend'
import { SearchIntailSection } from './styles'

const SearchIntialRender = () => {
	return (
		<SearchIntailSection>
		</SearchIntailSection>
	)
}

export default SearchIntialRender
