import { COLORS } from 'app/constants'
import React from 'react'
import { Text, View } from 'styles/reactnative'
import { TraderFollowInfoListSection } from './styles'

const TraderFolowerInfoListItems = ({ title, amount, equal }: any) => {
	return (
		<TraderFollowInfoListSection
			style={{ alignItems: equal ? 'center' : 'flex-start' }}>
			{title ? (
				<Text size={14} style={{ color: COLORS.lightGray }}>
					{title}
				</Text>
			) : null}
			{equal ? (
				<Text
					size={20}
					style={{
						color: COLORS.lightGray,
						background: COLORS.grayWhite,
						paddingBlock: 1,
						paddingInline: 5,
						borderRadius: 50,
					}}>
					=
				</Text>
			) : null}
			{amount ? (
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<Text
						size={24}
						style={{ color: COLORS.black, fontWeight: '600' }}>
						{amount}
					</Text>
					<Text
						size={16}
						style={{
							color: COLORS.lightGray,
							alignSelf: 'flex-end',
							marginBottom: 3,
							marginLeft: 5,
						}}>
						USDT
					</Text>
				</View>
			) : null}
		</TraderFollowInfoListSection>
	)
}

export default React.memo(TraderFolowerInfoListItems)
