import { STORE_TRADE } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import { getPersistData } from '../persist'
import { useEffect, useMemo, useState } from 'react'

const KEY = STORE_TRADE

let swrData: any = getPersistData(KEY)

export default function useTradeStore(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (reset === true) {
				swrData = null
				return null
			}
			if (initData !== undefined && swrData == undefined) {
				swrData = initData
			}
			return swrData
		},
		{ dedupingInterval: 1 }
	)

	const ep_id = useMemo(() => {
		return data?.ep_id
	}, [data?.ep_id])

	const chart_id = useMemo(() => {
		return data?.chart_id
	}, [data?.chart_id])

	const pairs = useMemo(() => {
		return data?.pairs
	}, [data?.pairs])

	const dp = useMemo(() => {
		return data?.dp || 2
	}, [data?.dp])

	const dp2 = useMemo(() => {
		return data?.dp2 || 2
	}, [data?.dp2])

	const unit = useMemo(() => {
		return data?.unit || 2
	}, [data?.unit])

	return {
		data,
		ep_id,
		chart_id,
		pairs,
		dp,
		dp2,
		unit,
		mutate: (value: any) => {
			swrData = value
			return mutate()
		},
	}
}
