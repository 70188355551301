export const USERLEVEL = {
	SIM_BLACK: -10,
	BLACK: -9,
	SIM_REQUEST: -8,
	REJECT: -2,
	WAIT: -1,
	NEW_REQUEST: 0,
	NORMAL: 1,
	VIP: 2,
	VVIP: 3,
	VVVIP: 4,
	PARTNER: 5,
	SIM: 6,
	FAKE: 7,
	ADMIN: 8,
	MASTERADMIN: 9,
	LEADERBOARD: 10,
	DEV: 99,
}
