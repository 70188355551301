import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'
export const HeaderSearchSection = styled.div``
export const StyledInput = styled.div`
	&.inputWithIcon {
		position: relative;
	}
	margin-left: 10px;
	.left-icon {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
	.right-icon {
		width: 15px;
		height: 15px;
		position: absolute;
		right: 10px;
		cursor: pointer;
		top: 50%;
		padding-left: 1px;
		transform: translateY(-50%);
		background-color: ${COLORS.grey};
		border-radius: 50px;
	}
`
