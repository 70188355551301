import useSWR from 'swr'
import { getPersistData } from '../persist'
import { useState } from 'react'

let swrData: any = getPersistData('LOCAL@MITORDER') || {}

export function useMitOrder() {
	const { data, mutate } = useSWR<any>('LOCAL@MITORDER', async () => {
		return swrData || {}
	})

	return {
		data,
		mutate: (value?: any, epid?: number) => {
			try {
				if (value !== undefined) {
					if (epid) {
						swrData['MIT' + epid] = value

						if (value && value.length == 0) {
							delete swrData['MIT' + epid]

							swrData['NO'] = [{ id: Math.random() }]
						}
						return mutate({ ...swrData })
					}
				}
				return mutate()
			} catch (e) {
				return mutate()
			}
		},
	}
}
