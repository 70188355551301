import { IconChevronRight } from '@tabler/icons'
import { CBottomSheet } from 'app/mobile/components'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const SecurityLists = ({
	title,
	IconComp,
	action,
	open,
	setOpen,
	BottomComp,
	item,
	setItem,
	maxHeight = 500,
}: any) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView
			style={{
				width: '100%',
				flexDirection: 'row',
				paddingBlock: 10,
				cursor: 'pointer',
			}}
			onClick={() => {
				setOpen && setOpen(true)
				action && toast(t('notWorkingNow') + '')
			}}>
			<MView
				style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
				<MView style={{ marginRight: 10 }}>
					{IconComp ? IconComp : null}
				</MView>
				<MText style={{ fontSize: 12, color: textw, fontWeight: 300 }}>
					{t(title)}
				</MText>
			</MView>
			<IconChevronRight size={16} color={textw} />
			<CBottomSheet
				open={open}
				setOpen={setOpen}
				BottomContainer={BottomComp}
				item={item}
				setItem={setItem}
				maxHeight={maxHeight}
			/>
		</MView>
	)
}

export default SecurityLists
