import React, { useContext, useEffect, useState } from 'react'
import { useToken } from '../hooks'
import { ThemeContext } from '../../theme/ThemeProvider'
import {
	ContentContainer,
	ContentWrapper,
} from '../../styles/globalPage-styles'
import { Header } from '../organisms'
import { Modal, Space, Spin } from 'antd'
import { IconCircleXFilled } from '@tabler/icons-react'

const Logout = ({}: any) => {
	const { data: token, mutate } = useToken()

	useEffect(() => {
		let islogout = true
		if (islogout) {
			if (token) {
				mutate(null)
				window.history.replaceState({}, document.title)
				window.location.href = '/'
			}
			setTimeout(() => {
				window.location.href = '/'
			}, 800)
			islogout = false
		} else {
		}
	}, [])

	const { isDarkMode, theme } = useContext(ThemeContext)
	const [visible, setVisible] = React.useState(false)
	return (
		<ContentContainer isDarkMode={isDarkMode}>
			<Header showSelect={visible} setShowSelect={setVisible} />
			<ContentWrapper>
				<div
					style={{
						flex: 1,

						justifyContent: 'center',
						width: '100%',
						height: '100%',
						color: theme.COLORS.textw,
						alignItems: 'center',
						marginTop: 30,
						justifyItems: 'center',
						textAlign: 'center',
					}}>
					<Spin size="large">Redirecting..</Spin>
					<Modal
						open={true}
						footer={null}
						closeIcon={null}
						closable={false}
						centered>
						<Space direction="vertical">
							<Space style={{ fontWeight: 'bold' }}>
								<IconCircleXFilled color="red" />
								SESSION EXPIRED
							</Space>

							<Space>
								세션이 만료되었습니다. 잠시 뒤 로그인 페이지로
								이동합니다.
							</Space>
						</Space>
					</Modal>
				</div>
			</ContentWrapper>
		</ContentContainer>
	)
}
export default Logout
